/* eslint-disable react-hooks/exhaustive-deps */
import BigNumber from 'bignumber.js'
import { useActiveWeb3React } from 'hooks'
import { useEffect, useMemo, useState } from 'react'
import { useFormatSymbolTokenPrice } from 'state/data/hooks/useTokenPrice'
import { useGetNett, useGetVeNETT, useGetVeNETTStaking } from 'hooks/contract/useGetContract'

export default function useGetVeNettInfo() {
  const { account } = useActiveWeb3React()
  const stakeContract = useGetVeNETTStaking()
  const veNettContract = useGetVeNETT()
  const nettContract = useGetNett()
  const [totalStaked, setTotalStake] = useState(new BigNumber(0))
  const [totalSupply, setTotalSupply] = useState(new BigNumber(0))
  const [userStakeAmount, setUserStakeAmount] = useState(new BigNumber(0))
  const priceInfo = useFormatSymbolTokenPrice()
  const [speedUpEndTimestamp, setSpeedUpEndTimestamp] = useState(0)
  const [maxCapPct, setMaxCapPct] = useState(new BigNumber(0))
  const [pendingVeNETT, setPendingVeNETT] = useState(new BigNumber(0))
  const [userVeNETTBalance, setUserVeNETTBalance] = useState(new BigNumber(0))

  async function getTotalSupply() {
    if (veNettContract) {
      const res = await veNettContract.totalSupply()
      if (res) {
        setTotalSupply(res?.toString() ? new BigNumber(res.toString()).shiftedBy(-18) : new BigNumber(0))
      }
    }
  }

  async function getTotalStakedNett() {
    if (stakeContract && nettContract) {
      const res = await nettContract.balanceOf(stakeContract.address)
      if (res) {
        setTotalStake(res?.toString() ? new BigNumber(res.toString()).shiftedBy(-18) : new BigNumber(0))
      }
    }
  }

  async function getMaxCapPct() {
    if (stakeContract) {
      const res = await stakeContract.maxCapPct()
      if (res) {
        setMaxCapPct(res?.toString() ? new BigNumber(res.toString()) : new BigNumber(0))
      }
    }
  }

  useEffect(() => {
    let interval: any
    if (stakeContract) {
      getMaxCapPct()

      interval = setInterval(() => {
        getMaxCapPct()
      }, 30000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [stakeContract])

  useEffect(() => {
    let interval: any
    if (stakeContract) {
      getTotalStakedNett()

      interval = setInterval(() => {
        getTotalStakedNett()
      }, 30000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [stakeContract])

  useEffect(() => {
    let interval: any
    if (veNettContract) {
      getTotalSupply()

      interval = setInterval(() => {
        getTotalSupply()
      }, 30000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [veNettContract])

  async function getUserInfo() {
    if (!stakeContract || !account) return
    try {
      const res = await stakeContract.userInfos(account)
      setUserStakeAmount(new BigNumber(res?.balance.toString() || 0).shiftedBy(-18))
      setSpeedUpEndTimestamp(res?.speedUpEndTimestamp.toNumber())
      // if (res?.allocation.toNumber()) {
      //   setUserAllocation(new BigNumber(res?.allocation.toString() || 0).shiftedBy(-data.saleToken.decimals))
      // }
    } catch (e) {
      console.error(`get  userInfo ${account} failed`)
      console.error(e)
    }
  }

  async function getBalance(contract: any) {
    if (contract && account) {
      try {
        const res = await contract.balanceOf(account)
        return res
      } catch (e) {
        console.error(e)
      }
    }
  }

  async function getUserVeNETTBalance() {
    if (veNettContract) {
      const res = await getBalance(veNettContract)
      if (res?.toString()) {
        setUserVeNETTBalance(new BigNumber(res.toString()).shiftedBy(-18))
      }
    }
  }

  useEffect(() => {
    let interval: any
    if (veNettContract && account) {
      getUserVeNETTBalance()

      interval = setInterval(() => {
        getUserVeNETTBalance()
      }, 30000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [veNettContract, account])

  async function getPendingVeNETT() {
    try {
      if (account && stakeContract) {
        const res = await stakeContract.getPendingVeNETT(account)

        if (res && res.toString()) {
          setPendingVeNETT(new BigNumber(res.toString()).shiftedBy(-18))
        }
      }
    } catch (e) {
      console.error(e)
      console.error('get pending veNETT error')
    }
  }

  useEffect(() => {
    let interval: any
    if (stakeContract && account) {
      getPendingVeNETT()

      interval = setInterval(() => {
        getPendingVeNETT()
      }, 15000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [stakeContract, account])

  const nettPrice: number = useMemo(() => {
    return priceInfo?.nett?.price || 0
  }, [priceInfo])

  const totalStakedUSD = useMemo(() => {
    if (nettPrice) {
      return totalStaked.multipliedBy(nettPrice).toNumber()
    }
    return 0
  }, [totalStaked, nettPrice])

  const youStakedUSD = useMemo(() => {
    if (nettPrice) {
      return userStakeAmount.multipliedBy(nettPrice).toNumber()
    }
    return 0
  }, [userStakeAmount, nettPrice])

  useEffect(() => {
    getUserInfo()
  }, [account, stakeContract])

  const totalPendingVeNett = useMemo(() => {
    return userStakeAmount.multipliedBy(maxCapPct).dividedBy(100)
  }, [userStakeAmount, maxCapPct])

  const pendingVeNettProgress = useMemo(() => {
    return totalPendingVeNett.isGreaterThan(0)
      ? pendingVeNETT
          .plus(userVeNETTBalance)
          .dividedBy(totalPendingVeNett)
          .multipliedBy(100)
          .toFixed(3)
      : '0'
  }, [totalPendingVeNett, pendingVeNETT, userVeNETTBalance])

  return {
    totalStaked,
    totalSupply,
    userStakeAmount,
    stakeContract,
    veNettContract,
    getUserInfo,
    getTotalStakedNett,
    getTotalSupply,
    totalStakedUSD,
    youStakedUSD,
    nettPrice,
    speedUpEndTimestamp,
    nettContract,
    getUserVeNETTBalance,
    getPendingVeNETT,
    totalPendingVeNett,
    maxCapPct,
    pendingVeNETT,
    userVeNETTBalance,
    pendingVeNettProgress
  }
}
