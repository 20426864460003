import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useTranslation } from 'react-i18next'
import { useFormatTime } from 'hooks'

import { formattedNum, urls } from 'utils/infos'
import { useMedia } from 'react-use'
// import { useCurrentCurrency } from 'hooks/infos/Application'
import { RowBetween } from 'components/Row'
import { FilterGroup, FilterText } from '../ButtonStyled'

import LocalLoader from 'components/Loading'
import { Box, Flex } from 'rebass'
import { EXPLORE_URL } from 'constants/index'
import Link from '../Link'
import { EmptyCard } from '..'
import DropdownSelect from '../DropdownSelect'
import FormattedName from '../FormattedName'
import { TYPE } from 'theme'
import { PairTransactions } from 'gql/subgraph/analytics'

dayjs.extend(utc)

const PageButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 0.5em;
`

const Arrow = styled.div<{ faded?: boolean }>`
  color: ${({ theme }) => theme.v2.t01};
  opacity: ${props => (props.faded ? 0.3 : 1)};
  padding: 0 20px;
  user-select: none;
  :hover {
    cursor: pointer;
  }
`

const List = styled(Box)`
  -webkit-overflow-scrolling: touch;
`

const DashGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 100px 1fr 1fr;
  grid-template-areas: 'txn value time';

  > * {
    justify-content: flex-end;
    text-align: right;
    /* width: 100%; */

    &:first-child {
      justify-content: flex-start;
      text-align: left;
    }

    &:last-child {
      justify-content: flex-end;
      text-align: right;
    }
  }

  @media screen and (min-width: 780px) {
    max-width: 1320px;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    grid-template-areas: 'txn value amountToken amountOther time';

    > * {
      justify-content: center;
      /* width: 100%; */
      text-align: center;
    }
  }

  @media screen and (min-width: 1080px) {
    max-width: 1320px;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: 'txn value amountToken amountOther account time';
  }
`

const ClickableText = styled(TYPE.v2Main)`
  text-align: center;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  user-select: none;
  color: ${({ theme }) => theme.v2.t02};
  font-size: 12px !important;
`

const DataText = styled(TYPE.mainLg)`
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.v2.t01};

  & > * {
    font-size: 14px;
  }

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`

const SORT_FIELD = {
  VALUE: 'amountUSD',
  AMOUNT0: 'token0Amount',
  AMOUNT1: 'token1Amount',
  TIMESTAMP: 'timestamp'
}

enum TXN_TYPE {
  ALL = 'All',
  SWAP = 'Swaps',
  ADD = 'Adds',
  REMOVE = 'Removes'
}

export type Transaction = {
  hash: string
  timestamp: number
  type: TXN_TYPE
  token0Amount: number
  token1Amount: number
  account: string
  token0Symbol: string
  token1Symbol: string
  amountUSD: number
}

const ITEMS_PER_PAGE = 10

function getTransactionType(event: TXN_TYPE, symbol0: string, symbol1: string) {
  const formattedS0 = symbol0?.length > 8 ? symbol0.slice(0, 7) + '...' : symbol0
  const formattedS1 = symbol1?.length > 8 ? symbol1.slice(0, 7) + '...' : symbol1
  switch (event) {
    case TXN_TYPE.ADD:
      return 'Add ' + formattedS0 + ' and ' + formattedS1
    case TXN_TYPE.REMOVE:
      return 'Remove ' + formattedS0 + ' and ' + formattedS1
    case TXN_TYPE.SWAP:
      return 'Swap ' + formattedS0 + ' for ' + formattedS1
    default:
      return ''
  }
}

// @TODO rework into virtualized list
function TxnList({
  transactions,
  symbol0Override,
  symbol1Override,
  color
}: {
  transactions: PairTransactions
  symbol0Override?: string
  symbol1Override?: string
  color?: string
}) {
  const { t } = useTranslation()
  const SCAN_URL = EXPLORE_URL
  // page state
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)

  // sorting
  const [sortDirection, setSortDirection] = useState(true)
  const [sortedColumn, setSortedColumn] = useState(SORT_FIELD.TIMESTAMP)
  const [filteredItems, setFilteredItems] = useState<Transaction[]>([])
  const [txFilter, setTxFilter] = useState(TXN_TYPE.ALL)
  const { formatTime } = useFormatTime()

  // const [currency] = useCurrentCurrency()

  useEffect(() => {
    setMaxPage(1) // edit this to do modular
    setPage(1)
  }, [transactions])

  // parse the txns and format for UI
  useEffect(() => {
    if (transactions && transactions.mints && transactions.burns && transactions.swaps) {
      let newTxns: Transaction[] = []
      if (transactions.mints.length > 0) {
        transactions.mints.map(mint => {
          let newTxn: Transaction = {
            hash: mint.transaction.id,
            timestamp: parseInt(mint.transaction.timestamp),
            type: TXN_TYPE.ADD,
            token0Amount: parseFloat(mint.amount0),
            token1Amount: parseFloat(mint.amount1),
            account: mint.to,
            token0Symbol: mint.pair.token0.symbol,
            token1Symbol: mint.pair.token1.symbol,
            amountUSD: parseFloat(mint.amountUSD)
          }
          return newTxns.push(newTxn)
        })
      }
      if (transactions.burns.length > 0) {
        transactions.burns.map(burn => {
          let newTxn: Transaction = {
            hash: burn.transaction.id,
            timestamp: parseInt(burn.transaction.timestamp),
            type: TXN_TYPE.REMOVE,
            token0Amount: parseFloat(burn.amount0),
            token1Amount: parseFloat(burn.amount1),
            account: burn.sender,
            token0Symbol: burn.pair.token0.symbol,
            token1Symbol: burn.pair.token1.symbol,
            amountUSD: parseFloat(burn.amountUSD)
          }
          return newTxns.push(newTxn)
        })
      }
      if (transactions.swaps.length > 0) {
        transactions.swaps.map(swap => {
          const netToken0 = parseFloat(swap.amount0In) - parseFloat(swap.amount0Out)
          const netToken1 = parseFloat(swap.amount1In) - parseFloat(swap.amount1Out)

          let newTxn: Transaction = {
            hash: swap.transaction.id,
            timestamp: parseInt(swap.transaction.timestamp),
            type: TXN_TYPE.SWAP,
            amountUSD: parseFloat(swap.amountUSD),
            account: swap.from,
            token0Symbol: '',
            token1Symbol: '',
            token0Amount: 0,
            token1Amount: 0
          }

          if (netToken0 < 0) {
            newTxn.token0Symbol = swap.pair.token0.symbol
            newTxn.token1Symbol = swap.pair.token1.symbol
            newTxn.token0Amount = Math.abs(netToken0)
            newTxn.token1Amount = Math.abs(netToken1)
          } else if (netToken1 < 0) {
            newTxn.token0Symbol = swap.pair.token1.symbol
            newTxn.token1Symbol = swap.pair.token0.symbol
            newTxn.token0Amount = Math.abs(netToken1)
            newTxn.token1Amount = Math.abs(netToken0)
          }
          return newTxns.push(newTxn)
        })
      }

      const filtered = newTxns.filter(item => {
        if (txFilter !== TXN_TYPE.ALL) {
          return item.type === txFilter
        }
        return true
      })
      setFilteredItems(filtered)
      let extraPages = 1
      if (filtered.length % ITEMS_PER_PAGE === 0) {
        extraPages = 0
      }
      if (filtered.length === 0) {
        setMaxPage(1)
      } else {
        setMaxPage(Math.floor(filtered.length / ITEMS_PER_PAGE) + extraPages)
      }
    }
  }, [transactions, txFilter])

  useEffect(() => {
    setPage(1)
  }, [txFilter])

  const filteredList =
    filteredItems &&
    filteredItems
      .sort((a: any, b: any) => {
        return parseFloat(a[sortedColumn]) > parseFloat(b[sortedColumn])
          ? (sortDirection ? -1 : 1) * 1
          : (sortDirection ? -1 : 1) * -1
      })
      .slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE)

  const below1080 = useMedia('(max-width: 1080px)')
  const below780 = useMedia('(max-width: 780px)')

  const ListItem = ({ item }: { item: Transaction }) => {
    if (item.token0Symbol === 'WMETIS') {
      item.token0Symbol = 'METIS'
    }

    if (item.token1Symbol === 'WMETIS') {
      item.token1Symbol = 'METIS'
    }

    return (
      <DashGrid style={{ height: '48px' }}>
        <DataText>
          <Link color={color} external href={urls.showTransaction(item.hash)}>
            {getTransactionType(item.type, item.token1Symbol, item.token0Symbol)}
          </Link>
        </DataText>
        <DataText>{formattedNum(item.amountUSD, true)}</DataText>
        {!below780 && (
          <>
            <DataText>
              {formattedNum(item.token1Amount) + ' '}{' '}
              <FormattedName text={item.token1Symbol} maxCharacters={5} margin={true} />
            </DataText>
            <DataText>
              {formattedNum(item.token0Amount) + ' '}{' '}
              <FormattedName text={item.token0Symbol} maxCharacters={5} margin={true} />
            </DataText>
          </>
        )}
        {!below1080 && (
          <DataText>
            <Link color={color} external href={`${SCAN_URL}/address/${item.account}`}>
              {item.account && item.account.slice(0, 6) + '...' + item.account.slice(38, 42)}
            </Link>
          </DataText>
        )}
        <DataText>{formatTime(item.timestamp)}</DataText>
      </DashGrid>
    )
  }

  return (
    <>
      <DashGrid style={{ height: 'fit-content', padding: '0 0 1rem 0' }}>
        {below780 ? (
          <RowBetween>
            <DropdownSelect options={TXN_TYPE} active={txFilter} setActive={setTxFilter} color={color} />
          </RowBetween>
        ) : (
          <FilterGroup>
            <FilterText
              onClick={() => {
                setTxFilter(TXN_TYPE.ALL)
              }}
              active={txFilter === TXN_TYPE.ALL}
            >
              {t('analytics.all')}
            </FilterText>
            <FilterText
              onClick={() => {
                setTxFilter(TXN_TYPE.SWAP)
              }}
              active={txFilter === TXN_TYPE.SWAP}
            >
              {t('analytics.txn_list.swaps')}
            </FilterText>
            <FilterText
              onClick={() => {
                setTxFilter(TXN_TYPE.ADD)
              }}
              active={txFilter === TXN_TYPE.ADD}
            >
              {t('analytics.txn_list.adds')}
            </FilterText>
            <FilterText
              onClick={() => {
                setTxFilter(TXN_TYPE.REMOVE)
              }}
              active={txFilter === TXN_TYPE.REMOVE}
            >
              {t('analytics.txn_list.removes')}
            </FilterText>
          </FilterGroup>
        )}

        <Flex alignItems="center" justifyContent="flexStart">
          <ClickableText
            color="textDim"
            onClick={e => {
              setSortedColumn(SORT_FIELD.VALUE)
              setSortDirection(sortedColumn !== SORT_FIELD.VALUE ? true : !sortDirection)
            }}
          >
            {t('analytics.txn_list.total_value')}{' '}
            {sortedColumn === SORT_FIELD.VALUE ? (!sortDirection ? '↑' : '↓') : ''}
          </ClickableText>
        </Flex>
        {!below780 && (
          <Flex alignItems="center">
            <ClickableText
              color="textDim"
              onClick={() => {
                setSortedColumn(SORT_FIELD.AMOUNT0)
                setSortDirection(sortedColumn !== SORT_FIELD.AMOUNT0 ? true : !sortDirection)
              }}
            >
              {t('analytics.txn_list.token_amount', {
                token: symbol0Override ? symbol0Override : t('analytics.token')
              })}
              {sortedColumn === SORT_FIELD.AMOUNT0 ? (sortDirection ? '↑' : '↓') : ''}
            </ClickableText>
          </Flex>
        )}
        <>
          {!below780 && (
            <Flex alignItems="center">
              <ClickableText
                color="textDim"
                onClick={() => {
                  setSortedColumn(SORT_FIELD.AMOUNT1)
                  setSortDirection(sortedColumn !== SORT_FIELD.AMOUNT1 ? true : !sortDirection)
                }}
              >
                {t('analytics.txn_list.token_amount', {
                  token: symbol1Override ? symbol1Override : t('analytics.token')
                })}
                {sortedColumn === SORT_FIELD.AMOUNT1 ? (sortDirection ? '↑' : '↓') : ''}
              </ClickableText>
            </Flex>
          )}
          {!below1080 && (
            <Flex alignItems="center">
              <TYPE.v2Main fontSize={12} color="t02">
                {t('analytics.account')}
              </TYPE.v2Main>
            </Flex>
          )}
          <Flex alignItems="center">
            <ClickableText
              color="textDim"
              onClick={() => {
                setSortedColumn(SORT_FIELD.TIMESTAMP)
                setSortDirection(sortedColumn !== SORT_FIELD.TIMESTAMP ? true : !sortDirection)
              }}
            >
              {t('analytics.txn_list.time')} {sortedColumn === SORT_FIELD.TIMESTAMP ? (!sortDirection ? '↑' : '↓') : ''}
            </ClickableText>
          </Flex>
        </>
      </DashGrid>
      <List p={0}>
        {!filteredList ? (
          <LocalLoader show={true} />
        ) : filteredList.length === 0 ? (
          <EmptyCard>{t('analytics.no_results')}</EmptyCard>
        ) : (
          filteredList.map((item, index) => {
            return (
              <div key={index}>
                <ListItem key={item.hash} item={item} />
              </div>
            )
          })
        )}
      </List>
      <PageButtons>
        <div
          onClick={e => {
            setPage(page === 1 ? page : page - 1)
          }}
        >
          <Arrow faded={page === 1 ? true : false}>
            <i className="iconfont icon-page-prev" />
          </Arrow>
        </div>
        <TYPE.body>{t('analytics.page_of', { current: page, total: maxPage })}</TYPE.body>
        <div
          onClick={e => {
            setPage(page === maxPage ? page : page + 1)
          }}
        >
          <Arrow faded={page === maxPage ? true : false}>
            <i className="iconfont icon-page-next" />
          </Arrow>
        </div>
      </PageButtons>
    </>
  )
}

export default TxnList
