import { ChainId } from '@netswap/sdk'
import ABI from './abi.json'

export const FarmLensConfig = {
  [ChainId.MAINNET]: {
    address: '0x8D8be2B72515680F6C31dc5D9a3fB30f1366Df60',
    abi: ABI,
  },
  [ChainId.TESTNET]: {
    address: '0x7c2551A7c6f415937B462d1B14077C5750a54F2e',
    abi: ABI,
  },
  // need config
  [ChainId.TESTNET_SEPOLIA]: {
    address: '0x7c2551A7c6f415937B462d1B14077C5750a54F2e',
    abi: ABI,
  },
}
