import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDarkModeManager } from 'state/user/hooks'
import Numeral from 'numeral'

import Container from '@material-ui/core/Container'
import { TYPE } from 'theme'
import { ButtonV2 } from 'components/Button'
import BuildingBlockSrc from 'assets/images/landing_page/building_block.png'
import NeboJumpingLightSrc from 'assets/images/landing_page/nebo_jumping.light.png'
import NeboJumpingDarkSrc from 'assets/images/landing_page/nebo_jumping.dark.png'

import useGetLiquitidyPools from 'state/data/hooks/LiquidityPool/useGetPoolList'
import useGetFarmLpPools from 'state/data/hooks/useGetFarmLpPools'
import useGetFarmAssetsPool from 'state/data/hooks/useGetFarmAssetsPools'
import { PairWithApr } from 'gql/subgraph/pairs'
import { FarmLPPool } from 'gql/microservices/farm-lp-pools'
import { AssetsPool, Period } from 'gql/microservices/assets-pools'
import { useMedia } from 'react-use'

enum EarnType {
  lp = 'liquidity',
  farm = 'farm',
  stake = 'stake'
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 23px 0 300px 0;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-bottom: 140px;
  `}
`

const Title = styled(TYPE.title)`
  font-size: 42px;
  line-height: 56px;
  text-align: center;
  padding: 0 10%;
  margin: 0 auto 12px auto !important;
  max-width: 920px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 20px;
    line-height: 28px;
  `}
`

const Description = styled(TYPE.main)`
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  max-width: 700px;
  margin: 0 auto 26px auto !important;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
    line-height: 16px;
  `}
`

const PartTitle = styled(TYPE.main)`
  font-size: 20px;
  line-height: 29px;
  margin-bottom: 40px !important;

  > span {
    background: ${({ theme }) => theme.v2.t06};
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-size: 32px;
    font-weight: 600;
    font-family: 'Poppins-Bold';
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
    > span {
      font-size: 20px;
    }
  `}
`

const Pannel = styled.div`
  width: 100%;
  padding: 50px 46px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => (theme.isDark ? '#2d2d4e' : '#8d98f9')};
  border-radius: 16px;
  max-width: 1082px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    padding: 23px 16px;
  `}
`

const ItemGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex: none;
    width: 50%;
    margin-bottom: 20px;
  `}
`

const ItemTitle = styled(TYPE.title)`
  color: #ffffff;
  font-size: 20px;
  line-height: 32px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `}
`

const ItemApr = styled(TYPE.title)`
  color: #ffffff;
  font-size 24px;
  line-height: 32px;
  margin-top: 6px !important;

  > span {
    color: ${({ theme }) => (theme.isDark ? '#a2a2a8' : '#383e77')};
    font-size: 16px;
    margin-left: 2px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    margin-top: 0 !important;

    > span {
      font-size: 12px;
    }
  `}
`

const ItemVol = styled(TYPE.mainLg)`
  color: ${({ theme }) => theme.v2.t09};
  font-size: 18px;
  line-height: 32px;
  margin-top: 4px !important;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
    line-height: 20px;
  `}
`

const ToggleBtn = styled(ButtonV2)`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  box-shadow: 0px 4px 0px 0px #171717;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  > .iconfont {
    font-size: 24px;
  }
`

const EnterBtn = styled(ButtonV2)`
  width: auto;
  height: 48px;
  padding: 0 24px;
  border-radius: 16px;
  box-shadow: 0px 4px 0px 0px #171717;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
`

const BuildingBlockImg = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-20%, 80%);
  height: 152px;
  width: auto;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 52px;
  `}
`

const NeboJumpingImg = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(60%, 66%);
  height: 326px;
  width: auto;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 118px;
    top: -80px;
    right: -45px;
    transform: translate(0, 0);
  `}
`

export default function Earn() {
  const { t } = useTranslation()
  const history = useHistory()
  const [earnType, setEarnType] = useState<EarnType>(EarnType.lp)
  const [darkMode] = useDarkModeManager()

  const [visiablePairs, setVisiablePairs] = useState<PairWithApr[]>()
  const [visiableFarmPools, setVisiableFarmPools] = useState<FarmLPPool[]>()
  const [visiableStakePools, setVisiableStakePools] = useState<AssetsPool[] | undefined>()

  const { pools: lpPools } = useGetLiquitidyPools()
  const farmPools = useGetFarmLpPools()
  const stakePools = useGetFarmAssetsPool()

  const isMobile = useMedia('(max-width: 780px)')

  const swichEarnType = useCallback(() => {
    if (earnType === EarnType.lp) {
      setEarnType(EarnType.farm)
    } else if (earnType === EarnType.farm) {
      if (visiableStakePools) {
        setEarnType(EarnType.stake)
      } else {
        setEarnType(EarnType.lp)
      }
    } else {
      setEarnType(EarnType.lp)
    }
  }, [earnType, visiableStakePools])

  const handleEnter = useCallback(() => {
    if (earnType === EarnType.lp) {
      history.push('/pool')
    } else if (earnType === EarnType.farm) {
      history.push('/farm')
    } else {
      history.push('/stake')
    }
  }, [earnType, history])

  useEffect(() => {
    if (lpPools) {
      const sortedPools = [...lpPools].sort((pair1, pair2) => pair2.lpApr - pair1.lpApr)
      setVisiablePairs(sortedPools.slice(0, 4))
    }
  }, [lpPools])

  useEffect(() => {
    if (farmPools) {
      const sortedFarmPools = [...farmPools].sort((p1, p2) => p2.nettApy - p1.nettApy)

      setVisiableFarmPools(sortedFarmPools.slice(0, 4))
    }
  }, [farmPools])

  useEffect(() => {
    if (stakePools) {
      const sortedStakePools = [...stakePools]
        .filter(item => item.period === Period.ongoing)
        .sort((p1, p2) => p2.apy - p1.apy)

      setVisiableStakePools(sortedStakePools.length > 0 ? sortedStakePools.slice(0, 1) : undefined)
    }
  }, [stakePools])

  return (
    <Container maxWidth="lg">
      <Wrapper>
        <Title>{t('home.earn.title')}</Title>

        <Description>{t('home.earn.description')}</Description>

        <PartTitle
          dangerouslySetInnerHTML={{
            __html: t(`home.earn.join_${earnType}`)
          }}
        />

        <Pannel>
          <ToggleBtn onClick={swichEarnType}>
            <i className="iconfont icon-switch" />
          </ToggleBtn>
          {!isMobile && (
            <EnterBtn onClick={handleEnter}>
              {earnType === EarnType.lp && t('home.earn.join_liquidity_btn')}

              {earnType === EarnType.farm && t('home.earn.join_farm_btn')}

              {earnType === EarnType.stake &&
                t('home.earn.join_stake_btn', {
                  token: visiableStakePools ? visiableStakePools[0].rewardToken.symbol : ''
                })}
            </EnterBtn>
          )}

          <BuildingBlockImg src={BuildingBlockSrc} alt="" />

          <NeboJumpingImg src={darkMode ? NeboJumpingDarkSrc : NeboJumpingLightSrc} alt="" />

          {earnType === EarnType.lp &&
            visiablePairs &&
            visiablePairs.map(pair => {
              return (
                <ItemGroup key={pair.id}>
                  <ItemTitle>{`${pair.token0.symbol} - ${pair.token1.symbol}`}</ItemTitle>

                  <ItemApr>
                    {Numeral(pair.lpApr / 100).format('0,0.[00]%')} <span>APR</span>
                  </ItemApr>

                  <ItemVol>{Numeral(pair.reserveUSD).format('$0,0')}</ItemVol>
                </ItemGroup>
              )
            })}

          {earnType === EarnType.farm &&
            visiableFarmPools &&
            visiableFarmPools.map(farm => {
              return (
                <ItemGroup key={farm.id}>
                  <ItemTitle>{`${farm.pair.token0.symbol} - ${farm.pair.token1.symbol}`}</ItemTitle>

                  <ItemApr>
                    {Numeral(farm.nettApy / 100).format('0,0.[00]%')} <span>APR</span>
                  </ItemApr>

                  <ItemVol>{Numeral(farm.totalDepositVolumeUSD).format('$0,0')}</ItemVol>
                </ItemGroup>
              )
            })}

          {earnType === EarnType.stake &&
            visiableStakePools &&
            visiableStakePools.map(pool => {
              return (
                <ItemGroup key={pool.id}>
                  <ItemTitle>NETT</ItemTitle>

                  <ItemApr>
                    {Numeral(pool.apy / 100).format('0,0.[00]%')} <span>APR</span>
                  </ItemApr>

                  <ItemVol>{Numeral(pool.tvl).format('$0,0')}</ItemVol>
                </ItemGroup>
              )
            })}

          {/* <ItemGroup>
            <ItemTitle>WETH-m.USDC LP</ItemTitle>

            <ItemApr>
              7.5% <span>APR</span>
            </ItemApr>

            <ItemVol>$192,323,401</ItemVol>
          </ItemGroup> */}
        </Pannel>
      </Wrapper>
    </Container>
  )
}
