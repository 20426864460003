/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Collapse, InputBase } from '@material-ui/core'
import Modal from 'components/CustomModal/modal'
import TokenImage from 'components/TokenImage'
import { FarmLPPool } from 'gql/microservices/farm-lp-pools'
import { Config as NettConfig } from 'constants/tokens/nett'
import { ChainId } from '@netswap/sdk'
import BigNumber from 'bignumber.js'
import { useActiveWeb3React } from 'hooks'
import { useContractByAbiAddr } from 'hooks/contract/useContract'
import useTokenApprove from 'hooks/contract/useTokenApprove'

import useSend from 'state/contract/hooks/useSend'
import { ERC20_ABI } from 'constants/abis/erc20'
import { Contract } from 'ethers'
import { isMobile } from 'react-device-detect'
import { TYPE } from 'theme'
import { ButtonMetis, ButtonV2 } from 'components/Button'
import { useFormatSymbolTokenPrice } from 'state/data/hooks/useTokenPrice'
import moment from 'moment'
import { useUpdateUserFarmLPPool } from 'state/data/hooks/useGetUserFarmPools'
import { useWalletModalToggle } from 'state/application/hooks'
import { RewarderABI } from 'constants/farm'
import { AutoColumn } from 'components/Column'
import Numeral from 'numeral'
import { RowBetween } from 'components/Row'
import classNames from 'classnames'
import TooltipInfo from 'components/TooltipInfo'

const InfoDefault = styled.div`
  display: flex;
  justify-content: flex-start;
`
const PoolInfo = styled(InfoDefault)`
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  div {
    &:first-child {
      z-index: 1;
    }
    &:last-child {
      z-index: 0;
      margin-left: -10px;
    }
    img {
      width: 28px;
      height: 28px;
      border-radius: 50%;
    }
  }
`
const RewardsInfo = styled(InfoDefault)`
  flex-flow: column wrap;
`
const RewardsInfoCon = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 6px;
  `}
  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
`

const InfoLink = styled(NavLink)`
  margin-left: 0 !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.v2.t05};
  text-decoration: none;
`

const InputWrapper = styled.div`
  border-radius: 8px;
  background: ${({ theme }) => theme.v2.bg02};
  padding: 8px 12px;
  display: flex;
  box-sizing: border-box;
  height: 46px;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &.card {
    background: ${({ theme }) => theme.v2.bg04};
  }

  input {
    color: ${({ theme }) => theme.v2.t01};
    ::placeholder {
      color: ${({ theme }) => theme.v2.t02};
    }
  }
`
const BtnDefault = styled(ButtonMetis)`
  cursor: pointer;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 400;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
    height: 48px;
  `}
`
const MaxBtn = styled(BtnDefault)`
  width: 50px;

  height: 32px;
  padding: 0;
  font-size: 14px;
  color: ${({ theme }) => theme.v2.t01};
  line-height: 20px;
  background: ${({ theme }) => theme.v2.bg04};

  border-radius: 12px;

  &.card {
    background: ${({ theme }) => theme.v2.bg02};
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 28px;
    line-height: 28px;
    width: 105px;
    border-radius: 8px;
  `}
`

const BalanceButton = styled(ButtonV2)`
  width: 100%;

  height: 40px;
  border-radius: 10px;
`
const HarvestBtn = styled(ButtonV2)`
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  line-height: 36px;
  border-radius: 10px;
  flex-shrink: 0;
  margin-top: 12px;
`

const StakeModalWrapper = styled.div`
  padding: 20px 12px;
  max-width: 560px;
  display: flex;
  flex-flow: column wrap;
  text-align: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 100%;
    > div {
      width: 100%;
    }
    h3 {
      font-size: 1.6rem !important;
      line-height: 2rem;
    }
  `};
  h2,
  h3 {
    margin: 0;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }
  h2 {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 16px;
  }
  h3 {
    font-size: 40px;
    line-height: 56px;
  }
  span {
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
    line-height: 20px;
  }
`
const StakeModalCon = styled.div`
  margin-top: 48px;
  h3 {
    word-break: break-all;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 24px;
  `};
`
const StakeModalNote = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  line-height: 20px;
  margin: 40px 0;
  text-align: left;
`
const StakeClaimBtn = styled.button`
  width: 100%;
  height: 64px;
  background: #c42a61;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.text1};
  line-height: 64px;
  border: none;
  max-width: 420px;
  cursor: pointer;
  margin: 0 auto;
`

const Input = styled(InputBase)`
  input {
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`

const EndTimeFlag = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  font-size: 10px;
  padding: 0 8px;

  height: 24px;
  background: ${({ theme }) => theme.v2.bg04};
  display: flex;
  align-items: center;

  border-radius: 0px 4px 0px 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const Wrapper = styled.div`
  /* display: flex; */
  /* align-items: center; */
  background: ${({ theme }) => theme.v2.bg02};
  border-radius: 12px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 24px;

  &.open {
    border-radius: 12px 12px 0 0;
  }

  &.card {
    box-sizing: border-box;
    padding: 14px;
  }
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 14px;
  cursor: pointer;

  height: 120px;

  &.hide {
    display: none;
  }
`

const Column = styled.div`
  flex: 0;
  flex-basis: 19%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  padding: 8px;
  height: 100%;

  > div {
    width: 100%;
  }
`

const Apy = styled(AutoColumn)`
  img {
    margin-right: 8px;
  }

  &.card {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    > div {
      width: fit-content;
      flex-direction: row-reverse;

      img {
        margin-right: 0;
        margin-left: 8px;
      }
    }
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`

const StyledCollapse = styled(Collapse)`
  width: 100%;
  background: ${({ theme }) => theme.v2.bg04};
  border-radius: 0 0 12px 12px;
  /* box-sizing: border-box; */
  /* padding: 16px 20px; */
`

const DetailWraper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  padding: 14px 20px;

  height: 210px;
`

const StyledAutoColumn = styled(AutoColumn)`
  height: 100%;

  &.inputColumn {
    width: 267px;
  }

  &.rewardColumn {
    flex-grow: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.v2.bg02};

    border-radius: 8px;
    padding: 8px 16px;
  }
`

const StyledRightArrow = styled.i`
  &.iconfont {
    font-size: 40px;
    margin: 0 24px;
  }
`

const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.v2.bg02};

  &.card {
    background-color: ${({ theme }) => theme.v2.bg04};
  }
  width: 144px;
  height: 24px;
  border-radius: 8px;
`

const Tab = styled.div`
  width: 50%;
  border-radius: 8px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.v2.t01};
  font-size: 12px;
  height: 100%;
  cursor: pointer;

  font-weight: 500;
  &.active {
    background-color: #d2cfd5;
    color: #000;
  }
`

const EarnColumn = styled.div`
  &:first-child {
    width: 60%;
  }
  width: 40%;
`

const ColumnMore = styled.i`
  &.iconfont {
    font-size: 24px;
  }

  transition: transform 0.2s linear;

  &.open {
    transform: rotate(-180deg);
  }
`

const Card = styled.div`
  &.hide {
    display: none;
  }
`

const CardEarnWrapper = styled.div`
  background-color: ${({ theme }) => theme.v2.bg04};
  border-radius: 8px;
  padding: 8px;
`

interface Props {
  data: FarmLPPool
  stakeContract: Contract | null
  align: string
}

enum RewarderStatus {
  END = 'END',
  STOP = 'STOP',
  START = 'START',
  UNSET = 'UNSET'
}

const nettAddress = NettConfig[ChainId.MAINNET].address

export default function PoolItem({ data, stakeContract, align = 'list' }: Props) {
  const tokenPrice = useFormatSymbolTokenPrice()
  const [open, setOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const { account } = useActiveWeb3React()
  const [stakeInput, setStakeInput] = useState('')
  const [withdrawInput, setWithdrawInput] = useState('')
  const lpTokenContract = useContractByAbiAddr(data.pair.id, ERC20_ABI)
  const rewarderContract = useContractByAbiAddr(data.rewarder?.id || '', RewarderABI)
  const [userStakeTokenBalance, setUserStakeTokenBalance] = useState(new BigNumber(0))
  const [stakeBalance, setStakeBalance] = useState(new BigNumber(0))
  const [rewardNettBalance, setRewardNettBalance] = useState(new BigNumber(0))
  // 2x reward token balance
  const [stakePendingBonusTokenAmount, setStakePendingBonusAmount] = useState(new BigNumber(0))
  const [rewarderUnPaidAmount, setRewarderUnPaidAmount] = useState(new BigNumber(0))
  const [isStake, setIsStake] = useState(true)

  const rewarderStatus = useMemo(() => {
    if (data.rewarder?.endTimestamp) {
      if (data.rewarder.endTimestamp === -1) {
        return RewarderStatus.END
      }
      const now = new Date().getTime()
      return now >= data.rewarder?.endTimestamp * 1000 ? RewarderStatus.END : RewarderStatus.START
    }

    return RewarderStatus.UNSET
  }, [data.rewarder])

  const rewardTokenBalance = useMemo(() => {
    if (rewarderStatus !== RewarderStatus.END && stakePendingBonusTokenAmount.isGreaterThan(rewarderUnPaidAmount)) {
      return stakePendingBonusTokenAmount.minus(rewarderUnPaidAmount)
    }

    return new BigNumber(0)
  }, [rewarderUnPaidAmount, stakePendingBonusTokenAmount, rewarderStatus])

  const send = useSend()
  const updateUserPool = useUpdateUserFarmLPPool()
  const toggleWalletModal = useWalletModalToggle()
  const stakeInputAmount = useMemo(() => {
    return new BigNumber(stakeInput || 0)
  }, [stakeInput])

  const withdrawInputAmount = useMemo(() => {
    return new BigNumber(withdrawInput || 0)
  }, [withdrawInput])
  const { approved, approve } = useTokenApprove(
    {
      address: data.pair.id,
      decimals: 18,
      symbol: 'NLP'
    },
    stakeContract?.address || '',
    stakeInputAmount
  )
  const handleModalClose = useCallback(() => {
    setModalOpen(false)
  }, [])

  const hideNett = useMemo(() => {
    return false
  }, [data.allocPoint])

  const isStakeInsufficienBalance = useMemo(() => {
    return stakeInputAmount.isGreaterThan(userStakeTokenBalance)
  }, [stakeInputAmount, userStakeTokenBalance])

  const stakeButtonDisable = useMemo(() => {
    // 1x reward nett pool and allocPoint = 0, disabled
    return !stakeInputAmount.toNumber() || isStakeInsufficienBalance || (hideNett && !data.rewarder)
  }, [stakeInputAmount, isStakeInsufficienBalance, hideNett, data.rewarder])

  const isWithdrawInsufficienBalance = useMemo(() => {
    return withdrawInputAmount.isGreaterThan(stakeBalance)
  }, [withdrawInputAmount, stakeBalance])

  const withdrawButtonDisable = useMemo(() => {
    return !withdrawInputAmount.toNumber() || isWithdrawInsufficienBalance
  }, [withdrawInputAmount, isWithdrawInsufficienBalance])

  const harvestButtonDisable = useMemo(() => {
    return !rewardNettBalance.toNumber() && !rewardTokenBalance.toNumber()
  }, [rewardNettBalance, rewardTokenBalance])

  const rewardTokenPrice = useMemo(() => {
    if (tokenPrice && data.rewarder?.rewardToken.address) {
      const addr = data.rewarder.rewardToken.address.toLowerCase()
      return tokenPrice[addr] ? tokenPrice[addr].price : 0
    }
    return 0
  }, [data.rewarder, tokenPrice])

  const lpPrice = useMemo(() => {
    return new BigNumber(data.pair.reserveUSD).dividedBy(data.pair.totalSupply).toNumber()
  }, [data])

  const stakePairReserveUSD = useMemo(() => {
    return new BigNumber(stakeBalance).multipliedBy(lpPrice).toFixed(3)
  }, [stakeBalance, lpPrice])

  const pairBalanceUSD = useMemo(() => {
    return new BigNumber(userStakeTokenBalance).multipliedBy(lpPrice).toFixed(3)
  }, [userStakeTokenBalance, lpPrice])

  const endTimeStr = useMemo(() => {
    if (data.rewarder?.endTimestamp) {
      return moment.utc(data.rewarder.endTimestamp * 1000).format('YYYY-MM-DD')
    }

    return '-'
  }, [data.rewarder])

  async function getBalance(contract: any) {
    if (contract && account) {
      try {
        const res = await contract.balanceOf(account)
        return res
      } catch (e) {
        console.error(e)
      }
    }
  }

  async function getStakeTokenBalance() {
    if (lpTokenContract) {
      const res = await getBalance(lpTokenContract)

      setUserStakeTokenBalance(res?.toString() ? new BigNumber(res.toString()).shiftedBy(-18) : new BigNumber(0))
    }
  }

  async function getStakeBalance() {
    if (stakeContract && account) {
      try {
        const res = await stakeContract.userInfo(data.id, account)
        setStakeBalance(res?.amount.toString() ? new BigNumber(res.amount.toString()).shiftedBy(-18) : new BigNumber(0))
      } catch (e) {
        console.error(e)
      }
    }
  }

  async function getRewardBalance() {
    if (stakeContract && account) {
      try {
        const res = await stakeContract.pendingTokens(data.id, account)

        if (res) {
          const rewardNettRes = res.pendingNETT?.toString()
            ? new BigNumber(res.pendingNETT.toString()).shiftedBy(-18)
            : new BigNumber(0)
          setRewardNettBalance(rewardNettRes)

          if (data.rewarder && rewarderContract) {
            const pendingBonusAmount = res.pendingBonusToken?.toString()
              ? new BigNumber(res.pendingBonusToken.toString()).shiftedBy(-data.rewarder.rewardToken.decimals)
              : new BigNumber(0)
            setStakePendingBonusAmount(pendingBonusAmount)

            const userInfo = await rewarderContract.userInfo(account)
            if (userInfo && userInfo.unpaidRewards?.toString()) {
              setRewarderUnPaidAmount(
                new BigNumber(userInfo.unpaidRewards.toString()).shiftedBy(-data.rewarder.rewardToken.decimals)
              )
            }
          }
        }
      } catch (e) {
        console.error(e)
      }
    }
    return new BigNumber(0)
  }

  useEffect(() => {
    let interval: any
    if (stakeContract && account) {
      getStakeTokenBalance()
      getRewardBalance()

      interval = setInterval(() => {
        getStakeTokenBalance()
        getRewardBalance()
      }, 15000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [stakeContract, account])

  useEffect(() => {
    getStakeBalance()
  }, [stakeContract, account])

  async function stake() {
    await send({
      contract: stakeContract,
      method: 'deposit',
      params: [data.id, stakeInputAmount.shiftedBy(18).toFixed()],
      pendingText: `Staking ${stakeInputAmount.toFixed()} ${lpName} NLP`,
      summary: `Staked ${stakeInputAmount.toFixed()} ${lpName} NLP`,
      onSuccess() {
        if (!stakeBalance.toNumber()) {
          updateUserPool({
            pids: [data.id],
            method: 'add'
          })
        }
        getStakeTokenBalance()
        getStakeBalance()
        getRewardBalance()
        setStakeInput('')
      }
    })
  }

  async function withdraw(isHarvest: boolean = false) {
    if (stakeBalance.toNumber()) {
      send({
        contract: stakeContract,
        method: 'withdraw',
        params: [data.id, isHarvest ? 0 : withdrawInputAmount.shiftedBy(18).toFixed()],
        pendingText: isHarvest
          ? `Harvesting ${lpName} NLP pool rewards`
          : `UnStaking ${withdrawInputAmount.toFixed()} ${lpName} NLP`,
        summary: isHarvest
          ? `Harvested ${lpName} NLP pool rewards`
          : `UnStaked ${withdrawInputAmount.toFixed()} ${lpName} NLP`,
        onSuccess() {
          if (!isHarvest && withdrawInputAmount.isEqualTo(stakeBalance)) {
            updateUserPool({
              pids: [data.id],
              method: 'remove'
            })
          }
          getStakeTokenBalance()
          getStakeBalance()
          getRewardBalance()
          handleModalClose()
          setWithdrawInput('')
        }
      })
    }
  }

  const rewardTokenPerDay = useMemo(() => {
    if (data.rewarder) {
      return new BigNumber(data.rewarder.tokenPerSec).multipliedBy(86400).toNumber()
    }
    return 0
  }, [data.rewarder])

  const rewardNettPerDay = useMemo(() => {
    if (data.nettPerSec) {
      return new BigNumber(data.nettPerSec || 0).multipliedBy(86400).toNumber()
    }
    return 0
  }, [data.nettPerSec])

  const lpName = useMemo(() => {
    return `${data.pair.token0.symbol} - ${data.pair.token1.symbol}`
  }, [data.pair])

  const rewardsUSD = useMemo(() => {
    if (data.rewarder) {
      return new BigNumber(tokenPrice?.nett?.price || 0)
        .multipliedBy(rewardNettBalance)
        .plus(new BigNumber(rewardTokenPrice || 0).multipliedBy(rewardTokenBalance))
        .toFixed(3)
    } else {
      return new BigNumber(rewardNettBalance).multipliedBy(tokenPrice?.nett?.price || 0).toNumber()
    }
  }, [rewardTokenPrice, tokenPrice.nett, rewardNettBalance, rewardTokenBalance, data.rewarder])

  const rewardEndStr = useMemo(() => {
    let prefix = ''

    if (data.rewarder) {
      if (!data.allocPoint || rewarderStatus === RewarderStatus.END) {
        if (!data.allocPoint && rewarderStatus === RewarderStatus.END) {
          prefix = 'All'
        } else if (!data.allocPoint) {
          prefix = 'NETT'
        } else {
          prefix = data.rewarder?.rewardToken.symbol || ''
        }
        return rewarderStatus !== RewarderStatus.START ? `${prefix} Rewards Ended` : `Ends on ${endTimeStr}`
      }
    } else if (!data.allocPoint) {
      return 'NETT Rewards Ended'
    }

    return ''
  }, [rewarderStatus, data.allocPoint, data.rewarder])

  const isRewarderStopped = useMemo(() => {
    return rewarderStatus === RewarderStatus.END
  }, [rewarderStatus])

  const tokenPerDay = (
    <RewardsInfo>
      {!hideNett && (
        <RewardsInfoCon>
          <TokenImage address={nettAddress} />
          &nbsp;
          {Numeral(rewardNettPerDay).format('0')}
          &nbsp;NETT/DAY
        </RewardsInfoCon>
      )}

      {data.rewarder && (
        <RewardsInfoCon style={{ marginTop: !hideNett ? '4px' : '0' }}>
          <TokenImage address={data.rewarder.rewardToken.address} />
          &nbsp;
          {Numeral(isRewarderStopped ? 0 : rewardTokenPerDay).format('0')}
          &nbsp;{data.rewarder.rewardToken.symbol}/DAY
        </RewardsInfoCon>
      )}
    </RewardsInfo>
  )

  const apy = (
    <>
      {!hideNett && (
        <RowBetween style={{ justifyContent: 'flex-start' }}>
          <TokenImage address={nettAddress} size="20px !important" />
          <TYPE.v2Main fontSize={16}>{Numeral(data.nettApy).format('0.[00]')} %</TYPE.v2Main>
        </RowBetween>
      )}
      {data.rewarder && (
        <RowBetween style={{ justifyContent: 'flex-start', marginTop: !hideNett ? '4px' : '0px' }}>
          <TokenImage address={data.rewarder.rewardToken.address} size="20px !important" />{' '}
          <TYPE.v2Main fontSize={16}>
            {Numeral(isRewarderStopped ? 0 : data.rewarderApy).format('0.[00] %')}
          </TYPE.v2Main>
        </RowBetween>
      )}
      {/* <p>
        <OutLink href={`https://info.netswap.io/pair/${data.pair.id}`}>
          +&nbsp;&nbsp;
          <Value value={data.lpApy} />
          %(LP)
        </OutLink>
      </p> */}
    </>
  )

  const stakeAndUnStakeButtonText = useMemo(() => {
    if (!account) {
      return 'Connect Wallet'
    }

    if (isStake) {
      if (isStakeInsufficienBalance) {
        return 'Insfficient LP balance'
      }

      if (!approved) {
        return 'Approve'
      }

      return 'Stake'
    } else {
      if (isWithdrawInsufficienBalance) {
        return 'Insfficient staked LP balance'
      }

      return 'Unstake'
    }
  }, [account, approved, isStakeInsufficienBalance, isStake, isWithdrawInsufficienBalance])

  const stakeAndUnstakeButtonDisabled = useMemo(() => {
    if (!account) return false
    if (isStake) {
      return stakeButtonDisable
    } else {
      return withdrawButtonDisable
    }
  }, [account, isStake, stakeButtonDisable, withdrawButtonDisable])

  function stakeAnUnstake() {
    if (!account) {
      toggleWalletModal()
      return
    }

    if (isStake) {
      if (!approved) {
        approve()
      } else {
        stake()
      }
    } else {
      withdraw()
    }
  }

  function handleMax() {
    if (isStake) {
      setStakeInput(userStakeTokenBalance.toFixed())
    } else {
      setWithdrawInput(stakeBalance.toFixed())
    }
  }

  const isCard = align !== 'list'

  return (
    <>
      <Wrapper id={data.id} className={isCard ? 'card' : ''}>
        <Row onClick={() => setOpen(!open)} className={isCard ? 'hide' : ''}>
          <Column style={{ flexBasis: '24%' }}>
            <TitleWrapper>
              <PoolInfo>
                <TokenImage size="24px" address={data.pair.token0.address} />
                <TokenImage size="24px" address={data.pair.token1.address} />
              </PoolInfo>
              <AutoColumn gap="md">
                <TYPE.desc fontSize={14}>Stake LP</TYPE.desc>
                <TYPE.v2Main fontSize={16}>
                  {lpName}
                  <br />
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TYPE.desc fontSize={12}>+{data.lpApy} %</TYPE.desc>
                    <TooltipInfo text="This is the APR of earning 0.25% fees for LP. Based on last 24hrs performance" />
                  </div>
                </TYPE.v2Main>
              </AutoColumn>
            </TitleWrapper>
          </Column>
          <Column style={{ flexBasis: '24%' }}>
            <AutoColumn gap="md">
              <TYPE.desc fontSize={14} style={{ display: 'flex', alignItems: 'center' }}>
                TVL <TooltipInfo text="Total value of the funds in this farm’s liquidity pool" />
              </TYPE.desc>
              <TYPE.v2Main fontSize={16}>{Numeral(data.totalDepositVolumeUSD).format('$0,0')}</TYPE.v2Main>
            </AutoColumn>
          </Column>
          <Column style={{ flexBasis: '24%' }}>
            <AutoColumn gap="md">
              <TYPE.desc fontSize={14} style={{ display: 'flex', alignItems: 'center' }}>
                APR{' '}
                <TooltipInfo text="Reward APRs are calcuated in real time. Dex swap fee APRs are calculated based on previous 24 hours of trading volume. APRs are provided for your convenience. APRs are constantly changing and do not represent guaranteed returns." />
              </TYPE.desc>
              <Apy>{apy}</Apy>
            </AutoColumn>
          </Column>
          <Column style={{ flexBasis: '24%' }}>
            <AutoColumn gap="md">
              <TYPE.desc fontSize={14}>Rewards in</TYPE.desc>
              {tokenPerDay}
            </AutoColumn>
          </Column>
          <Column style={{ flexBasis: '4%', justifyContent: 'center', alignItems: 'center' }}>
            <ColumnMore className={classNames('iconfont icon-arrow-down', open && 'open')} />
          </Column>
          {rewardEndStr && <EndTimeFlag>{rewardEndStr}</EndTimeFlag>}
        </Row>
        <StyledCollapse in={open} timeout="auto">
          <DetailWraper>
            <StyledAutoColumn>
              <TYPE.v2Main fontSize={14}>Available Balance</TYPE.v2Main>
              <TYPE.v2Main color={stakeBalance.isEqualTo(0) && userStakeTokenBalance.isEqualTo(0) ? 't07' : 't09'}>
                {userStakeTokenBalance.isEqualTo(0) ? '' : '≈'}
                {userStakeTokenBalance.toFixed(3)}{' '}
                <TooltipInfo
                  text={
                    <>
                      <RowBetween style={{ justifyContent: 'flex-start' }}>
                        <TokenImage size="20px" address={data.pair.token0.address} />
                        <TYPE.v2Main marginLeft="10px">{data.pair.token0.symbol}</TYPE.v2Main>
                      </RowBetween>
                      <RowBetween style={{ justifyContent: 'flex-start', marginTop: '6px' }}>
                        <TokenImage size="20px" address={data.pair.token1.address} />
                        <TYPE.v2Main marginLeft="10px">{data.pair.token1.symbol}</TYPE.v2Main>
                      </RowBetween>
                    </>
                  }
                >
                  LP
                </TooltipInfo>
                <br /> <TYPE.desc>≈ ${pairBalanceUSD}</TYPE.desc>
              </TYPE.v2Main>
              {stakeBalance.isEqualTo(0) && userStakeTokenBalance.isEqualTo(0) && (
                <TYPE.v2Main fontSize={12} marginTop="6px">
                  Not have this LP?
                </TYPE.v2Main>
              )}
              <InfoLink to={`/add/${data.pair.token0.address}/${data.pair.token1.address}`}>
                {`Get ${lpName} NLP >`}
              </InfoLink>

              {stakeBalance.isGreaterThan(0) && (
                <div>
                  <TYPE.v2Main fontSize={14} marginTop="24px">
                    Your Staked
                  </TYPE.v2Main>
                  <TYPE.v2Main color={'t09'}>
                    ≈ {stakeBalance.toFixed(3)} LP
                    <br /> <TYPE.desc>≈ ${stakePairReserveUSD}</TYPE.desc>
                  </TYPE.v2Main>
                </div>
              )}
            </StyledAutoColumn>
            <StyledRightArrow className="iconfont icon-point-arrow-right" />

            <StyledAutoColumn justify="flex-start" className="inputColumn">
              <RowBetween
                style={{
                  justifyContent: stakeBalance.isEqualTo(0) ? 'flex-start' : 'space-between',
                  height: 'fit-content'
                }}
              >
                <TYPE.v2Main fontSize={14}>To Farm</TYPE.v2Main>
                {stakeBalance.isGreaterThan(0) && (
                  <TabWrapper>
                    <Tab className={isStake ? 'active' : ''} onClick={() => setIsStake(true)}>
                      Stake
                    </Tab>
                    <Tab className={!isStake ? 'active' : ''} onClick={() => setIsStake(false)}>
                      Unstake
                    </Tab>
                  </TabWrapper>
                )}
              </RowBetween>
              <InputWrapper>
                <Input
                  fullWidth
                  autoFocus={!isMobile}
                  placeholder="0.0"
                  type="number"
                  value={isStake ? stakeInput : withdrawInput}
                  onChange={e => {
                    isStake ? setStakeInput(e.currentTarget.value || '') : setWithdrawInput(e.currentTarget.value || '')
                  }}
                />
                <MaxBtn onClick={handleMax}>Max</MaxBtn>
              </InputWrapper>
              <BalanceButton disabled={stakeAndUnstakeButtonDisabled} onClick={stakeAnUnstake}>
                {stakeAndUnStakeButtonText}
              </BalanceButton>
            </StyledAutoColumn>
            <StyledRightArrow className="iconfont icon-point-arrow-right" />
            <StyledAutoColumn justify="flex-start" className="rewardColumn">
              <TYPE.v2Main>Earn</TYPE.v2Main>
              <RowBetween marginTop="8px">
                <EarnColumn>
                  <RowBetween style={{ justifyContent: 'flex-start', fontSize: '12px' }}>
                    {!hideNett && (
                      <>
                        <TokenImage address={nettAddress} size="16px" />
                        &nbsp;
                        {Numeral(rewardNettBalance.toNumber()).format('≈ 0,0.[000]')}
                        &nbsp;NETT
                      </>
                    )}
                  </RowBetween>
                  {data.rewarder && (
                    <RowBetween style={{ justifyContent: 'flex-start', fontSize: '12px', marginTop: '4px' }}>
                      <TokenImage address={data.rewarder.rewardToken.address} size="16px" />
                      &nbsp;
                      {Numeral(rewardTokenBalance.toNumber()).format('≈ 0,0.[000]')}
                      &nbsp;{data.rewarder.rewardToken.symbol}
                    </RowBetween>
                  )}
                </EarnColumn>
                <EarnColumn>
                  <TYPE.desc fontSize={14}>Value</TYPE.desc>
                  <TYPE.v2Main>≈ $ {Numeral(rewardsUSD).format(' 0,0.[000]')}</TYPE.v2Main>
                </EarnColumn>
              </RowBetween>
              <HarvestBtn disabled={harvestButtonDisable} onClick={() => withdraw(true)}>
                Harvest
              </HarvestBtn>
            </StyledAutoColumn>
          </DetailWraper>
        </StyledCollapse>
        <Card className={isCard ? '' : 'hide'}>
          <AutoColumn gap="md">
            <RowBetween>
              <PoolInfo>
                <TokenImage size="24px" address={data.pair.token0.address} />
                <TokenImage size="24px" address={data.pair.token1.address} />
              </PoolInfo>
              <TYPE.v2Main fontSize={16}>{lpName}</TYPE.v2Main>
            </RowBetween>
            <RowBetween style={{ alignItems: 'flex-start' }}>
              <TYPE.desc fontSize={14} style={{ display: 'flex', alignItems: 'center' }}>
                APY{' '}
                <TooltipInfo text="Reward APRs are calcuated in real time. Dex swap fee APRs are calculated based on previous 24 hours of trading volume. APRs are provided for your convenience. APRs are constantly changing and do not represent guaranteed returns." />
              </TYPE.desc>
              <Apy className="card">{apy}</Apy>
            </RowBetween>
            <RowBetween>
              <TYPE.desc fontSize={14} style={{ display: 'flex', alignItems: 'center' }}>
                TVL <TooltipInfo text="Total value of the funds in this farm’s liquidity pool" />
              </TYPE.desc>
              <TYPE.v2Main fontSize={16}>{Numeral(data.totalDepositVolumeUSD).format('$0,0')}</TYPE.v2Main>
            </RowBetween>
            <CardEarnWrapper>
              <RowBetween>
                <TYPE.v2Main>Earn</TYPE.v2Main>
                <TYPE.v2Main style={{ display: 'flex', alignItems: 'center' }}>
                  <TYPE.desc fontSize={14}>Value</TYPE.desc>&nbsp;≈ $ {Numeral(rewardsUSD).format(' 0,0.[000]')}
                </TYPE.v2Main>
              </RowBetween>
              <RowBetween marginTop="8px">
                <EarnColumn>
                  <RowBetween style={{ justifyContent: 'flex-start', fontSize: '12px' }}>
                    {!hideNett && (
                      <>
                        <TokenImage address={nettAddress} size="16px" />
                        &nbsp;
                        {Numeral(rewardNettBalance.toNumber()).format('≈ 0,0.[000]')}
                        &nbsp;NETT
                      </>
                    )}
                  </RowBetween>
                  {data.rewarder && (
                    <RowBetween style={{ justifyContent: 'flex-start', fontSize: '12px', marginTop: '4px' }}>
                      <TokenImage address={data.rewarder.rewardToken.address} size="16px" />
                      &nbsp;
                      {Numeral(rewardTokenBalance.toNumber()).format('≈ 0,0.[000]')}
                      &nbsp;{data.rewarder.rewardToken.symbol}
                    </RowBetween>
                  )}
                </EarnColumn>
                <HarvestBtn
                  style={{ marginTop: '0', padding: '0', width: '110px', height: '30px' }}
                  disabled={harvestButtonDisable}
                  onClick={() => withdraw(true)}
                >
                  Harvest
                </HarvestBtn>
              </RowBetween>
            </CardEarnWrapper>
            <RowBetween>
              <TYPE.v2Main fontSize={14}>To Farm</TYPE.v2Main>
              {stakeBalance.isGreaterThan(0) && (
                <TabWrapper className="card">
                  <Tab className={isStake ? 'active' : ''} onClick={() => setIsStake(true)}>
                    Stake
                  </Tab>
                  <Tab className={!isStake ? 'active' : ''} onClick={() => setIsStake(false)}>
                    Unstake
                  </Tab>
                </TabWrapper>
              )}
            </RowBetween>
            <InputWrapper className="card">
              <Input
                fullWidth
                autoFocus={!isMobile}
                placeholder="0.0"
                type="number"
                value={isStake ? stakeInput : withdrawInput}
                onChange={e => {
                  isStake ? setStakeInput(e.currentTarget.value || '') : setWithdrawInput(e.currentTarget.value || '')
                }}
              />
              <MaxBtn className="card" onClick={handleMax}>
                Max
              </MaxBtn>
            </InputWrapper>
            <BalanceButton disabled={stakeAndUnstakeButtonDisabled} onClick={stakeAnUnstake}>
              {stakeAndUnStakeButtonText}
            </BalanceButton>
            <RowBetween style={{ alignItems: 'flex-start' }}>
              <TYPE.v2Main fontSize={12}>
                Available Balance <br />{' '}
                <InfoLink to={`/add/${data.pair.token0.address}/${data.pair.token1.address}`}>
                  {`Get ${lpName} NLP >`}
                </InfoLink>
              </TYPE.v2Main>
              <TYPE.v2Main
                fontSize={12}
                color={stakeBalance.isEqualTo(0) && userStakeTokenBalance.isEqualTo(0) ? 't07' : 't09'}
              >
                {userStakeTokenBalance.isEqualTo(0) ? '' : '≈'}
                {userStakeTokenBalance.toFixed(3)}{' '}
                <TooltipInfo
                  text={
                    <>
                      <RowBetween style={{ justifyContent: 'flex-start' }}>
                        <TokenImage size="20px" address={data.pair.token0.address} />
                        <TYPE.v2Main marginLeft="10px">{data.pair.token0.symbol}</TYPE.v2Main>
                      </RowBetween>
                      <RowBetween style={{ justifyContent: 'flex-start', marginTop: '6px' }}>
                        <TokenImage size="20px" address={data.pair.token1.address} />
                        <TYPE.v2Main marginLeft="10px">{data.pair.token1.symbol}</TYPE.v2Main>
                      </RowBetween>
                    </>
                  }
                >
                  LP
                </TooltipInfo>
                <br /> <TYPE.desc fontSize={12}>≈ ${pairBalanceUSD}</TYPE.desc>
              </TYPE.v2Main>
            </RowBetween>

            {stakeBalance.isGreaterThan(0) && (
              <RowBetween style={{ alignItems: 'flex-start' }}>
                <TYPE.v2Main fontSize={12}>Your Staked</TYPE.v2Main>
                <TYPE.v2Main color={'t09'} fontSize={12}>
                  ≈ {stakeBalance.toFixed(3)} LP
                  <br /> <TYPE.desc>≈ ${stakePairReserveUSD}</TYPE.desc>
                </TYPE.v2Main>
              </RowBetween>
            )}
          </AutoColumn>
        </Card>
      </Wrapper>

      <Modal isOpen={modalOpen} onClose={handleModalClose}>
        <StakeModalWrapper>
          <h2>Withdraw</h2>
          <StakeModalCon>
            <h3>{withdrawInputAmount.toFixed()}</h3>
            <span>Withdraw {lpName} NLP</span>
          </StakeModalCon>
          <StakeModalCon>
            <h3>{rewardNettBalance.toFixed()}</h3>
            <span>Unclaimed NETT</span>
          </StakeModalCon>
          {data.rewarder && (
            <StakeModalCon>
              <h3>{rewardTokenBalance.toFixed()}</h3>
              <span>Unclaimed {data.rewarder.rewardToken.symbol}</span>
            </StakeModalCon>
          )}
          <StakeModalNote>
            When you withdraw,your NETT {data.rewarder ? `and ${data.rewarder.rewardToken.symbol}` : ''} is claimed and
            your {lpName} NLP is returned to you.{' '}
            {withdrawInputAmount.isEqualTo(stakeBalance)
              ? `You will no longer earn NETT ${data.rewarder ? `and ${data.rewarder.rewardToken.symbol}` : ''}
            rewards on this pool.`
              : ''}
          </StakeModalNote>
          <StakeClaimBtn
            onClick={() => {
              withdraw()
            }}
          >
            Withdraw & Claim
          </StakeClaimBtn>
        </StakeModalWrapper>
      </Modal>
    </>
  )
}
