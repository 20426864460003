import React from 'react'
import styled from 'styled-components'
import { useLastTruthy } from '../../hooks/useLast'
import { AdvancedSwapDetails, AdvancedSwapDetailsProps } from './AdvancedSwapDetails'

const AdvancedDetailsFooter = styled.div<{ show: boolean }>`
  position: absolute;
  bottom: 0;
  padding-top: 16px;
  /* padding-bottom: 16px; */
  /* margin-top: -2rem; */
  width: 100%;
  /* max-width: 400px; */
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: ${({ theme }) => theme.text2};
  /* background-color: ${({ theme }) => theme.advancedBG}; */
  /* z-index: 1; */
  ${({ show }) => (show ? 'opacity: 1' : 'opacity: 0')}
  transform: ${({ show }) => (show ? 'translateY(100%)' : 'translateY(-50%)')};
  transition: all 300ms ease-in-out;
`

export default function AdvancedSwapDetailsDropdown({ trade, ...rest }: AdvancedSwapDetailsProps) {
  const lastTrade = useLastTruthy(trade)

  return (
    <AdvancedDetailsFooter show={Boolean(trade)}>
      <AdvancedSwapDetails {...rest} trade={trade ?? lastTrade ?? undefined} />
    </AdvancedDetailsFooter>
  )
}
