import { useMemo } from 'react'
import useGetUserLiquidityPools from './useGerUserLiquidityPools'

export default function useGetUserPoolByPairAddress(address?: string) {
  const userPools = useGetUserLiquidityPools()

  return useMemo(() => {
    if (!address) return undefined
    const addr = address.toLowerCase()

    return userPools.filter(item => item.pair.id === addr)[0]
  }, [userPools, address])
}
