/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import getOverview from 'gql/microservices/overview'
import { useAppDispatch, useAppSelector } from 'state'
import { setOverview } from 'state/data'

export default function useGetOverviewData() {
  const dispatch = useAppDispatch()
  const { overview } = useAppSelector((state) => state.netswapData)

  async function getOverviewData() {
    const res = await getOverview()

    if (res) {
      dispatch(setOverview(res))
    }
  }

  useEffect(() => {
    getOverviewData()
    let interval = setInterval(() => {
      getOverviewData()
    }, 30000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  return {
    overview,
    getOverview,
  }
}
