import ABI from '../abis/erc20.json'
import { ChainId } from '@netswap/sdk'
import { NETWORK_CHAIN_ID } from 'connectors'

export const Config = {
  [ChainId.MAINNET]: {
    address: '0x90fE084F877C65e1b577c7b2eA64B8D8dd1AB278',
    abi: ABI,
  },
  [ChainId.TESTNET]: {
    address: '0xA49efFF1961C0aF60519887E390e9954952176f8',
    abi: ABI,
  },
  // need config
  [ChainId.TESTNET_SEPOLIA]: {
    address: '0xA49efFF1961C0aF60519887E390e9954952176f8',
    abi: ABI,
  },
}

export const NettAddres = Config[NETWORK_CHAIN_ID as ChainId].address
