import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDarkModeManager } from 'state/user/hooks'
import LightLogo from 'assets/images/netswap-logo.light.png'
import DarkLogo from 'assets/images/netswap-logo.dark.png'
import NettPrice from 'components/Nett/Price'
// import Menu from '../Menu'
import DiscordIcon from 'components/Icon/discord'
import { useMedia } from 'react-use'
import moment from 'moment'

const FooterWrapper = styled.div`
  height: 66px;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.v2.headerBg};
  padding: 0 40px;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100vw;
    overflow-x: hidden;
    padding: 0 16px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
  `}
`

const MediaWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  > .media-link {
    margin-right: 20px;
    color: ${({ theme }) => theme.v2.t01};
    text-decoration: none;
    display: flex;
    align-item: center;

    > .iconfont {
      font-size: 28px;

      &:hover {
        opacity: 0.6;
      }
    }

    > svg {
      width: 28px;
      height 28px;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`

const LinksWrapper = styled.div`
  display: flex;
  flex: 1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
        display: none;    
  `}
`

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  justify-content: center;

  img {
    height: 20px;
  }

  > span {
    width: 295px;
    height: 12px;
    font-size: 12px;
    font-family: 'Poppins-Regular';
    font-weight: 400;
    color: ${({ theme }) => (theme.isDark ? '#A2A2A8' : '#68697C')};
    line-height: 12px;
    margin-top: 4px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
        display: none;    
  `}
`

const ShowSmall = styled.div`
  display: none;

  ${({ theme }) => theme.mediaWidth.upToSmall`
        display: block;
        width: 100%;
  `}
`

const MobileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const currentYear = moment().year()

export default function Footer() {
  const { t } = useTranslation()
  const [darkMode] = useDarkModeManager()

  return (
    <FooterWrapper>
      <MediaWrapper>
        <a className="media-link" href="https://twitter.com/netswapofficial" target="__blank">
          <i className="iconfont icon-twitter-circle-fill" />
        </a>

        <a className="media-link" href="https://t.me/netswap_officialEN" target="__blank">
          <i className="iconfont icon-Telegram" />
        </a>

        <a className="media-link" href="https://medium.com/@netswapofficial" target="__blank">
          <i className="iconfont icon-medium" />
        </a>

        <a className="media-link" href="https://github.com/netswap" target="__blank">
          <i className="iconfont icon-GitHub" />
        </a>

        <a className="media-link" href="https://discord.gg/K9bByCTYPq" target="__blank">
          <DiscordIcon />
        </a>
      </MediaWrapper>

      <LinksWrapper></LinksWrapper>

      <LogoWrapper>
        <NavLink to={'/'}>
          <img src={darkMode ? DarkLogo : LightLogo} alt="" />
        </NavLink>

        <span>
          Copyright© {currentYear} NETSWAP. {t('footer.use_at_your_own_risk')}
        </span>
      </LogoWrapper>
      <ShowSmall>
        <MobileWrapper>
          <NettPrice />
          {/* <Menu /> */}
        </MobileWrapper>
      </ShowSmall>
    </FooterWrapper>
  )
}

const MobileFooterWrapper = styled.div`
  margin-top: 20px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: transparent;
  padding: 0 40px;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100vw;
    overflow-x: hidden;
    padding: 0 16px;
    
    ${MediaWrapper} {
      display: flex;
      margin-bottom: 20px;
    }

    ${LogoWrapper} {
      display: flex;
      margin-bottom: 60px;
      align-items: flex-start;
    }
  `}
`

export function MobileFooter() {
  const isMobile = useMedia('(max-width: 768px)')
  const { t } = useTranslation()
  const [darkMode] = useDarkModeManager()

  return isMobile ? (
    <MobileFooterWrapper>
      <MediaWrapper>
        <a className="media-link" href="https://twitter.com/netswapofficial" target="__blank">
          <i className="iconfont icon-twitter-circle-fill" />
        </a>

        <a className="media-link" href="https://t.me/netswap_officialEN" target="__blank">
          <i className="iconfont icon-Telegram" />
        </a>

        <a className="media-link" href="https://medium.com/@netswapofficial" target="__blank">
          <i className="iconfont icon-medium" />
        </a>

        <a className="media-link" href="https://github.com/netswap" target="__blank">
          <i className="iconfont icon-GitHub" />
        </a>

        <a className="media-link" href="https://discord.gg/K9bByCTYPq" target="__blank">
          <DiscordIcon />
        </a>
      </MediaWrapper>

      <LogoWrapper>
        <NavLink to={'/'}>
          <img src={darkMode ? DarkLogo : LightLogo} alt="" />
        </NavLink>

        <span>
          Copyright© {currentYear} NETSWAP. {t('footer.use_at_your_own_risk')}
        </span>
      </LogoWrapper>
    </MobileFooterWrapper>
  ) : (
    <></>
  )
}
