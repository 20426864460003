import React from 'react'
import styled from 'styled-components'
import { TYPE } from 'theme'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 32px;
    margin-right: 12px;
  }
`

interface Props {
  text?: string
  className?: string
}

export default function LogoText({ text, className }: Props) {
  return (
    <Wrapper className={className}>
      <img src={require('assets/images/nett-token-with-circle.png')} alt="" />
      <TYPE.desc fontSize={20}>{text}</TYPE.desc>
    </Wrapper>
  )
}
