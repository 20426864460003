import React, { createContext, useContext, useReducer, useMemo, useCallback, useEffect, useState } from 'react'

import { ExchangeSubgraph as client } from 'gql/index'
import {
  PAIR_DATA,
  PAIR_CHART,
  FILTERED_TRANSACTIONS,
  PAIRS_CURRENT,
  PAIRS_BULK,
  PAIRS_HISTORICAL_BULK,
  HOURLY_PAIR_RATES,
  PairOnlyWithVol,
  PairFull,
  Mint,
  Burn,
  Swap,
  PairDayData,
  PairTransactions,
} from 'gql/subgraph/analytics'

import { useMetisPrice } from './GlobalData'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import {
  getPercentChange,
  get2DayPercentChange,
  isAddress,
  getBlocksFromTimestamps,
  getTimestampsForChanges,
  splitQuery,
} from 'utils/infos'
import { TimeframeOptions } from 'constants/index'
import { useBlockNumber } from 'state/application/hooks'

dayjs.extend(utc)

const UPDATE = 'UPDATE'
const UPDATE_PAIR_TXNS = 'UPDATE_PAIR_TXNS'
const UPDATE_CHART_DATA = 'UPDATE_CHART_DATA'
const UPDATE_TOP_PAIRS = 'UPDATE_TOP_PAIRS'
const UPDATE_HOURLY_DATA = 'UPDATE_HOURLY_DATA'

dayjs.extend(utc)

export type PairHourlyData = {
  timestamp: number
  open: number
  close: number
}

export type PairFormatedData = {
  oneDayVolumeUSD: number
  oneWeekVolumeUSD: number
  volumeChangeUSD: number
  weekVolumeChangeUSD: number
  oneDayVolumeUntracked: number
  oneWeekVolumeUntracked: number
  volumeChangeUntracked: number
  weekVolumeChangeUntracked: number
  apr: number
  trackedReserveUSD: number
  liquidityChangeUSD: number
} & PairFull

export type PairInState = PairFormatedData & {
  hourlyData?: {
    [key: string]: PairHourlyData[][]
  }
  txns?: PairTransactions
  chartData?: PairDayData[]
}

export function safeAccess(object: any, path: any[]) {
  return object
    ? path.reduce(
        (accumulator, currentValue) => (accumulator && accumulator[currentValue] ? accumulator[currentValue] : null),
        object
      )
    : null
}

const PairDataContext = createContext<
  [
    {
      [key: string]: PairInState
    },
    any
  ]
>([{}, []])

function usePairDataContext() {
  return useContext(PairDataContext)
}

function reducer(state: any, { type, payload }: { type: string; payload: any }) {
  switch (type) {
    case UPDATE: {
      const { pairAddress, data } = payload
      return {
        ...state,
        [pairAddress]: {
          ...state?.[pairAddress],
          ...data,
        },
      }
    }

    case UPDATE_TOP_PAIRS: {
      const { topPairs } = payload
      let added: any = {}
      topPairs.map((pair: any) => {
        return (added[pair.id] = pair)
      })
      return {
        ...state,
        ...added,
      }
    }

    case UPDATE_PAIR_TXNS: {
      const { address, transactions } = payload
      return {
        ...state,
        [address]: {
          ...(safeAccess(state, [address]) || {}),
          txns: transactions,
        },
      }
    }
    case UPDATE_CHART_DATA: {
      const { address, chartData } = payload
      return {
        ...state,
        [address]: {
          ...(safeAccess(state, [address]) || {}),
          chartData,
        },
      }
    }

    case UPDATE_HOURLY_DATA: {
      const { address, hourlyData, timeWindow } = payload
      return {
        ...state,
        [address]: {
          ...state?.[address],
          hourlyData: {
            ...state?.[address]?.hourlyData,
            [timeWindow]: hourlyData,
          },
        },
      }
    }

    default: {
      throw Error(`Unexpected action type in DataContext reducer: '${type}'.`)
    }
  }
}

export default function Provider({ children }: { children: React.ReactChildren | React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, {})

  // update pair specific data
  const update = useCallback((pairAddress: any, data: any) => {
    dispatch({
      type: UPDATE,
      payload: {
        pairAddress,
        data,
      },
    })
  }, [])

  const updateTopPairs = useCallback((topPairs: any) => {
    dispatch({
      type: UPDATE_TOP_PAIRS,
      payload: {
        topPairs,
      },
    })
  }, [])

  const updatePairTxns = useCallback((address: any, transactions: any) => {
    dispatch({
      type: UPDATE_PAIR_TXNS,
      payload: { address, transactions },
    })
  }, [])

  const updateChartData = useCallback((address: any, chartData: any) => {
    dispatch({
      type: UPDATE_CHART_DATA,
      payload: { address, chartData },
    })
  }, [])

  const updateHourlyData = useCallback((address: any, hourlyData: any, timeWindow: any) => {
    dispatch({
      type: UPDATE_HOURLY_DATA,
      payload: { address, hourlyData, timeWindow },
    })
  }, [])

  return (
    <PairDataContext.Provider
      value={useMemo(
        () => [
          state,
          {
            update,
            updatePairTxns,
            updateChartData,
            updateTopPairs,
            updateHourlyData,
          },
        ],
        [state, update, updatePairTxns, updateChartData, updateTopPairs, updateHourlyData]
      )}
    >
      {children as any}
    </PairDataContext.Provider>
  )
}

async function getBulkPairData(pairList: string[], metisPrice: number): Promise<PairFormatedData[]> {
  const [t1, t2, tWeek, t2Week] = getTimestampsForChanges()
  let blockRes = await getBlocksFromTimestamps([t1, t2, tWeek, t2Week])

  if (blockRes.length < 4) {
    return []
  }

  let [{ number: b1 }, { number: b2 }, { number: bWeek }, { number: b2Week }] = blockRes

  try {
    let current = await client().query<{ pairs: PairFull[] }>({
      query: PAIRS_BULK,
      variables: {
        allPairs: pairList,
      },
      fetchPolicy: 'cache-first',
    })

    let [oneDayResult, twoDayResult, oneWeekResult, twoWeekResult] = await Promise.all(
      [b1, b2, bWeek, b2Week].map(async (block) => {
        let result = client().query<{ pairs: PairOnlyWithVol[] }>({
          query: PAIRS_HISTORICAL_BULK(block, pairList),
          fetchPolicy: 'cache-first',
        })
        return result
      })
    )

    let oneDayData: { [key: string]: PairOnlyWithVol } = oneDayResult?.data?.pairs.reduce((obj, cur) => {
      return { ...obj, [cur.id]: cur }
    }, {})

    let twoDayData: { [key: string]: PairOnlyWithVol } = twoDayResult?.data?.pairs.reduce((obj, cur) => {
      return { ...obj, [cur.id]: cur }
    }, {})

    let oneWeekData: { [key: string]: PairOnlyWithVol } = oneWeekResult?.data?.pairs.reduce((obj, cur) => {
      return { ...obj, [cur.id]: cur }
    }, {})

    let twoWeekData: { [key: string]: PairOnlyWithVol } = twoWeekResult?.data?.pairs.reduce((obj, cur) => {
      return { ...obj, [cur.id]: cur }
    }, {})

    let pairData: PairFormatedData[] = await Promise.all(
      current &&
        current.data.pairs.map(async (pair) => {
          let data = pair
          let oneDayHistory = oneDayData?.[pair.id]
          if (!oneDayHistory) {
            let newData = await client().query({
              query: PAIR_DATA(pair.id, b1),
              fetchPolicy: 'cache-first',
            })
            oneDayHistory = newData.data.pairs[0]
          }
          let twoDayHistory = twoDayData?.[pair.id]
          if (!twoDayHistory) {
            let newData = await client().query({
              query: PAIR_DATA(pair.id, b2),
              fetchPolicy: 'cache-first',
            })
            twoDayHistory = newData.data.pairs[0]
          }
          let oneWeekHistory = oneWeekData?.[pair.id]
          if (!oneWeekHistory) {
            let newData = await client().query({
              query: PAIR_DATA(pair.id, bWeek),
              fetchPolicy: 'cache-first',
            })
            oneWeekHistory = newData.data.pairs[0]
          }
          let twoWeekHistory = twoWeekData?.[pair.id]
          if (!twoWeekHistory) {
            let newData = await client().query({
              query: PAIR_DATA(pair.id, b2Week),
              fetchPolicy: 'cache-first',
            })
            twoWeekHistory = newData.data.pairs[0]
          }
          return parseData(data, oneDayHistory, twoDayHistory, oneWeekHistory, twoWeekHistory, metisPrice, b1)
        })
    )
    return pairData
  } catch (e) {
    console.log(e)
    return []
  }
}

function parseData(
  data: PairFull,
  oneDayData: PairOnlyWithVol,
  twoDayData: PairOnlyWithVol,
  oneWeekData: PairOnlyWithVol,
  twoWeekData: PairOnlyWithVol,
  metisPrice: number,
  oneDayBlock: number
): PairFormatedData {
  // get volume changes
  const [oneDayVolumeUSD, volumeChangeUSD] = get2DayPercentChange(
    data?.volumeUSD,
    oneDayData?.volumeUSD ? oneDayData.volumeUSD : 0,
    twoDayData?.volumeUSD ? twoDayData.volumeUSD : 0
  )
  const [oneDayVolumeUntracked, volumeChangeUntracked] = get2DayPercentChange(
    data?.untrackedVolumeUSD,
    oneDayData?.untrackedVolumeUSD ? parseFloat(oneDayData?.untrackedVolumeUSD) : 0,
    twoDayData?.untrackedVolumeUSD ? twoDayData?.untrackedVolumeUSD : 0
  )

  const [oneWeekVolumeUSD, weekVolumeChangeUSD] = get2DayPercentChange(
    data?.volumeUSD,
    oneWeekData?.volumeUSD ? oneWeekData.volumeUSD : 0,
    twoWeekData?.volumeUSD ? twoWeekData.volumeUSD : 0
  )
  const [oneWeekVolumeUntracked, weekVolumeChangeUntracked] = get2DayPercentChange(
    data?.untrackedVolumeUSD,
    oneWeekData?.untrackedVolumeUSD ? parseFloat(oneWeekData?.untrackedVolumeUSD) : 0,
    twoWeekData?.untrackedVolumeUSD ? twoWeekData?.untrackedVolumeUSD : 0
  )

  let returnData: PairFormatedData = {
    ...data,
    oneDayVolumeUSD: 0,
    oneWeekVolumeUSD: 0,
    volumeChangeUSD: 0,
    weekVolumeChangeUSD: 0,
    oneDayVolumeUntracked: 0,
    oneWeekVolumeUntracked: 0,
    volumeChangeUntracked: 0,
    weekVolumeChangeUntracked: 0,
    apr: 0,
    trackedReserveUSD: 0,
    liquidityChangeUSD: 0,
  }

  // set volume properties
  returnData.oneDayVolumeUSD = oneDayVolumeUSD
  returnData.oneWeekVolumeUSD = oneWeekVolumeUSD
  returnData.volumeChangeUSD = volumeChangeUSD
  returnData.weekVolumeChangeUSD = weekVolumeChangeUSD
  returnData.oneDayVolumeUntracked = oneDayVolumeUntracked
  returnData.oneWeekVolumeUntracked = oneWeekVolumeUntracked
  returnData.volumeChangeUntracked = volumeChangeUntracked
  returnData.weekVolumeChangeUntracked = weekVolumeChangeUntracked
  // data.apr = oneWeekData
  //   ? (((oneWeekVolumeUSD * 0.0025 * 365) / 7) * 100) / data.reserveUSD
  //   : (oneDayVolumeUSD * 0.0025 * 365 * 100) / data.reserveUSD
  returnData.apr = (oneDayVolumeUSD * 0.0025 * 365 * 100) / Number(data.reserveUSD)

  // set liquiditry properties
  returnData.trackedReserveUSD = Number(data.trackedReserveMETIS) * metisPrice
  returnData.liquidityChangeUSD = getPercentChange(data.reserveUSD, oneDayData?.reserveUSD)

  // format if pair hasnt existed for a day or a week
  if (!oneDayData && returnData && Number(data.createdAtBlockNumber) > oneDayBlock) {
    returnData.oneDayVolumeUSD = parseFloat(data.volumeUSD)
  }
  if (!oneDayData && data) {
    returnData.oneDayVolumeUSD = parseFloat(data.volumeUSD)
  }
  if (!oneWeekData && data) {
    returnData.oneWeekVolumeUSD = parseFloat(data.volumeUSD)
  }

  return returnData
}

const getPairTransactions = async (pairAddress: string) => {
  const transactions: {
    mints: Mint[]
    burns: Burn[]
    swaps: Swap[]
  } = {
    mints: [],
    burns: [],
    swaps: [],
  }

  try {
    let result = await client().query<{
      mints: Mint[]
      burns: Burn[]
      swaps: Swap[]
    }>({
      query: FILTERED_TRANSACTIONS,
      variables: {
        allPairs: [pairAddress],
      },
      fetchPolicy: 'no-cache',
    })

    transactions.mints = result.data.mints
    transactions.burns = result.data.burns
    transactions.swaps = result.data.swaps
  } catch (e) {
    console.log(e)
  }

  return transactions
}

const getPairChartData = async (pairAddress: string) => {
  let data: PairDayData[] = []
  const utcEndTime = dayjs.utc()
  let utcStartTime = utcEndTime.subtract(1, 'year').startOf('minute')
  let startTime = utcStartTime.unix() - 1

  try {
    let allFound = false
    let skip = 0
    while (!allFound) {
      let result = await client().query<{ pairDayDatas: PairDayData[] }>({
        query: PAIR_CHART,
        variables: {
          pairAddress: pairAddress,
          skip,
        },
        fetchPolicy: 'cache-first',
      })
      skip += 1000
      data = data.concat(result.data.pairDayDatas)
      if (result.data.pairDayDatas.length < 1000) {
        allFound = true
      }
    }

    let dayIndexSet = new Set()
    const oneDay = 24 * 60 * 60
    let _data = data.map((dayData, i) => {
      // add the day index to the set of days
      dayIndexSet.add((Number(data[i].date) / oneDay).toFixed(0))

      return {
        ...dayData,
        date: Number(dayData.date),
        dailyVolumeUSD: parseFloat(dayData.dailyVolumeUSD),
        reserveUSD: parseFloat(dayData.reserveUSD),
        dayString: '',
      }
    })

    let dayIndexArray = [..._data]

    if (_data[0]) {
      // fill in empty days
      let timestamp = _data[0].date ? _data[0].date : startTime
      let latestLiquidityUSD = _data[0].reserveUSD
      let index = 1
      while (timestamp < utcEndTime.unix() - oneDay) {
        const nextDay = timestamp + oneDay
        let currentDayIndex = (nextDay / oneDay).toFixed(0)
        if (!dayIndexSet.has(currentDayIndex)) {
          _data.push({
            ..._data[0],
            date: nextDay,
            dayString: nextDay.toString(),
            dailyVolumeUSD: 0,
            reserveUSD: latestLiquidityUSD,
          })
        } else {
          latestLiquidityUSD = dayIndexArray[index].reserveUSD
          index = index + 1
        }
        timestamp = nextDay
      }
    }

    _data = _data.sort((a, b) => (a.date > b.date ? 1 : -1))
  } catch (e) {
    console.log(e)
  }

  return data
}

const getHourlyRateData = async (
  pairAddress: string,
  startTime: number,
  latestBlock: number
): Promise<[PairHourlyData[], PairHourlyData[]]> => {
  try {
    const utcEndTime = dayjs.utc()
    let time = startTime

    // create an array of hour start times until we reach current hour
    const timestamps = []
    while (time <= utcEndTime.unix() - 3600) {
      timestamps.push(time)
      time += 3600
    }

    // backout if invalid timestamp format
    if (timestamps.length === 0) {
      return [[], []]
    }

    // once you have all the timestamps, get the blocks for each timestamp in a bulk query
    let blocks

    blocks = await getBlocksFromTimestamps(timestamps, 100)

    // catch failing case
    if (!blocks || blocks?.length === 0) {
      return [[], []]
    }

    if (latestBlock) {
      blocks = blocks.filter((b) => {
        return parseFloat(b.number) <= latestBlock
      })
    }

    const result: {
      [key: string]: {
        token0Price: string
        token1Price: string
      }
    } = await splitQuery(HOURLY_PAIR_RATES, client(), [pairAddress], blocks, 100)

    // format token METIS price results
    let values = []
    for (let row in result) {
      let timestamp = row.split('t')[1]
      if (timestamp) {
        values.push({
          timestamp,
          rate0: parseFloat(result[row]?.token0Price),
          rate1: parseFloat(result[row]?.token1Price),
        })
      }
    }

    let formattedHistoryRate0: PairHourlyData[] = []
    let formattedHistoryRate1: PairHourlyData[] = []

    // for each hour, construct the open and close price
    for (let i = 0; i < values.length - 1; i++) {
      formattedHistoryRate0.push({
        timestamp: parseInt(values[i].timestamp),
        open: values[i].rate0,
        close: values[i + 1].rate0,
      })
      formattedHistoryRate1.push({
        timestamp: parseInt(values[i].timestamp),
        open: values[i].rate1,
        close: values[i + 1].rate1,
      })
    }

    return [formattedHistoryRate0, formattedHistoryRate1]
  } catch (e) {
    console.log(e)
    return [[], []]
  }
}

export function Updater() {
  const [, { updateTopPairs }] = usePairDataContext()
  const [metisPrice] = useMetisPrice()
  useEffect(() => {
    async function getData() {
      // get top pairs by reserves
      let {
        data: { pairs },
      } = await client().query<{ pairs: { id: string }[] }>({
        query: PAIRS_CURRENT,
        fetchPolicy: 'cache-first',
      })

      // format as array of addresses
      const formattedPairs = pairs.map((pair) => {
        return pair.id
      })

      // get data for every pair in list
      let topPairs = await getBulkPairData(formattedPairs, metisPrice)
      topPairs && updateTopPairs(topPairs)
    }
    metisPrice && getData()
  }, [metisPrice, updateTopPairs])
  return null
}

export function useHourlyRateData(pairAddress: string, timeWindow: string) {
  const [state, { updateHourlyData }] = usePairDataContext()
  const chartData = state?.[pairAddress]?.hourlyData?.[timeWindow as any]
  const latestBlock = useBlockNumber()

  useEffect(() => {
    if (!latestBlock) {
      return
    }

    const _block = latestBlock
    const currentTime = dayjs.utc()
    const windowSize = timeWindow === TimeframeOptions.MONTH ? 'month' : 'week'
    const startTime =
      timeWindow === TimeframeOptions.ALL_TIME ? 1589760000 : currentTime.subtract(1, windowSize).startOf('hour').unix()

    async function fetch() {
      let data = await getHourlyRateData(pairAddress, startTime, _block)
      updateHourlyData(pairAddress, data, timeWindow)
    }
    if (!chartData) {
      fetch()
    }
  }, [chartData, timeWindow, pairAddress, updateHourlyData, latestBlock])

  return chartData
}

/**
 * @todo
 * store these updates to reduce future redundant calls
 */
export function useDataForList(pairList: { id: string }[]) {
  const [state] = usePairDataContext()
  const [metisPrice] = useMetisPrice()

  const [stale, setStale] = useState(false)
  const [fetched, setFetched] = useState<PairFormatedData[]>([])
  const [hasFetched, setHasFetched] = useState<boolean>(false)

  // reset
  useEffect(() => {
    if (pairList) {
      setStale(false)
      // setFetched([])
      setHasFetched(false)
    }
  }, [pairList])

  useEffect(() => {
    async function fetchNewPairData() {
      let newFetched: PairFormatedData[] = []
      let unfetched: string[] = []

      pairList.map(async (pair) => {
        let currentData = state?.[pair.id]
        if (!currentData) {
          unfetched.push(pair.id)
        } else {
          newFetched.push(currentData)
        }
      })

      let newPairData = await getBulkPairData(
        unfetched.map((pair) => {
          return pair
        }),
        metisPrice
      )
      setFetched(newFetched.concat(newPairData))
      setHasFetched(true)
    }
    if (metisPrice && pairList && pairList.length > 0 && !hasFetched && !stale) {
      setStale(true)
      fetchNewPairData()
    }
  }, [metisPrice, state, pairList, stale, fetched, hasFetched])

  let formattedFetch: {
    [key: string]: PairFormatedData
  } =
    fetched &&
    fetched.reduce((obj, cur) => {
      return { ...obj, [cur?.id]: cur }
    }, {})

  return formattedFetch
}

/**
 * Get all the current and 24hr changes for a pair
 */
export function usePairData(pairAddress: string) {
  const [state, { update }] = usePairDataContext()
  const [metisPrice] = useMetisPrice()
  const pairData = state?.[pairAddress]

  useEffect(() => {
    async function fetchData() {
      if (!pairData && pairAddress) {
        let data = await getBulkPairData([pairAddress], metisPrice)
        data && update(pairAddress, data[0])
      }
    }
    if (!pairData && pairAddress && metisPrice && isAddress(pairAddress)) {
      fetchData()
    }
  }, [pairAddress, pairData, update, metisPrice])

  return pairData || {}
}

/**
 * Get most recent txns for a pair
 */
export function usePairTransactions(pairAddress: string) {
  const [state, { updatePairTxns }] = usePairDataContext()
  const pairTxns = state?.[pairAddress]?.txns
  useEffect(() => {
    async function checkForTxns() {
      if (!pairTxns) {
        let transactions = await getPairTransactions(pairAddress)
        updatePairTxns(pairAddress, transactions)
      }
    }
    checkForTxns()
  }, [pairTxns, pairAddress, updatePairTxns])
  return pairTxns
}

export function usePairChartData(pairAddress: string) {
  const [state, { updateChartData }] = usePairDataContext()
  const chartData = state?.[pairAddress]?.chartData

  useEffect(() => {
    async function checkForChartData() {
      if (!chartData) {
        let data = await getPairChartData(pairAddress)
        updateChartData(pairAddress, data)
      }
    }
    checkForChartData()
  }, [chartData, pairAddress, updateChartData])
  return chartData as PairDayData[]
}

/**
 * Get list of all pairs in Netswap
 */
export function useAllPairData() {
  const [state] = usePairDataContext()
  return state || {}
}

/**
 * Get list of pairs with given tokens
 */
// export function usePairDataWithTokens() {
//   const [state] = usePairDataContext()
//   const [pairs, setPairs] = useState<{[key: string]: PairInState}>({})
//   const [tokens, setTokens] = useState({})

//   useEffect(() => {
//     if (Object.keys(tokens).filter((address) => !!tokens[address]).length === 0) {
//       setPairs(state)
//       return
//     }

//     const newPairs: {[key: string]: PairInState} = {}
//     for (const pairAddress in state) {
//       if (tokens[state[pairAddress].token0.id] || tokens[state[pairAddress].token1.id]) {
//         newPairs[pairAddress] = state[pairAddress]
//       }
//     }

//     setPairs(newPairs)
//   }, [tokens, state])

//   return [pairs, setTokens]
// }
