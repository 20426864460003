import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'

import { RoundInfo, getRounds } from 'gql/microservices/trade-competition'
import Competition from './Competition'
import RoundsLists from './RoundsLists'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 250px;
  padding-top: 44px;
  position: relative;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-left: 0;
    padding-top: 80px;
  `}
`

export default function TradeCompetition() {
  const [rounds, setRounds] = useState<RoundInfo[]>([])

  const [selectedRound, setSelectedRound] = useState<RoundInfo | null>(null)

  const updateRounds = useCallback(async () => {
    const newRounds = await getRounds()

    setRounds(newRounds)
  }, [setRounds])

  const onRoundSelect = useCallback(
    (round: RoundInfo) => {
      setSelectedRound(round)
    },
    [setSelectedRound]
  )

  useEffect(() => {
    updateRounds()
  }, [updateRounds])

  useEffect(() => {
    if (rounds.length > 0 && selectedRound === null) {
      setSelectedRound(rounds[rounds.length - 1])
    }
  }, [rounds, selectedRound, setSelectedRound])

  return (
    <Container maxWidth="lg">
      <Wrapper>
        <RoundsLists rounds={rounds} selectedRound={selectedRound} onRoundSelect={onRoundSelect} />
        <Competition />
      </Wrapper>
    </Container>
  )
}
