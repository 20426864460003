import { ChainId } from '@netswap/sdk'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import { AssetsPool } from 'gql/microservices/assets-pools'
import { AirdropInfo } from 'gql/microservices/userinfo'
import { Overview } from 'gql/microservices/overview'
import { TokenData } from 'gql/subgraph/tokens'
import Storage from 'utils/storage'
import { FarmLPPool } from 'gql/microservices/farm-lp-pools'
import { LaunchPad } from 'gql/microservices/pads'
import { SubgraphPad } from 'gql/subgraph/user-pads'
import { LaunchPadV2 } from 'gql/subgraph/launchpadV2'
import { ProjectIntro } from './hooks/useGetLaunchpadV2Data'
import { PairWithApr } from 'gql/subgraph/pairs'
import { UserLiquidityPool } from 'gql/subgraph/user-liquidity-pool'

interface WrapperAirdropInfo extends AirdropInfo {
  formatAmount: number
}

interface AirdropInfoState {
  info?: WrapperAirdropInfo
  endTime?: number
  claimed?: boolean
}

export interface LaunchPadIntroduction {
  name: string
  description: string
  links: {
    [key: string]: string
  }
  logo: string
  website: string
}

export interface SwapHistory {
  USD: number
  amount: number
  pair: {
    symbol: string
  }[]
  time: number | string
  chainId?: ChainId
  account: string
}

export interface BaseTokenInfo {
  symbol: string
  price: number
  maxSupply: number
  circulatingSupply: number
  fdv: number
}

interface State {
  overview: Overview | undefined
  airdropData: {
    first: AirdropInfoState
    second: AirdropInfoState
  }
  nettTokenInfo: {
    totalSupply: number
  }
  communityTresury: {
    nettBalance: number
  }
  blackHold: {
    nettBalance: number
  }
  fetchTokenList: string[]
  tokenPrice: TokenData
  farm: {
    assetsPool?: {
      [ChainId.MAINNET]: AssetsPool[]
      [ChainId.TESTNET]: AssetsPool[]
      [ChainId.TESTNET_SEPOLIA]: AssetsPool[]
    }
    lpPool?: {
      [ChainId.MAINNET]: FarmLPPool[]
      [ChainId.TESTNET]: FarmLPPool[]
      [ChainId.TESTNET_SEPOLIA]: FarmLPPool[]
    }
    userLPPools: {
      [key: string]: {
        [ChainId.MAINNET]: string[]
        [ChainId.TESTNET]: string[]
        [ChainId.TESTNET_SEPOLIA]: string[]
      }
    }
  }
  launchpad: {
    pads?: {
      [ChainId.MAINNET]: LaunchPad[]
      [ChainId.TESTNET]: LaunchPad[]
      [ChainId.TESTNET_SEPOLIA]: LaunchPad[]
    }
    userPads: {
      [key: string]: {
        [ChainId.MAINNET]: SubgraphPad[]
        [ChainId.TESTNET]: SubgraphPad[]
        [ChainId.TESTNET_SEPOLIA]: SubgraphPad[]
      }
    }
    introduction: {
      [ChainId.MAINNET]?: {
        [key: string]: LaunchPadIntroduction
      }
      [ChainId.TESTNET]?: {
        [key: string]: LaunchPadIntroduction
      }
      [ChainId.TESTNET_SEPOLIA]?: {
        [key: string]: LaunchPadIntroduction
      }
    }
  }
  launchpadV2: {
    pads?: {
      [ChainId.MAINNET]: LaunchPadV2[]
      [ChainId.TESTNET]: LaunchPadV2[]
      [ChainId.TESTNET_SEPOLIA]: LaunchPadV2[]
    }
    userPads: {
      [key: string]: {
        [ChainId.MAINNET]: string[]
        [ChainId.TESTNET]: string[]
        [ChainId.TESTNET_SEPOLIA]: string[]
      }
    }
    projectInfo: {
      [ChainId.MAINNET]: {
        [key: string]: ProjectIntro
      }
      [ChainId.TESTNET]: {
        [key: string]: ProjectIntro
      }
      [ChainId.TESTNET_SEPOLIA]: {
        [key: string]: ProjectIntro
      }
    }
  }
  transactionHistory: {
    swap: SwapHistory[]
  }
  liquidityPools: {
    [ChainId.MAINNET]: PairWithApr[]
    [ChainId.TESTNET]: PairWithApr[]
    [ChainId.TESTNET_SEPOLIA]: PairWithApr[]
  }
  userLiquidityPools: {
    [key: string]: {
      [ChainId.MAINNET]: UserLiquidityPool[]
      [ChainId.TESTNET]: UserLiquidityPool[]
      [ChainId.TESTNET_SEPOLIA]: UserLiquidityPool[]
    }
  }
  baseTokensInfos: BaseTokenInfo[]
}

export const FarmAssetsPoolKey = 'netswap-data-farm-assets-pool'
const FarmAssetsPoolStorageData = Storage.get(FarmAssetsPoolKey)

export const FarmLPPoolKey = 'netswap-data-farm-lp-pool'
const FarmLPPoolStorageData = Storage.get(FarmLPPoolKey)

export const UserFarmLPPoolKey = 'netswap-data-user-farm-lp-pool'
const UserFarmLPPoolStorageData = Storage.get(UserFarmLPPoolKey)

export const LaunchpadKey = 'netswap-data-launchpad-pads'
const LaunchpadStorageData = Storage.get(LaunchpadKey)

export const UserPadsKey = 'netswap-data-user-pads'
const UserPadsStorageData = Storage.get(UserPadsKey)

export const LaunchPadIntroductionKey = 'netswap-data-launchpad-project-introduction'
const LaunchpadIntroductionStorageData = Storage.get(LaunchPadIntroductionKey)

export const LaunchpadV2PadsKey = 'netswap-data-launchpad-v2-pads'
const LaunchpadV2Pads = Storage.get(LaunchpadV2PadsKey)

export const LaunchpadV2UserPadsKey = 'netswap-data-launchpad-v2-user-pads'
const LaunchpadV2UserPads = Storage.get(LaunchpadV2UserPadsKey)

export const LaunchpadV2ProjectInfoKey = 'netswap-data-launchpad-v2-project-info'
const LaunchpadV2ProjectInfo = Storage.get(LaunchpadV2ProjectInfoKey)

export const TransactionHistoryKey = 'netswap-data-transaction-history'
const TransactionHistory = Storage.get(TransactionHistoryKey)

export const LiquidityPoolsKey = 'netswap-data-liquidty-pool'
const LiquidityPools = Storage.get(LiquidityPoolsKey)

export const UserLiquidityPoolsKey = 'netswap-data-user-liquidty-pool'
const UserLiquidityPools = Storage.get(UserLiquidityPoolsKey)

export const BaseTokensInfosKey = 'netswap-data-base-tokens-infos'
const BaseTokensInfos = Storage.get(BaseTokensInfosKey)

// Define the initial state using that type
// -1 === loading
const initialState: State = {
  overview: undefined,
  airdropData: {
    first: {
      info: undefined,
      endTime: -1,
      claimed: false,
    },
    second: {
      info: undefined,
      endTime: -1,
      claimed: false,
    },
  },
  nettTokenInfo: {
    totalSupply: -1,
  },
  communityTresury: {
    nettBalance: -1,
  },
  blackHold: {
    nettBalance: -1,
  },
  fetchTokenList: [],
  tokenPrice: {
    // key = token address , expect metis
    metis: {
      price: 0,
    },
  },
  farm: {
    assetsPool: FarmAssetsPoolStorageData,
    lpPool: FarmLPPoolStorageData,
    userLPPools: UserFarmLPPoolStorageData || {},
  },
  launchpad: {
    pads: LaunchpadStorageData,
    userPads: UserPadsStorageData || {},
    introduction: LaunchpadIntroductionStorageData || {},
  },
  launchpadV2: {
    pads: LaunchpadV2Pads,
    userPads: LaunchpadV2UserPads || {},
    projectInfo: LaunchpadV2ProjectInfo,
  },
  transactionHistory: TransactionHistory || {
    swap: [],
  },
  liquidityPools: LiquidityPools,
  userLiquidityPools: UserLiquidityPools || {},
  baseTokensInfos: BaseTokensInfos,
}

function formateAirdropInfoData(data: AirdropInfo) {
  const res = {
    ...data,
    formatAmount: new BigNumber(data.amount).shiftedBy(-18).toNumber(),
  }
  if (data) {
    res.lp.amount = new BigNumber(data.lp.amount).shiftedBy(-18).toNumber()
    res.swap.amount = new BigNumber(data.swap.amount).shiftedBy(-18).toNumber()
    res.promotion.amount = new BigNumber(data.promotion.amount).shiftedBy(-18).toNumber()
  }

  return res
}

export const netswapData = createSlice({
  name: 'netswapData',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setOverview: (state, action: PayloadAction<Overview>) => {
      state.overview = action.payload
    },
    setAirdropInfo: (
      state,
      action: PayloadAction<{
        key: 'first' | 'second'
        update: 'info' | 'endTime' | 'claimed'
        value: {
          info?: AirdropInfo
          endTime?: number
          claimed?: boolean
        }
      }>
    ) => {
      const { key, value, update } = action.payload
      let res = value[update]
      if (update === 'info' && value.info) {
        res = formateAirdropInfoData(value.info)
      }
      ;(state.airdropData as any)[key][update] = res
    },
    setNettTokenInfoTotalSupply: (state, action: PayloadAction<number>) => {
      state.nettTokenInfo.totalSupply = action.payload
    },
    setCommunityTresuryNettBalance: (state, action: PayloadAction<number>) => {
      state.communityTresury.nettBalance = action.payload
    },
    setBlackHoldAddress: (state, action: PayloadAction<number>) => {
      state.blackHold.nettBalance = action.payload
    },
    updateFetchTokenList: (
      state,
      action: PayloadAction<{
        type: 'add' | 'remove'
        value: string[]
      }>
    ) => {
      let { type, value } = action.payload
      value = value.map((item) => item.toLowerCase())
      if (type === 'add') {
        let res = []
        for (let i = 0, len = value.length; i < len; i++) {
          if (state.fetchTokenList.indexOf(value[i].toLowerCase()) === -1) {
            res.push(value[i])
          }
        }
        state.fetchTokenList = res
      } else if (type === 'remove') {
        let res = []
        for (let i = 0, len = value.length; i < len; i++) {
          if (state.fetchTokenList.indexOf(value[i].toLowerCase()) === -1) {
            res.push(value[i])
          }
        }

        state.fetchTokenList = res
      }
    },
    setTokenPrice: (state, action: PayloadAction<TokenData>) => {
      state.tokenPrice = {
        ...state.tokenPrice,
        ...action.payload,
      }
    },
    setFarmAssetsPool: (
      state,
      action: PayloadAction<{
        chainId: ChainId
        data: AssetsPool[]
      }>
    ) => {
      const { chainId, data } = action.payload
      if (!state.farm.assetsPool) {
        state.farm.assetsPool = {
          [ChainId.MAINNET]: [],
          [ChainId.TESTNET]: [],
          [ChainId.TESTNET_SEPOLIA]: [],
        }
      }
      state.farm.assetsPool[chainId] = data
      Storage.set(FarmAssetsPoolKey, state.farm.assetsPool)
    },
    setFarmLPPool: (
      state,
      action: PayloadAction<{
        chainId: ChainId
        data: FarmLPPool[]
      }>
    ) => {
      const { chainId, data } = action.payload
      if (!state.farm.lpPool) {
        state.farm.lpPool = {
          [ChainId.MAINNET]: [],
          [ChainId.TESTNET]: [],
          [ChainId.TESTNET_SEPOLIA]: [],
        }
      }
      state.farm.lpPool[chainId] = data
      Storage.set(FarmLPPoolKey, state.farm.lpPool)
    },
    setUserFarmPool: (
      state,
      action: PayloadAction<{
        chainId: ChainId
        account: string
        data: string[]
        method: 'add' | 'remove' | 'set'
      }>
    ) => {
      const { chainId, data, account, method } = action.payload
      if (!state.farm.userLPPools[account]) {
        state.farm.userLPPools[account] = {
          [ChainId.MAINNET]: [],
          [ChainId.TESTNET]: [],
          [ChainId.TESTNET_SEPOLIA]: [],
        }
      }

      if (method === 'set') {
        state.farm.userLPPools[account][chainId] = data
      } else if (method === 'add') {
        for (let i = 0, len = data.length; i < len; i++) {
          if (state.farm.userLPPools[account][chainId].indexOf(data[i]) === -1) {
            state.farm.userLPPools[account][chainId].push(data[i])
          }
        }
      } else {
        let newRes = []
        for (let i = 0, len = state.farm.userLPPools[account][chainId].length; i < len; i++) {
          const id = state.farm.userLPPools[account][chainId][i]
          if (data.indexOf(id) === -1) {
            newRes.push(id)
          }
        }
        state.farm.userLPPools[account][chainId] = newRes
      }

      Storage.set(UserFarmLPPoolKey, state.farm.userLPPools)
    },
    setLaunchpads: (
      state,
      action: PayloadAction<{
        chainId: ChainId
        data: LaunchPad[]
      }>
    ) => {
      const { chainId, data } = action.payload
      if (!state.launchpad.pads) {
        state.launchpad.pads = {
          [ChainId.MAINNET]: [],
          [ChainId.TESTNET]: [],
          [ChainId.TESTNET_SEPOLIA]: [],
        }
      }
      state.launchpad.pads[chainId] = data
      Storage.set(LaunchpadKey, state.launchpad.pads)
    },
    setUserPads: (
      state,
      action: PayloadAction<{
        chainId: ChainId
        account: string
        data: SubgraphPad[]
        method: 'add' | 'remove' | 'set'
      }>
    ) => {
      const { chainId, data, account, method } = action.payload
      if (!state.launchpad.userPads[account]) {
        state.launchpad.userPads[account] = {
          [ChainId.MAINNET]: [],
          [ChainId.TESTNET]: [],
          [ChainId.TESTNET_SEPOLIA]: [],
        }
      }

      if (method === 'set') {
        state.launchpad.userPads[account][chainId] = data
      } else if (method === 'add') {
        for (let i = 0, len = data.length; i < len; i++) {
          if (state.launchpad.userPads[account][chainId].indexOf(data[i]) === -1) {
            state.launchpad.userPads[account][chainId].push(data[i])
          }
        }
      } else {
        let newRes = []
        for (let i = 0, len = state.launchpad.userPads[account][chainId].length; i < len; i++) {
          const id = state.launchpad.userPads[account][chainId][i]
          if (data.indexOf(id) === -1) {
            newRes.push(id)
          }
        }
        state.launchpad.userPads[account][chainId] = newRes
      }

      Storage.set(UserFarmLPPoolKey, state.launchpad.userPads)
    },
    setLaunchpadsIntroduction: (
      state,
      action: PayloadAction<{
        chainId: ChainId
        data: {
          [key: string]: LaunchPadIntroduction
        }
      }>
    ) => {
      const { data, chainId } = action.payload
      state.launchpad.introduction[chainId] = data
      Storage.set(LaunchPadIntroductionKey, state.launchpad.introduction)
    },
    setV2Launchpad: (
      state,
      action: PayloadAction<{
        chainId: ChainId
        data: LaunchPadV2[]
      }>
    ) => {
      const { chainId, data } = action.payload
      if (!state.launchpadV2.pads) {
        state.launchpadV2.pads = {
          [ChainId.MAINNET]: [],
          [ChainId.TESTNET]: [],
          [ChainId.TESTNET_SEPOLIA]: [],
        }
      }
      state.launchpadV2.pads[chainId] = data
      Storage.set(LaunchpadV2PadsKey, state.launchpadV2.pads)
    },
    setV2LaunchpadItem: (
      state,
      action: PayloadAction<{
        chainId: ChainId
        data: LaunchPadV2
      }>
    ) => {
      const { chainId, data } = action.payload
      if (!state.launchpadV2.pads) {
        state.launchpadV2.pads = {
          [ChainId.MAINNET]: [],
          [ChainId.TESTNET]: [],
          [ChainId.TESTNET_SEPOLIA]: [],
        }
      }
      const index = state.launchpadV2.pads[chainId].map((item) => item.id.toLowerCase()).indexOf(data.id.toLowerCase())
      if (index >= 0) {
        state.launchpadV2.pads[chainId][index] = data
      } else {
        state.launchpadV2.pads[chainId].push(data)
      }
      Storage.set(LaunchpadV2PadsKey, state.launchpadV2.pads)
    },
    setLaunchpadV2UserPads: (
      state,
      action: PayloadAction<{
        chainId: ChainId
        account: string
        data: string[]
        method: 'add' | 'remove' | 'set'
      }>
    ) => {
      const { chainId, data, account, method } = action.payload
      if (!state.launchpadV2.userPads[account]) {
        state.launchpadV2.userPads[account] = {
          [ChainId.MAINNET]: [],
          [ChainId.TESTNET]: [],
          [ChainId.TESTNET_SEPOLIA]: [],
        }
      }

      if (method === 'set') {
        state.launchpadV2.userPads[account][chainId] = data
      } else if (method === 'add') {
        for (let i = 0, len = data.length; i < len; i++) {
          if (state.launchpadV2.userPads[account][chainId].indexOf(data[i]) === -1) {
            state.launchpadV2.userPads[account][chainId].push(data[i])
          }
        }
      } else {
        let newRes = []
        for (let i = 0, len = state.launchpadV2.userPads[account][chainId].length; i < len; i++) {
          const id = state.launchpadV2.userPads[account][chainId][i]
          if (data.indexOf(id) === -1) {
            newRes.push(id)
          }
        }
        state.launchpadV2.userPads[account][chainId] = newRes
      }

      Storage.set(LaunchpadV2UserPadsKey, state.launchpadV2.userPads)
    },
    setV2LaunchPadProjectInfo: (
      state,
      action: PayloadAction<{
        chainId: ChainId
        address: string
        data: ProjectIntro
      }>
    ) => {
      const { chainId, address, data } = action.payload
      if (!state.launchpadV2.projectInfo) {
        state.launchpadV2.projectInfo = {
          [ChainId.MAINNET]: {},
          [ChainId.TESTNET]: {},
          [ChainId.TESTNET_SEPOLIA]: {},
        }
      }

      state.launchpadV2.projectInfo[chainId][address] = data

      Storage.set(LaunchpadV2ProjectInfoKey, state.launchpadV2.projectInfo)
    },
    setSwapHistory: (
      state,
      action: PayloadAction<{
        data: SwapHistory
      }>
    ) => {
      const { data } = action.payload
      data.account = data.account.toLowerCase()
      if (!state.transactionHistory.swap) {
        state.transactionHistory.swap = []
      }
      state.transactionHistory.swap.push(data)
      Storage.set(TransactionHistoryKey, state.transactionHistory)
    },
    setLiquidityPool: (
      state,
      action: PayloadAction<{
        chainId: ChainId
        data: PairWithApr[]
      }>
    ) => {
      const { data, chainId } = action.payload
      if (!state.liquidityPools) {
        state.liquidityPools = {
          [ChainId.MAINNET]: [],
          [ChainId.TESTNET]: [],
          [ChainId.TESTNET_SEPOLIA]: [],
        }
      }
      state.liquidityPools[chainId] = data
      Storage.set(LiquidityPoolsKey, state.liquidityPools)
    },
    setUserLiquidityPools: (
      state,
      action: PayloadAction<{
        chainId: ChainId
        account: string
        data: UserLiquidityPool[]
      }>
    ) => {
      const { chainId, data, account } = action.payload
      if (!state.userLiquidityPools[account]) {
        state.userLiquidityPools[account] = {
          [ChainId.MAINNET]: [],
          [ChainId.TESTNET]: [],
          [ChainId.TESTNET_SEPOLIA]: [],
        }
      }

      state.userLiquidityPools[account][chainId] = data

      Storage.set(UserLiquidityPoolsKey, state.userLiquidityPools)
    },
    setBaseTokensInfos: (state, action: PayloadAction<{ data: BaseTokenInfo[] }>) => {
      state.baseTokensInfos = action.payload.data
      Storage.set(BaseTokensInfosKey, state.baseTokensInfos)
    },
  },
})

export const {
  setOverview,
  setAirdropInfo,
  setNettTokenInfoTotalSupply,
  updateFetchTokenList,
  setTokenPrice,
  setFarmAssetsPool,
  setCommunityTresuryNettBalance,
  setBlackHoldAddress,
  setFarmLPPool,
  setUserFarmPool,
  setLaunchpads,
  setUserPads,
  setLaunchpadsIntroduction,
  setV2Launchpad,
  setV2LaunchpadItem,
  setLaunchpadV2UserPads,
  setV2LaunchPadProjectInfo,
  setSwapHistory,
  setLiquidityPool,
  setBaseTokensInfos,
  setUserLiquidityPools,
} = netswapData.actions

export default netswapData.reducer
