// import { ChainId, TokenAmount } from '@netswap/sdk'
import React, { useMemo } from 'react'
// import { Text } from 'rebass'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
// import { useActiveWeb3React } from 'hooks'
// import { useETHBalances } from 'state/wallet/hooks'
import NettPrice from '../Nett/Price'
import SecondMenu from './secondMenu'
import ThemeButton from './themeButton'
import ConnectWalletBtn from './connectWalletButton'
import NavDrawer from './NavDrawer'
import { useActiveWeb3React } from 'hooks'
import { useMedia } from 'react-use'
import { useAppSelector } from 'state'

export type HeaderProps = {
  showSearch: boolean
}

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  /* top: 0; */
  position: relative;
  padding: 0 40px;
  height: 66px;
  background: ${({ theme }) => theme.v2.headerBg};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100vw;
    overflow-x: hidden;
    padding: 0 16px;
  `}
`

const LogoWrapper = styled.div`
  height: 100%;
  width: 250px;

  > a {
    height: 100%;
    display: flex;
    align-items: center;
  }

  img {
    height: 32px;
    width: auto;
    transition: transform 0.3s ease-in;
  }

  &:hover img {
    transform: rotate(-5deg);
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 70px;
  `}
`

const LogoText = styled.i`
  font-size: 24px;
  margin: 3px 0 0 6px !important;
  color: ${({ theme }) => theme.v2.t01};
  text-decoration: none;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
    
  `}
`

const MenuContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;
  transition: 0.2s ease-in-out;
  min-width: ;
  &.hide {
    flex: none;
    width: 0;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
    
  `}
`

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-left: 20px;
`

const MenuItemLink = styled(NavLink)`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: 'Poppins-Medium';

  color: ${({ theme }) => theme.v2.t01};
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.6;
  }

  &.active {
    color: #5347ff;
    font-weight: 600;
  }
`

const SearchInput = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 0;
`

const SearchText = styled.div`
  display: none;
  font-size: 14px;
  font-family: 'Poppins-Regular';
  font-weight: 400;
  color: ${({ theme }) => (theme.isDark ? '#c0c0c0' : '#68697c')};
`

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 20px;
  margin-left: 74px;
  transition: 0.2s ease-in-out;

  &.full-mode {
    flex: 1;
    margin-left: 0;
    cursor: pointer;

    ${SearchText} {
      display: block;
    }

    ${SearchInput} {
      width: 100%;
      justify-content: space-between;
      padding: 0 20px 0 30px;
      background-color: ${({ theme }) => (theme.isDark ? '#313144' : '#f1f1f3')};
      border-radius: 20px;
      overflow: hidden;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
    
  `}
`

const SearchBtn = styled.i`
  cursor: pointer;
  font-size: 28px;
`

const PCLogo = styled.img``

// const LngBtn = styled.div``

const RightWrapper = styled.div<{ logined?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  flex-grow: 1;
  ${({ theme, logined }) => theme.mediaWidth.upToSmall`
    flex-grow: ${logined ? '1' : '0'};
  `}
`

const StyledNettPrice = styled(NettPrice)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
    
  `}
`

const Noti = styled.div`
  width: 100%;
  background: linear-gradient(270deg, #00cfff 0%, #0057fd 100%);
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  padding: 10px 20px;
  font-size: 18px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 14px;
  `};
`

export default function Header(props: HeaderProps) {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const { account } = useActiveWeb3React()
  const below1400 = useMedia('(max-width: 1400px)')
  const { showNoti } = useAppSelector((state) => state.application)

  const isSearchPath = useMemo(() => {
    return location.pathname === '/search'
  }, [location])

  const logined = useMemo(() => {
    return !!account
  }, [account])

  return (
    <>
      {showNoti && (
        <Noti>
          Metis is decentralizing its sequencer, making history as the first Ethereum Layer 2 to do so.
          <br />
          Please be patient as the upgrade is currently taking place.
        </Noti>
      )}
      <HeaderWrapper>
        <LogoWrapper>
          <NavLink to={'/'} style={{ textDecoration: 'none' }}>
            <PCLogo src={require('assets/images/nebo_logo.png')} alt="" />
            <LogoText className="iconfont icon-text-logo" />
          </NavLink>
        </LogoWrapper>

        <RightWrapper logined={logined}>
          <MenuContainer className={props.showSearch ? 'hide' : ''}>
            <MenuItem>
              <SecondMenu
                menus={[
                  {
                    link: '/swap',
                    text: 'header.swap',
                    key: 'swap',
                  },
                  {
                    link: '/pool',
                    text: 'header.pool',
                    key: 'pool',
                  },
                ]}
              >
                {t('header.trade')}

                <i className="iconfont icon-arrow-down" />
              </SecondMenu>
            </MenuItem>

            <MenuItem>
              <SecondMenu
                menus={[
                  {
                    link: '/farm',
                    text: 'header.farm',
                    key: 'farm',
                  },
                  {
                    link: '/nett-staking',
                    text: 'header.stake',
                    key: 'stake',
                  },
                ]}
              >
                {t('header.earn')}

                <i className="iconfont icon-arrow-down" />
              </SecondMenu>
            </MenuItem>

            {/* {!below1200 && (
            <MenuItem>
              <MenuItemLink to={'/trade-competition'}>{t('header.competition')}</MenuItemLink>
            </MenuItem>
          )} */}

            <MenuItem>
              <SecondMenu
                menus={[
                  {
                    link: '/launchpad-v2',
                    text: 'header.launchpad_v2',
                    key: 'v2',
                  },
                  {
                    link: '/launchpad-instructions',
                    text: 'header.how_to_use_v2',
                    key: 'introduction',
                  },
                  {
                    link: '/launchpad',
                    text: 'header.launchpad_v1',
                    key: 'v1',
                  },
                ]}
              >
                {t('header.launchpad')}

                <i className="iconfont icon-arrow-down" />
              </SecondMenu>
            </MenuItem>
            <MenuItem>
              <MenuItemLink to={'/bridge'}>Bridge</MenuItemLink>
            </MenuItem>

            {!below1400 && (
              <MenuItem>
                <MenuItemLink to={'/analytics'}>{t('header.chart')}</MenuItemLink>
              </MenuItem>
            )}

            <MenuItem>
              <SecondMenu
                menus={[
                  {
                    link: '/trade-competition',
                    text: 'header.competition',
                    key: 'competition',
                    outlink: false,
                  },
                  {
                    link: 'https://vote.netswap.io',
                    text: 'header.vote',
                    key: 'vote',
                    outlink: true,
                  },
                ].concat(
                  below1400
                    ? [
                        {
                          link: '/analytics',
                          text: 'header.chart',
                          key: 'chart',
                          outlink: false,
                        },
                      ]
                    : []
                )}
              >
                <i className="iconfont icon-dot" style={{ fontSize: 18, fontWeight: 800 }} />
              </SecondMenu>
            </MenuItem>
          </MenuContainer>

          <SearchWrapper className={props.showSearch ? 'full-mode' : ''}>
            <SearchInput
              className="preview-input"
              onClick={() => {
                if (isSearchPath) {
                  history.goBack()
                } else {
                  history.push('/search')
                }
              }}
            >
              <SearchText>{t('header.search_place_holder')}</SearchText>

              {!isSearchPath && <SearchBtn className="iconfont icon-search" />}

              {isSearchPath && <SearchBtn className="iconfont icon-close" />}
            </SearchInput>
          </SearchWrapper>

          <StyledNettPrice />

          <ConnectWalletBtn />

          <ThemeButton />

          <NavDrawer />
        </RightWrapper>

        {/* <LngBtn></LngBtn> */}
      </HeaderWrapper>
    </>
  )
}
