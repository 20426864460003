import { Token, TokenAmount } from '@netswap/sdk'
import { useActiveWeb3React } from 'hooks'
import useAllowance from 'hooks/useAllowance'
import useContractByChainId from 'hooks/useContractByChainId'
import { useCallback, useMemo } from 'react'

import { useTokenContract } from '../hooks/useContract'
import { useSingleCallResult } from '../state/multicall/hooks'
import { getContract as getErc20ContractByAddress } from 'constants/tokens/erc20ByAddress'

export function useTokenAllowance(token?: Token, owner?: string, spender?: string): TokenAmount | undefined {
  const contract = useTokenContract(token?.address, false)

  const inputs = useMemo(() => [owner, spender], [owner, spender])
  const allowance = useSingleCallResult(contract, 'allowance', inputs).result

  return useMemo(
    () => (token && allowance ? new TokenAmount(token, allowance.toString()) : undefined),
    [token, allowance]
  )
}

export function useTokenAllowanceByContract(token?: Token, owner?: string, spender?: string, refresh?: boolean) {
  const tokenAddress = token ? token?.address : ''
  const { library, chainId } = useActiveWeb3React()
  const getErc20TokenContractFunc = useCallback(
    (chainId: number, web3: any) => {
      return getErc20ContractByAddress(tokenAddress)(chainId, web3)
    },
    [tokenAddress]
  )
  const myTokenContract = useContractByChainId(getErc20TokenContractFunc, library?.provider, chainId)
  const { allowance } = useAllowance(myTokenContract, spender, owner || '', refresh)

  return useMemo(
    () => (token && allowance ? new TokenAmount(token, allowance.toFixed()) : undefined),
    [token, allowance]
  )
}
