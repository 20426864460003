import React from 'react'

import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 720px;
  margin-top: 20px;
  border-radius: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
        width: calc(100vw - 2rem);
    `};
  .MuiPaper-root {
    background: ${({ theme }) => theme.v2.bg06};
    box-sizing: border-box;
    height: 48px;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .MuiInputBase-root {
      height: 24px;
      flex-grow: 1;
      input {
        color: ${({ theme }) => theme.v2.t01};
        ::placeholder {
          color: ${({ theme }) => theme.v2.t02};
          padding: 0;
        }
      }
    }

    .icon-search {
      font-size: 18px;
      color: ${({ theme }) => theme.v2.t01};
    }
  }

  border-radius: 12px;
`
interface Props {
  value?: string
  onChange?(value: string): void
}

export default function SearchPair({ value, onChange }: Props) {
  return (
    <Wrapper>
      <Paper>
        <InputBase
          placeholder="Search pool by name, symbol or address"
          inputProps={{ 'aria-label': 'Search pool by name, symbol or address' }}
          value={value}
          onChange={e => {
            if (onChange) {
              onChange(e.currentTarget.value)
            }
          }}
        />
        <IconButton aria-label="search">
          <i className="iconfont icon-search" />
        </IconButton>
      </Paper>
    </Wrapper>
  )
}
