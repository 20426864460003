import { useAppDispatch } from 'state'
import { useTransactionAdder } from 'state/transactions/hooks'
import { setConfimModal } from '../index'
import { useEtherGasEstimate } from 'hooks/use-estimate-gas'
import { TransactionResponse } from '@ethersproject/providers'
import { WrappedTokenInfo } from 'state/lists/hooks'

interface SendParams {
  contract: any
  method: string
  params: any
  sendParams?: {
    value: string
  }
  pendingText?: string
  summary?: string
  onSuccess?({ hash, res }: { hash: string; res: any }): Promise<void> | void
  onError?(e: any): void
  addTokenToMetamask?: WrappedTokenInfo
}

export default function useSend() {
  const addTransaction = useTransactionAdder()
  const dispatch = useAppDispatch()
  const getEstimate = useEtherGasEstimate()

  async function send({
    contract,
    method,
    params,
    sendParams,
    pendingText,
    summary,
    onSuccess,
    onError,
    addTokenToMetamask
  }: SendParams) {
    console.log(contract, method, sendParams, params)
    dispatch(
      setConfimModal({
        isOpen: true,
        attemptingTxn: true,
        hash: '',
        pendingText: pendingText || '',
        errorMessage: ''
      })
    )

    try {
      await new Promise(async resolve => {
        const res = await getEstimate(contract, method, params)

        if (!res.success) {
          dispatch(
            setConfimModal({
              isOpen: true,
              attemptingTxn: false,
              hash: '',
              pendingText: pendingText || '',
              errorMessage: res.errorMessage || 'Someting wrong, please try again later'
            })
          )
          resolve('')
          return
        }

        ;(sendParams ? contract[method](...params, sendParams) : contract[method](...params))
          .then(async (response: TransactionResponse) => {
            const receipt = await response.wait()
            if (!receipt?.transactionHash) {
              dispatch(
                setConfimModal({
                  isOpen: true,
                  attemptingTxn: false,
                  hash: '',
                  pendingText: '',
                  errorMessage: 'Transaction failed!'
                })
              )

              resolve('')
              return
            }
            const hash = receipt.transactionHash
            dispatch(
              setConfimModal({
                isOpen: true,
                attemptingTxn: false,
                hash,
                pendingText: pendingText || '',
                errorMessage: '',
                addTokenToMetamask
              })
            )
            if (summary) {
              addTransaction(
                {
                  hash
                } as any,
                {
                  summary
                }
              )
            }

            if (onSuccess) {
              await onSuccess({
                hash,
                res: receipt
              })
            }
            resolve('')
          })
          .catch((e: any) => {
            console.log('useSend Hooks error')
            console.error(e)
            dispatch(
              setConfimModal({
                isOpen: e.code === 4001 ? false : true,
                attemptingTxn: false,
                hash: '',
                pendingText: pendingText || '',
                errorMessage: e ? (e as any).message : undefined
              })
            )
            if (onError) {
              onError(e)
            }
            resolve('')
          })
      })
    } catch (e) {
      console.error(e)
    }
  }

  return send
}
