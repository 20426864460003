import React from 'react'
import { Button as RebassButton, ButtonProps } from 'rebass/styled-components'
import styled from 'styled-components'
import { ChevronDown, ChevronUp } from 'react-feather'
import { darken, transparentize } from 'polished'
import { RowBetween, RowFixed } from '../../Row'
import { StyledIcon } from '..'

const Base = styled(RebassButton)<{ open?: boolean; disabled?: boolean }>`
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 12px;
  cursor: pointer;
  outline: none;
  border: 1px solid transparent;
  outline: none;
  font-family: 'Poppins-Medium';
  border-bottom-right-radius: ${({ open }) => open && '0'};
  border-bottom-left-radius: ${({ open }) => open && '0'};
`

export default function ButtonStyled({ children, ...rest }: any) {
  return <Base {...rest}>{children}</Base>
}

export const ButtonLight = styled(Base)<{ color?: string }>`
  background-color: transparent;

  border-radius: 12px;
  border: 1px solid ${({ color, theme }) => (color ? color : theme.v2.t10)};
  color: ${({ color, theme }) => (color ? color : theme.v2.t10)};
  padding: 8px 16px;

  min-width: fit-content;
  white-space: nowrap;

  a {
    color: ${({ color, theme }) => (color ? darken(0.1, color) : theme.v2.t10)};
  }

  :hover {
    background-color: ${({ color, theme }) => (color ? transparentize(0.8, color) : transparentize(0.8, theme.v2.t10))};
  }
`

export function ButtonDropdown({
  disabled = false,
  children,
  open,
  ...rest
}: {
  disabled?: boolean
  open: boolean
} & ButtonProps) {
  return (
    <ButtonFadedWithoutBg disabled={disabled} open={open} {...rest}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        {open ? (
          <StyledIcon>
            <ChevronUp size={24} />
          </StyledIcon>
        ) : (
          <StyledIcon>
            <ChevronDown size={24} />
          </StyledIcon>
        )}
      </RowBetween>
    </ButtonFadedWithoutBg>
  )
}

export const ButtonDark = styled(Base)<{ color?: string }>`
  background-color: ${({ color, theme }) => (color ? color : theme.v2.t10)};
  color: ${({ color, theme }) => (color ? color : theme.isDark ? '#1c1924' : '#ffffff')};
  width: fit-content;
  border-radius: 12px;
  white-space: nowrap;

  :hover {
    background-color: ${({ color, theme }) => (color ? darken(0.1, color) : darken(0.1, theme.v2.t10))};
  }
`

export const ButtonFaded = styled(Base)`
  background-color: ${({ theme }) => theme.bg2};
  color: (255, 255, 255, 0.5);
  white-space: nowrap;

  :hover {
    opacity: 0.5;
  }
`

export const ButtonFadedWithoutBg = styled(ButtonFaded)`
  background-color: ${({ theme }) => (theme.isDark ? theme.bg2 : theme.bg3)};
`

export const OptionButton = styled.div<{
  active?: boolean
  disabled?: boolean
}>`
  font-weight: 500;
  width: fit-content;
  white-space: nowrap;
  padding: 0 20px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid ${({ active, theme }) => (active ? theme.v2.t10 : theme.v2.df02)};
  background-color: ${({ active, theme }) => (active ? theme.v2.t10 : theme.v2.df02)};
  color: ${({ active, theme }) => (active ? theme.v2.bg01 : theme.v2.t01)};
  font-family: 'Poppins-Medium';

  :hover {
    cursor: ${({ disabled }) => !disabled && 'pointer'};
  }
`

export const FilterGroup = styled(RowFixed)`
  background: ${({ theme }) => theme.v2.bg01};
  padding: 0;
  height: 30px;
  border-radius: 8px;
  align-items: center;
`

export const FilterText = styled.button<{ active?: boolean }>`
  cursor: pointer;
  font-weight: 500;
  font-family: 'Poppins-Medium';
  border: none;
  background-color: ${({ active, theme }) => (active ? (theme.isDark ? '#ffffff' : theme.v2.t05) : 'transparent')};
  font-size: 14px;
  padding: 0 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ active, theme }) => {
    if (theme.isDark) {
      return active ? '#020423' : '#ffffff'
    } else {
      return active ? '#ffffff' : '#020423'
    }
  }};
  outline: none;
  font-size: 12px;
  height: 30px;
  border-radius: 8px;

  /* @media screen and (max-width: 600px) {
    font-size: 14px;
  } */
`
