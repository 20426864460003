import { ChainId } from '@netswap/sdk'

export default {
  [ChainId.MAINNET]: {
    exchangeSubgraph: 'https://metisapi.0xgraph.xyz/subgraphs/name/netswap/exchange',
    farmSubgraph: 'https://metisapi.0xgraph.xyz/subgraphs/name/netswap/farm',
    boostedFarmSubgraph: 'https://metisapi.0xgraph.xyz/subgraphs/name/netswap/boosted-farm',
    graph: 'https://api.netswap.io/service/airdrop/api/airdrop/graphql',
    services: 'https://api.netswap.io/microservice/api/graphql',
    padSubgraph: 'https://metisapi.0xgraph.xyz/subgraphs/name/netswap/launchpad',
    padV2Subgraph: 'https://metisapi.0xgraph.xyz/subgraphs/name/netswap/launchpad-v2',
    tradeCompetition: 'https://api.netswap.io/service/trading-competition/api/trade/graphql',
    dexCandles: 'https://metisapi.0xgraph.xyz/subgraphs/name/netswap/dexcandles',
    blockSubgraph: 'https://metisapi.0xgraph.xyz/subgraphs/name/netswap/blocks',
    // indexNodeSubgraph: 'https://andromeda-index-node.thegraph.metis.io/graphql',
    exchangeSyncStatusUrl: 'https://metisapi.0xgraph.xyz/api/public/query_deployment?subgraph_id=QmXUxZjSRhhDuJijtSRu1DzYDcFoKSTM98HkJQiWMi7gMj'
  },
  [ChainId.TESTNET]: {
    exchangeSubgraph: 'https://goerli.thegraph.metis.io/subgraphs/name/netswap/exchange',
    farmSubgraph: 'https://goerli.thegraph.metis.io/subgraphs/name/netswap/farm',
    boostedFarmSubgraph: 'https://goerli.thegraph.metis.io/subgraphs/name/netswap/boosted-farm',
    graph: 'https://api.netswap.io/service/airdrop/api/airdrop/graphql',
    services: 'https://api.netswap.io/microservice_test/api/graphql',
    padSubgraph: 'https://goerli.thegraph.metis.io/subgraphs/name/netswap/launchpad',
    padV2Subgraph: 'https://goerli.thegraph.metis.io/subgraphs/name/netswap/launchpad-v2',
    tradeCompetition: 'https://api.netswap.io/service/trading-competition/api/trade/graphql',
    dexCandles: 'https://goerli.thegraph.metis.io/subgraphs/name/netswap/dexcandles',
    blockSubgraph: 'https://goerli.thegraph.metis.io/subgraphs/name/netswap/blocks',
    // indexNodeSubgraph: 'https://index-node.stardust.thegraph.metis.io/graphql',
    exchangeSyncStatusUrl: 'https://metisapi.0xgraph.xyz/api/public/query_deployment?subgraph_id=QmXUxZjSRhhDuJijtSRu1DzYDcFoKSTM98HkJQiWMi7gMj'
  },
  // need config
  [ChainId.TESTNET_SEPOLIA]: {
    exchangeSubgraph: 'https://goerli.thegraph.metis.io/subgraphs/name/netswap/exchange',
    farmSubgraph: 'https://goerli.thegraph.metis.io/subgraphs/name/netswap/farm',
    boostedFarmSubgraph: 'https://goerli.thegraph.metis.io/subgraphs/name/netswap/boosted-farm',
    graph: 'https://api.netswap.io/service/airdrop/api/airdrop/graphql',
    services: 'https://api.netswap.io/microservice_test/api/graphql',
    padSubgraph: 'https://goerli.thegraph.metis.io/subgraphs/name/netswap/launchpad',
    padV2Subgraph: 'https://goerli.thegraph.metis.io/subgraphs/name/netswap/launchpad-v2',
    tradeCompetition: 'https://api.netswap.io/service/trading-competition/api/trade/graphql',
    dexCandles: 'https://goerli.thegraph.metis.io/subgraphs/name/netswap/dexcandles',
    blockSubgraph: 'https://goerli.thegraph.metis.io/subgraphs/name/netswap/blocks',
    // indexNodeSubgraph: 'https://index-node.stardust.thegraph.metis.io/graphql',
    exchangeSyncStatusUrl: 'https://metisapi.0xgraph.xyz/api/public/query_deployment?subgraph_id=QmXUxZjSRhhDuJijtSRu1DzYDcFoKSTM98HkJQiWMi7gMj'
  },
}
