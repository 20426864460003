import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Numeral from 'numeral'
import { useGetBaseTokensOverviewInfo } from 'state/data/hooks/useGetTokenInfo'
import { BaseTokenInfo } from 'state/data/index'
import Container from '@material-ui/core/Container'

import { ButtonV2 } from 'components/Button'
import { TYPE } from 'theme'
import BubbleImgSrc from 'assets/images/landing_page/overview_bubble.png'
import { useMedia } from 'react-use'
import Row from 'components/Row'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background-image: linear-gradient(121deg, rgba(118, 171, 255, 0.17) 0%, rgba(82, 79, 148, 0) 100%);
  z-index: 1;
`

const Triangle = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-width: 50px 50vw 50px 50vw;
  border-style: solid;
  border-color: transparent transparent ${({ theme }) => theme.v2.bg01} ${({ theme }) => theme.v2.bg01};
  z-index: 2;
`

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  z-index: 2;
  position: relative;
  height: 700px;
`

const BubbleContainer = styled.div`
  height: 100%;
  flex: 1;
  position: relative;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    position: absolute;
    z-index: 1;
    display: flex;
    height: 304px;
    justify-content: center;
    align-items: center;
    bottom: 101px;
    left: 0;
  `}
`

const BubbleImg = styled.img`
  height: auto;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 10px;
`

const OverviewContainer = styled.div`
  height: 100%;
  margin-left: 33px;
  flex: 2;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
  padding-right: 200px;
  padding-top: 20px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding-right: 160px;
  `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-right: 0;
    margin-left: 0;
  `}
`

const Title = styled(TYPE.title)`
  font-size: 42px;
  line-height: 56px;
  margin-bottom: 12px !important;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 20px;
    line-height: 28px;
  `}
`

const Description = styled(TYPE.main)`
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 30px !important;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
    line-height: 18px;
  `}
`

const TokenStatPart = styled.div`
  display: flex;
  flex-direction: row;
  algin-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 33px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: space-between;
    margin-bottom: 0;
  `}
`

const StatGroup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  &:not(:last-child):after {
    right: 18px;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: ${({ theme }) => theme.v2.t01};
    content: '';
    position: absolute;
    opacity: 0.18;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 36px;

    &:not(:last-child):after {
      opacity: 0;
    }
  `}
`

const StatGroupLabel = styled.span`
  color: ${({ theme }) => (theme.isDark ? '#00cfff' : '#5347ff')};
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  font-family: 'Poppins-SemiBold';
  margin-bottom: 4px;
`

const StatGroupValue = styled.span`
  color: ${({ theme }) => theme.v2.t01};
  font-size: 24px;
  font-family: 'Poppins-Bold';
  font-weight: 600;
  line-height: 33px;
`

const BuyBtn = styled(ButtonV2)`
  width: 208px;
  height: 48px;
  box-shadow: 0px 4px 0px 0px #171717;
  border-radius: 16px;
  margin-top: 6px;
`

export default function Overview() {
  const { t } = useTranslation()
  const history = useHistory()

  const isMobile = useMedia('(max-width: 780px)')

  const [tokensInfo, setTokensInfo] = useState<BaseTokenInfo[]>([
    {
      symbol: 'METIS',
      price: 0,
      maxSupply: 0,
      circulatingSupply: 0,
      fdv: 0,
    },
    {
      symbol: 'NETT',
      price: 0,
      maxSupply: 0,
      circulatingSupply: 0,
      fdv: 0,
    },
  ])

  const baseTokensInfos = useGetBaseTokensOverviewInfo()

  useEffect(() => {
    if (baseTokensInfos && baseTokensInfos.length > 0) {
      setTokensInfo(baseTokensInfos)
    }
  }, [baseTokensInfos])

  return (
    <Wrapper>
      <Triangle />

      <Container maxWidth="lg">
        <ContentWrapper>
          <BubbleContainer>
            <BubbleImg src={BubbleImgSrc} alt="" />
          </BubbleContainer>

          <OverviewContainer>
            <Title>{t('home.overview.title')}</Title>

            <Description>{t('home.overview.description')}</Description>

            {!isMobile &&
              tokensInfo.map((info) => {
                return (
                  <TokenStatPart key={info.symbol}>
                    <StatGroup>
                      <StatGroupLabel>{t('home.overview.token_price', { token: info.symbol })}</StatGroupLabel>
                      <StatGroupValue>{Numeral(info.price).format('$0.[00]')}</StatGroupValue>
                    </StatGroup>

                    <StatGroup>
                      <StatGroupLabel>{t('home.overview.fdv')}</StatGroupLabel>
                      <StatGroupValue>{Numeral(info.fdv).format('$0,0')}</StatGroupValue>
                    </StatGroup>

                    <StatGroup>
                      <StatGroupLabel>{t('home.overview.max_supply')}</StatGroupLabel>
                      <StatGroupValue>{Numeral(info.maxSupply).format('0,0')}</StatGroupValue>
                    </StatGroup>

                    <StatGroup>
                      <StatGroupLabel>{t('home.overview.circulating_supply')}</StatGroupLabel>
                      <StatGroupValue>{Numeral(info.circulatingSupply).format('0,0')}</StatGroupValue>
                    </StatGroup>
                  </TokenStatPart>
                )
              })}

            {isMobile &&
              tokensInfo.map((info) => {
                return (
                  <TokenStatPart key={info.symbol}>
                    <Row>
                      <StatGroup>
                        <StatGroupLabel>{t('home.overview.token_price', { token: info.symbol })}</StatGroupLabel>
                        <StatGroupValue>{Numeral(info.price).format('$0.[00]')}</StatGroupValue>
                      </StatGroup>

                      <StatGroup>
                        <StatGroupLabel>{t('home.overview.fdv')}</StatGroupLabel>
                        <StatGroupValue>{Numeral(info.fdv).format('$0,0')}</StatGroupValue>
                      </StatGroup>
                    </Row>

                    <Row>
                      <StatGroup>
                        <StatGroupLabel>{t('home.overview.max_supply')}</StatGroupLabel>
                        <StatGroupValue>{Numeral(info.maxSupply).format('0,0')}</StatGroupValue>
                      </StatGroup>

                      <StatGroup>
                        <StatGroupLabel>{t('home.overview.circulating_supply')}</StatGroupLabel>
                        <StatGroupValue>{Numeral(info.circulatingSupply).format('0,0')}</StatGroupValue>
                      </StatGroup>
                    </Row>
                  </TokenStatPart>
                )
              })}

            {!isMobile && (
              <BuyBtn onClick={() => history.push('/swap')}>{t('home.overview.buy_token', { token: 'NETT' })}</BuyBtn>
            )}

            {isMobile && (
              <Row justify="center">
                <BuyBtn onClick={() => history.push('/swap')}>{t('home.overview.buy_token', { token: 'NETT' })}</BuyBtn>
              </Row>
            )}
          </OverviewContainer>
        </ContentWrapper>
      </Container>
    </Wrapper>
  )
}
