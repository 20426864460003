/* eslint-disable react-hooks/exhaustive-deps */
import useTokenPrice from './hooks/useTokenPrice'
// import useUpdateAirdropData from './hooks/useUpdateAirdropData'
import useGetNettTokenInfo from './hooks/useGetNettTokenInfo'

export default function DataUpdater() {
  //   useUpdateAirdropData()
  useGetNettTokenInfo()
  useTokenPrice()

  return null
}
