/* eslint-disable react-hooks/exhaustive-deps */
import { ChainId } from '@netswap/sdk'
import { NETWORK_CHAIN_ID } from 'connectors'
import getUserLiquidityPositions from 'gql/subgraph/user-liquidity-pool'
import { useActiveWeb3React } from 'hooks'
import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import { setUserLiquidityPools } from '../../index'

export default function useGetUserLiquidityPools() {
  const { chainId, account } = useActiveWeb3React()
  const dispatch = useAppDispatch()
  const { userLiquidityPools } = useAppSelector((state) => state.netswapData)
  async function getUserLiquidityPositionsFunc() {
    if (!account) return
    const res = await getUserLiquidityPositions(account)
    const cid = chainId || NETWORK_CHAIN_ID
    if (res.length) {
      dispatch(
        setUserLiquidityPools({
          chainId: cid,
          account,
          data: res,
        })
      )
    }
  }

  useEffect(() => {
    getUserLiquidityPositionsFunc()
    let interval = setInterval(() => {
      getUserLiquidityPositionsFunc()
    }, 15000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [account])

  return useMemo(() => {
    if (account) {
      if (chainId === ChainId.TESTNET_SEPOLIA) {
        return []
      }
      return userLiquidityPools && userLiquidityPools[account]
        ? userLiquidityPools[account][(chainId || NETWORK_CHAIN_ID) as ChainId]
        : []
    }
    return []
  }, [userLiquidityPools, account, chainId])
}
