/* eslint-disable react-hooks/exhaustive-deps */

import { ChainId } from '@netswap/sdk'
import BigNumber from 'bignumber.js'
import { NETWORK_CHAIN_ID } from 'connectors'
import { useActiveWeb3React } from 'hooks'
import Numeral from 'numeral'
import React, { useEffect, useMemo, useState } from 'react'
import useSend from 'state/contract/hooks/useSend'
import { WrappedTokenInfo } from 'state/lists/hooks'
import { getTokenImage } from 'utils'

import StakeNett from './components/StakeNett'
import Title from './components/Title'
import useGetVeNettInfo from './hooks/useGetveNettInfo'

export default function VeNettStaking() {
  const { account, chainId } = useActiveWeb3React()
  const {
    totalSupply,
    userStakeAmount,
    stakeContract,
    veNettContract,
    getUserInfo,
    getTotalStakedNett,
    getTotalSupply,
    totalStakedUSD,
    youStakedUSD,
    speedUpEndTimestamp,
    getUserVeNETTBalance,
    getPendingVeNETT,
    totalPendingVeNett,
    pendingVeNETT,
    userVeNETTBalance,
    pendingVeNettProgress
  } = useGetVeNettInfo()

  const [veNETTPerSec, setVeNETTPerSec] = useState(new BigNumber(0))
  const send = useSend()

  async function getVeNETTPerSec() {
    if (stakeContract) {
      const res = await stakeContract.veNETTPerSharePerSec()
      if (res) {
        setVeNETTPerSec(res?.toString() ? new BigNumber(res.toString()).shiftedBy(-18) : new BigNumber(0))
      }
    }
  }

  useEffect(() => {
    getVeNETTPerSec()
  }, [stakeContract])

  const veNettPerDay = useMemo(() => {
    return veNETTPerSec
      .multipliedBy(userStakeAmount)
      .multipliedBy(86400)
      .toNumber()
  }, [veNETTPerSec, userStakeAmount])

  async function stake({ inputAmount, onSuccess }: { inputAmount: BigNumber; onSuccess?: () => void }) {
    if (stakeContract) {
      await send({
        contract: stakeContract,
        method: 'deposit',
        params: [inputAmount.shiftedBy(18).toFixed(0)],
        pendingText: `Staking ${inputAmount.toFixed()} NETT`,
        summary: `Staked ${inputAmount.toFixed()} NETT`,
        onSuccess() {
          getUserInfo()

          getUserVeNETTBalance()
          getPendingVeNETT()
          getTotalSupply()
          getTotalStakedNett()
          if (onSuccess) {
            onSuccess()
          }
        }
      })
    }
  }

  async function unStake({ inputAmount, onSuccess }: { inputAmount: BigNumber; onSuccess?: () => void }) {
    if (userStakeAmount.isGreaterThan(0) && inputAmount.isGreaterThan(0)) {
      send({
        contract: stakeContract,
        method: 'withdraw',
        params: [inputAmount.shiftedBy(18).toFixed()],
        pendingText: `Unstaking ${inputAmount.toFixed()} NETT`,
        summary: `Unstake ${inputAmount.toFixed()} NETT`,
        onSuccess() {
          getUserInfo()

          getUserVeNETTBalance()
          getPendingVeNETT()
          getTotalSupply()
          getTotalStakedNett()
          if (onSuccess) {
            onSuccess()
          }
        }
      })
    }
  }

  async function claimPendingVeNETT({ inputAmount, onSuccess }: { inputAmount: BigNumber; onSuccess?: () => void }) {
    if (pendingVeNETT.isGreaterThan(0)) {
      send({
        contract: stakeContract,
        method: 'claim',
        params: [],
        pendingText: `Claim pending veNETT`,
        summary: `Claimed pending veNETT`,
        addTokenToMetamask: {
          chainId: chainId || (NETWORK_CHAIN_ID as ChainId),
          name: 'veNETT',
          symbol: 'veNETT',
          address: veNettContract?.address || '',
          decimals: 18,
          logoURI: getTokenImage(veNettContract?.address || '')
        } as WrappedTokenInfo,
        onSuccess() {
          getUserVeNETTBalance()
          getPendingVeNETT()
          getTotalSupply()
          getUserInfo()
          if (onSuccess) {
            onSuccess()
          }
        }
      })
    }
  }

  useEffect(() => {
    getUserInfo()
  }, [account, stakeContract])

  const veNettSpeedConfig = useMemo(() => {
    return {
      endTime: speedUpEndTimestamp,
      total: totalPendingVeNett?.toFixed(3) || '0',
      progress: pendingVeNettProgress,
      current: (pendingVeNETT || new BigNumber(0)).plus(userVeNETTBalance).toFixed(3)
    }
  }, [speedUpEndTimestamp, pendingVeNETT, userVeNETTBalance, totalPendingVeNett, pendingVeNettProgress])

  return (
    <>
      <Title
        title="Earn veNETT"
        desc="Accrue veNETT to boost yield farming and participate in future governance"
        logo={require('assets/images/stake/v2/veNett-logo.png')}
      />
      <StakeNett
        type="veNETT"
        tvl={Numeral(totalStakedUSD).format('$ 0,0')}
        stakeBalance={userStakeAmount}
        stakeTokenUSD={Numeral(youStakedUSD).format('$ 0,0.[00]')}
        rewardToken={{
          symbol: 'veNETT',
          name: 'veNETT Token',
          decimals: 18,
          address: veNettContract?.address || ''
        }}
        unclaimedRewardBalace={pendingVeNETT}
        approveAddress={stakeContract?.address || ''}
        stake={stake}
        unStake={unStake}
        // userRewardPerDay={userRewardPerDay}
        claimPendingRewardToken={claimPendingVeNETT}
        totalRewardTokenSupply={Numeral(totalSupply).format('0,0.[00]')}
        userRewardTokenBalance={userVeNETTBalance}
        veNettSpeedConfig={veNettSpeedConfig}
        userRewardPerDay={veNettPerDay}
      />
    </>
  )
}
