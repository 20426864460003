import React, { createContext, useContext, useReducer, useMemo, useCallback, useEffect } from 'react'

const NETSWAP = 'NETSWAP'

const VERSION = 'VERSION'
const CURRENT_VERSION = 0
const LAST_SAVED = 'LAST_SAVED'
const DISMISSED_PATHS = 'DISMISSED_PATHS'
const SAVED_ACCOUNTS = 'SAVED_ACCOUNTS'
const SAVED_TOKENS = 'SAVED_TOKENS'
const SAVED_PAIRS = 'SAVED_PAIRS'

const DARK_MODE = 'DARK_MODE'

const UPDATABLE_KEYS = [DARK_MODE, DISMISSED_PATHS, SAVED_ACCOUNTS, SAVED_PAIRS, SAVED_TOKENS]

const UPDATE_KEY = 'UPDATE_KEY'

export type PairInStore = {
  address: string
  token0Address: string
  token1Address: string
  token0Symbol: string
  token1Symbol: string
}

export type TokenInStore = {
  symbol: string
}

type LocalStorageState = {
  [DISMISSED_PATHS]: {
    [key: string]: boolean
  }
  [SAVED_ACCOUNTS]: string[]
  [SAVED_TOKENS]: {
    [key: string]: TokenInStore | null
  }
  [SAVED_PAIRS]: {
    [key: string]: PairInStore | null
  }
}

const defaultLocalStorage = {
  [DISMISSED_PATHS]: {},
  [SAVED_ACCOUNTS]: [],
  [SAVED_TOKENS]: {},
  [SAVED_PAIRS]: {},
}

const LocalStorageContext = createContext<[LocalStorageState, any]>([defaultLocalStorage, {}])

function useLocalStorageContext() {
  return useContext(LocalStorageContext)
}

function reducer(state: LocalStorageState, { type, payload }: { type: string; payload: any }) {
  switch (type) {
    case UPDATE_KEY: {
      const { key, value } = payload
      if (!UPDATABLE_KEYS.some((k) => k === key)) {
        throw Error(`Unexpected key in LocalStorageContext reducer: '${key}'.`)
      } else {
        return {
          ...state,
          [key]: value,
        }
      }
    }
    default: {
      throw Error(`Unexpected action type in LocalStorageContext reducer: '${type}'.`)
    }
  }
}

function init() {
  try {
    const parsed = JSON.parse(window.localStorage.getItem(NETSWAP) || '')
    if (parsed[VERSION] !== CURRENT_VERSION) {
      // this is where we could run migration logic
      return defaultLocalStorage
    } else {
      return { ...defaultLocalStorage, ...parsed }
    }
  } catch {
    return defaultLocalStorage
  }
}

export default function Provider({ children }: { children: React.ReactChildren | React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, undefined, init)

  const updateKey = useCallback((key: any, value: any) => {
    dispatch({ type: UPDATE_KEY, payload: { key, value } })
  }, [])

  return (
    <LocalStorageContext.Provider value={useMemo(() => [state, { updateKey }], [state, updateKey])}>
      {children as any}
    </LocalStorageContext.Provider>
  )
}

export function Updater() {
  const [state] = useLocalStorageContext()

  useEffect(() => {
    window.localStorage.setItem(NETSWAP, JSON.stringify({ ...state, [LAST_SAVED]: Math.floor(Date.now() / 1000) }))
  })

  return null
}

export function useSavedAccounts(): [string[], (account: string) => void, (account: string) => void] {
  const [state, { updateKey }] = useLocalStorageContext()
  const savedAccounts = state?.[SAVED_ACCOUNTS]

  function addAccount(account: string) {
    let newAccounts = state?.[SAVED_ACCOUNTS]
    newAccounts.push(account)
    updateKey(SAVED_ACCOUNTS, newAccounts)
  }

  function removeAccount(account: string) {
    let newAccounts = state?.[SAVED_ACCOUNTS]
    let index = newAccounts.indexOf(account)
    if (index > -1) {
      newAccounts.splice(index, 1)
    }
    updateKey(SAVED_ACCOUNTS, newAccounts)
  }

  return [savedAccounts, addAccount, removeAccount]
}

export function useSavedPairs(): [
  { [key: string]: PairInStore | null },
  (val: PairInStore) => void,
  (address: string) => void
] {
  const [state, { updateKey }] = useLocalStorageContext()
  const savedPairs = state?.[SAVED_PAIRS]

  function addPair({ address, token0Address, token1Address, token0Symbol, token1Symbol }: PairInStore) {
    let newList = state?.[SAVED_PAIRS]
    newList[address] = {
      address,
      token0Address,
      token1Address,
      token0Symbol,
      token1Symbol,
    }
    updateKey(SAVED_PAIRS, newList)
  }

  function removePair(address: string) {
    let newList = state?.[SAVED_PAIRS]
    newList[address] = null
    updateKey(SAVED_PAIRS, newList)
  }

  return [savedPairs, addPair, removePair]
}

export function useSavedTokens(): [
  {
    [key: string]: TokenInStore | null
  },
  (address: string, symbol: string) => void,
  (address: string) => void
] {
  const [state, { updateKey }] = useLocalStorageContext()
  const savedTokens = state?.[SAVED_TOKENS]

  function addToken(address: string, symbol: string) {
    let newList = state?.[SAVED_TOKENS]
    newList[address] = {
      symbol,
    }
    updateKey(SAVED_TOKENS, newList)
  }

  function removeToken(address: string) {
    let newList = state?.[SAVED_TOKENS]
    newList[address] = null
    updateKey(SAVED_TOKENS, newList)
  }

  return [savedTokens, addToken, removeToken]
}

export function usePathDismissed(path: string): [boolean, () => void] {
  const [state, { updateKey }] = useLocalStorageContext()
  const pathDismissed = state?.[DISMISSED_PATHS]?.[path]
  function dismiss() {
    let newPaths = state?.[DISMISSED_PATHS]
    newPaths[path] = true
    updateKey(DISMISSED_PATHS, newPaths)
  }

  return [pathDismissed, dismiss]
}
