import React, { useState, useEffect, useMemo } from 'react'
import { ethers } from 'ethers'
import styled from 'styled-components'
import PlaceHolder from '../../assets/images/unknow.svg'
import { useActiveWeb3React } from 'hooks'
import { Config as wNettConfig } from 'constants/tokens/wNett'
import { VeNETTConfig } from 'constants/tokens/veNett'
import { ChainId } from '@netswap/sdk'
import { NETWORK_CHAIN_ID } from 'connectors'

function isAddress(value: string) {
  try {
    return ethers.utils.getAddress(value.toLowerCase()).toLowerCase()
  } catch {
    return false
  }
}

const BAD_IMAGES: any = {}

const Inline = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`

const Image = styled.img<{
  size: string
}>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background-color: #020423;
  border-radius: 50%;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  position: relative;
`

const DefaultLogo = styled.div<{
  size: string
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    width: ${({ size }) => size};
    height: ${({ size }) => size};
  }
`

export default function TokenLogo({ address = '', header = false, size = '24px', style = {}, ...rest }) {
  const [error, setError] = useState(false)
  const { chainId } = useActiveWeb3React()
  const cid: ChainId = chainId || NETWORK_CHAIN_ID
  const tokenAddress = address?.toLowerCase()

  const path = useMemo(() => {
    if (tokenAddress === wNettConfig[cid].address.toLowerCase()) {
      return require('assets/images/stake/v2/wNett-logo.png')
    } else if (tokenAddress === VeNETTConfig[cid].address.toLowerCase()) {
      return require('assets/images/stake/v2/veNett-logo.png')
    } else {
      return `https://raw.githubusercontent.com/Netswap/tokens/master/assets/${isAddress(
        tokenAddress
      )}/logo.png`.toLowerCase()
    }
  }, [tokenAddress, cid])

  useEffect(() => {
    setError(false)
  }, [address])

  if (error || BAD_IMAGES[address]) {
    return (
      <Inline>
        <Image {...rest} alt={''} src={PlaceHolder} size={size} style={{ ...style, background: '#fff' }} />
      </Inline>
    )
  }

  if (tokenAddress === '0xdeaddeaddeaddeaddeaddeaddeaddeaddead0000') {
    return (
      <DefaultLogo size={size} style={style} {...rest}>
        <img
          src="/metis.svg"
          style={{
            boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.075)',
            borderRadius: '24px'
          }}
          alt=""
        />
      </DefaultLogo>
    )
  }

  return (
    <Inline>
      <Image
        style={style}
        {...rest}
        alt={''}
        src={path}
        size={size}
        onError={event => {
          BAD_IMAGES[address] = true
          setError(true)
          event.preventDefault()
        }}
      />
    </Inline>
  )
}
