import { ChainId } from '@netswap/sdk'
import ABI from './NETTFarm_ABI.json'

export const StakeConfig = {
  [ChainId.MAINNET]: {
    address: '0x9d1dbB49b2744A1555EDbF1708D64dC71B0CB052',
    abi: ABI,
  },
  [ChainId.TESTNET]: {
    address: '0xA42f8cc09E32a8D1d302580A58e57AbB156f655c',
    abi: ABI,
  },
  // need config
  [ChainId.TESTNET_SEPOLIA]: {
    address: '0x2B33F38F58cfD118aE34e6F6392D43bB9a28D543',
    abi: ABI,
  },
}

export { default as RewarderABI } from './SimpleRewarderPerSec_ABI.json'
