/* eslint-disable react-hooks/exhaustive-deps */
import { ChainId } from '@netswap/sdk'
import { NETWORK_CHAIN_ID } from 'connectors'
import getFarmLPPool from 'gql/microservices/farm-lp-pools'
import { useActiveWeb3React } from 'hooks'
import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import { setFarmLPPool } from '../index'

export default function useGetFarmLpPools() {
  const { chainId } = useActiveWeb3React()
  const dispatch = useAppDispatch()
  const { farm } = useAppSelector((state) => state.netswapData)
  async function getPoolsData() {
    const res = await getFarmLPPool()
    const cid = chainId || NETWORK_CHAIN_ID
    if (res) {
      dispatch(
        setFarmLPPool({
          chainId: cid,
          data: res,
        })
      )
    }
  }

  useEffect(() => {
    getPoolsData()
    let interval = setInterval(() => {
      getPoolsData()
    }, 30000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  return useMemo(() => {
    return farm.lpPool && farm.lpPool[(chainId || NETWORK_CHAIN_ID) as ChainId]
      ? farm.lpPool[(chainId || NETWORK_CHAIN_ID) as ChainId]
      : []
  }, [farm.lpPool, chainId])
}

export function useGetFarmLpPoolById(id: string) {
  const pools = useGetFarmLpPools()

  return useMemo(() => {
    return pools.find((item) => item.id === id)
  }, [id, pools])
}
