import React, { useMemo } from 'react'
import styled from 'styled-components'
import LinearProgress from '@material-ui/core/LinearProgress'
import { RowBetween } from '../../../../components/Row'
import moment from 'moment'
import Value from 'components/Value'
import { useHistory } from 'react-router-dom'
// import UnlimitedAbi from 'constants/launchpadV2/unlimited.json'
import { Phase } from 'hooks/contract/useLaunchpadV2PadContract'
import { steps } from '../../config'
import { LauchpadV2WithPhase } from 'state/data/hooks/useGetLaunchpadV2Data'
import TokenLogo from 'components/TokenImage'
import { TYPE } from 'theme'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
interface Props {
  data: LauchpadV2WithPhase[]
}

const ListsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 40px;

  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;

    img {
      margin-bottom: 16px;
      width: 80px;
    }
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-content: center;
  `};
  .listsCon {
    margin-right: 12px;
    /* min-width: 380px; */
    background: ${({ theme }) => theme.v2.bg02};
    border-radius: 12px;
    width: calc(50% - 12px);
    padding: 10px 10px 28px 10px;
    display: flex;
    flex-flow: column;
    border: 1px solid rgba(212, 209, 209, 0.06);
    margin-bottom: 24px;
    cursor: pointer;

    ${({ theme }) => theme.mediaWidth.upToLarge`
      width: calc(50% - 12px);
      .listsTipCon {
        padding: 10px 10px 10px 0;
      }
    `};
    ${({ theme }) => theme.mediaWidth.upToMedium`
      width: 100%;
      margin-right: 0;
    `};

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      min-width: unset;
    `};
    h2,
    h3,
    p {
      margin: 0;
    }
    .whitelistTip {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #c42a61;
      padding: 3px 10px;
      background: rgba(196, 42, 97, 0.05);
      border-radius: 40px;
    }
    .peopleNumbers {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px;
      background: ${({ theme }) => theme.v2.bg04};
      border-radius: 6px;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: ${({ theme }) => theme.v2.t01};
      img {
        margin-right: 5px;
      }
    }
    .listtimeTip {
      background: #f00;
      color: ${({ theme }) => theme.v2.t01};
      font-size: 12px;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 0 8px 0 0;
    }

    /* listsTopCon start */
    .listsTopCon {
      width: 100%;
      margin: 20px 0 24px;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: space-evenly;
      img {
        max-width: 100px;
        max-height: 100px;
        border-radius: 50%;
      }
      h2 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: ${({ theme }) => theme.v2.t01};
        margin: 10px auto 8px;
      }
      button {
        border: none;
        padding: 0px 14px;
        height: 24px;
        background: ${({ theme }) => theme.v2.bg04};

        border-radius: 13px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        &.depositing,
        &.Prepare {
          color: ${({ theme }) => theme.v2.t01};
        }
        &.deposit,
        &.Deposit {
          color: ${({ theme }) => theme.v2.t01};
        }
        &.launch,
        &.Launch {
          color: ${({ theme }) => theme.v2.t01};
        }
        &.stopped {
          color: #fff;
          background: red;
        }
      }
      p {
        max-width: 300px;
        margin-top: 8px;
        font-weight: 300;
        font-size: 12px;
        line-height: 19px;
        text-align: center;
        color: #989dae;
      }
    }
    /* listsTopCon end */

    /* listsInfoCon start */
    .listsInfoCon {
      padding: 0 10px;
      h2 {
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: #767c93;
      }
      .saleInfo,
      .tierInfo {
        h3 {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: ${({ theme }) => theme.v2.t01};
        }
        p {
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          color: ${({ theme }) => theme.v2.t01};
          /* transform: scale(0.8); */
          span {
            color: #767c93;
          }
        }
      }
      .saleInfo {
        margin: 4px 0 8px;
        align-items: flex-end;
      }
      .tierInfo {
        margin: 18px 0 24px;
      }
      .durationCon {
        margin: 10px 0 32px;
      }
      .durationInfo {
        width: calc(50% - 5px);
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        h3 {
          padding: 2px;
          border-radius: 2px;
          width: 40px;
          font-weight: 400;
          font-size: 12px;
          line-height: 19px;
          text-align: center;
          color: ${({ theme }) => theme.v2.t01};
          background: ${({ theme }) => theme.v2.bg04};
          &.endTitle {
            color: #f64278;
            background: rgba(246, 66, 120, 0.1);
          }
        }
        p {
          margin-top: 8px;
          font-weight: 400;
          font-size: 13px;
          line-height: 22px;
          color: ${({ theme }) => theme.v2.t01};
        }
      }

      /* progress */
      .MuiLinearProgress-root {
        height: 10px;
        border-radius: 15px;
      }
      .MuiLinearProgress-colorPrimary {
        background: #4b2958;
      }
      .MuiLinearProgress-barColorPrimary {
        background: #1667f6;
        border-radius: 15px;
      }
    }
    /* listsInfoCon end */

    /* listsBottomCon start */
    .listsBottomCon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      ${({ theme }) => theme.mediaWidth.upToMoreLarge`
        padding: 0;
      `};
      .listBottomInfo {
        display: flex;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #767c93;
        p {
          font-weight: 700;
          color: ${({ theme }) => theme.v2.t01};
          margin-left: 5px;
        }
        &.listBottomSolidInfo {
          padding: 6px;
          background: #3b2046;
          border-radius: 6px;
        }
      }
    }
    /* listsBottomCon end */
  }
`

export default function Lists({ data }: Props) {
  return (
    <ListsWrapper>
      {data.length ? (
        data.map(item => (
          // eslint-disable-next-line react/jsx-key
          <ListItems padInfo={item} key={item.id} />
        ))
      ) : (
        <div style={{ textAlign: 'center', display: 'block', margin: '0 auto' }}>
          <div className="empty">
            <img src={require('assets/images/launchpad/empty.png')} alt="Empty" />
            <TYPE.desc>No Launches yet.</TYPE.desc>
          </div>
        </div>
      )}
    </ListsWrapper>
  )
}

const phaseClass = {
  [Phase.Prepare]: 'Prepare',
  [Phase.Deposit]: 'Deposit',
  [Phase.SaleEnded]: 'Deposit',
  [Phase.Launch]: 'Launch'
}

const PeopleIcon = styled(SupervisorAccountIcon)`
  fill: ${({ theme }) => theme.v2.t01};
  height: 20px !important;
`

export function ListItems({ padInfo }: { padInfo: LauchpadV2WithPhase }) {
  const history = useHistory()
  //  const { getPadCurrenctPhase, currentPhase, padContract } = useLaunchpadV2PadContract(padInfo?.id, UnlimitedAbi)
  const percentage = useMemo(() => {
    return (padInfo.paidAmountBeforeLaunch / padInfo.targetRaised) * 100
  }, [padInfo])

  // const currentSold = useMemo(() => {
  //   if (!padInfo) return 0
  //   if (padInfo.paidAmountBeforeLaunch > padInfo.targetRaised) {
  //     return padInfo.issuedTokenAmount
  //   }

  //   return padInfo.paidAmountBeforeLaunch / padInfo.price
  // }, [padInfo])

  function goToDetailPage() {
    history.push(`launchpad-v2/${padInfo.id}`)
  }

  const depositStartTime = useMemo(() => {
    return moment.utc(padInfo.depositStart).format('YYYY-MM-DD HH:mm(UTC)')
  }, [padInfo])

  const depositEndTime = useMemo(() => {
    return moment.utc(padInfo.depositEnd).format('YYYY-MM-DD HH:mm(UTC)')
  }, [padInfo])

  const launchTime = useMemo(() => {
    return moment.utc(padInfo.launchTime).format('YYYY-MM-DD HH:mm(UTC)')
  }, [padInfo])

  //  useEffect(() => {
  //    let interval: any
  //    if (padContract) {
  //      getPadCurrenctPhase()
  //      interval = setInterval(() => {
  //        getPadCurrenctPhase()
  //      }, 30000)
  //    }

  //    return () => {
  //      if (interval) {
  //        clearInterval(interval)
  //      }
  //    }
  //  }, [padContract])

  return (
    <div className="listsCon" onClick={goToDetailPage}>
      <RowBetween className="listsTipCon">
        <p className="whitelistTip">{/* Whitelist */}</p>
        <div className="peopleNumbers">
          <PeopleIcon /> {padInfo.participatedUserCount}
        </div>
      </RowBetween>
      <div className="listsTopCon">
        <TokenLogo size="100px" address={padInfo.issuedToken.id} />
        <h2>{padInfo.issuedToken.symbol}</h2>
        <button className={padInfo.stopped ? 'stopped' : phaseClass[padInfo.currentPhase]}>
          {padInfo.stopped ? 'Cancelled' : steps[padInfo.currentPhase]}
        </button>
        <p>{padInfo.issuedToken.name}</p>
      </div>
      <div className="listsInfoCon">
        <h2>Total Raised</h2>
        <RowBetween className="saleInfo">
          <h3>
            $<Value value={padInfo.paidAmountBeforeLaunch} decimals={0} />
            &nbsp;/ $
            <Value value={padInfo.targetRaised} decimals={0} />
          </h3>
          <p>Sale {percentage.toFixed(2)}%</p>
        </RowBetween>
        <LinearProgress variant="determinate" value={percentage >= 100 ? 100 : percentage} />
        <RowBetween className="tierInfo">
          <p>Unlimited Tier</p>
          <p>
            Sales Amount:{' '}
            <span>
              {/* {currentSold.toLocaleString('en-US', { maximumFractionDigits: 0 })}/ */}
              {padInfo.issuedTokenAmount.toLocaleString('en-US', { maximumFractionDigits: 0 })}
            </span>
          </p>
        </RowBetween>
      </div>
      <div className="listsInfoCon">
        <h2>{padInfo.currentPhase === Phase.Launch ? 'Launch after' : 'Duration'}</h2>
        <RowBetween className="durationCon">
          {padInfo.currentPhase === Phase.Launch ? (
            <div className="durationInfo" style={{ width: '100%' }}>
              {/* <h3>At</h3> */}
              <p>{launchTime}</p>
            </div>
          ) : (
            <>
              <div className="durationInfo">
                <h3>Start</h3>
                <p>{depositStartTime}</p>
              </div>
              <div className="durationInfo">
                <h3 className="endTitle">End</h3>
                <p>{depositEndTime}</p>
              </div>
            </>
          )}
        </RowBetween>
      </div>
      <div className="listsBottomCon">
        <div className="listBottomInfo">{/* Max Deposit: <p>-</p> */}</div>
        <div className="listBottomInfo">
          Token price: <p>${padInfo.price}</p>
        </div>
      </div>
    </div>
  )
}
