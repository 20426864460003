import { ChainId } from '@netswap/sdk'
import ABI from './abi.json'

export const VeNettStakingConfig = {
  [ChainId.MAINNET]: {
    address: '0x3c13674aE82dAc9FFfe5D7f6E9eE555536Bc9961',
    abi: ABI,
  },
  [ChainId.TESTNET]: {
    address: '0xe3ae306CE7DF312AFE964b592729d8f759454a9D',
    abi: ABI,
  },
  // need config
  [ChainId.TESTNET_SEPOLIA]: {
    address: '0x5670a3a618Db1CcEB99106Bb0079bEbe49304fC5',
    abi: ABI,
  },
}

export { default as veNETTStakingABI } from './abi.json'
