/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react'
import { Box } from 'rebass'
// import * as echarts from 'echarts/core'
// import { LineChart, LineSeriesOption } from 'echarts/charts'
import ReactECharts from 'echarts-for-react'
import moment from 'moment'

import 'echarts/i18n/langFR'

import Web3Status from '../Web3Status'
import { useActiveWeb3React } from '../../hooks'
import { Tooltip } from '@material-ui/core'
import {
  getSwapUserScoreRecords,
  getSwapUserLPScoreRecords,
  getOverviewInfo,
  getUserInfo,
  OrderDirection,
} from 'gql/useGraphqlQuery'
import { ValueFormat } from 'components/Value'
import OverviewInfo from './OverviewInfo'
import styled from 'styled-components'
import { useDarkModeManager } from 'state/user/hooks'
// import NetSwapLogo from '../../assets/svg/logo_new.svg'

const Score = styled(Box)`
  width: 100%;
  background-color: ${({ theme }) => theme.v2.bg01};
  border-radius: 4px;
  display: flex;
  padding: 20px 24px;
  width: 100%;
  flex-flow: column wrap;
  margin-bottom: 24px !important;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 10px;
  `}
  &:last-child {
    margin-bottom: 0 !important;
  }
`
const NotLoginScore = styled(Score)`
  min-height: 164px;
  align-items: center;
  justify-content: center;
`
const ScoreTitle = styled.h2`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.text1};
  line-height: 22px;
  margin: 0 0 20px 0;
  width: 100%;
  height: 22px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: auto;
  `}
  span {
    font-size: 12px;
    line-height: 22px;
    font-weight: 400;
    margin-left: 10px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      display: block;
      margin-left: 0;
    `}
  }
`
const ScoreCon = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-flow: column wrap;
  `}
`

const ScoreComingSoon = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.text1};
  line-height: 40px;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 10px;

  p {
    margin: 0;
  }

  img {
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }
`

const ScoreConText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 270px;
  flex-flow: column wrap;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `}
  h2 {
    font-size: 28px;
    font-weight: 600;
    color: ${({ theme }) => theme.v2.t09};
    line-height: 40px;
    margin: 0 0 12px 0;
    text-align: center;
  }
  p {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.v2.t02};
    line-height: 17px;
    margin: 0;
    display: flex;
    align-items: center;
    span {
      font-size: 16px;
      font-weight: 400;
      color: ${({ theme }) => theme.v2.t09};
      line-height: 22px;
      margin-left: 8px;
    }
  }
`
const ScoreConEcharts = styled.div`
  width: 500px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  width: 100%;
`}
`

export const MyOverview = () => {
  const { account } = useActiveWeb3React()
  return (
    <>
      {account ? (
        <AccountScore account={account} />
      ) : (
        <NotLoginScore>
          <Web3Status />
        </NotLoginScore>
      )}
    </>
  )
}

interface Props {
  account: string
}
export function AccountScore({ account }: Props) {
  const [scoreRecordsArr, setScoreRecordsArr] = useState<any>() //scoreArray
  const [scoreRecordsArrOfChart, setScoreRecordsArrOfChart] = useState<any>() // scoreArray of chart
  const [lpScoreRecordsArr, setLpScoreRecordsArr] = useState<any>() //lpArray
  const [overviewScoreInfo, setOverviewScoreInfo] = useState<any>() //OverviewInfo
  const [timeText, setTimeText] = useState(true)
  const [updateTime, setUpdateTime] = useState(0)
  const mAccount = account ? account : ''

  useEffect(() => {
    getUserInfo(mAccount)
      .then((result) => {
        setOverviewScoreInfo(result.userInfo)
      })
      .catch((e: any) => {
        console.error(e)
      })
  }, [])

  useEffect(() => {
    getSwapUserScoreRecords(mAccount, 0, 100, OrderDirection.desc).then((result) => {
      const showScore = result.userSwapScoreRecords.data.map((item, _) => {
        return item.score.toFixed(2)
      })
      const showShare = result.userSwapScoreRecords.data.map((item, _) => {
        return item.share.toFixed(2)
      })

      setScoreRecordsArr({ showScore: showScore.reverse(), showShare: showShare.reverse() })
    })
  }, [])
  useEffect(() => {
    getSwapUserLPScoreRecords(mAccount, 0, 100, OrderDirection.desc).then((result) => {
      const showScore = result.userLPScoreRecords.data.map((item, _) => {
        return item.score.toFixed(2)
      })
      const showShare = result.userLPScoreRecords.data.map((item, _) => {
        return item.share.toFixed(2)
      })

      setLpScoreRecordsArr({ showScore: showScore.reverse(), showShare: showShare.reverse() })
    })
  }, [])

  useEffect(() => {
    if (scoreRecordsArr && overviewScoreInfo) {
      setScoreRecordsArrOfChart({
        showScore: [...scoreRecordsArr.showScore, overviewScoreInfo.swapScore.toFixed(2)],
        showShare: [...scoreRecordsArr.showShare, overviewScoreInfo.swapShare.toFixed(2)],
      })
    }
  }, [overviewScoreInfo, scoreRecordsArr])

  const dateString = useMemo(() => {
    const showText = timeText ? 'Scoring Plan: ' : 'Latest update: '
    const showTime = updateTime === 0 ? '--' : moment.utc(updateTime * 1000).format('YYYY-MM-DD HH:mm:ss') + '(UTC)'
    return showText + showTime
  }, [updateTime])
  // 获取overviewinfo
  useEffect(() => {
    getOverviewInfo()
      .then((result) => {
        const startTime = result.overviewInfo.airdropStartTime
        const tmpTime = result.overviewInfo.lastAirdropUpdateTime
        if (tmpTime <= 0) {
          setUpdateTime(startTime <= 0 ? 0 : startTime)
        } else {
          setTimeText(false)
          setUpdateTime(tmpTime <= 0 ? 0 : tmpTime)
        }
      })
      .catch((e: any) => {
        console.error(e)
      })
  }, [])

  return (
    <>
      <Score>
        <ScoreTitle>Estimated Rewards</ScoreTitle>
        <OverviewInfo />
        <ScoreComingSoon>
          {/* <img src={NetSwapLogo} alt="Netswap" /> */}
          <p>The first round of airdrop will be available to claim at 15:00:00 UTC, Dec 24th.</p>
          <p>The second round scores are being counted.</p>
        </ScoreComingSoon>
      </Score>

      <Score>
        <ScoreTitle>
          Swap Score<span>{dateString}</span>
        </ScoreTitle>
        <ScoreCon>
          <ScoreConText>
            <h2>
              <Tooltip
                title={overviewScoreInfo && overviewScoreInfo.swapScore ? overviewScoreInfo.swapScore : 0}
                placement="top"
              >
                <span>
                  <ValueFormat value={overviewScoreInfo && overviewScoreInfo.swapScore} />
                </span>
              </Tooltip>
            </h2>
            <p>
              Share of Swap Score:{' '}
              <span>
                {overviewScoreInfo && overviewScoreInfo.swapShare >= 0.001 ? overviewScoreInfo.swapShare : '< 0.001'}%
              </span>
            </p>
          </ScoreConText>
          <ScoreConEcharts>
            <ScoreConEchartsCon
              showTitle="Swap Score"
              leftData={scoreRecordsArrOfChart ? scoreRecordsArrOfChart.showScore : [0]}
              rightData={scoreRecordsArrOfChart ? scoreRecordsArrOfChart.showShare : [0]}
            />
          </ScoreConEcharts>
        </ScoreCon>
      </Score>
      <Score>
        <ScoreTitle>
          Liquidity Score<span>{dateString}</span>
        </ScoreTitle>
        <ScoreCon>
          <ScoreConText>
            <h2>
              <Tooltip
                title={overviewScoreInfo && overviewScoreInfo.lpScore ? overviewScoreInfo.lpScore : 0}
                placement="top"
              >
                <span>
                  <ValueFormat value={overviewScoreInfo && overviewScoreInfo.lpScore} />
                </span>
              </Tooltip>
            </h2>
            <p>
              Share of Liquidity Score:{' '}
              <span>
                {overviewScoreInfo && overviewScoreInfo.lpShare >= 0.001 ? overviewScoreInfo.lpShare : '< 0.001'}%
              </span>
            </p>
          </ScoreConText>
          <ScoreConEcharts>
            <ScoreConEchartsCon
              showTitle="LP Score"
              leftData={lpScoreRecordsArr ? lpScoreRecordsArr.showScore : [0]}
              rightData={lpScoreRecordsArr ? lpScoreRecordsArr.showShare : [0]}
            />
          </ScoreConEcharts>
        </ScoreCon>
      </Score>
    </>
  )
}
interface EcharProps {
  leftData?: number[]
  rightData?: number[]
  showTitle: string
}
export function ScoreConEchartsCon({ leftData, rightData, showTitle }: EcharProps) {
  const arrLeft = leftData
  const arrRight = rightData
  const [isDark] = useDarkModeManager()

  const option = {
    tooltip: {},
    legend: {
      data: [showTitle, 'Share'],
      top: 30,
      textStyle: {
        color: isDark ? '#fff' : '#000',
        fontWeight: 400,
        fontSize: 12,
      },
    },
    xAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#4A3063',
        },
      },
      data: [],
    },
    yAxis: [
      {
        type: 'value',
        name: showTitle,
        splitNumber: 5,
        interval: Math.ceil(Math.ceil(Math.max(...(arrLeft as any))) / 5),
        min: 0,
        max: Math.ceil(Math.ceil(Math.max(...(arrLeft as any))) / 5) * 5,
        nameTextStyle: {
          color: isDark ? '#fff' : '#000',
          fontWeight: 400,
          fontSize: 12,
        },
        axisLabel: {
          margin: 4,
          formatter: function (value: number) {
            const sizes = [
              { num: 1, symbol: '' },
              { num: 1e3, symbol: 'K' },
              { num: 1e6, symbol: 'M' },
              { num: 1e9, symbol: 'B' },
            ]
            const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
            let i
            for (i = sizes.length - 1; i > 0; i--) {
              if (value >= sizes[i].num) {
                break
              }
            }
            const tmpValue = ((value ? value : 0) / sizes[i].num).toFixed(2).replace(rx, '$1') + sizes[i].symbol
            return tmpValue
          },
        },

        axisLine: {
          show: true,
          lineStyle: {
            color: '#4A3063',
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#4A3063',
            type: 'dashed',
          },
        },
      },
      {
        type: 'value',
        name: 'Share',
        splitNumber: 5,
        interval: Math.ceil(Math.ceil(Math.max(...(arrRight as any))) / 5),
        min: 0,
        max: Math.ceil(Math.ceil(Math.max(...(arrRight as any))) / 5) * 5,

        nameTextStyle: {
          color: isDark ? '#fff' : '#000',
          fontWeight: 400,
          fontSize: 12,
        },
        axisLabel: {
          formatter: '{value}%',
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#4A3063',
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#4A3063',
            type: 'dashed',
          },
        },
      },
    ],
    series: [
      {
        data: arrLeft,
        name: showTitle,
        type: 'line',
        symbol: 'none',
        lineStyle: {
          color: '#762EBB',
        },
      },
      {
        data: arrRight,
        name: 'Share',
        type: 'line',
        symbol: 'none',
        yAxisIndex: 1,
        lineStyle: {
          color: '#73E894',
        },
      },
    ],
  }

  return <ReactECharts option={option} style={{ height: 350 }} />
}
