import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WrappedTokenInfo } from 'state/lists/hooks'
// import type { RootState } from '../index';

// Define a type for the slice state
interface ConfimModal {
  isOpen: boolean
  attemptingTxn: boolean
  hash: string
  pendingText: string
  errorMessage?: string
  addTokenToMetamask?: WrappedTokenInfo
}

export const InitConfirmModalConfig = {
  isOpen: false,
  attemptingTxn: false,
  hash: '',
  pendingText: '',
  errorMessage: undefined,
  addTokenToMetamask: undefined
}

interface State {
  confirmModal: ConfimModal
}

// Define the initial state using that type
// -1 === loading
const initialState: State = {
  confirmModal: InitConfirmModalConfig
}

export const contractState = createSlice({
  name: 'contractState',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setConfimModal: (state, action: PayloadAction<ConfimModal>) => {
      state.confirmModal = action.payload
    },
    setCloseConfirModalState: state => {
      state.confirmModal = InitConfirmModalConfig
    }
  }
})

export const { setConfimModal, setCloseConfirModalState } = contractState.actions

export default contractState.reducer
