import React, { useCallback } from 'react'
import SecondMenuNav from './SecondMenu'

export const Trade = () => {
  const moreActive = useCallback(
    (pathname: string) =>
      pathname.startsWith('/add') ||
      pathname.startsWith('/remove') ||
      (pathname.startsWith('/create') && pathname !== '/createToken') ||
      pathname.startsWith('/find'),
    []
  )
  return (
    <SecondMenuNav
      menuName="Trade"
      key="trade"
      icon="trade"
      links={[
        {
          path: '/swap',
          name: 'Swap'
        },
        {
          path: '/pool',
          name: 'Pool'
        }
      ]}
      moreActive={moreActive}
    />
  )
}

export const Earn = () => (
  <SecondMenuNav
    menuName="Earn"
    key="earn"
    icon="earn"
    links={[
      {
        path: '/farm',
        name: 'Farm'
      },
      {
        path: '/nett-staking',
        name: 'Stake'
      }
    ]}
  />
)

export const Launchpad = () => (
  <SecondMenuNav
    menuName="Launchpad"
    key="charts"
    icon="launchpad"
    links={[
      {
        path: '/launchpad-v2',
        name: 'V2'
      },
      {
        path: '/launchpad-instructions',
        name: 'How to use V2'
      },
      {
        path: '/launchpad',
        name: 'V1(Legacy)'
      }
    ]}
  />
)

export const Charts = () => (
  <SecondMenuNav
    menuName="Charts"
    key="charts"
    icon="charts"
    links={[
      {
        path: '/analytics/overview',
        name: 'Overview'
      },
      {
        path: '/analytics/tokens',
        name: 'Tokens'
      },
      {
        path: '/analytics/pairs',
        name: 'Pairs'
      },
      {
        path: '/analytics/accounts',
        name: 'Accounts'
      }
    ]}
  />
)
