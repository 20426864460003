import useSend from '../../../state/contract/hooks/useSend'
import { useAppDispatch, useAppSelector } from 'state'
import { useActiveWeb3React } from 'hooks'
import { useGetAirdrop1, useGetAirdrop2 } from '../useGetContract'
import { setAirdropInfo } from 'state/data'

export default function useAirdrop() {
  const { account } = useActiveWeb3React()
  const airdrop1 = useGetAirdrop1()
  const airdrop2 = useGetAirdrop2()
  const send = useSend()
  const {
    airdropData: {
      first: { info },
      second: { info: secondInfo }
    }
  } = useAppSelector(state => state.netswapData)
  const dispatch = useAppDispatch()

  async function claimAirdrop1() {
    if (info && account && airdrop1) {
      await send({
        contract: airdrop1,
        method: 'claim',
        params: [info.index, account, info.amount, info.proof],
        pendingText: 'Claiming',
        summary: `Claimed ${info.formatAmount.toFixed(3)} NETT`,
        onSuccess() {
          // setClaimed status
          dispatch(
            setAirdropInfo({
              key: 'first',
              update: 'claimed',
              value: {
                claimed: true
              }
            })
          )
        }
      })
    }
  }

  async function claimAirdrop2() {
    if (secondInfo && account && airdrop2) {
      await send({
        contract: airdrop2,
        method: 'claim',
        params: [secondInfo.index, account, secondInfo.amount, secondInfo.proof],
        pendingText: 'Claiming',
        summary: `Claimed ${secondInfo.formatAmount.toFixed(3)} NETT`,
        onSuccess() {
          // setClaimed status
          dispatch(
            setAirdropInfo({
              key: 'second',
              update: 'claimed',
              value: {
                claimed: true
              }
            })
          )
        }
      })
    }
  }

  return {
    claimAirdrop1,
    claimAirdrop2
  }
}
