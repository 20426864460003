/* eslint-disable react-hooks/exhaustive-deps */
import { ChainId } from '@netswap/sdk'
import { NETWORK_CHAIN_ID } from 'connectors'
import { getAllPairsWithApr } from 'gql/subgraph/pairs'
import { useActiveWeb3React } from 'hooks'
import { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import { setLiquidityPool } from '../../index'

export default function useGetLiquitidyPools() {
  const { chainId } = useActiveWeb3React()
  const dispatch = useAppDispatch()
  const { liquidityPools } = useAppSelector((state) => state.netswapData)

  const [loading, setLoading] = useState(true)
  async function getAllPairsWithAprFunc() {
    setLoading(true)
    const res = await getAllPairsWithApr()

    setLoading(false)
    const cid = chainId || NETWORK_CHAIN_ID
    if (res.length) {
      dispatch(
        setLiquidityPool({
          chainId: cid,
          data: res,
        })
      )
    }
  }

  useEffect(() => {
    getAllPairsWithAprFunc()
    let interval = setInterval(() => {
      getAllPairsWithAprFunc()
    }, 25000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  const pools = useMemo(() => {
    if (chainId === ChainId.TESTNET_SEPOLIA) {
      return []
    }
    return liquidityPools ? liquidityPools[(chainId || NETWORK_CHAIN_ID) as ChainId] : []
  }, [liquidityPools, chainId])

  const firstLoading = useMemo(() => {
    return loading && !pools?.length
  }, [pools, loading])

  return {
    pools,
    loading: firstLoading,
  }
}
