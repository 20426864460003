// used to mark unsupported tokens, these are hosted lists of unsupported tokens
/**
 * @TODO add list from blockchain association
 */
export const UNSUPPORTED_LIST_URLS: string[] = []

// const COMPOUND_LIST = 'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json'
// const UMA_LIST = 'https://umaproject.org/uma.tokenlist.json'
// const AAVE_LIST = 'tokenlist.aave.eth'
// const SYNTHETIX_LIST = 'synths.snx.eth'
// const WRAPPED_LIST = 'wrapped.tokensoft.eth'
// const SET_LIST = 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/set.tokenlist.json'
// const OPYN_LIST = 'https://raw.githubusercontent.com/opynfinance/opyn-tokenlist/master/opyn-v1.tokenlist.json'
// const ROLL_LIST = 'https://app.tryroll.com/tokens.json'
// const COINGECKO_LIST = 'https://tokens.coingecko.com/uniswap/all.json'
// const CMC_ALL_LIST = 'defi.cmc.eth'
// const CMC_STABLECOIN = 'stablecoin.cmc.eth'
// const KLEROS_LIST = 't2crtokens.eth'
// const GEMINI_LIST = 'https://www.gemini.com/uniswap/manifest.json'

const METIS_TESTNET_LIST = 'https://raw.githubusercontent.com/Netswap/token-lists/master/test.tokenlist.json'
const METIS_MAINNET_LIST = 'https://raw.githubusercontent.com/Netswap/token-lists/master/top100.tokenlist.json'
const METIS_SEPOLIA_LIST = 'https://raw.githubusercontent.com/Netswap/token-lists/master/59902.tokenlist.json'
export const METIS_MAINNET_DEFI = 'https://raw.githubusercontent.com/Netswap/token-lists/master/defi.tokenlist.json'
export const METIS_MAINNET_MB = 'https://raw.githubusercontent.com/Netswap/token-lists/master/mb.tokenlist.json'
export const METIS_MAINNET_OB = 'https://raw.githubusercontent.com/Netswap/token-lists/master/ob.tokenlist.json'
export const METIS_MAINNET_POLY = 'https://raw.githubusercontent.com/Netswap/token-lists/master/pb.tokenlist.json'
export const METIS_MAINNET_RB = 'https://raw.githubusercontent.com/Netswap/token-lists/master/rb.tokenlist.json'
export const METIS_MAINNET_SB = 'https://raw.githubusercontent.com/Netswap/token-lists/master/sb.tokenlist.json'
export const METIS_MAINNET_O3 = 'https://raw.githubusercontent.com/Netswap/token-lists/master/o3.tokenlist.json'
export const METIS_MAINNET_MCB = 'https://raw.githubusercontent.com/Netswap/token-lists/master/mcb.tokenlist.json'
// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  // COMPOUND_LIST,
  // AAVE_LIST,
  // SYNTHETIX_LIST,
  // UMA_LIST,
  // WRAPPED_LIST,
  // SET_LIST,
  // OPYN_LIST,
  // ROLL_LIST,
  // COINGECKO_LIST,
  // CMC_ALL_LIST,
  // CMC_STABLECOIN,
  // KLEROS_LIST,
  // GEMINI_LIST,
  METIS_TESTNET_LIST,
  METIS_SEPOLIA_LIST,
  METIS_MAINNET_LIST,
  METIS_MAINNET_DEFI,
  METIS_MAINNET_MB,
  METIS_MAINNET_OB,
  //   METIS_MAINNET_POLY,
  METIS_MAINNET_RB,
  METIS_MAINNET_SB,
  METIS_MAINNET_O3,
  METIS_MAINNET_MCB,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [
  METIS_MAINNET_LIST,
  METIS_TESTNET_LIST,
  METIS_SEPOLIA_LIST,
  METIS_MAINNET_DEFI,
  METIS_MAINNET_MB,
  METIS_MAINNET_OB,
  //   METIS_MAINNET_POLY,
  METIS_MAINNET_RB,
  METIS_MAINNET_SB,
  METIS_MAINNET_O3,
  METIS_MAINNET_MCB,
]
