import { AutoColumn } from 'components/Column'
import React from 'react'
import styled from 'styled-components'
import { TYPE } from 'theme'
import PoolList from './v2/PoolList'

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`
const TitleIcon = styled.img`
  width: 110px;
  height: 102px;
  margin-right: 10px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
       width: 70px;
  height: 64px;
    `};
`

export default function PoolV2() {
  return (
    <>
      <TitleWrapper>
        <TitleIcon src={require('assets/images/pool/header.png')} />
        <AutoColumn justify="center" gap="md">
          <TYPE.v2Main fontSize={32}>Liquidity Pool</TYPE.v2Main>
          <TYPE.v2Main fontSize={14} marginTop={12} textAlign="center">
            Easy add liquidity to become Netswap liquidity provider
          </TYPE.v2Main>
        </AutoColumn>
      </TitleWrapper>
      <PoolList />
    </>
  )
}
