import React from 'react'
import { withRouter } from 'react-router-dom'
import { Box } from 'rebass'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { AutoRow, RowBetween } from 'components/Row'
import { AutoColumn } from 'components/Column'
import PairList from 'components/Infos/PairList'
import TopTokenList from 'components/Infos/TokenList'
import TxnList from 'components/Infos/TxnList'
import GlobalChart from 'components/Infos/GlobalChart'
// import Search from 'components/Infos/Search'
import GlobalStats from 'components/Infos/GlobalStats'

import { useGlobalData, useGlobalTransactions } from 'hooks/infos/GlobalData'
import { useAllPairData } from 'hooks/infos/PairData'
import { useMedia } from 'react-use'
import Panel from 'components/Infos/Panel'
import { useAllTokenData } from 'hooks/infos/TokenData'
import { formattedNum, formattedPercent } from 'utils/infos'
import { TYPE } from 'theme'
import { CustomLink } from 'components/Infos/Link'

import { PageWrapper, ContentWrapper } from 'components/Infos/index'

const ListOptions = styled(AutoRow)`
  height: 40px;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 600;

  @media screen and (max-width: 640px) {
    font-size: 1rem;
  }
`

const GridRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  column-gap: 6px;
  align-items: start;
  justify-content: space-between;
`

function GlobalPage(props: any) {
  const { t } = useTranslation()
  // get data for lists and totals
  const allPairs = useAllPairData()
  const allTokens = useAllTokenData()
  const transactions = useGlobalTransactions()
  const { totalLiquidityUSD, oneDayVolumeUSD, volumeChangeUSD, liquidityChangeUSD } = useGlobalData()

  // breakpoints
  const below800 = useMedia('(max-width: 800px)')

  // scrolling refs

  // useEffect(() => {
  //   document.querySelector('body').scrollTo({
  //     behavior: 'smooth',
  //     top: 0,
  //   })
  // }, [])

  const params = new URLSearchParams(props.location.search)

  return (
    <PageWrapper>
      <ContentWrapper>
        <div>
          <AutoColumn gap="6px" style={{ paddingBottom: below800 ? '0' : '40px' }}>
            <TYPE.title fontSize={26}>
              {below800 ? t('analytics.overview_page.title') : t('analytics.overview_page.full_title')}
            </TYPE.title>
            <GlobalStats showAll={!!params.get('analytics.show_all')} />
          </AutoColumn>
          {below800 && ( // mobile card
            <Box mb={20}>
              <Panel>
                <Box>
                  <AutoColumn gap="36px">
                    <AutoColumn gap="20px">
                      <RowBetween>
                        <TYPE.v2Main>{t('analytics.overview_page.volume_24hrs')}</TYPE.v2Main>
                        <div />
                      </RowBetween>
                      <RowBetween align="flex-end">
                        <TYPE.v2Main fontSize={'1.5rem'} lineHeight={1} fontWeight={600}>
                          {formattedNum(oneDayVolumeUSD, true)}
                        </TYPE.v2Main>
                        <TYPE.v2Main fontSize={12}>{formattedPercent(volumeChangeUSD)}</TYPE.v2Main>
                      </RowBetween>
                    </AutoColumn>
                    <AutoColumn gap="20px">
                      <RowBetween>
                        <TYPE.v2Main>{t('analytics.overview_page.total_liquidity')}</TYPE.v2Main>
                        <div />
                      </RowBetween>
                      <RowBetween align="flex-end">
                        <TYPE.v2Main fontSize={'1.5rem'} lineHeight={1} fontWeight={600}>
                          {formattedNum(totalLiquidityUSD, true)}
                        </TYPE.v2Main>
                        <TYPE.v2Main fontSize={12}>{formattedPercent(liquidityChangeUSD)}</TYPE.v2Main>
                      </RowBetween>
                    </AutoColumn>
                  </AutoColumn>
                </Box>
              </Panel>
            </Box>
          )}
          {!below800 && (
            <GridRow>
              <Panel style={{ height: '100%', minHeight: '300px' }}>
                <GlobalChart display="liquidity" />
              </Panel>
              <Panel style={{ height: '100%' }}>
                <GlobalChart display="volume" />
              </Panel>
            </GridRow>
          )}
          {below800 && (
            <AutoColumn style={{ marginTop: '6px' }} gap="24px">
              <Panel style={{ height: '100%', minHeight: '300px' }}>
                <GlobalChart display="liquidity" />
              </Panel>
            </AutoColumn>
          )}
          <ListOptions gap="10px" style={{ marginTop: '32px', marginBottom: '30px' }}>
            <RowBetween>
              <TYPE.title fontSize={20}>{t('analytics.overview_page.top_tokens')}</TYPE.title>
              <CustomLink to={'/analytics/tokens'} style={{ fontSize: '16px' }}>
                {t('analytics.overview_page.see_all')}

                <i className="iconfont icon-page-next" style={{ marginLeft: 8 }} />
              </CustomLink>
            </RowBetween>
          </ListOptions>
          <Panel style={{ marginTop: '6px', padding: '30px 24px' }} borderRadius="16px">
            <TopTokenList tokens={allTokens} />
          </Panel>
          <ListOptions gap="10px" style={{ marginTop: '32px', marginBottom: '30px' }}>
            <RowBetween>
              <TYPE.title fontSize={20}>{t('analytics.overview_page.top_pairs')}</TYPE.title>
              <CustomLink to={'/analytics/pairs'} style={{ fontSize: '16px' }}>
                {t('analytics.overview_page.see_all')}

                <i className="iconfont icon-page-next" style={{ marginLeft: 8 }} />
              </CustomLink>
            </RowBetween>
          </ListOptions>
          <Panel style={{ marginTop: '6px', padding: '30px 24px' }} borderRadius="16px">
            <PairList pairs={allPairs} />
          </Panel>

          <span>
            <TYPE.v2Main fontSize={'1.125rem'} style={{ marginTop: '2rem' }}>
              {t('analytics.overview_page.transactions')}
            </TYPE.v2Main>
          </span>
          <Panel style={{ margin: '1rem 0' }}>
            <TxnList transactions={transactions} />
          </Panel>
        </div>
      </ContentWrapper>
    </PageWrapper>
  )
}

export default withRouter(GlobalPage)
