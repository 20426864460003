import { gql } from '@apollo/client'
import { DocumentNode } from 'graphql'
// import { useActiveWeb3React } from 'hooks'
import client from './index'
// import { useActiveWeb3React } from 'hooks';
// import client from './index';

//main request logic
const fetchGraphQL = async <T extends {}>(query: DocumentNode, variables: Record<string, any> = {}): Promise<T> => {
  const result = await client().query<T>({
    query,
    variables,
    fetchPolicy: 'no-cache'
  })
  return result.data
}
//SwapScoreRankData logic
interface SwapScoreRankData {
  swapScoreRanks: [
    {
      // swap 分数排名(id 为1 到 100， 10 %， 20 %， 50 %)
      id: string
      // 用户钱包地址
      userAddress: string
      // 分数
      score: number
      // 占比
      share: number
      //更新时间
      updateTime: number
    }
  ]
}

export const getSwapScoreRanks = (): Promise<SwapScoreRankData> => {
  const GET_SWAP_SCORE_RANKS = gql`
    query {
      swapScoreRanks {
        id
        userAddress
        updateTime
        score
        share
      }
    }
  `
  return fetchGraphQL<SwapScoreRankData>(GET_SWAP_SCORE_RANKS)
}

//SwapScoreRankData logic
interface LPScoreRank {
  lpScoreRanks: [
    {
      // swap 分数排名(id 为1 到 100， 10 %， 20 %， 50 %)
      id: string
      // 用户钱包地址
      userAddress: string
      // 分数
      score: number
      // 占比
      share: number
      //更新时间
      updateTime: number
    }
  ]
}
export const getLPScoreRank = (): Promise<LPScoreRank> => {
  const GET_SWAP_SCORE_RANKS = gql`
    query {
      lpScoreRanks {
        id
        userAddress
        updateTime
        score
        share
      }
    }
  `
  return fetchGraphQL<LPScoreRank>(GET_SWAP_SCORE_RANKS)
}

//userLPScoreRecords
interface UserLPScoreRecord {
  // 用户lp 分数记录
  id: string
  // 用户id = 钱包地址
  userId: string
  // 分数
  score: number
  // 占比
  share: number
  // 更新时间
  updateTime: number
}
interface UserLPScoreRecordsReturn {
  userLPScoreRecords: {
    total: number
    data: [UserLPScoreRecord]
  }
}

export enum OrderDirection {
  desc = 'desc',
  asc = 'asc'
}

export const getSwapUserLPScoreRecords = (
  userId = '',
  skip = 0,
  limit = 0,
  orderDirection: OrderDirection = OrderDirection.asc
): Promise<UserLPScoreRecordsReturn> => {
  //   const { account } = useActiveWeb3React()
  //   const userId = uid ? uid : 1
  //   if (!userId) return Promise.reject('useSwapUserScoreRecords: user is not login or queyr userid is null')
  const GET_LP_SCORE_RECORD = gql`
    query($userId: String!, $skip: Int, $limit: Int, $orderDirection: OrderDirection) {
      userLPScoreRecords(userId: $userId, skip: $skip, limit: $limit, orderDirection: $orderDirection) {
        total
        data {
          id
          userId
          score
          share
          updateTime
        }
      }
    }
  `
  return fetchGraphQL<UserLPScoreRecordsReturn>(GET_LP_SCORE_RECORD, { userId, skip, limit, orderDirection })
}

//userSwapScoreRecords
interface UserSwapScoreRecord {
  // 用户lp 分数记录
  id: string
  // 用户id = 钱包地址
  userId: string
  // 分数
  score: number
  // 占比
  share: number
  // 更新时间
  updateTime: number
}
interface UserSwapScoreRecordsReturn {
  userSwapScoreRecords: {
    total: number
    data: [UserSwapScoreRecord]
  }
}

export const getSwapUserScoreRecords = (
  userId = '',
  skip = 0,
  limit = 0,
  orderDirection: OrderDirection = OrderDirection.asc
): Promise<UserSwapScoreRecordsReturn> => {
  //   const { account } = useActiveWeb3React()
  //   const userId = uid ? uid : 1
  //   if (!userId) return Promise.reject('useSwapUserScoreRecords: user is not login or queyr userid is null')
  const GET_SCORE_RECORD = gql`
    query($userId: String!, $skip: Int, $limit: Int, $orderDirection: OrderDirection) {
      userSwapScoreRecords(userId: $userId, skip: $skip, limit: $limit, orderDirection: $orderDirection) {
        total
        data {
          id
          userId
          score
          share
          updateTime
        }
      }
    }
  `

  return fetchGraphQL<UserSwapScoreRecordsReturn>(GET_SCORE_RECORD, { userId, skip, limit, orderDirection })
}

//overviewScoreRecords
interface OverviewScoreRecord {
  overviewScoreRecords: [
    {
      id: string
      lpScore: string
      swapScore: string
      updateTime: number
    }
  ]
  //总览分数信息记录
}

export const getOverviewScoreRecords = (startTime = 0, endTime = 0): Promise<OverviewScoreRecord> => {
  const GET_SCORE_RECORD = gql`
    query($startTime: Float!, $endTime: Float!) {
      overviewScoreRecords(startTime: $startTime, endTime: $endTime) {
        id
        lpScore
        swapScore
        updateTime
      }
    }
  `

  return fetchGraphQL<OverviewScoreRecord>(GET_SCORE_RECORD, { startTime, endTime })
}

//overviewInfo
interface OverviewInfo {
  overviewInfo: {
    // 信息总览
    // only "info-1"
    id: string
    // lp 总分数
    lpTotalScore: string
    // swap 总分数
    swapTotalScore: string
    // lp 总空投量
    lpTotalAirdropToken: string
    // swap 总空投量
    swapTotalAirdropToken: string
    // 参与空投总人数
    airdropTotalUserCount: number
    // 空投活动开始时间
    airdropStartTime: number
    // 空投活动结束时间
    airdropEndTime: number
    // 上次空投数据更新时间
    lastAirdropUpdateTime: number
  }
}

export const getOverviewInfo = (): Promise<OverviewInfo> => {
  const GET_OVERVIEW_INFO = gql`
    query {
      overviewInfo {
        id
        lpTotalScore
        swapTotalScore
        lpTotalAirdropToken
        swapTotalAirdropToken
        airdropTotalUserCount
        airdropStartTime
        airdropEndTime
        lastAirdropUpdateTime
      }
    }
  `

  return fetchGraphQL<OverviewInfo>(GET_OVERVIEW_INFO)
}

//UserInfo
interface UserInfo {
  userInfo: {
    // 信息总览
    // only "info-1"
    id: string
    // lp 分数
    lpScore: string
    // lp share百分比
    lpShare: string
    // swap 分数
    swapScore: string
    // swap share百分比
    swapShare: string
    rank: string
    // 数据更新时间
    updateTime: number
    // 数据创建时间
    createTime: number
    // 可获取的空投数
    airdropToken: number
  }
}

export const getUserInfo = (userId = ''): Promise<UserInfo> => {
  const GET_USER_INFO = gql`
    query($userId: String!) {
      userInfo(id: $userId) {
        id
        lpScore
        lpShare
        swapScore
        swapShare
        rank
        updateTime
        createTime
        airdropToken
      }
    }
  `
  return fetchGraphQL<UserInfo>(GET_USER_INFO, { userId })
}
