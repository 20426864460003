import Copy from 'components/AccountDetails/Copy'
import { AutoColumn } from 'components/Column'
import { RowBetween } from 'components/Row'
import TokenLogo from 'components/TokenImage'
import Value from 'components/Value'
import Numeral from 'numeral'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useGetPoolInfoByPairAddress from 'state/data/hooks/LiquidityPool/useGetPoolInfoByPairAddress'
import useGetUserPoolByPairAddress from 'state/data/hooks/LiquidityPool/userGetUserPoolByPairAddress'
import styled from 'styled-components'
import { TYPE } from 'theme'
import { shortenAddress } from 'utils'

const Wrapper = styled.div`
  width: 786px;
  /* height: 560px; */
  background: ${({ theme }) => theme.v2.bg02};
  border-radius: 16px;
  overflow: hidden;
`

const InnerWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 38px 40px;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const PairImagesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;

  img {
    width: 32px;
    height: 32px;
    &:first-child {
      margin-right: -8px;
      z-index: 1;
    }

    &:last-child {
      z-index: 0;
    }
  }
`

const Descirption = styled(AutoColumn)`
  border-radius: 9px;
  padding: 14px 20px;
  margin-top: 20px;
  background: ${({ theme }) => theme.v2.bg04};
`

const DataRow = styled(RowBetween)`
  padding: 36px 0;

  ${({ theme }) => theme.mediaWidth.upToSmall`
        flex-direction: column;
        justify-content: flex-start;
        padding: 1rem 0;
    `};

  > div {
    flex-grow: 0;
    flex-shrink: 0;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    ${({ theme }) => theme.mediaWidth.upToSmall`
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
    `};

    .flex {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

const UserPoolInfoWrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.v2.bg06};

  ${({ theme }) => theme.mediaWidth.upToSmall`
     box-sizing: border-box;
     padding: 0 2rem;
    `};
`

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  > div {
    flex: 0;
  }
  .title {
    width: 360px;
    flex-grow: 1;
  }

  .celebrate {
    width: 330px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
       display: none;
    `};
  }
`

interface Props {
  pairAddress?: string
  className?: string
  viewType?: 'create' | 'info'
  // only for not exist pair
  tokens?: {
    symbol?: string
    address?: string
  }[]
}

export default function PairInfo({ pairAddress, className, viewType = 'info', tokens }: Props) {
  const { t } = useTranslation()
  const { pair: poolInfo } = useGetPoolInfoByPairAddress(pairAddress)
  const userPoolInfo = useGetUserPoolByPairAddress(pairAddress)
  const existPair = !!pairAddress && !!poolInfo

  const lpName = useMemo(() => {
    if (poolInfo) {
      return `${poolInfo?.token0.symbol} - ${poolInfo?.token1.symbol} LP`
    } else if (tokens) {
      return `${tokens[0].symbol} - ${tokens[1].symbol} LP`
    }

    return '-'
  }, [poolInfo, tokens])
  return (
    <Wrapper className={className}>
      <InnerWrapper>
        <Title>
          <PairImagesWrapper>
            <TokenLogo address={poolInfo?.token0.id || (tokens && tokens[0].address)} />
            <TokenLogo address={poolInfo?.token1.id || (tokens && tokens[1].address)} />
          </PairImagesWrapper>
          <AutoColumn>
            <TYPE.v2Main fontSize={20}>{lpName}</TYPE.v2Main>
            {existPair && (
              <RowBetween>
                <TYPE.desc fontSize={14}>Pair Address: {shortenAddress(poolInfo?.id || '')}</TYPE.desc>
                <Copy toCopy={poolInfo?.id || ''} />
              </RowBetween>
            )}
          </AutoColumn>
        </Title>
        {!existPair && (
          <InfoWrapper>
            <div className="title">
              <TYPE.v2Main fontWeight={600} fontSize={32}>
                You are the first liquidity provider of new pool
              </TYPE.v2Main>
              <TYPE.desc fontSize={16} marginTop="16px">
                The ratio of tokens you add will set the price. Once you are ok with the rate click supply to review.
              </TYPE.desc>
            </div>
            <div>
              <img className="celebrate" src={require('assets/images/pool/celebrate.png')} alt="" />
            </div>
          </InfoWrapper>
        )}
        <Descirption>
          <TYPE.v2Main fontSize={14}>{t('pool.lpRewards')}</TYPE.v2Main>
          <TYPE.desc fontSize={12}>{t('pool.lpRewardsDesc')}</TYPE.desc>
        </Descirption>
        {existPair && (
          <>
            <DataRow>
              <div>
                <TYPE.desc fontSize={14}>Liquidity</TYPE.desc>
                <TYPE.v2Main fontSize={16} marginTop="14px">
                  {poolInfo ? Numeral(poolInfo.reserveUSD).format('$ 0,0.[000]') : '-'}
                </TYPE.v2Main>
              </div>
              <div>
                <TYPE.desc fontSize={14}>Volume(24H)</TYPE.desc>
                <TYPE.v2Main fontSize={16} marginTop="14px">
                  {poolInfo ? Numeral(poolInfo.last24HourVol).format('$ 0,0.[000]') : '-'}
                </TYPE.v2Main>
              </div>
              <div>
                <TYPE.desc fontSize={14}>Total Fee(24H)</TYPE.desc>
                <TYPE.v2Main fontSize={16} marginTop="14px">
                  {poolInfo ? Numeral(poolInfo.last24HourVol * 0.0025).format('$ 0,0.[000]') : '-'}
                </TYPE.v2Main>
              </div>
              <div>
                <TYPE.desc fontSize={14}>LP Reward APR</TYPE.desc>
                <TYPE.v2Main color="t08" fontSize={16} marginTop="14px">
                  + {poolInfo?.lpApr || 0} %
                </TYPE.v2Main>
              </div>
            </DataRow>
            {poolInfo && (
              <DataRow style={{ paddingBottom: '0' }}>
                <div>
                  <TYPE.desc fontSize={14}>
                    {poolInfo.token0.symbol} per {poolInfo.token1.symbol}
                  </TYPE.desc>
                  <TYPE.v2Main fontSize={16} marginTop="14px">
                    {Numeral(poolInfo.token0Price).format('0,0.[000]')}
                  </TYPE.v2Main>
                </div>
                <div>
                  <TYPE.desc fontSize={14}>
                    {poolInfo.token1.symbol} per {poolInfo.token0.symbol}
                  </TYPE.desc>
                  <TYPE.v2Main fontSize={16} marginTop="14px">
                    {Numeral(poolInfo.token1Price).format('0,0.[000]')}
                  </TYPE.v2Main>
                </div>
                <div>
                  <TYPE.desc fontSize={14}>{poolInfo.token0.symbol}</TYPE.desc>
                  <TYPE.v2Main fontSize={16} marginTop="14px" className="flex">
                    ≈&nbsp;&nbsp; <TokenLogo address={poolInfo?.token0.id} />
                    &nbsp; {Numeral(poolInfo.reserve0).format('0,0.[000]')}
                  </TYPE.v2Main>
                </div>
                <div>
                  <TYPE.desc fontSize={14}>{poolInfo.token1.symbol} </TYPE.desc>
                  <TYPE.v2Main fontSize={16} marginTop="14px" className="flex">
                    ≈&nbsp;&nbsp; <TokenLogo address={poolInfo?.token1.id} />
                    &nbsp; {Numeral(poolInfo.reserve1).format('0,0.[000]')}
                  </TYPE.v2Main>
                </div>
              </DataRow>
            )}
          </>
        )}
      </InnerWrapper>

      {existPair && userPoolInfo && (
        <UserPoolInfoWrapper>
          <DataRow>
            <div>
              <TYPE.desc fontSize={14}>Your Share of Pool</TYPE.desc>
              <TYPE.v2Main fontSize={16} marginTop="14px">
                {userPoolInfo.share < 0.01 ? '< 0.01' : userPoolInfo.share} %
              </TYPE.v2Main>
            </div>
            <div>
              <TYPE.desc fontSize={14}>Your total Pool Tokens</TYPE.desc>
              <TYPE.v2Main fontSize={16} marginTop="14px">
                ≈ <Value value={Number(userPoolInfo.liquidityTokenBalance)} />
              </TYPE.v2Main>
            </div>
            <div>
              <TYPE.desc fontSize={14}>{userPoolInfo.pair.token0.symbol}</TYPE.desc>
              <TYPE.v2Main fontSize={16} marginTop="14px" className="flex">
                ≈&nbsp;&nbsp; <TokenLogo address={userPoolInfo?.pair.token0.id} />
                &nbsp;&nbsp;
                <Value value={Number(userPoolInfo.token0Amount)} />
              </TYPE.v2Main>
            </div>
            <div>
              <TYPE.desc fontSize={14}>{userPoolInfo.pair.token1.symbol}</TYPE.desc>
              <TYPE.v2Main fontSize={16} marginTop="14px" className="flex">
                ≈&nbsp;&nbsp; <TokenLogo address={userPoolInfo?.pair.token1.id} />
                &nbsp;&nbsp;
                <Value value={Number(userPoolInfo.token1Amount)} />
              </TYPE.v2Main>
            </div>
          </DataRow>
        </UserPoolInfoWrapper>
      )}
    </Wrapper>
  )
}
