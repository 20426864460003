import Row from 'components/Row'
import TokenImage from 'components/TokenImage'
import { FarmLPPair } from 'gql/microservices/farm-lp-pools'
import React from 'react'
import styled from 'styled-components'
import { TYPE } from 'theme'

const PairImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  div {
    &:first-child {
      z-index: 1;
    }
    &:last-child {
      z-index: 0;
      margin-left: -10px;
    }
    img {
      width: 72px;
      height: 72px;
      border-radius: 50%;
    }
  }
`

const Wrapper = styled(Row)``

interface Props {
  pair: FarmLPPair
}
export default function PairInfo({ pair }: Props) {
  return (
    <Wrapper>
      <PairImgWrapper>
        <TokenImage size="72px" address={pair.token0.address} />
        <TokenImage size="72px" address={pair.token1.address} />
      </PairImgWrapper>
      <TYPE.v2Main fontSize={26}>
        {pair.token0.symbol} - {pair.token1.symbol}
      </TYPE.v2Main>
    </Wrapper>
  )
}
