import { AutoColumn } from 'components/Column'
import React from 'react'
import { TYPE } from 'theme'

export default function NoPool() {
  return (
    <AutoColumn justify="center" gap="md" style={{ paddingTop: '2rem' }}>
      <img src={require('assets/images/nebo_logo.png')} alt="" />
      <TYPE.desc fontSize={16} textAlign="center" lineHeight={1.6}>
        No liquidity found.
        <br />
        {`By adding liquidity you'll earn 0.25% of all trades on this pair proportional to your share of the pool. <br />
        Try to add Liquidty in recommend pool`}
      </TYPE.desc>
    </AutoColumn>
  )
}
