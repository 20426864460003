import React, { createContext, useContext, useReducer, useMemo, useCallback, useEffect, useState } from 'react'
import { ExchangeSubgraph as client } from 'gql/index'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useTimeframe } from './Application'
import {
  getPercentChange,
  getBlockFromTimestamp,
  getBlocksFromTimestamps,
  get2DayPercentChange,
  getTimeframe,
} from 'utils/infos'
import {
  GLOBAL_DATA,
  GLOBAL_TXNS,
  GLOBAL_CHART,
  METIS_PRICE,
  ALL_PAIRS,
  ALL_TOKENS,
  TOP_LPS_PER_PAIRS,
  FactoryData,
  NetswapDayData,
  PairTransactions,
  PairBaseInfo,
  TokenBaseInfo,
  LPBaseInfo,
} from 'gql/subgraph/analytics'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { useAllPairData } from './PairData'
// import { FACTORY_ADDRESS } from '../constants'
const UPDATE = 'UPDATE'
const UPDATE_TXNS = 'UPDATE_TXNS'
const UPDATE_CHART = 'UPDATE_CHART'
const UPDATE_METIS_PRICE = 'UPDATE_METIS_PRICE'
const METIS_PRICE_KEY = 'METIS_PRICE_KEY'
const UPDATE_ALL_PAIRS_IN_UNISWAP = 'UPDAUPDATE_ALL_PAIRS_IN_UNISWAPTE_TOP_PAIRS'
const UPDATE_ALL_TOKENS_IN_UNISWAP = 'UPDATE_ALL_TOKENS_IN_UNISWAP'
const UPDATE_TOP_LPS = 'UPDATE_TOP_LPS'

// format dayjs with the libraries that we need
dayjs.extend(utc)
dayjs.extend(weekOfYear)

export type GlobalData = {
  id: string
  totalVolumeUSD: number
  totalVolumeMETIS: number
  untrackedVolumeUSD: number
  totalLiquidityUSD: number
  totalLiquidityMETIS: number
  txCount: number
  pairCount: number
  userCount: number
}

export type GlobalDataWithChange = GlobalData & {
  oneDayVolumeUSD: number
  volumeChangeUSD: number
  liquidityChangeUSD: number
  oneDayTxns: number
  txnChange: number

  oneWeekVolume: number
  weeklyVolumeChange: number
}

export type TopLP = {
  user: {
    id: string
  }
  pairName: string
  pairAddress: string
  token0: string
  token1: string
  usd: number
}

export type StateData = {
  globalData: GlobalDataWithChange
  transactions: PairTransactions
  chartData: {
    daily: NetswapDayData[]
    weekly: NetswapDayData[]
  }
  allPairs: PairBaseInfo[]
  allTokens: TokenBaseInfo[]
  topLps: TopLP[]
  [METIS_PRICE_KEY]: number
  oneDayPrice: number
  metisPriceChange: number
}

const initialState = {
  globalData: {
    id: '',
    totalVolumeUSD: 0,
    totalVolumeMETIS: 0,
    untrackedVolumeUSD: 0,
    totalLiquidityUSD: 0,
    totalLiquidityMETIS: 0,
    txCount: 0,
    pairCount: 0,
    userCount: 0,
    oneDayVolumeUSD: 0,
    volumeChangeUSD: 0,
    liquidityChangeUSD: 0,
    oneDayTxns: 0,
    txnChange: 0,

    oneWeekVolume: 0,
    weeklyVolumeChange: 0,
  },
  transactions: {
    mints: [],
    swaps: [],
    burns: [],
  },
  chartData: {
    daily: [],
    weekly: [],
  },
  allPairs: [],
  allTokens: [],
  topLps: [],
  [METIS_PRICE_KEY]: 0,
  oneDayPrice: 0,
  metisPriceChange: 0,
}

const GlobalDataContext = createContext<[StateData, any]>([initialState, {}])

function useGlobalDataContext() {
  return useContext(GlobalDataContext)
}

function reducer(state: any, { type, payload }: { type: string; payload: any }) {
  switch (type) {
    case UPDATE: {
      const { data } = payload
      return {
        ...state,
        globalData: data,
      }
    }
    case UPDATE_TXNS: {
      const { transactions } = payload
      return {
        ...state,
        transactions,
      }
    }
    case UPDATE_CHART: {
      const { daily, weekly } = payload
      return {
        ...state,
        chartData: {
          daily,
          weekly,
        },
      }
    }
    case UPDATE_METIS_PRICE: {
      const { metisPrice, oneDayPrice, metisPriceChange } = payload
      return {
        ...state,
        [METIS_PRICE_KEY]: metisPrice,
        oneDayPrice,
        metisPriceChange,
      }
    }

    case UPDATE_ALL_PAIRS_IN_UNISWAP: {
      const { allPairs } = payload
      return {
        ...state,
        allPairs,
      }
    }

    case UPDATE_ALL_TOKENS_IN_UNISWAP: {
      const { allTokens } = payload
      return {
        ...state,
        allTokens,
      }
    }

    case UPDATE_TOP_LPS: {
      const { topLps } = payload
      return {
        ...state,
        topLps,
      }
    }
    default: {
      throw Error(`Unexpected action type in DataContext reducer: '${type}'.`)
    }
  }
}

export default function Provider({ children }: { children: React.ReactChildren | React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const update = useCallback((data: any) => {
    dispatch({
      type: UPDATE,
      payload: {
        data,
      },
    })
  }, [])

  const updateTransactions = useCallback((transactions: any) => {
    dispatch({
      type: UPDATE_TXNS,
      payload: {
        transactions,
      },
    })
  }, [])

  const updateChart = useCallback((daily: any, weekly: any) => {
    dispatch({
      type: UPDATE_CHART,
      payload: {
        daily,
        weekly,
      },
    })
  }, [])

  const updateMetisPrice = useCallback((metisPrice: any, oneDayPrice: any, metisPriceChange: any) => {
    dispatch({
      type: UPDATE_METIS_PRICE,
      payload: {
        metisPrice,
        oneDayPrice,
        metisPriceChange,
      },
    })
  }, [])

  const updateAllPairsInNetswap = useCallback((allPairs: any) => {
    dispatch({
      type: UPDATE_ALL_PAIRS_IN_UNISWAP,
      payload: {
        allPairs,
      },
    })
  }, [])

  const updateAllTokensInNetswap = useCallback((allTokens: any) => {
    dispatch({
      type: UPDATE_ALL_TOKENS_IN_UNISWAP,
      payload: {
        allTokens,
      },
    })
  }, [])

  const updateTopLps = useCallback((topLps: any) => {
    dispatch({
      type: UPDATE_TOP_LPS,
      payload: {
        topLps,
      },
    })
  }, [])
  return (
    <GlobalDataContext.Provider
      value={useMemo(
        () => [
          state,
          {
            update,
            updateTransactions,
            updateChart,
            updateMetisPrice,
            updateTopLps,
            updateAllPairsInNetswap,
            updateAllTokensInNetswap,
          },
        ],
        [
          state,
          update,
          updateTransactions,
          updateTopLps,
          updateChart,
          updateMetisPrice,
          updateAllPairsInNetswap,
          updateAllTokensInNetswap,
        ]
      )}
    >
      {children as any}
    </GlobalDataContext.Provider>
  )
}

/**
 * Gets all the global data for the overview page.
 * Needs current metis price and the old metis price to get
 * 24 hour USD changes.
 * @param {*} metisPrice
 * @param {*} oldMetisPrice
 */
async function getGlobalData(metisPrice: number, oldMetisPrice: number) {
  // data for each day , historic data used for % changes
  let data: GlobalDataWithChange | null = null
  let oneDayData: GlobalData
  let twoDayData: GlobalData

  const formatFactoryDataToGlobalData = (factoryData: FactoryData): GlobalData => {
    return {
      id: factoryData.id,
      totalVolumeUSD: parseFloat(factoryData.totalVolumeUSD),
      totalVolumeMETIS: parseFloat(factoryData.totalVolumeMETIS),
      untrackedVolumeUSD: parseFloat(factoryData.untrackedVolumeUSD),
      totalLiquidityUSD: parseFloat(factoryData.totalLiquidityUSD),
      totalLiquidityMETIS: parseFloat(factoryData.totalLiquidityMETIS),
      txCount: parseFloat(factoryData.txCount),
      pairCount: parseFloat(factoryData.pairCount),
      userCount: parseFloat(factoryData.userCount),
    }
  }

  try {
    // get timestamps for the days
    const utcCurrentTime = dayjs(new Date())
    const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix()
    const utcTwoDaysBack = utcCurrentTime.subtract(2, 'day').unix()
    const utcOneWeekBack = utcCurrentTime.subtract(1, 'week').unix()
    const utcTwoWeeksBack = utcCurrentTime.subtract(2, 'week').unix()

    // get the blocks needed for time travel queries
    let [oneDayBlock, twoDayBlock, oneWeekBlock, twoWeekBlock] = await getBlocksFromTimestamps([
      utcOneDayBack,
      utcTwoDaysBack,
      utcOneWeekBack,
      utcTwoWeeksBack,
    ])

    // fetch the global data
    let result = await client().query<{ netswapFactories: FactoryData[] }>({
      query: GLOBAL_DATA(),
      fetchPolicy: 'cache-first',
    })

    data = {
      ...formatFactoryDataToGlobalData(result.data.netswapFactories[0]),
      oneDayVolumeUSD: 0,
      volumeChangeUSD: 0,
      liquidityChangeUSD: 0,
      oneDayTxns: 0,
      txnChange: 0,

      oneWeekVolume: 0,
      weeklyVolumeChange: 0,
    }

    // fetch the historical data
    let oneDayResult = await client().query({
      query: GLOBAL_DATA(oneDayBlock?.number),
      fetchPolicy: 'cache-first',
    })

    oneDayData = formatFactoryDataToGlobalData(oneDayResult.data.netswapFactories[0])

    let twoDayResult = await client().query({
      query: GLOBAL_DATA(twoDayBlock?.number),
      fetchPolicy: 'cache-first',
    })

    twoDayData = formatFactoryDataToGlobalData(twoDayResult.data.netswapFactories[0])

    let oneWeekResult = await client().query({
      query: GLOBAL_DATA(oneWeekBlock?.number),
      fetchPolicy: 'cache-first',
    })

    const oneWeekData = formatFactoryDataToGlobalData(oneWeekResult.data.netswapFactories[0])

    let twoWeekResult = await client().query({
      query: GLOBAL_DATA(twoWeekBlock?.number),
      fetchPolicy: 'cache-first',
    })
    const twoWeekData = twoWeekResult.data.netswapFactories[0]

    if (data && oneDayData && twoDayData) {
      let [oneDayVolumeUSD, volumeChangeUSD] = get2DayPercentChange(
        data.totalVolumeUSD,
        oneDayData.totalVolumeUSD ? oneDayData.totalVolumeUSD : 0,
        twoDayData.totalVolumeUSD ? twoDayData.totalVolumeUSD : 0
      )

      const [oneDayTxns, txnChange] = get2DayPercentChange(
        data.txCount,
        oneDayData.txCount ? oneDayData.txCount : 0,
        twoDayData.txCount ? twoDayData.txCount : 0
      )

      // format the total liquidity in USD
      data.totalLiquidityUSD = data.totalLiquidityMETIS * metisPrice
      const liquidityChangeUSD = getPercentChange(
        data.totalLiquidityMETIS * metisPrice,
        oneDayData.totalLiquidityMETIS * oldMetisPrice
      )

      if (oneWeekData && twoWeekData) {
        const [oneWeekVolume, weeklyVolumeChange] = get2DayPercentChange(
          data.totalVolumeUSD,
          oneWeekData.totalVolumeUSD,
          twoWeekData.totalVolumeUSD
        )

        data.oneWeekVolume = oneWeekVolume
        data.weeklyVolumeChange = weeklyVolumeChange
      }

      // add relevant fields with the calculated amounts
      data.oneDayVolumeUSD = oneDayVolumeUSD
      data.volumeChangeUSD = volumeChangeUSD
      data.liquidityChangeUSD = liquidityChangeUSD
      data.oneDayTxns = oneDayTxns
      data.txnChange = txnChange
    } else if (data && oneDayData) {
      let [oneDayVolumeUSD, volumeChangeUSD] = get2DayPercentChange(
        data.totalVolumeUSD,
        oneDayData.totalVolumeUSD ? oneDayData.totalVolumeUSD : 0,
        0
      )

      const [oneDayTxns, txnChange] = get2DayPercentChange(data.txCount, oneDayData.txCount ? oneDayData.txCount : 0, 0)

      // format the total liquidity in USD
      data.totalLiquidityUSD = data.totalLiquidityMETIS * metisPrice
      const liquidityChangeUSD = getPercentChange(
        data.totalLiquidityMETIS * metisPrice,
        oneDayData.totalLiquidityMETIS * oldMetisPrice
      )

      if (oneWeekData && twoWeekData) {
        const [oneWeekVolume, weeklyVolumeChange] = get2DayPercentChange(
          data.totalVolumeUSD,
          oneWeekData.totalVolumeUSD,
          twoWeekData.totalVolumeUSD
        )

        data.oneWeekVolume = oneWeekVolume
        data.weeklyVolumeChange = weeklyVolumeChange
      }

      // add relevant fields with the calculated amounts
      data.oneDayVolumeUSD = oneDayVolumeUSD
      data.volumeChangeUSD = volumeChangeUSD
      data.liquidityChangeUSD = liquidityChangeUSD
      data.oneDayTxns = oneDayTxns
      data.txnChange = txnChange
    }

    // if (data && oneDayData && twoDayData && twoWeekData) {
    //   let [oneDayVolumeUSD, volumeChangeUSD] = get2DayPercentChange(
    //     data.totalVolumeUSD,
    //     oneDayData.totalVolumeUSD ? oneDayData.totalVolumeUSD : 0,
    //     twoDayData.totalVolumeUSD ? twoDayData.totalVolumeUSD : 0
    //   )

    //   const [oneWeekVolume, weeklyVolumeChange] = get2DayPercentChange(
    //     data.totalVolumeUSD,
    //     oneWeekData.totalVolumeUSD,
    //     twoWeekData.totalVolumeUSD
    //   )

    //   const [oneDayTxns, txnChange] = get2DayPercentChange(
    //     data.txCount,
    //     oneDayData.txCount ? oneDayData.txCount : 0,
    //     twoDayData.txCount ? twoDayData.txCount : 0
    //   )

    //   // format the total liquidity in USD
    //   data.totalLiquidityUSD = data.totalLiquidityMETIS * metisPrice
    //   const liquidityChangeUSD = getPercentChange(
    //     data.totalLiquidityMETIS * metisPrice,
    //     oneDayData.totalLiquidityMETIS * oldMetisPrice
    //   )

    //   // add relevant fields with the calculated amounts
    //   data.oneDayVolumeUSD = oneDayVolumeUSD
    //   data.oneWeekVolume = oneWeekVolume
    //   data.weeklyVolumeChange = weeklyVolumeChange
    //   data.volumeChangeUSD = volumeChangeUSD
    //   data.liquidityChangeUSD = liquidityChangeUSD
    //   data.oneDayTxns = oneDayTxns
    //   data.txnChange = txnChange
    // }
  } catch (e) {
    console.log(e)
  }

  // console.log(data)

  return data
}

/**
 * Get historical data for volume and liquidity used in global charts
 * on main page
 * @param {*} oldestDateToFetch // start of window to fetch from
 */
const getChartData = async (
  oldestDateToFetch: number
): Promise<[NetswapDayData[], (NetswapDayData & { weeklyVolumeUSD?: number })[]]> => {
  let data: NetswapDayData[] = []
  let weeklyData: (NetswapDayData & { weeklyVolumeUSD?: number })[] = []
  const utcEndTime = dayjs.utc()
  let skip = 0
  let allFound = false

  try {
    while (!allFound) {
      let result = await client().query<{ netswapDayDatas: NetswapDayData[] }>({
        query: GLOBAL_CHART,
        variables: {
          startTime: oldestDateToFetch,
          skip,
        },
        fetchPolicy: 'cache-first',
      })
      skip += 1000
      data = data.concat(result.data.netswapDayDatas)
      if (result.data.netswapDayDatas.length < 1000) {
        allFound = true
      }
    }

    if (data) {
      let dayIndexSet = new Set()
      const oneDay = 24 * 60 * 60

      // for each day, parse the daily volume and format for chart array
      data.forEach((dayData, i) => {
        // add the day index to the set of days
        dayIndexSet.add((Number(data[i].date) / oneDay).toFixed(0))
      })

      let dayIndexArray = [...data]

      // fill in empty days ( there will be no day datas if no trades made that day )
      let timestamp = data[0].date ? Number(data[0].date) : oldestDateToFetch
      let latestLiquidityUSD = data[0].totalLiquidityUSD
      let index = 1
      while (timestamp < utcEndTime.unix() - oneDay) {
        const nextDay = timestamp + oneDay
        let currentDayIndex = (nextDay / oneDay).toFixed(0)
        if (!dayIndexSet.has(currentDayIndex)) {
          data.push({
            id: '',
            totalVolumeUSD: '',
            dailyVolumeMETIS: '',
            totalLiquidityMETIS: '',
            date: nextDay.toString(),
            dailyVolumeUSD: '',
            totalLiquidityUSD: latestLiquidityUSD,
          })
        } else {
          latestLiquidityUSD = dayIndexArray[index].totalLiquidityUSD
          index = index + 1
        }
        timestamp = nextDay
      }
    }

    // format weekly data for weekly sized chunks
    data = data.sort((a, b) => (parseInt(a.date) > parseInt(b.date) ? 1 : -1))
    let startIndexWeekly = -1
    let currentWeek = -1
    data.forEach((entry, i) => {
      const week = dayjs.utc(dayjs.unix(Number(data[i].date))).week()
      if (week !== currentWeek) {
        currentWeek = week
        startIndexWeekly++
      }
      weeklyData[startIndexWeekly] = weeklyData[startIndexWeekly] || {}
      weeklyData[startIndexWeekly].date = data[i].date
      weeklyData[startIndexWeekly].weeklyVolumeUSD =
        (weeklyData[startIndexWeekly].weeklyVolumeUSD ?? 0) + Number(data[i].dailyVolumeUSD)
    })
  } catch (e) {
    console.log(e)
  }
  return [data, weeklyData]
}

/**
 * Get and format transactions for global page
 */
const getGlobalTransactions = async () => {
  let transactions: PairTransactions = {
    mints: [],
    burns: [],
    swaps: [],
  }

  try {
    let result = await client().query<{ transactions: PairTransactions[] }>({
      query: GLOBAL_TXNS,
      fetchPolicy: 'cache-first',
    })
    transactions.mints = []
    transactions.burns = []
    transactions.swaps = []
    result?.data?.transactions &&
      result.data.transactions.map((transaction) => {
        if (transaction.mints.length > 0) {
          transaction.mints.map((mint) => {
            return transactions.mints.push(mint)
          })
        }
        if (transaction.burns.length > 0) {
          transaction.burns.map((burn) => {
            return transactions.burns.push(burn)
          })
        }
        if (transaction.swaps.length > 0) {
          transaction.swaps.map((swap) => {
            return transactions.swaps.push(swap)
          })
        }
        return true
      })
  } catch (e) {
    console.log(e)
  }

  return transactions
}

/**
 * Gets the current price  of METIS, 24 hour price, and % change between them
 */
const getMetisPrice = async () => {
  const utcCurrentTime = dayjs()
  const utcOneDayBack = utcCurrentTime.subtract(1, 'day').startOf('minute').unix()

  let metisPrice = 0
  let metisPriceOneDay = 0
  let priceChangeMETIS = 0

  try {
    let oneDayBlock = await getBlockFromTimestamp(utcOneDayBack)
    let result = await client().query({
      query: METIS_PRICE(),
      fetchPolicy: 'cache-first',
    })
    let resultOneDay = await client().query({
      query: METIS_PRICE(oneDayBlock),
      fetchPolicy: 'cache-first',
    })
    const currentPrice = result?.data?.bundles[0]?.metisPrice
    const oneDayBackPrice = resultOneDay?.data?.bundles[0]?.metisPrice
    priceChangeMETIS = getPercentChange(currentPrice, oneDayBackPrice)
    metisPrice = currentPrice
    metisPriceOneDay = oneDayBackPrice
  } catch (e) {
    console.log(e)
  }

  return [metisPrice, metisPriceOneDay, priceChangeMETIS]
}

const PAIRS_TO_FETCH = 500
const TOKENS_TO_FETCH = 500

/**
 * Loop through every pair on netswap, used for search
 */
async function getAllPairsOnNetswap() {
  try {
    let allFound = false
    let pairs: PairBaseInfo[] = []
    let skipCount = 0
    while (!allFound) {
      let result = await client().query<{ pairs: PairBaseInfo[] }>({
        query: ALL_PAIRS,
        variables: {
          skip: skipCount,
        },
        fetchPolicy: 'cache-first',
      })
      skipCount = skipCount + PAIRS_TO_FETCH
      pairs = pairs.concat(result?.data?.pairs)
      if (result?.data?.pairs.length < PAIRS_TO_FETCH || pairs.length > PAIRS_TO_FETCH) {
        allFound = true
      }
    }
    return pairs
  } catch (e) {
    console.log(e)
    return []
  }
}

/**
 * Loop through every token on netswap, used for search
 */
async function getAllTokensOnNetswap() {
  try {
    let allFound = false
    let skipCount = 0
    let tokens: TokenBaseInfo[] = []
    while (!allFound) {
      let result = await client().query<{ tokens: TokenBaseInfo[] }>({
        query: ALL_TOKENS,
        variables: {
          skip: skipCount,
        },
        fetchPolicy: 'cache-first',
      })
      tokens = tokens.concat(result?.data?.tokens)
      if (result?.data?.tokens?.length < TOKENS_TO_FETCH || tokens.length > TOKENS_TO_FETCH) {
        allFound = true
      }
      skipCount = skipCount += TOKENS_TO_FETCH
    }
    return tokens
  } catch (e) {
    console.error(e)
    return []
  }
}

/**
 * Hook that fetches overview data, plus all tokens and pairs for search
 */
export function useGlobalData() {
  const [state, { update, updateAllPairsInNetswap, updateAllTokensInNetswap }] = useGlobalDataContext()
  const [metisPrice, oldMetisPrice] = useMetisPrice()

  const data = state?.globalData

  useEffect(() => {
    async function fetchData() {
      let globalData = await getGlobalData(metisPrice, oldMetisPrice)
      globalData && update(globalData)

      let allPairs = await getAllPairsOnNetswap()
      updateAllPairsInNetswap(allPairs)

      let allTokens = await getAllTokensOnNetswap()
      updateAllTokensInNetswap(allTokens)
    }

    if (!data.id && metisPrice && oldMetisPrice) {
      fetchData()
    }
  }, [metisPrice, oldMetisPrice, update, data, updateAllPairsInNetswap, updateAllTokensInNetswap])

  // return (
  //   data || {
  //     id: FACTORY_ADDRESS,
  //     liquidityChangeUSD: 0,
  //     oneDayTxns: 0,
  //     oneDayVolumeUSD: 0,
  //     oneWeekVolume: 0,
  //     txnChange: 0,
  //     userCount: '0',
  //     volumeChangeUSD: 0,
  //     weeklyVolumeChange: 0,
  //     totalVolumeUSD: '0',
  //     totalVolumeMETIS: '0',
  //     untrackedVolumeUSD: '0',
  //     totalLiquidityUSD: '0',
  //     totalLiquidityMETIS: '0',
  //     txCount: 0,
  //     pairCount: 0,
  //   }
  // )
  return data
}

export function useGlobalChartData() {
  const [state, { updateChart }] = useGlobalDataContext()
  const [oldestDateFetch, setOldestDateFetched] = useState<number>()
  const [activeWindow] = useTimeframe()

  const chartDataDaily = state?.chartData?.daily
  const chartDataWeekly = state?.chartData?.weekly

  /**
   * Keep track of oldest date fetched. Used to
   * limit data fetched until its actually needed.
   * (dont fetch year long stuff unless year option selected)
   */
  useEffect(() => {
    // based on window, get starttime
    let startTime = getTimeframe(activeWindow)

    if (!oldestDateFetch || (activeWindow && startTime < oldestDateFetch)) {
      setOldestDateFetched(startTime)
    }
  }, [activeWindow, oldestDateFetch])

  /**
   * Fetch data if none fetched or older data is needed
   */
  useEffect(() => {
    async function fetchData() {
      // historical stuff for chart
      if (oldestDateFetch) {
        let [newChartData, newWeeklyData] = await getChartData(oldestDateFetch)
        updateChart(newChartData, newWeeklyData)
      }
    }
    if (oldestDateFetch && chartDataDaily.length === 0 && chartDataWeekly.length === 0) {
      fetchData()
    }
  }, [chartDataDaily, chartDataWeekly, oldestDateFetch, updateChart])

  return [chartDataDaily, chartDataWeekly]
}

export function useGlobalTransactions() {
  const [state, { updateTransactions }] = useGlobalDataContext()
  const transactions = state?.transactions
  useEffect(() => {
    async function fetchData() {
      if (transactions.mints.length + transactions.swaps.length + transactions.burns.length === 0) {
        let txns = await getGlobalTransactions()
        updateTransactions(txns)
      }
    }
    fetchData()
  }, [updateTransactions, transactions])
  return transactions
}

export function useMetisPrice() {
  const [state, { updateMetisPrice }] = useGlobalDataContext()
  const metisPrice = state?.[METIS_PRICE_KEY]
  const metisPriceOld = state?.['oneDayPrice']
  useEffect(() => {
    async function checkForMetisPrice() {
      if (!metisPrice && updateMetisPrice) {
        let [newPrice, oneDayPrice, priceChange] = await getMetisPrice()
        updateMetisPrice(newPrice, oneDayPrice, priceChange)
      }
    }
    checkForMetisPrice()
  }, [metisPrice, updateMetisPrice])

  return [metisPrice, metisPriceOld]
}

export function useAllPairsInNetswap() {
  const [state] = useGlobalDataContext()
  let allPairs = state?.allPairs

  return allPairs || []
}

export function useAllTokensInNetswap() {
  const [state] = useGlobalDataContext()
  let allTokens = state?.allTokens

  return allTokens || []
}

/**
 * Get the top liquidity positions based on USD size
 * @TODO Not a perfect lookup needs improvement
 */
export function useTopLps() {
  const [state, { updateTopLps }] = useGlobalDataContext()
  let topLps = state?.topLps

  const allPairs = useAllPairData()

  useEffect(() => {
    async function fetchData() {
      // get top 20 by reserves
      let topPairs = Object.keys(allPairs)
        ?.sort((a, b) => (parseFloat(allPairs[a].reserveUSD) > parseFloat(allPairs[b].reserveUSD) ? -1 : 1))
        ?.slice(0, 99)
        .map((pair) => pair)

      let topLpLists = await Promise.all(
        topPairs.map(async (pair) => {
          // for each one, fetch top LPs
          try {
            const { data: results } = await client().query<{ liquidityPositions: LPBaseInfo[] }>({
              query: TOP_LPS_PER_PAIRS,
              variables: {
                pair: pair.toString(),
              },
              fetchPolicy: 'cache-first',
            })
            if (results) {
              return results.liquidityPositions
            }
          } catch (e) {
            console.error(e)
          }

          return []
        })
      )

      // get the top lps from the results formatted
      const topLps: TopLP[] = []
      topLpLists
        .filter((i) => !!i) // check for ones not fetched correctly
        .map((list) => {
          return list.map((entry) => {
            const pairData = allPairs[entry.pair.id]
            return topLps.push({
              user: entry.user,
              pairName: pairData.token0.symbol + '-' + pairData.token1.symbol,
              pairAddress: entry.pair.id,
              token0: pairData.token0.id,
              token1: pairData.token1.id,
              usd:
                (parseFloat(entry.liquidityTokenBalance) / parseFloat(pairData.totalSupply)) *
                parseFloat(pairData.reserveUSD),
            })
          })
        })

      const sorted = topLps.sort((a, b) => (a.usd > b.usd ? -1 : 1))
      const shorter = sorted.splice(0, 100)
      updateTopLps(shorter)
    }

    if (topLps.length === 0 && allPairs && Object.keys(allPairs).length > 0) {
      fetchData()
    }
  })

  return topLps
}
