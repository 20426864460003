/* eslint-disable react-hooks/exhaustive-deps */
import BigNumber from 'bignumber.js'
import { WNettStakeContract } from 'constants/wNettStaking'
import { useActiveWeb3React } from 'hooks'
import { useContract } from 'hooks/contract/useContract'
import { useGetWNett } from 'hooks/contract/useGetContract'
import { useEffect, useMemo, useState } from 'react'
import { useFormatSymbolTokenPrice } from 'state/data/hooks/useTokenPrice'

export default function useGetWNettInfo() {
  const { account } = useActiveWeb3React()
  const stakeContract = useContract(WNettStakeContract)
  const [totalStaked, setTotalStake] = useState(new BigNumber(0))
  const [totalSupply, setTotalSupply] = useState(new BigNumber(0))
  const wNettContract = useGetWNett()
  const [userStakeAmount, setUserStakeAmount] = useState(new BigNumber(0))
  const priceInfo = useFormatSymbolTokenPrice()
  async function getTotalSupply() {
    if (wNettContract) {
      const res = await wNettContract.totalSupply()
      if (res) {
        setTotalSupply(res?.toString() ? new BigNumber(res.toString()).shiftedBy(-18) : new BigNumber(0))
      }
    }
  }

  async function getTotalStakedNett() {
    if (stakeContract) {
      const res = await stakeContract.totalNETTStaked()
      if (res) {
        setTotalStake(res?.toString() ? new BigNumber(res.toString()).shiftedBy(-18) : new BigNumber(0))
      }
    }
  }

  useEffect(() => {
    let interval: any
    if (stakeContract) {
      getTotalStakedNett()

      interval = setInterval(() => {
        getTotalStakedNett()
      }, 30000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [stakeContract])

  useEffect(() => {
    let interval: any
    if (wNettContract) {
      getTotalSupply()

      interval = setInterval(() => {
        getTotalSupply()
      }, 30000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [wNettContract])

  async function getUserInfo() {
    if (!stakeContract || !account) return
    try {
      const res = await stakeContract.userInfo(account)
      setUserStakeAmount(new BigNumber(res?.amount.toString() || 0).shiftedBy(-18))
      // if (res?.allocation.toNumber()) {
      //   setUserAllocation(new BigNumber(res?.allocation.toString() || 0).shiftedBy(-data.saleToken.decimals))
      // }
    } catch (e) {
      console.error(`get  userInfo ${account} failed`)
      console.error(e)
    }
  }

  const nettPrice: number = useMemo(() => {
    return priceInfo?.nett?.price || 0
  }, [priceInfo])

  const totalStakedUSD = useMemo(() => {
    if (nettPrice) {
      return totalStaked.multipliedBy(nettPrice).toNumber()
    }
    return 0
  }, [totalStaked, nettPrice])

  const youStakedUSD = useMemo(() => {
    if (nettPrice) {
      return userStakeAmount.multipliedBy(nettPrice).toNumber()
    }
    return 0
  }, [userStakeAmount, nettPrice])

  useEffect(() => {
    getUserInfo()
  }, [account, stakeContract])

  return {
    totalStaked,
    totalSupply,
    userStakeAmount,
    stakeContract,
    wNettContract,
    getUserInfo,
    getTotalStakedNett,
    getTotalSupply,
    totalStakedUSD,
    youStakedUSD,
    nettPrice
  }
}
