import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { PortisConnector } from '@web3-react/portis-connector'
import { ChainId } from '@netswap/sdk'

import { FortmaticConnector } from './Fortmatic'
import { NetworkConnector } from './NetworkConnector'
// import { SupportChainId } from '../constants'

export const RPC = {
  [ChainId.MAINNET]: 'https://andromeda-rpc.metis.io',
  [ChainId.TESTNET]: 'https://goerli.gateway.metisdevops.link',
  [ChainId.TESTNET_SEPOLIA]: 'https://sepolia.metisdevops.link',
}

const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID

export const NETWORK_CHAIN_ID: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '1')

// if (typeof NETWORK_URL === 'undefined') {
//   throw new Error(`REACT_APP_NETWORK_URL must be a defined environment variable`)
// }

export const network = new NetworkConnector({
  defaultChainId: NETWORK_CHAIN_ID,
  urls: {
    [ChainId.MAINNET]: RPC[ChainId.MAINNET],
    [ChainId.TESTNET]: RPC[ChainId.TESTNET],
    [ChainId.TESTNET_SEPOLIA]: RPC[ChainId.TESTNET_SEPOLIA],
  },
})

const supportedChainIds = [ChainId.MAINNET, ChainId.TESTNET, ChainId.TESTNET_SEPOLIA]
let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any))
}

export const injected = new InjectedConnector({
  supportedChainIds: supportedChainIds,
})

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: {
    [ChainId.MAINNET]: `https://andromeda.metis.io/?owner=${ChainId.MAINNET}`,
    [ChainId.TESTNET]: `https://goerli.gateway.metisdevops.link`,
    [ChainId.TESTNET_SEPOLIA]: `https://goerli.gateway.metisdevops.link`,
  },
  // bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  supportedChainIds: supportedChainIds,
})

// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? '',
  chainId: 1,
})

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? '',
  networks: [1],
})

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: RPC[NETWORK_CHAIN_ID as ChainId],
  appName: 'Uniswap',
  appLogoUrl:
    'https://mpng.pngfly.com/20181202/bex/kisspng-emoji-domain-unicorn-pin-badges-sticker-unicorn-tumblr-emoji-unicorn-iphoneemoji-5c046729264a77.5671679315437924251569.jpg',
})
