import { gql } from '@apollo/client'
import { MicroServicesClient } from '../index'
import { FarmLPPool } from './farm-lp-pools'

export default function getBoostedFarmPools(): Promise<FarmLPPool[] | undefined> {
  return new Promise(resolve => {
    MicroServicesClient()
      .query<{
        boostedFarmPools: FarmLPPool[]
      }>({
        query: gql`
          query boostedFarmPools {
            boostedFarmPools(skip: 0, limit: 100) {
              id
              pair {
                id
                token0 {
                  address
                  decimals
                  symbol
                  name
                }
                token1 {
                  address
                  decimals
                  symbol
                  name
                }
                totalSupply
                reserveUSD
              }
              rewarder {
                id
                rewardToken {
                  address
                  decimals
                  symbol
                  name
                }
                tokenPerSec
              }
              allocPoint
              totalDepositVolumeUSD
              nettPerSec
              medianBoostedUser
              baseApr
              lpApr
              averageBoostedApr
              rewarderApr
              nettApr
            }
          }
        `,
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        let returnData = undefined
        let { data } = res
        if (data?.boostedFarmPools) {
          returnData = data.boostedFarmPools
            .map(item => {
              return {
                ...item,
                allocPoint: Number(item.allocPoint),
                nettApy: (item as any).nettApr,
                rewarderApy: (item as any).rewarderApr,
                lpApy: item.lpApr,
                isBoosted: true
              }
            })
            .sort((a, b) => b.allocPoint - a.allocPoint)
        }
        resolve(returnData)
      })
      .catch((e: any) => {
        console.error(e)
        resolve(undefined)
      })
  })
}
