import React, { useMemo, useState } from 'react'
import useGetFarmLpPools from 'state/data/hooks/useGetFarmLpPools'
import { useGetTokensPrice } from 'state/data/hooks/useTokenPrice'
import useGetUserFarmLpPools from 'state/data/hooks/useGetUserFarmPools'
import Web3Status from 'components/Web3Status'
import { useActiveWeb3React } from 'hooks'
import styled from 'styled-components'
import { AutoColumn } from 'components/Column'
import { TYPE } from 'theme'
import Swtich from 'components/Swtich'
import Select from 'components/Select'
import { Input, InputAdornment } from '@material-ui/core'
import { SearchOutlined } from '@material-ui/icons'
import { StakeConfig } from 'constants/farm'
import { useContract } from 'hooks/contract/useContract'
import PoolItem from './v2/Pool'
import classNames from 'classnames'
import { FarmLPPool } from 'gql/microservices/farm-lp-pools'
import Numeral from 'numeral'
import useGetOverviewData from 'state/data/hooks/useGetOverviewData'
import { isMobile } from 'react-device-detect'

const Wrapper = styled.div`
  width: 940px;
  margin: 0 auto;
  /* margin-top: 30px; */

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100vw - 2rem);
  `}
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const FarmIcon = styled.img`
  width: 129px;
  height: 102px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 70px;
    height: 60px;
  `}
`

const SwitchTabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.v2.bg06};
  width: 200px;

  height: 30px;
  border-radius: 8px;
  margin-left: 20px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 0;
    width: 145px;
  `}
`

const SwitchTab = styled.div`
  width: 50%;
  border-radius: 8px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.v2.t01};
  font-size: 14px;
  height: 100%;
  cursor: pointer;

  font-weight: 500;
  &.active {
    background-color: #d2cfd5;
    color: #000;
  }
`

const ToolsWrapper = styled.div`
  background: ${({ theme }) => theme.v2.bg02};
  box-sizing: border-box;
  padding: 8px 16px;
  border-radius: 12px;
  width: 100%;
  margin-top: 60px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    padding: 8px;
  `}
`

const LeftTools = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .iconfont {
    font-size: 20px;
    color: ${({ theme }) => theme.v2.t02};
    cursor: pointer;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}

    &:first-child {
      margin-right: 8px;
    }

    &:hover,
    &.active {
      color: ${({ theme }) => theme.v2.t01};
    }
  }

  .icon-list {
    font-size: 21px;
    padding-top: 4px;
  }
`

const MyLiquidityWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const SortByWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 10px;
  `}
`

const Search = styled(Input)`
  &.MuiInputBase-root {
    background: ${({ theme }) => theme.v2.bg04};

    height: 32px;

    border-radius: 24px;
    padding: 0 12px;

    &::after,
    &::before {
      border: none !important;
    }
    input {
      color: ${({ theme }) => theme.v2.t01};

      font-size: 12px;
      ::placeholder {
        color: ${({ theme }) => theme.v2.t02};
      }
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 150px;
    margin-top: 10px;
  `}
  }
`
const CustomSearchIcon = styled(SearchOutlined)`
  color: ${({ theme }) => theme.v2.t02};
`

const PoolsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;

  &.card {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100vw - 2rem);
  `}
    .cardWrapper {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      width: 298px;
      margin-right: 24px;

      ${({ theme }) => theme.mediaWidth.upToSmall`
        align-items: center;
        width: 100%;
        margin-right: 0;
    `}
      &:last-child {
        margin-right: 0;
      }
    }
  }
`

const SortItems = [
  {
    text: 'APR',
    value: 'apr',
  },
  {
    text: 'TVL',
    value: 'tvl',
  },
  {
    text: 'Rewards',
    value: 'rewards',
  },
]

export default function Farm() {
  const { overview } = useGetOverviewData()
  const [active, setActive] = useState(true)
  const [showType, setShowType] = useState(isMobile ? 'card' : 'list')

  const { account } = useActiveWeb3React()
  const [search, setSearch] = useState('')
  const [myLiquidityChecked, setMyLiquidityChecked] = useState(false)
  const [sort, setSort] = useState('apr')

  const userPoolIds = useGetUserFarmLpPools()

  const pools = useGetFarmLpPools()
  const stakeContract = useContract(StakeConfig)

  const rewardTokensAddress = useMemo(() => {
    const res = pools
      .filter((item) => !!item.rewarder)
      .map((item) => item.rewarder?.rewardToken.address.toLowerCase() || '')
    return Array.from(new Set(res))
  }, [pools])

  // update fetchToken state to fetch reward token price
  useGetTokensPrice(rewardTokensAddress)

  const poolsData = useMemo(() => {
    let res = [...pools]
    const now = new Date().getTime()

    if (!active) {
      res = res.filter(
        (item) => !Number(item.allocPoint) && (item.rewarder ? now > item.rewarder.endTimestamp * 1000 : true)
      )
    } else {
      res = res.filter(
        (item) => !!Number(item.allocPoint) || (item.rewarder && now <= item.rewarder.endTimestamp * 1000)
      )
    }

    if (myLiquidityChecked) {
      res = res.filter((item) => userPoolIds.indexOf(item.id) >= 0)
    }

    if (!search) {
      res = [...res]
    } else {
      res = [
        ...res.filter((item) => {
          const token0Symbol = item.pair.token0.symbol.toLowerCase()
          const token1Symbol = item.pair.token1.symbol.toLowerCase()
          const searchValueReg = new RegExp(search.toLowerCase())

          return searchValueReg.test(token0Symbol) || searchValueReg.test(token1Symbol)
        }),
      ]
    }

    if (res.length) {
      res = res.sort((a, b) => {
        let sort1 = b
        let sort2 = a
        //   if (sort === SortDirection.desc) {
        //     sort1 = b
        //     sort2 = a
        //   }
        if (sort === 'tvl') {
          return Number(sort1.totalDepositVolumeUSD) - Number(sort2.totalDepositVolumeUSD)
        } else if (sort === 'rewards') {
          return Number(sort1.nettPerSec) - Number(sort2.nettPerSec)
        } else {
          // apr

          return sort1.nettApy - sort2.nettApy
        }
      })
    }
    return res
  }, [pools, active, sort, myLiquidityChecked, search, userPoolIds])

  const lists = useMemo(() => {
    const lists: FarmLPPool[][] = [[], [], []]
    if (showType !== 'list') {
      for (let i = 0, len = poolsData.length; i < len; i++) {
        lists[i % 3].push(poolsData[i])
      }
    }
    return lists
  }, [poolsData, showType])

  return (
    <Wrapper>
      <TitleWrapper>
        <FarmIcon src={require('assets/images/farm/banner.png')} />
        <AutoColumn justify="center" gap="md">
          <TYPE.v2Main fontWeight="bold" fontSize={32}>
            Farms
          </TYPE.v2Main>
          <TYPE.desc fontSize={14} textAlign="center">
            Staking your LP tokens to harvest more NETT tokens
          </TYPE.desc>
          <TYPE.v2Main fontWeight="bold" fontSize={20} textAlign="center">
            {Numeral(overview?.farmTvl || 0).format('$ 0,0')} Total Value Locked (TVL)
          </TYPE.v2Main>
        </AutoColumn>
      </TitleWrapper>

      <ToolsWrapper>
        <LeftTools>
          <i
            className={classNames('iconfont icon-block-list', showType !== 'list' && 'active')}
            onClick={() => {
              setShowType('card')
            }}
          />
          <i
            className={classNames('iconfont icon-list', showType === 'list' && 'active')}
            onClick={() => {
              setShowType('list')
            }}
          />

          <SwitchTabWrapper>
            <SwitchTab className={active ? 'active' : ''} onClick={() => setActive(true)}>
              Active
            </SwitchTab>
            <SwitchTab className={!active ? 'active' : ''} onClick={() => setActive(false)}>
              Ended
            </SwitchTab>
          </SwitchTabWrapper>
        </LeftTools>
        <MyLiquidityWrapper>
          <TYPE.v2Main fontSize={14} marginRight="12px">
            My farms
          </TYPE.v2Main>
          <Swtich checked={myLiquidityChecked} onChange={setMyLiquidityChecked} />
        </MyLiquidityWrapper>
        <SortByWrapper>
          <TYPE.v2Main fontSize={14} marginRight="12px">
            SORT BY
          </TYPE.v2Main>
          <Select value={sort} items={SortItems} onChange={setSort} />
        </SortByWrapper>
        <Search
          type="text"
          id="search-by-symbol"
          placeholder="Search farm by symbol "
          value={search}
          onChange={(e) => {
            setSearch(e.currentTarget.value)
          }}
          endAdornment={
            <InputAdornment position="start">
              <CustomSearchIcon />
            </InputAdornment>
          }
        />
      </ToolsWrapper>
      <PoolsWrapper className={classNames(showType)}>
        {!account ? (
          <div style={{ marginTop: '30px' }}>
            <Web3Status />
          </div>
        ) : !poolsData.length ? (
          <div style={{ textAlign: 'center', display: 'block', margin: '20px auto' }}>
            <div className="empty">
              <img width="60px" src={require('assets/images/launchpad/empty.png')} alt="Empty" />
              <TYPE.desc>No result yet.</TYPE.desc>
            </div>
          </div>
        ) : showType === 'list' ? (
          poolsData.map((row) => <PoolItem align={showType} key={row.id} data={row} stakeContract={stakeContract} />)
        ) : (
          <>
            {!isMobile ? (
              lists.map((list, index) => {
                return (
                  <div key={index} className="cardWrapper">
                    {list.map((row) => (
                      <PoolItem align={showType} key={row.id} data={row} stakeContract={stakeContract} />
                    ))}
                  </div>
                )
              })
            ) : (
              <div className="cardWrapper">
                {poolsData.map((item) => (
                  <PoolItem align={showType} key={item.id} data={item} stakeContract={stakeContract} />
                ))}
              </div>
            )}
          </>
        )}
      </PoolsWrapper>
    </Wrapper>
  )
}
