/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { isMobile } from 'react-device-detect'
import Modal from 'components/Modal'
import { Checkbox, InputBase } from '@material-ui/core'
import { ButtonMetis } from 'components/Button'
import Value from 'components/Value'
import { AutoRow, RowBetween } from 'components/Row'
import TokenImage from 'components/TokenImage'
import { BigNumber } from 'bignumber.js'
import { Contract } from 'ethers/lib/ethers'
import ERC20 from 'constants/abis/erc20.json'
import { useContractByAbiAddr } from 'hooks/contract/useContract'
import { useActiveWeb3React } from 'hooks'
import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import useSend from 'state/contract/hooks/useSend'
import useTokenApprove from 'hooks/contract/useTokenApprove'
import { LaunchPadV2 } from 'gql/subgraph/launchpadV2'
import { TYPE } from 'theme'
import { ProjectIntro } from 'state/data/hooks/useGetLaunchpadV2Data'

const ModalWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
  background: #3b2046;
  border-radius: 20px;
  .modalInitCon {
    ${({ theme }) => theme.flexColumnNoWrap}
    position: relative;
    align-items: center;
    padding: 20px;
    overflow: hidden;
    .leftBorder,
    .midBorder,
    .rightBorder {
      position: absolute;
      border: 1px solid rgba(255, 255, 255, 0.05);
      border-radius: 50%;
    }
    .leftBorder {
      width: 275px;
      height: 275px;
      left: -43px;
      top: -43px;
    }
    .midBorder {
      width: 247px;
      height: 247px;
      left: 180px;
      top: -61px;
    }
    .rightBorder {
      width: 345px;
      height: 345px;
      left: 193px;
      top: 85px;
    }
  }
  h2,
  h3,
  p {
    margin: 0;
  }

  .depositIcons {
    position: relative;

    .depositIconOne {
      position: absolute;
      left: -10px;
      top: -4px;
      width: 40px;
      ${({ theme }) => theme.mediaWidth.upToSmall`
             width: 30px;
      `};
    }

    .depositIconTwo {
      width: 130px;
      ${({ theme }) => theme.mediaWidth.upToSmall`
             width: 100px;
      `};
    }
  }
  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #ffffff;
    margin: 20px 0;

    ${({ theme }) => theme.mediaWidth.upToSmall`
            margin: 20px 0;
      `};
  }
  p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px;
    &.subTxt {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 8px;
      width: 100%;
    }
  }

  span {
    padding: 0;
  }
  .checkCon {
    cursor: pointer;
    margin-top: 24px;
  }
  .checkInfo {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #989dae;
    margin-left: 12px;
    width: 89%;
  }

  .modalDepositCon {
    ${({ theme }) => theme.flexColumnNoWrap}
    position: relative;
    align-items: center;
    padding: 40px 0 0 0;
    overflow: hidden;
    .leftBorder,
    .midBorder,
    .rightBorder {
      position: absolute;
      border: 1px solid rgba(255, 255, 255, 0.05);
      border-radius: 50%;
    }
    .leftBorder {
      width: 595px;
      height: 595px;
      left: -409px;
      top: -99px;
    }
    .midBorder {
      width: 535px;
      height: 535px;
      left: 73px;
      top: -138px;
    }
    .rightBorder {
      width: 746px;
      height: 747px;
      left: 102px;
      top: 178px;
    }
    h2 {
      margin: 0 0 30px 0;
    }
    .modalDepositTopCon,
    .modalDepositBottomCon {
      padding: 0 40px;
      h3 {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
        margin-bottom: 8px;
      }

      .errorInfo {
        font-size: 12px;
        color: #fd4040;
        font-weight: 500;
        line-height: 14px;
        margin: 0 0 10px 0;
      }
    }
    .modalDepositTopCon {
      ${({ theme }) => theme.flexRowNoWrap}
      justify-content: space-between;
      width: 100%;
      .depositTop {
        ${({ theme }) => theme.flexColumnNoWrap}
        width: calc(50% - 6px);
        .depositTopInfo {
          ${({ theme }) => theme.flexRowNoWrap}
          align-items: center;
          padding: 10px;
          width: 100%;
          height: 60px;
          background: rgba(25, 6, 41, 0.17);
          border-radius: 70px;
          img {
            width: 40px;
            height: 40px;
            margin-right: 10px;
          }
          p {
            font-weight: 500;
            margin: 0;
            font-size: 24px;
            line-height: 29px;
            color: #ffffff;
          }
        }
      }
    }
    .modalDepositBottomCon {
      width: 100%;
      background: rgba(25, 6, 41, 0.4);
      border-radius: 20px;
      padding: 20px 20px 40px 20px;
      margin-top: 32px;
      ${({ theme }) => theme.flexColumnNoWrap}
      .yourNettTip {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        text-align: right;
        color: #afafaf;
      }
      .depositBottomCon {
        ${({ theme }) => theme.flexRowNoWrap}
        justify-content: space-between;
        align-items: center;
        margin: 0 0 10px 0;
        padding: 12px 10px;
        width: 100%;
        height: 62px;
        background: rgba(9, 9, 9, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(13px);
        border-radius: 10px;
        img {
          width: 28px;
          height: 28px;
        }
        .depositBottomInput {
          ${({ theme }) => theme.flexRowNoWrap}
          justify-content: space-between;
          align-items: center;
          padding: 0 10px;
          margin: 0 10px;
          border-left: 1px solid rgba(255, 255, 255, 0.4);
          border-right: 1px solid rgba(255, 255, 255, 0.4);
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
          .MuiInputBase-input {
            font-weight: 600;
            font-size: 32px;
            line-height: 38px;
            color: #ffffff;
            padding: 0;
          }
        }
        .noRightBorder {
          border-right: none;
          padding-right: 0;
          margin-right: 0;
        }
      }
    }
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    .modalInitCon {
      padding: 20px;
    }
    .modalDepositCon {
      padding: 40px 0 0 0;

      .modalDepositTopCon {
        padding: 0 20px;
      }
    }
  `}
`

const Input = styled(InputBase)`
  input {
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`

const ModalBtn = styled(ButtonMetis)`
  width: 100%;
  /* max-width: 360px; */
  margin: 0 auto;
  height: 46px;
  margin-top: 20px;
  font-size: 16px;
  padding: 12px 20px;
  border-radius: 56px;
  &.cancelBtn {
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    background: transparent;
  }
  &.approveBtn {
    background: #1667f6;
  }
`
const MaxBtn = styled(ButtonMetis)`
  width: 47px;
  height: 27px;
  padding: 4px 6px;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 6px;
  font-size: 16px;
  font-weight: 400;
  color: #c42a61;
  line-height: 27px;
  cursor: pointer;
  flex-shrink: 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: fit-content;
    box-sizing: border-box;
    padding: 0 4px;
  `};
`

const InfoLink = styled(NavLink)`
  margin-left: 0 !important;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 10px 0 !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #c42a61;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  `}
`

interface Props {
  isOpen: boolean
  onClose(): void
  padInfo: LaunchPadV2
  padContract: Contract | null
  wNettBalance: BigNumber
  handleDepositSuccess?(): void
  userStakePaymentTokenAmount: BigNumber
  projectInfo?: ProjectIntro
}

export default function DepositModal({
  isOpen,
  onClose,
  padInfo,
  wNettBalance,
  padContract,
  handleDepositSuccess,
  userStakePaymentTokenAmount,
  projectInfo,
}: Props) {
  const send = useSend()
  const [depositModal, setDepositModal] = useState('init')
  const [confirmed, setConfirmed] = useState(false)
  const { account } = useActiveWeb3React()
  const [userPaymentTokenBalance, setUserPaymentTokenBalance] = useState(new BigNumber(0))
  const [inputValue, setInputValue] = useState('')

  const paymenTokenContract = useContractByAbiAddr(padInfo.paymentToken.id, ERC20)

  async function getUserPaymentTokenBalance() {
    if (paymenTokenContract && account) {
      const res = await paymenTokenContract.balanceOf(account)

      setUserPaymentTokenBalance(res?.toString() ? new BigNumber(res.toString()).shiftedBy(-6) : new BigNumber(0))
    } else {
      setUserPaymentTokenBalance(new BigNumber(0))
    }
  }

  useEffect(() => {
    getUserPaymentTokenBalance()
  }, [paymenTokenContract, account])

  function handleClose() {
    setDepositModal('init')
    onClose()
  }

  const inputAmount = useMemo(() => {
    return new BigNumber(inputValue || 0)
  }, [inputValue])

  const errorCode = useMemo(() => {
    const currentUserStakeAmount = userStakePaymentTokenAmount.plus(inputAmount)
    if (inputAmount.isGreaterThan(wNettBalance.multipliedBy(padInfo.USDPerWNETT || 0))) {
      // need remaind user to stake nett to get wnett allocation
      return 1
    } else if (inputAmount.isGreaterThan(userPaymentTokenBalance)) {
      // Insufficient balance
      return 2
    } else if (currentUserStakeAmount.isLessThan(padInfo.minDeposit)) {
      return 3
    }
    return 0
  }, [inputAmount, wNettBalance, padInfo, userStakePaymentTokenAmount])

  function handleMax() {
    setInputValue(
      userPaymentTokenBalance.isGreaterThan(wNettBalance.multipliedBy(padInfo.USDPerWNETT || 0))
        ? wNettBalance.multipliedBy(padInfo.USDPerWNETT || 0).toFixed(6, 1)
        : userPaymentTokenBalance.toFixed()
    )
  }

  const { approved, approve } = useTokenApprove(
    {
      address: padInfo.paymentToken.id || '',
      decimals: 6,
      symbol: padInfo.paymentToken.symbol || '',
    },
    padContract?.address || '',
    inputAmount
  )

  async function stake() {
    if (padContract) {
      await send({
        contract: padContract,
        method: 'deposit',
        params: [inputAmount.shiftedBy(6).toFixed(0, 1)],
        pendingText: `Depositing ${inputAmount.toFixed()} ${padInfo.paymentToken.symbol}`,
        summary: `Deposited ${inputAmount.toFixed()} ${padInfo.paymentToken.symbol}`,
        onSuccess() {
          getUserPaymentTokenBalance()
          if (handleDepositSuccess) {
            handleDepositSuccess()
          }
          setInputValue('')
          handleClose()
        },
      })
    }
  }

  function handleConfirm() {
    if (approved) {
      stake()
    } else {
      approve()
    }
  }
  return (
    <Modal isOpen={isOpen} onDismiss={handleClose} minHeight={false} maxWidth={440} maxHeight={140}>
      <ModalWrapper>
        {depositModal === 'init' && (
          <div className="modalInitCon">
            <div className="leftBorder"></div>
            <div className="midBorder"></div>
            <div className="rightBorder"></div>
            <div className="depositIcons">
              <img
                src={require('assets/images/launchpad/deposit-icon-one.svg')}
                className="depositIconOne"
                alt="depositIconOne"
              />
              <img
                className="depositIconTwo"
                src={require('assets/images/launchpad/deposit-icon-two.svg')}
                alt="depositIconTwo"
              />
            </div>
            <h2>Deposit Rules</h2>
            <p className="subTxt">1. Please deposit the needed stablecoin and the wNETT you used will be burned.</p>
            <p className="subTxt">2. The deposited stablecoin cannot be withdrawn during the sale.</p>
            <p className="subTxt">
              3. If the Unlimited Tier sale is over-subscribed, you can claim refunds after sale ends.
            </p>
            <p className="subTxt">4. There will be amount of participate fees charged by Netswap.</p>
            <p className="subTxt">
              5. The purchased tokens will be sent to your wallet in the distribution stage according to the vesting
              plan.
            </p>
            <p className="subTxt">{`6. Don't forget adding sale token into your wallet.`}</p>
            <p className="subTxt">7. Deposit at your own risk</p>
            <AutoRow className="checkCon" onClick={() => setConfirmed(!confirmed)}>
              <Checkbox
                name="confirmed"
                checkedIcon={<img src={require('assets/images/launchpad/check-on.svg')} alt="checkIcon" />}
                icon={<img src={require('assets/images/ic_tick_off.png')} width="20px" alt="checkOffIcon" />}
                checked={confirmed}
                onChange={() => setConfirmed(!confirmed)}
              />
              <span className="checkInfo">I have read and understood the above information.</span>
            </AutoRow>

            <ModalBtn
              disabled={!confirmed}
              onClick={() => {
                setDepositModal('deposit')
              }}
            >
              Deposit
            </ModalBtn>

            <ModalBtn className="cancelBtn" onClick={handleClose}>
              Cancel
            </ModalBtn>
          </div>
        )}

        {depositModal === 'deposit' && (
          <div className="modalDepositCon">
            <h2>Deposit</h2>
            <div className="modalDepositTopCon">
              <div className="depositTop">
                <h3>Project:</h3>
                <div className="depositTopInfo">
                  <TokenImage address={padInfo.issuedToken.id} />
                  <p>{padInfo.issuedToken.symbol}</p>
                </div>
              </div>
              <div className="depositTop">
                <h3>Price:</h3>
                <div className="depositTopInfo">
                  <TokenImage address={'0x90fE084F877C65e1b577c7b2eA64B8D8dd1AB278'} />
                  <p>
                    $<Value value={padInfo.price || '--'} />
                  </p>
                </div>
              </div>
            </div>
            <div className="modalDepositBottomCon">
              <div className="depositBottom">
                <RowBetween>
                  <h3>Deposit</h3>
                </RowBetween>
                <div className="depositBottomCon">
                  <TokenImage address={padInfo.paymentToken.id} />
                  <div className="depositBottomInput">
                    <Input
                      fullWidth
                      autoFocus={!isMobile}
                      placeholder="0.0"
                      type="number"
                      value={inputValue}
                      onChange={(e) => {
                        if (Number(e.currentTarget.value) < 0 || e.currentTarget.value === '-') {
                          setInputValue('')
                          return
                        }
                        if (/^([1-9][0-9]*)+(.[0-9]{1,6})?$/g.test(e.currentTarget.value)) {
                          setInputValue(e.currentTarget.value || '')
                        }
                      }}
                    />
                    {padInfo.paymentToken.symbol}
                  </div>
                  <MaxBtn onClick={handleMax}>MAX</MaxBtn>
                </div>
                <div className="yourNettTip">
                  Your {padInfo.paymentToken.symbol}: {userPaymentTokenBalance.toFixed()}
                </div>
                {errorCode === 2 && <p className="errorInfo">Insufficient balance</p>}
                {errorCode === 3 && (
                  <p className="errorInfo">
                    You are required to deposit from {padInfo.minDeposit} {padInfo.paymentToken.symbol}
                  </p>
                )}
              </div>
              <div className="depositBottom">
                <RowBetween>
                  <h3>Burn wNETT</h3>
                </RowBetween>
                <div className="depositBottomCon">
                  <TokenImage address={'0x90fE084F877C65e1b577c7b2eA64B8D8dd1AB278'} />
                  <div className={cx('depositBottomInput', 'noRightBorder')}>
                    <Input
                      fullWidth
                      placeholder="0.0"
                      type="number"
                      disabled
                      value={inputAmount.dividedBy(padInfo.USDPerWNETT || 0).toFixed()}
                    />
                    wNETT
                  </div>
                </div>
              </div>
              <div className="yourNettTip">Your wNETT: {wNettBalance.toFixed()}</div>
              {errorCode === 1 && <InfoLink to={`/launchpad-v2`}>{`Stake NETT to get wNETT`}</InfoLink>}

              <ModalBtn className="approveBtn" disabled={errorCode !== 0 || !inputValue} onClick={handleConfirm}>
                {approved ? 'Deposit' : 'Approve'}
              </ModalBtn>
              <RowBetween align="center" marginTop="16px" style={{ justifyContent: 'center' }}>
                <TYPE.body color="rbga(255,255,255,0.5)" fontSize={12}>
                  * Burning 1 wNETT allows you to deposit {padInfo.USDPerWNETT} {padInfo.paymentToken.symbol}
                </TYPE.body>
              </RowBetween>
            </div>
          </div>
        )}
      </ModalWrapper>
    </Modal>
  )
}
