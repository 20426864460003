/* eslint-disable react-hooks/exhaustive-deps */
import { ChainId } from '@netswap/sdk'
import { NETWORK_CHAIN_ID } from 'connectors'
import getAssetsPool from 'gql/microservices/assets-pools'
import { useActiveWeb3React } from 'hooks'
import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import { setFarmAssetsPool } from '../index'

export default function useGetFarmAssetsPool() {
  const { chainId } = useActiveWeb3React()
  const dispatch = useAppDispatch()
  const { farm } = useAppSelector((state) => state.netswapData)
  async function getPoolsData() {
    const res = await getAssetsPool()
    const cid = chainId || NETWORK_CHAIN_ID
    if (res) {
      dispatch(
        setFarmAssetsPool({
          chainId: cid,
          data: res,
        })
      )
    }
  }

  useEffect(() => {
    getPoolsData()
    let interval = setInterval(() => {
      getPoolsData()
    }, 60000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  return useMemo(() => {
    return farm.assetsPool && farm.assetsPool[(chainId || NETWORK_CHAIN_ID) as ChainId]
      ? farm.assetsPool[(chainId || NETWORK_CHAIN_ID) as ChainId]
      : []
  }, [farm.assetsPool, chainId])
}
