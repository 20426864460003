import ABI from '../abis/erc20.json'
import { ChainId } from '@netswap/sdk'

export const Config = {
  [ChainId.MAINNET]: {
    address: '0x8738E9E9a8263065b07aD460beB66815Dd7B81a5',
    abi: ABI,
  },
  [ChainId.TESTNET]: {
    address: '0x31fb08C0c4d8B147A73F0Be5E0E769721D0956ED',
    abi: ABI,
  },
  // need config
  [ChainId.TESTNET_SEPOLIA]: {
    address: '0x31fb08C0c4d8B147A73F0Be5E0E769721D0956ED',
    abi: ABI,
  },
}
