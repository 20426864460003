import { useCallback } from 'react'
import { BASE_PRICE_TOKEN, USD_PRICE_TOKEN } from '../constants'
import { useActiveWeb3React } from 'hooks'

export interface Params {
  fromToken: string
  toToken: string
}

export default function useGetBasePriceToken() {
  const { chainId } = useActiveWeb3React()

  const getBasePriceToken = useCallback(
    ({ fromToken, toToken }: Params) => {
      if (!chainId) {
        return fromToken
      }

      let baseToken = fromToken
      const basePriceTokenAddresses: string[] = [...BASE_PRICE_TOKEN[chainId].map(token => token.address.toLowerCase())]
      const usdPriceTokenAddresses: string[] = [...USD_PRICE_TOKEN[chainId].map(token => token.address.toLowerCase())]

      if (
        basePriceTokenAddresses.indexOf(toToken.toLowerCase()) > -1 &&
        usdPriceTokenAddresses.indexOf(fromToken.toLowerCase()) === -1
      ) {
        baseToken = toToken
      }

      return baseToken
    },
    [chainId]
  )

  return getBasePriceToken
}
