import { Interface } from '@ethersproject/abi'
import { ChainId } from '@netswap/sdk'
import V1_EXCHANGE_ABI from './v1_exchange.json'
import V1_FACTORY_ABI from './v1_factory.json'

const V1_FACTORY_ADDRESSES: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0x70f51d68D16e8f9e418441280342BD43AC9Dff9f',
  [ChainId.TESTNET]: '0x587e879E48AE1753d44D9F33603141c6AFb87F76',
  [ChainId.TESTNET_SEPOLIA]: '0xd0Ea9fD2B9E6F7be8663f62647F18bac64300697',
}

const V1_FACTORY_INTERFACE = new Interface(V1_FACTORY_ABI)
const V1_EXCHANGE_INTERFACE = new Interface(V1_EXCHANGE_ABI)

export { V1_FACTORY_ADDRESSES, V1_FACTORY_INTERFACE, V1_FACTORY_ABI, V1_EXCHANGE_INTERFACE, V1_EXCHANGE_ABI }
