import React, { useEffect } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import StakeingReward from './stakingReward'
import WNettStaking from './wNett'
import VeNettStaking from './veNett'

const Back = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  cursor: pointer;
  padding-bottom: 16px;
  color: ${({ theme }) => theme.v2.t01};
  font-weight: bold;

  span {
    margin-left: 8px;
    font-size: 20px;
  }
`

const Wrapper = styled.div`
  width: 940px;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100vw - 2rem);
  `}
  padding-bottom: 40px;
`
export default function NettStakingDetail() {
  const history = useHistory()

  useEffect(() => {
    const pageContainer = document.getElementById('pageContainer')
    if (pageContainer) {
      pageContainer.scrollTop = 0
    }
  }, [])

  return (
    <Wrapper>
      <Back
        onClick={() => {
          history.push('/nett-staking')
        }}
      >
        <ArrowBackIosIcon />
        <span>Back to Staking</span>
      </Back>
      <Switch>
        <Route exact path={`/nett-staking-detail/:tab/:id`}>
          <StakeingReward />
        </Route>
        <Route exact path="/nett-staking-detail/wNett">
          <WNettStaking />
        </Route>
        <Route exact path="/nett-staking-detail/veNett">
          <VeNettStaking />
        </Route>
      </Switch>
    </Wrapper>
  )
}
