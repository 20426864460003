import React, { createContext, useContext, useReducer, useMemo, useCallback, useState, useEffect } from 'react'
import { TokenList, TokenInfo } from '@uniswap/token-lists'
import { TimeframeOptions, SUPPORTED_LIST_URLS__NO_ENS } from 'constants/index'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { SyncStatusReq } from '../../gql'
import { useFetchListCallback } from '../useFetchListCallback'
dayjs.extend(utc)

const UPDATE = 'UPDATE'
const UPDATE_TIMEFRAME = 'UPDATE_TIMEFRAME'
const UPDATE_SESSION_START = 'UPDATE_SESSION_START'
const UPDATED_SUPPORTED_TOKENS = 'UPDATED_SUPPORTED_TOKENS'
const UPDATE_LATEST_BLOCK = 'UPDATE_LATEST_BLOCK'
const UPDATE_HEAD_BLOCK = 'UPDATE_HEAD_BLOCK'

const SUPPORTED_TOKENS = 'SUPPORTED_TOKENS'
const TIME_KEY = 'TIME_KEY'
const CURRENCY = 'CURRENCY'
const SESSION_START = 'SESSION_START'
const LATEST_BLOCK = 'LATEST_BLOCK'
const HEAD_BLOCK = 'HEAD_BLOCK'

type ApplicationState = {
  [CURRENCY]: string
  [TIME_KEY]: string
  [SESSION_START]?: number
  [LATEST_BLOCK]?: number
  [HEAD_BLOCK]?: number
  [SUPPORTED_TOKENS]?: {
    [key: string]: TokenInfo & { address: string }
  }
}

const INITIAL_STATE = {
  CURRENCY: 'USD',
  TIME_KEY: TimeframeOptions.ALL_TIME,
}

const ApplicationContext = createContext<[ApplicationState, any]>([INITIAL_STATE, {}])

function useApplicationContext() {
  return useContext(ApplicationContext)
}

function reducer(state: ApplicationState, { type, payload }: { type: any; payload: any }) {
  switch (type) {
    case UPDATE: {
      const { currency } = payload
      return {
        ...state,
        [CURRENCY]: currency,
      }
    }
    case UPDATE_TIMEFRAME: {
      const { newTimeFrame } = payload
      return {
        ...state,
        [TIME_KEY]: newTimeFrame,
      }
    }
    case UPDATE_SESSION_START: {
      const { timestamp } = payload
      return {
        ...state,
        [SESSION_START]: timestamp,
      }
    }

    case UPDATE_LATEST_BLOCK: {
      const { block } = payload
      return {
        ...state,
        [LATEST_BLOCK]: block,
      }
    }

    case UPDATE_HEAD_BLOCK: {
      const { block } = payload
      return {
        ...state,
        [HEAD_BLOCK]: block,
      }
    }

    case UPDATED_SUPPORTED_TOKENS: {
      const { supportedTokens } = payload
      return {
        ...state,
        [SUPPORTED_TOKENS]: supportedTokens,
      }
    }

    default: {
      throw Error(`Unexpected action type in DataContext reducer: '${type}'.`)
    }
  }
}

export default function Provider({ children }: { children: React.ReactChildren | React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)
  const update = useCallback((currency: any) => {
    dispatch({
      type: UPDATE,
      payload: {
        currency,
      },
    })
  }, [])

  // global time window for charts - see timeframe options in constants
  const updateTimeframe = useCallback((newTimeFrame: any) => {
    dispatch({
      type: UPDATE_TIMEFRAME,
      payload: {
        newTimeFrame,
      },
    })
  }, [])

  // used for refresh button
  const updateSessionStart = useCallback((timestamp: any) => {
    dispatch({
      type: UPDATE_SESSION_START,
      payload: {
        timestamp,
      },
    })
  }, [])

  const updateSupportedTokens = useCallback((supportedTokens: any) => {
    dispatch({
      type: UPDATED_SUPPORTED_TOKENS,
      payload: {
        supportedTokens,
      },
    })
  }, [])

  const updateLatestBlock = useCallback((block: any) => {
    dispatch({
      type: UPDATE_LATEST_BLOCK,
      payload: {
        block,
      },
    })
  }, [])

  const updateHeadBlock = useCallback((block: any) => {
    dispatch({
      type: UPDATE_HEAD_BLOCK,
      payload: {
        block,
      },
    })
  }, [])

  return (
    <ApplicationContext.Provider
      value={useMemo(
        () => [
          state,
          {
            update,
            updateSessionStart,
            updateTimeframe,
            updateSupportedTokens,
            updateLatestBlock,
            updateHeadBlock,
          },
        ],
        [state, update, updateTimeframe, updateSessionStart, updateSupportedTokens, updateLatestBlock, updateHeadBlock]
      )}
    >
      {children as any}
    </ApplicationContext.Provider>
  )
}

// export function useLatestBlocks() {
//   const [state, { updateLatestBlock, updateHeadBlock }] = useApplicationContext()

//   const latestBlock = state?.[LATEST_BLOCK]
//   const headBlock = state?.[HEAD_BLOCK]

//   useEffect(() => {
//     async function fetch() {
//       try {
//         const res = await IndexNodeSubgraph().query({
//           query: SUBGRAPH_HEALTH,
//         })
//         const syncedBlock = res.data.indexingStatusForCurrentVersion.chains[0].latestBlock.number
//         const headBlock = res.data.indexingStatusForCurrentVersion.chains[0].chainHeadBlock.number
//         if (syncedBlock && headBlock) {
//           updateLatestBlock(syncedBlock)
//           updateHeadBlock(headBlock)
//         }
//       } catch (e) {
//         console.log(e)
//       }
//     }
//     if (!latestBlock) {
//       fetch()
//     }
//   }, [latestBlock, updateHeadBlock, updateLatestBlock])

//   return [latestBlock, headBlock]
// }

export function useLatestBlocks() {
  const [state, { updateLatestBlock, updateHeadBlock }] = useApplicationContext()

  const latestBlock = state?.[LATEST_BLOCK]
  const headBlock = state?.[HEAD_BLOCK]

  useEffect(() => {
    async function fetch() {
      try {
        const res = await SyncStatusReq();
        const data = res.data;

        console.log(data);
        if (data.code !== 0) {
          throw new Error(data.msg);
        } else {
          const headBlock = parseInt(data.data['chain_head_block'])
          const syncedBlock = parseInt(data.data['latest_block'])
          updateLatestBlock(syncedBlock)
          updateHeadBlock(headBlock)
        }
      } catch (e) {
        console.log(e)
      }
    }
    if (!latestBlock) {
      fetch()
    }
  }, [latestBlock, updateHeadBlock, updateLatestBlock])

  return [latestBlock, headBlock]
}

export function useCurrentCurrency(): [string, () => void] {
  const [state, { update }] = useApplicationContext()
  const toggleCurrency = useCallback(() => {
    if (state[CURRENCY] === 'METIS') {
      update('USD')
    } else {
      update('METIS')
    }
  }, [state, update])
  return [state[CURRENCY], toggleCurrency]
}

export function useTimeframe() {
  const [state, { updateTimeframe }] = useApplicationContext()
  const activeTimeframe = state?.[TIME_KEY]
  return [activeTimeframe, updateTimeframe]
}

export function useStartTimestamp() {
  const [activeWindow] = useTimeframe()
  const [startDateTimestamp, setStartDateTimestamp] = useState<number>(0)

  // monitor the old date fetched
  useEffect(() => {
    let startTime =
      dayjs
        .utc()
        .subtract(
          1,
          activeWindow === TimeframeOptions.WEEK ? 'week' : activeWindow === TimeframeOptions.ALL_TIME ? 'year' : 'year'
        )
        .startOf('day')
        .unix() - 1
    // if we find a new start time less than the current startrtime - update oldest pooint to fetch
    setStartDateTimestamp(startTime)
  }, [activeWindow, startDateTimestamp])

  return startDateTimestamp
}

// keep track of session length for refresh ticker
export function useSessionStart() {
  const [state, { updateSessionStart }] = useApplicationContext()
  const sessionStart = state?.[SESSION_START]

  useEffect(() => {
    if (!sessionStart) {
      updateSessionStart(Date.now())
    }
  })

  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    let interval: any = null
    interval = setInterval(() => {
      setSeconds(Date.now() - (sessionStart ?? Date.now()))
    }, 1000)

    return () => clearInterval(interval)
  }, [seconds, sessionStart])

  return Math.floor(seconds / 1000)
}

export function useListedTokens() {
  const [state, { updateSupportedTokens }] = useApplicationContext()
  const supportedTokens = state?.[SUPPORTED_TOKENS]
  const getTokenList = useFetchListCallback()

  useEffect(() => {
    async function fetchList() {
      const allFetched = await SUPPORTED_LIST_URLS__NO_ENS.reduce(async (fetchedTokens: Promise<TokenInfo[]>, url) => {
        const tokensSoFar: TokenInfo[] = await fetchedTokens
        const newTokens: TokenList = await getTokenList(`${url}?v=1&time=${new Date().getTime()}`)
        return Promise.resolve([...tokensSoFar, ...newTokens.tokens])
      }, Promise.resolve([]))

      let formatted: any = {
        '0xdeaddeaddeaddeaddeaddeaddeaddeaddead0000': {
          chainId: 1088,
          address: '0xdeaddeaddeaddeaddeaddeaddeaddeaddead0000',
          decimals: 18,
          name: 'Metis',
          symbol: 'Metis',
          logoURI:
            'https://raw.githubusercontent.com/MetisProtocol/metis-bridge-resources/master/tokens/METIS/logo.png',
        },
      }
      for (const token of allFetched) {
        formatted[token.address.toLowerCase()] = {
          ...token,
          address: token.address.toLowerCase(),
        }
      }
      updateSupportedTokens(formatted)
    }
    if (!supportedTokens) {
      fetchList()
    }
  }, [updateSupportedTokens, supportedTokens, getTokenList])

  return supportedTokens
}

export function useExistedTokens() {
  const [state] = useApplicationContext()
  const supportedTokens = state?.[SUPPORTED_TOKENS]

  return supportedTokens
}
