import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Text } from 'rebass'
import { AlertTriangle } from 'react-feather'
import { RowBetween, RowFixed } from 'components/Row'
import { ButtonDark } from '../ButtonStyled'
import { AutoColumn } from 'components/Column'
import { Hover } from '..'
import Link from '../Link'
import { useMedia } from 'react-use'
import { EXPLORE_URL } from 'constants/index'

const WarningWrapper = styled.div<{ show?: boolean }>`
  border-radius: 20px;
  border: 1px solid #f82d3a;
  background: rgba(248, 45, 58, 0.05);
  padding: 1rem;
  color: #f82d3a;
  display: ${({ show }) => !show && 'none'};
  margin: 0 2rem 2rem 2rem;
  position: relative;

  @media screen and (max-width: 800px) {
    width: 80% !important;
    margin-left: 5%;
  }
`

const StyledWarningIcon = styled(AlertTriangle)`
  min-height: 20px;
  min-width: 20px;
  stroke: red;
`

export default function Warning({
  type,
  show,
  setShow,
  address
}: {
  type: string
  show: boolean
  setShow(val: boolean): void
  address: string
}) {
  const { t } = useTranslation()
  const below800 = useMedia('(max-width: 800px)')
  const SCAN_URL = EXPLORE_URL

  const textContent = below800 ? (
    <div>
      <Text fontWeight={500} lineHeight={'145.23%'} mt={'10px'}>
        {t('analytics.warning_page.warn1')}
      </Text>
      <Text fontWeight={500} lineHeight={'145.23%'} mt={'10px'}>
        {t('analytics.warning_page.warn2')}
      </Text>
    </div>
  ) : (
    <Text fontWeight={500} lineHeight={'145.23%'} mt={'10px'}>
      {t('analytics.warning_page.warn3')}
    </Text>
  )

  return (
    <WarningWrapper show={show}>
      <AutoColumn gap="4px">
        <RowFixed>
          <StyledWarningIcon />
          <Text fontWeight={600} lineHeight={'145.23%'} ml={'10px'}>
            {t('analytics.alert')}
          </Text>
        </RowFixed>
        {textContent}
        {below800 ? (
          <div>
            <Hover style={{ marginTop: '10px' }}>
              <Link href={`${SCAN_URL}/address/${address}`} target="_blank">
                {t('analytics.view_contract_on_metis_scan', { address: type === 'token' ? 'token' : 'pair' })}
              </Link>
            </Hover>
            <RowBetween style={{ marginTop: '20px' }}>
              <div />
              <ButtonDark color={'#f82d3a'} style={{ minWidth: '140px' }} onClick={() => setShow(false)}>
                {t('analytics.understand')}
              </ButtonDark>
            </RowBetween>
          </div>
        ) : (
          <RowBetween style={{ marginTop: '10px' }}>
            <Hover>
              <Link href={`${SCAN_URL}/address/${address}`} target="_blank">
                {t('analytics.view_contract_on_metis_scan', { address: type === 'token' ? 'token' : 'pair' })}
              </Link>
            </Hover>
            <ButtonDark color={'#f82d3a'} style={{ minWidth: '140px' }} onClick={() => setShow(false)}>
              {t('analytics.understand')}
            </ButtonDark>
          </RowBetween>
        )}
      </AutoColumn>
    </WarningWrapper>
  )
}
