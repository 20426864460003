/* eslint-disable react-hooks/exhaustive-deps */
import getTokens from 'gql/subgraph/tokens'
import { useActiveWeb3React } from 'hooks'
import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import { setTokenPrice, updateFetchTokenList } from '../index'
import { Config as NettConfig } from '../../../constants/tokens/nett'
import { ChainId } from '@netswap/sdk'
import { METIS_TOKEN } from '../../../constants'
import { isAddress } from 'utils'
export default function useTokenPrice() {
  const { chainId } = useActiveWeb3React()
  const dispatch = useAppDispatch()
  const { fetchTokenList } = useAppSelector((state) => state.netswapData)

  const defaultToken = useMemo(() => {
    const res = []
    if (chainId && [ChainId.MAINNET, ChainId.TESTNET].indexOf(chainId) >= 0) {
      const nettAddress = NettConfig[chainId as ChainId].address.toLowerCase()
      res.push(nettAddress)
    }
    return res
  }, [chainId])

  async function getTokenInfo() {
    const ids = Array.from(new Set(fetchTokenList.concat(defaultToken))).map((item) => item.toLowerCase())
    if (ids.length === 0) return
    const res = await getTokens(ids)

    if (res) {
      dispatch(setTokenPrice(res))
    }
  }

  useEffect(() => {
    let interval: any
    getTokenInfo()
    interval = setInterval(() => {
      getTokenInfo()
    }, 60000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [fetchTokenList, defaultToken])
}

export function useFormatSymbolTokenPrice() {
  const { chainId } = useActiveWeb3React()
  const { tokenPrice } = useAppSelector((state) => state.netswapData)

  const nettAddress = useMemo(() => {
    if (chainId && [ChainId.MAINNET, ChainId.TESTNET].indexOf(chainId) >= 0) {
      return NettConfig[chainId as ChainId].address.toLowerCase()
    }
    return undefined
  }, [chainId])

  return useMemo(() => {
    let res = {
      ...tokenPrice,
    }
    if (nettAddress && tokenPrice[nettAddress]) {
      res['nett'] = tokenPrice[nettAddress]
    }

    return res
  }, [tokenPrice, nettAddress])
}

export function useGetTokenPrice(address: string) {
  const { tokenPrice } = useAppSelector((state) => state.netswapData)
  const dispatch = useAppDispatch()

  const addr = useMemo(() => {
    let addr = ''
    if (address === 'METIS') {
      addr = METIS_TOKEN
    } else if (isAddress(address)) {
      addr = address
    }

    return addr
  }, [address])

  useEffect(() => {
    if (addr && !tokenPrice[addr]) {
      dispatch(
        updateFetchTokenList({
          type: 'add',
          value: [addr.toLowerCase()],
        })
      )
    }
  }, [addr])

  return useMemo(() => {
    return addr ? tokenPrice[addr.toLowerCase()] : undefined
  }, [tokenPrice, addr])
}

export function useGetTokensPrice(tokenAddress: string[]) {
  const { tokenPrice } = useAppSelector((state) => state.netswapData)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (tokenAddress.length === 1 && tokenPrice[tokenAddress[0]]) return
    dispatch(
      updateFetchTokenList({
        type: 'add',
        value: tokenAddress.map((item) => item.toLowerCase()),
      })
    )
  }, [tokenAddress])

  return tokenPrice
}
