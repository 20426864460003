// import getWeb3 from 'utils/web3'
import { useActiveWeb3React } from 'hooks'
import { Contract } from 'ethers'

function decodeErrorString(error: any) {
  let errorMessage = ''
  error = new Error(error).message
  if (error) {
    const reg = new RegExp('Error: Internal JSON-RPC error.')
    if (reg.test(error)) {
      const message = error.replace(reg, '')

      const errorData = JSON.parse(message)

      if (errorData && typeof errorData === 'object' && errorData.message) {
        errorMessage = errorData.message
      }
    } else {
      errorMessage = error
    }
  }
  return errorMessage
}

export function useGasEstimate() {
  const { account } = useActiveWeb3React()

  function gasEstimate(
    promise: any
  ): Promise<{
    success: boolean
    errorMessage?: string
  }> {
    // const web3 = getWeb3()
    return new Promise(async (resolve, reject) => {
      // let gasPrice = 1000000000
      // if (web3) {
      //   gasPrice = await web3.eth.getGasPrice()
      // }
      if (promise) {
        promise.estimateGas(
          {
            from: account
          },
          function(error: any, gasAmount: any) {
            if (error) {
              const errorMessage = decodeErrorString(error)
              return resolve({
                success: false,
                errorMessage
              })
            } else {
              resolve({
                success: true
              })
            }
          }
        )
      }
    })
  }

  return gasEstimate
}

export function useEtherGasEstimate() {
  async function gasEstimate(
    contract: Contract,
    method: string,
    params: any[]
  ): Promise<{
    success: boolean
    errorMessage?: string
  }> {
    try {
      // let gasPrice = 1000000000
      // if (library?.provider) {
      //   gasPrice = await (library.provider as any).getGasPrice()
      //   console.log(gasPrice)
      // }
      const estimation = await contract.estimateGas[method](...params)
      console.log('contract', contract)
      console.log('method', method)
      console.log('params', params)
      console.log('estimation', estimation.toString())
      return {
        success: true
      }
    } catch (e) {
      // const errorMessage = decodeErrorString(e)
      const errorMessage = (e as any)?.data?.message || (e as any)?.message
      return {
        success: false,
        errorMessage
      }
    }
  }

  return gasEstimate
}
