import { transparentize } from 'polished'
import React, { useMemo } from 'react'
import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
  css,
  DefaultTheme,
} from 'styled-components'
import { useIsDarkMode } from '../state/user/hooks'
import { Text, TextProps } from 'rebass'
import { Colors } from './styled'

export * from './components'

const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
  upToMoreLarge: 1440,
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {}
) as any

const white = '#FFFFFF'
const black = '#000000'

export function colors(darkMode: boolean): Colors {
  return {
    // base
    white,
    black,

    // text
    text1: darkMode ? '#FFFFFF' : '#000000',
    text2: darkMode ? '#C3C5CB' : '#565A69',
    text3: darkMode ? '#6C7284' : '#888D9B',
    text4: darkMode ? '#565A69' : '#C3C5CB',
    text5: darkMode ? '#2C2F36' : '#EDEEF2',
    text6: darkMode ? '#C42A61' : '#C42A61',

    // backgrounds / greys
    bg1: darkMode ? '#0D1F32' : '#FFFFFF',
    bg2: darkMode ? '#2C2F36' : '#F7F8FA',
    bg3: darkMode ? '#40444F' : '#EDEEF2',
    bg4: darkMode ? '#565A69' : '#CED0D9',
    bg5: darkMode ? '#6C7284' : '#888D9B',
    bg6: darkMode ? '#170627' : '#170627',
    bg7: darkMode ? '#1f0835' : '#1f0835',

    //specialty colors
    modalBG: darkMode ? 'rgba(0,0,0,.425)' : 'rgba(0,0,0,0.3)',
    advancedBG: darkMode ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.6)',

    //primary colors
    primary1: darkMode ? '#2172E5' : '#ff007a',
    primary2: darkMode ? '#3680E7' : '#FF8CC3',
    primary3: darkMode ? '#4D8FEA' : '#FF99C9',
    primary4: darkMode ? '#376bad70' : '#F6DDE8',
    primary5: darkMode ? '#153d6f70' : '#FDEAF1',

    // color text
    primaryText1: darkMode ? '#6da8ff' : '#ff007a',

    // secondary colors
    secondary1: darkMode ? '#2172E5' : '#ff007a',
    secondary2: darkMode ? '#17000b26' : '#F6DDE8',
    secondary3: darkMode ? '#17000b26' : '#FDEAF1',

    // other
    red1: '#FD4040',
    red2: '#F82D3A',
    red3: '#D60000',
    green1: '#27AE60',
    yellow1: '#FFE270',
    yellow2: '#F3841E',
    yellow3: '#FF784F',
    blue1: '#2172E5',
    blue2: '#3D9EFF',

    // dont wanna forget these blue yet
    // blue4: darkMode ? '#153d6f70' : '#C4D9F8',
    // blue5: darkMode ? '#153d6f70' : '#EBF4FF',
  }
}

export function v2Color(darkMode: boolean) {
  return {
    // header
    // bg1: darkMode ? '#14163B' : '#FFFFFF',
    // bg2: darkMode ? '#2D2D4E' : '#FFFFFF',
    // bg3: darkMode
    //   ? 'linear-gradient(90deg, #76AAFF 0%, rgba(82, 79, 148, 0) 100%)'
    //   : 'linear-gradient(90deg, #76AAFF 0%, rgba(82, 79, 148, 0) 100%)',
    // bg4: darkMode ? '#1B1B30' : '#F1F1F3',
    // bg5: darkMode ? '#313144' : 'rgba(49, 49, 68, 0.1)',
    // bg6: darkMode ? '#1B1B30' : 'rgba(49, 49, 68, 0.1)',
    // bg7: darkMode ? '#020423' : '#473ebc',
    // bg8: darkMode ? '#05071e' : '#f1f1f3',
    // bg9: darkMode ? '#05071e' : '#e5faff',
    // modalBg: darkMode ? '#2D2D4E' : '#5F7AFF',
    // headerBg: darkMode ? '#14163b' : '#ffffff',
    // mainText: darkMode ? '#fff' : '#020423',
    // descText: darkMode ? '#C0C0C0' : '#68697C',
    // inputDefaultText: darkMode ? '#C0C0C0' : '#68697C',
    // text2: darkMode ? '#fff' : '#000',
    // text3: darkMode ? '#00cfff' : '#2d2d4e',
    // content: darkMode ? '#020423' : '#F3F5FF',

    // background color
    bg01: darkMode ? '#020423' : '#f3f5ff',
    bg02: darkMode ? '#2d2d4e' : '#ffffff',
    bg03: darkMode
      ? 'linear-gradient(90deg, #76AAFF 0%, rgba(82, 79, 148, 0) 100%)'
      : 'linear-gradient(90deg, #76AAFF 0%, rgba(82, 79, 148, 0) 100%)',
    bg04: darkMode ? '#1B1B30' : '#F1F1F3',
    bg05: darkMode ? '#313144' : 'rgba(49, 49, 68, 0.1)',
    bg06: darkMode ? '#1B1B30' : 'rgba(49, 49, 68, 0.1)',
    bg07: darkMode ? '#020423' : '#473ebc',
    bg08: darkMode ? '#05071e' : '#f1f1f3',
    bg09: darkMode ? '#05071e' : '#e5faff',
    bg10: darkMode ? '#3f426b' : '#f3f5ff',
    bg11: darkMode ? '#16687b' : '#5548F6',

    modalBg: darkMode ? '#2D2D4E' : '#ffffff',
    headerBg: darkMode ? '#14163b' : '#ffffff',

    // border color
    df01: darkMode ? '#2d2d4e' : '#ffffff',
    df02: darkMode ? '#1b1b30' : '#f1f1f3',

    // button color
    btn01: '#00cfff',
    btn02: '#2d2d4e',
    btn03: '#42ffbc',
    btn04: '#ff6969',

    // text color
    t01: darkMode ? '#ffffff' : '#020423',
    t02: darkMode ? '#c0c0c0' : '#68697c',
    t03: darkMode ? '#00cfff' : '#2d2d4e',
    t04: darkMode ? 'rgba(255, 255, 255, 0.4)' : 'rgba(2, 4, 35, 0.4)',
    t05: '#5f7aff',
    t06: 'linear-gradient(234deg, #00cfff 0%, #34C1FF 53%, #904BF9 100%)',
    t07: '#ff6969',
    t08: darkMode ? '#42ffbc' : '#04c91d',
    t09: '#00cfff',
    t10: darkMode ? '#00cfff' : '#5347FF',
    t11: '#42ffbc',
  }
}

export function theme(darkMode: boolean): DefaultTheme {
  return {
    isDark: darkMode,

    ...colors(darkMode),

    v2: {
      ...v2Color(darkMode),
    },

    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    //shadows
    shadow1: darkMode ? '#000' : '#2F80ED',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const darkMode = useIsDarkMode()

  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`
const TextV2Wrapper = styled(Text)<{ color: keyof ReturnType<typeof v2Color> }>`
  color: ${({ color, theme }) => (theme.v2 as any)[color]};
`

export const TYPE = {
  main(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'t01'} {...props} />
  },
  link(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  black(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text1'} {...props} />
  },
  white(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'white'} {...props} />
  },
  body(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={16} color={'text1'} {...props} />
  },
  largeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />
  },
  mediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} {...props} />
  },
  subHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} {...props} />
  },
  small(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={11} {...props} />
  },
  blue(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'blue1'} {...props} />
  },
  yellow(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'yellow1'} {...props} />
  },
  darkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text3'} {...props} />
  },
  gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg3'} {...props} />
  },
  italic(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />
  },
  error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />
  },
  pink(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text6'} {...props} />
  },
  v2Main(props: TextProps) {
    return <TextV2Wrapper fontWeight={500} fontSize={14} color={'t01'} fontFamily={'Poppins-Regular'} {...props} />
  },

  desc(props: TextProps) {
    return <TextV2Wrapper fontWeight={400} color={'t02'} fontFamily={'Poppins-Regular'} {...props} />
  },

  mainLg(props: TextProps) {
    return <TextV2Wrapper fontWeight={500} fontFamily={'Poppins-Medium'} {...props} />
  },

  title(props: TextProps) {
    return <TextV2Wrapper fontWeight={600} fontFamily={'Poppins-Bold'} {...props} />
  },
}

export const FixedGlobalStyle = createGlobalStyle`
* {
::-webkit-scrollbar {
    width: 8px;
    height: 100%;
    background-color: #fff;
    border-radius: 8px;
}
::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 8px;
}
}
html, input, textarea, button {
  font-family: 'Inter', sans-serif;
  font-display: fallback;
}
@supports (font-variation-settings: normal) {
  html, input, textarea, button {
    font-family: 'Inter var', sans-serif;
  }
}

html,
body {
  margin: 0;
  padding: 0;
}

 a {
   color: ${colors(false).blue1}; 
 }

* {
  box-sizing: border-box;
}

button {
  user-select: none;
}

html {
  font-size: 16px;
  font-variant: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-feature-settings: 'ss01' on, 'ss02' on, 'cv01' on, 'cv03' on;
  
}
`

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg6};
}

body {
  min-height: 100vh;
  background-position: 0 -30vh;
  background-repeat: no-repeat;
  font-family: 'Poppins-Regular';
  background-image: ${({ theme }) =>
    `radial-gradient(50% 50% at 50% 50%, ${transparentize(0.9, theme.primary1)} 0%, ${transparentize(
      1,
      theme.bg1
    )} 100%)`};
}
.MuiTooltip-tooltip {
    background-color: #5f7aff !important;
    border-radius: 4px !important;
    padding: 8px !important;
    font-size: 14px !important;
  }

  .MuiTooltip-arrow {
    color: #5f7aff !important;
  }

  @media (min-width: 1280px) {
    .MuiContainer-maxWidthLg {
      max-width: 1440px !important;
    }
  }

`
