/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react'
import CountUp from 'react-countup'

interface ValueProps {
  value: string | number
  decimals?: number
  defaultZero?: string
}

export default function Value({ value, decimals }: ValueProps) {
  const [start, updateStart] = useState(0)
  const [end, updateEnd] = useState(0)

  useEffect(() => {
    if (typeof value === 'number') {
      updateStart(end)
      updateEnd(value)
    }
  }, [value])

  return (
    <>
      {typeof value == 'string' ? (
        value
      ) : (
        <CountUp
          start={start}
          end={end}
          decimals={decimals !== undefined ? decimals : end < 0 ? 4 : end > 1e5 ? 0 : 3}
          duration={1}
          separator=","
        />
      )}
    </>
  )
}

export function ValueFormat({ value, decimals = 2 }: { value: number; decimals?: number }) {
  const showValue = useMemo(() => {
    const sizes = [
      { num: 1, symbol: '' },
      { num: 1e3, symbol: 'K' },
      { num: 1e6, symbol: 'M' },
      { num: 1e9, symbol: 'B' }
    ]
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    let i
    for (i = sizes.length - 1; i > 0; i--) {
      if (value >= sizes[i].num) {
        break
      }
    }
    return ((value ? value : 0) / sizes[i].num).toFixed(decimals).replace(rx, '$1') + sizes[i].symbol
  }, [value])
  return <>{showValue}</>
}
