import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDarkModeManager } from 'state/user/hooks'
import { useActiveWeb3React } from 'hooks'
import { useWalletModalToggle } from 'state/application/hooks'
import { useHistory } from 'react-router-dom'

import BannerBgDark from 'assets/images/landing_page/banner_bg_dark.png'
import BannerBgLight from 'assets/images/landing_page/banner_bg_light.png'
import BannerBgMobileDark from 'assets/images/landing_page/banner_bg.mobile.dark.png'
import BannerBgMobileLight from 'assets/images/landing_page/banner_bg.mobile.light.png'
import BannerNeboDark from 'assets/images/landing_page/banner_nebo_dark.png'
import BannerNeboLight from 'assets/images/landing_page/banner_nebo_light.png'

import Container from '@material-ui/core/Container'
import { TYPE } from 'theme'
import { ButtonV2 } from 'components/Button'

const Wrapper = styled.div`
  width: 100%;
  background-image: url(${({ theme }) => (theme.isDark ? BannerBgDark : BannerBgLight)});
  height: 0;
  padding-bottom: 49.5%;
  position: relative;
  background-repeat: no-repeat;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    background-image: url(${({ theme }) => (theme.isDark ? BannerBgMobileDark : BannerBgMobileLight)});
    padding-bottom: 0;
    height: 222px;
  `}
`

const Nebo = styled.img`
  position: absolute;
  right: 13.6%;
  bottom: 11.6%;
  height: 74%;
  width: auto;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    right: 20px;
    bottom: 66px;
  `}
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  algin-items: flex-start;
  width: 600px;
  padding: 13.5% 0 0 0;

  ${({ theme }) => theme.mediaWidth.upToMoreLarge`
    padding: 13.5% 20px 0 20px;
    width: 50%;
  `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0;
    width: 100%;
    height: 222px;
    justify-content: center;
    padding: 0 8px;
  `}
`

const Title = styled(TYPE.title)`
  font-size: 60px;
  line-height: 1;
  margin-bottom: 2px !important;
  color: #ffffff !important;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 28px;
  `}
`

const SubTitle = styled(TYPE.title)`
  font-size: 42px;
  line-height: 1.1;
  color: #ffffff !important;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `}
`

const Desctiption = styled(TYPE.mainLg)`
  margin-top: 20px !important;
  font-size: 20px;
  line-height: 1.6;
  color: #ffffff !important;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `}
`

const BtnGroup = styled.div`
  display: flex;
  align-items: center;
  margin-top: 56px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 18px;
  `}
`

const ConnectBtn = styled(ButtonV2)`
  width: 188px;
  height: 48px;
  box-shadow: 0px 4px 0px 0px #171717;
  border-radius: 16px;
  margin-right: 28px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
    height: 40px;
  `}
`

const TradeBtn = styled(ButtonV2)`
  width: 188px;
  height: 48px;
  background-color: ${({ theme }) => (theme.isDark ? 'rgba(0, 0, 0, 0.28)' : '#ffffff')};
  box-shadow: 0px 4px 0px 0px #171717;
  border-radius: 16px;
  border: 1px solid #00cfff;
  color: ${({ theme }) => (theme.isDark ? '#00cfff' : '#020423')};

  &:hover {
    background-color: ${({ theme }) => (theme.isDark ? 'rgba(0, 0, 0, 0.4)' : '#f1f1f3')};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
    height: 40px;
  `}
`

export default function Banner() {
  const [isDark] = useDarkModeManager()
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const history = useHistory()
  return (
    <Wrapper>
      <Nebo src={isDark ? BannerNeboDark : BannerNeboLight} />

      <Container maxWidth="lg">
        <ContentWrapper>
          <Title>Netswap</Title>

          <SubTitle>{t('home.subtitle')}</SubTitle>

          <Desctiption>{t('home.description')}</Desctiption>

          <BtnGroup>
            {!account && <ConnectBtn onClick={toggleWalletModal}>{t('wallet_btn.connect_to')}</ConnectBtn>}

            <TradeBtn
              onClick={() => {
                history.push('/swap')
              }}
            >
              {t('home.trade_now')}
            </TradeBtn>
          </BtnGroup>
        </ContentWrapper>
      </Container>
    </Wrapper>
  )
}
