import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Row from 'components/Row'
import BannerBgDarkSrc from 'assets/images/trade_competition/banner_bg.dark.png'
import BannerBgLightSrc from 'assets/images/trade_competition/banner_bg.light.png'
import PhaseTagBgSrc from 'assets/images/trade_competition/phase_tag_bg.png'
import NeboWithCoinDarkSrc from 'assets/images/trade_competition/nebo_with_coins.dark.png'
import NeboWithCoinLightSrc from 'assets/images/trade_competition/nebo_with_coins.light.png'
import { RoundInfo } from 'gql/microservices/trade-competition'
import { useDarkModeManager } from 'state/user/hooks'
import { TYPE, theme } from 'theme'

const Wrapper = styled.div`
  background-image: url(${({ theme }) => (theme.isDark ? BannerBgDarkSrc : BannerBgLightSrc)});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 276px;
  position: relative;
  border-radius: 8px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 72px 398px 72px 40px;

  .banner-title {
    font-size: 32px;
  }

  .banner-subtitle {
    font-size: 40px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 60px 16px 24px;
    height: auto;

    .banner-title {
      font-size: 24px;
    }

    .banner-subtitle {
      font-size: 32px;
    }
  `}
`

const NeboImg = styled.img`
  width: 394px;
  height: auto;
  position: absolute;
  right: 0;
  bottom: 0;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 140px;
    bottom: auto;
    top: -50px;
  `}
`

const PhaseBox = styled.div`
  height: 60px;
  width: 180px;
  background-image: url(${PhaseTagBgSrc});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 40px;

  .phase-title {
    font-size: 14px;
    line-height: 1.3;
  }

  .phase-round {
    font-size: 20px;
    line-height: 1.1;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 120px;
    height: 40px;

    .phase-title {
      font-size: 12px;
    }

    .phase-round {
      font-size: 16px;
    }
  `}
`

const RewardAmount = styled.span`
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  background: linear-gradient(234deg, #00cfff 0%, #34c1ff 53%, #904bf9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  font-size: 50px;
  margin-right: 8px;
  font-family: 'Poppins-Bold';
  font-weight: bold;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 30px;
  `}
`

export interface Props {
  round?: RoundInfo
}

export default function Banner({ round }: Props) {
  const { t } = useTranslation()
  const [darkMode] = useDarkModeManager()
  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  return (
    <Wrapper>
      {round && (
        <PhaseBox>
          <TYPE.title style={{ color: '#ffffff' }}>Phase {round.round}</TYPE.title>

          <TYPE.title style={{ textTransform: 'uppercase', color: '#ffffff' }}>
            {t(`round_status.${round.status}`)}
          </TYPE.title>
        </PhaseBox>
      )}

      <NeboImg src={themeObject.isDark ? NeboWithCoinDarkSrc : NeboWithCoinLightSrc} alt="" />

      <TYPE.title className="banner-title" style={{ color: '#ffffff' }}>
        {t('trade_competition.title')}
      </TYPE.title>

      <Row style={{ alignItems: 'center' }}>
        <RewardAmount>$100,000</RewardAmount>
        <TYPE.title className="banner-subtitle" style={{ color: '#ffffff' }}>
          IN PRIZES!
        </TYPE.title>
      </Row>

      <TYPE.mainLg className="banner-des" style={{ color: '#ffffff' }}>
        {t('trade_competition.description')}
      </TYPE.mainLg>
    </Wrapper>
  )
}
