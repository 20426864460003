import React, { useEffect } from 'react'
import { useIsDarkMode } from 'state/user/hooks'
import styled, { createGlobalStyle } from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 600px;
  height: 800px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  `};
`

const GlobalStyled = createGlobalStyle`
    #pageContainer {
        background-color: ${({ theme }) => (theme.isDark ? '#2d2d4e' : '#f3f5ff')};;
    }
`
export default function Bridge() {
  const darkMode = useIsDarkMode()

  useEffect(() => {
    const wrapper = document.getElementById('debridgeWidget')
    if (wrapper) {
      wrapper.innerHTML = ''
    }
    ;(window as any).deBridge.widget({
      v: '1',
      element: 'debridgeWidget',
      title: '',
      description: '',
      width: '600',
      height: '800',
      r: 28094,
      supportedChains:
        '{"inputChains":{"1":"all","10":"all","56":"all","100":"all","137":"all","1088":"all","1890":"all","8453":"all","42161":"all","43114":"all","59144":"all","7565164":"all","245022934":"all"},"outputChains":{"1":"all","10":"all","56":"all","100":"all","137":"all","1088":"all","1890":"all","8453":"all","42161":"all","43114":"all","59144":"all","7565164":"all","245022934":"all"}}',
      inputChain: 56,
      outputChain: 1,
      inputCurrency: '',
      outputCurrency: '',
      address: '',
      showSwapTransfer: true,
      amount: '',
      outputAmount: '',
      isAmountFromNotModifiable: false,
      isAmountToNotModifiable: false,
      lang: 'en',
      mode: 'deswap',
      isEnableCalldata: false,
      styles: darkMode
        ? 'eyJhcHBCYWNrZ3JvdW5kIjoiIzJkMmQ0ZSIsImJvcmRlclJhZGl1cyI6OCwiaWNvbkNvbG9yIjoiIzAwY2ZmZiIsImZvbnRGYW1pbHkiOiIiLCJwcmltYXJ5QnRuQmciOiIjMDBjZmZmIiwicHJpbWFyeUJ0blRleHQiOiIjMDAwMDAwIiwic2Vjb25kYXJ5QnRuQmciOiIiLCJsaWdodEJ0bkJnIjoiIiwiaXNOb1BhZGRpbmdGb3JtIjpmYWxzZSwiYnRuUGFkZGluZyI6eyJ0b3AiOm51bGwsInJpZ2h0IjpudWxsLCJib3R0b20iOm51bGwsImxlZnQiOm51bGx9LCJidG5Gb250U2l6ZSI6bnVsbCwiYnRuRm9udFdlaWdodCI6bnVsbH0='
        : 'eyJhcHBCYWNrZ3JvdW5kIjoiI2YzZjVmZiIsImJvcmRlclJhZGl1cyI6OCwiaWNvbkNvbG9yIjoiIzAwY2ZmZiIsImZvbnRGYW1pbHkiOiIiLCJwcmltYXJ5QnRuQmciOiIjMDBjZmZmIiwibGlnaHRCdG5CZyI6IiIsImlzTm9QYWRkaW5nRm9ybSI6ZmFsc2UsImJ0blBhZGRpbmciOnsidG9wIjpudWxsLCJyaWdodCI6bnVsbCwiYm90dG9tIjpudWxsLCJsZWZ0IjpudWxsfSwiYnRuRm9udFNpemUiOm51bGwsImJ0bkZvbnRXZWlnaHQiOm51bGx9',
      theme: darkMode ? 'dark' : 'light',
      isHideLogo: false,
      logo: '',
    })
  }, [darkMode])
  return (
    <>
      <GlobalStyled />
      <Wrapper id="debridgeWidget"></Wrapper>
    </>
  )
}
