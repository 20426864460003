import { AutoColumn } from 'components/Column'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { TYPE } from 'theme'
import { Box } from 'rebass/styled-components'
import { RowBetween } from 'components/Row'
import { useHistory } from 'react-router-dom'
import { useFormatSymbolTokenPrice } from 'state/data/hooks/useTokenPrice'
import useGetOverviewData from 'state/data/hooks/useGetOverviewData'
import Numeral from 'numeral'
import BigNumber from 'bignumber.js'
import useGetWNettInfo from './hooks/useGetwNettInfo'
import { useActiveWeb3React } from 'hooks'
import { useWalletModalToggle } from 'state/application/hooks'
import useGetVeNettInfo from './hooks/useGetveNettInfo'
import useGetStakingRewardsInfo from './hooks/useGetStakingRewardInfo'
import { useGetNETTCirculationAndMAxSupply } from 'state/data/hooks/useGetNettTokenInfo'
import TooltipInfo from 'components/TooltipInfo'

const StakeBox = styled(Box)`
  width: 940px;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100vw - 2rem);
  `}
`

const StakeWrapper = styled.div`
  max-width: 940px;
  width: 100%;
  border-radius: 16px;
  margin: 0 auto;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `}

  section {
    margin-bottom: 32px;
  }
  h2,
  p {
    color: ${({ theme }) => theme.text1};
    margin: 0;
    text-indent: 20px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      text-indent: 0;
    `};
  }
  h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 14px;
  }
  p {
    font-size: 14px;
    line-height: 20px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const TitleIcon = styled.img`
  width: 110px;
  height: 102px;
  margin-right: 48px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
       width: 70px;
    height: 64px;
    `};
`

// const TabWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   margin: 0 auto;
//   margin-top: 12px;
//   justify-content: center;
//   background-color: ${({ theme }) => theme.v2.bg06};
//   width: 200px;

//   /* height: 30px; */
//   border-radius: 8px;
//   box-sizing: border-box;
//   padding: 8px 12px;
//   background-color: ${({ theme }) => theme.v2.bg02};
// `

// const Tab = styled.div`
//   width: 50%;
//   border-radius: 8px;
//   background-color: transparent;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: ${({ theme }) => theme.v2.t01};
//   font-size: 14px;

//   height: 30px;
//   cursor: pointer;

//   font-weight: 600;
//   &.active {
//     background-color: #d2cfd5;
//     color: #000;
//   }
// `

const TVLWrapper = styled.div`
  width: 100%;

  border: 1px solid ${({ theme }) => theme.v2.t03};
  border-radius: 14px;

  /* height: 120px; */
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  box-sizing: border-box;
  padding: 20px;
  max-width: calc(100vw - 3rem);

  ${({ theme }) => theme.mediaWidth.upToSmall`
  flex-direction: column;
  align-items: center;
  gap: 20px;
  `}
`

const CardWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 40px;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  flex-direction: column;
  align-items: center;
  `}
`

const Card = styled.div`
  width: 300px;
  height: 270px;
  border-radius: 14px;

  background: ${({ theme }) => theme.v2.bg02};
  box-sizing: border-box;
  padding: 12px;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-top: 2rem;
  width: calc(100vw - 3rem)
  `}
`

const CardHeaderInfo = styled.div`
  width: 100%;
  background: ${({ theme }) => `linear-gradient(180deg, ${theme.v2.bg04} 0%, ${theme.v2.bg02} 100%);`};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 20px 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  justify-content: flex-start;
  padding: 1rem
  `}
`

const CardImg = styled.img`
  width: auto;
  height: 48px;
  margin-right: 16px;
`

const CardTag = styled.div`
  border-radius: 4px;
  color: ${({ theme }) => theme.v2.t01};

  font-size: 10px;
  padding: 4px 8px;
  background: ${({ theme }) => theme.v2.bg04};
  width: fit-content;
`

const CardInfo = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 8px;
  margin-top: 24px;
`

const CardInfoRow = styled(RowBetween)`
  align-items: flex-start;
  > div {
    width: 50%;
    text-align: center;
  }
`

const StyledButton = styled.div`
  width: 120px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.v2.t09};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.v2.t09};

  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 10px;
  `}
`

export default function NettStakingV2() {
  const { account } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  //   const [viewUSD, setViewUSD] = useState(true)
  const history = useHistory()
  const { overview } = useGetOverviewData()
  const prices = useFormatSymbolTokenPrice()
  const {
    highestAprPool,
    totalStakeUSD: stakingTotalStakUSD,
    totalUserStakeUSD: stakingTotalUserStakeUSD,
    pendingRewardsUSD: stakingPendingRewardsUSD
  } = useGetStakingRewardsInfo()
  const {
    totalStakedUSD: totalStakedUSDForWNETT,
    youStakedUSD: yourStakedUSDForWNETT,
    totalSupply: wNETTTotalSupply
  } = useGetWNettInfo()

  const {
    totalStakedUSD: totalStakedUSDForVeNETT,
    youStakedUSD: yourStakedUSDForVeNETT,
    totalSupply: veNETTTotalSupply
  } = useGetVeNettInfo()

  const nettPrice = prices?.nett?.price

  const nettStakeAmount = useMemo(() => {
    return overview?.stakeTotalTvl ? new BigNumber(overview.stakeTotalTvl).dividedBy(nettPrice).toNumber() : 0
  }, [overview, nettPrice])

  const { nettInCirculation } = useGetNETTCirculationAndMAxSupply()

  return (
    <StakeBox>
      <StakeWrapper>
        <TitleWrapper>
          <TitleIcon src={require('assets/images/stake/banner.png')} />
          <AutoColumn gap="md">
            <TYPE.v2Main fontWeight="bold" fontSize={32}>
              NETT Stake
            </TYPE.v2Main>
            <TYPE.desc fontSize={14}>Earn more with staking your NETT tokens</TYPE.desc>
          </AutoColumn>
        </TitleWrapper>
        {/* <TabWrapper>
          <Tab className={viewUSD ? 'active' : ''} onClick={() => setViewUSD(true)}>
            USD
          </Tab>
          <Tab className={!viewUSD ? 'active' : ''} onClick={() => setViewUSD(false)}>
            NETT
          </Tab>
        </TabWrapper> */}
        <TVLWrapper>
          <AutoColumn justify="center" gap="2px">
            <TYPE.desc fontSize={14} style={{ display: 'flex', alignItems: 'center' }} textAlign="center">
              Circulating Supply
              <TooltipInfo
                style={{ marginLeft: '8px', display: 'flex' }}
                text="The amount of NETT that are circulating in the market and are tradeable by the public."
              />
              {/* {viewUSD ? '' : 'Total Stake Amount'} */}
            </TYPE.desc>
            <TYPE.v2Main color="t03" fontWeight={600} fontSize={32} marginTop="8px">
              {nettInCirculation > -1 ? `${Numeral(nettInCirculation).format('0,0')} NETT` : '--'}
            </TYPE.v2Main>
          </AutoColumn>
          <AutoColumn justify="center" gap="2px">
            <TYPE.desc fontSize={14} textAlign="center">
              Total Value Locked (TVL)
              {/* {viewUSD ? '' : 'Total Stake Amount'} */}
            </TYPE.desc>
            <TYPE.v2Main color="t03" fontWeight={600} fontSize={32} marginTop="8px">
              {nettStakeAmount ? `${Numeral(nettStakeAmount).format('0,0')} NETT` : '--'}
            </TYPE.v2Main>
            <TYPE.desc fontSize={14} marginTop="8px">
              ≈ {overview ? Numeral(overview.stakeTotalTvl).format('$ 0,0') : '--'}
            </TYPE.desc>
          </AutoColumn>
          <AutoColumn justify="center" gap="2px">
            <TYPE.desc fontSize={14} style={{ display: 'flex', alignItems: 'center' }} textAlign="center">
              NETT Supply Staked
              <TooltipInfo
                style={{ marginLeft: '8px', display: 'flex' }}
                text=" Percentage of NETT Circulating Supply that is staked."
              />
              {/* {viewUSD ? '' : 'Total Stake Amount'} */}
            </TYPE.desc>
            <TYPE.v2Main color="t03" fontWeight={600} fontSize={32} marginTop="8px">
              {Numeral((nettStakeAmount / nettInCirculation) * 100).format('0,0.[00]')}%
            </TYPE.v2Main>
          </AutoColumn>
        </TVLWrapper>
        <CardWrapper>
          <Card
            onClick={() => {
              if (!highestAprPool?.id) return
              history.push(`/nett-staking-detail/0/${highestAprPool?.id}`)
            }}
          >
            <CardHeaderInfo>
              <CardImg src={require('assets/images/stake/v2/stakingrewards-logo.png')} />
              <AutoColumn gap="md">
                <TYPE.v2Main fontWeight="bold" fontSize={16}>
                  StakingRewards
                </TYPE.v2Main>
                <CardTag>Earn Tokens</CardTag>
              </AutoColumn>
            </CardHeaderInfo>
            <CardInfo>
              <CardInfoRow>
                <AutoColumn justify="center" gap="md">
                  <TYPE.desc fontSize="8px">Total Staked (USD)</TYPE.desc>
                  <TYPE.v2Main fontWeight="bold" fontSize={14}>
                    {Numeral(stakingTotalStakUSD).format('$ 0,0')}
                  </TYPE.v2Main>
                </AutoColumn>
                <AutoColumn justify="center" gap="md">
                  <TYPE.desc fontSize="8px">Your Stake (USD)</TYPE.desc>
                  <TYPE.v2Main fontWeight="bold" fontSize={14}>
                    {account ? (
                      Numeral(stakingTotalUserStakeUSD).format('$ 0,0.[00]')
                    ) : (
                      <StyledButton
                        onClick={e => {
                          toggleWalletModal()
                          e.stopPropagation()
                        }}
                      >
                        Connect wallet
                      </StyledButton>
                    )}
                  </TYPE.v2Main>
                </AutoColumn>
              </CardInfoRow>
              <CardInfoRow marginTop={12}>
                <AutoColumn justify="center" gap="md">
                  <TYPE.desc fontSize="8px">Highest APR</TYPE.desc>
                  <TYPE.v2Main fontWeight="bold" fontSize={14}>
                    {Numeral(highestAprPool?.apy || 0).format('0,0.[00]')}%
                  </TYPE.v2Main>
                </AutoColumn>
                {account && (
                  <AutoColumn justify="center" gap="md">
                    <TYPE.desc fontSize="8px">Unclaimed reward</TYPE.desc>
                    <TYPE.v2Main fontWeight="bold" fontSize={14}>
                      {Numeral(stakingPendingRewardsUSD).format('$ 0,0.[00]')}
                    </TYPE.v2Main>
                  </AutoColumn>
                )}
              </CardInfoRow>
            </CardInfo>
          </Card>
          <Card
            onClick={() => {
              history.push(`/nett-staking-detail/wNETT`)
            }}
          >
            <CardHeaderInfo>
              <CardImg src={require('assets/images/stake/v2/wNett-logo.png')} />
              <AutoColumn gap="md">
                <TYPE.v2Main fontWeight="bold" fontSize={16}>
                  wNETT
                </TYPE.v2Main>
                <CardTag>Get ticket for Launchpad events</CardTag>
              </AutoColumn>
            </CardHeaderInfo>
            <CardInfo>
              <CardInfoRow>
                <AutoColumn justify="center" gap="md">
                  <TYPE.desc fontSize="8px">Total Staked (USD)</TYPE.desc>
                  <TYPE.v2Main fontWeight="bold" fontSize={14}>
                    {Numeral(totalStakedUSDForWNETT).format('$ 0,0')}
                  </TYPE.v2Main>
                </AutoColumn>
                <AutoColumn justify="center" gap="md">
                  <TYPE.desc fontSize="8px">Your Stake (USD)</TYPE.desc>
                  <TYPE.v2Main fontWeight="bold" fontSize={14}>
                    {account ? (
                      Numeral(yourStakedUSDForWNETT).format('$ 0,0.[00]')
                    ) : (
                      <StyledButton
                        onClick={e => {
                          toggleWalletModal()
                          e.stopPropagation()
                        }}
                      >
                        Connect wallet
                      </StyledButton>
                    )}
                  </TYPE.v2Main>
                </AutoColumn>
              </CardInfoRow>
              <CardInfoRow marginTop={12}>
                <AutoColumn justify="center" gap="md">
                  <TYPE.desc fontSize="8px">Total wNETT Supply</TYPE.desc>
                  <TYPE.v2Main fontWeight="bold" fontSize={14}>
                    {Numeral(wNETTTotalSupply).format('0,0')}
                  </TYPE.v2Main>
                </AutoColumn>
                <AutoColumn justify="center" gap="md"></AutoColumn>
              </CardInfoRow>
            </CardInfo>
          </Card>
          <Card
            onClick={() => {
              history.push(`/nett-staking-detail/veNETT`)
            }}
          >
            <CardHeaderInfo>
              <CardImg src={require('assets/images/stake/v2/veNett-logo.png')} />
              <AutoColumn gap="md">
                <TYPE.v2Main fontWeight="bold" fontSize={16}>
                  veNETT
                </TYPE.v2Main>
                <CardTag>Boost & Governance</CardTag>
              </AutoColumn>
            </CardHeaderInfo>
            <CardInfo>
              <CardInfoRow>
                <AutoColumn justify="center" gap="md">
                  <TYPE.desc fontSize="8px">Total Staked (USD)</TYPE.desc>
                  <TYPE.v2Main fontWeight="bold" fontSize={14}>
                    {Numeral(totalStakedUSDForVeNETT).format('$ 0,0')}
                  </TYPE.v2Main>
                </AutoColumn>
                <AutoColumn justify="center" gap="md">
                  <TYPE.desc fontSize="8px">Your Stake (USD)</TYPE.desc>
                  <TYPE.v2Main fontWeight="bold" fontSize={14}>
                    {account ? (
                      Numeral(yourStakedUSDForVeNETT).format('$ 0,0.[00]')
                    ) : (
                      <StyledButton
                        onClick={e => {
                          toggleWalletModal()
                          e.stopPropagation()
                        }}
                      >
                        Connect wallet
                      </StyledButton>
                    )}
                  </TYPE.v2Main>
                </AutoColumn>
              </CardInfoRow>
              <CardInfoRow marginTop={12}>
                <AutoColumn justify="center" gap="md">
                  <TYPE.desc fontSize="8px">Total veNETT Supply</TYPE.desc>
                  <TYPE.v2Main fontWeight="bold" fontSize={14}>
                    {Numeral(veNETTTotalSupply).format('0,0.[00]')}
                  </TYPE.v2Main>
                </AutoColumn>
                <AutoColumn justify="center" gap="md"></AutoColumn>
              </CardInfoRow>
            </CardInfo>
          </Card>
        </CardWrapper>
      </StakeWrapper>
    </StakeBox>
  )
}
