/* eslint-disable react-hooks/exhaustive-deps */
import { ChainId } from '@netswap/sdk'
// import BigNumber from 'bignumber.js'
import { NETWORK_CHAIN_ID } from 'connectors'
import { useActiveWeb3React } from 'hooks'
// import { useContract } from 'hooks/contract/useContract'
import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state'
// import { Config } from '../../../constants/launchpadV2/helper'
import { setV2Launchpad, setV2LaunchpadItem, setLaunchpadV2UserPads, setV2LaunchPadProjectInfo } from '../index'
import getV2Pads, { getPadById, getUserPads, LaunchPadV2 } from 'gql/subgraph/launchpadV2'
import { Phase } from 'hooks/contract/useLaunchpadV2PadContract'
import { useBlockTime } from 'state/application/hooks'
import axios from 'axios'

// export interface UnlimitedPad {
//   DEPOSIT_DURATION: number
//   USDPerWNETT: number
//   depositStart: number
//   id: string
//   issuedToken: string
//   issuedTokenAmount: number
//   issuedTokenDecimals: number
//   launchTime: number
//   paymentToken: string
//   paymentTokenReserve: number
//   price: number
//   targetRaised: number
//   userCount: number
// }

export interface LauchpadV2WithPhase extends LaunchPadV2 {
  currentPhase: Phase
}

function currentPhase(pad: LaunchPadV2, blockTime: number) {
  if (pad.depositStart === 0 || blockTime < pad.depositStart) {
    return Phase.Prepare
  } else if (blockTime < pad.depositEnd) {
    return Phase.Deposit
  } else if (blockTime >= pad.depositEnd && blockTime < pad.launchTime) {
    return Phase.SaleEnded
  }
  return Phase.Launch
}

export function useGetV2Pads() {
  // const helperContract = useContract(Config)
  const dispatch = useAppDispatch()
  const { chainId } = useActiveWeb3React()
  const cid = chainId || NETWORK_CHAIN_ID
  const {
    launchpadV2: { pads }
  } = useAppSelector(state => state.netswapData)

  const blockTime = useBlockTime()

  async function getV2PadsFunc(offset: number = 0, limit: number = 50) {
    try {
      // if (helperContract) {
      //   let data: UnlimitedPad[] = await helperContract.getV2Pads(offset, limit)

      //   if (data.length) {
      //     data = data.map(item => {
      //       return {
      //         DEPOSIT_DURATION: (item.DEPOSIT_DURATION as any).toNumber() * 1000,
      //         USDPerWNETT: new BigNumber((item.USDPerWNETT as any).toString()).shiftedBy(-6).toNumber(),
      //         depositStart: (item.depositStart as any).toNumber() * 1000,
      //         id: item.id,
      //         issuedToken: item.issuedToken,
      //         issuedTokenAmount: new BigNumber((item.issuedTokenAmount as any).toString())
      //           .shiftedBy(-Math.log10((item.issuedTokenDecimals as any).toString()))
      //           .toNumber(),
      //         issuedTokenDecimals: Math.log10((item.issuedTokenDecimals as any).toString()),
      //         launchTime: (item.launchTime as any).toNumber() * 1000,
      //         paymentToken: item.paymentToken,
      //         paymentTokenReserve: new BigNumber((item.paymentTokenReserve as any).toString()).shiftedBy(-6).toNumber(),
      //         price: new BigNumber((item.price as any).toString()).shiftedBy(-18).toNumber(),
      //         targetRaised: new BigNumber((item.targetRaised as any).toString()).shiftedBy(-6).toNumber(),
      //         userCount: (item.userCount as any).toNumber()
      //       } as UnlimitedPad
      //     })
      //     dispatch(
      //       setV2Launchpad({
      //         chainId: cid,
      //         data
      //       })
      //     )
      //   }

      //   console.log('unlimited data', data)
      const data = await getV2Pads()
      if (data?.length) {
        dispatch(
          setV2Launchpad({
            chainId: cid,
            data
          })
        )
      }
    } catch (e) {
      console.log('getV2Pads error')
      console.error(e)
    }
  }

  useEffect(() => {
    getV2PadsFunc()
    let interval = setInterval(() => {
      getV2PadsFunc()
    }, 30000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  return useMemo(() => {
    const data = pads ? pads[(chainId || NETWORK_CHAIN_ID) as ChainId] || [] : []

    return data.map(item => {
      return {
        ...item,
        currentPhase: currentPhase(item, blockTime || new Date().getTime() - 300000)
      }
    })
  }, [pads, chainId, blockTime])
}

export function useGetLaunchpadV2DetailById(id: string) {
  const {
    launchpadV2: { pads }
  } = useAppSelector(state => state.netswapData)

  const { chainId } = useActiveWeb3React()
  const cid = (chainId || NETWORK_CHAIN_ID) as ChainId
  const dispatch = useAppDispatch()
  const computedPads = useMemo(() => {
    return pads ? pads[cid] || [] : []
  }, [pads, cid])

  async function getPadByIdFunc() {
    try {
      const data = await getPadById(id)
      if (data) {
        dispatch(
          setV2LaunchpadItem({
            chainId: cid,
            data
          })
        )
      }
    } catch (e) {
      console.error('getPadByIdFunc error')
      console.error(e)
    }
  }

  useEffect(() => {
    getPadByIdFunc()
    const interval = setInterval(() => {
      getPadByIdFunc()
    }, 30000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  return useMemo(() => {
    return computedPads.filter(item => item.id.toLowerCase() === id.toLowerCase())[0]
  }, [computedPads, id])
}

export default function useGetV2UserPads() {
  const { chainId, account } = useActiveWeb3React()
  const dispatch = useAppDispatch()
  const {
    launchpadV2: { userPads }
  } = useAppSelector(state => state.netswapData)
  async function getUserData() {
    if (!account) return
    const res = await getUserPads(account)
    const cid = chainId || NETWORK_CHAIN_ID
    if (res.length) {
      dispatch(
        setLaunchpadV2UserPads({
          chainId: cid,
          account,
          data: res,
          method: 'set'
        })
      )
    }
  }

  useEffect(() => {
    getUserData()
  }, [account])

  return useMemo(() => {
    if (account) {
      return userPads && userPads[account] ? userPads[account][(chainId || NETWORK_CHAIN_ID) as ChainId] : []
    }
    return []
  }, [userPads, account, chainId])
}

export interface ProjectIntro {
  id: string
  projectInfo: {
    name: string
    description: string
    website?: string
    telegram?: string
    discord?: string
    twitter?: string
    medium?: string
  }
  saleInfo: {
    saleAmount: string
    price: string
    paymentToken: string
    depositStart: number
    depositDuration: number
    launchTime: number
    vesting?: string
  }
  tokenInfo: {
    tokenName?: string
    tokenSymbol?: string
    tokenDecimals?: number
    totalSupply?: string
    marketCap?: string
    maxSupply?: string
    fdv?: string
    tokenAddress?: string
  }
  about: {
    summary?: string
    whitepaper?: string
    tokenomics?: string
    roadmap?: string
    team?: {
      name?: string
      title?: string
      avatar?: string
    }[]
    investors?: {
      name?: string
      logo?: string
    }[]
    video?: {
      link?: string
      introduction?: string
    }
  }
}

export function useGetProjecInfo(address: string) {
  const { chainId } = useActiveWeb3React()
  const dispatch = useAppDispatch()
  const {
    launchpadV2: { projectInfo }
  } = useAppSelector(state => state.netswapData)
  const cid = chainId || (NETWORK_CHAIN_ID as ChainId)

  async function getJson() {
    try {
      if (!address) return
      const url = `https://raw.githubusercontent.com/Netswap/launchpad-resources/${
        chainId === ChainId.TESTNET ? 'testnet' : 'master'
      }/v2/${address.toLowerCase()}/info.json`

      const { data } = await axios.get<ProjectIntro>(url)
      if (data) {
        dispatch(
          setV2LaunchPadProjectInfo({
            chainId: cid,
            address: address.toLowerCase(),
            data
          })
        )
      }
    } catch (e) {
      console.error('getLaunchPadsIntroduction fetch failed')
      console.error(e)
    }
  }

  useEffect(() => {
    getJson()
  }, [address])

  return useMemo(() => {
    return projectInfo && projectInfo[cid] ? projectInfo[cid][address] : undefined
  }, [projectInfo, cid, address])
}
