import React from 'react'
import styled from 'styled-components'
import Banner from './banner'
import Overview from './overview'
import Earn from './earn'
// import TradingAward from './tradingAward'
import TradingIntroduction from './tradingIntroduction'
import Launchpad from './launchpad'
import Footer from './footer'
import Partners from './partners'
import Noti from './Noti'

const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 100px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-bottom: 0;
  `}
`

export default function Home() {
  return (
    <HomeWrapper>
      <Noti />
      <Banner />

      <Overview />

      <Earn />

      {/* <TradingAward /> */}

      <TradingIntroduction />

      <Launchpad />

      <Partners />

      <Footer />
    </HomeWrapper>
  )
}
