import React from 'react'
import styled from 'styled-components'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'

// import SelectNetwork from '../../components/SelectNetwork'
import { ExternalLink } from 'theme'
import SkipIcon from 'assets/images/ic_skip.png'

import { Trade, Earn, Launchpad, Charts } from './NavConfig'

const Wrapper = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: ${({ theme }) => theme.v2.bg02};
  overflow: hidden;
  &::-webkit-scrollbar {
    width: 0;
  }
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;
  display: flex;
  align-items: center;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.v2.t09};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.v2.t09)};
  }
`

const StyledExternalLink = styled(ExternalLink)`
  ${({ theme }) => theme.flexRowNoWrap}
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 400;
  display: flex;
  align-items: center;

  :hover,
  :focus {
    text-decoration: none;
    color: darken(0.1, #63d7cc);
  }
  span {
    font-size: 11px;
    display: inline-block;
    width: 12px;
    padding-top: 10px;
    background-image: url(${SkipIcon});
    /* background-position-y: 8px; */
    background-repeat: no-repeat;
    background-position-x: right;
  }
`

const LogoWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 10px 16px;
  padding-top: 20px;

  > a {
    display: flex;
    align-items: center;
  }

  img {
    height: 32px;
    width: auto;
    transition: transform 0.3s ease-in;
  }

  &:hover img {
    transform: rotate(-5deg);
  }
`

const LogoText = styled.i`
  font-size: 24px;
  margin: 3px 0 0 6px !important;
  color: ${({ theme }) => theme.v2.t01};
  text-decoration: none;
`

const ListWrapper = styled(List)`
  flex-grow: 1;
`

// const SelectNetworkWrapper = styled.div`
//   flex-shrink: 0;
//   display: flex;
//   align-items: center;
//   padding: 1rem 0;
//   justify-content: center;
// `

// const Icon = styled.img`
//   width: 24px;
//   height: 24px;
//   margin-right: 8px;
// `

const PCLogo = styled.img``

export default function NavSider() {
  return (
    <Wrapper>
      <LogoWrapper>
        <NavLink to={'/'} style={{ textDecoration: 'none' }}>
          <PCLogo src={require('assets/images/nebo_logo.png')} alt="" />
          <LogoText className="iconfont icon-text-logo" />
        </NavLink>
      </LogoWrapper>
      <ListWrapper>
        <Trade />
        <Earn />
        <ListItem button key={'competition-nav-link'}>
          <StyledNavLink id={`competition-nav-link`} to={'/trade-competition'}>
            {/* <Icon src={require(`../../assets/images/nav/competition.svg`)} alt="icon" /> */}
            Competition
          </StyledNavLink>
        </ListItem>

        <Launchpad />
        <ListItem button key={'bridge-nav-link'}>
          <StyledNavLink id={`bridge-nav-link`} to={'/bridge'}>
            {/* <Icon src={require(`../../assets/images/nav/competition.svg`)} alt="icon" /> */}
            Bridge
          </StyledNavLink>
        </ListItem>
        <Charts />
        <ListItem button key={'vote-nav-link'}>
          <StyledExternalLink href="https://vote.netswap.io">
            {/* <Icon
              src={require(`../../assets/images/nav/vote.svg`)}
              style={{ width: '20px', height: '20px' }}
              alt="icon"
            /> */}
            <div>Vote</div> <span style={{ fontSize: '11px' }}></span>
          </StyledExternalLink>
        </ListItem>
      </ListWrapper>
      {/* <SelectNetworkWrapper>
        <SelectNetwork />
      </SelectNetworkWrapper> */}
    </Wrapper>
  )
}
