import { ChainId, JSBI, Percent, Token, WETH } from '@netswap/sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'
import Web3 from 'web3'

// import { fortmatic, injected, portis, walletconnect, walletlink } from '../connectors'
import { injected, NETWORK_CHAIN_ID, walletconnect, RPC } from '../connectors'

export const ROUTER_ADDRESS = {
  [ChainId.MAINNET]: '0x1E876cCe41B7b844FDe09E38Fa1cf00f213bFf56',
  [ChainId.TESTNET]: '0xEf18F299d2134D1a9D34bFdf9DA30d1347D0Fbb4',
  // need config router
  [ChainId.TESTNET_SEPOLIA]: '0xaCCb24357D4ae5F84b8dDB97adaD1b0217383639',
}

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export { PRELOADED_PROPOSALS } from './proposals'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const METIS_TOKEN = '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000'

// export const DAI = new Token(ChainId.MAINNET, '0x6B175474E89094C44Da98b954EedeAC495271d0F', 18, 'DAI', 'Dai Stablecoin')
export const USDC = new Token(ChainId.MAINNET, '0x48c754c7A6ED973FB10d04aDbb15C91BC704F72A', 6, 'USDC', 'USDC')
export const MAINNET_ETH = new Token(ChainId.MAINNET, '0x420000000000000000000000000000000000000A', 18, 'WETH', 'Ether')
export const MAINNET_NETT = new Token(
  ChainId.MAINNET,
  '0x90fE084F877C65e1b577c7b2eA64B8D8dd1AB278',
  18,
  'NETT',
  'Netswap Token'
)

export const MAINNET_BNB = new Token(
  ChainId.MAINNET,
  '0x2692be44a6e38b698731fddf417d060f0d20a0cb',
  18,
  'BNB',
  'Poly-Peg BNB'
)
export const MAINNET_USDC = new Token(
  ChainId.MAINNET,
  '0xea32a96608495e54156ae48931a7c20f0dcc1a21',
  6,
  'm.USDC',
  'USDC Token'
)
export const MAINNET_USDT = new Token(
  ChainId.MAINNET,
  '0xbb06dca3ae6887fabf931640f67cab3e3a16f4dc',
  6,
  'm.USDT',
  'USDT Token'
)

export const MAINNET_RELAY = new Token(
  ChainId.MAINNET,
  '0xfe282af5f9eb59c30a3f78789eeffa704188bdd4',
  6,
  'RELAY',
  'Relay Token'
)

export const MAINNET_BUSD = new Token(
  ChainId.MAINNET,
  '0x12d84f1cfe870ca9c9df9785f8954341d7fbb249',
  18,
  'BUSD',
  'Poly-Peg BUSD'
)

export const MAINNET_WBTC = new Token(
  ChainId.MAINNET,
  '0xa5b55ab1daf0f8e1efc0eb1931a957fd89b918f4',
  8,
  'WBTC',
  'Wrapped BTC'
)

export const MAINNET_BYTE = new Token(
  ChainId.MAINNET,
  '0x721532bc0da5ffaeb0a6a45fb24271e8098629a7',
  18,
  'ByteToken',
  'BYTE'
)

export const MAINNET_ENKI = new Token(
  ChainId.MAINNET,
  '0x096A84536ab84E68ee210561FFD3A038E79736F1',
  18,
  'ENKI',
  'ENKI Protocol'
)
// export const COMP = new Token(ChainId.MAINNET, '0xc00e94Cb662C3520282E6f5717214004A7f26888', 18, 'COMP', 'Compound')
// export const MKR = new Token(ChainId.MAINNET, '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2', 18, 'MKR', 'Maker')
// export const AMPL = new Token(ChainId.MAINNET, '0xD46bA6D942050d489DBd938a2C909A5d5039A161', 9, 'AMPL', 'Ampleforth')
// export const WBTC = new Token(ChainId.MAINNET, '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599', 8, 'WBTC', 'Wrapped BTC')

// Block time here is slightly higher (~1s) than average in order to avoid ongoing proposals past the displayed time
export const AVERAGE_BLOCK_TIME_IN_SECS = 13
export const PROPOSAL_LENGTH_IN_BLOCKS = 40_320
export const PROPOSAL_LENGTH_IN_SECS = AVERAGE_BLOCK_TIME_IN_SECS * PROPOSAL_LENGTH_IN_BLOCKS

export const GOVERNANCE_ADDRESS = '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'

export const TIMELOCK_ADDRESS = '0x1a9C8182C09F50C8318d769245beA52c32BE35BC'

// netswap token
const NST_ADDRESS = '0x90fE084F877C65e1b577c7b2eA64B8D8dd1AB278'
export const UNI: { [chainId in ChainId]: Token } = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, NST_ADDRESS, 18, 'METIS', 'Netswap'),
  [ChainId.TESTNET]: new Token(ChainId.TESTNET, NST_ADDRESS, 18, 'METIS', 'Netswap'),
  [ChainId.TESTNET_SEPOLIA]: new Token(ChainId.TESTNET_SEPOLIA, NST_ADDRESS, 18, 'METIS', 'Netswap'),
}

export const COMMON_CONTRACT_NAMES: { [address: string]: string } = {
  [NST_ADDRESS]: 'NETT',
  [GOVERNANCE_ADDRESS]: 'Governance',
  [TIMELOCK_ADDRESS]: 'Timelock',
}

// TODO: specify merkle distributor for mainnet
export const MERKLE_DISTRIBUTOR_ADDRESS: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e',
}

const WMETIS_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.TESTNET]: [WETH[ChainId.TESTNET]],
  [ChainId.TESTNET_SEPOLIA]: [WETH[ChainId.TESTNET_SEPOLIA]],
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WMETIS_ONLY,
  [ChainId.MAINNET]: [
    ...WMETIS_ONLY[ChainId.MAINNET],
    MAINNET_USDC,
    MAINNET_USDT,
    MAINNET_NETT,
    MAINNET_BNB,
    MAINNET_RELAY,
    MAINNET_WBTC,
    MAINNET_BUSD,
    MAINNET_ETH,
    MAINNET_BYTE,
    MAINNET_ENKI,
  ],
  [ChainId.TESTNET]: [...WMETIS_ONLY[ChainId.TESTNET]],
}

export const BASE_PRICE_TOKEN: ChainTokenList = {
  [ChainId.MAINNET]: [...WMETIS_ONLY[ChainId.MAINNET], MAINNET_USDC, MAINNET_USDT],
  [ChainId.TESTNET]: [...WMETIS_ONLY[ChainId.TESTNET]],
  [ChainId.TESTNET_SEPOLIA]: [...WMETIS_ONLY[ChainId.TESTNET_SEPOLIA]],
}

export const USD_PRICE_TOKEN: ChainTokenList = {
  [ChainId.MAINNET]: [MAINNET_USDC, MAINNET_USDT],
  [ChainId.TESTNET]: [...WMETIS_ONLY[ChainId.TESTNET]],
  [ChainId.TESTNET_SEPOLIA]: [...WMETIS_ONLY[ChainId.TESTNET_SEPOLIA]],
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.TESTNET]: {
    // [AMPL.address]: [DAI, WETH[ChainId.TESTNET]]
  },
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  // [ChainId.MAINNET]: [...WMETIS_ONLY[ChainId.TESTNET], DAI, USDC, USDT, WBTC]
  [ChainId.MAINNET]: [MAINNET_USDT, MAINNET_USDC, MAINNET_NETT, MAINNET_ETH],
  [ChainId.TESTNET]: [],
  [ChainId.TESTNET_SEPOLIA]: [],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WMETIS_ONLY,
  //  [ChainId.MAINNET]: [...WMETIS_ONLY[ChainId.TESTNET], DAI, USDC, USDT, WBTC]
  [ChainId.TESTNET]: [...WMETIS_ONLY[ChainId.TESTNET]],
  [ChainId.TESTNET_SEPOLIA]: [...WMETIS_ONLY[ChainId.TESTNET_SEPOLIA]],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.TESTNET]: [
    // [
    //   new Token(ChainId.TESTNET, '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643', 8, 'cDAI', 'Compound Dai'),
    //   new Token(ChainId.TESTNET, '0x39AA39c021dfbaE8faC545936693aC917d5E7563', 8, 'cUSDC', 'Compound USD Coin')
    // ],
    // [USDC, USDT],
    // [DAI, USDT]
  ],
  [ChainId.TESTNET_SEPOLIA]: [],
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true,
  },
  // WALLET_LINK: {
  //   connector: walletlink,
  //   name: 'Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Use Coinbase Wallet app on mobile device',
  //   href: null,
  //   color: '#315CF5'
  // },
  // COINBASE_LINK: {
  //   name: 'Open in Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Open in Coinbase Wallet app.',
  //   href: 'https://go.cb-w.com/mtUDhEZPy1',
  //   color: '#315CF5',
  //   mobile: true,
  //   mobileOnly: true
  // },
  // FORTMATIC: {
  //   connector: fortmatic,
  //   name: 'Fortmatic',
  //   iconName: 'fortmaticIcon.png',
  //   description: 'Login using Fortmatic hosted wallet',
  //   href: null,
  //   color: '#6748FF',
  //   mobile: true
  // },
  // Portis: {
  //   connector: portis,
  //   name: 'Portis',
  //   iconName: 'portisIcon.png',
  //   description: 'Login using Portis hosted wallet',
  //   href: null,
  //   color: '#4A6C9B',
  //   mobile: true
  // }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7)

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008',
]

export const SupportChainId = [ChainId.MAINNET, ChainId.TESTNET, ChainId.TESTNET_SEPOLIA]

export const SWITCH_NETWORK_CONFIG = {
  [ChainId.TESTNET]: {
    chainId: `0x${ChainId.TESTNET.toString(16)}`,
    chainName: 'Metis Goerli',
    nativeCurrency: {
      name: 'Metis Token',
      symbol: 'METIS',
      decimals: 18,
    },
    rpcUrls: [`https://goerli.gateway.metisdevops.link`],
    blockExplorerUrls: ['https://goerli.explorer.metisdevops.link'],
  },
  [ChainId.MAINNET]: {
    chainId: `0x${ChainId.MAINNET.toString(16)}`,
    chainName: 'Andromeda(Metis)',
    nativeCurrency: {
      name: 'Metis Token',
      symbol: 'METIS',
      decimals: 18,
    },
    rpcUrls: [`https://andromeda.metis.io/?owner=${ChainId.MAINNET}`],
    blockExplorerUrls: ['https://andromeda-explorer.metis.io'],
  },
  [ChainId.TESTNET_SEPOLIA]: {
    chainId: `0x${ChainId.TESTNET_SEPOLIA.toString(16)}`,
    chainName: 'Metis Sepolia',
    nativeCurrency: {
      name: 'Metis Token',
      symbol: 'METIS',
      decimals: 18,
    },
    rpcUrls: [
      'https://sepolia.metisdevops.link',
      // `https://stardust.metis.io/?owner=${ChainId.layer2Testnet}`,
    ],
    blockExplorerUrls: ['https://sepolia-explorer.metisdevops.link'],
  },
}

export const BridgeUrl = 'http://bridge.metis.io/'

export const DefaultWeb3 = new Web3(new Web3.providers.HttpProvider(RPC[NETWORK_CHAIN_ID as ChainId]))

export interface ContractConfig {
  [ChainId.MAINNET]: {
    abi: any
    address: string
  }
  [ChainId.TESTNET]: {
    abi: any
    address: string
  }
  [ChainId.TESTNET_SEPOLIA]: {
    abi: any
    address: string
  }
}

export const BUNDLE_ID = '1'

export enum TimeframeOptions {
  WEEK = '1 week',
  MONTH = '1 month',
  // THREE_MONTHS: '3 months',
  // YEAR: '1 year',
  ALL_TIME = 'All time',
}

export const SUPPORTED_LIST_URLS__NO_ENS = [
  'https://raw.githubusercontent.com/Netswap/token-lists/master/whitelist.json',
]

export const OVERVIEW_TOKEN_BLACKLIST: string[] = []

export const PAIR_BLACKLIST: string[] = []

export const EXPLORE_URL = 'https://andromeda-explorer.metis.io'

export const FEE_WARNING_TOKENS: string[] = []
