import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client'
import { ChainId } from '@netswap/sdk'
import { NETWORK_CHAIN_ID } from 'connectors'
import axios from 'axios'
import Config from './config'
import { SupportChainId } from '../constants'

const web3ChainId = Number((window?.web3 as any)?.currentProvider.chainId)

const chainId = SupportChainId.indexOf(web3ChainId) >= 0 ? web3ChainId : NETWORK_CHAIN_ID

console.log('currentChainId', chainId)

function getCurrentChainId(): ChainId {
  const web3ChainId = Number((window?.web3 as any)?.currentProvider.chainId)

  return SupportChainId.indexOf(web3ChainId) >= 0 ? web3ChainId : NETWORK_CHAIN_ID
}

function getApolloClient(key: string) {
  return () =>
    new ApolloClient({
      cache: new InMemoryCache(),
      link: new HttpLink({
        uri: (Config[getCurrentChainId()] as any)[key],
      }),
    })
}

function getSyncStatusReq() {
  return () => axios.get((Config[getCurrentChainId()] as any)['exchangeSyncStatusUrl'])
}

const client = getApolloClient('graph')
export const ExchangeSubgraph = getApolloClient('exchangeSubgraph')
export const BlockSubgraph = getApolloClient('blockSubgraph')
// export const IndexNodeSubgraph = getApolloClient('indexNodeSubgraph')
export const SyncStatusReq = getSyncStatusReq()
export const FarmSubgraph = getApolloClient('farmSubgraph')
export const BoostedFarmSubgraph = getApolloClient('boostedFarmSubgraph')
export const LaunchpadSubgraph = getApolloClient('padSubgraph')
export const LaunchpadV2Subgraph = getApolloClient('padV2Subgraph')
export const MicroServicesClient = getApolloClient('services')
export const TradeCompetition = getApolloClient('tradeCompetition')
export const DexCandlesSubgraph = getApolloClient('dexCandles')

export default client
