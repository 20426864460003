import React from 'react'
import { Link as RebassLink, LinkProps } from 'rebass'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { lighten, darken } from 'polished'

const WrappedLink = ({
  external,
  children,
  ...rest
}: LinkProps & {
  external?: boolean
  children: React.ReactNode | React.ReactChildren
}) => (
  <RebassLink target={external ? '_blank' : ''} rel={external ? 'noopener noreferrer' : ''} color="#2f80ed" {...rest}>
    {children}
  </RebassLink>
)

WrappedLink.propTypes = {
  external: PropTypes.bool
}

const Link = styled(WrappedLink)<{ color?: string }>`
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  color: ${({ color, theme }) => (color ? color : theme.isDark ? theme.v2.t09 : theme.v2.t05)};

  &:visited {
    color: ${({ color, theme }) =>
      color ? lighten(0.1, color) : lighten(0.1, theme.isDark ? theme.v2.t09 : theme.v2.t05)};
  }

  &:hover {
    cursor: pointer;
    text-decoration: none;
    underline: none;
    color: ${({ color, theme }) =>
      color ? darken(0.1, color) : darken(0.1, theme.isDark ? theme.v2.t09 : theme.v2.t05)};
  }
`

export const InnerLink = styled(RouterLink)<{ color?: string }>`
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  color: ${({ color, theme }) => (color ? color : theme.isDark ? theme.v2.t09 : theme.v2.t05)};

  &:visited {
    color: ${({ color, theme }) =>
      color ? lighten(0.1, color) : lighten(0.1, theme.isDark ? theme.v2.t09 : theme.v2.t05)};
  }

  &:hover {
    cursor: pointer;
    text-decoration: none;
    underline: none;
    color: ${({ color, theme }) =>
      color ? darken(0.1, color) : darken(0.1, theme.isDark ? theme.v2.t09 : theme.v2.t05)};
  }
`

export default Link

export const CustomLink = styled(RouterLink)`
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  color: ${({ color, theme }) => (color ? color : theme.v2.t10)};

  &:visited {
    color: ${({ color, theme }) => (color ? lighten(0.1, color) : lighten(0.1, theme.v2.t10))};
  }

  &:hover {
    cursor: pointer;
    text-decoration: none;
    underline: none;
    color: ${({ color, theme }) =>
      color ? darken(0.1, color) : darken(0.1, theme.isDark ? theme.v2.t09 : theme.v2.t05)};
  }
`

export const BasicLink = styled(RouterLink)`
  text-decoration: none;
  color: inherit;
  &:hover {
    cursor: pointer;
    text-decoration: none;
    underline: none;
  }
`
