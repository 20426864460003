import React from 'react'
import TransactionSetting from 'components/TransactionSettings'
import { useUserSlippageTolerance, useUserTransactionTTL } from 'state/user/hooks'
import styled from 'styled-components'

const TransactionSettingWrapper = styled(TransactionSetting)`
  &.slippageWrapper {
    padding: 12px 1rem 0px 1.5rem;
    .slippageLine {
      display: flex;

      ${({ theme }) => theme.mediaWidth.upToSmall`
        flex-direction: column;
    `};

      .tabTitle {
        color: ${({ theme }) => theme.v2.t01} !important;
      }
    }
    .slippageItems {
      flex: 1;

      button {
        min-width: 3.5rem;
        border-radius: 11px;
        ${({ theme }) => theme.mediaWidth.upToSmall`
            flex: 1;
        `};

        background: ${(props) => props.theme.v2.bg02};

        &.active {
          background: #00cfff;
          color: #000;
          border: none;
        }
      }

      .optionCustom {
        input {
          background: transparent;
        }
      }
    }
  }
`

export default function SlippageTabs() {
  const [userSlippageTolerance, setUserslippageTolerance] = useUserSlippageTolerance()
  const [ttl, setTtl] = useUserTransactionTTL()

  return (
    <TransactionSettingWrapper
      rawSlippage={userSlippageTolerance}
      setRawSlippage={setUserslippageTolerance}
      deadline={ttl}
      setDeadline={setTtl}
      showDeadline={false}
      className="slippageWrapper"
    />
  )
}
