import React, { useMemo, useState } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import styled from 'styled-components'
import MaterialTable from 'material-table'
import TokenLogo from 'components/TokenImage'
import useGetUserLiquidityPools from 'state/data/hooks/LiquidityPool/useGerUserLiquidityPools'
import { useHistory, useLocation } from 'react-router-dom'
import { ButtonV2, ButtonV2Transparent } from 'components/Button'
import SearchPair from './Search'
import useGetLiquitidyPools from 'state/data/hooks/LiquidityPool/useGetPoolList'
import { RowBetween } from 'components/Row'
import { NettAddres } from 'constants/tokens/nett'
import NoPool from './NoPool'
import { useActiveWeb3React } from 'hooks'
import LogoText from 'components/LogoText'
import Numeral from 'numeral'
import MaterialTablePagination from 'components/MaterialTablePagination'
import { TYPE } from 'theme'
import { isMobile } from 'react-device-detect'

const Wrapper = styled.div`
  margin-top: 30px;
  width: 930px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
        margin-top: 0px;
        width: calc(100vw - 2rem);
    `};
  height: fit-content;

  .MuiTabs-root {
    .MuiTabs-flexContainer {
      justify-content: flex-start;
      background: transparent;
      .MuiTab-root {
        &.Mui-selected {
          color: #00cfff;
        }
        color: ${({ theme }) => theme.v2.t02};
      }
    }

    .MuiTabs-indicator {
      background-color: #00cfff;
    }
  }
`

const TableWrapper = styled.div`
  width: 100%;
  height: fit-content;
  /* max-height: 200px; */
  margin-top: 1rem;

  &.mobile {
    table {
      height: 1px;
      border-collapse: separate;
      border-spacing: 0 1rem;
    }
    .MuiTableRow-head {
      display: none;
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        border-top: 8px solid ${({ theme }) => theme.v2.bg01};
        border-bottom: 8px solid ${({ theme }) => theme.v2.bg01};
        background: ${({ theme }) => theme.v2.bg02};
      }
    }

    .MuiTableRow-root {
      .MuiTableCell-body {
        vertical-align: top;
        text-align: left;
        &:first-child {
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
        }
        &:last-child {
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
    }
  }
  .MuiPaper-root {
    background: transparent;
    box-shadow: none;
  }
  .MuiTableCell-head,
  .MuiTableCell-root {
    padding: 4px;
    text-align: center;
    border: none;
  }

  &.empty {
    .MuiTableBody-root {
      tr {
        height: 360px !important;
      }
    }
  }

  table {
    overflow: hidden;
  }

  .MuiTableCell-root {
    font-size: 14px !important;
    background-color: transparent;

    &.MuiTableCell-head {
      font-size: 14px !important;
      color: #c0c0c0;
      background-color: transparent !important;
    }

    color: ${({ theme }) => theme.v2.t01} !important;

    .buy {
      color: #00cb83;
    }

    .sale {
      color: #ff6969;
    }
  }
  .MuiTableBody-root {
    .MuiTableRow-root {
      cursor: pointer;

      :hover {
        background: #2d2d4e;
        .MuiTableCell-root {
          color: #fff !important;
        }
      }
    }
  }
  .MuiTableRow-root {
    height: 48px !important;
    /* border-top: 4px solid ${({ theme }) => theme.v2.bg02};
    border-bottom: 4px solid ${({ theme }) => theme.v2.bg02}; */

    .MuiTableCell-body {
      &:first-child {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
      }
      &:last-child {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }

  .MuiTablePagination-select,
  .MuiTablePagination-selectRoot {
    display: none !important;
  }
  .MuiTableFooter-root {
    overflow: hidden;
    .MuiTableCell-root {
      background-color: transparent;
    }
  }

  .MuiTablePagination-toolbar {
    display: block;
  }
  .MuiTableFooter-root {
    display: flex;
    align-items: center;
    justify-content: center;
    .MuiTableRow-footer {
      color: #fff;
      display: block;
      /* width: 500px; */

      .MuiTableCell-root {
        * {
          color: ${({ theme }) => theme.v2.t01} !important;
        }
      }
    }
  }
`

const MobileColumn = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 12px;
  button {
    height: 28px;
    padding: unset;
    width: 106px;
    font-size: 12px;
  }
`

const NameWrapepr = styled.div`
  display: Flex;
  align-items: center;
  justify-content: flex-start;

  &.mobile {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    button {
      height: 28px;
      padding: unset;
      width: 106px;
      font-size: 12px;
    }
  }
`

const TokenWrapper = styled.div`
  display: Flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  button {
    width: 60px;
    height: 28px;
    border-radius: 68px;
    padding: unset;

    font-size: 12px;
    &:not(:first-child) {
      margin-left: 8px;
    }
  }
`

const JoinWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;
  background: ${({ theme }) => theme.v2.headerBg};
  width: 930px;
  border-radius: 18px;
  box-sizing: border-box;
  padding: 10px 0;
  margin-bottom: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
        width: calc(100vw - 2rem);
        
    `};
`

const JoinDescWrapper = styled.div`
  position: relative;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.v2.t04};
`

const JoinBanner = styled.img`
  position: absolute;
  left: 0;
  transform: translate(-120%, -90%);
  top: 50%;
  width: 108px;
  height: 76px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 72px;
        height: 50px;
    `};
`

const CustomRowBetween = styled(RowBetween)`
  width: 350px;

  button {
    width: 154px;
    height: 40px;
    padding: unset;

    border-radius: 10px;
    position: relative;

    .existing {
      position: absolute;
      right: 11px;
      top: 0;
      transform: translateY(-20px);
      background-color: ${({ theme }) => theme.v2.df02};

      border-radius: 12px 12px 0px 0px;
      color: ${({ theme }) => theme.v2.t01};
      width: 86px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 10px;
    }
  }
`

const StyleLogoText = styled(LogoText)`
  margin-top: 40px;
`

const DataColumn = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  > div {
    &:first-child {
      margin-bottom: 12px;
    }
  }

  button {
    height: 28px;
    padding: unset;
    font-size: 12px;
    margin-top: 24px;
    margin-bottom: 12px;
    width: 106px;
  }
`

const DataWrapper = styled.div`
  /* margin-bottom: 20px; */
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`

export default function PoolList() {
  const [searchText, setSearchText] = useState('')
  const { pools: allPools } = useGetLiquitidyPools()
  const { search } = useLocation()

  const tabQuery = useMemo(() => new URLSearchParams(search).get('tab'), [search])
  const userPools = useGetUserLiquidityPools()
  const [tab, setTab] = React.useState(tabQuery === 'my' ? 1 : 0)
  const history = useHistory()
  const { account } = useActiveWeb3React()

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setTab(newValue)
    if (newValue === 0) {
      history.replace('/pool')
    }
  }

  const computedPools = useMemo(() => {
    let data = allPools.map((item) => {
      return {
        ...item,
      }
    })

    if (searchText) {
      data = data.filter((item) => {
        const searchValueReg = new RegExp(searchText.toLowerCase())
        const p = `
            ${item.id.toLowerCase()}/
            ${item.token0.symbol.toLowerCase()}/
            ${item.token1.symbol.toLowerCase()}/
            ${item.token0.name.toLowerCase()}/
            ${item.token1.name.toLowerCase()}/
            ${item.token0.id.toLowerCase()}/
            ${item.token1.id.toLowerCase()}/
        `
        return searchValueReg.test(p)
      })
    }
    return data
  }, [allPools, searchText])

  const computedUserPools = useMemo(() => {
    if (!userPools?.length) return []
    let data = userPools.map((item) => {
      return {
        ...item,
      }
    })

    if (searchText) {
      data = data.filter((item) => {
        const searchValueReg = new RegExp(searchText.toLowerCase())
        const p = `
              ${item.pair.token0.symbol.toLowerCase()}/
              ${item.pair.token1.symbol.toLowerCase()}/
              ${item.pair.token0.name.toLowerCase()}/
              ${item.pair.token1.name.toLowerCase()}/
              ${item.pair.token0.id.toLowerCase()}/
              ${item.pair.token1.id.toLowerCase()}/
          `
        return searchValueReg.test(p)
      })
    }
    return data
  }, [userPools, searchText])

  const userListPageSize = computedUserPools.length >= 10 ? 10 : computedUserPools.length

  return (
    <>
      <SearchPair value={searchText} onChange={setSearchText} />
      <Wrapper>
        <Tabs value={tab} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
          <Tab label="All" value={0} />
          <Tab label="My Pool" value={1} />
        </Tabs>
        {tab === 0 ? (
          !isMobile ? (
            <TableWrapper>
              <MaterialTable
                key={'tab0'}
                title="Render Image Preview"
                options={{
                  toolbar: false,
                  sorting: false,
                  pageSize: 10,
                  draggable: false,
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: 'No result',
                  },
                }}
                components={{
                  Pagination: MaterialTablePagination,
                }}
                onRowClick={(e, rowData) => {
                  history.push(`/add/${rowData?.token0.id}/${rowData?.token1.id}`)
                }}
                columns={[
                  {
                    title: 'Name',
                    align: 'left',
                    cellStyle: {
                      width: '25%',
                      textAlign: 'left',
                    },
                    render: (item) => (
                      <NameWrapepr>
                        <TokenWrapper>
                          <TokenLogo address={item.token0.id} />{' '}
                          <TokenLogo address={item.token1.id} style={{ marginLeft: '-8px' }} />
                        </TokenWrapper>
                        {item.token0.symbol} / {item.token1.symbol}
                      </NameWrapepr>
                    ),
                  },
                  {
                    title: 'Liquidity',
                    width: '25%',
                    render: (item) => <>{Numeral(item.reserveUSD).format('$ 0,0')}</>,
                  },
                  {
                    title: 'Volume(24h)',
                    render: (item) => <>{Numeral(item.last24HourVol).format('$ 0,0')}</>,
                  },
                  {
                    title: 'LP Reward APR',
                    render: (item) => <TYPE.v2Main color="t08">+{item.lpApr} %</TYPE.v2Main>,
                  },
                ]}
                data={computedPools}
              />
            </TableWrapper>
          ) : (
            <TableWrapper className="mobile">
              <MaterialTable
                key={'tab0'}
                title="Render Image Preview"
                options={{
                  toolbar: false,
                  sorting: false,
                  pageSize: 10,
                  draggable: false,
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: 'No result',
                  },
                }}
                components={{
                  Pagination: MaterialTablePagination,
                }}
                onRowClick={(e, rowData) => {
                  history.push(`/add/${rowData?.token0.id}/${rowData?.token1.id}`)
                }}
                columns={[
                  {
                    title: 'MobileName',
                    align: 'left',
                    cellStyle: {
                      textAlign: 'left',
                    },
                    render: (item) => (
                      <NameWrapepr className="mobile">
                        <TYPE.desc fontSize={12}>Name</TYPE.desc>
                        <TokenWrapper style={{ margin: '4px 0' }}>
                          <TokenLogo address={item.token0.id} />{' '}
                          <TokenLogo address={item.token1.id} style={{ marginLeft: '-8px' }} />
                        </TokenWrapper>
                        {item.token0.symbol} / {item.token1.symbol}
                      </NameWrapepr>
                    ),
                  },
                  {
                    title: 'Data1',
                    width: '25%',
                    render: (item) => (
                      <DataColumn>
                        <DataWrapper>
                          <TYPE.desc fontSize={12}>Liquidity</TYPE.desc>
                          <TYPE.v2Main>{Numeral(item.reserveUSD).format('$ 0,0')}</TYPE.v2Main>
                        </DataWrapper>
                        <DataWrapper>
                          <TYPE.desc fontSize={12}>Total Fee(24H)</TYPE.desc>
                          <TYPE.v2Main>{Numeral(item.last24HourVol * 0.003).format('$ 0,0.[00]')}</TYPE.v2Main>
                        </DataWrapper>
                      </DataColumn>
                    ),
                  },
                  {
                    title: 'Data2',
                    width: '25%',
                    render: (item) => (
                      <DataColumn>
                        <DataWrapper>
                          <TYPE.desc fontSize={12}>Volume(24h)</TYPE.desc>
                          <TYPE.v2Main>{Numeral(item.last24HourVol).format('$ 0,0')}</TYPE.v2Main>
                        </DataWrapper>
                        <DataWrapper>
                          <TYPE.desc fontSize={12}>LP Reward APR</TYPE.desc>
                          <TYPE.v2Main color="t08">+{item.lpApr} %</TYPE.v2Main>
                        </DataWrapper>
                      </DataColumn>
                    ),
                  },
                ]}
                data={computedPools}
              />
            </TableWrapper>
          )
        ) : !account ? (
          <StyleLogoText text="Please connect to wallet" />
        ) : computedUserPools.length ? (
          !isMobile ? (
            <TableWrapper>
              <MaterialTable
                key={'tab1'}
                title="Render Image Preview"
                options={{ toolbar: false, sorting: false, pageSize: userListPageSize, draggable: false }}
                localization={{
                  body: {
                    emptyDataSourceMessage: 'No result',
                  },
                }}
                components={{
                  Pagination: MaterialTablePagination,
                }}
                columns={[
                  {
                    title: 'Name',
                    width: '20%',
                    render: (item) => (
                      <NameWrapepr>
                        <TokenWrapper>
                          <TokenLogo address={item.pair.token0.id} />{' '}
                          <TokenLogo address={item.pair.token1.id} style={{ marginLeft: '-8px' }} />
                        </TokenWrapper>
                        {item.pair.token0.symbol} / {item.pair.token1.symbol}
                      </NameWrapepr>
                    ),
                  },
                  {
                    title: 'Your total Pool Token',
                    render: (item) => <>{item.liquidityTokenBalance}</>,
                  },
                  {
                    title: 'Your pool tokens',
                    render: (item) => (
                      <>
                        {item.token0Amount.toFixed(6)} {item.pair.token0.symbol} <br />
                        {item.token1Amount.toFixed(6)} {item.pair.token1.symbol}
                      </>
                    ),
                  },
                  {
                    title: 'Your pool share',
                    align: 'left',
                    render: (item) => <>{item.share < 0.01 ? '< 0.01' : item.share}%</>,
                  },
                  {
                    title: '',
                    align: 'left',
                    render: (item) => (
                      <ButtonWrapper>
                        <ButtonV2
                          onClick={() => {
                            history.push(`/add/${item?.pair.token0.id}/${item?.pair.token1.id}`)
                          }}
                        >
                          Add
                        </ButtonV2>
                        <ButtonV2Transparent
                          onClick={() => {
                            history.push(`/remove/${item?.pair.token0.id}/${item?.pair.token1.id}`)
                          }}
                        >
                          Remove
                        </ButtonV2Transparent>
                      </ButtonWrapper>
                    ),
                  },
                ]}
                data={computedUserPools}
              />
            </TableWrapper>
          ) : (
            <TableWrapper className="mobile">
              <MaterialTable
                key={'tab1'}
                title="Render Image Preview"
                options={{ toolbar: false, sorting: false, pageSize: userListPageSize, draggable: false }}
                localization={{
                  body: {
                    emptyDataSourceMessage: 'No result',
                  },
                }}
                components={{
                  Pagination: MaterialTablePagination,
                }}
                columns={[
                  {
                    title: 'Name',
                    width: '20%',
                    render: (item) => (
                      <MobileColumn>
                        <NameWrapepr className="mobile">
                          <TYPE.desc fontSize={12}>Name</TYPE.desc>
                          <TokenWrapper style={{ margin: '4px 0' }}>
                            <TokenLogo address={item.pair.token0.id} />{' '}
                            <TokenLogo address={item.pair.token1.id} style={{ marginLeft: '-8px' }} />
                          </TokenWrapper>
                          {item.pair.token0.symbol} / {item.pair.token1.symbol}
                        </NameWrapepr>
                        <ButtonV2
                          onClick={() => {
                            history.push(`/add/${item?.pair.token0.id}/${item?.pair.token1.id}`)
                          }}
                        >
                          Add
                        </ButtonV2>
                      </MobileColumn>
                    ),
                  },
                  {
                    title: 'Data1',
                    render: (item) => (
                      <DataColumn>
                        <DataWrapper>
                          <TYPE.desc fontSize={12}>Your total pool tokens</TYPE.desc>
                          <TYPE.v2Main>≈ {Number(item.liquidityTokenBalance).toFixed(6)}</TYPE.v2Main>
                        </DataWrapper>
                        <DataWrapper>
                          <TYPE.desc fontSize={12}>Pooled token pair</TYPE.desc>
                          <TYPE.v2Main style={{ display: 'flex', alignItems: 'center' }}>
                            {item.token0Amount.toFixed(6)}{' '}
                            <TokenLogo style={{ marginLeft: '4px' }} address={item.pair.token0.id} />
                          </TYPE.v2Main>
                          <TYPE.v2Main style={{ display: 'flex', alignItems: 'center' }}>
                            {item.token1Amount.toFixed(6)}{' '}
                            <TokenLogo style={{ marginLeft: '4px' }} address={item.pair.token1.id} />
                          </TYPE.v2Main>
                        </DataWrapper>
                        <ButtonV2Transparent
                          onClick={() => {
                            history.push(`/remove/${item?.pair.token0.id}/${item?.pair.token1.id}`)
                          }}
                        >
                          Remove
                        </ButtonV2Transparent>
                      </DataColumn>
                    ),
                  },

                  {
                    title: 'Data2',
                    render: (item) => (
                      <DataColumn>
                        <DataWrapper>
                          <TYPE.desc fontSize={12}>Your pool share</TYPE.desc>
                          <TYPE.v2Main>{item.share < 0.01 ? '< 0.01' : item.share}%</TYPE.v2Main>
                        </DataWrapper>
                      </DataColumn>
                    ),
                  },
                ]}
                data={computedUserPools}
              />
            </TableWrapper>
          )
        ) : (
          <NoPool />
        )}
      </Wrapper>
      {account && (
        <JoinWrapper>
          <JoinDescWrapper>
            Don‘t see a pool you joined? <JoinBanner alt="" src={require('assets/images/pool/header.png')} />
          </JoinDescWrapper>
          <CustomRowBetween>
            <ButtonV2
              onClick={() => {
                history.push(`create/METIS/${NettAddres}`)
              }}
            >
              Create Pool
            </ButtonV2>
            <ButtonV2Transparent
              onClick={() => {
                history.push(`/find`)
              }}
            >
              Import Pool<span className="existing">For exsiting</span>
            </ButtonV2Transparent>
          </CustomRowBetween>
        </JoinWrapper>
      )}
    </>
  )
}
