import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useActiveWeb3React } from '../../hooks'
import { AppDispatch, AppState } from '../index'
import {
  addPopup,
  ApplicationModal,
  PopupContent,
  removePopup,
  setOpenModal,
  ApplicationToggleKey,
  updatToggleKey,
  updateHeaderHeight
} from './actions'

export function useBlockNumber(): number | undefined {
  const { chainId } = useActiveWeb3React()

  return useSelector((state: AppState) => state.application.blockNumber[chainId ?? -1])
}

export function useBlockTime(): number | undefined {
  const { chainId } = useActiveWeb3React()

  return useSelector((state: AppState) => state.application.blockTime[chainId ?? -1])
}

export function useModalOpen(modal: ApplicationModal): boolean {
  const openModal = useSelector((state: AppState) => state.application.openModal)
  return openModal === modal
}

export function useToggleModal(modal: ApplicationModal): () => void {
  const open = useModalOpen(modal)
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => dispatch(setOpenModal(open ? null : modal)), [dispatch, modal, open])
}

export function useOpenModal(modal: ApplicationModal): () => void {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => dispatch(setOpenModal(modal)), [dispatch, modal])
}

export function useCloseModals(): () => void {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => dispatch(setOpenModal(null)), [dispatch])
}

export function useWalletModalToggle(): () => void {
  return useToggleModal(ApplicationModal.WALLET)
}

export function useNettToggle(): () => void {
  return useToggleModal(ApplicationModal.NETT)
}

export function useBridgeToggle(): () => void {
  return useToggleModal(ApplicationModal.BRIDGE)
}

export function useToggleSettingsMenu(): () => void {
  return useToggleModal(ApplicationModal.SETTINGS)
}

export function useShowClaimPopup(): boolean {
  return useModalOpen(ApplicationModal.CLAIM_POPUP)
}

export function useToggleShowClaimPopup(): () => void {
  return useToggleModal(ApplicationModal.CLAIM_POPUP)
}

export function useToggleSelfClaimModal(): () => void {
  return useToggleModal(ApplicationModal.SELF_CLAIM)
}

export function useToggleDelegateModal(): () => void {
  return useToggleModal(ApplicationModal.DELEGATE)
}

export function useToggleVoteModal(): () => void {
  return useToggleModal(ApplicationModal.VOTE)
}

export function useViewToggleState(block: ApplicationToggleKey): boolean {
  return useSelector((state: AppState) => state.application.toggleKey[block])
}

export function useToggleKey(block: ApplicationToggleKey) {
  const blockValue = useSelector((state: AppState) => state.application.toggleKey[block])
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(
    () =>
      dispatch(
        updatToggleKey({
          key: block,
          value: !blockValue
        })
      ),
    [dispatch, block, blockValue]
  )
}

export function useToggleSwapChart(): () => void {
  return useToggleKey(ApplicationToggleKey.SWAP_CHART)
}
export function useToggleSwapHistory(): () => void {
  return useToggleKey(ApplicationToggleKey.SWAP_HISTORY)
}
export function useToggleSwapUpdateTrade(): () => void {
  return useToggleKey(ApplicationToggleKey.SWAP_UPDATE_TRADE)
}

// returns a function that allows adding a popup
export function useAddPopup(): (content: PopupContent, key?: string) => void {
  const dispatch = useDispatch()

  return useCallback(
    (content: PopupContent, key?: string) => {
      dispatch(addPopup({ content, key }))
    },
    [dispatch]
  )
}

// returns a function that allows removing a popup via its key
export function useRemovePopup(): (key: string) => void {
  const dispatch = useDispatch()
  return useCallback(
    (key: string) => {
      dispatch(removePopup({ key }))
    },
    [dispatch]
  )
}

// get the list of active popups
export function useActivePopups(): AppState['application']['popupList'] {
  const list = useSelector((state: AppState) => state.application.popupList)
  return useMemo(() => list.filter(item => item.show), [list])
}

export function useHeaderHeight(): AppState['application']['headerHeight'] {
  return useSelector((state: AppState) => state.application.headerHeight)
}

export function useUpdateHeaderHeight(): (height: number) => void {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback((height: number) => dispatch(updateHeaderHeight({ value: height })), [dispatch])
}
