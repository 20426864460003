/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import { ReactComponent as Close } from 'assets/images/x.svg'
import { TYPE } from 'theme'
import { LaunchPad, PadType } from 'gql/microservices/pads'
import { ButtonMetis } from 'components/Button'
import { Contract } from 'ethers'
import TokenImage from 'components/TokenImage'
import { BigNumber } from 'bignumber.js'
import useSend from 'state/contract/hooks/useSend'
import { InputBase } from '@material-ui/core'
import { isMobile } from 'react-device-detect'

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`

const LaunchpadSection = styled.div`
  padding: 32px 40px;
  position: relative;
  ${({ theme }) => theme.flexColumnNoWrap}
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 24px 12px;
  `}
`

const CloseIcon = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
  color: #c42a61;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
const CloseColor = styled(Close)`
  path {
    stroke: '${({ theme }) => theme.text4}';
  }
`
const LaunchpadTitle = styled(TYPE.white)`
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
`

const LaunchpadCon = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  width: 100%;
  margin: 39px auto 32px;

  .launchpadConTitle {
    height: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    line-height: 20px;
    margin: 0;
  }
`

const LaunchpadSolidCon = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  justify-content: space-between;
  padding: 12px 16px;
  background: #221232;
  border-radius: 8px;
  align-items: center;
  margin: 16px 0;
  &.outlineTransParentBg {
    background: transparent;
    border: 1px solid #331b4b;
  }
  &.flexColumn {
    ${({ theme }) => theme.flexColumnNoWrap}
  }
  .launchpadConLeft {
    ${({ theme }) => theme.flexRowNoWrap}
    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }

  .launchpadConRight {
    ${({ theme }) => theme.flexRowNoWrap}
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }
`

const SmallBtnCon = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  justify-content: center;
`
const SmallConfirmButton = styled(ButtonMetis)`
  height: 48px;
  font-size: 16px;
  width: 160px;
  border-radius: 8px;
  font-weight: 600;
  line-height: 48px;
`

const InputLine = styled.div`
  border-radius: 8px;
  background: #221232;
  padding: 0 8px 0 12px;
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 7px 8px 7px 12px;
  `}
`

const BtnDefault = styled(ButtonMetis)`
  cursor: pointer;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 400;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
    height: 48px;
  `}
`

const MaxBtn = styled(BtnDefault)`
  width: 42px;
  height: 24px;
  padding: 0;
  border: 1px solid #c42a61 !important;
  font-size: 14px;
  color: #c42a61;
  line-height: 20px;
  background: transparent;
  border-radius: 4px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 40px;
    line-height: 40px;
    width: 105px;
    border-radius: 8px;
  `}
`

const Input = styled(InputBase)`
  input {
    color: #fff;
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`

const InfoDefault = styled.div`
  display: flex;
  justify-content: flex-start;
`

const InfoConTopBottom = styled(InfoDefault)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-flow: column nowrap;
  `}
  p,
  a {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    margin: 0;
    line-height: 1.5;
    color: ${({ theme }) => theme.text1};
    ${({ theme }) => theme.mediaWidth.upToSmall`
      font-size: 16px;
      width: 100%;
      word-break: break-all;
    `}
  }
  a {
    color: #c42a61;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`

interface Props {
  isOpen: boolean
  onClose(): void
  padInfo: LaunchPad
  baseModal: Contract | null
  unlimitModal: Contract | null
  handleWithdrawSuccess?(): void
  userStakeAmount: BigNumber
}

export default function LaunchpadModal({
  isOpen,
  onClose,
  padInfo,
  baseModal,
  unlimitModal,
  userStakeAmount,
  handleWithdrawSuccess
}: Props) {
  const [withdrawInput, setWithdrawInput] = useState('')
  const withdrawInputAmount = useMemo(() => {
    return new BigNumber(withdrawInput || 0)
  }, [withdrawInput])

  const stakeContract = useMemo(() => {
    return padInfo.poolType === PadType.basic ? baseModal : unlimitModal
  }, [padInfo.poolType, baseModal, unlimitModal])

  const send = useSend()

  async function Unstake() {
    if (withdrawInputAmount.toNumber()) {
      send({
        contract: stakeContract,
        method: 'claim',
        params: [padInfo.pid, withdrawInputAmount.shiftedBy(padInfo.stakedToken.decimals).toFixed()],
        pendingText: `Unstaking ${withdrawInputAmount.toFixed()} ${padInfo.stakedToken.symbol}`,
        summary: `Unstake ${withdrawInputAmount.toFixed()} ${padInfo.stakedToken.symbol}`,
        onSuccess() {
          if (handleWithdrawSuccess) {
            handleWithdrawSuccess()
          }
          onClose()
        }
      })
    }
  }

  async function handleWithdrawMax() {
    setWithdrawInput(userStakeAmount.toFixed())
  }

  const isWithdrawInsufficienBalance = useMemo(() => {
    return withdrawInputAmount.isGreaterThan(userStakeAmount)
  }, [userStakeAmount, withdrawInputAmount])

  const disableWithdraw = useMemo(() => {
    return isWithdrawInsufficienBalance || !withdrawInputAmount.toNumber()
  }, [withdrawInputAmount, isWithdrawInsufficienBalance])

  return (
    <Modal isOpen={isOpen} onDismiss={onClose} minHeight={false} maxWidth={560} maxHeight={200}>
      <Wrapper>
        <LaunchpadSection>
          <CloseIcon onClick={onClose}>
            <CloseColor />
          </CloseIcon>
          <LaunchpadTitle>Withdraw Your {padInfo.stakedToken.symbol}</LaunchpadTitle>
          <LaunchpadCon>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <h3 className="launchpadConTitle">Withdraw</h3>
              <TYPE.white fontSize={14} lineHeight={'24px'}>
                Staked: {userStakeAmount.toFixed()} {padInfo.stakedToken.symbol}
              </TYPE.white>
            </div>
            <LaunchpadSolidCon>
              <div className="launchpadConLeft">
                <TokenImage address={padInfo.stakedToken.address} alt="tokenIcon" />
                <TYPE.white fontSize={14} lineHeight={'24px'} margin={'0 16px 0 12px'}>
                  {padInfo.stakedToken.symbol}
                </TYPE.white>
              </div>
              <TYPE.white fontSize={14} lineHeight={'24px'} style={{ flexGrow: 1 }}>
                <InputLine>
                  <Input
                    fullWidth
                    autoFocus={!isMobile}
                    placeholder="0.00"
                    type="number"
                    value={withdrawInput}
                    onChange={e => {
                      setWithdrawInput(e.currentTarget.value || '')
                    }}
                  />
                  <MaxBtn onClick={handleWithdrawMax}>Max</MaxBtn>
                </InputLine>
              </TYPE.white>
            </LaunchpadSolidCon>
            <InfoConTopBottom>
              {isWithdrawInsufficienBalance && (
                <p>
                  <TYPE.error fontSize={14} error={true}>
                    Insufficient Balance
                  </TYPE.error>
                </p>
              )}
            </InfoConTopBottom>
          </LaunchpadCon>
          <SmallBtnCon>
            <SmallConfirmButton disabled={disableWithdraw} onClick={Unstake}>
              Confirm
            </SmallConfirmButton>
          </SmallBtnCon>
        </LaunchpadSection>
      </Wrapper>
    </Modal>
  )
}
