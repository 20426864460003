import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { RowFixed, RowBetween } from 'components/Row'
import { useMedia } from 'react-use'
import { useGlobalData, useMetisPrice } from 'hooks/infos/GlobalData'
import { formattedNum, localNumber, Big } from 'utils/infos'

import NetPrice from '../NetPrice'
import { TYPE } from 'theme'

const Header = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
`

const Medium = styled.span`
  font-weight: 500;
`

export default function GlobalStats(props: { showAll?: boolean }) {
  const { t } = useTranslation()
  const { showAll } = props
  const below1295 = useMedia('(max-width: 1295px)')
  const below1180 = useMedia('(max-width: 1180px)')
  const below1024 = useMedia('(max-width: 1024px)')
  const below400 = useMedia('(max-width: 400px)')

  const [showPriceCard, setShowPriceCard] = useState(false)

  const { oneDayVolumeUSD, oneDayTxns, pairCount, txCount, userCount, totalVolumeUSD } = useGlobalData()
  const [metisPrice] = useMetisPrice()
  const formattedMetisPrice = metisPrice ? formattedNum(metisPrice, true) : '-'
  const oneDayFees = oneDayVolumeUSD ? formattedNum(oneDayVolumeUSD * 0.003, true) : ''

  const totalFees = totalVolumeUSD
    ? formattedNum(
        Big(totalVolumeUSD)
          .multipliedBy(0.003)
          .toNumber(),
        true
      )
    : ''

  return (
    <Header>
      <RowBetween>
        <RowFixed>
          {!below400 && (
            <TYPE.v2Main
              mr={'1rem'}
              onMouseEnter={() => {
                setShowPriceCard(true)
              }}
              onMouseLeave={() => {
                setShowPriceCard(false)
              }}
              style={{ position: 'relative' }}
            >
              METIS {t('analytics.price')}: <Medium>{formattedMetisPrice}</Medium>
              {showPriceCard && <NetPrice />}
            </TYPE.v2Main>
          )}

          {!below1180 && (
            <TYPE.v2Main mr={'1rem'}>
              Transactions (24H): <Medium>{localNumber(oneDayTxns)}</Medium>
            </TYPE.v2Main>
          )}
          {!below1024 && (
            <TYPE.v2Main mr={'1rem'}>
              {t('analytics.pairs')}: <Medium>{localNumber(pairCount)}</Medium>
            </TYPE.v2Main>
          )}
          {!below1295 && (
            <TYPE.v2Main mr={'1rem'}>
              {t('analytics.global_stats.fees')}: <Medium>{oneDayFees}</Medium>&nbsp;
            </TYPE.v2Main>
          )}

          {!below1024 && (
            <TYPE.v2Main mr={'1rem'}>
              {t('analytics.global_stats.totalVol')}: <Medium>{formattedNum(totalVolumeUSD, true)}</Medium>&nbsp;
            </TYPE.v2Main>
          )}

          {showAll && (
            <TYPE.v2Main mr={'1rem'}>
              {t('analytics.tx_count')}: <Medium>{txCount}</Medium>&nbsp;
            </TYPE.v2Main>
          )}

          {showAll && (
            <TYPE.v2Main mr={'1rem'}>
              {t('analytics.user_count')}: <Medium>{userCount}</Medium>&nbsp;
            </TYPE.v2Main>
          )}

          {showAll && (
            <TYPE.v2Main mr={'1rem'}>
              {t('analytics.total_fees')}: <Medium>{totalFees}</Medium>&nbsp;
            </TYPE.v2Main>
          )}
        </RowFixed>
      </RowBetween>
    </Header>
  )
}
