/* eslint-disable react/no-find-dom-node */
import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import useGetLiquitidyPools from 'state/data/hooks/LiquidityPool/useGetPoolList'
import { PairWithApr } from 'gql/subgraph/pairs'
import { TYPE } from 'theme'
import TokenImage from 'components/TokenImage'
import { findDOMNode } from 'react-dom'
// import useInterval from '../../hooks/useInterval'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 80px;
`

const Container = styled.div`
  max-width: 780px;
  margin: 0 auto;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 90%;
  `}
`

const InputContainer = styled.div`
  width: 100%;
  position: relative;
`

const SearchInput = styled.input`
  background: transparent;
  color: ${({ theme }) => theme.v2.t01};
  font-size: 26px;
  font-family: 'Poppins-Medium';
  font-weight: 500;
  line-height: 63px;
  width: 100%;
  height: 63px;
  border: none;
  out-line: none;

  &::placeholder {
    color: ${({ theme }) => theme.v2.t04};
  }

  &:focus-visible {
    outline: none;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `}
`

const ClearIcon = styled.i`
  width: 74px;
  height: 58px;
  background-color: ${({ theme }) => theme.v2.df02};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
  color: ${({ theme }) => theme.v2.t01};
  cursor: pointer;
`

const RecommendWrapper = styled.div`
  margin-top: 40px;
`

const RecommendTitle = styled(TYPE.title)`
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
`

const RecoList = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: flex-start;
  `}
`

const TokenLogo = styled(TokenImage)`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  position: relative;

  > img {
    width: 32px;
    height: 32px;
  }
`

const PairName = styled(TYPE.mainLg)`
  font-size: 16px;
  line-height: 28px;
`

const PairItem = styled.div`
  display: flex;
  padding: 10px 16px;
  color: ${({ theme }) => theme.v2.t01};

  ${TokenLogo} {
    z-index: 2;
  }

  .token-logo-last {
    z-index: 1;
    transform: translateX(-6px);
  }

  ${PairName} {
    margin-left: 6px;
  }
`

const RecoItem = styled(PairItem)`
  border-radius: 26px;
  background-color: ${({ theme }) => theme.v2.df02};
  margin-right: 20px;
  cursor: pointer;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-right: 0px;
    margin-bottom: 12px;
  `}
`

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 0;
  margin-top: 50px;
`

const MenuItem = styled(PairItem)`
  padding: 20px 22px;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.v2.df02};
  }
`

export default function Search() {
  const { pools: allPools } = useGetLiquitidyPools()
  const { t } = useTranslation()
  const history = useHistory()

  const [searchResults, setSearchResults] = useState<PairWithApr[]>([])
  const [recommends, setRecommends] = useState<PairWithApr[]>([])
  const [searchString, setSearchString] = useState<string>('')
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false)
  const searchRef = useRef<HTMLDivElement | null>(null)
  const resultsRef = useRef<HTMLDivElement | null>(null)
  const recommendsRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!searchString || !allPools || allPools.length === 0) {
      setSearchResults([])

      return
    }

    const filterResults = allPools
      .filter((pair) => {
        const token0Symbol = pair.token0.symbol.toLowerCase()
        const token1Symbol = pair.token1.symbol.toLowerCase()
        const token0Addr = pair.token0.id.toLowerCase()
        const token1Addr = pair.token1.id.toLowerCase()

        const searchStringSelects = [
          token0Symbol,
          token1Symbol,
          token0Addr,
          token1Addr,
          `${token0Symbol}/${token1Symbol}`,
          `${token1Symbol}/${token0Symbol}`,
        ]

        if (searchStringSelects.filter((item) => item.indexOf(searchString.toLowerCase()) > -1).length > 0) {
          return true
        } else {
          return false
        }
      })
      .sort((a, b) => b.lpApr - a.lpApr)

    console.log(searchString, filterResults)

    setSearchResults(filterResults.slice(0, 10))
  }, [searchString, allPools])

  useEffect(() => {
    if (allPools && allPools.length > 0) {
      const pairs = [...allPools].sort((a, b) => b.lpApr - a.lpApr)
      setRecommends(pairs.slice(0, 2))
    } else {
      setRecommends([])
    }
  }, [allPools])

  useEffect(() => {
    setShowSearchResults(searchResults.length > 0)
  }, [searchResults])

  function checkInRef(ref: React.MutableRefObject<HTMLDivElement | null>, event: React.MouseEvent<HTMLDivElement>) {
    if (ref.current) {
      const dom = findDOMNode(ref.current)

      return event.target === dom || dom?.contains(event.target as any)
    } else {
      return false
    }
  }

  function onPageClick(event: React.MouseEvent<HTMLDivElement>) {
    const checkRes = checkInRef(searchRef, event) || checkInRef(recommendsRef, event) || checkInRef(resultsRef, event)

    if (!checkRes) {
      history.goBack()
    }
  }

  function goSwapPair(token0Addr: string, token1Addr: string) {
    history.push(`/swap?inputCurrency=${token0Addr}&outputCurrency=${token1Addr}`)
  }

  return (
    <Wrapper onClick={onPageClick}>
      <Container>
        <InputContainer ref={searchRef}>
          <SearchInput
            onChange={(e) => {
              setSearchString(e.target.value)
            }}
            value={searchString}
            placeholder={t('search.inputPlaceholder')}
          />

          {showSearchResults && <ClearIcon className="iconfont icon-delete" onClick={() => setSearchString('')} />}
        </InputContainer>

        {!showSearchResults && (
          <RecommendWrapper ref={recommendsRef}>
            <RecommendTitle>{t('search.recommended')}</RecommendTitle>

            <RecoList>
              {recommends.map((item) => {
                return (
                  <RecoItem key={item.id} onClick={() => goSwapPair(item.token0.id, item.token1.id)}>
                    <TokenLogo address={item.token0.id} />
                    <TokenLogo className="token-logo-last" address={item.token1.id} />
                    <PairName>{`${item.token0.symbol}/${item.token1.symbol}`}</PairName>
                  </RecoItem>
                )
              })}
            </RecoList>
          </RecommendWrapper>
        )}

        {showSearchResults && (
          <MenuList ref={resultsRef}>
            {searchResults.map((item) => {
              return (
                <MenuItem key={item.id} onClick={() => goSwapPair(item.token0.id, item.token1.id)}>
                  <TokenLogo address={item.token0.id} />
                  <TokenLogo className="token-logo-last" address={item.token1.id} />
                  <PairName>{`${item.token0.symbol}/${item.token1.symbol}`}</PairName>
                </MenuItem>
              )
            })}
          </MenuList>
        )}
      </Container>
    </Wrapper>
  )
}
