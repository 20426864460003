import React from 'react'
import styled from 'styled-components'
import { useActiveWeb3React } from 'hooks'
import { useWalletModalToggle } from 'state/application/hooks'
import { useETHBalances } from '../../state/wallet/hooks'
import { ConnectWalletButtonInHeader } from 'components/Button'
import { useTranslation } from 'react-i18next'

import DefaultAccountAvatar from 'assets/images/default-account-avatar.png'
import { isEnvChain, NETWORK_CONFIG, isMetisNetwork, shortenAddress } from 'utils'
import { isMobile } from 'react-device-detect'

const WalletWrapper = styled.div`
  height: 40px;
  border-radius: 20px;
  background-color: ${({ theme }) => (theme.isDark ? '#313144' : '#f3f5ff')};
  font-size: 14px;
  font-family: 'Poppins-Medium';
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 0;
  overflow: hidden;
  line-height: 24px;
  cursor: pointer;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-shrink: 0;
  `};
`

const BalanceWrapper = styled.div`
  height: 40px;
  margin-right: 8px;
  padding-left: 14px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.v2.t01};
  text-align: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 10px;
    line-height: 1.2;
  `};
`

const AccountWrapper = styled.div`
  background: ${({ theme }) => theme.v2.bg08};
  color: ${({ theme }) => theme.v2.t01};
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 40px;
  border-radius: 20px;
  flex-shrink: 0;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 10px;
  `};
`

const AccountAvatar = styled.img`
  height: 32px;
  width: 32px;
  margin-left: 6px;
`

export default function ConnectWalletBtnInHeader() {
  const { account, chainId } = useActiveWeb3React()
  const { t } = useTranslation()
  const toggleWalletModal = useWalletModalToggle()
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']

  let ConnectBtn = (
    <ConnectWalletButtonInHeader onClick={toggleWalletModal}>
      {isMobile ? 'Connect' : t('wallet_btn.connect_to')}
    </ConnectWalletButtonInHeader>
  )

  if (account && chainId) {
    if (!isMetisNetwork(chainId)) {
      // wrong network
      ConnectBtn = (
        <ConnectWalletButtonInHeader className="error">{t('wallet_btn.wrong_network')}</ConnectWalletButtonInHeader>
      )
    } else {
      ConnectBtn = (
        <WalletWrapper onClick={toggleWalletModal}>
          <BalanceWrapper>
            {userEthBalance?.toSignificant(4)}{' '}
            {chainId && isEnvChain(chainId) ? NETWORK_CONFIG[chainId].tokenSymbol : 'ETH'}
          </BalanceWrapper>
          <AccountWrapper>
            {shortenAddress(account)} {!isMobile && <AccountAvatar src={DefaultAccountAvatar} alt="" />}
          </AccountWrapper>
        </WalletWrapper>
      )
    }
  }

  return ConnectBtn
}
