import { RowBetween, default as Row } from 'components/Row'
import { useActiveWeb3React } from 'hooks'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import BigNumber from 'bignumber.js'
import { Currency, METIS } from '@netswap/sdk'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import styled from 'styled-components'
import { TYPE, theme } from 'theme'
import { formattedPrice } from 'utils'
import { useTransition, animated } from 'react-spring'
import classNames from 'classnames'
import TokenImage from 'components/TokenImage'
import Loading from 'components/Loading'
import { useDarkModeManager } from 'state/user/hooks'
import { usePrevious } from 'react-use'
import { getAllCandlesByTokens, Candle, Pair, Token, PERIODS, Period } from 'gql/subgraph/dexcandles'
import { METIS_TOKEN } from '../../constants'
import useGetBasePriceToken from 'hooks/useGetBasePriceToken'
import Chart from './chart'

dayjs.extend(utc)

const Wrapper = styled(animated.div)<{ smallWidth?: boolean }>`
  width: ${({ smallWidth }) => (smallWidth ? '550px' : '740px')};

  border-radius: 16px;
  background-color: ${({ theme }) => theme.v2.bg02};
  box-sizing: border-box;
  padding: 18px 12px;
  height: 100%;
  transition: width 0.2s linear;
  position: relative;
`
const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

const PriceText = styled(TYPE.mainLg)`
  font-size: 32px;
  line-height: 56px;
`

const PairText = styled(TYPE.mainLg)`
  font-size: 28px;
  line-height: 50px;
  margin-left: 8px !important;
`

const PeriodList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  width: 100%;
  margin-bottom: 12px;
`

const PeriodItem = styled.span`
  font-size: 14px;
  font-family: 'Poppins-Medium';
  font-weight: 500;
  color: ${({ theme }) => theme.v2.t01};
  line-height: 24px;
  padding: 4px 16px;
  margin: 0 8px;
  cursor: pointer;

  &.active {
    background: ${({ theme }) => (theme.isDark ? '#1b1b30' : '#00cfff')};
    border-radius: 16px;
    color: ${({ theme }) => (theme.isDark ? '#6ae0fb' : theme.v2.t01)};
    cursor: default;
  }
`

const TokenLogo = styled(TokenImage)`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  position: relative;

  > img {
    width: 32px;
    height: 32px;
  }
`

const PairIconsGroup = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;

  ${TokenLogo} {
    z-index: 2;
  }

  .token-logo-last {
    z-index: 1;
    transform: translateX(-6px);
  }
`

const BasePriceTokenSwitch = styled.div`
  height: 30px;
  border-radius: 8px;
  background-color: ${({ theme }) => (theme.isDark ? '#020423' : '#1b1b30')};
  display: flex;
  align-items: center;
  padding: ${({ theme }) => (theme.isDark ? 0 : '2px')};
`

const SwitchItem = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 6px;
  font-size: 14px;
  font-family: 'Poppins-Medium';
  line-height: 24px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
  padding: 0 16px;

  &.active {
    background-color: #ffffff;
    color: ${({ theme }) => (theme.isDark ? '#000' : '#020423')};
  }
`

interface Props {
  className?: string
  show?: boolean
  smallWidth?: boolean
  fromToken?: Currency
  toToken?: Currency
}

function reverseCandlePrice(candles: Candle[]) {
  return candles.map((candle) => {
    return {
      ...candle,
      open: new BigNumber(1).div(candle.open).toString(),
      close: new BigNumber(1).div(candle.close).toString(),
      high: new BigNumber(1).div(candle.low).toString(),
      low: new BigNumber(1).div(candle.high).toString(),
    }
  })
}

export default function PairChart({ className, show, smallWidth, fromToken, toToken }: Props) {
  const { chainId } = useActiveWeb3React()
  const { t } = useTranslation()
  const [candles, setCandles] = useState<Candle[]>([])
  const [usdCandles, setUSDCandles] = useState<Candle[]>([])
  const [selectedPeriod, setSelectedPeriod] = useState<Period>(PERIODS[1])
  const prevPeriod = usePrevious<Period>(selectedPeriod)
  const [pair, setPair] = useState<Pair>()
  const [price, setPrice] = useState<number>(0)
  // const [priceChange, setPriceChange] = useState<number>(0)
  const [baseToken, setBaseToken] = useState<Token | null>(null)
  const [useUsdData, setUseUsdData] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(true)
  const [preTokens, setPreTokens] = useState<string[]>([])
  const [preBaseTokenAddr, setPreBaseTokenAddr] = useState<string>('')
  const [darkMode] = useDarkModeManager()
  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  const getBasePriceToken = useGetBasePriceToken()

  const transition = useTransition(show, null, {
    config: { duration: 200 },
    from: { opacity: 1, marginRight: '8px' },
    enter: { opacity: 1, marginRight: '8px' },
    leave: { opacity: 0, marginRight: '-278px' },
  })

  const updateCandles = useCallback(
    async (fromToken: string, toToken: string) => {
      if (!chainId) {
        return
      }
      let endTime = Math.floor(new Date().getTime() / 1000)

      const queryRes = await getAllCandlesByTokens({
        token0: fromToken,
        token1: toToken,
        endTime,
        limit: 1000,
        period: selectedPeriod.value,
      })

      // if pair include metis
      let candles = queryRes.candles
      let usdCandles = queryRes.usdCandles

      if (queryRes.pair) {
        const basePriceTokenAddr = getBasePriceToken({ fromToken, toToken })

        const basePriceToken =
          queryRes.pair.token0.id === basePriceTokenAddr ? queryRes.pair.token0 : queryRes.pair.token1

        if (basePriceToken.id !== queryRes.pair.token0.id) {
          candles = reverseCandlePrice(candles)
          usdCandles = reverseCandlePrice(usdCandles)
        }
        setBaseToken(basePriceToken)
      } else {
        setBaseToken(null)
      }

      setCandles(candles)
      setUSDCandles(usdCandles)
      setPair(queryRes.pair)
      setLoadingData(false)
    },
    [setCandles, setPair, selectedPeriod, setBaseToken, chainId, getBasePriceToken]
  )

  useEffect(() => {
    if (!fromToken || !toToken || !chainId) {
      setCandles([])
      return
    }

    let fromAddr: string = (fromToken as any).address
    let toAddr: string = (toToken as any).address

    if (fromToken === METIS) {
      fromAddr = METIS_TOKEN
    }

    if (toToken === METIS) {
      toAddr = METIS_TOKEN
    }

    const basePriceToken = getBasePriceToken({ fromToken: fromAddr, toToken: toAddr })
    if (prevPeriod === selectedPeriod) {
      if (preTokens.indexOf(toAddr) > -1 && preTokens.indexOf(fromAddr) > -1 && preBaseTokenAddr === basePriceToken) {
        return
      }
    }

    setLoadingData(true)
    setPreTokens([fromAddr, toAddr])
    setPreBaseTokenAddr(basePriceToken)
    updateCandles(fromAddr.toLowerCase(), toAddr.toLowerCase())
  }, [
    fromToken,
    toToken,
    selectedPeriod,
    prevPeriod,
    chainId,
    preTokens,
    preBaseTokenAddr,
    updateCandles,
    setPreTokens,
    getBasePriceToken,
    setPreBaseTokenAddr,
  ])

  useEffect(() => {
    let priceCandles = candles
    if (useUsdData) {
      priceCandles = usdCandles
    }
    if (priceCandles.length > 0) {
      setPrice(Number(priceCandles[priceCandles.length - 1].close))
    } else {
      setPrice(0)
    }
  }, [candles, usdCandles, setPrice, useUsdData])

  useEffect(() => {
    if (candles.length === 0 && usdCandles.length > 0) {
      setUseUsdData(true)
    } else {
      setUseUsdData(false)
    }
  }, [candles, usdCandles, setUseUsdData])

  // useEffect(() => {
  //   setLoadingData(true)
  // }, [fromToken, toToken, selectedPeriod, setLoadingData])

  function changePeriod(period: Period) {
    setSelectedPeriod(period)
  }

  return (
    <>
      {transition.map(
        ({ item, key, props }) =>
          item && (
            <Wrapper key={key} className={classNames(className)} style={props} smallWidth={smallWidth}>
              {!loadingData && (
                <RowBetween
                  style={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    paddingLeft: '20px',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <Header>
                    <Row style={{ flex: 1, alignItems: 'center' }}>
                      {pair && <PriceText>{formattedPrice(price, useUsdData)}</PriceText>}

                      <PairText>
                        {pair &&
                          `${pair.token0.id === baseToken?.id ? pair.token1.symbol : pair.token0.symbol}/${
                            baseToken?.symbol
                          }`}
                      </PairText>

                      {pair && (
                        <PairIconsGroup>
                          <TokenLogo address={pair.token0.id === baseToken?.id ? pair.token1.id : pair.token0.id} />
                          <TokenLogo className="token-logo-last" address={baseToken?.id} />
                        </PairIconsGroup>
                      )}
                    </Row>

                    {candles.length > 0 && usdCandles.length > 0 && (
                      <BasePriceTokenSwitch>
                        <SwitchItem className={useUsdData ? '' : 'active'} onClick={() => setUseUsdData(false)}>
                          {baseToken?.symbol}
                        </SwitchItem>
                        <SwitchItem className={useUsdData ? 'active' : ''} onClick={() => setUseUsdData(true)}>
                          USD
                        </SwitchItem>
                      </BasePriceTokenSwitch>
                    )}
                  </Header>

                  <Chart candles={useUsdData ? usdCandles : candles} usd={useUsdData} baseToken={baseToken} />

                  <PeriodList>
                    {PERIODS.map((period) => {
                      return (
                        <PeriodItem
                          key={period.key}
                          className={selectedPeriod.key === period.key ? 'active' : ''}
                          onClick={() => changePeriod(period)}
                        >
                          {t(period.key)}
                        </PeriodItem>
                      )
                    })}
                  </PeriodList>
                </RowBetween>
              )}

              <Loading show={loadingData} bgColor={themeObject.v2.bg02} />
            </Wrapper>
          )
      )}
    </>
  )
}
