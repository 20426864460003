import { ChainId } from '@netswap/sdk'
import BaseABI from './BaseModal.json'
import UnlimitABI from './UnlimitModal.json'

export const BaseModalConfig = {
  [ChainId.MAINNET]: {
    address: '0x846b62d627F07Fe5e2804a32Cd5A0cEc408B98f7',
    abi: BaseABI,
  },
  [ChainId.TESTNET]: {
    address: '0xc9bC74689147ae40E83fAaB4034495B32c99fa43',
    abi: BaseABI,
  },
  // need config
  [ChainId.TESTNET_SEPOLIA]: {
    address: '0xc9bC74689147ae40E83fAaB4034495B32c99fa43',
    abi: BaseABI,
  },
}

export const UnlimitModalConfig = {
  [ChainId.MAINNET]: {
    address: '0x7b32AcDa3EfAf549e4FCa0F898CF280B40Ba3342',
    abi: UnlimitABI,
  },
  [ChainId.TESTNET]: {
    address: '0x99366FcC2609F15A360C68F9DDF923d6A1cE88bd',
    abi: UnlimitABI,
  },
  // need config
  [ChainId.TESTNET_SEPOLIA]: {
    address: '0x99366FcC2609F15A360C68F9DDF923d6A1cE88bd',
    abi: UnlimitABI,
  },
}
