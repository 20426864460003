import { ValueFormat } from 'components/Value'
import React from 'react'
import { useNettToggle } from 'state/application/hooks'
import { useFormatSymbolTokenPrice } from 'state/data/hooks/useTokenPrice'
import styled from 'styled-components'
const NETTPriceWrapper = styled.div`
  height: 40px;
  border-radius: 20px;
  background: ${({ theme }) => theme.v2.bg08};
  color: ${({ theme }) => theme.v2.t01};
  display: flex;
  align-items: center;
  padding: 0 16px;
  justify-content: center;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-right: 16px;
  cursor: pointer;

  > img {
    height: 28px;
    width: 28px;
    margin-right: 5px;
  }
`

interface Props {
  className?: string
}

export default function NettPrice({ className }: Props) {
  const toggleNett = useNettToggle()
  const tokens = useFormatSymbolTokenPrice()
  return (
    <NETTPriceWrapper onClick={toggleNett} className={className}>
      <img src={require('assets/images/nett-token-with-circle.png')} alt="" />

      {tokens?.nett ? (
        <>
          $<ValueFormat value={tokens.nett.price} />
        </>
      ) : (
        '--'
      )}
    </NETTPriceWrapper>
  )
}
