import React from 'react'
import styled from 'styled-components'
import { useDarkModeManager } from 'state/user/hooks'
import classnames from 'classnames'

const Wrapper = styled.div`
  height: 40px;
  width: 56px;
  border-radius: 20px;
  background-color: ${({ theme }) => (theme.isDark ? '#05071e' : '#f1f1f3')};
  color: #00cfff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 16px;

  .iconfont {
    font-size: 24px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0 8px;
    flex-shrink: 0;
  `}
`

export default function ThemeButton() {
  const [darkMode, toggleDarkMode] = useDarkModeManager()

  return (
    <Wrapper onClick={() => toggleDarkMode()}>
      <i className={classnames('iconfont', darkMode ? 'icon-light' : 'icon-dark')} />
    </Wrapper>
  )
}
