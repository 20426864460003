import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { Typography } from '@material-ui/core'

import TooltipInfo from 'components/TooltipInfo'

export const AboutScore = () => {
  const SmallSectionTitle = styled(Typography)`
    font-size: 15px;
    width: 100%;
    margin-bottom: 10px !important;
    display: flex;
    align-items: center;
    font-family: 'Inter var', sans-serif !important;
  `

  const AboutRewardParent = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
  `
  const AboutRewardChild = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 4px;
    background: ${({ theme }) => theme.v2.bg01};
    align-items: flex-start;
    padding: 20px 30px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      height: 100%;
      padding: 10px;
    `}
  `
  return (
    <AboutRewardParent>
      <AboutRewardChild>
        <SmallSectionTitle>
          Swap Pair & Liquidity
          <TooltipInfo
            text={'You will get score when you swap or provide liquidity for the following Token pairs. '}
            style={{ marginLeft: '12px' }}
          />
        </SmallSectionTitle>

        <TokenPoolContainer />
      </AboutRewardChild>
    </AboutRewardParent>
  )
}

interface TokenListResponse {
  name: string
  pairs: Pair[]
}
interface Pair {
  label: string
  token0: string
  token1: string
  token0Address: string
  token1Address: string
  pairAddress: string
  token0Logo: string
  token1Logo: string
  coefficient: number
}
export function TokenPoolContainer() {
  const [tokenList, setTokenList] = useState<TokenListResponse>(null as any)
  const showTokenList = useMemo(() => {
    return tokenList
  }, [tokenList])
  useEffect(() => {
    axios
      .get<TokenListResponse>('https://raw.githubusercontent.com/Netswap/token-lists/master/score.list.json')
      .then(resp => {
        resp.data && setTokenList(resp.data)
      })
      .catch(e => {
        setTokenList(null as any)
        console.log(e)
      })
  }, [])
  return (
    <>
      {showTokenList &&
        showTokenList.pairs
          .sort((a: { coefficient: number }, b: { coefficient: number }) => b.coefficient - a.coefficient)
          .map((item, idx) => <PoolTokenItem key={idx} {...item} />)}
    </>
  )
}

const AboutRewardCon = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 49%;
  height: 56px;
  background: ${({ theme }) => theme.v2.bg02};
  border-radius: 8px;
  margin-bottom: 5px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
      min-width: 100%;
      margin-bottom: 8px;
    `}
`
const AboutRewardIcon = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`
const AboutRewardCoefficient = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.v2.t01};
  line-height: 56px;
  padding-right: 16px;
`
const AboutRewardImg = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 12px;
  background-size: 100% 100%;
  margin: 0 10px;
  z-index: 999;

  img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }
`
const AboutRewardImgDouble = styled(AboutRewardImg)`
  margin-left: -20px;
`
export function PoolTokenItem({ label, token0, token1, token0Logo, token1Logo, coefficient }: Pair) {
  return (
    <AboutRewardCon>
      <AboutRewardIcon>
        <AboutRewardImg>
          <img src={token0Logo} alt={token0} />
        </AboutRewardImg>
        <AboutRewardImgDouble>
          <img src={token1Logo} alt={token1} />
        </AboutRewardImgDouble>
        {label}
      </AboutRewardIcon>
      <AboutRewardCoefficient>{coefficient}</AboutRewardCoefficient>
    </AboutRewardCon>
  )
}
