import React from 'react'
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'

import Overview from './overview'
import PairsList from './Pair/list'
import PairDetail from './Pair/detail'
import TokensList from './Token/list'
import TokenDetail from './Token/detail'
import AccountsList from './Account/list'
import AccountDetail from './Account/detail'

import Search from 'components/Infos/Search'
import { TokenIcon, OverviewIcon, PairsIcon, AccountsIcon } from 'components/Infos/Icons'

import { useHeaderHeight } from 'state/application/hooks'
import GlobalDataContextProvider from 'hooks/infos/GlobalData'
import ApplicationContextProvider from 'hooks/infos/Application'
import PairDataContextProvider, { Updater as PairDataContextUpdater } from 'hooks/infos/PairData'
import TokenDataContextProvider, { Updater as TokenDataContextUpdater } from 'hooks/infos/TokenData'
import UserContextProvider from 'hooks/infos/User'
import LocalStorageContextProvider, { Updater as LocalStorageContextUpdater } from 'hooks/infos/LocalStorage'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

const Header = styled.div<{ outerHeaderHeight: number }>`
  width: 100%;
  height: 54px;
  background-color: #473ebc;
  position: fixed;
  top: ${({ outerHeaderHeight }) => outerHeaderHeight}px;
  left: 0;
  z-index: 3;
`

const Tabs = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  height: 54px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`

const TabItem = styled.div<{ active: boolean }>`
  height: 100%;
  position: relative;
  padding: 0 40px;
  display: flex;
  align-items: center;
  color: ${({ active }) => (active ? '#00cfff' : '#b5b1e4')};
  font-family: 'Poppins-Medium';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;

  .tab-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  &:after {
    content: '';
    width: 100%;
    height: 4px;
    background: #00cfff;
    bottom: 0;
    left: 0;
    position: absolute;
    border-radius: 1px;
    display: ${(props) => (props.active ? 'block' : 'none')};
  }

  $:hover {
    color: #00cfff;

    $:after {
      display: block;
    }
  }
`

const SearchContainer = styled.div`
  position: absolute;
  right: 10%;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
`

const Body = styled.div`
  padding-top: 23px;
`

function ContextProviders({ children }: { children: React.ReactChildren | React.ReactNode }) {
  return (
    <LocalStorageContextProvider>
      <ApplicationContextProvider>
        <TokenDataContextProvider>
          <GlobalDataContextProvider>
            <PairDataContextProvider>
              <UserContextProvider>{children}</UserContextProvider>
            </PairDataContextProvider>
          </GlobalDataContextProvider>
        </TokenDataContextProvider>
      </ApplicationContextProvider>
    </LocalStorageContextProvider>
  )
}

function Updaters() {
  return (
    <>
      <LocalStorageContextUpdater />
      <PairDataContextUpdater />
      <TokenDataContextUpdater />
    </>
  )
}

export default function Analytics() {
  const history = useHistory()
  const location = useLocation()
  const outerHeaderHeight: number = useHeaderHeight()

  return (
    <ContextProviders>
      <Updaters />
      <Wrapper>
        <Header outerHeaderHeight={outerHeaderHeight}>
          <Container maxWidth="md">
            <Tabs>
              <TabItem
                onClick={() => history.push('/analytics/overview')}
                active={location.pathname.indexOf('/analytics/overview') > -1}
              >
                <OverviewIcon className="tab-icon" />
                Overview
              </TabItem>

              <TabItem
                onClick={() => history.push('/analytics/tokens')}
                active={location.pathname.indexOf('/analytics/tokens') > -1}
              >
                <TokenIcon className="tab-icon" />
                Tokens
              </TabItem>

              <TabItem
                onClick={() => history.push('/analytics/pairs')}
                active={location.pathname.indexOf('/analytics/pairs') > -1}
              >
                <PairsIcon className="tab-icon" />
                Pairs
              </TabItem>

              <TabItem
                onClick={() => history.push('/analytics/accounts')}
                active={location.pathname.indexOf('/analytics/accounts') > -1}
              >
                <AccountsIcon className="tab-icon" />
                Accounts
              </TabItem>
            </Tabs>
          </Container>

          <SearchContainer>
            <Search small={true} />
          </SearchContainer>
        </Header>

        <Body>
          <Switch>
            <Route exact strict path="/analytics/overview">
              <Overview />
            </Route>

            <Route exact strict path="/analytics/pairs">
              <PairsList />
            </Route>

            <Route exact strict path="/analytics/tokens">
              <TokensList />
            </Route>

            <Route exact strict path="/analytics/accounts">
              <AccountsList />
            </Route>

            <Route exact strict path="/analytics/pairs/:pairAddress">
              <PairDetail />
            </Route>

            <Route exact strict path="/analytics/tokens/:address">
              <TokenDetail />
            </Route>

            <Route exact strict path="/analytics/accounts/:account">
              <AccountDetail />
            </Route>

            <Route exact path="/analytics">
              <Redirect to="/analytics/overview" />
            </Route>
          </Switch>
        </Body>
      </Wrapper>
    </ContextProviders>
  )
}
