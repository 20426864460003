import React from 'react'
import Modal, { Props } from './modal'
import { ButtonMetis } from '../Button'
import { TYPE } from 'theme'
import { makeStyles, createStyles } from '@material-ui/core'

interface CustomProps extends Props {
  type?: 'success' | 'error' | 'sorry' | 'fail' | 'congratulations' | 'info'
  title?: string
  text?: string
  buttonText?: string
  textAlign?: 'center' | 'left' | 'right'
  hideClose?: boolean
  disableBackgroundClick?: boolean
  onConfirm?(): void
  onCancel?(): void
}

const ModalTitle = {
  success: 'Success!',
  error: 'Something went wrong!',
  sorry: 'Sorry',
  fail: 'Sorry',
  congratulations: 'Congratulations',
  info: 'Title'
}

const ButtonText = {
  success: 'Next',
  error: 'Restart',
  sorry: 'Back to square',
  fail: 'Back to square',
  congratulations: 'Next',
  info: 'desc'
}

const useStyles = makeStyles(() =>
  createStyles({
    customWrapper: {
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: '20px',
      overflow: 'hidden',
      maxWidth: '720px'
    },
    footer: {
      width: '100%',
      marginTop: '20px',
      paddingBottom: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    }
  })
)

export default function CustomModal({
  isOpen,
  type = 'success',
  onClose,
  title,
  text,
  textAlign = 'center',
  buttonText,
  onConfirm,
  hideClose,
  disableBackgroundClick,
  onCancel
}: CustomProps) {
  const style = useStyles()
  title = title || ModalTitle[type]
  buttonText = buttonText || ButtonText[type]
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onCancel}
      hideClose={hideClose}
      disableBackgroundClick={disableBackgroundClick}
    >
      <div className={style.customWrapper}>
        <TYPE.body color="#fff" fontSize={24} fontWeight={600} textAlign="center">
          <span
            dangerouslySetInnerHTML={{
              __html: title
            }}
          />
        </TYPE.body>
        {text && (
          <TYPE.body color="#63D7CC" fontSize={18} textAlign={textAlign} marginTop="2rem">
            <span
              dangerouslySetInnerHTML={{
                __html: text
              }}
            />
          </TYPE.body>
        )}
        <div className={style.footer}>
          <ButtonMetis
            width="260px"
            onClick={() => {
              onConfirm && onConfirm()
              onClose && onClose()
            }}
            size="middle"
          >
            {buttonText}
          </ButtonMetis>
        </div>
      </div>
    </Modal>
  )
}
