import ABI from '../abis/veNett.json'
import { ChainId } from '@netswap/sdk'

export const VeNETTConfig = {
  [ChainId.MAINNET]: {
    address: '0x6535Db9e8ba2C4304fE45B25D866B37346cC2BD5',
    abi: ABI,
  },
  [ChainId.TESTNET]: {
    address: '0xCf040dAb3AD9e587e1E73496eeF1b784B0afb46d',
    abi: ABI,
  },
  // need config
  [ChainId.TESTNET_SEPOLIA]: {
    address: '0x02c348b0563901EA75C0Bc04c670b57D409481Ea',
    abi: ABI,
  },
}
