import { gql } from '@apollo/client'
import { LaunchpadV2Subgraph } from '../index'

interface Token {
  symbol: string
  name: string
  id: string
  decimals: number
}

export enum PadStatus {
  Success = 'Success',
  Fail = 'Fail'
}

export enum PadType {
  primary = 1,
  unlimited = 2
}

export interface LaunchPadV2 {
  id: string
  padType: PadType
  depositStart: number
  depositEnd: number
  launchTime: number
  issuedToken: Token
  issuedTokenAmount: number
  paymentToken: Token
  targetRaised: number
  paidAmountBeforeLaunch: number
  price: number
  stopped: boolean
  participatedUserCount: number
  minDeposit: number
  USDPerWNETT: number
}

const CORE_PADV2_FIELDS = gql`
  fragment CorePadV2 on Pad {
    id
    padType
    depositStart
    depositEnd
    launchTime
    issuedToken {
      name
      symbol
      decimals
      id
    }
    issuedTokenAmount
    paymentToken {
      name
      symbol
      decimals
      id
    }
    targetRaised
    paidAmountBeforeLaunch
    price
    stopped
    participatedUserCount
    USDPerWNETT
    minDeposit
  }
`

function formatePad(item: LaunchPadV2) {
  return {
    ...item,
    launchTime: item.launchTime * 1000,
    depositStart: item.depositStart * 1000,
    depositEnd: item.depositEnd * 1000,
    paidAmountBeforeLaunch: Number(item.paidAmountBeforeLaunch),
    price: Number(item.price),
    targetRaised: Number(item.targetRaised),
    issuedTokenAmount: Number(item.issuedTokenAmount)
  }
}

export default function getV2Pads(): Promise<LaunchPadV2[] | undefined> {
  // if (!name) return Promise.resolve([]);
  return new Promise(resolve => {
    LaunchpadV2Subgraph()
      .query<{
        pads: LaunchPadV2[]
      }>({
        query: gql`
          ${CORE_PADV2_FIELDS}
          query pads {
            pads(skip: 0, limit: 100, OrderDirection: desc) {
              ...CorePadV2
            }
          }
        `,
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        let returnData = undefined
        let { data } = res
        if (data?.pads) {
          returnData = data.pads.map(item => formatePad(item))
        }
        resolve(returnData)
      })
      .catch((e: any) => {
        console.error(e)
        resolve(undefined)
      })
  })
}

export function getPadById(id: string): Promise<LaunchPadV2 | undefined> {
  // if (!name) return Promise.resolve([]);
  return new Promise(resolve => {
    LaunchpadV2Subgraph()
      .query<{
        pad: LaunchPadV2
      }>({
        query: gql`
          ${CORE_PADV2_FIELDS}
          query pad($id: ID!) {
            pad(id: $id) {
              ...CorePadV2
            }
          }
        `,
        variables: {
          id: id.toLowerCase()
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        let returnData = undefined
        let { data } = res
        if (data?.pad) {
          returnData = formatePad(data.pad)
          resolve(returnData)
        }
      })
      .catch((e: any) => {
        console.error(e)
        resolve(undefined)
      })
  })
}

export function getUserPads(user: string): Promise<string[]> {
  return new Promise(resolve => {
    LaunchpadV2Subgraph()
      .query<{
        userPadPositions: {
          pad: {
            id: string
          }
        }[]
      }>({
        query: gql`
          query userPadPositions($user: String) {
            userPadPositions(where: { user: $user, paidAmount_gt: 0 }, orderDirection: asc) {
              pad {
                id
              }
            }
          }
        `,
        variables: {
          user: user.toLowerCase()
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        let returnData = []
        let { data } = res
        if (data?.userPadPositions.length) {
          returnData = data.userPadPositions.map(item => item.pad.id.toLowerCase())
          resolve(returnData)
        }
      })
      .catch((e: any) => {
        console.error(e)
        resolve([])
      })
  })
}
