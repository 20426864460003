import React from 'react'
import { useTranslation } from 'react-i18next'
import { saveAs } from 'file-saver'
import styled from 'styled-components'

import Container from '@material-ui/core/Container'
import BgSrc from 'assets/images/landing_page/footer_bg.png'
import { TYPE } from 'theme'

const InnerWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 70px 388px 80px 80px;

  background-image: url(${BgSrc});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 80px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    padding: 70px 0 16px;
    background-position: bottom;
    margin-top: 0;
  `}
`

const Group = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 20px;
    flex: none;
    width: 100%;
  `}
`

const GroupTitle = styled(TYPE.title)`
  font-size: 16px;
  line-height: 26px;
  color: ${({ theme }) => (theme.isDark ? '#00cfff' : '#4843fb')};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    line-height: 18px;
  `}
`

const GroupLink = styled.a`
  text-decoration: none;
  font-family: 'Poppins-Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  margin-top: 26px;
  color: ${({ theme }) => theme.v2.t01};
  width: fit-content;

  &:hover {
    opacity: 0.7;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    line-height: 18px;
    margin-top: 12px;
  `}
`

export default function Footer() {
  const { t } = useTranslation()

  const handleDownload = () => {
    saveAs('/static/files/NetSwap_audit.pdf', 'NetSwap_audit.pdf')
  }

  return (
    // <Wrapper>
    <Container maxWidth="lg">
      <InnerWrapper>
        <Group>
          <GroupTitle>{t('home.footer.about')}</GroupTitle>

          {/* <GroupLink>{t('home.footer.brand')}</GroupLink>

          <GroupLink>{t('home.footer.community')}</GroupLink>

          <GroupLink>{t('home.footer.swap_token')}</GroupLink> */}
          <GroupLink href="https://docs.netswap.io/" target="__blank">
            Docs
          </GroupLink>

          <GroupLink href="https://medium.com/@netswapofficial/netswap-litepaper-9edf2bd5372" target="__blank">
            Litepaper
          </GroupLink>

          <GroupLink href="https://t.me/Netswap_Announcement_CH" target="__blank">
            TG Channel
          </GroupLink>
        </Group>

        {/* <Group>
          <GroupTitle>{t('home.footer.help')}</GroupTitle>
          <GroupLink>{t('home.footer.customer')}</GroupLink>
          <GroupLink>{t('home.footer.troubleshooting')}</GroupLink>
          <GroupLink>{t('home.footer.guides')}</GroupLink>
        </Group> */}

        <Group>
          <GroupTitle>{t('home.footer.developers')}</GroupTitle>
          <GroupLink href="https://github.com/netswap" target="__blank">
            {t('home.footer.github')}
          </GroupLink>

          <GroupLink href="#" onClick={handleDownload}>
            {t('home.footer.audit')}
          </GroupLink>
          {/* <GroupLink>{t('home.footer.documentation')}</GroupLink>
          <GroupLink>{t('home.footer.bug_bounty')}</GroupLink>
          <GroupLink>{t('home.footer.careers')}</GroupLink> */}
        </Group>
      </InnerWrapper>
    </Container>
    // </Wrapper>
  )
}
