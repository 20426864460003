/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useMemo, useState } from 'react'
// import styled from 'styled-components'
import Title from './components/Title'
import BigNumber from 'bignumber.js'
import useSend from 'state/contract/hooks/useSend'
import { useActiveWeb3React } from 'hooks'
import { NETWORK_CHAIN_ID } from 'connectors'
import { ChainId } from '@netswap/sdk'
import { getTokenImage } from 'utils'
import { WrappedTokenInfo } from 'state/lists/hooks'
import StakeNett from './components/StakeNett'
import Numeral from 'numeral'
import useGetWNettInfo from './hooks/useGetwNettInfo'

export default function WNettStaking() {
  const { account, chainId } = useActiveWeb3React()
  const [wNETTPerSec, setWNETTPerSec] = useState(new BigNumber(0))

  const [pendingWNett, setPendingWNett] = useState(new BigNumber(0))
  const [userwNettBalance, setUserWNettBalance] = useState(new BigNumber(0))
  const send = useSend()
  const {
    totalStaked,
    totalSupply,
    userStakeAmount,
    stakeContract,
    wNettContract,
    getUserInfo,
    getTotalStakedNett,
    getTotalSupply,
    totalStakedUSD,
    youStakedUSD
  } = useGetWNettInfo()

  async function getBalance(contract: any) {
    if (contract && account) {
      try {
        const res = await contract.balanceOf(account)
        return res
      } catch (e) {
        console.error(e)
      }
    }
  }

  async function getUserWNettBalance() {
    if (wNettContract) {
      const res = await getBalance(wNettContract)

      setUserWNettBalance(res?.toString() ? new BigNumber(res.toString()).shiftedBy(-18) : new BigNumber(0))
    }
  }

  async function getWNETTPerSec() {
    if (stakeContract) {
      const res = await stakeContract.wNETTPerSec()
      if (res) {
        setWNETTPerSec(res?.toString() ? new BigNumber(res.toString()).shiftedBy(-18) : new BigNumber(0))
      }
    }
  }

  useEffect(() => {
    getWNETTPerSec()
  }, [stakeContract])

  const wNettPerDay = useMemo(() => {
    return wNETTPerSec.multipliedBy(86400).toNumber()
  }, [wNETTPerSec])

  useEffect(() => {
    let interval: any
    if (wNettContract && account) {
      getUserWNettBalance()

      interval = setInterval(() => {
        getUserWNettBalance()
      }, 30000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [wNettContract, account])

  async function stake({ inputAmount, onSuccess }: { inputAmount: BigNumber; onSuccess?: () => void }) {
    if (stakeContract) {
      await send({
        contract: stakeContract,
        method: 'deposit',
        params: [inputAmount.shiftedBy(18).toFixed(0)],
        pendingText: `Staking ${inputAmount.toFixed()} NETT`,
        summary: `Staked ${inputAmount.toFixed()} NETT`,
        onSuccess() {
          getUserInfo()

          getUserWNettBalance()
          getPendingWNett()
          getTotalSupply()
          getTotalStakedNett()
          if (onSuccess) {
            onSuccess()
          }
        }
      })
    }
  }

  async function unStake({ inputAmount, onSuccess }: { inputAmount: BigNumber; onSuccess?: () => void }) {
    if (userStakeAmount.isGreaterThan(0) && inputAmount.isGreaterThan(0)) {
      send({
        contract: stakeContract,
        method: 'withdraw',
        params: [inputAmount.shiftedBy(18).toFixed()],
        pendingText: `Unstaking ${inputAmount.toFixed()} NETT`,
        summary: `Unstake ${inputAmount.toFixed()} NETT`,
        onSuccess() {
          getUserInfo()

          getUserWNettBalance()
          getPendingWNett()
          getTotalSupply()
          getTotalStakedNett()
          if (onSuccess) {
            onSuccess()
          }
        }
      })
    }
  }

  async function claimPendingWNett({ inputAmount, onSuccess }: { inputAmount: BigNumber; onSuccess?: () => void }) {
    if (pendingWNett.isGreaterThan(0)) {
      send({
        contract: stakeContract,
        method: 'withdraw',
        params: ['0'],
        pendingText: `Claim pending wNETT`,
        summary: `Claimed pending wNETT`,
        addTokenToMetamask: {
          chainId: chainId || (NETWORK_CHAIN_ID as ChainId),
          name: 'wNETT',
          symbol: 'wNETT',
          address: wNettContract?.address || '',
          decimals: 18,
          logoURI: getTokenImage(wNettContract?.address || '')
        } as WrappedTokenInfo,
        onSuccess() {
          getUserWNettBalance()
          getPendingWNett()
          getTotalSupply()
          if (onSuccess) {
            onSuccess()
          }
        }
      })
    }
  }

  async function getPendingWNett() {
    try {
      if (account && stakeContract) {
        const res = await stakeContract.pendingWNETT(account)

        if (res && res.toString()) {
          setPendingWNett(new BigNumber(res.toString()).shiftedBy(-18))
        } else {
          setPendingWNett(new BigNumber(0))
        }
      }
    } catch (e) {
      console.error(e)
      console.error('get pending wnett error')
    }
  }

  useEffect(() => {
    let interval: any
    if (stakeContract && account) {
      getPendingWNett()

      interval = setInterval(() => {
        getPendingWNett()
      }, 15000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [stakeContract, account])

  const userRewardPerDay = useMemo(() => {
    if (totalStaked.isGreaterThan(0)) {
      return userStakeAmount
        .dividedBy(totalStaked)
        .multipliedBy(wNettPerDay)
        .toNumber()
    }
    return 0
  }, [userStakeAmount, totalStaked, wNettPerDay])

  return (
    <>
      <Title
        title="Earn wNETT"
        desc="Accrue wNETT as the ticket to participate in future Launchpad events."
        logo={require('assets/images/stake/v2/wNett-logo.png')}
      />
      <StakeNett
        type="wNETT"
        tvl={Numeral(totalStakedUSD).format('$ 0,0')}
        stakeBalance={userStakeAmount}
        stakeTokenUSD={Numeral(youStakedUSD).format('$ 0,0.[00]')}
        rewardToken={{
          symbol: 'wNETT',
          name: 'wNETT Token',
          decimals: 18,
          address: wNettContract?.address || ''
        }}
        unclaimedRewardBalace={pendingWNett}
        approveAddress={stakeContract?.address || ''}
        stake={stake}
        unStake={unStake}
        userRewardPerDay={userRewardPerDay}
        claimPendingRewardToken={claimPendingWNett}
        totalRewardTokenSupply={Numeral(totalSupply).format('0,0')}
        userRewardTokenBalance={userwNettBalance}
      />
    </>
  )
}
