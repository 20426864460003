import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { TYPE } from 'theme'
import { PageWrapper, FullWrapper } from 'components/Infos/index'
import Panel from 'components/Infos/Panel'
import LPList from 'components/Infos/LPList'
import styled from 'styled-components'
import AccountSearch from 'components/Infos/AccountSearch'
import { useTopLps } from 'hooks/infos/GlobalData'
import LocalLoader from 'components/Loading'
import { RowBetween } from 'components/Row'

const AccountWrapper = styled.div`
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`

function AccountLookup() {
  const { t } = useTranslation()
  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const topLps = useTopLps()

  return (
    <PageWrapper>
      <FullWrapper>
        <RowBetween>
          <TYPE.title fontSize={26}>{t('analytics.account_lookup.title')}</TYPE.title>
        </RowBetween>
        <AccountWrapper>
          <AccountSearch />
        </AccountWrapper>
        <TYPE.title fontSize={16} style={{ marginTop: '2rem' }}>
          {t('analytics.account_lookup.top_liquidity_positions')}
        </TYPE.title>
        <Panel>
          {topLps && topLps.length > 0 ? <LPList lps={topLps} maxItems={50} /> : <LocalLoader show={true} />}
        </Panel>
      </FullWrapper>
    </PageWrapper>
  )
}

export default withRouter(AccountLookup)
