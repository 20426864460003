import React, { useState, useCallback, useRef, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { transparentize } from 'polished'
import Portal from '@reach/portal'
import { usePopper } from 'react-popper'
const PopoverContainer = styled.div<{ show: boolean }>`
  z-index: 9999;

  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  opacity: ${props => (props.show ? 1 : 0)};
  transition: visibility 150ms linear, opacity 150ms linear;

  background: ${({ theme }) => theme.bg2};
  box-shadow: 0 4px 8px 0 ${({ theme }) => transparentize(0.9, theme.shadow1)};
  color: ${({ theme }) => theme.text2};
  border-radius: 8px;
`

const ReferenceElement = styled.div`
  display: inline-block;
  height: 100%;
`

const MenuItemText = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: 'Poppins-Medium';

  color: ${({ theme }) => theme.v2.t01};
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;

  &:hover {
    opacity: 0.6;
  }

  &.active {
    color: #5347ff;
  }

  > .iconfont {
    margin-left: 10px;
    font-size: 8px;
    padding-top: 2px;
  }
`

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 16px;
  background: ${({ theme }) => theme.v2.headerBg};
`

const MenuItemLink = styled(NavLink)`
  margin: 8px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: 'Poppins-Medium';

  color: ${({ theme }) => theme.v2.t01};
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.6;
  }

  &.active {
    color: #5347ff;
    font-weight: 600;
  }
`

const MenuItemOutLink = styled.a`
  margin: 8px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: 'Poppins-Medium';

  color: ${({ theme }) => theme.v2.t01};
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.6;
  }

  &.active {
    color: #5347ff;
    font-weight: 600;
  }
`

export type Menu = {
  text: string
  link: string
  key: string
  outlink?: boolean
}

export type SecondMenuProps = {
  menus: Menu[]
  children: React.ReactNode
}

function Menus(props: { menus: Menu[] }) {
  const { t } = useTranslation()
  return (
    <MenuList>
      {props.menus.map(menu => {
        return !menu.outlink ? (
          <MenuItemLink key={menu.key} to={menu.link}>
            {t(menu.text)}
          </MenuItemLink>
        ) : (
          <MenuItemOutLink key={menu.key} href={menu.link} target="__blank">
            {t(menu.text)}
          </MenuItemOutLink>
        )
      })}
    </MenuList>
  )
}

export default function SecondMenu(props: SecondMenuProps) {
  const [showMenus, setShowMenus] = useState<boolean>(false)
  const referRef = useRef<HTMLDivElement | null>(null)
  const popperRef = useRef<HTMLDivElement | null>(null)
  const { styles, update, attributes } = usePopper(referRef.current, popperRef.current, {
    placement: 'bottom',
    strategy: 'fixed',
    modifiers: [{ name: 'offset', options: { offset: [0, 0] } }]
  })
  const updateCallback = useCallback(() => {
    update && update()
  }, [update])

  useEffect(() => {
    const timeout = setTimeout(updateCallback, showMenus ? 1000 : 2000)

    return () => clearTimeout(timeout)
  }, [showMenus, updateCallback])

  return (
    <>
      <ReferenceElement ref={referRef}>
        <MenuItemText onMouseOver={() => setShowMenus(true)} onMouseLeave={() => setShowMenus(false)}>
          {props.children}
        </MenuItemText>
      </ReferenceElement>
      <Portal>
        <PopoverContainer show={showMenus} ref={popperRef} style={styles.popper} {...attributes.popper}>
          <div onMouseLeave={() => setShowMenus(false)} onMouseOver={() => setShowMenus(true)}>
            <Menus menus={props.menus} />
          </div>
        </PopoverContainer>
      </Portal>
    </>
  )
}
