/* eslint-disable react-hooks/exhaustive-deps */
import BigNumber from 'bignumber.js'
import { useEffect, useMemo, useState } from 'react'
import ERC20_ABI from 'constants/abis/erc20.json'
import { useContractByAbiAddr } from './useContract'
import { useActiveWeb3React } from 'hooks'
import useSend from 'state/contract/hooks/useSend'
import { ethers } from 'ethers'

export default function useTokenApprove(
  token: {
    symbol: string
    address: string
    decimals?: number
  },
  spender: string,
  amount: BigNumber,
  interval?: number
) {
  const { symbol, address, decimals } = token
  const tokenContract = useContractByAbiAddr(address, ERC20_ABI)
  const [allowance, setAllowance] = useState(new BigNumber(0))
  const { account } = useActiveWeb3React()
  const send = useSend()

  async function getAllowance() {
    try {
      if (tokenContract && account && spender) {
        let decimalsRes = decimals
        if (!decimalsRes) {
          decimalsRes = await tokenContract.decimals()
        }
        const allowanceRes = await tokenContract.allowance(account, spender)

        if (allowance?.toString() && decimalsRes) {
          setAllowance(new BigNumber(allowanceRes.toString()).shiftedBy(-decimalsRes))
        } else {
          setAllowance(new BigNumber(0))
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  const approved = useMemo(() => {
    return amount.isLessThan(allowance)
  }, [amount, allowance])

  useEffect(() => {
    getAllowance()
    let i: any
    if (!approved && interval) {
      i = setInterval(() => {
        getAllowance()
      }, interval)
    }

    return () => {
      if (i && interval) {
        clearInterval(interval)
      }
    }
  }, [tokenContract, account, approved])

  async function approve() {
    send({
      contract: tokenContract,
      method: 'approve',
      params: [spender, ethers.constants.MaxUint256.toString()],
      pendingText: 'Approving',
      summary: `Approved ${symbol}`,
      onSuccess() {
        getAllowance()
      }
    })
  }

  return {
    approved,
    approve
  }
}
