import React from 'react'
import S from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import styled from 'styled-components'
import classNames from 'classnames'
import { darken } from 'polished'
import { makeStyles, createStyles } from '@material-ui/core'

const StyledSwtich = styled(S)`
  &.MuiInputBase-root {
    height: 32px;
    border: 1px solid ${({ theme }) => theme.v2.t01};
    background-color: ${({ theme }) => theme.v2.bg04};
    .MuiSelect-root {
      padding: 10px 14px;
      padding-right: 50px;
    }
    color: ${({ theme }) => theme.v2.t01};

    font-size: 12px;
    border-radius: 12px;
    &:focus,
    &:hover,
    &:active {
      border: 1px solid ${({ theme }) => darken(0.5, theme.v2.t01)};
    }

    &::after,
    &::before {
      border: none !important;
    }
  }
`

const ArrowDown = styled.i`
  color: ${({ theme }) => theme.v2.t01} !important;
  top: unset !important;
  right: 4px !important;
`

const CustomExpandMore = ({ className, classes, ...rest }: any) => {
  return <ArrowDown {...rest} className={classNames(className, 'iconfont icon-arrow-down')} />
}

const CustomOption = styled(MenuItem)`
  background-color: ${({ theme }) => theme.v2.df01} !important;
  color: ${({ theme }) => theme.v2.t01} !important;

  &:hover {
    color: ${({ theme }) => theme.v2.t03} !important;
    background-color: ${({ theme }) => darken(0.1, theme.v2.bg02)} !important;
  }
`

interface Props {
  value?: number | string
  items: {
    text: string
    value: number | string
  }[]
  onChange?(value: any): void
  className?: string
  keyName?: string | number
}

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      background: 'transparent',
      border: '1px solid #fff',
      borderRadius: '12px'
    },
    list: {
      padding: '0'
    }
  })
)

export default function Select({ value, items, onChange, className, keyName = new Date().getTime() }: Props) {
  const classes = useStyles()
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (onChange) {
      onChange(event.target.value as any)
    }
  }
  return (
    <StyledSwtich
      labelId="netswap-switch"
      value={value}
      onChange={handleChange}
      className={className}
      IconComponent={CustomExpandMore}
      MenuProps={{
        classes: {
          paper: classes.paper,
          list: classes.list
        }
      }}
    >
      {items.map((i, index) => (
        <CustomOption className="selectOption" value={i.value} key={`${keyName}-${index}`}>
          {i.text}
        </CustomOption>
      ))}
    </StyledSwtich>
  )
}
