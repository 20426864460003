import React from 'react'
import styled from 'styled-components'

import { RowBetween } from 'components/Row'
import { NavLink } from 'react-router-dom'
import { Config as NettConfig } from 'constants/tokens/nett'
import { ChainId } from '@netswap/sdk'

const InstructionContainer = styled.div`
  display: flex;
  margin-top: -30px;
  width: 100%;
  padding-bottom: 205px;
  background: url(${require('assets/images/launchpad/bg-top.png')}) no-repeat top center,
    url(${require('assets/images/launchpad/bg_bottom.png')}) no-repeat bottom center;
  background-size: contain;
  position: relative;
  .bgLeftOne,
  .bgLeftTwo,
  .bgLeftThree,
  .bgLeftFour,
  .bgRightOne,
  .bgRightTwo,
  .bgRightThree {
    position: absolute;
    z-index: -10;
  }
  .bgLeftOne {
    left: 0;
    top: 911px;
  }
  .bgLeftTwo {
    left: 0;
    top: 1846px;
  }
  .bgLeftThree {
    left: 0;
    top: 2733px;
  }
  .bgLeftFour {
    left: 0;
    top: 3471px;
  }
  .bgRightOne {
    right: 0;
    top: 1289px;
  }
  .bgRightTwo {
    right: 0;
    top: 1660px;
  }
  .bgRightThree {
    right: 0;
    top: 4129px;
  }
`
const InstructionWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  width: 100%;
  max-width: 1440px;
  align-items: center;
  margin: 0 auto;
  h2,
  h3,
  p {
    margin: 0;
    color: #ffffff;
  }
  h3 {
    font-size: 48px;
    font-family: 'PangMenZhengDao';
    line-height: 55px;
  }
  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }

  .bigTitle {
    font-size: 64px;
    font-family: 'PangMenZhengDao';
    max-width: 1180px;
    text-shadow: 0 -6px #0647d5, 0 6px #c42a61;
    margin: 180px 0 80px;
    text-align: center;
    letter-spacing: 2px;
    line-height: 72px;
    text-transform: uppercase;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        font-size: 48px;
        line-height: 54px;
        word-break: break-word;
    `}
  }
  .instructionShowImg {
    max-width: 100%;

    &.padListImg {
      width: 481px;

      ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 100%;
    `}
    }

    &.padDetail,
    &.receiveToken {
      width: 603px;
      ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 100%;
    `}
    }
  }

  .getNettWrapper {
    ${({ theme }) => theme.flexColumnNoWrap}
    align-items: center;
    width: 100%;
    max-width: 1130px;
    padding-bottom: 45px;
    margin-bottom: 150px;
    background: url(${require('assets/images/launchpad/bg_get_nett.png')}) no-repeat bottom center;

    h3 {
      margin: 20px 0 56px;
    }
    .getNettRowCon {
      ${({ theme }) => theme.mediaWidth.upToSmall`
            ${({ theme }) => theme.flexColumnNoWrap}
        `}
    }
    .getNettCon {
      ${({ theme }) => theme.flexColumnNoWrap}
      align-items: center;
      width: 50%;
      p {
        margin-bottom: 40px;
        max-width: 360px;
        text-align: center;
      }
      ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 100%;
        margin-bottom: 40px;
      p {
        margin-bottom: 20px;
      }
    `}
    }
  }

  .instructionsCon {
    ${({ theme }) => theme.flexColumnNoWrap}
    width: 100%;
    max-width: 1130px;

    .instructionLeftRow,
    .instructionRightRow {
      margin-bottom: 110px;
      ${({ theme }) => theme.mediaWidth.upToSmall`
        ${({ theme }) => theme.flexColumnNoWrap}
        padding: 0 16px;
        img {
            margin-top: 24px;
        }
        h3,
        p {
            text-align: center;
        }
      `}
    }
    .instructionRightRow {
      h3,
      p {
        text-align: right;
      }
      &:last-child {
        margin-bottom: 0;
      }
      ${({ theme }) => theme.mediaWidth.upToSmall`
        flex-direction:column-reverse;
        h3,
        p {
            text-align: center;
        }
      `}
    }
    .instructionsInfo {
      ${({ theme }) => theme.flexColumnNoWrap}
      flex: 1;

      &.leftRowOne {
        max-width: 564px;
      }
      &.leftRowTwo {
        max-width: 430px;
      }
      &.rightRowOne {
        max-width: 513px;
      }
      &.rightRowTwo {
        max-width: 490px;
      }
      ${({ theme }) => theme.mediaWidth.upToSmall`
        &.leftRowOne,
        &.leftRowTwo,
        &.rightRowOne,
        &.rightRowTwo {
            max-width: 100%;
        }
      `}
      h3 {
        margin-bottom: 44px;
        color: ${({ theme }) => theme.v2.t01};
      }
      p {
        color: ${({ theme }) => theme.v2.t01};
        font-size: 16px;
        line-height: 22px;
      }

      .instructionsInfoLs {
        h4 {
          ${({ theme }) => theme.flexRowNoWrap}
          align-items: center;
          font-size: 24px;
          font-family: 'PangMenZhengDao';
          color: ${({ theme }) => theme.v2.t01};
          line-height: 27px;
          margin-top: 16px;
          span {
            display: inline-block;
            background: #c32a60;
            margin-right: 13px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
          }
        }
        p {
          margin: 24px 0;
        }
        ${({ theme }) => theme.mediaWidth.upToSmall`
            p {
                text-align: left;
            }
        `}
      }
    }
  }
`
const GoToNettOrStakeBtn = styled(NavLink)`
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  width: 240px;
  height: 64px;
  background: #96197b;
  border-radius: 16px;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  line-height: 64px;
  text-decoration: none;
  img {
    margin: 24px 0 0 5px;
  }
`
export default function instructions() {
  return (
    <InstructionContainer>
      <img className="bgLeftOne" src={require('assets/images/launchpad/bg_left_one.png')} alt="bgLeftOne" />
      <img className="bgLeftTwo" src={require('assets/images/launchpad/bg_left_triangle.png')} alt="bgLeftTwo" />
      <img className="bgLeftThree" src={require('assets/images/launchpad/bg_letf_two.png')} alt="bgLeftThree" />
      <img className="bgLeftFour" src={require('assets/images/launchpad/bg_left_ring.png')} alt="bgLeftThree" />
      <img className="bgRightOne" src={require('assets/images/launchpad/bg_right_ring.png')} alt="bgRightOne" />
      <img className="bgRightTwo" src={require('assets/images/launchpad/bg_right_one.png')} alt="bgRightTwo" />
      <img className="bgRightThree" src={require('assets/images/launchpad/bg_right_triangle.png')} alt="bgRightThree" />
      <InstructionWrapper>
        <h2 className="bigTitle">
          Launchpad V2
          <br />
          Step-by-step Instructions
        </h2>
        <div className="getNettWrapper">
          <img
            className="instructionShowImg"
            src={require('assets/images/launchpad/img_get_nett.png')}
            alt="get-nett"
          />
          <h3>Get NETT</h3>
          <RowBetween className="getNettRowCon">
            <div className="getNettCon">
              <p>Go to Netswap and enter the amount of NETT you wish to purchase.</p>
              <GoToNettOrStakeBtn to={`/swap/outputCurrency=${NettConfig[ChainId.MAINNET].address}`}>
                Buy NETT <img src={require('assets/images/launchpad/ic_arrow.png')} alt="ic_arrow.png" />
              </GoToNettOrStakeBtn>
            </div>
            <div className="getNettCon">
              <p>Go to Netswap Farms and stake LP token to harvest NETT.</p>
              <GoToNettOrStakeBtn to={`/farm`}>
                Stake LP Token <img src={require('assets/images/launchpad/ic_arrow.png')} alt="ic_arrow.png" />
              </GoToNettOrStakeBtn>
            </div>
          </RowBetween>
        </div>
        <div className="instructionsCon">
          <RowBetween className="instructionLeftRow">
            <div className="instructionsInfo leftRowOne">
              <h3>Earn wNETT</h3>
              <p>
                Staking NETT on the Launchpad V2 page to earn wNETT. There are no limits or restrictions when staking
                into wNETT. Any amount of NETT can be deposited, with no fee to deposit or withdraw.
              </p>
            </div>
            <img
              className="instructionShowImg"
              src={require('assets/images/launchpad/img_earn_wnett.png')}
              alt="earn_wnett"
            />
          </RowBetween>

          <RowBetween className="instructionRightRow">
            <img
              className="instructionShowImg"
              src={require('assets/images/launchpad/img_claim_pending_wnett.png')}
              alt="claim_pending_wnett"
            />
            <div className="instructionsInfo rightRowOne">
              <h3>Claim Pending wNETT</h3>
              <p>
                {`wNETT is stored in your wallet when you claim it. It's non-transferable and cannot be sent to other
                wallets. You cannot buy wNETT on any platform, it must be accrued from staking.`}
              </p>
            </div>
          </RowBetween>

          <RowBetween className="instructionLeftRow">
            <div className="instructionsInfo leftRowTwo">
              <h3>Unlimited Tier or Primary Tier?</h3>
              <div className="instructionsInfoLs">
                <h4>
                  <span></span>Primary Tier(Not supported now)
                </h4>
                <p>
                  Maximum deposit cap for each address.
                  <br />
                  Maximum deposit cap for total raised stablecoins.
                </p>
              </div>
              <div className="instructionsInfoLs">
                <h4>
                  <span></span>Unlimited Tier
                </h4>
                <p>
                  There are no limitations for depositing.
                  <br />
                  Allowing over-subscription for total raised stablecoins.
                  <br />A small participation fee will be charged by Netswap.
                </p>
              </div>
            </div>
            <img
              className="instructionShowImg padListImg"
              src={require('assets/images/launchpad/img_unlimited_tier_or_primary_tier.png')}
              alt="unlimited_tier_or_primary_tier"
            />
          </RowBetween>
          <RowBetween className="instructionRightRow">
            <img
              className="instructionShowImg padDetail"
              src={require('assets/images/launchpad/img_enter_active_event.png')}
              alt="enter_active_event"
            />
            <div className="instructionsInfo rightRowTwo">
              <h3>Enter active event</h3>
              <p>
                Use wNETT to enter an active event, the more wNETT tokens you have the more stablecoins you will be able
                to deposit.
              </p>
            </div>
          </RowBetween>
          <RowBetween className="instructionLeftRow">
            <div className="instructionsInfo leftRowOne">
              <h3>Deposit stablecoin</h3>
              <p>
                When the stablecoin is deposited, the corresponding ratio of wNETT will be burned. The ratio will be
                solid to 1:1 after the first IDO of V2, which means burning 1 wNETT allows you to deposit $1.
              </p>
            </div>
            <img
              className="instructionShowImg"
              src={require('assets/images/launchpad/img_deposit_stablecoin.png')}
              alt="deposit_stablecoin"
            />
          </RowBetween>
          <RowBetween className="instructionRightRow">
            <img
              className="instructionShowImg receiveToken"
              src={require('assets/images/launchpad/img_receive_your_tokens.png')}
              alt="receive_your_tokens"
            />
            <div className="instructionsInfo rightRowOne">
              <h3>Receive your tokens</h3>
              <p>
                The new tokens will be distributed to your wallet automatically when the sale token launches based on
                your deposit and vesting plan (Such as TGE 40%). You may need to claim some refunds when the Unlimited
                Tier is oversubscribed.
              </p>
            </div>
          </RowBetween>
        </div>
      </InstructionWrapper>
    </InstructionContainer>
  )
}
