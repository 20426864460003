import { gql } from '@apollo/client'
import { MicroServicesClient } from '../index'

export interface Token {
  symbol: string
  name: string
  address: string
  decimals: number
}

export interface FarmLPPair {
  id: string
  token0: Token
  token1: Token
  totalSupply: string
  reserveUSD: string
}

export interface FarmLPPool {
  id: string
  allocPoint: number
  pair: FarmLPPair
  lpApy: number
  rewarderApy: number
  nettApy: number
  totalDepositVolumeUSD: string
  rewarder?: {
    id: string
    endTimestamp: number
    rewardToken: Token
    tokenPerSec: string
  }
  nettPerSec: number

  // for boosted
  medianBoostedUser: string
  lpApr: number
  baseApr: number
  averageBoostedApr: number
  isBoosted: boolean
}

export default function getFarmLPPool(): Promise<FarmLPPool[] | undefined> {
  return new Promise(resolve => {
    MicroServicesClient()
      .query<{
        farmPools: FarmLPPool[]
      }>({
        query: gql`
          query farmPools {
            farmPools(skip: 0, limit: 100) {
              id
              allocPoint
              pair {
                id
                token0 {
                  address
                  decimals
                  symbol
                  name
                }
                token1 {
                  address
                  decimals
                  symbol
                  name
                }
                totalSupply
                reserveUSD
              }
              lpApy
              rewarderApy
              nettApy
              totalDepositVolumeUSD
              rewarder {
                id
                rewardToken {
                  address
                  decimals
                  symbol
                  name
                }
                tokenPerSec
              }
              nettPerSec
            }
          }
        `,
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        let returnData = undefined
        let { data } = res
        if (data?.farmPools) {
          returnData = data.farmPools
            .map(item => {
              return {
                ...item,
                allocPoint: Number(item.allocPoint)
              }
            })
            .sort((a, b) => b.allocPoint - a.allocPoint)
        }
        resolve(returnData)
      })
      .catch((e: any) => {
        console.error(e)
        resolve(undefined)
      })
  })
}
