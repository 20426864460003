import { ChainId } from '@netswap/sdk'
import ABI from '../abis/erc20.json'

export const METIS_CONFIG = {
  4: {
    address: '',
    abi: ABI
  }
}

export function getContract(contractAddress: string) {
  return (chainId: ChainId, web3: any) => {
    if (!contractAddress || !web3) return null
    const instance = new web3.eth.Contract(ABI, contractAddress)
    return instance
  }
}
