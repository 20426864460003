import React from 'react'
import S from '@material-ui/core/Switch'
import styled from 'styled-components'

const StyledSwtich = styled(S)`
  &.MuiSwitch-root {
    width: 56px;
    height: 30px;
    padding: 0px;
    .MuiSwitch-switchBase {
      padding: 0px;
      top: 4px;
      left: 4px;

      &.Mui-checked {
        transform: translateX(26px);
        color: #fff;

        & + .MuiSwitch-track {
          background-color: #52d869;
          opacity: 1;
          border: none;
        }
      }

      .MuiSwitch-thumb {
        width: 22px;
        height: 22px;
      }
    }

    .MuiSwitch-track {
      border-radius: 16px;
      background-color: ${({ theme }) => theme.v2.bg04};
      opacity: 1;
      transition: background-color 0.05s ease-out;
    }
  }
`

interface Props {
  checked?: boolean
  onChange?(checked: boolean): void
  className?: string
}

export default function Swtich({ checked, onChange, className }: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(!!event.target.checked)
    }
  }
  return (
    <StyledSwtich
      checked={checked}
      onChange={handleChange}
      color="primary"
      name="netswapSwitch"
      className={className}
      inputProps={{ 'aria-label': 'primary checkbox' }}
    />
  )
}
