/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
// import ChevronRightIcon from '@material-ui/icons/ChevronRight'
// import { useHistory } from 'react-router-dom'
import { AutoColumn } from 'components/Column'
import { InternalLinkV2, TYPE } from 'theme'
import TooltipInfo from 'components/TooltipInfo'
import Row, { RowBetween } from 'components/Row'
import TokenImage from 'components/TokenImage'
import { AuthButton } from 'components/Button'
import { useActiveWeb3React } from 'hooks'
import BigNumber from 'bignumber.js'
import { isMobile } from 'react-device-detect'
import { InputBase } from '@material-ui/core'
import RightBottomImage from 'assets/images/stake/v2/detail-right-bottom.png'
import Modal from 'components/CustomModal/modal'
import useTokenApprove from 'hooks/contract/useTokenApprove'
import PairInfo from './PairInfo'
import { FarmLPPool } from 'gql/microservices/farm-lp-pools'
import { useTotalSupply } from 'data/TotalSupply'
import { ChainId, Token, TokenAmount } from '@netswap/sdk'
import { NETWORK_CHAIN_ID } from 'connectors'
import { usePair } from 'data/Reserves'
import { useGetTokensPrice } from 'state/data/hooks/useTokenPrice'
import { Config as NettConfig } from 'constants/tokens/nett'

import { ERC20_ABI } from 'constants/abis/erc20'
import { useContractByAbiAddr } from 'hooks/contract/useContract'
import { useGetFarmLens } from 'hooks/contract/useGetContract'
import { wrapperNumeral } from 'utils'
const StakeWrapper = styled.div`
  width: 1100px;
  background: ${({ theme }) => theme.v2.bg04};
  border-radius: 14px;
  box-sizing: border-box;
  padding: 24px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 24px;

  position: relative;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: calc(100vw - 2rem);
  padding: 1rem;
  flex-direction: column;
  `}
`

const LeftBlocks = styled.div`
  width: 630px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  `}
`

const RightBlocks = styled.div`
  width: 400px;
  background: ${({ theme }) => theme.v2.bg02};
  box-sizing: border-box;
  padding: 18px 24px;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-image: url(${RightBottomImage}); */
  background-position: bottom center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  margin-top: 1rem;
  `}
`

const Block = styled.div`
  border-radius: 14px;
  box-sizing: border-box;
  padding: 18px 24px;
  width: 100%;
  background: ${({ theme }) => theme.v2.bg02};

  &.two {
    width: 48%;
  }

  &.three {
    width: 31%;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding: 10px;
   &.three {
    width: 48%;
    margin-bottom: 10px;
   }
  `}
`

const StyledButtonV2 = styled(AuthButton)`
  width: 300px;
  margin: 0 auto;
  margin-top: 32px;
  height: 48px;
  &.authButton {
    max-width: unset !important;
  }
`

const StakeTabWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 12px;
  justify-content: center;
  background-color: ${({ theme }) => theme.v2.bg06};
  width: 200px;

  /* height: 30px; */
  border-radius: 8px;
  box-sizing: border-box;
`

const StakeTab = styled.div`
  width: 50%;
  border-radius: 8px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.v2.t01};
  font-size: 14px;

  height: 30px;
  cursor: pointer;

  font-weight: 600;
  &.active {
    background-color: #d2cfd5;
    color: #000;
  }
`

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  padding: 12px 10px;
  width: 100%;
  height: 62px;
  background: ${({ theme }) => theme.v2.bg04};
  backdrop-filter: blur(13px);
  border-radius: 10px;
`

const ErrorMessage = styled.p`
  font-size: 12px;
  color: #fd4040;
  font-weight: 500;
  line-height: 14px;
  margin: 0 0 10px 0;
`

const MaxBtn = styled.button`
  width: 62px;
  height: 40px;
  background: ${({ theme }) => theme.v2.bg05};
  border-radius: 8px;
  border: none;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  line-height: 27px;
  cursor: pointer;
  flex-shrink: 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: fit-content;
    box-sizing: border-box;
    padding: 0 4px;
  `};
`

const Input = styled(InputBase)`
  input {
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    color: ${({ theme }) => theme.v2.t01};
  }
`

const TipsInfo = styled.div`
  width: 100%;

  border-radius: 16px;
  box-sizing: border-box;
  padding: 8px 20px;

  font-size: 14px;
  background: ${({ theme }) => theme.v2.bg04};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-top: 1rem;
  `}
`

const StakeModalWrapper = styled.div`
  padding: 20px 12px;
  width: 420px;
  display: flex;
  flex-flow: column wrap;
  text-align: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 100%;
    > div {
      width: 100%;
    }
    h3 {
      font-size: 1.6rem !important;
      line-height: 2rem;
    }
  `};
  h2,
  h3 {
    margin: 0;
    font-weight: 600;
    color: ${({ theme }) => theme.v2.t01};
  }
  h2 {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 16px;
  }
  h3 {
    font-size: 40px;
    line-height: 56px;
  }
  span {
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.v2.t01};
    line-height: 20px;
  }
`
const StakeModalRow = styled.div`
  padding: 24px 0;

  &.border {
    border-top: 1px dashed ${({ theme }) => theme.v2.t01};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-top: 24px;
  `};

  h3 {
    word-break: break-word;
  }
`
const StakeModalNote = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.v2.t01};
  line-height: 20px;
  margin: 24px 0;
  text-align: left;

  border-radius: 20px;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.v2.bg06};
`
const APRDetailWrapper = styled.div`
  border-radius: 14px;
  background: ${({ theme }) => theme.v2.bg02};
  padding: 18px 0 0 24px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  margin: 20px 0;

  &.onlyRewardPool {
    justify-content: space-between;
    > div {
      width: 35%;
    }
  }
  > div {
    width: 192px;
    margin-right: 10px;
    margin-bottom: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 47%;
    `}
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding: 10px 0 0 12px;
  `}
`

const StakeInfo = styled(AutoColumn)`
  background: ${({ theme }) => theme.v2.bg01};
  box-sizing: border-box;
  width: 100%;
  padding: 18px;
  border-radius: 14px;
`

interface Props {
  pool: FarmLPPool
  type: 'normal' | 'boost'
  stakeBalance: BigNumber
  approveAddress: string
  stake(props: { inputAmount: BigNumber; onSuccess?: () => void }): Promise<void>
  unStake(props: { inputAmount: BigNumber; onSuccess?: () => void }): Promise<void>
  claimPendingRewardToken?(props: { inputAmount: BigNumber; onSuccess?: () => void }): Promise<void>
  userVeNETTInfo?: {
    pendingVeNETT: BigNumber
    userVeNETTBalance: BigNumber
    pendingVeNettProgress: string
  }
  pendingNETT: BigNumber
  pendingBonus?: BigNumber
  unPaidBounsAmount?: BigNumber
}

enum RewarderStatus {
  END = 'END',
  STOP = 'STOP',
  START = 'START',
  UNSET = 'UNSET',
}

export default function StakeLP({
  pool,
  type,
  stakeBalance,
  approveAddress,
  stake,
  unStake,
  claimPendingRewardToken,
  pendingNETT,
  pendingBonus,
  unPaidBounsAmount,
  userVeNETTInfo,
}: Props) {
  const { account, chainId } = useActiveWeb3React()
  //   const history = useHistory()
  const [isStake, setIsStake] = useState(true)
  const isBoostPool = type === 'boost'
  const isDoubleReward = !!pool.rewarder
  const cid = (chainId || NETWORK_CHAIN_ID) as ChainId
  const [inputValue, setInputValue] = useState('')
  const [boostFarmInfo, setBoostFarmInfo] = useState<any>()
  const [userLPBalance, setUserLPBalance] = useState(new BigNumber(0))
  const [unStakeModalOpen, setUnStakeModalOpen] = useState(false)
  const [medianUserBoostFarmInfo, setMedianUserBoostFarmInfo] = useState<any>()
  //   const [unStakeVeNettModalOpen, setUnStakeVeNettModalOpen] = useState(false)
  const lpTokenContract = useContractByAbiAddr(pool?.pair.id, ERC20_ABI)
  const farmLensContract = useGetFarmLens()

  // use to get token0, token1 staked
  const [token0Currency, token1Currency] = useMemo(() => {
    return [
      new Token(chainId || NETWORK_CHAIN_ID, pool.pair.token0.address, pool.pair.token0.decimals),
      new Token(chainId || NETWORK_CHAIN_ID, pool.pair.token1.address, pool.pair.token1.decimals),
    ]
  }, [pool, chainId])
  const [, pair] = usePair(token0Currency, token1Currency)
  const pairToken = new Token(chainId || NETWORK_CHAIN_ID, pool?.pair.id, 18)
  const totalPoolTokens = useTotalSupply(pairToken)
  const userStakeAmount = useMemo(() => {
    return new TokenAmount(pairToken, stakeBalance.shiftedBy(18).toFixed())
  }, [stakeBalance, pairToken])
  const [token0Deposited, token1Deposited] =
    pair && totalPoolTokens
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userStakeAmount, false).toFixed(),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userStakeAmount, false).toFixed(),
        ]
      : [undefined, undefined]
  // --use to get token0, token1 staked END--

  const tokensRequestPrice = useMemo(() => {
    const p = [NettConfig[cid].address]
    if (pool.rewarder) {
      p.push(pool.rewarder.rewardToken.address)
    }
    return p
  }, [pool, cid])

  const tokensPrice = useGetTokensPrice(tokensRequestPrice)

  const lpPrice = useMemo(() => {
    return pool ? new BigNumber(pool.pair.reserveUSD).dividedBy(pool.pair.totalSupply).toNumber() : 0
  }, [pool])

  const stakeTokenUSD = useMemo(() => {
    return stakeBalance.multipliedBy(lpPrice).toFixed()
  }, [stakeBalance, lpPrice])

  const rewarderStatus = useMemo(() => {
    if (pool.rewarder?.endTimestamp) {
      if (pool.rewarder.endTimestamp === -1) {
        return RewarderStatus.END
      }
      const now = new Date().getTime()
      return now >= pool.rewarder?.endTimestamp * 1000 ? RewarderStatus.END : RewarderStatus.START
    }

    return RewarderStatus.UNSET
  }, [pool.rewarder])

  const canClaimPendingBonusAmount = useMemo(() => {
    if (
      rewarderStatus !== RewarderStatus.END &&
      pendingBonus &&
      unPaidBounsAmount &&
      pendingBonus.isGreaterThan(unPaidBounsAmount)
    ) {
      return pendingBonus.minus(unPaidBounsAmount)
    }

    return new BigNumber(0)
  }, [unPaidBounsAmount, pendingBonus, rewarderStatus])

  const harvestButtonDisable = useMemo(() => {
    return !pendingNETT.toNumber() && !canClaimPendingBonusAmount.toNumber()
  }, [pendingNETT, canClaimPendingBonusAmount])

  const pendingNETTUSD = useMemo(() => {
    return pendingNETT && tokensPrice && tokensPrice[NettConfig[cid].address.toLowerCase()]
      ? pendingNETT.multipliedBy(tokensPrice[NettConfig[cid].address.toLowerCase()].price).toNumber()
      : 0
  }, [pendingNETT, tokensPrice, cid])

  const canClaimPendingBonusUSD = useMemo(() => {
    return canClaimPendingBonusAmount &&
      pool &&
      pool.rewarder &&
      pool.rewarder.rewardToken.address &&
      tokensPrice &&
      tokensPrice[pool.rewarder.rewardToken.address.toLowerCase()]
      ? canClaimPendingBonusAmount
          .multipliedBy(tokensPrice[pool.rewarder.rewardToken.address.toLowerCase()].price)
          .toNumber()
      : 0
  }, [canClaimPendingBonusAmount, tokensPrice, pool])

  const inputAmount = useMemo(() => {
    return new BigNumber(inputValue || 0)
  }, [inputValue])
  const { approved, approve } = useTokenApprove(
    {
      address: lpTokenContract?.address || '',
      decimals: 18,
      symbol: 'NLP',
    },
    approveAddress,
    inputAmount
  )

  async function getBalance(contract: any) {
    if (contract && account) {
      try {
        const res = await contract.balanceOf(account)
        return res
      } catch (e) {
        console.error(e)
      }
    }
  }
  async function getLpBalance() {
    const res = await getBalance(lpTokenContract)

    setUserLPBalance(res?.toString() ? new BigNumber(res.toString()).shiftedBy(-18) : new BigNumber(0))
  }

  useEffect(() => {
    let interval: any
    if (account) {
      getLpBalance()
      interval = setInterval(() => {
        getLpBalance()
      }, 15000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [chainId, account])

  const changeStakeUnstake = (name: string) => {
    if (!account) {
      return
    }
    if (name === 'stake') {
      if (!isStake) {
        setIsStake(true)
      }
    }
    if (name === 'unstake') {
      if (isStake) {
        setIsStake(false)
      }
    }
  }

  const errorMessage = useMemo(() => {
    if (
      (isStake && inputAmount.isGreaterThan(userLPBalance)) ||
      (!isStake && inputAmount.isGreaterThan(stakeBalance))
    ) {
      return 'Insufficient balance'
    }
    return ''
  }, [userLPBalance, stakeBalance, isStake, inputAmount])

  async function handleMax() {
    if (isStake) {
      setInputValue(userLPBalance.toFixed())
    } else {
      setInputValue(stakeBalance.toFixed())
    }
  }

  async function handleStake() {
    if (!approved) {
      await approve()
    } else {
      await stake({
        inputAmount,
        onSuccess() {
          getLpBalance()
          setInputValue('')
        },
      })
    }
  }
  async function handleUnStake() {
    await unStake({
      inputAmount,
      onSuccess() {
        getLpBalance()
        setInputValue('')
      },
    })
  }

  async function handleClaimPendingRewardToken() {
    if (claimPendingRewardToken) {
      await claimPendingRewardToken({
        inputAmount,
      })
    }
  }

  async function getBoostedFarmsInfo() {
    try {
      if (pool && pool.isBoosted && farmLensContract && approveAddress && account) {
        const res = await farmLensContract.getBoostedFarmInfos(approveAddress, account, [pool.id])
        if (res) {
          setBoostFarmInfo(res[0])
        }
      }
    } catch (e) {
      console.error('getBoostAPRAndMedianBoostAPR error')
      console.error(e)
    }
  }

  useEffect(() => {
    getBoostedFarmsInfo()
  }, [pool, farmLensContract, approveAddress, account])

  const userBoostAPR = useMemo(() => {
    if (boostFarmInfo) {
      return new BigNumber(boostFarmInfo.userBoostedApr.toString()).shiftedBy(-18).multipliedBy(100).toNumber()
    }

    return 0
  }, [boostFarmInfo])
  async function getMedianUserBoostFarmsInfo() {
    try {
      if (pool && pool.isBoosted && farmLensContract && approveAddress && pool.medianBoostedUser) {
        const res = await farmLensContract.getBoostedFarmInfos(approveAddress, pool.medianBoostedUser, [pool.id])
        if (res) {
          setMedianUserBoostFarmInfo(res[0])
        }
      }
    } catch (e) {
      console.error('getMedianUserBoostFarmsInfo error')
      console.error(e)
    }
  }

  useEffect(() => {
    getMedianUserBoostFarmsInfo()
  }, [pool, farmLensContract, approveAddress])

  const medianBoostApr = useMemo(() => {
    if (medianUserBoostFarmInfo) {
      return wrapperNumeral(
        new BigNumber(medianUserBoostFarmInfo.userBoostedApr.toString()).shiftedBy(-18).multipliedBy(100)
      ).format('0.[0]')
    }

    return 0
  }, [medianUserBoostFarmInfo])

  const useVeNettShare = useMemo(() => {
    if (boostFarmInfo) {
      const res = new BigNumber(boostFarmInfo.userFactorShare.toString()).shiftedBy(-18).multipliedBy(100)
      if (res.isGreaterThan(0) && res.isLessThan(0.0001)) {
        return '<0.0001'
      }
      return wrapperNumeral(res).format('0.[000]')
    }
    return 0
  }, [boostFarmInfo])

  const nettApr = useMemo(() => {
    if (isBoostPool) {
      return wrapperNumeral(
        new BigNumber(boostFarmInfo?.baseApr.toString() || 0).shiftedBy(-18).multipliedBy(100)
      ).format('0.[00]')
    } else {
      return wrapperNumeral(pool.nettApy).format('0.[0]')
    }
  }, [isBoostPool, pool, boostFarmInfo])

  const averageBoostedApr = useMemo(() => {
    if (boostFarmInfo) {
      return wrapperNumeral(
        new BigNumber(boostFarmInfo.averageBoostedApr.toString()).shiftedBy(-18).multipliedBy(100)
      ).format('0.[0]')
    }
    return 0
  }, [boostFarmInfo])

  return (
    <>
      <PairInfo pair={pool.pair} />
      <StakeWrapper>
        <LeftBlocks>
          <RowBetween style={{ flexWrap: 'wrap' }}>
            <Block className={!isBoostPool && !isDoubleReward ? 'three' : 'two'}>
              <AutoColumn gap="md">
                <TYPE.desc fontSize={16} style={{ display: 'flex' }}>
                  Total Staked{' '}
                  <TooltipInfo style={{ marginLeft: '8px' }} text="Total value of the funds in this pool" />
                </TYPE.desc>
                <TYPE.v2Main fontWeight="bold" fontSize={24}>
                  ≈ {wrapperNumeral(pool.totalDepositVolumeUSD).format('$0,0')}
                </TYPE.v2Main>
              </AutoColumn>
            </Block>
            <Block className={!isBoostPool && !isDoubleReward ? 'three' : 'two'}>
              <AutoColumn gap="md">
                <TYPE.desc fontSize={16} style={{ display: 'flex' }}>
                  LP APR{' '}
                  <TooltipInfo
                    style={{ marginLeft: '8px' }}
                    text="This is the APR of earning 0.25% fees for LP. Based on last 24hrs performance"
                  />
                </TYPE.desc>
                <TYPE.v2Main fontWeight="bold" fontSize={24}>
                  {wrapperNumeral(pool.lpApy).format('0,0.[0]')} %
                </TYPE.v2Main>
              </AutoColumn>
            </Block>
            {!isBoostPool && !isDoubleReward && (
              <Block className="three">
                <AutoColumn gap="18px">
                  <TYPE.desc fontSize={16} style={{ display: 'flex' }}>
                    NETT APR
                  </TYPE.desc>
                  <TYPE.v2Main fontWeight="bold" fontSize={24}>
                    {nettApr} %
                  </TYPE.v2Main>
                </AutoColumn>
              </Block>
            )}
          </RowBetween>
          {(isBoostPool || isDoubleReward) && (
            <APRDetailWrapper className={!isBoostPool && isDoubleReward ? 'onlyRewardPool' : ''}>
              <AutoColumn gap="md">
                <TYPE.desc fontSize={16} style={{ display: 'flex' }}>
                  NETT APR
                </TYPE.desc>
                <TYPE.v2Main fontWeight="bold" fontSize={24}>
                  {nettApr} %
                </TYPE.v2Main>
              </AutoColumn>
              {isDoubleReward && (
                <AutoColumn gap="md">
                  <TYPE.desc fontSize={16} style={{ display: 'flex' }}>
                    Bonus APR{' '}
                    {/* <TooltipInfo
                                style={{ marginLeft: '8px' }}
                                text="This is the APR of earning 0.25% fees for LP. Based on last 24hrs performance"
                              /> */}
                  </TYPE.desc>
                  <TYPE.v2Main fontWeight="bold" fontSize={24}>
                    {pool.rewarderApy} %
                  </TYPE.v2Main>
                </AutoColumn>
              )}
              {isBoostPool && (
                <>
                  <AutoColumn gap="md">
                    <TYPE.desc fontSize={16} style={{ display: 'flex' }}>
                      Your Boost APR{' '}
                      {/* <TooltipInfo
                                style={{ marginLeft: '8px' }}
                                text="This is the APR of earning 0.25% fees for LP. Based on last 24hrs performance"
                              /> */}
                    </TYPE.desc>
                    <TYPE.v2Main fontWeight="bold" fontSize={24}>
                      {wrapperNumeral(userBoostAPR).format('0.[00]')} %
                    </TYPE.v2Main>
                  </AutoColumn>
                  <AutoColumn gap="md">
                    <TYPE.desc fontSize={16} style={{ display: 'flex' }}>
                      Avg. Boost APR
                      {/* <TooltipInfo
                                style={{ marginLeft: '8px' }}
                                text="This is the APR of earning 0.25% fees for LP. Based on last 24hrs performance"
                              /> */}
                    </TYPE.desc>
                    <TYPE.v2Main fontWeight="bold" fontSize={24}>
                      {averageBoostedApr} %
                    </TYPE.v2Main>
                  </AutoColumn>
                  <AutoColumn gap="md">
                    <TYPE.desc fontSize={16} style={{ display: 'flex' }}>
                      Median Boost APR
                      {/* <TooltipInfo
                                style={{ marginLeft: '8px' }}
                                text="This is the APR of earning 0.25% fees for LP. Based on last 24hrs performance"
                              /> */}
                    </TYPE.desc>
                    <TYPE.v2Main fontWeight="bold" fontSize={24}>
                      {medianBoostApr} %
                    </TYPE.v2Main>
                  </AutoColumn>
                </>
              )}
            </APRDetailWrapper>
          )}
          <Block style={{ marginTop: '24px' }}>
            {isBoostPool && (
              <>
                <RowBetween align="flex-start" style={{ marginBottom: '24px' }}>
                  <AutoColumn gap="md">
                    <TYPE.desc fontSize={16} style={{ display: 'flex' }}>
                      Your veNETT Balance
                      {/* <TooltipInfo
                                style={{ marginLeft: '8px' }}
                                text="This is the APR of earning 0.25% fees for LP. Based on last 24hrs performance"
                              /> */}
                    </TYPE.desc>
                    <TYPE.v2Main fontWeight="bold" fontSize={24}>
                      {userVeNETTInfo?.userVeNETTBalance?.toFixed(3) || '--'} veNETT
                    </TYPE.v2Main>
                    <TYPE.desc>Unclaim:{userVeNETTInfo?.pendingVeNETT?.toFixed(3) || '--'} veNETT</TYPE.desc>
                    <InternalLinkV2 to="/nett-staking-detail/veNETT">Go to claim</InternalLinkV2>
                  </AutoColumn>
                  <AutoColumn gap="md">
                    <TYPE.desc fontSize={16} style={{ display: 'flex' }}>
                      Your veNETT Share
                      {/* <TooltipInfo
                                style={{ marginLeft: '8px' }}
                                text="This is the APR of earning 0.25% fees for LP. Based on last 24hrs performance"
                              /> */}
                    </TYPE.desc>
                    <TYPE.v2Main fontWeight="bold" fontSize={24}>
                      {useVeNettShare} %
                    </TYPE.v2Main>
                  </AutoColumn>
                </RowBetween>
              </>
            )}
            <StakeInfo gap="md" justify="center">
              <TYPE.desc fontSize={14}>Your staked</TYPE.desc>
              <TYPE.v2Main fontSize={24}>≈ {wrapperNumeral(stakeTokenUSD).format('$ 0,0.[000]')}</TYPE.v2Main>
              <TYPE.v2Main color="t09">
                ≈ {wrapperNumeral(token0Deposited).format('0,0.[000000]')} {pool.pair.token0.symbol}
                &nbsp;&nbsp;|&nbsp;&nbsp; ≈ {wrapperNumeral(token1Deposited).format('0,0.[000000]')}{' '}
                {pool.pair.token1.symbol}
              </TYPE.v2Main>
            </StakeInfo>
          </Block>

          <Block style={{ marginTop: '24px' }}>
            <AutoColumn gap="md">
              <TYPE.desc fontSize={16} style={{ display: 'flex' }}>
                Pending Rewards
              </TYPE.desc>
              <RowBetween justify={isDoubleReward ? 'justify' : 'center'}>
                <Row justify={isDoubleReward ? 'justify' : 'center'}>
                  <TokenImage
                    size="48px"
                    style={{ marginRight: '16px' }}
                    address={NettConfig[ChainId.MAINNET].address}
                  />
                  <AutoColumn>
                    <TYPE.v2Main fontWeight="bold" fontSize={24}>
                      {wrapperNumeral(pendingNETT).format('≈ 0,0.[000]')} NETT
                    </TYPE.v2Main>
                    <TYPE.desc fontSize={14} style={{ display: 'flex' }}>
                      ≈ {wrapperNumeral(pendingNETTUSD).format('$ 0,0.[000]')}
                    </TYPE.desc>
                  </AutoColumn>
                </Row>
                {isDoubleReward && (
                  <Row>
                    <TokenImage
                      size="48px"
                      style={{ marginRight: '16px' }}
                      address={pool.rewarder?.rewardToken.address || ''}
                    />
                    <AutoColumn>
                      <TYPE.v2Main fontWeight="bold" fontSize={24}>
                        {wrapperNumeral(canClaimPendingBonusAmount).format('≈ 0,0.[000]')}{' '}
                        {pool.rewarder?.rewardToken.symbol || ''}
                      </TYPE.v2Main>
                      <TYPE.desc fontSize={14} style={{ display: 'flex' }}>
                        ≈ {wrapperNumeral(canClaimPendingBonusUSD).format('$ 0,0.[000]')}
                      </TYPE.desc>
                    </AutoColumn>
                  </Row>
                )}
              </RowBetween>

              <StyledButtonV2 disabled={harvestButtonDisable} auth onClick={handleClaimPendingRewardToken}>
                Harvest
              </StyledButtonV2>
            </AutoColumn>
          </Block>
        </LeftBlocks>
        <RightBlocks>
          <div>
            <StakeTabWrapper>
              <StakeTab className={isStake ? 'active' : ''} onClick={() => changeStakeUnstake('stake')}>
                Stake
              </StakeTab>
              <StakeTab className={!isStake ? 'active' : ''} onClick={() => changeStakeUnstake('unstake')}>
                UnStake
              </StakeTab>
            </StakeTabWrapper>
            <InputWrapper>
              <Input
                fullWidth
                autoFocus={!isMobile}
                placeholder="0.0"
                type="number"
                value={inputValue}
                onChange={(e) => {
                  if (Number(e.currentTarget.value) < 0 || e.currentTarget.value === '-') {
                    setInputValue('')
                    return
                  }
                  setInputValue(e.currentTarget.value || '')
                }}
              />
              <MaxBtn onClick={handleMax}>Max</MaxBtn>
              {/* <TokenImage address={lpTokenContract?.address || ''} alt="NETTICON" />
              <TYPE.v2Main style={{ flexShrink: 0, marginLeft: '4px' }} fontSize={16}>
                NETT
              </TYPE.v2Main> */}
            </InputWrapper>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            {isStake && (
              <RowBetween style={{ flexWrap: 'wrap' }}>
                <TYPE.desc fontSize={14}>LP Token Balance: {userLPBalance.toFixed()}</TYPE.desc>
                <InternalLinkV2 to={`/add/${pool.pair.token0.address}/${pool.pair.token1.address}`}>
                  Get LP Token
                </InternalLinkV2>
              </RowBetween>
            )}
            {!isStake && <TYPE.desc fontSize={14}>You Staked: {stakeBalance.toFixed()} LP Token</TYPE.desc>}
            {isStake && (
              <StyledButtonV2
                auth
                disabled={!inputValue || !!errorMessage}
                onClick={handleStake}
                style={{ height: '48px', marginTop: '16px' }}
              >
                {approved ? 'Stake' : 'Approve'}
              </StyledButtonV2>
            )}

            {!isStake && (
              <StyledButtonV2
                auth
                onClick={handleUnStake}
                disabled={!inputValue || !!errorMessage}
                style={{ height: '48px', marginTop: '16px' }}
              >
                Unstake
              </StyledButtonV2>
            )}
          </div>
          {false && (
            <div>
              <TipsInfo>
                <h3>Stake Information</h3>
                <p>● xxx.</p>
              </TipsInfo>
            </div>
          )}
        </RightBlocks>
      </StakeWrapper>
      <Modal
        isOpen={unStakeModalOpen}
        onClose={() => {
          setUnStakeModalOpen(false)
        }}
      >
        <StakeModalWrapper>
          <h2>Unstake & Claim</h2>
          <StakeModalRow>
            <h3> ≈ {stakeBalance.toFixed(6)}</h3>
            <span>Staked NETT</span>
          </StakeModalRow>
          <StakeModalRow className="border">
            <span>Reward</span>
          </StakeModalRow>
          <StakeModalNote>
            When you withdraw,your Reward is claimed and your NETT is returned to you. You will no longer earn staking
            rewards on this NETT.
          </StakeModalNote>
          <AuthButton
            auth
            onClick={handleUnStake}
            disabled={!!errorMessage}
            style={{ height: '48px', marginTop: '16px' }}
          >
            Unstake
          </AuthButton>
        </StakeModalWrapper>
      </Modal>
    </>
  )
}
