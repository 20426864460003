// eslint-disable-next-line react-hooks/exhaustive-deps
import React, { Suspense, useEffect, useMemo, useState, useRef } from 'react'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
// import classNames from 'classnames'
import { useAppSelector } from 'state'
import { throttle } from 'utils'
// import { useMedia } from 'react-use'

import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import AddressClaimModal from '../components/claim/AddressClaimModal'
import Header from '../components/HeaderV2'
import Polling from '../components/Header/Polling'
import URLWarning from '../components/HeaderV2/URLWarning'
import Adversizement from '../components/HeaderV2/Advertisement'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import { ApplicationModal } from '../state/application/actions'
import { useModalOpen, useToggleModal, useUpdateHeaderHeight } from '../state/application/hooks'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import AddLiquidity from './AddLiquidity'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity,
} from './AddLiquidity/redirects'
import Earn from './Earn'
import Manage from './Earn/Manage'
import MigrateV1 from './MigrateV1'
import MigrateV1Exchange from './MigrateV1/MigrateV1Exchange'
import RemoveV1Exchange from './MigrateV1/RemoveV1Exchange'
import PoolV2 from './Pool/V2'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import Swap from './Swap'
import { OpenClaimAddressModalAndRedirectToSwap, RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
// import Vote from './Vote'
// import VotePage from './Vote/VotePage'
// import Home from './Home'
// import Footer from '../components/Footer'
import Footer, { MobileFooter } from 'components/FooterV2'
import Reward from './Reward'
import { TransactionConfirmationModalWithErrorMessage } from 'components/TransactionConfirmationModal'
import NettModal from '../components/Nett'
import Stake from './Stake/v2'
import FarmV2 from './Farm/V2'
import Launchpad from './Launchpad'
import BridgeModal from 'components/BridgeModal'
import WalletModal from 'components/WalletModal'
// import LeverageSwap from './Leverage/swap'
// import LeveragePortfolio from './Leverage/portfolio'
// import LeverageEarn from './Leverage/earn'
import Christmasbg from 'assets/images/christmas/bg.png'
import TradeCompetition from './TradeCompetition'
import LaunchpadV2 from './LaunchpadV2'
import LandingPage from './LandingPage'
import LaunchpadV2Detail from './LaunchpadV2/detail'
import LaunchpadV2Instruction from './LaunchpadV2/instructions'
import Analytics from './Analytics'
import Search from './Search'
import { useAllTransactions } from 'state/transactions/hooks'
import { TransactionDetails } from 'state/transactions/reducer'
import NettStakingV2 from './NettStake'
import NettStakingDetail from './NettStake/detail'
import FarmWithBoost from 'pages/FarmWithBoost'
import FarmWithBoostDetail from 'pages/FarmWithBoost/detail'
import Bridge from './Bridge'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  z-index: 2;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    z-index: 3;
  `};
  &.showBg {
    background-color: rgba(23, 6, 39, 1);
    transition: all 0.3s linear;
  }
`

const BodyWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  flex: 1;
  /* overflow-y: auto; */
  overflow-x: hidden;

  z-index: 1;
`

const NormalMarginer = styled.div`
  margin-top: 0rem;
`
const IframeMarginer = styled.div`
  margin-top: 0rem;
  /* ${({ theme }) => theme.mediaWidth.upToMoreLarge`
    margin-top: 4rem;
  `}; */
`

const Layout = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
`

const PageContent = styled.div<{ hidePadding?: boolean }>`
  width: 100%;
  height: 100vh;
  padding-top: ${({ hidePadding }) => (hidePadding ? '0' : '30px')};
  overflow-x: hidden;
  overflow-y: scroll;
  align-items: center;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  z-index: 1;
  background: ${({ theme }) => theme.v2.bg01};
  /* max-height: 100vh; */
  /* overflow-y: scroll; */

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-bottom: 40px;
  `};
`

const ContentContainer = styled.div<{ headerHeight: number }>`
  width: 100%;
  min-height: calc(100vh - 96px);
  padding-top: ${({ headerHeight }) => headerHeight}px;
  align-items: center;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    position: relative;
    width: 100vw;
    overflow-x: hidden;
    min-height: unset;
  `};
`

function TopLevelModals() {
  const open = useModalOpen(ApplicationModal.ADDRESS_CLAIM)
  const toggle = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
  return <AddressClaimModal isOpen={open} onDismiss={toggle} />
}

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

export default function App() {
  const location = useLocation()
  const [headerSearchShow, setHeaderSearchShow] = useState(false)
  const [showAd, setShowAd] = useState<boolean>(true)
  const headerRef = useRef<HTMLDivElement | null>(null)
  const updateHeaderHeight = useUpdateHeaderHeight()
  const isIframePath = useMemo(() => {
    return location.pathname === '/leverage'
  }, [location])
  useEffect(() => {
    if (window.ethereum) {
      ;(window.ethereum as any).on('chainChanged', async (chanIdHex: string) => {
        window.location.reload()
      })
    }
  }, [])

  useEffect(() => {
    if (location.pathname !== '/home') {
      setHeaderSearchShow(false)
      return
    }
    const dom: Element | null = document.querySelector('#pageContainer')
    const scroll = throttle(() => {
      if (dom) {
        if (dom.scrollTop >= 50) {
          setHeaderSearchShow(true)
        } else {
          setHeaderSearchShow(false)
        }
      }
    }, 10)
    if (dom) {
      dom.addEventListener('scroll', scroll)
    }

    return () => {
      if (dom) {
        dom.removeEventListener('scroll', scroll)
      }
    }
  }, [location.pathname])

  const headerHeight = headerRef?.current?.clientHeight || 0

  useEffect(() => {
    if (showAd && headerHeight) {
      updateHeaderHeight(headerHeight)
    }

    if (!showAd) {
      updateHeaderHeight(66)
    }
  }, [headerHeight, updateHeaderHeight, showAd])

  const { isChristmas } = useAppSelector((state) => state.application)
  const GlobalStyle = createGlobalStyle`
    body {
      background: #1D0831;
    }
    .MuiTooltip-popper{
        z-index: 100000 !important;
    }
  `

  const GlobalStyleChristmas = createGlobalStyle`
    body {
      background: url(${Christmasbg});
      background-size: contain;
    }
  `
  const isHomepath = useMemo(() => {
    return location.pathname === '/home'
  }, [location])

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)
  const confirmed = sortedRecentTransactions.filter((tx) => tx.receipt).map((tx) => tx.hash)
  return (
    <Suspense fallback={null}>
      <Route component={GoogleAnalyticsReporter} />
      <Route component={DarkModeQueryParamReader} />
      {isChristmas ? <GlobalStyleChristmas /> : <GlobalStyle />}
      <AppWrapper className={'container'}>
        <BodyWrapper>
          <Popups />
          {!isHomepath && <Polling />}
          <TopLevelModals />
          <TransactionConfirmationModalWithErrorMessage />
          <NettModal />
          <BridgeModal />
          <WalletModal pendingTransactions={pending} confirmedTransactions={confirmed} />

          <Layout id="layoutContainer">
            {/* <HeaderWrapper id="headerWrapper" className={classNames(headerBgShow && 'showBg')}>
              <Header />
            </HeaderWrapper> */}

            <HeaderWrapper id="headerWrapper" ref={headerRef}>
              <Adversizement onClose={() => setShowAd(false)} show={showAd} />
              <Header showSearch={headerSearchShow} />
            </HeaderWrapper>

            <PageContent id="pageContainer" hidePadding={isHomepath}>
              <ContentContainer headerHeight={headerHeight}>
                <URLWarning offsetTop={!isHomepath} />
                <Web3ReactManager>
                  <Switch>
                    <>
                      <Route exact strict path="/swap" component={Swap} />
                      <Route exact strict path="/claim" component={OpenClaimAddressModalAndRedirectToSwap} />
                      <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                      <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
                      <Route exact strict path="/find" component={PoolFinder} />
                      <Route exact strict path="/pool" component={PoolV2} />
                      <Route exact strict path="/uni" component={Earn} />
                      {/* <Route exact strict path="/vote" component={Vote} /> */}
                      <Route exact strict path="/create" component={RedirectToAddLiquidity} />
                      <Route exact path="/add" component={AddLiquidity} />
                      <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                      <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                      <Route exact path="/create" component={AddLiquidity} />
                      <Route exact path="/create/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                      <Route exact path="/create/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                      <Route exact strict path="/remove/v1/:address" component={RemoveV1Exchange} />
                      <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
                      <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
                      <Route exact strict path="/migrate/v1" component={MigrateV1} />
                      <Route exact strict path="/migrate/v1/:address" component={MigrateV1Exchange} />
                      <Route exact strict path="/uni/:currencyIdA/:currencyIdB" component={Manage} />
                      {/* <Route exact strict path="/home" component={Home} /> */}
                      <Route exact strict path="/home" component={LandingPage} />
                      <Route exact strict path="/reward" component={Reward} />
                      <Route exact strict path="/stake" component={Stake} />
                      <Route exact strict path="/farm-v1" component={FarmV2} />
                      <Route exact strict path="/farm" component={FarmWithBoost} />
                      <Route exact strict path="/launchpad" component={Launchpad} />
                      <Route exact strict path="/trade-competition" component={TradeCompetition} />
                      <Route exact strict path="/launchpad-v2" component={LaunchpadV2} />
                      <Route exact strict path="/launchpad-v2/:id" component={LaunchpadV2Detail} />
                      <Route exact strict path="/launchpad-instructions" component={LaunchpadV2Instruction} />
                      <Route exact strict path="/nett-staking" component={NettStakingV2} />
                      <Route path="/nett-staking-detail" component={NettStakingDetail} />
                      <Route path="/f" component={FarmWithBoostDetail} />
                      {/* <Route exact strict path="/leverage/swap" component={LeverageSwap} />
                  <Route exact strict path="/leverage/portfolio" component={LeveragePortfolio} />
                  <Route exact strict path="/leverage/earn" component={LeverageEarn} /> */}
                      <Route exact strict path="/search" component={Search} />
                      <Route path="/analytics" component={Analytics} />
                      <Route path="/bridge" component={Bridge} />
                      <Route exact path="/">
                        <Redirect to="/home" />
                      </Route>
                    </>
                  </Switch>
                </Web3ReactManager>
                <MobileFooter />
              </ContentContainer>
              {isIframePath ? <IframeMarginer /> : <NormalMarginer />}
              <Footer />
            </PageContent>
          </Layout>
        </BodyWrapper>
      </AppWrapper>
    </Suspense>
  )
}
