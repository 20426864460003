import { ChainId } from '@netswap/sdk'
import ABI from './wNettStaking.json'

export const WNettStakeContract = {
  [ChainId.MAINNET]: {
    address: '0x276164cDe2607ce3E45Dd76fE7f4f31511D9DB9D',
    abi: ABI,
  },
  [ChainId.TESTNET]: {
    address: '0x3c13674aE82dAc9FFfe5D7f6E9eE555536Bc9961',
    abi: ABI,
  },
  // need config
  [ChainId.TESTNET_SEPOLIA]: {
    address: '0x3c13674aE82dAc9FFfe5D7f6E9eE555536Bc9961',
    abi: ABI,
  },
}

export { default as wNettABI } from './wNettStaking.json'
