import { ChainId } from '@netswap/sdk'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FarmLPPool } from 'gql/microservices/farm-lp-pools'
import Storage from 'utils/storage'
// import type { RootState } from '../index';

// Define a type for the slice state

export const BoostedFarmPoolsKey = 'netswap-data-boosted-farm-pool'
const BoostedFarmPools = Storage.get(BoostedFarmPoolsKey)

export const UserBoostedFarmLPPoolKey = 'netswap-data-user-boosted-farm-pool'
const UserBoostFarmLPPools = Storage.get(UserBoostedFarmLPPoolKey)

interface State {
  pools: {
    [ChainId.MAINNET]: FarmLPPool[]
    [ChainId.TESTNET]: FarmLPPool[]
    [ChainId.TESTNET_SEPOLIA]: FarmLPPool[]
  }
  userPools: {
    [key: string]: {
      [ChainId.MAINNET]: string[]
      [ChainId.TESTNET]: string[]
      [ChainId.TESTNET_SEPOLIA]: string[]
    }
  }
}

// Define the initial state using that type
// -1 === loading
const initialState: State = {
  pools: BoostedFarmPools,
  userPools: UserBoostFarmLPPools || {},
}

export const contractState = createSlice({
  name: 'boostedFarm',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setPools: (
      state,
      action: PayloadAction<{
        chainId: ChainId
        data: FarmLPPool[]
      }>
    ) => {
      const { chainId, data } = action.payload
      if (!state.pools) {
        state.pools = {
          [ChainId.MAINNET]: [],
          [ChainId.TESTNET]: [],
          [ChainId.TESTNET_SEPOLIA]: [],
        }
      }
      state.pools[chainId] = data
      Storage.set(BoostedFarmPoolsKey, state.pools)
    },
    setUserPools: (
      state,
      action: PayloadAction<{
        chainId: ChainId
        account: string
        data: string[]
      }>
    ) => {
      const { chainId, data, account } = action.payload
      if (!state.userPools[account]) {
        state.userPools[account] = {
          [ChainId.MAINNET]: [],
          [ChainId.TESTNET]: [],
          [ChainId.TESTNET_SEPOLIA]: [],
        }
      }

      state.userPools[account][chainId] = data

      Storage.set(UserBoostedFarmLPPoolKey, state.userPools)
    },
  },
})

export const { setPools, setUserPools } = contractState.actions

export default contractState.reducer
