import React from 'react'
import cx from 'classnames'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Zoom from '@material-ui/core/Zoom'
import CloseIcon from '@material-ui/icons/Close'
import styled from 'styled-components'

export interface Props {
  isOpen?: boolean
  onClose?(): void
  onCancel?(): void
  children?: React.ReactNode
  customContentClass?: string
  hideClose?: boolean
  disableBackgroundClick?: boolean
  keepMounted?: boolean
}

// const useStyles = makeStyles(() =>
//   createStyles({
//     modal: {
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center'
//     },
//     content: {
//       position: 'relative',
//       minWidth: '200px',
//       maxWidth: '95vw',
//       padding: '20px',
//       outline: 'none',
//       background: '#170627',
//       borderRadius: '10px'
//     },
//     close: {
//       position: 'absolute',
//       right: '15px',
//       top: '15px',
//       cursor: 'pointer',
//       width: '20px !important',
//       height: '20px !important',
//       color: '#C42A61'
//     }
//   })
// )

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const CustomContent = styled.div`
  position: relative;
  min-width: 200px;
  max-width: 95vw;
  padding: 20px;
  outline: none;
  background: ${({ theme }) => theme.v2.bg02};
  border-radius: 10px;
`

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  width: 20px !important;
  height: 20px !important;
  color: ${({ theme }) => theme.v2.t01};
`

export default function CustomModal({
  isOpen,
  onClose,
  onCancel,
  children,
  customContentClass,
  hideClose,
  keepMounted,
  disableBackgroundClick = false
}: Props) {
  return (
    <StyledModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={!!isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      keepMounted={keepMounted}
      disableBackdropClick={disableBackgroundClick}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Zoom in={isOpen}>
        <CustomContent className={cx('card', customContentClass)}>
          {!hideClose && (
            <StyledCloseIcon
              onClick={() => {
                if (onCancel) {
                  onCancel()
                }
                if (onClose) {
                  onClose()
                }
              }}
            />
          )}
          {children}
        </CustomContent>
      </Zoom>
    </StyledModal>
  )
}
