import React, { useState } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ButtonFaded } from '../ButtonStyled'
import { ButtonV2 } from 'components/Button'
import { AutoRow, RowBetween } from '../../Row'
import { isAddress } from 'utils/index'
import { useSavedAccounts } from 'hooks/infos/LocalStorage'
import { AutoColumn } from '../../Column'
import { TYPE } from 'theme'
import { Hover, StyledIcon } from '..'
import Panel from '../Panel'
import { Flex } from 'rebass'

import { X } from 'react-feather'
import { transparentize } from 'polished'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  border-radius: 12px;
`

const Input = styled.input`
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  white-space: nowrap;
  background: none;
  border: none;
  outline: none;
  padding: 12px 16px;
  border-radius: 12px;
  color: ${({ theme }) => theme.v2.t01};
  background-color: ${({ theme }) => (theme.isDark ? theme.v2.bg01 : transparentize(0.8, theme.v2.bg01))};
  font-size: 16px;
  margin-right: 1rem;
  border: 1px solid ${({ theme }) => (theme.isDark ? theme.v2.df01 : theme.v2.t02)};

  ::placeholder {
    color: ${({ theme }) => (theme.isDark ? theme.v2.t03 : transparentize(0.6, theme.text1))};
    font-size: 14px;
  }

  @media screen and (max-width: 640px) {
    ::placeholder {
      font-size: 1rem;
    }
  }
`

const AccountLink = styled.span`
  display: flex;
  cursor: pointer;
  color: ${({ theme }) => theme.v2.t04};
  font-size: 14px;
  font-weight: 500;
`

const DashGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr;
  grid-template-areas: 'account';
  padding: 0 4px;

  > * {
    justify-content: flex-end;
  }
`

const MainText = styled.span`
  color: ${({ theme }) => (theme.isDark ? theme.text1 : theme.white)};
`

const HeaderText = styled.span`
  color: ${({ theme }) => (theme.isDark ? theme.text1 : theme.white)};
`

function AccountSearch({ history, small = false }: { history: string[]; small?: boolean } & RouteComponentProps<any>) {
  const { t } = useTranslation()
  const [accountValue, setAccountValue] = useState<string>()
  const [savedAccounts, addAccount, removeAccount] = useSavedAccounts()

  function handleAccountSearch() {
    if (accountValue && isAddress(accountValue)) {
      history.push('/analytics/accounts/' + accountValue)
      if (!savedAccounts.includes(accountValue)) {
        addAccount(accountValue)
      }
    }
  }

  return (
    <AutoColumn gap={'1rem'}>
      {!small && (
        <>
          <AutoRow>
            <Wrapper>
              <Input
                placeholder="0x..."
                onChange={(e) => {
                  setAccountValue(e.target.value)
                }}
              />
            </Wrapper>
            <ButtonV2 style={{ width: 200, height: 46, fontSize: 14 }} onClick={handleAccountSearch}>
              {t('analytics.account_search.load_account_details')}
            </ButtonV2>
          </AutoRow>
        </>
      )}

      <AutoColumn gap={'12px'}>
        {!small && (
          <Panel>
            <DashGrid style={{ height: 'fit-content', padding: '0 0 1rem 0', justifyContent: 'center' }}>
              <TYPE.v2Main>{t('analytics.account_search.saved_accounts')}</TYPE.v2Main>
            </DashGrid>
            {savedAccounts?.length > 0 ? (
              savedAccounts.map((account) => {
                return (
                  <DashGrid
                    key={account}
                    style={{ height: 'fit-content', padding: '1rem 0 0 0', justifyContent: 'center' }}
                  >
                    <Flex justifyContent="space-between" onClick={() => history.push('/analytics/accounts' + account)}>
                      <AccountLink>{account?.slice(0, 42)}</AccountLink>
                      <Hover onClick={() => removeAccount(account)}>
                        <StyledIcon>
                          <X size={16} />
                        </StyledIcon>
                      </Hover>
                    </Flex>
                  </DashGrid>
                )
              })
            ) : (
              <TYPE.mainLg style={{ marginTop: '1rem' }}>{t('analytics.account_search.no_saved_accounts')}</TYPE.mainLg>
            )}
          </Panel>
        )}

        {small && (
          <>
            <TYPE.v2Main>
              <MainText>{t('analytics.account_search.accounts')}</MainText>
            </TYPE.v2Main>
            {savedAccounts?.length > 0 ? (
              savedAccounts.map((account) => {
                return (
                  <RowBetween key={account}>
                    <ButtonFaded onClick={() => history.push('/analytics/accounts/' + account)}>
                      {small ? (
                        <TYPE.title>
                          <HeaderText>{account?.slice(0, 6) + '...' + account?.slice(38, 42)}</HeaderText>
                        </TYPE.title>
                      ) : (
                        <AccountLink>{account?.slice(0, 42)}</AccountLink>
                      )}
                    </ButtonFaded>
                    <Hover onClick={() => removeAccount(account)}>
                      <StyledIcon>
                        <MainText>
                          <X size={16} />
                        </MainText>
                      </StyledIcon>
                    </Hover>
                  </RowBetween>
                )
              })
            ) : (
              <TYPE.mainLg>{t('analytics.account_search.no_pinned_wallets')}</TYPE.mainLg>
            )}
          </>
        )}
      </AutoColumn>
    </AutoColumn>
  )
}

export default withRouter(AccountSearch)
// export default AccountSearch
