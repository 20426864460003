import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { shortenAddress } from 'utils'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { RoundInfo, SwapVolRank } from 'gql/microservices/trade-competition'
import moment from 'moment'
import { TYPE } from 'theme'

import Top1ImgSrc from 'assets/images/trade_competition/top_1.png'
import Top2ImgSrc from 'assets/images/trade_competition/top_2.png'
import Top3ImgSrc from 'assets/images/trade_competition/top_3.png'
import Popover from 'components/Popover'
import { useMedia } from 'react-use'

const TOP_IMG_ARR = [Top1ImgSrc, Top2ImgSrc, Top3ImgSrc]

const Wrapper = styled.div`
  width: 904px;
  margin: 20px auto 40px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
      width: 100%;
       padding: 10px 20px;
  `}

  box-sizing: border-box;

  .MuiPaper-elevation2 {
    box-shadow: none;
  }
  .MuiTableContainer-root {
    width: 440px;
    background: ${({ theme }) => (theme.isDark ? '#080c2e' : '#ffffff')};
    border-radius: 10px;
    padding: 22px;

    &.single {
      width: 800px;
    }
  }
  .MuiTable-root {
    background-color: transparent;
  }
  .MuiTableHead-root {
    height: 30px;
  }
  .MuiTableCell-head {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.5) !important;
    line-height: 20px !important;
  }
  .MuiTableCell-root {
    border: none;
    height: 48px;
    padding: 0;
    text-align: center;
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      font-size: 0.75rem !important;
    `};

    & .inner-box {
      color: ${({ theme }) => theme.v2.t01};
    }
  }
  .MuiTypography-body2 {
    color: #fff;
  }
  .MuiTablePagination-toolbar {
    height: 40px;
    min-height: 40px;
    justify-content: center;
    display: flex;
  }
  .MuiTablePagination-input {
    display: none;
  }
  .MuiTypography-caption {
    color: #fff;
    line-height: 24px;
  }
  .MuiTableFooter-root {
    padding-top: 24px;
    background-color: ${({ theme }) => theme.bg6}!important;
  }
  .MuiTablePagination-spacer {
    flex: initial !important;
  }
  .MuiIconButton-root {
    padding: 0;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    margin: 0 8px;
    background-color: ${({ theme }) => theme.bg7}!important;
  }
  .MuiIcon-root {
    color: ${({ theme }) => theme.text1};
    font-size: 14px;
  }
  /* .MuiTableBody-root {
    height: 210px;
  } */

  .MuiTableRow-root {
    & > .MuiTableCell-body:first-child > .inner-box {
      border-top-left-radius: 9px;
      border-bottom-left-radius: 9px;
    }

    & > .MuiTableCell-body:last-child > .inner-box {
      border-top-right-radius: 9px;
      border-bottom-right-radius: 9px;
    }
  }

  .MuiTableCell-body {
    height: 56px;
    /* border-bottom: 8px solid ${({ theme }) => (theme.isDark ? '#080c2e' : '#ffffff')}; */

    .inner-box {
      background-color: ${({ theme }) => (theme.isDark ? 'rgba(45, 45, 78,0.54)' : '#f1f1f3')};
      height: 48px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .MuiSvgIcon-root {
    color: #fff;
  }
`

const TableWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const Pagination = styled.div`
  display: flex;
  margin: 24px auto 0;
  align-items: center;
  justify-content: space-between;
  width: 150px;

  font-size: 12px;

  .iconfont {
    font-size: 14px;
    font-family: 'Poppins-Regular';
    color: ${({ theme }) => theme.v2.t01};
    cursor: pointer;
  }
`

const TimeBox = styled.div`
  width: 100%;
  height: 60px;
  background: ${({ theme }) =>
    theme.isDark ? 'linear-gradient(90deg, rgba(28, 30, 63, 0.04) 0%, rgba(28, 30, 63, 0.39) 100%)' : '#8d98f9'};
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  > .icon-time {
    font-size: 23px;
    background: ${({ theme }) =>
      theme.isDark ? 'linear-gradient(234deg, #00cfff 0%, #34C1FF 53%, #904BF9 100%)' : '#00cfff'};
    -webkit-background-clip: text;
    color: transparent;
  }

  .icon-helper {
    font-size: 22px;
    color: #ffffff;
    cursor: pointer;
    margin-left: 4px;
  }

  > .iconfont {
    margin: 0 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;

    .iconfont {
      display: none;
    }
  `}
`

const Helper = ({ content }: { content: string }) => {
  const [show, setShow] = useState<boolean>(false)

  const Content = (
    <TYPE.v2Main fontSize={12} color="#ffffff" padding="10px 12px" maxWidth={240}>
      {content}
    </TYPE.v2Main>
  )

  return (
    <Popover show={show} placement="right" content={Content}>
      <i className="iconfont icon-helper" onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} />
    </Popover>
  )
}

const pageSize = 10

interface Props {
  data: SwapVolRank[]
  loading: boolean
  roundInfo?: RoundInfo
}

export default function ScoreRanking({ data, loading, roundInfo }: Props) {
  const [page, setPage] = useState(0)
  const isMobile = useMedia('(max-width: 768px)')
  const realPageSize = useMemo(() => (isMobile ? 10 : 20), [isMobile])
  const totalPage = useMemo(() => {
    return Math.round(data.length / realPageSize) ? Math.round(data.length / realPageSize) : 1
  }, [data, realPageSize])

  function pre() {
    if (page > 0) {
      setPage(page - 1)
    }
  }

  function next() {
    if (page < totalPage - 1) {
      setPage(page + 1)
    }
  }

  const pageData = useMemo(() => {
    return data.slice(realPageSize * page, realPageSize * (page + 1))
  }, [page, data, realPageSize])

  const array1 = useMemo(() => {
    return pageData.slice(0, pageSize).map((item, index) => {
      return {
        ...item,
        index: page * realPageSize + index + 1,
      }
    })
  }, [pageData, page, realPageSize])

  const array2 = useMemo(() => {
    return pageData.slice(pageSize, realPageSize).map((item, index) => {
      return {
        ...item,
        index: page * realPageSize + index + pageSize + 1,
      }
    })
  }, [pageData, page, realPageSize])

  const showLoading = useMemo(() => {
    return loading && !data.length
  }, [data, loading])

  return (
    <Wrapper>
      <TimeBox>
        {roundInfo && (
          <>
            <i className="iconfont icon-time" />

            <TYPE.v2Main fontSize={14} color="#ffffff" marginRight={28}>
              Start from: {moment.utc(roundInfo.startTime * 1000).format('YYYY-MM-DD HH:mm(UTC)')}
            </TYPE.v2Main>

            <TYPE.v2Main fontSize={14} color="#ffffff">
              Latest Updated: {moment.utc(roundInfo.lastUpdateTime * 1000).format('YYYY-MM-DD HH:mm(UTC)')}
            </TYPE.v2Main>

            <Helper
              content={`Update around every ${
                roundInfo.updateTimeInterval / 60
              } minutes, and will delay the retrieval by 12 minutes`}
            />
          </>
        )}
      </TimeBox>
      <TableWrapper>
        <TableContainer
          classes={{
            root: !isMobile && !showLoading && array2.length === 0 ? 'single' : '',
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ minWidth: 40 }}>
                  <TYPE.mainLg className="inner-box" fontSize={18} lineHeight={1.2}>
                    Rank
                  </TYPE.mainLg>
                </TableCell>
                <TableCell align="center" style={{ minWidth: 100 }}>
                  <TYPE.mainLg className="inner-box" fontSize={18} lineHeight={1.2}>
                    Address
                  </TYPE.mainLg>
                </TableCell>
                <TableCell align="center" style={{ minWidth: 100 }}>
                  <TYPE.mainLg className="inner-box" fontSize={18} lineHeight={1.2}>
                    Volume
                  </TYPE.mainLg>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!showLoading ? (
                array1.length ? (
                  array1.map((item) => (
                    <TableRow key={item.rank}>
                      <TableCell align="center">
                        {item.rank > 3 && (
                          <TYPE.v2Main className="inner-box" fontSize={16}>
                            {item.rank}
                          </TYPE.v2Main>
                        )}

                        {item.rank <= 3 && (
                          <div className="inner-box">
                            <img style={{ height: 31, width: 'auto' }} src={TOP_IMG_ARR[item.rank - 1]} alt="" />
                          </div>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <TYPE.v2Main className="inner-box" fontSize={16}>
                          {shortenAddress(item.userAddress)}
                        </TYPE.v2Main>
                      </TableCell>
                      <TableCell align="center">
                        <TYPE.v2Main className="inner-box" fontSize={16}>
                          $ {item.swapVolume.toLocaleString('en-US', { maximumFractionDigits: 0 })}
                        </TYPE.v2Main>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={3}>
                      Coming soon
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    {data.length ? 'Coming soon' : 'Loading...'}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!isMobile && (showLoading || (!showLoading && array2.length > 0)) && (
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ minWidth: 40 }}>
                    <TYPE.mainLg className="inner-box" fontSize={18} lineHeight={1.2}>
                      Rank
                    </TYPE.mainLg>
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 100 }}>
                    <TYPE.mainLg className="inner-box" fontSize={18} lineHeight={1.2}>
                      Address
                    </TYPE.mainLg>
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 100 }}>
                    <TYPE.mainLg className="inner-box" fontSize={18} lineHeight={1.2}>
                      Volume
                    </TYPE.mainLg>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!showLoading ? (
                  array2.map((item) => (
                    <TableRow key={item.rank}>
                      <TableCell align="center">
                        <TYPE.v2Main className="inner-box" fontSize={16}>
                          {item.rank}
                        </TYPE.v2Main>
                      </TableCell>
                      <TableCell align="center">
                        <TYPE.v2Main className="inner-box" fontSize={16}>
                          {shortenAddress(item.userAddress)}
                        </TYPE.v2Main>
                      </TableCell>
                      <TableCell align="center">
                        <TYPE.v2Main className="inner-box" fontSize={16}>
                          $ {item.swapVolume.toLocaleString('en-US', { maximumFractionDigits: 0 })}
                        </TYPE.v2Main>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={3}>
                      {data.length ? 'Coming soon' : 'Loading...'}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </TableWrapper>
      {/* {roundInfo && (
        <>
          {roundInfo.lastUpdateTime !== -1 && (
            <LastUpdate>
              Latest update: {moment.utc(roundInfo.lastUpdateTime * 1000).format('YYYY-MM-DD HH:mm(UTC)')}
            </LastUpdate>
          )}
          <LastUpdate>
            Update around every {roundInfo.updateTimeInterval / 60} minutes, and will delay the retrieval by 12 minutes
          </LastUpdate>
        </>
      )} */}
      <Pagination>
        <i onClick={pre} className={cx('iconfont', 'icon-page-prev', page === 0 ? 'disabled' : '')} />
        <TYPE.v2Main fontSize={12}>
          Page {page + 1} of {totalPage}
        </TYPE.v2Main>
        <i onClick={next} className={cx('iconfont', 'icon-page-next', page === totalPage - 1 ? 'disabled' : '')} />
      </Pagination>
    </Wrapper>
  )
}
