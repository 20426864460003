/* eslint-disable react-hooks/exhaustive-deps */
import { ChainId } from '@netswap/sdk'
import { NETWORK_CHAIN_ID } from 'connectors'
import getUserBoostedFarmPools from 'gql/subgraph/user-boosted-farm-pools'
import { useActiveWeb3React } from 'hooks'
import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import { setUserPools } from '../index'

export default function useGetUserBoostedFarmPools() {
  const { chainId, account } = useActiveWeb3React()
  const dispatch = useAppDispatch()
  const { userPools } = useAppSelector(state => state.boostedFarm)
  async function getPoolsData() {
    if (!account) return
    const res = await getUserBoostedFarmPools(account)
    const cid = chainId || NETWORK_CHAIN_ID
    if (res.length) {
      dispatch(
        setUserPools({
          chainId: cid,
          account,
          data: res
        })
      )
    }
  }

  useEffect(() => {
    getPoolsData()
    // let interval = setInterval(() => {
    //   getPoolsData()
    // }, 60000)

    // return () => {
    //   if (interval) {
    //     clearInterval(interval)
    //   }
    // }
  }, [account])

  return useMemo(() => {
    if (account) {
      return userPools && userPools[account] ? userPools[account][(chainId || NETWORK_CHAIN_ID) as ChainId] : []
    }
    return []
  }, [userPools, account, chainId])
}
