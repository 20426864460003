/* eslint-disable react-hooks/exhaustive-deps */
import { ChainId } from '@netswap/sdk'
import { NETWORK_CHAIN_ID } from 'connectors'
import getUserPads, { SubgraphPad } from 'gql/subgraph/user-pads'
import { useActiveWeb3React } from 'hooks'
import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import { setUserPads } from '../index'

export default function useGetUserPads() {
  const { chainId, account } = useActiveWeb3React()
  const dispatch = useAppDispatch()
  const { launchpad } = useAppSelector(state => state.netswapData)
  async function getUserPadsFunc() {
    if (!account) return
    const res = await getUserPads(account)
    const cid = chainId || NETWORK_CHAIN_ID
    if (res.length) {
      dispatch(
        setUserPads({
          chainId: cid,
          account,
          data: res,
          method: 'set'
        })
      )
    }
  }

  useEffect(() => {
    getUserPadsFunc()
    let interval = setInterval(() => {
      getUserPadsFunc()
    }, 15000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [account])

  return useMemo(() => {
    if (account) {
      return launchpad.userPads[account] ? launchpad.userPads[account][(chainId || NETWORK_CHAIN_ID) as ChainId] : []
    }
    return []
  }, [launchpad.userPads, account, chainId])
}

export function useUpdateUserFarmLPPool() {
  const { chainId, account } = useActiveWeb3React()
  const dispatch = useAppDispatch()
  function update({ pids, method }: { pids: SubgraphPad[]; method: 'add' | 'remove' }) {
    if (!account) return
    const cid = chainId || NETWORK_CHAIN_ID
    dispatch(
      setUserPads({
        chainId: cid,
        account: account,
        data: pids,
        method
      })
    )
  }

  return update
}
