import { useMemo } from 'react'
import useGetLiquitidyPools from './useGetPoolList'

export default function useGetPoolInfoByPairAddress(address?: string) {
  const { pools: allPair, loading } = useGetLiquitidyPools()

  const pair = useMemo(() => {
    if (!address) return undefined
    const addr = address.toLowerCase()
    return allPair.filter(item => item.id === addr)[0]
  }, [allPair, address])

  return {
    pair,
    loading
  }
}
