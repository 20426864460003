import { gql } from '@apollo/client'
import { MicroServicesClient } from '../index'

export interface Overview {
  totalTvl: number
  liquidityTvl: number
  stakeTotalTvl: number
  launchpadTotalTvl: number
  metisPrice: number
  nettPrice: number
  totalPairs: number
  wNettTvl: number
  farmTvl: number
}

export default function getOverview(): Promise<Overview | undefined> {
  return new Promise((resolve, reject) => {
    MicroServicesClient()
      .query<{
        overview: Overview
      }>({
        query: gql`
          query overview {
            overview {
              totalTvl
              liquidityTvl
              stakeTotalTvl
              launchpadTotalTvl
              metisPrice
              nettPrice
              totalPairs
              wNettTvl
              farmTvl
            }
          }
        `,
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        let returnData = undefined
        let { data } = res
        if (data.overview) {
          returnData = data.overview
        }
        resolve(returnData)
      })
      .catch((e: any) => {
        console.error(e)
        resolve(undefined)
      })
  })
}
