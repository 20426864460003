import React from 'react'
// import styled from 'styled-components'
// import { useMedia } from 'react-use'
// import AdPcSrc from 'assets/images/ads/ad.pc.gif'
// import AdMobileSrc from 'assets/images/ads/ad.mobile.gif'

// const Wrapper = styled.div<{ isActive: boolean }>`
//   width: 100%;
//   color: #ffffff;
//   /* position: fixed; */
//   /* top: 0; */
//   /* z-index: 99; */
//   position: relative;
//   display: ${({ isActive }) => (isActive ? 'block' : 'none')};
// `

// const CloseIcon = styled.i`
//   color: #ffffff;
//   font-size: 24px;
//   position: absolute;
//   top: 16px;
//   right: 6%;
//   cursor: pointer;

//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     font-size: 18px;
//     top: 6px;
//     right: 2px;
//   `}
// `

// const AdImg = styled.img<{ isMobile: boolean }>`
//   /* width: auto;
//   height: ${({ isMobile }) => (isMobile ? '40px' : '88px')}; */
//   height: auto;
//   width: 100%;
// `

export interface Props {
  onClose(): void
  show: boolean
}

// export default function Advertisement(props: Props) {
//   const isMobile = useMedia('(max-width: 768px)')

//   return (
//     <Wrapper isActive={props.show}>
//       <a
//         href="https://giftcards.cryptocart.cc/"
//         target="__blank"
//         style={{ width: '100%', display: 'flex', justifyContent: 'center', background: '#000' }}
//       >
//         <AdImg isMobile={isMobile} src={isMobile ? AdMobileSrc : AdPcSrc} alt="advertisement" />
//       </a>
//       <CloseIcon className="iconfont icon-close" onClick={props.onClose} />
//     </Wrapper>
//   )
// }
export default function Advertimsement(props: Props) {
  return <></>
}
