import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { createChart, CrosshairMode, IChartApi, UTCTimestamp } from 'lightweight-charts'
import dayjs from 'dayjs'
import cx from 'classnames'
import utc from 'dayjs/plugin/utc'
import { formattedPrice } from 'utils'
import { rebuildCandlesData } from 'utils/candle'
import styled from 'styled-components'
import { Candle, PERIODS, Token, Period } from 'gql/subgraph/dexcandles'
import { useDarkModeManager } from 'state/user/hooks'
import Row from 'components/Row'
import { theme, TYPE } from 'theme'

dayjs.extend(utc)

const ChartContainer = styled.div`
  width: 100%;
  flex: 1;
`

const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Tooptip = styled.div`
  width: 100%;
  margin-bottom: 26px;
`

const RowMid = styled(Row)`
  align-items: center;
`

interface Props {
  candles: Candle[]
  usd?: boolean
  baseToken?: Token | null
}

const LastData = ({ data, usd = false, baseToken }: { data: Candle; usd?: boolean; baseToken?: Token | null }) => {
  const [darkMode] = useDarkModeManager()
  const themeObject = useMemo(() => theme(darkMode), [darkMode])
  const [period, setPeriod] = useState<Period>(PERIODS[0])
  const { t } = useTranslation()

  const open = Number(data.open)
  const close = Number(data.close)

  useEffect(() => {
    if (!data) {
      setPeriod(PERIODS[0])
    } else {
      for (const p of PERIODS) {
        if (p.value === data.period) {
          setPeriod(p)
        }
      }
    }
  }, [data, setPeriod])

  const percent = (((close - open) / open) * 100).toFixed(2)

  return (
    <RowMid>
      <TYPE.main fontSize={12}>
        <i
          className={cx('iconfont', open > close ? 'icon-go-down' : 'icon-go-up')}
          style={{ color: open > close ? themeObject.v2.t07 : themeObject.v2.t08, fontSize: 12 }}
        />
      </TYPE.main>

      <TYPE.main fontSize={12} style={{ marginLeft: 6, color: open > close ? themeObject.v2.t07 : themeObject.v2.t08 }}>
        {formattedPrice(close - open, usd)}
        {!usd && baseToken ? baseToken.symbol : ''}({percent}%)
      </TYPE.main>

      <TYPE.main fontSize={12} style={{ marginLeft: 8 }}>
        {t('chart.past_time', { period: t(`chart.period.${period.key}`) })}
      </TYPE.main>
    </RowMid>
  )
}

const HoverData = ({ data, usd }: { data: Candle; usd: boolean }) => {
  const [darkMode] = useDarkModeManager()
  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  return (
    <RowMid>
      <TYPE.main fontSize={12} style={{ marginRight: 24 }}>
        {dayjs(data.time * 1000).format('MMM DD, YYYY hh:mm A')}
      </TYPE.main>

      <TYPE.main fontSize={12} style={{ color: themeObject.v2.t08, marginRight: 12 }}>
        H: {formattedPrice(Number(data.high), usd)}
      </TYPE.main>

      <TYPE.main fontSize={12} style={{ color: themeObject.v2.t07 }}>
        L: {formattedPrice(Number(data.low), usd)}
      </TYPE.main>
    </RowMid>
  )
}

export default function PairChart({ candles, usd = false, baseToken }: Props) {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [chartCreated, setChartCreated] = useState<IChartApi | null>(null)
  const [darkMode] = useDarkModeManager()
  const [hoverData, setHoverData] = useState<Candle | null>(null)
  const [lastData, setLastData] = useState<Candle | null>(null)

  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  useEffect(() => {
    if (chartRef.current && candles) {
      // tmp beautify candles data
      const rebuildCandles = rebuildCandlesData(candles)
      const chart = createChart(chartRef.current, {
        width: chartRef.current.clientWidth,
        height: chartRef.current.clientHeight,
        layout: {
          backgroundColor: 'transparent',
          textColor: themeObject.v2.t01,
        },
        grid: {
          horzLines: {
            color: 'rgba(255,255,255,0)',
          },
          vertLines: {
            color: 'rgba(255,255,255,0)',
          },
        },
        leftPriceScale: {
          borderColor: '#AAAAB7',
          visible: true,
        },
        rightPriceScale: {
          visible: false,
        },
        crosshair: {
          mode: CrosshairMode.Normal,
        },
        timeScale: {
          borderColor: '#AAAAB7',
          timeVisible: true,
          secondsVisible: false,
        },
        localization: {
          priceFormatter: (val: any) => formattedPrice(val, usd),
          timeFormatter: (val: any) => dayjs(new Date(val * 1000)).format('YYYY-MM-DD HH:mm:ss'),
          // dateFormat: 'DD/MM',
          locale: 'en',
        },
      })
      const series = chart.addCandlestickSeries({
        priceFormat: {
          type: 'volume',
        },
        priceScaleId: 'left',
        priceLineColor: '#AAAAB7',
        baseLineColor: '#78788C',
        borderUpColor: '#78788C',
        upColor: '#42FFBC',
        downColor: '#FF6969',
        wickUpColor: '#42FFBC',
        wickDownColor: '#FF6969',
      })

      series.setData(
        rebuildCandles.map((candle) => {
          return {
            time: candle.time as UTCTimestamp,
            // time: dayjs(candle.time * 1000).format('YYYY-MM-DD HH:mm:ss'),
            open: Number(candle.open),
            close: Number(candle.close),
            low: Number(candle.low),
            high: Number(candle.high),
          }
        })
      )

      chart.subscribeCrosshairMove(function (param) {
        if (
          param === undefined ||
          param.time === undefined ||
          param.point?.x === undefined ||
          param.point?.y === undefined ||
          param.point.x < 0 ||
          param.point.x > (chartRef.current?.clientWidth || 0) ||
          param.point.y < 0 ||
          param.point.y > (chartRef.current?.clientHeight || 0)
        ) {
          setHoverData(null)
        } else {
          const data = param.seriesPrices.get(series)
          setHoverData({
            time: param.time,
            ...(data as any),
          })
        }
      })

      setChartCreated(chart)
      return () => {
        setChartCreated(null)
        chart.remove()
      }
    }

    return
  }, [candles, chartRef, themeObject, usd, setHoverData])

  useEffect(() => {
    if (chartRef?.current && chartCreated) {
      let chartDom = chartRef.current
      const observerCallback: ResizeObserverCallback = (entries: ResizeObserverEntry[]) => {
        window.requestAnimationFrame((): void | undefined => {
          if (!Array.isArray(entries) || !entries.length) {
            return
          }
          for (const entry of entries) {
            chartCreated.resize(entry.target.clientWidth - 20, entry.target.clientHeight - 20)
          }
        })
      }
      const observer = new ResizeObserver(observerCallback)

      try {
        observer.observe(chartDom)
      } catch (e) {
        console.error(e)
      }

      return () => observer.unobserve(chartDom)
    }

    return
  }, [chartRef, chartCreated])

  useEffect(() => {
    // tmp beautify candles data
    const _candles = rebuildCandlesData(candles)
    setLastData(_candles.length > 0 ? _candles[_candles.length - 1] : null)
  }, [candles, setLastData])

  return (
    <Wrapper>
      <Tooptip>
        {!hoverData ? (
          lastData && <LastData data={lastData} usd={usd} baseToken={baseToken} />
        ) : (
          <HoverData data={hoverData} usd={usd} />
        )}
      </Tooptip>
      <ChartContainer ref={chartRef} />
    </Wrapper>
  )
}
