import React, { useMemo } from 'react'
import styled from 'styled-components'
import { TextProps } from 'rebass'
import { useTranslation } from 'react-i18next'

import { useDarkModeManager } from 'state/user/hooks'
import { TYPE, theme } from 'theme'
import Prize1ImgSrc from 'assets/images/trade_competition/prize_1.png'
import Prize2ImgSrc from 'assets/images/trade_competition/prize_2.png'
import Prize3ImgSrc from 'assets/images/trade_competition/prize_3.png'
import PodiumImgDarkSrc from 'assets/images/trade_competition/podium.dark.png'
import PodiumImgLightSrc from 'assets/images/trade_competition/podium.light.png'
import { useMedia } from 'react-use'

const Wrapper = styled.div`
  width: 100%;
  padding-top: 50px;
  max-width: 821px;
  margin: 0 auto;
`

const Top3Box = styled.div`
  width: 604px;
  position: relative;
  display: flex;
  margin: 0 auto;
  align-items: flex-end;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: auto;
  `}
`

const PrizeBox = styled.div`
  flex: 1;
  background: linear-gradient(180deg, rgba(143, 112, 255, 0) 0%, rgba(94, 59, 220, 0.29) 100%);
  z-index: 2;
  display: flex;
  align-items: flex-end;
`

const InnerBox = styled.div<{ offsetY?: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  transform: translateY(-${({ offsetY = 0 }) => offsetY}px);
`

const PrizeImg = styled.img`
  height: 138px;
  width: auto;
  margin-bottom: 9px;
`

const PodiumImg = styled.img`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
`

const RangeItem = styled.div`
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex: 33%;
    border-right: none;
    margin-bottom: 8px;
  `}
`

const RankRangeBox = styled.div`
  height: 153px;
  width: calc(100% - 80px);
  margin: 0 auto;
  z-index: 2;
  transform: translateY(-36px);
  background: ${({ theme }) => (theme.isDark ? '#2d2d4e' : '#8d98f9')};
  border-radius: 8px;
  display: flex;
  position: relative;
  padding: 28px 0;

  ${RangeItem}:last-child {
    border-right-width: 0;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: auto;
    transform: translateY(-20px);
    flex-wrap: wrap;
    padding-top: 60px;
  `}
`

const RangeTitleBox = styled.div`
  border-radius: 10px;
  border: 1px solid #00cfff;
  color: #00cfff;
  padding: 4px 16px;
  font-size: 10px;
  font-family: 'Poppins-Medium';
  font-weight: 500;
  position: absolute;
  top: 14px;
  left: 12px;
  transform: scale(0.8);
`

export default function TotalPrizes() {
  const { t } = useTranslation()
  const [darkMode] = useDarkModeManager()
  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  const isMobile = useMedia('(max-width: 768px)')

  const sizeRate = isMobile ? 0.8 : 1

  const PodiumImgSrc = useMemo(() => {
    return themeObject.isDark ? PodiumImgDarkSrc : PodiumImgLightSrc
  }, [themeObject])

  const RangeItemLabelStyle: TextProps = {
    fontSize: 20 * sizeRate,
    color: '#ffffff',
    marginBottom: 14 * sizeRate,
  }

  const RangeItemValueStyle: TextProps = {
    fontSize: 26 * sizeRate,
    color: '#ffffff',
    lineHeight: 1,
  }

  return (
    <Wrapper>
      <Top3Box>
        <PrizeBox style={{ paddingBottom: 64 * sizeRate }}>
          <InnerBox offsetY={13}>
            <PrizeImg src={Prize2ImgSrc} alt="" />

            <TYPE.title fontSize={30 * sizeRate} color="#ffffff">
              $10,000
            </TYPE.title>
          </InnerBox>
        </PrizeBox>

        <PrizeBox style={{ paddingBottom: 104 * sizeRate }}>
          <InnerBox>
            <PrizeImg src={Prize1ImgSrc} alt="" />

            <TYPE.title fontSize={30 * sizeRate} color="#ffffff">
              $15,000
            </TYPE.title>
          </InnerBox>
        </PrizeBox>

        <PrizeBox style={{ paddingBottom: 42 * sizeRate }}>
          <InnerBox offsetY={24}>
            <PrizeImg src={Prize3ImgSrc} alt="" />

            <TYPE.title fontSize={30 * sizeRate} color="#ffffff">
              $8,000
            </TYPE.title>
          </InnerBox>
        </PrizeBox>

        <PodiumImg src={PodiumImgSrc} alt="" />
      </Top3Box>

      <RankRangeBox>
        <RangeTitleBox>{t('trade_competition.rank_range')}</RangeTitleBox>

        <RangeItem>
          <TYPE.mainLg {...RangeItemLabelStyle}># 4</TYPE.mainLg>

          <TYPE.title {...RangeItemValueStyle}>$6,000</TYPE.title>
        </RangeItem>

        <RangeItem>
          <TYPE.mainLg {...RangeItemLabelStyle}># 5</TYPE.mainLg>

          <TYPE.title {...RangeItemValueStyle}>$4,000</TYPE.title>
        </RangeItem>

        <RangeItem>
          <TYPE.mainLg {...RangeItemLabelStyle}># 6-20</TYPE.mainLg>

          <TYPE.title {...RangeItemValueStyle}>$20,000</TYPE.title>
        </RangeItem>

        <RangeItem>
          <TYPE.mainLg {...RangeItemLabelStyle}># 21-50</TYPE.mainLg>

          <TYPE.title {...RangeItemValueStyle}>$18,500</TYPE.title>
        </RangeItem>

        <RangeItem>
          <TYPE.mainLg {...RangeItemLabelStyle}># 51-100</TYPE.mainLg>

          <TYPE.title {...RangeItemValueStyle}>$18,500</TYPE.title>
        </RangeItem>
      </RankRangeBox>
    </Wrapper>
  )
}
