import React, { useState, useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Row, { RowFixed } from 'components/Row'
import TokenLogo from 'components/TokenImage'
import { BasicLink } from '../Link'

import { useAllTokenData, useTokenData } from 'hooks/infos/TokenData'
import { useAllPairData, usePairData } from 'hooks/infos/PairData'
import DoubleTokenLogo from '../DoubleLogo'
import { useMedia } from 'react-use'
import { useAllPairsInNetswap, useAllTokensInNetswap } from 'hooks/infos/GlobalData'
import { OVERVIEW_TOKEN_BLACKLIST, PAIR_BLACKLIST } from 'constants/index'

import { transparentize } from 'polished'
import { ExchangeSubgraph as client } from 'gql/index'
import { PairBaseInfo, PAIR_SEARCH, TokenBaseInfo, TOKEN_SEARCH } from 'gql/subgraph/analytics'
import FormattedName from '../FormattedName'
import { TYPE } from 'theme'

const Container = styled.div`
  /* height: 48px; */
  z-index: 2;
  position: relative;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`

const Wrapper = styled.div<{
  small?: boolean
  open?: boolean
}>`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: ${({ small }) => (small ? '9px 12px' : '12px 16px')};
  border-radius: 12px;
  background: ${transparentize(0.8, '#ffffff')};
  border-bottom-right-radius: ${({ open }) => (open ? '0px' : '12px')};
  border-bottom-left-radius: ${({ open }) => (open ? '0px' : '12px')};
  z-index: 9999;
  width: 100%;
  min-width: 300px;
  box-sizing: border-box;
  @media screen and (max-width: 500px) {
    background: ${transparentize(0.2, '#ffffff')};
  }
`
const Input = styled.input<{
  large?: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  background: none;
  border: none;
  outline: none;
  width: 100%;
  color: #ffffff;
  font-size: ${({ large }) => (large ? '20px' : '14px')};

  ::placeholder {
    color: ${({ theme }) => theme.v2.t02};
    font-size: 16px;
  }

  @media screen and (max-width: 640px) {
    ::placeholder {
      font-size: 1rem;
    }
  }
`

const SearchIcon = styled.i`
  font-size: 24px;
  margin-right: 0.5rem;
  position: absolute;
  right: 10px;
  pointer-events: none;
  color: #ffffff;
`

const CloseIcon = styled.i`
  font-size: 24px;
  margin-right: 0.5rem;
  position: absolute;
  right: 10px;
  color: #ffffff;
  :hover {
    cursor: pointer;
  }
`

const Menu = styled.div<{
  hide?: boolean
}>`
  display: flex;
  flex-direction: column;
  z-index: 9999;
  width: 100%;
  top: 38px;
  max-height: 540px;
  overflow: auto;
  left: 0;
  padding-bottom: 20px;
  background: ${({ theme }) => theme.v2.bg04};
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  display: ${({ hide }) => hide && 'none'};
  position: absolute;
`

const MenuItem = styled(Row)`
  padding: 1rem;
  font-size: 0.85rem;
  color: ${({ theme }) => theme.v2.t01};
  & > * {
    margin-right: 6px;
  }
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => transparentize(0.4, theme.v2.t10)};
    color: #ffffff;
  }
`

const Heading = styled(Row)<{
  hide?: boolean
}>`
  padding: 1rem;
  display: ${({ hide = false }) => hide && 'none'};
`

const Gray = styled.span`
  color: #888d9b;
`

const Orange = styled.span`
  color: ${({ theme }) => theme.v2.t05};
  :hover {
    cursor: pointer;
  }
`

export const Search = ({ small = false }: { small?: boolean }) => {
  const { t } = useTranslation()
  let allTokens = useAllTokensInNetswap()
  const allTokenData = useAllTokenData()

  let allPairs = useAllPairsInNetswap()
  const allPairData = useAllPairData()

  const [showMenu, toggleMenu] = useState(false)
  const [value, setValue] = useState('')
  const [, toggleShadow] = useState(false)
  const [, toggleBottomShadow] = useState(false)

  // fetch new data on tokens and pairs if needed
  useTokenData(value)
  usePairData(value)

  const below700 = useMedia('(max-width: 700px)')
  const below470 = useMedia('(max-width: 470px)')
  const below410 = useMedia('(max-width: 410px)')

  useEffect(() => {
    if (value !== '') {
      toggleMenu(true)
    } else {
      toggleMenu(false)
    }
  }, [value])

  const [searchedTokens, setSearchedTokens] = useState<TokenBaseInfo[]>([])
  const [searchedPairs, setSearchedPairs] = useState<PairBaseInfo[]>([])

  useEffect(() => {
    async function fetchData() {
      try {
        if (value?.length > 0) {
          let tokens = await client().query<{
            asSymbol: TokenBaseInfo[]
            asName: TokenBaseInfo[]
            asAddress: TokenBaseInfo[]
          }>({
            variables: {
              value: value ? value.toUpperCase() : '',
              id: value
            },
            query: TOKEN_SEARCH
          })

          let pairs = await client().query<{
            as0: PairBaseInfo[]
            as1: PairBaseInfo[]
            asAddress: PairBaseInfo[]
          }>({
            query: PAIR_SEARCH,
            variables: {
              tokens: tokens.data.asSymbol?.map(t => t.id),
              id: value
            }
          })
          setSearchedPairs(pairs.data.as0.concat(pairs.data.as1).concat(pairs.data.asAddress))
          let foundTokens = tokens.data.asSymbol.concat(tokens.data.asAddress).concat(tokens.data.asName)
          setSearchedTokens(foundTokens)
        }
      } catch (e) {
        console.log(e)
      }
    }
    fetchData()
  }, [value])

  function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
  }

  // add the searched tokens to the list if now found yet
  allTokens = allTokens.concat(
    searchedTokens.filter(searchedToken => {
      let included = false
      allTokens.map(token => {
        if (token.id === searchedToken.id) {
          included = true
        }
        return true
      })
      return !included
    })
  )

  let uniqueTokens: TokenBaseInfo[] = []
  let found: {
    [key: string]: boolean
  } = {}
  allTokens &&
    allTokens.map(token => {
      if (!found[token.id]) {
        found[token.id] = true
        uniqueTokens.push(token)
      }
      return true
    })

  allPairs = allPairs.concat(
    searchedPairs.filter(searchedPair => {
      let included = false
      allPairs.map(pair => {
        if (pair.id === searchedPair.id) {
          included = true
        }
        return true
      })
      return !included
    })
  )

  let uniquePairs: PairBaseInfo[] = []
  let pairsFound: {
    [key: string]: boolean
  } = {}
  allPairs &&
    allPairs.map(pair => {
      if (!pairsFound[pair.id]) {
        pairsFound[pair.id] = true
        uniquePairs.push(pair)
      }
      return true
    })

  const filteredTokenList = useMemo(() => {
    return uniqueTokens
      ? uniqueTokens
          .sort((a, b) => {
            if (OVERVIEW_TOKEN_BLACKLIST.includes(a.id)) {
              return 1
            }
            if (OVERVIEW_TOKEN_BLACKLIST.includes(b.id)) {
              return -1
            }
            const tokenA = allTokenData[a.id]
            const tokenB = allTokenData[b.id]
            if (tokenA?.oneDayVolumeUSD && tokenB?.oneDayVolumeUSD) {
              return tokenA.oneDayVolumeUSD > tokenB.oneDayVolumeUSD ? -1 : 1
            }
            if (tokenA?.oneDayVolumeUSD && !tokenB?.oneDayVolumeUSD) {
              return -1
            }
            if (!tokenA?.oneDayVolumeUSD && tokenB?.oneDayVolumeUSD) {
              return tokenA?.totalLiquidity > tokenB?.totalLiquidity ? -1 : 1
            }
            return 1
          })
          .filter(token => {
            if (OVERVIEW_TOKEN_BLACKLIST.includes(token.id)) {
              return false
            }
            const regexMatches = Object.keys(token).map(tokenEntryKey => {
              const isAddress = value.slice(0, 2) === '0x'
              if (tokenEntryKey === 'id' && isAddress) {
                return token[tokenEntryKey].match(new RegExp(escapeRegExp(value), 'i'))
              }
              if (tokenEntryKey === 'symbol' && !isAddress) {
                return token[tokenEntryKey].match(new RegExp(escapeRegExp(value), 'i'))
              }
              if (tokenEntryKey === 'name' && !isAddress) {
                return token[tokenEntryKey].match(new RegExp(escapeRegExp(value), 'i'))
              }
              return false
            })
            return regexMatches.some(m => m)
          })
      : []
  }, [allTokenData, uniqueTokens, value])

  const filteredPairList = useMemo(() => {
    return uniquePairs
      ? uniquePairs
          .sort((a, b) => {
            const pairA = allPairData[a.id]
            const pairB = allPairData[b.id]
            if (pairA?.trackedReserveMETIS && pairB?.trackedReserveMETIS) {
              return parseFloat(pairA.trackedReserveMETIS) > parseFloat(pairB.trackedReserveMETIS) ? -1 : 1
            }
            if (pairA?.trackedReserveMETIS && !pairB?.trackedReserveMETIS) {
              return -1
            }
            if (!pairA?.trackedReserveMETIS && pairB?.trackedReserveMETIS) {
              return 1
            }
            return 0
          })
          .filter(pair => {
            if (PAIR_BLACKLIST.includes(pair.id)) {
              return false
            }
            if (value && value.includes(' ')) {
              const pairA = value.split(' ')[0]?.toUpperCase()
              const pairB = value.split(' ')[1]?.toUpperCase()
              return (
                (pair.token0.symbol.includes(pairA) || pair.token0.symbol.includes(pairB)) &&
                (pair.token1.symbol.includes(pairA) || pair.token1.symbol.includes(pairB))
              )
            }
            if (value && value.includes('-')) {
              const pairA = value.split('-')[0]?.toUpperCase()
              const pairB = value.split('-')[1]?.toUpperCase()
              return (
                (pair.token0.symbol.includes(pairA) || pair.token0.symbol.includes(pairB)) &&
                (pair.token1.symbol.includes(pairA) || pair.token1.symbol.includes(pairB))
              )
            }
            const regexMatches = Object.keys(pair).map(field => {
              const isAddress = value.slice(0, 2) === '0x'
              if (field === 'id' && isAddress) {
                return pair[field].match(new RegExp(escapeRegExp(value), 'i'))
              }
              if (field === 'token0') {
                return (
                  pair[field].symbol.match(new RegExp(escapeRegExp(value), 'i')) ||
                  pair[field].name.match(new RegExp(escapeRegExp(value), 'i'))
                )
              }
              if (field === 'token1') {
                return (
                  pair[field].symbol.match(new RegExp(escapeRegExp(value), 'i')) ||
                  pair[field].name.match(new RegExp(escapeRegExp(value), 'i'))
                )
              }
              return false
            })
            return regexMatches.some(m => m)
          })
      : []
  }, [allPairData, uniquePairs, value])

  useEffect(() => {
    if (Object.keys(filteredTokenList).length > 2) {
      toggleShadow(true)
    } else {
      toggleShadow(false)
    }
  }, [filteredTokenList])

  useEffect(() => {
    if (Object.keys(filteredPairList).length > 2) {
      toggleBottomShadow(true)
    } else {
      toggleBottomShadow(false)
    }
  }, [filteredPairList])

  const [tokensShown, setTokensShown] = useState(3)
  const [pairsShown, setPairsShown] = useState(3)

  function onDismiss() {
    setPairsShown(3)
    setTokensShown(3)
    toggleMenu(false)
    setValue('')
  }

  // refs to detect clicks outside modal
  const wrapperRef = useRef<any>()
  const menuRef = useRef<any>()

  const handleClick = (e: MouseEvent) => {
    if (
      !(menuRef.current && menuRef.current.contains(e.target)) &&
      !(wrapperRef.current && wrapperRef.current.contains(e.target))
    ) {
      setPairsShown(3)
      setTokensShown(3)
      toggleMenu(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })

  return (
    <Container>
      <Wrapper open={showMenu} small={small}>
        <Input
          large={!small}
          type={'text'}
          ref={wrapperRef}
          placeholder={
            small
              ? ''
              : below410
              ? t('analytics.overview_search.short_search_placeholder')
              : below470
              ? t('analytics.overview_search.small_search_placeholder')
              : below700
              ? t('analytics.overview_search.normal_search_placeholder')
              : t('analytics.overview_search.full_search_placeholder')
          }
          value={value}
          onChange={e => {
            setValue(e.target.value)
          }}
          onFocus={() => {
            if (!showMenu) {
              toggleMenu(true)
            }
          }}
        />
        {!showMenu ? (
          <SearchIcon className="iconfont icon-search" />
        ) : (
          <CloseIcon className="iconfont icon-close" onClick={() => toggleMenu(false)} />
        )}
      </Wrapper>
      <Menu hide={!showMenu} ref={menuRef}>
        <Heading>
          <Gray>{t('analytics.pairs')}</Gray>
        </Heading>
        <div>
          {filteredPairList && Object.keys(filteredPairList).length === 0 && (
            <MenuItem>
              <TYPE.body>{t('analytics.overview_search.no_results')}</TYPE.body>
            </MenuItem>
          )}
          {filteredPairList &&
            filteredPairList.slice(0, pairsShown).map(pair => {
              // if (pair?.token0?.id === '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2') {
              //   pair.token0.name = 'METIS (Wrapped)'
              //   pair.token0.symbol = 'METIS'
              // }
              // if (pair?.token1.id === '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2') {
              //   pair.token1.name = 'METIS (Wrapped)'
              //   pair.token1.symbol = 'METIS'
              // }
              return (
                <BasicLink to={'/analytics/pairs/' + pair.id} key={pair.id} onClick={onDismiss}>
                  <MenuItem>
                    <DoubleTokenLogo a0={pair?.token0?.id} a1={pair?.token1?.id} margin={true} />
                    <TYPE.body style={{ marginLeft: '10px' }}>
                      {pair.token0.symbol + '-' + pair.token1.symbol} Pair
                    </TYPE.body>
                  </MenuItem>
                </BasicLink>
              )
            })}
          <Heading
            hide={!(Object.keys(filteredPairList).length > 3 && Object.keys(filteredPairList).length >= pairsShown)}
          >
            <Orange
              onClick={() => {
                setPairsShown(pairsShown + 5)
              }}
            >
              {t('analytics.overview_search.see_more')}
            </Orange>
          </Heading>
        </div>
        <Heading>
          <Gray>{t('analytics.tokens')}</Gray>
        </Heading>
        <div>
          {Object.keys(filteredTokenList).length === 0 && (
            <MenuItem>
              <TYPE.body>{t('analytics.overview_search.no_results')}</TYPE.body>
            </MenuItem>
          )}
          {filteredTokenList.slice(0, tokensShown).map(token => {
            return (
              <BasicLink to={'/analytics/token/' + token.id} key={token.id} onClick={onDismiss}>
                <MenuItem>
                  <RowFixed>
                    <TokenLogo address={token.id} style={{ marginRight: '10px' }} />
                    <FormattedName text={token.name} maxCharacters={20} style={{ marginRight: '6px' }} />
                    (<FormattedName text={token.symbol} maxCharacters={6} />)
                  </RowFixed>
                </MenuItem>
              </BasicLink>
            )
          })}

          <Heading
            hide={!(Object.keys(filteredTokenList).length > 3 && Object.keys(filteredTokenList).length >= tokensShown)}
          >
            <Orange
              onClick={() => {
                setTokensShown(tokensShown + 5)
              }}
            >
              {t('analytics.overview_search.see_more')}
            </Orange>
          </Heading>
        </div>
      </Menu>
    </Container>
  )
}

export default Search
