/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import StakeLP from './components/StakeLP'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { useActiveWeb3React } from 'hooks'
import { useGetBoostNettFarmProxy } from 'hooks/contract/useGetContract'
import useGetVeNettInfo from 'pages/NettStake/hooks/useGetveNettInfo'
import useSend from 'state/contract/hooks/useSend'
import { useContractByAbiAddr } from 'hooks/contract/useContract'
import { RewarderABI } from 'constants/farm'
import { useGetBoostedFarmPoolById } from 'state/BoostedFarm/hooks/useGetBoostFarmPools'

const Wrapper = styled.div``
export default function BoostPool() {
  const { id } = useParams<{
    id: string
  }>()
  const { account } = useActiveWeb3React()
  const pool = useGetBoostedFarmPoolById(id)
  const [userStakeAmount, setUserStakeAmount] = useState(new BigNumber(0))
  const stakeContract = useGetBoostNettFarmProxy()
  const { pendingVeNETT, userVeNETTBalance, pendingVeNettProgress } = useGetVeNettInfo()
  const send = useSend()
  const rewarderContract = useContractByAbiAddr(pool?.rewarder?.id || '', RewarderABI)
  const [pendingNEET, setPendingNETT] = useState(new BigNumber(0))
  const [pendingBonus, setPendingBonus] = useState(new BigNumber(0))
  const [unPaidBounsAmount, setUnpaidBounsAmount] = useState(new BigNumber(0))

  const lpName = useMemo(() => {
    if (!pool) return ''
    return `${pool.pair.token0.symbol} - ${pool.pair.token1.symbol}`
  }, [pool])

  async function getStakeBalance() {
    if (stakeContract && account && pool) {
      try {
        const res = await stakeContract.userInfo(pool.id, account)
        setUserStakeAmount(
          res?.amount.toString() ? new BigNumber(res.amount.toString()).shiftedBy(-18) : new BigNumber(0)
        )
      } catch (e) {
        console.error(e)
      }
    }
  }

  async function getPendingReward() {
    if (stakeContract && account && pool) {
      try {
        const res = await stakeContract.pendingTokens(pool.id, account)

        if (res) {
          const rewardNettRes = res.pendingNETT.toString()
            ? new BigNumber(res.pendingNETT.toString()).shiftedBy(-18)
            : new BigNumber(0)
          setPendingNETT(rewardNettRes)

          if (pool.rewarder && rewarderContract) {
            const pendingBonusAmount = res.pendingBonusToken?.toString()
              ? new BigNumber(res.pendingBonusToken.toString()).shiftedBy(-pool.rewarder.rewardToken.decimals)
              : new BigNumber(0)
            setPendingBonus(pendingBonusAmount)

            const userInfo = await rewarderContract.userInfo(account)
            if (userInfo && userInfo.unpaidRewards?.toString()) {
              setUnpaidBounsAmount(
                new BigNumber(userInfo.unpaidRewards.toString()).shiftedBy(-pool.rewarder.rewardToken.decimals)
              )
            }
          }
        }
      } catch (e) {
        console.error(e)
      }
    }
    return new BigNumber(0)
  }

  useEffect(() => {
    getStakeBalance()
  }, [stakeContract, account])

  async function stake({ inputAmount, onSuccess }: { inputAmount: BigNumber; onSuccess?: () => void }) {
    if (!pool) return
    await send({
      contract: stakeContract,
      method: 'deposit',
      params: [pool.id, inputAmount.shiftedBy(18).toFixed()],
      pendingText: `Staking ${inputAmount.toFixed()} ${lpName} NLP`,
      summary: `Staked ${inputAmount.toFixed()} ${lpName} NLP`,
      onSuccess() {
        getStakeBalance()
        getPendingReward()
        if (onSuccess) {
          onSuccess()
        }
      }
    })
  }

  async function unStake({ inputAmount, onSuccess }: { inputAmount: BigNumber; onSuccess?: () => void }) {
    if (userStakeAmount.toNumber() && pool) {
      send({
        contract: stakeContract,
        method: 'withdraw',
        params: [pool.id, inputAmount.shiftedBy(18).toFixed()],
        pendingText: `UnStaking ${inputAmount.toFixed()} ${lpName} NLP`,
        summary: `UnStaked ${inputAmount.toFixed()} ${lpName} NLP`,
        onSuccess() {
          getStakeBalance()
          getPendingReward()
          if (onSuccess) {
            onSuccess()
          }
        }
      })
    }
  }
  async function claimPendingRewardToken({
    inputAmount,
    onSuccess
  }: {
    inputAmount: BigNumber
    onSuccess?: () => void
  }) {
    if (pool) {
      send({
        contract: stakeContract,
        method: 'withdraw',
        params: [pool.id, '0'],
        pendingText: `Harvesting ${lpName} NLP pool rewards`,

        summary: `Harvested ${lpName} NLP pool rewards`,
        onSuccess() {
          getStakeBalance()
          getPendingReward()
          if (onSuccess) {
            onSuccess()
          }
        }
      })
    }
  }

  useEffect(() => {
    let interval: any
    if (stakeContract && account) {
      getPendingReward()

      interval = setInterval(() => {
        getPendingReward()
      }, 15000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [stakeContract, account])

  const veNettInfo = useMemo(() => {
    return {
      pendingVeNETT,
      userVeNETTBalance,
      pendingVeNettProgress
    }
  }, [pendingVeNETT, userVeNETTBalance, pendingVeNettProgress])

  if (!pool) {
    return null
  }

  return (
    <Wrapper>
      <StakeLP
        pool={pool}
        stakeBalance={userStakeAmount}
        approveAddress={stakeContract?.address || ''}
        stake={stake}
        unStake={unStake}
        type="boost"
        userVeNETTInfo={veNettInfo}
        pendingNETT={pendingNEET}
        pendingBonus={pendingBonus}
        unPaidBounsAmount={unPaidBounsAmount}
        claimPendingRewardToken={claimPendingRewardToken}
      />
    </Wrapper>
  )
}
