/* eslint-disable react-hooks/exhaustive-deps */
import { useActiveWeb3React } from 'hooks'
import { useMemo } from 'react'
import { ChainId } from '@netswap/sdk'
import { ContractConfig } from '../../constants'
import { NETWORK_CHAIN_ID } from 'connectors'
import { isMetisNetwork } from 'utils'
import { useContract as useContractHooks } from 'hooks/useContract'

export function useContract(Config: ContractConfig) {
  const { chainId } = useActiveWeb3React()

  const config = useMemo(() => {
    const cid = chainId && isMetisNetwork(chainId) ? chainId : NETWORK_CHAIN_ID
    const config = Config[cid as ChainId]

    return config
  }, [Config, chainId])

  return useContractHooks(config.address, config.abi)
}

export function useContractByAbiAddr(address?: string, abi?: any) {
  return useContractHooks(address, abi)
}
