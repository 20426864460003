import React from 'react'
import { BigNumber } from 'bignumber.js'
import dayjs from 'dayjs'
import { ethers } from 'ethers'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import utc from 'dayjs/plugin/utc'
import { ExchangeSubgraph as client, BlockSubgraph as blockClient } from 'gql/index'
import { GET_BLOCK, GET_BLOCKS, SHARE_VALUE } from 'gql/subgraph/analytics'
import Numeral from 'numeral'
import { DocumentNode } from 'graphql'
import { EXPLORE_URL } from 'constants/index'
import { TYPE } from 'theme'

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  // THREE_MONTHS: '3 months',
  // YEAR: '1 year',
  ALL_TIME: 'All time',
}

// format libraries
BigNumber.set({ EXPONENTIAL_AT: 50 })
dayjs.extend(utc)
const SCAN_URL = EXPLORE_URL

export function getTimeframe(timeWindow: string) {
  const utcEndTime = dayjs.utc()
  // based on window, get starttime
  let utcStartTime
  switch (timeWindow) {
    case timeframeOptions.WEEK:
      utcStartTime = utcEndTime.subtract(1, 'week').endOf('day').unix() - 1
      break
    case timeframeOptions.MONTH:
      utcStartTime = utcEndTime.subtract(1, 'month').endOf('day').unix() - 1
      break
    case timeframeOptions.ALL_TIME:
      utcStartTime = utcEndTime.subtract(1, 'year').endOf('day').unix() - 1
      break
    default:
      utcStartTime = utcEndTime.subtract(1, 'year').startOf('year').unix() - 1
      break
  }
  return utcStartTime
}

export function getPoolLink(token0Address: string, token1Address: string | null = null, remove = false) {
  if (!token1Address) {
    return (
      `/` +
      (remove ? `remove` : `add`) +
      `/${token0Address === '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2' ? 'METIS' : token0Address}/${'METIS'}`
    )
  } else {
    return (
      `/` +
      (remove ? `remove` : `add`) +
      `/${token0Address === '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2' ? 'METIS' : token0Address}/${
        token1Address === '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2' ? 'METIS' : token1Address
      }`
    )
  }
}

export function getSwapLink(token0Address: string, token1Address: string | null = null) {
  if (!token1Address) {
    return `/swap?inputCurrency=${token0Address}`
  } else {
    return `/swap?inputCurrency=${
      token0Address === '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2' ? 'METIS' : token0Address
    }&outputCurrency=${token1Address === '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2' ? 'METIS' : token1Address}`
  }
}

export function localNumber(val: any) {
  return Numeral(val).format('0,0')
}

export const toNiceDate = (date: number) => {
  let x = dayjs.utc(dayjs.unix(date)).format('MMM DD')
  return x
}

export const toWeeklyDate = (date: number) => {
  const formatted = dayjs.utc(dayjs.unix(date))
  let _date = new Date(formatted.valueOf())
  const day = new Date(formatted.valueOf()).getDay()
  let lessDays = day === 6 ? 0 : day + 1
  let wkStart = new Date(new Date(date).setDate(_date.getDate() - lessDays))
  let wkEnd = new Date(new Date(wkStart).setDate(wkStart.getDate() + 6))
  return dayjs.utc(wkStart).format('MMM DD') + ' - ' + dayjs.utc(wkEnd).format('MMM DD')
}

export function getTimestampsForChanges() {
  const utcCurrentTime = dayjs()
  const t1 = utcCurrentTime.subtract(1, 'day').startOf('hour').unix()
  const t2 = utcCurrentTime.subtract(2, 'day').startOf('hour').unix()
  const tWeek = utcCurrentTime.subtract(1, 'week').startOf('hour').unix()
  const t2Week = utcCurrentTime.subtract(2, 'week').startOf('hour').unix()
  return [t1, t2, tWeek, t2Week]
}

export async function splitQuery(
  query: (...rest: any[]) => DocumentNode,
  localClient: ApolloClient<NormalizedCacheObject>,
  vars: any,
  list: any[],
  skipCount: number = 100
) {
  let fetchedData = {}
  let allFound = false
  let skip = 0

  while (!allFound) {
    let end = list.length
    if (skip + skipCount < list.length) {
      end = skip + skipCount
    }
    let sliced = list.slice(skip, end)
    let result = await localClient.query({
      query: query(...vars, sliced),
      fetchPolicy: 'cache-first',
    })
    fetchedData = {
      ...fetchedData,
      ...result.data,
    }
    if (Object.keys(result.data).length < skipCount || skip + skipCount > list.length) {
      allFound = true
    } else {
      skip += skipCount
    }
  }

  return fetchedData
}

/**
 * @notice Fetches first block after a given timestamp
 * @dev Query speed is optimized by limiting to a 600-second period
 * @param {Int} timestamp in seconds
 */
export async function getBlockFromTimestamp(timestamp: number) {
  let result = await blockClient().query({
    query: GET_BLOCK,
    variables: {
      timestampFrom: timestamp,
      timestampTo: timestamp + 1800,
    },
    fetchPolicy: 'cache-first',
  })
  return result?.data?.blocks?.[0]?.number
}

/**
 * @notice Fetches block objects for an array of timestamps.
 * @dev blocks are returned in chronological order (ASC) regardless of input.
 * @dev blocks are returned at string representations of Int
 * @dev timestamps are returns as they were provided; not the block time.
 * @param {Array} timestamps
 */
export async function getBlocksFromTimestamps(timestamps: number[], skipCount: number = 500) {
  if (timestamps?.length === 0) {
    return []
  }

  let fetchedData: any = await splitQuery(GET_BLOCKS, blockClient(), [], timestamps, skipCount)

  let blocks = []
  if (fetchedData) {
    for (let t in fetchedData) {
      if (fetchedData[t].length > 0) {
        blocks.push({
          timestamp: t.split('t')[1],
          number: fetchedData[t][0]['number'],
        })
      }
    }
  }
  return blocks
}

export async function getLiquidityTokenBalanceOvertime(account: string, timestamps: number[]) {
  // get blocks based on timestamps
  const blocks = await getBlocksFromTimestamps(timestamps)

  // get historical share values with time travel queries
  let result = await client().query({
    query: SHARE_VALUE(account, blocks),
    fetchPolicy: 'cache-first',
  })

  let values = []
  for (let row in result?.data) {
    let timestamp = row.split('t')[1]
    if (timestamp) {
      values.push({
        timestamp,
        balance: 0,
      })
    }
  }
}

/**
 * @notice Example query using time travel queries
 * @dev TODO - handle scenario where blocks are not available for a timestamps (e.g. current time)
 * @param {String} pairAddress
 * @param {Array} timestamps
 */
export async function getShareValueOverTime(pairAddress: string, timestamps: number[]) {
  if (!timestamps) {
    const utcCurrentTime = dayjs()
    const utcSevenDaysBack = utcCurrentTime.subtract(8, 'day').unix()
    timestamps = getTimestampRange(utcSevenDaysBack, 86400, 7)
  }

  // get blocks based on timestamps
  const blocks = await getBlocksFromTimestamps(timestamps)

  // get historical share values with time travel queries
  let result = await client().query({
    query: SHARE_VALUE(pairAddress, blocks),
    fetchPolicy: 'cache-first',
  })

  let values: any[] = []
  for (let row in result?.data) {
    let timestamp = row.split('t')[1]
    let sharePriceUsd = parseFloat(result.data[row]?.reserveUSD) / parseFloat(result.data[row]?.totalSupply)
    if (timestamp) {
      values.push({
        timestamp,
        sharePriceUsd,
        totalSupply: result.data[row].totalSupply,
        reserve0: result.data[row].reserve0,
        reserve1: result.data[row].reserve1,
        reserveUSD: result.data[row].reserveUSD,
        token0DerivedMETIS: result.data[row].token0.derivedMETIS,
        token1DerivedMETIS: result.data[row].token1.derivedMETIS,
        roiUsd: values && values[0] ? sharePriceUsd / values[0]['sharePriceUsd'] : 1,
        metisPrice: 0,
        token0PriceUSD: 0,
        token1PriceUSD: 0,
      })
    }
  }

  // add ht prices
  let index = 0
  for (let brow in result?.data) {
    let timestamp = brow.split('b')[1]
    if (timestamp) {
      values[index].metisPrice = result.data[brow].metisPrice
      values[index].token0PriceUSD = result.data[brow].metisPrice * values[index].token0DerivedMETIS
      values[index].token1PriceUSD = result.data[brow].metisPrice * values[index].token1DerivedMETIS
      index += 1
    }
  }

  return values
}

/**
 * @notice Creates an evenly-spaced array of timestamps
 * @dev Periods include a start and end timestamp. For example, n periods are defined by n+1 timestamps.
 * @param {Int} timestamp_from in seconds
 * @param {Int} period_length in seconds
 * @param {Int} periods
 */
export function getTimestampRange(timestamp_from: number, period_length: number, periods: number) {
  let timestamps = []
  for (let i = 0; i <= periods; i++) {
    timestamps.push(timestamp_from + i * period_length)
  }
  return timestamps
}

export const toNiceDateYear = (date: number) => dayjs.utc(dayjs.unix(date)).format('MMMM DD, YYYY')

export const isAddress = (value: string) => {
  try {
    return ethers.utils.getAddress(value.toLowerCase()).toLowerCase()
  } catch {
    return false
  }
}

export const toK = (num: number) => {
  return Numeral(num).format('0.[00]a')
}

export const setThemeColor = (theme: string) =>
  document.documentElement.style.setProperty('--c-token', theme || '#333333')

export const Big = (number: number | string) => new BigNumber(number)

export const urls = {
  showTransaction: (tx: string) => `${SCAN_URL}/tx/${tx}/`,
  showAddress: (address: string) => `${SCAN_URL}/address/${address}/`,
  showToken: (address: string) => `${SCAN_URL}/token/${address}/`,
  showBlock: (block: string) => `${SCAN_URL}/block/${block}/`,
}

export const formatTime = (unix: number) => {
  const now = dayjs()
  const timestamp = dayjs.unix(unix)

  const inSeconds = now.diff(timestamp, 'second')
  const inMinutes = now.diff(timestamp, 'minute')
  const inHours = now.diff(timestamp, 'hour')
  const inDays = now.diff(timestamp, 'day')

  if (inHours >= 24) {
    return `${inDays} ${inDays === 1 ? 'day' : 'days'} ago`
  } else if (inMinutes >= 60) {
    return `${inHours} ${inHours === 1 ? 'hour' : 'hours'} ago`
  } else if (inSeconds >= 60) {
    return `${inMinutes} ${inMinutes === 1 ? 'minute' : 'minutes'} ago`
  } else {
    return `${inSeconds} ${inSeconds === 1 ? 'second' : 'seconds'} ago`
  }
}

export const formatNumber = (num: number) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

// using a currency library here in case we want to add more in future
let priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
})

export const toSignificant = (number: number, significantDigits: number): string => {
  const fullNums = number.toFixed(significantDigits).split('.')

  const decimalsNum = fullNums[1] || new Array(significantDigits).fill('0').join('')

  const intNum = Numeral(number).format('0,0')

  return significantDigits > 0 ? `${intNum}.${decimalsNum}` : intNum
}

export const formattedNum = (num: number | string, usd = false, acceptNegatives = false) => {
  if (typeof num === 'string') {
    num = parseFloat(num)
  }

  if (num > 1e9) {
    return (usd ? '$' : '') + toK(num)
  }

  if (num === 0) {
    if (usd) {
      return '$0'
    }
    return 0
  }

  if (num < 0.0000000001 && num > 0) {
    return usd ? '< $0.0000000001' : '< 0.0000000001'
  }

  if (num > 1000) {
    return usd ? '$' + Number(num.toFixed(0)).toLocaleString() : '' + Number(num.toFixed(0)).toLocaleString()
  }

  if (usd) {
    if (num < 0.0001) {
      return '$' + new BigNumber(num).toFixed(10)
    } else if (num < 0.1) {
      return '$' + Number(num.toFixed(4))
    } else {
      let usdString = priceFormatter.format(num)
      return '$' + usdString.slice(1, usdString.length)
    }
  }

  return Number(num.toFixed(5))
}

export function rawPercent(percentRaw: number) {
  let percent = percentRaw * 100
  if (!percent || percent === 0) {
    return '0%'
  }
  if (percent < 1 && percent > 0) {
    return '< 1%'
  }
  return percent.toFixed(0) + '%'
}

export function formattedPercent(percent: number, symbol = true) {
  if (!percent || percent === 0) {
    return <TYPE.mainLg fontWeight={500}>0%</TYPE.mainLg>
  }

  if (percent < 0.0001 && percent > 0) {
    return <TYPE.mainLg color="t11">{'< 0.0001%'}</TYPE.mainLg>
  }

  if (percent < 0 && percent > -0.0001) {
    return (
      <TYPE.mainLg fontWeight={500} color="t07">
        {'< 0.0001%'}
      </TYPE.mainLg>
    )
  }

  let fixedPercent = Number(percent.toFixed(2))
  if (fixedPercent === 0) {
    return '0%'
  }
  if (!symbol) {
    return <TYPE.mainLg fontWeight={500} color="t11">{`${Math.abs(percent).toFixed(2)}%`}</TYPE.mainLg>
  }

  if (fixedPercent > 0) {
    if (fixedPercent > 100) {
      return <TYPE.mainLg fontWeight={500} color="t11">{`+${percent?.toFixed(0).toLocaleString()}%`}</TYPE.mainLg>
    } else {
      return <TYPE.mainLg fontWeight={500} color="t11">{`+${fixedPercent}%`}</TYPE.mainLg>
    }
  } else {
    return <TYPE.mainLg fontWeight={500} color="t07">{`${fixedPercent}%`}</TYPE.mainLg>
  }
}

/**
 * gets the amoutn difference plus the % change in change itself (second order change)
 * @param {*} valueNow
 * @param {*} value24HoursAgo
 * @param {*} value48HoursAgo
 */
export const get2DayPercentChange = (valueNow: any, value24HoursAgo: any, value48HoursAgo: any) => {
  // get volume info for both 24 hour periods
  let currentChange = parseFloat(valueNow) - parseFloat(value24HoursAgo)
  let previousChange = parseFloat(value24HoursAgo) - parseFloat(value48HoursAgo)

  const adjustedPercentChange = ((currentChange - previousChange) / previousChange) * 100

  if (isNaN(adjustedPercentChange) || !isFinite(adjustedPercentChange)) {
    return [currentChange, 0]
  }
  return [currentChange, adjustedPercentChange]
}

/**
 * get standard percent change between two values
 * @param {*} valueNow
 * @param {*} value24HoursAgo
 */
export const getPercentChange = (valueNow: any, value24HoursAgo: any) => {
  const adjustedPercentChange =
    ((parseFloat(valueNow) - parseFloat(value24HoursAgo)) / parseFloat(value24HoursAgo)) * 100
  if (isNaN(adjustedPercentChange) || !isFinite(adjustedPercentChange)) {
    return 0
  }
  return adjustedPercentChange
}

export function isEquivalent(a: any, b: any) {
  let aProps = Object.getOwnPropertyNames(a)
  let bProps = Object.getOwnPropertyNames(b)
  if (aProps.length !== bProps.length) {
    return false
  }
  for (let i = 0; i < aProps.length; i++) {
    let propName = aProps[i]
    if (a[propName] !== b[propName]) {
      return false
    }
  }
  return true
}
