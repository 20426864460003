import { ChainId } from '@netswap/sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0x0071DDb5a2e80B6b22317594a0307599836621CD',
  [ChainId.TESTNET]: '0x794a5108085Fe5ee26aDD48181bCe1a0CECD07CC',
  // need config
  [ChainId.TESTNET_SEPOLIA]: '0x10D8D7C16828c25bDe08171fdDc3Bb325730114A',
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
