import React, { useState, useEffect } from 'react'
import { useMedia } from 'react-use'
import dayjs from 'dayjs'
import Row from 'components/Row'
import { useTranslation } from 'react-i18next'
import LocalLoader from 'components/Loading'
import utc from 'dayjs/plugin/utc'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Box, Flex } from 'rebass'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'

import { PairFormatedData } from 'hooks/infos/PairData'
import { CustomLink } from '../Link'
import { formattedNum, formattedPercent } from 'utils/infos'
import DoubleTokenLogo from '../DoubleLogo'
import FormattedName from '../FormattedName'
import QuestionHelper from '../QuestionHelper'
import { TYPE } from 'theme'

dayjs.extend(utc)

const PageButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 0.5em;
`

const Arrow = styled.div<{ faded?: boolean }>`
  color: ${({ theme }) => theme.v2.t01};
  opacity: ${(props) => (props.faded ? 0.3 : 1)};
  padding: 0 20px;
  user-select: none;
  :hover {
    cursor: pointer;
  }
`

const List = styled(Box)`
  -webkit-overflow-scrolling: touch;
`

const DashGrid = styled.div<{ disableLinks?: string[]; focus?: boolean; center?: boolean }>`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 50px 100px 1fr 1fr;

  > * {
    justify-content: flex-end;

    :first-child {
      justify-content: flex-start;
      text-align: left;
      width: 30px;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-gap: 4px;
    grid-template-columns: 20px 1.5fr 1fr 1fr;
      > * {
        justify-content: center;
        width: 100%;

        &:first-child {
          justify-content: flex-start;
          text-align: left;
        }

        &:last-child {
          justify-content: end;
          text-align: right;
        }
      }
  `}

  @media screen and (min-width: 1080px) {
    grid-template-columns: 50px 1.5fr 1fr 1fr 1fr 1fr 1fr;
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: 50px 1.5fr 1fr 1fr 1fr 1fr 1fr;
  }
`

const ListWrapper = styled.div``

const ClickableText = styled(TYPE.v2Main)`
  text-align: center;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  user-select: none;
  color: ${({ theme }) => theme.v2.t02};
  font-size: 12px !important;
`

const DataText = styled(TYPE.mainLg)`
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.v2.t01};

  & > * {
    font-size: 14px;
  }

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`

const SORT_FIELD = {
  LIQ: 0,
  VOL: 1,
  VOL_7DAYS: 3,
  FEES: 4,
  APY: 5,
}

const FIELD_TO_VALUE = {
  // [SORT_FIELD.LIQ]: 'trackedReserveUSD', // sort with tracked volume only
  [SORT_FIELD.LIQ]: 'reserveUSD',
  [SORT_FIELD.VOL]: 'oneDayVolumeUSD',
  [SORT_FIELD.VOL_7DAYS]: 'oneWeekVolumeUSD',
  [SORT_FIELD.FEES]: 'oneDayVolumeUSD',
}

function PairList({
  pairs,
  color,
  disableLinks = [],
  maxItems = 10,
}: {
  pairs: {
    [key: string]: PairFormatedData
  }
  color?: string
  disableLinks?: string[]
  maxItems?: number
} & RouteComponentProps<any>) {
  const { t } = useTranslation()
  const below600 = useMedia('(max-width: 600px)')
  const below740 = useMedia('(max-width: 740px)')
  const below1080 = useMedia('(max-width: 1080px)')

  // pagination
  const [page, setPage] = useState<number>(1)
  const [maxPage, setMaxPage] = useState<number>(1)
  const ITEMS_PER_PAGE = maxItems

  // sorting
  const [sortDirection, setSortDirection] = useState(true)
  const [sortedColumn, setSortedColumn] = useState(SORT_FIELD.LIQ)

  useEffect(() => {
    setMaxPage(1) // edit this to do modular
    setPage(1)
  }, [pairs])

  useEffect(() => {
    if (pairs) {
      let extraPages = 1
      if (Object.keys(pairs).length % ITEMS_PER_PAGE === 0) {
        extraPages = 0
      }
      setMaxPage(Math.floor(Object.keys(pairs).length / ITEMS_PER_PAGE) + extraPages)
    }
  }, [ITEMS_PER_PAGE, pairs])

  const ListItem = ({ pairAddress, index }: { pairAddress: string; index: number }) => {
    const pairData = pairs[pairAddress]

    if (pairData && pairData.token0 && pairData.token1) {
      const liquidity = formattedNum(parseFloat(pairData.reserveUSD), true)
      const volume = formattedNum(pairData.oneDayVolumeUSD, true)
      const apr = formattedPercent(pairData.apr)

      return (
        <DashGrid style={{ height: '48px' }} disableLinks={disableLinks} focus={true}>
          <DataText>{index}</DataText>
          <DataText>
            <Row justify="flex-start">
              <DoubleTokenLogo
                size={below600 ? 16 : 20}
                a0={pairData.token0.id}
                a1={pairData.token1.id}
                margin={!below740}
              />
              <CustomLink
                style={{ marginLeft: '20px', whiteSpace: 'nowrap' }}
                to={'/analytics/pairs/' + pairAddress}
                color={color}
              >
                {below600 ? (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <FormattedName text={pairData.token0.symbol} maxCharacters={8} adjustSize={true} link={true} />
                    <FormattedName text={pairData.token1.symbol} maxCharacters={8} adjustSize={true} link={true} />
                  </div>
                ) : (
                  <FormattedName
                    text={pairData.token0.symbol + '-' + pairData.token1.symbol}
                    maxCharacters={below600 ? 8 : 16}
                    adjustSize={true}
                    link={true}
                  />
                )}
              </CustomLink>
            </Row>
          </DataText>
          <DataText>{liquidity}</DataText>
          <DataText>{volume}</DataText>
          {!below1080 && <DataText>{formattedNum(pairData.oneWeekVolumeUSD, true)}</DataText>}
          {!below1080 && <DataText>{formattedNum(pairData.oneDayVolumeUSD * 0.003, true)}</DataText>}
          {!below1080 && <DataText>{apr}</DataText>}
        </DashGrid>
      )
    } else {
      return <></>
    }
  }

  const pairList =
    pairs &&
    Object.keys(pairs)
      .sort((addressA: string, addressB: string) => {
        const pairA = pairs[addressA]
        const pairB = pairs[addressB]
        if (sortedColumn === SORT_FIELD.APY) {
          const apy0 = (pairA.oneDayVolumeUSD * 0.003 * 356 * 100) / parseFloat(pairA.reserveUSD)
          const apy1 = (pairB.oneDayVolumeUSD * 0.003 * 356 * 100) / parseFloat(pairB.reserveUSD)
          return apy0 > apy1 ? (sortDirection ? -1 : 1) * 1 : (sortDirection ? -1 : 1) * -1
        }
        const sortedKey = FIELD_TO_VALUE[sortedColumn]
        return new BigNumber((pairA as any)[sortedKey]).gt((pairB as any)[sortedKey])
          ? (sortDirection ? -1 : 1) * 1
          : (sortDirection ? -1 : 1) * -1
      })
      .slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE)
      .map((pairAddress, index) => {
        return (
          pairAddress && (
            <div key={index}>
              <ListItem key={index} index={(page - 1) * ITEMS_PER_PAGE + index + 1} pairAddress={pairAddress} />
            </div>
          )
        )
      })

  return (
    <ListWrapper>
      <DashGrid center={true} disableLinks={disableLinks} style={{ height: 'fit-content', paddingBottom: '16px' }}>
        <Flex alignItems="center" justifyContent="flex-start">
          <TYPE.v2Main color="t02" fontSize={12}>
            #
          </TYPE.v2Main>
        </Flex>
        <Flex alignItems="center" justifyContent="flex-start">
          <TYPE.v2Main color="t02" fontSize={12}>
            {t('analytics.name')}
          </TYPE.v2Main>
        </Flex>
        <Flex alignItems="center" justifyContent="flexEnd">
          <ClickableText
            onClick={(e) => {
              setSortedColumn(SORT_FIELD.LIQ)
              setSortDirection(sortedColumn !== SORT_FIELD.LIQ ? true : !sortDirection)
            }}
          >
            {t('analytics.liquidity')} {sortedColumn === SORT_FIELD.LIQ ? (!sortDirection ? '↑' : '↓') : ''}
          </ClickableText>
        </Flex>
        <Flex alignItems="center">
          <ClickableText
            onClick={(e) => {
              setSortedColumn(SORT_FIELD.VOL)
              setSortDirection(sortedColumn !== SORT_FIELD.VOL ? true : !sortDirection)
            }}
          >
            {t('analytics.volume_24hrs')}
            {sortedColumn === SORT_FIELD.VOL ? (!sortDirection ? '↑' : '↓') : ''}
          </ClickableText>
        </Flex>
        {!below1080 && (
          <Flex alignItems="center" justifyContent="flexEnd">
            <ClickableText
              onClick={(e) => {
                setSortedColumn(SORT_FIELD.VOL_7DAYS)
                setSortDirection(sortedColumn !== SORT_FIELD.VOL_7DAYS ? true : !sortDirection)
              }}
            >
              {t('analytics.volume_7d')} {sortedColumn === SORT_FIELD.VOL_7DAYS ? (!sortDirection ? '↑' : '↓') : ''}
            </ClickableText>
          </Flex>
        )}
        {!below1080 && (
          <Flex alignItems="center" justifyContent="flexEnd">
            <ClickableText
              onClick={(e) => {
                setSortedColumn(SORT_FIELD.FEES)
                setSortDirection(sortedColumn !== SORT_FIELD.FEES ? true : !sortDirection)
              }}
            >
              {t('analytics.fees_24hr')} {sortedColumn === SORT_FIELD.FEES ? (!sortDirection ? '↑' : '↓') : ''}
            </ClickableText>
          </Flex>
        )}
        {!below1080 && (
          <Flex alignItems="center" justifyContent="flexEnd">
            <ClickableText
              onClick={(e) => {
                setSortedColumn(SORT_FIELD.APY)
                setSortDirection(sortedColumn !== SORT_FIELD.APY ? true : !sortDirection)
              }}
            >
              {t('analytics.1y_fees_liquidity')} {sortedColumn === SORT_FIELD.APY ? (!sortDirection ? '↑' : '↓') : ''}
            </ClickableText>
            <QuestionHelper text={t('analytics.pairs_list.base_24hr_annualized')} />
          </Flex>
        )}
      </DashGrid>
      <List p={0}>{!pairList ? <LocalLoader show={true} /> : pairList}</List>
      <PageButtons>
        <div
          onClick={(e) => {
            setPage(page === 1 ? page : page - 1)
          }}
        >
          <Arrow faded={page === 1 ? true : false}>
            <i className="iconfont icon-page-prev" />
          </Arrow>
        </div>
        <TYPE.body>{'Page ' + page + ' of ' + maxPage}</TYPE.body>
        <div
          onClick={(e) => {
            setPage(page === maxPage ? page : page + 1)
          }}
        >
          <Arrow faded={page === maxPage ? true : false}>
            <i className="iconfont icon-page-next" />
          </Arrow>
        </div>
      </PageButtons>
    </ListWrapper>
  )
}

export default withRouter(PairList)
