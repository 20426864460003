import { gql } from '@apollo/client'
import { MicroServicesClient } from '../index'

interface Token {
  symbol: string
  name: string
  address: string
  decimals: number
}

export enum PadPeriod {
  Prepare = 'Prepare',
  Staking = 'Staking',
  Vesting = 'Vesting',
  Cashing = 'Cashing',
  Ended = 'Ended'
}

export enum PadType {
  basic = 1,
  unlimited = 2
}

export enum PadStatus {
  Success = 'Success',
  Fail = 'Fail'
}

export interface LaunchPad {
  id: string
  pid: string
  // basic: 1, unlimited: 2
  poolType: PadType
  period: PadPeriod
  saleToken: Token
  paymentToken: Token
  stakedToken: Token
  startTime: number
  stakingEndTime: number
  vestingEndTime: number
  cashingEndTime: number
  salesAmount: string
  stakedAmount: string
  stakedUserAmount: string
  stakedUserAmountBeforeStakingEnd: string
  cashedAmount: string
  maxPerUser: string
  raisedAmount: string
  price: string
  // The min staked user amount requirement for token sale, only for unlimited pad pool, basic pad pool value is 0
  minStakedUserAmount: string
  minStakedCap: string
  maxStakedCap: string
  isWhiteList: string
  status: PadStatus
}

export default function getPads(): Promise<LaunchPad[] | undefined> {
  // if (!name) return Promise.resolve([]);
  return new Promise(resolve => {
    MicroServicesClient()
      .query<{
        pads: LaunchPad[]
      }>({
        query: gql`
          query pads {
            pads(skip: 0, limit: 100, OrderDirection: desc) {
              id
              pid
              poolType
              period
              saleToken {
                name
                symbol
                decimals
                address
              }
              paymentToken {
                name
                symbol
                decimals
                address
              }
              stakedToken {
                name
                symbol
                decimals
                address
              }
              startTime
              stakingEndTime
              vestingEndTime
              cashingEndTime
              salesAmount
              stakedAmount
              stakedUserAmount
              stakedUserAmountBeforeStakingEnd
              cashedAmount
              maxPerUser
              raisedAmount
              price
              minStakedUserAmount
              minStakedCap
              maxStakedCap
              isWhiteList
              status
            }
          }
        `,
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        let returnData = undefined
        let { data } = res
        if (data?.pads) {
          returnData = data.pads.map(item => {
            return {
              ...item,
              startTime: item.startTime * 1000,
              stakingEndTime: item.stakingEndTime * 1000,
              vestingEndTime: item.vestingEndTime * 1000,
              cashingEndTime: item.cashingEndTime * 1000
            }
          })
        }
        resolve(returnData)
      })
      .catch((e: any) => {
        console.error(e)
        resolve(undefined)
      })
  })
}
