import React from 'react'
import { useAppSelector } from 'state'
import styled from 'styled-components'

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 420px;
  width: 100%;
  background: #170627;
  box-shadow: 0px 0px 1px rgb(178, 39, 101, 0.1), 0px 4px 8px rgb(178, 39, 101, 0.1),
    0px 16px 24px rgb(178, 39, 101, 0.1), 0px 24px 32px rgb(178, 39, 101, 0.1);
  border-radius: 16px;
  /* padding: 30px; */
`

export const BodyWrapperV2 = styled.div`
  position: relative;
  max-width: 446px;
  width: 100%;
  background: ${({ theme }) => theme.v2.bg02};
  border-radius: 16px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100vw - 2rem)
  `}
`

export const Marginer = styled.div`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100vw;
    display: block;
    height: 4rem;
  `}
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  const { isChristmas } = useAppSelector(state => state.application)
  return (
    <BodyWrapper>
      {isChristmas && (
        <img
          style={{ position: 'absolute', top: '-10px', width: '100%', maxHeight: '22px' }}
          src={require('../assets/images/christmas/bg_snow.png')}
          alt="christmasChang"
        />
      )}
      {children}
    </BodyWrapper>
  )
}

export function AppBodyV2({ children, className }: { children: React.ReactNode; className?: string }) {
  const { isChristmas } = useAppSelector(state => state.application)
  return (
    <>
      <BodyWrapperV2 className={className}>
        {isChristmas && (
          <img
            style={{ position: 'absolute', top: '-10px', width: '100%', maxHeight: '22px' }}
            src={require('../assets/images/christmas/bg_snow.png')}
            alt="christmasChang"
          />
        )}
        {children}
      </BodyWrapperV2>
    </>
  )
}
