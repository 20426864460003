import { ChainId } from '@netswap/sdk'
import ABI from './abi.json'

export const BoostNetFarmProxyConfig = {
  [ChainId.MAINNET]: {
    address: '0x5E1f9Cd1B9635506af6Bc3B2414AC9C8b2840EFa',
    abi: ABI,
  },
  [ChainId.TESTNET]: {
    address: '0xA82B195661AE803c18df4713E5Bf5CA18731724c',
    abi: ABI,
  },
  // need config
  [ChainId.TESTNET_SEPOLIA]: {
    address: '0x0E7eb444EB972f5EEF726928A5E4E74264D417cE',
    abi: ABI,
  },
}
