import { ChainId } from '@netswap/sdk'
import ABI from './abi.json'

export const Config_1 = {
  [ChainId.MAINNET]: {
    address: '0x12Cd499009EB443eF8F378E271aCc97D0543C0A7',
    abi: ABI,
  },
  [ChainId.TESTNET]: {
    address: '0x55a59630d0985A33aB5E168E98Db2e66C2914312',
    abi: ABI,
  },
  // need config
  [ChainId.TESTNET_SEPOLIA]: {
    address: '0x55a59630d0985A33aB5E168E98Db2e66C2914312',
    abi: ABI,
  },
}

export const Config_2 = {
  [ChainId.MAINNET]: {
    address: '0x142f0F56A1C9600e7B2B034648134850C93e7238',
    abi: ABI,
  },
  [ChainId.TESTNET]: {
    address: '0x668B98E3F357eB3FFD7eb9048e3844a0Ac034D34',
    abi: ABI,
  },
  // need config
  [ChainId.TESTNET_SEPOLIA]: {
    address: '0x668B98E3F357eB3FFD7eb9048e3844a0Ac034D34',
    abi: ABI,
  },
}
