import { ChainId } from '@netswap/sdk'
import ABI from './helper.json'

export const Config = {
  [ChainId.MAINNET]: {
    address: '0x35c3123e3E274f05F921A792BD0d80414d50D07F',
    abi: ABI,
  },
  [ChainId.TESTNET]: {
    address: '0x5E1f9Cd1B9635506af6Bc3B2414AC9C8b2840EFa',
    abi: ABI,
  },
  // need config
  [ChainId.TESTNET_SEPOLIA]: {
    address: '0x5E1f9Cd1B9635506af6Bc3B2414AC9C8b2840EFa',
    abi: ABI,
  },
}
