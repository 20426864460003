import React, { ReactNode, CSSProperties, ReactElement } from 'react'
import styled from 'styled-components'
import { Tooltip } from '@material-ui/core'
import { HelpCircle as Question } from 'react-feather'
interface Props {
  text?: string | ReactElement
  style?: CSSProperties
  placement?: 'top' | 'bottom' | 'top-start' | 'left'
  children?: ReactNode
  questionSize?: number
}

const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  border: none;
  background: none;
  outline: none;
  cursor: default;
  border-radius: 36px;
  /* background-color: ${({ theme }) => theme.bg2}; */
  color: ${({ theme }) => theme.text2};
  :hover,
  :focus {
    opacity: 0.7;
  }
`

const TooltipsWrapper = styled(Tooltip)``

export default function TooltipInfo({
  text = '',
  style: cssStyle,
  placement = 'top',
  children,
  questionSize = 16
}: Props) {
  return (
    <TooltipsWrapper title={text} placement={placement} arrow>
      {children ? (
        <div style={{ display: 'inline-block', ...cssStyle }}>{children}</div>
      ) : (
        <QuestionWrapper style={{ display: 'inline-block', ...cssStyle }}>
          <Question size={questionSize} />
        </QuestionWrapper>
      )}
    </TooltipsWrapper>
  )
}
