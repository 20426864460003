import React, { useState, useEffect } from 'react'
import MenuIcon from '@material-ui/icons/Menu'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'
import NavSider from '../NavSider'
import styled from 'styled-components'

const useStyles = makeStyles({
  list: {
    width: 250,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  },
  fullList: {
    width: 'auto'
  },
  drawerContent: {
    background: '#000'
  },
  flexWrap: {
    flexWrap: 'wrap'
  }
})

const StyleMenuIcon = styled(MenuIcon)`
  display: none !important;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block !important;
  `}
`
export default function NavDrawer() {
  const location = useLocation()
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    setIsOpen(false)
  }, [location])

  return (
    <>
      <StyleMenuIcon
        onClick={() => {
          setIsOpen(true)
        }}
      />
      <Drawer
        anchor="left"
        open={isOpen}
        classes={{
          paper: classes.drawerContent
        }}
        onClose={() => {
          setIsOpen(false)
        }}
      >
        <NavSider />
      </Drawer>
    </>
  )
}
