import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import BigNumber from 'bignumber.js'
import { ExchangeSubgraph } from '../index'

interface Token {
  id: string
  symbol: string
  name: string
  totalLiquidity: string
  derivedMETIS: string
}

interface HourData {
  hourStartUnix: number
  hourlyVolumeUSD: string
}

export interface Pair {
  id: string
  txCount: string
  token0: Token
  token1: Token
  reserve0: string
  reserve1: string
  reserveUSD: number
  totalSupply: string
  trackedReserveMETIS: string
  reserveMETIS: string
  volumeUSD: number
  untrackedVolumeUSD: string
  token0Price: string
  token1Price: string
  createdAtTimestamp: string
}

export interface PairWithHourData extends Pair {
  pairHourData: HourData[]
}

export interface PairWithApr extends PairWithHourData {
  last24HourVol: number
  lpApr: number
}

const PairFields = `
  fragment PairFields on Pair {
    id
    txCount
    token0 {
      id
      symbol
      name
      totalLiquidity
      derivedMETIS
    }
    token1 {
      id
      symbol
      name
      totalLiquidity
      derivedMETIS
    }
    reserve0
    reserve1
    reserveUSD
    totalSupply
    trackedReserveMETIS
    reserveMETIS
    volumeUSD
    untrackedVolumeUSD
    token0Price
    token1Price
    createdAtTimestamp
  }
`

export const TOKEN_SEARCH = gql`
  query tokens($value: String, $id: String) {
    asSymbol: tokens(where: { symbol_contains: $value }, orderBy: totalLiquidity, orderDirection: desc) {
      id
      symbol
      name
      totalLiquidity
    }
    asName: tokens(where: { name_contains: $value }, orderBy: totalLiquidity, orderDirection: desc) {
      id
      symbol
      name
      totalLiquidity
    }
    asAddress: tokens(where: { id: $id }, orderBy: totalLiquidity, orderDirection: desc) {
      id
      symbol
      name
      totalLiquidity
    }
  }
`

export const PAIR_SEARCH = gql`
  ${PairFields}
  query pairs($tokens: [Bytes]!, $id: String) {
    as0: pairs(where: { token0_in: $tokens }) {
      ...PairFields
    }
    as1: pairs(where: { token1_in: $tokens }) {
      ...PairFields
    }
    asAddress: pairs(where: { id: $id }) {
      ...PairFields
    }
  }
`

export const PAIR_SEARCH_BY_TOKENS = gql`
  ${PairFields}
  query pairs($token0: Bytes!, $token1: Bytes!) {
    as0: pairs(where: { token0: $token0, token1: $token1 }) {
      ...PairFields
    }
    as1: pairs(where: { token1: $token0, token0: $token1 }) {
      ...PairFields
    }
  }
`

export const ALL_PAIRS_WITH_HOUR_DATA = gql`
  ${PairFields}
  query pairs($dateAfter: Int!) {
    pairs(first: 500, orderBy: reserveUSD, orderDirection: desc) {
      ...PairFields

      pairHourData(first: 24, orderBy: hourStartUnix, orderDirection: desc, where: { hourStartUnix_gte: $dateAfter }) {
        hourStartUnix
        hourlyVolumeUSD
      }
    }
  }
`

export async function getAllPairsWithApr(): Promise<PairWithApr[]> {
  try {
    const queryRes = await ExchangeSubgraph().query<{ pairs: PairWithHourData[] }>({
      query: ALL_PAIRS_WITH_HOUR_DATA,
      variables: {
        dateAfter: Math.floor(
          dayjs()
            .add(-24, 'hours')
            .valueOf() / 1000
        )
      }
    })

    return queryRes.data.pairs.map(pair => {
      const last24HourVol = pair.pairHourData.reduce(
        (sum: BigNumber, data) => sum.plus(data.hourlyVolumeUSD),
        new BigNumber(0)
      )
      let lpApr = 0

      if (new BigNumber(pair.reserveUSD).gt(0)) {
        lpApr = Number(
          last24HourVol
            .multipliedBy(365)
            .multipliedBy(0.0025)
            .div(pair.reserveUSD)
            .multipliedBy(100)
            .toFixed(4)
        )
      }
      return {
        ...pair,
        reserveUSD: Number(pair.reserveUSD),
        volumeUSD: Number(pair.volumeUSD),
        last24HourVol: last24HourVol.toNumber(),
        lpApr
      }
    })
  } catch (err) {
    console.error(err)
    return []
  }
}

export async function getPairByTwoTokens(token0: string, token1: string): Promise<Pair | null> {
  try {
    const queryRes = await ExchangeSubgraph().query<{ as0: Pair[]; as1: Pair[] }>({
      query: PAIR_SEARCH_BY_TOKENS,
      variables: {
        token0: token0.toLowerCase(),
        token1: token1.toLowerCase()
      }
    })

    return queryRes.data.as0[0] || queryRes.data.as1[0] || null
  } catch (err) {
    console.error(err)
    return null
  }
}
