import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Box } from 'rebass/styled-components'
import Pools, { EarnWNettPool } from './components/Pools'
import { withStyles, createStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import useGetFarmAssetsPool from 'state/data/hooks/useGetFarmAssetsPools'
import { Period } from 'gql/microservices/assets-pools'
import { AutoColumn } from 'components/Column'
import { TYPE } from 'theme'

import Swtich from 'components/Swtich'
import Select from 'components/Select'

const StakeBox = styled(Box)`
  width: 940px;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100vw - 2rem);
  `}
`
const StakeWrapper = styled.div`
  max-width: 940px;
  width: 100%;
  border-radius: 16px;
  margin: 0 auto;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `}

  section {
    margin-bottom: 32px;
  }
  h2,
  p {
    color: ${({ theme }) => theme.text1};
    margin: 0;
    text-indent: 20px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      text-indent: 0;
    `};
  }
  h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 14px;
  }
  p {
    font-size: 14px;
    line-height: 20px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const TitleIcon = styled.img`
  width: 110px;
  height: 102px;
  margin-right: 10px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
       width: 70px;
  height: 64px;
    `};
`

const StyledTabs = styled(Tabs)`
  margin-top: 28px;

  .MuiTabs-root {
    margin-bottom: 40px;
  }
  .MuiTabs-flexContainer {
    justify-content: flex-start;
  }
  .MuiTab-root {
    padding: 0 20px;
    color: ${({ theme }) => theme.v2.t02};

    &.Mui-selected {
      color: ${({ theme }) => theme.v2.t09};
    }
  }
  .MuiTabs-indicator {
    display: flex;
    justify-content: flex-start;
    background-color: transparent;
    bottom: 0px !important;
    background-color: ${({ theme }) => theme.v2.t09};
    /* width: 60px !important; */
  }
`

interface StyledTabProps {
  label: string
}

const StyledTab = withStyles(() =>
  createStyles({
    root: {
      textTransform: 'none',
      color: '#fff',
      '&:focus': {
        opacity: 1,
        color: '#c42a61',
      },
    },
    selected: {
      color: '#c42a61',
    },
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />)

function a11yProps(index: any) {
  return {
    id: `launchpad-tab-${index}`,
    'aria-controls': `launchpad-tabpanel-${index}`,
  }
}

const ToolsWrapper = styled.div`
  background: ${({ theme }) => theme.v2.bg02};
  box-sizing: border-box;
  padding: 8px 16px;
  border-radius: 12px;
  width: 100%;
  margin-top: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

const MyLiquidityWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const SortByWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const SortItems = [
  {
    text: 'APR',
    value: 'apr',
  },
  {
    text: 'TVL',
    value: 'tvl',
  },
]

export default function Stake() {
  const [value, setValue] = useState(0)
  const pools = useGetFarmAssetsPool()

  const [myPoolChecked, setMyPoolCheck] = useState(false)
  const [sort, setSort] = useState('apr')

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue)
  }

  const [userPoolsIds, setUserPoolsIds] = useState<string[]>([])

  const computedPools = useMemo(() => {
    let res = [...pools]
    if (res.length) {
      res = res.sort((a, b) => {
        let sort1 = b
        let sort2 = a
        //   if (sort === SortDirection.desc) {
        //     sort1 = b
        //     sort2 = a
        //   }
        if (sort === 'tvl') {
          return Number(sort1.tvl) - Number(sort2.tvl)
        } else {
          return sort1.apy - sort2.apy
        }
      })
    }

    if (myPoolChecked) {
      if (!userPoolsIds.length) {
        res = []
      } else {
        res = res.filter((item) => userPoolsIds.indexOf(item.id) >= 0)
      }
    }

    return res
  }, [pools, sort, userPoolsIds, myPoolChecked])

  const ongoingPools = useMemo(() => {
    return computedPools.filter((item) => item.period === Period.ongoing)
  }, [computedPools])

  const endedPools = useMemo(() => {
    return computedPools.filter((item) => item.period === Period.ended)
  }, [computedPools])

  const preparePools = useMemo(() => {
    return computedPools.filter((item) => item.period === Period.prepare)
  }, [computedPools])

  function updateUserPoolsById(id: string, action: 'add' | 'remove') {
    if (action === 'add' && userPoolsIds.indexOf(id) === -1) {
      userPoolsIds.push(id)
      setUserPoolsIds([...userPoolsIds])
    }

    if (action === 'remove' && userPoolsIds.indexOf(id) >= 0) {
      userPoolsIds.splice(userPoolsIds.indexOf(id), 1)
      setUserPoolsIds([...userPoolsIds])
    }
  }

  function addUserPool(id: string) {
    updateUserPoolsById(id, 'add')
  }

  function removeUserPool(id: string) {
    updateUserPoolsById(id, 'remove')
  }
  return (
    <StakeBox>
      <StakeWrapper>
        <TitleWrapper>
          <TitleIcon src={require('assets/images/stake/banner.png')} />
          <AutoColumn justify="center" gap="md">
            <TYPE.v2Main fontWeight="bold" fontSize={32}>
              NETT Stake
            </TYPE.v2Main>
            <TYPE.desc fontSize={14} textAlign="center">
              Stake your NETT tokens to unlock various privileges
            </TYPE.desc>
          </AutoColumn>
        </TitleWrapper>

        <section>
          <StyledTabs value={value} onChange={handleChange} aria-label="launchpad">
            <StyledTab label="All" {...a11yProps(0)} />
            <StyledTab label="Ongoing" {...a11yProps(1)} />
          </StyledTabs>
          <ToolsWrapper>
            <MyLiquidityWrapper>
              <TYPE.v2Main fontSize={14} marginRight="12px">
                My Pool Only
              </TYPE.v2Main>
              <Swtich checked={myPoolChecked} onChange={setMyPoolCheck} />
            </MyLiquidityWrapper>
            <SortByWrapper>
              <TYPE.v2Main fontSize={14} marginRight="12px">
                SORT BY
              </TYPE.v2Main>
              <Select value={sort} items={SortItems} onChange={setSort} />
            </SortByWrapper>
          </ToolsWrapper>
          {value === 1 ? (
            <>
              <Pools
                title="Stake NETT to earn tokens"
                pools={ongoingPools}
                addUserPool={addUserPool}
                removeUserPool={removeUserPool}
              />
              <EarnWNettPool />
            </>
          ) : (
            <>
              <Pools
                title="Stake NETT to earn tokens"
                pools={ongoingPools}
                emptyHide
                addUserPool={addUserPool}
                removeUserPool={removeUserPool}
              />
              <Pools
                title="Stake NETT to participate in new laucher"
                pools={preparePools}
                emptyHide
                addUserPool={addUserPool}
                removeUserPool={removeUserPool}
              />
              <EarnWNettPool />
              <Pools
                title="Withdraw only"
                pools={endedPools}
                emptyHide
                addUserPool={addUserPool}
                removeUserPool={removeUserPool}
              />
            </>
          )}
        </section>
      </StakeWrapper>
    </StakeBox>
  )
}
