import { gql } from '@apollo/client'
import { ExchangeSubgraph } from '../index'
import BigNumber from 'bignumber.js'

interface Token {
  id: string
  symbol: string
  name: string
}

interface Swap {
  transaction: {
    id: string
  }
  timestamp: number
  id: string
  pair: {
    token0: Token
    token1: Token
  }
  amount0In: string
  amount0Out: string
  amount1In: string
  amount1Out: string
  amountUSD: string
  to: string
  from: string
  sender: string
}

export interface SwapReturn {
  transaction: {
    id: string
  }
  id: string
  timestamp: number
  pair: {
    token0: Token
    token1: Token
  }
  fromToken: Token
  toToken: Token
  amountIn: BigNumber
  amountOut: BigNumber
  amountUSD: BigNumber
  to: string
  from: string
  sender: string
}

const GET_SWAPS = gql`
  query swaps($pair: String!, $user: String!, $limit: Int!, $endTime: Int!) {
    swaps(
      first: $limit
      where: { pair_contains: $pair, timestamp_lte: $endTime, from_contains: $user }
      orderBy: timestamp
      orderDirection: desc
    ) {
      transaction {
        id
      }
      id
      timestamp
      pair {
        token0 {
          id
          symbol
          name
        }
        token1 {
          id
          symbol
          name
        }
      }
      amount0In
      amount0Out
      amount1In
      amount1Out
      amountUSD
      to
      from
      sender
    }
  }
`

export async function getSwaps({
  pair,
  user,
  limit,
  endTime
}: {
  pair?: string
  user?: string
  limit: number
  endTime: number
}): Promise<SwapReturn[]> {
  try {
    const queryRes = await ExchangeSubgraph().query<{ swaps: Swap[] }>({
      query: GET_SWAPS,
      variables: {
        pair: pair || '',
        user: user || '',
        limit,
        endTime
      }
    })

    return queryRes.data.swaps.map(swap => {
      let fromToken = swap.pair.token0
      let toToken = swap.pair.token1
      let amountIn = new BigNumber(swap.amount0In)
      let amountOut = new BigNumber(swap.amount1Out)

      if (amountIn.eq(0)) {
        fromToken = swap.pair.token1
        toToken = swap.pair.token0
        amountIn = new BigNumber(swap.amount1In)
        amountOut = new BigNumber(swap.amount0Out)
      }

      return {
        id: swap.id,
        pair: swap.pair,
        transaction: swap.transaction,
        fromToken,
        toToken,
        amountIn,
        amountOut,
        amountUSD: new BigNumber(swap.amountUSD),
        timestamp: swap.timestamp,
        from: swap.from,
        to: swap.to,
        sender: swap.sender
      }
    })
  } catch (err) {
    console.error(err)
    return []
  }
}
