import React from 'react'
import styled from 'styled-components'
import Settings from '../Settings'
import { RowBetween } from '../Row'
import { TYPE } from '../../theme'
import TooltipsInfo from '../TooltipInfo'
import classNames from 'classnames'
import {
  useViewToggleState,
  useToggleSwapChart,
  useToggleSwapHistory,
  useToggleSwapUpdateTrade,
} from 'state/application/hooks'
import { ApplicationToggleKey } from 'state/application/actions'

const StyledSwapHeader = styled.div`
  padding: 12px 1rem 1rem 1rem;
  width: calc(100% - 2rem);
  margin: 0 auto;
  color: ${({ theme }) => theme.text2};
  border-bottom: 1px solid rgba(0, 0, 0, 0.19);
`

const Title = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

const SettingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const IconWrapper = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.charts {
    ${({ theme }) => theme.mediaWidth.upToSmall`
        display: none;
    `};
  }

  .iconfont {
    font-size: 22px;

    &.active {
      color: #00cfff;
    }
  }

  :hover {
    .iconfont {
      color: #00cfff;
    }
  }
`

export default function SwapHeader() {
  const chartOpen = useViewToggleState(ApplicationToggleKey.SWAP_CHART)
  const historyOpen = useViewToggleState(ApplicationToggleKey.SWAP_HISTORY)
  const toggleSwapChart = useToggleSwapChart()
  const toggleSwapHistory = useToggleSwapHistory()
  const toggleUpdateSwapTrade = useToggleSwapUpdateTrade()
  return (
    <StyledSwapHeader>
      <RowBetween>
        <Title>
          <TYPE.v2Main fontWeight={500} lineHeight="28px">
            Swap
          </TYPE.v2Main>
          <TYPE.v2Main fontSize={14} fontWeight={500} lineHeight="24px">
            Trade Your Token instantly
          </TYPE.v2Main>
        </Title>
        <SettingWrapper>
          <TooltipsInfo text="Charts">
            <IconWrapper
              className="charts"
              onClick={() => {
                toggleSwapChart()
              }}
            >
              <i className={classNames('iconfont', chartOpen ? ' icon-chart-close active' : 'icon-chart')}></i>
            </IconWrapper>
          </TooltipsInfo>
          <TooltipsInfo text="History">
            <IconWrapper
              onClick={() => {
                toggleSwapHistory()
              }}
            >
              <i className={classNames('iconfont  icon-history', historyOpen && 'active')}></i>
            </IconWrapper>
          </TooltipsInfo>
          <TooltipsInfo text="Update">
            <IconWrapper
              onClick={() => {
                toggleUpdateSwapTrade()
              }}
            >
              <i className="iconfont  icon-time"></i>
            </IconWrapper>
          </TooltipsInfo>
          <Settings />
        </SettingWrapper>
      </RowBetween>
    </StyledSwapHeader>
  )
}
