import { gql } from '@apollo/client'
import { FarmSubgraph } from '../index'

export interface PoolInfo {
  farmPool: {
    id: string
  }
}

export default function getUserFarmLPPools(user: string): Promise<PoolInfo[]> {
  // if (!name) return Promise.resolve([]);
  return new Promise((resolve, reject) => {
    FarmSubgraph()
      .query<{
        userFarmPositions: PoolInfo[]
      }>({
        query: gql`
          query userFarmPositions($user: String) {
            userFarmPositions(where: { user: $user, lpAmount_gt: 0 }) {
              farmPool {
                id
              }
            }
          }
        `,
        variables: {
          user: user.toLowerCase()
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        const { data } = res
        if (data.userFarmPositions) {
          return resolve(data.userFarmPositions)
        }
        resolve([])
      })
      .catch((e: any) => {
        resolve([])
      })
  })
}
