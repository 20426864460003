/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
// import Countdown, { zeroPad } from 'react-countdown'
import { List, ListItem } from '@material-ui/core'
import styled from 'styled-components'
import classnames from 'classnames'
import TooltipInfo from 'components/TooltipInfo'

import { Contract } from 'ethers'
import { LaunchPad, PadPeriod, PadStatus, PadType } from 'gql/microservices/pads'
import StakeModal from '../StakeModal'
import ProjectIntroModal from '../ProjectIntroModal'
import { useActiveWeb3React } from 'hooks'
import BigNumber from 'bignumber.js'
import moment from 'moment'
import TokenImage from 'components/TokenImage'
import { useFormatSymbolTokenPrice } from 'state/data/hooks/useTokenPrice'
import WithdrawModal from '../WithdrawModal'
import { ButtonMetis } from 'components/Button'
import BuyModal from '../BuyModal'
import { useWalletModalToggle } from 'state/application/hooks'
import PeopleIcon from '@material-ui/icons/People'
import { LaunchPadIntroduction } from 'state/data'
import Value from 'components/Value'

const PadWrapper = styled.div`
  display: flex;
  width: 820px;
  position: relative;
  min-height: 407px;
  background: #1f0835;
  border-radius: 4px;
  padding: 12px 32px;
  margin-bottom: 32px;
  flex-wrap: wrap;
  align-content: space-between;
  &:last-child {
    margin-bottom: 0;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    max-width: 820px;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 12px 24px;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 12px;
  `}
  .is_whitelist {
    position: absolute;
    left: 0;
    top: 0;
  }
`
const PadHeader = styled.div`
  height: 68px;
  width: 100%;
  display: flex;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-flow: column;
    height: auto;
  `}
  .padHeaderCon {
    display: flex;
    width: 100%;
  }
`
const PadHeaderLogo = styled.div`
  display: flex;
  height: 68px;
  font-size: 16px;
  padding: 14px 0;
  font-weight: 600;
  color: #fff;
  line-height: 40px;
  min-width: 126px;

  img {
    width: 40px;
    height: 40px;
    margin-right: 24px;
  }
`
// time & status style
const PadTimeWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;

  ul {
    /* font-size: 0; */
    margin: 0;
    padding: 12px 0 0 0;
    li {
      display: inline-block;
      vertical-align: top;

      & .time_display {
        position: relative;
        display: block;
        width: 52px;
        height: 32px;

        em {
          position: absolute;
          z-index: 1;
          display: block;
          padding-left: 6px;
          width: 100%;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          color: #fff;
          line-height: 32px;
          text-align: center;
          letter-spacing: 14px;
        }

        &::before,
        &::after {
          position: absolute;
          top: 0;
          z-index: 0;
          content: '';
          display: inline-block;
          width: 24px;
          height: 32px;
          background: #150623;
          border-radius: 4px;
          text-align: center;
          vertical-align: top;
        }

        &::before {
          left: 0;
        }

        &::after {
          right: 0;
        }
      }

      & .time_colon {
        display: inline-block;
        padding: 0 10px;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        vertical-align: top;
        color: rgba(255, 255, 255, 0.5);
      }

      & .time_des {
        margin-top: 10px;
        display: block;
        font-size: 10px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.3);
        line-height: 14px;
        text-align: center;
      }
    }
  }
`
const PadStatusDom = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.text6};
  display: flex;
  align-items: center;
  margin-left: 72px;

  &.commingsoon {
    color: #0a8c10;
  }
  &.buy {
    color: #9daeff;
  }
  &.finish {
    color: #ff784f;
  }
  &.failure {
    color: #8f839a;
  }
  &.blocked {
    color: #fff;
  }
  em {
    font-size: 32px;
    font-weight: 600;
    margin-right: 9px;
  }
`

const PadContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 245px;
  padding: 20px;
  margin: 16px auto;
  background: #1a072c;
  border-radius: 4px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 20px 12px;
  `}
`
const PadContentTop = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;

  h2 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    line-height: 22px;
    margin: 0;
    span {
      font-size: 14px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.5);
      line-height: 22px;
      margin-left: 12px;
    }
  }
`
const PadContentBottom = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-flow: column nowrap;
  `}
`
const PadContentBottomLeft = styled.div`
  width: 306px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    max-width: 306px;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 100%;
  `}
  &.tipMiddle {
    align-items: center;
  }
  .MuiListItem-root {
    align-items: flex-start;
    &.progress {
      color: #fff;
      display: flex;
      align-items: center;
      font-size: 14px;
      padding-left: 0;

      .text {
        color: #ff784f;
      }

      .bar {
        background: #150623;
        position: relative;
        width: 140px;
        margin: 0 8px;
        border-radius: 4px;
        height: 8px;
        overflow: hidden;
        .content {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 50%;
          border-radius: 4px;

          background: #ff784f;
        }
      }

      &.success {
        .bar {
          .content {
            background: green;
          }
        }
        .text {
          color: green;
        }
      }
    }
  }
`
const PadContentBottomLeftTip = styled.h2`
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
`
const PadContentBottomLeftAvatar = styled(TokenImage)`
  border-radius: 50%;
  width: 40px;
  height: 40px;
`
const PadContentBottomLeftCon = styled.div`
  margin-left: 10px;
  p {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
    margin: 0;
  }
  span {
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
    line-height: 17px;
  }
`
const PadContentBottomRight = styled.div`
  width: 410px;
  background: rgba(21, 6, 35, 1);
  display: flex;
  flex-wrap: wrap;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    max-width: 410px;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 100%;
    margin-top: 16px;
  `}
  .MuiList-root {
    width: 100%;
  }
  .MuiListItem-root {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
    justify-content: space-between;
  }
`
const PadContentBottomRightBtn = styled.div`
  width: 100%;
  align-items: flex-end;
  justify-content: center;
  display: flex;
  flex-flow: column;
  margin-top: 16px;
  button {
    width: 128px;
    height: 36px;
    background: #c42a61;
    border-radius: 4px;
    text-align: center;
    line-height: 36px;
    border: none;
    color: #fff;
    cursor: pointer;
    &.finish {
      background: #ff784f;
    }
    &.finished {
      background: ##2a1034;
    }
    &.failure {
      background: #8f839a;
    }
    &.withdrawBtn {
      background: transparent;
      text-decoration: underline;
    }
  }
`

const PadFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
`
const PadFooterExplain = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  line-height: 20px;
`
const PadFooterLink = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.text6};
  line-height: 20px;
  text-decoration: underline;
  cursor: pointer;
`

const PaymentTokenInfo = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-right: 6px;
    width: 20px;
    height: 20px;
  }
`

const BtnDefault = styled(ButtonMetis)`
  cursor: pointer;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 400;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
    height: 48px;
  `}
`

const Button = styled(BtnDefault)`
  width: 100%;
  height: 32px;
  background: #c42a61;
  color: #fff;
  line-height: 32px;
  margin-left: 8px;
  max-width: 88px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 0;
    max-width: 100% !important;
  `}
  &.buy {
    background: #b35a40;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      margin-top: 10px;
    `}
  }
`

const PadTime = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  line-height: 17px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`
const PadMobileTime = styled.span`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
    line-height: 17px;
  `}
`

const LabelRightText = styled.span`
  word-break: break-all;
  text-align: right;
`

interface PadTimeProps {
  period: PadPeriod
  status: PadStatus
  padInfo: LaunchPad
  project?: LaunchPadIntroduction
  // handleCountDownOver(): void
}

const PadPeriodText = {
  [PadPeriod.Prepare]: PadPeriod.Prepare,
  [PadPeriod.Staking]: PadPeriod.Staking,
  [PadPeriod.Vesting]: 'Locked',
  [PadPeriod.Cashing]: 'Purchasing',
  [PadPeriod.Ended]: PadPeriod.Ended
}

function PadTimeAndStatus(props: PadTimeProps) {
  const { padInfo, period, status, project } = props

  const timeStr = useMemo(() => {
    if (padInfo.period === PadPeriod.Prepare) {
      return `Start around ${moment.utc(padInfo.startTime).format(`YYYY-MM-DD HH:mm(UTC)`)}`
    } else if (padInfo.period === PadPeriod.Staking) {
      return `Duration: ${moment.utc(padInfo.startTime).format(`YYYY-MM-DD HH:mm(UTC)`)} ~ ${moment
        .utc(padInfo.stakingEndTime)
        .format(`YYYY-MM-DD HH:mm(UTC)`)}`
    } else if (padInfo.period === PadPeriod.Vesting && padInfo.status === PadStatus.Success) {
      return `Duration: ${moment.utc(padInfo.stakingEndTime).format(`YYYY-MM-DD HH:mm(UTC)`)} ~ ${moment
        .utc(padInfo.vestingEndTime)
        .format(`YYYY-MM-DD HH:mm(UTC)`)}`
    } else if (padInfo.period === PadPeriod.Cashing && padInfo.status === PadStatus.Success) {
      return `Duration: ${moment.utc(padInfo.vestingEndTime).format(`YYYY-MM-DD HH:mm(UTC)`)} ~ ${moment
        .utc(padInfo.cashingEndTime)
        .format(`YYYY-MM-DD HH:mm(UTC)`)}`
    }
    return ''
  }, [padInfo])

  return (
    <>
      <div className="padHeaderCon">
        <PadHeaderLogo>
          {project ? (
            <img src={project.logo} style={{ objectFit: 'contain' }} alt="logo" />
          ) : (
            <TokenImage address={padInfo.saleToken.address} alt="logo" />
          )}
          {project ? project.name : padInfo.saleToken.symbol}
        </PadHeaderLogo>
        <PadTimeWrapper>
          {timeStr && (
            <PadTime>
              {timeStr}
              <TooltipInfo
                text={`If there is a discrepancy between the on-chain time and the actual time, the contract will use the on-chain time to determine the start time`}
                style={{ marginLeft: '8px', width: '16px', height: '16px' }}
              />
            </PadTime>
          )}
          <PadStatusDom className={classnames(period)}>
            {period && (
              <>
                <em>·</em>
                {period === PadPeriod.Prepare || period === PadPeriod.Staking
                  ? PadPeriodText[period]
                  : status === PadStatus.Fail
                  ? 'Failed'
                  : PadPeriodText[period]}
              </>
            )}
          </PadStatusDom>
        </PadTimeWrapper>
      </div>
      <PadMobileTime>{timeStr}</PadMobileTime>
      {/* {period !== PadPeriod.Ended && status !== PadStatus.Fail && (
        <Countdown
          date={date ? new Date(date) : new Date()}
          intervalDelay={0}
          precision={3}
          // onComplete={() => {
          //   handleCountDownOver()
          // }}
          renderer={props => (
            <ul>
              <li style={{ marginRight: '15px' }}>
                <span className="time_des">END:</span>
              </li>
              <li>
                <span className="time_display">
                  <em>{zeroPad(props.days) || '00'}</em>
                </span>
                <span className="time_des">DAYS</span>
              </li>
              <li>
                <span className="time_colon">:</span>
              </li>
              <li>
                <span className="time_display">
                  <em>{zeroPad(props.hours) || '00'}</em>
                </span>
                <span className="time_des">HRS</span>
              </li>
              <li>
                <span className="time_colon">:</span>
              </li>
              <li>
                <span className="time_display">
                  <em>{zeroPad(props.minutes) || '00'}</em>
                </span>
                <span className="time_des">MIN</span>
              </li>
              <li>
                <span className="time_colon">:</span>
              </li>
              <li>
                <span className="time_display">
                  <em>{zeroPad(props.seconds) || '00'}</em>
                </span>
                <span className="time_des">SEC</span>
              </li>
            </ul>
          )}
        />
      )} */}
    </>
  )
}

interface Props {
  baseModal: Contract | null
  unlimitModal: Contract | null
  data: LaunchPad
  userPadInfo: {
    cash: string
    paid: string
  }
  project?: LaunchPadIntroduction
}

function getFeeRate(totalStakeAmount: BigNumber, maxStakedCap: BigNumber) {
  const multipler = totalStakeAmount.div(maxStakedCap).toNumber()
  let res = 1
  if (multipler < 50) {
    res = 1
  } else if (multipler < 100) {
    res = 0.5
  } else if (multipler < 250) {
    res = 0.3
  } else if (multipler < 500) {
    res = 0.25
  } else if (multipler < 1000) {
    res = 0.2
  } else if (multipler < 1500) {
    res = 0.1
  } else {
    res = 0.05
  }
  return res
}

export default function Pad({ baseModal, unlimitModal, data, userPadInfo, project }: Props) {
  const listStatus = 'commingsoon'
  // const [padComputedPeriod, setPadComputedPeriod] = useState(data.period)
  const [isOpenStakeModal, setIsOpenStakeModal] = useState(false)
  const { account } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const [userStakeAmount, setUserStakeAmount] = useState(new BigNumber(0))
  const [userAllocation, setUserAllocation] = useState(new BigNumber(0))
  const tokenPrice = useFormatSymbolTokenPrice()
  const [isOpenWithdraw, setIsOpenWithdrawModal] = useState(false)
  const [isOpenBuyModal, setIsOpenBuyModal] = useState(false)
  const [isOpenIntro, setIsOpenIntro] = useState(false)
  // const [padStatus, setPadStatus] = useState(data.status)
  const [userInWhiteList, setUserInWhiteList] = useState(false)

  const stakeContract = useMemo(() => {
    return data.poolType === PadType.basic ? baseModal : unlimitModal
  }, [data.poolType, baseModal, unlimitModal])

  const stakeTokenPrice = useMemo(() => {
    if (tokenPrice && data.stakedToken) {
      const addr = data.stakedToken.address.toLowerCase()
      return tokenPrice[addr] ? tokenPrice[addr].price : 0
    }
    return 0
  }, [data.stakedToken, tokenPrice])

  const stakeTokenUsd = useMemo(() => {
    return userStakeAmount.multipliedBy(stakeTokenPrice).toFixed(2)
  }, [stakeTokenPrice, userStakeAmount])

  // function handleCountDownOver() {
  //   const now = new Date().getTime()
  //   let p = PadPeriod.Prepare

  //   if (now < data.startTime) {
  //     p = PadPeriod.Prepare
  //   } else if (now < data.stakingEndTime) {
  //     p = PadPeriod.Staking
  //   } else if (now < data.vestingEndTime) {
  //     p = PadPeriod.Vesting
  //   } else if (now < data.cashingEndTime) {
  //     p = PadPeriod.Cashing
  //   } else {
  //     p = PadPeriod.Ended
  //   }

  //   setPadComputedPeriod(p)
  //   getPadStatus()
  // }

  const padComputedPeriod = data.period
  const padStatus = data.status

  // const countDonwTime = useMemo(() => {
  //   if (padComputedPeriod === PadPeriod.Prepare) {
  //     return data.startTime
  //   } else if (padComputedPeriod === PadPeriod.Staking) {
  //     return data.stakingEndTime
  //   } else if (padComputedPeriod === PadPeriod.Vesting) {
  //     return data.vestingEndTime
  //   } else {
  //     return data.cashingEndTime
  //   }
  // }, [data, padComputedPeriod])

  const isPerpare = useMemo(() => {
    return padComputedPeriod === PadPeriod.Prepare
  }, [padComputedPeriod])

  const isStaking = useMemo(() => {
    return padComputedPeriod === PadPeriod.Staking
  }, [padComputedPeriod])

  // const isEnded = useMemo(() => {
  //   return padComputedPeriod === PadPeriod.Ended
  // }, [padComputedPeriod])

  async function getUserInfo() {
    if (!stakeContract || !account) return
    try {
      const res = await stakeContract.userInfo(data.pid, account)
      setUserStakeAmount(new BigNumber(res?.stakeAmount.toString() || 0).shiftedBy(-data.stakedToken.decimals))
      // if (res?.allocation.toNumber()) {
      //   setUserAllocation(new BigNumber(res?.allocation.toString() || 0).shiftedBy(-data.saleToken.decimals))
      // }
    } catch (e) {
      console.error(`get pad ${data.pid} userInfo ${account} failed`)
      console.error(e)
    }
  }

  async function getRealTimeStakedAmount() {
    if (stakeContract) {
      try {
        const res = await stakeContract.padInfo(data.pid)
        if (res && res.stakedAmount) {
          return new BigNumber(res.stakedAmount.toString()).shiftedBy(-data.stakedToken.decimals)
        }
      } catch (e) {
        console.error(`get pad ${data.pid} padInfo  failed`)
        console.error(e)
      }
    }
    return new BigNumber(0)
  }

  async function getUserWhitelistStatus() {
    if (!stakeContract || !account) return

    try {
      const res = await stakeContract.whitelist(data.pid, account)
      setUserInWhiteList(res)
    } catch (e) {
      console.error(`get pad ${data.pid} whitelist ${account} failed`)
      console.error(e)
    }
  }

  async function getCalcAllocation() {
    if (!stakeContract || !account) return
    try {
      const res = await stakeContract.calcAllocation(data.pid)
      if (res?.toString()) {
        setUserAllocation(new BigNumber(res?.toString()).shiftedBy(-data.saleToken.decimals))
      }
    } catch (e) {
      console.error(`get pad ${data.pid} calcAllocation ${account} failed`)
      console.error(e)
    }
  }

  useEffect(() => {
    getUserInfo()
    getCalcAllocation()
    getUserWhitelistStatus()
  }, [stakeContract, account, data.pid])

  const isStaked = useMemo(() => {
    return !!userStakeAmount.toNumber()
  }, [userStakeAmount])

  const stakeDuration = useMemo(() => {
    const d = moment(data.vestingEndTime).diff(data.stakingEndTime, 'days')
    return d
  }, [data])

  const additionFeeRate = useMemo(() => {
    return data.poolType === PadType.unlimited
      ? getFeeRate(new BigNumber(data.stakedAmount), new BigNumber(data.maxStakedCap))
      : 0
  }, [data])

  const shareRate = useMemo(() => {
    return Number(data.stakedAmount) && userStakeAmount.dividedBy(data.stakedAmount).isLessThanOrEqualTo(1)
      ? userStakeAmount.dividedBy(data.stakedAmount).multipliedBy(100)
      : new BigNumber(0)
  }, [data, userStakeAmount])

  const isPrepare = useMemo(() => {
    return data.period === PadPeriod.Prepare
  }, [data.period])

  const isVesting = useMemo(() => {
    return data.period === PadPeriod.Vesting
  }, [data.period])

  const computedPadStatus = useMemo(() => {
    return isPrepare || isStaking ? PadStatus.Success : padStatus
  }, [padStatus, isPrepare, isStaking, isVesting])

  const isCashing = useMemo(() => {
    return data.period === PadPeriod.Cashing
  }, [data.period])

  const isEnded = useMemo(() => {
    return data.period === PadPeriod.Ended
  }, [data.period])

  const canBuy = useMemo(() => {
    return padStatus === PadStatus.Success && isCashing && userAllocation
  }, [padStatus, isCashing, userAllocation])

  const stakedPercentage = useMemo(() => {
    return Number(data.maxStakedCap) ? (Number(data.stakedAmount) / Number(data.maxStakedCap)) * 100 : 0
  }, [data])

  const paidAmount = useMemo(() => {
    if (userPadInfo) {
      if (data.poolType === PadType.basic) {
        return userPadInfo.paid
      }

      return new BigNumber(userPadInfo.paid).multipliedBy(additionFeeRate / 100 + 1).toFixed()
    }

    return 0
  }, [userPadInfo, data.poolType, additionFeeRate])

  function handleTransSuccess() {
    getUserInfo()
  }

  const formatStakedAmount = useMemo(() => {
    const numberStakeAmount = Number(data.stakedAmount)

    if (numberStakeAmount) {
      if (numberStakeAmount >= 1000000) {
        return `${(numberStakeAmount / 1000000).toFixed(3)}M`
      } else if (numberStakeAmount >= 1000000000) {
        return `${(numberStakeAmount / 1000000000).toFixed(3)}B`
      }
    }

    return numberStakeAmount
  }, [data.stakedAmount])

  const progress = useMemo(() => {
    return Number(data.salesAmount) ? (Number(data.cashedAmount) / Number(data.salesAmount)) * 100 : -1
  }, [data])

  return (
    <>
      <PadWrapper id={data.id}>
        {data.isWhiteList && (
          <img
            className="is_whitelist"
            src={require('../../../../assets/images/ic_whitelist.png')}
            alt="whitelistIcon"
          />
        )}
        <PadHeader>
          <PadTimeAndStatus
            period={padComputedPeriod}
            status={padStatus}
            padInfo={data}
            project={project}
            // handleCountDownOver={handleCountDownOver}
          />
        </PadHeader>
        <PadContent>
          <PadContentTop>
            <h2>
              {data.poolType === PadType.basic ? 'Primary Tier' : 'Unlimited Tier'}{' '}
              {isStaking || (isCashing && <span>{isStaking ? 'ON STAKING' : 'ON SALE'}</span>)}
              <TooltipInfo
                text={
                  data.poolType === PadType.basic
                    ? 'Primary Tier is made for retail investors, there will be a limit on the staking amount of METIS or NETT token for each user.'
                    : 'Unlimited Tier is more appealing to those who hold a larger amount of assets. In Unlimited Tier, you can stake an unlimited amount of METIS or NETT token to get more IDO token allocations.'
                }
              />
            </h2>
            <TooltipInfo text="Number of participants">
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <PeopleIcon />
                &nbsp;&nbsp;
                {data.stakedUserAmountBeforeStakingEnd}
              </span>
            </TooltipInfo>
          </PadContentTop>
          <PadContentBottom>
            <PadContentBottomLeft className={classnames(isPerpare && 'tipMiddle')}>
              {isPerpare || !isStaked ? (
                (isCashing || isEnded) && userPadInfo ? (
                  <List>
                    <ListItem>
                      <PadContentBottomLeftAvatar address={data.stakedToken.address} alt="test" />
                      <PadContentBottomLeftCon>
                        <p>Total paid</p>
                        <span>
                          {paidAmount} {data.paymentToken.symbol}{' '}
                          {data.poolType === PadType.unlimited &&
                            `| Fee: ≈ ${(
                              (Number(paidAmount) / (1 + additionFeeRate / 100)) *
                              (additionFeeRate / 100)
                            ).toFixed(2)} ${data.paymentToken.symbol}`}
                        </span>
                      </PadContentBottomLeftCon>
                    </ListItem>
                    <ListItem>
                      <PadContentBottomLeftAvatar address={data.saleToken.address} alt="test" />
                      <PadContentBottomLeftCon>
                        <p>Total received</p>
                        <span>
                          {userPadInfo.cash} {data.saleToken.symbol}
                        </span>
                      </PadContentBottomLeftCon>
                    </ListItem>
                  </List>
                ) : (
                  <PadContentBottomLeftTip>
                    Stake {data.stakedToken.symbol} to get {data.saleToken.symbol} allocation
                  </PadContentBottomLeftTip>
                )
              ) : (
                <List>
                  <ListItem>
                    <PadContentBottomLeftAvatar address={data.stakedToken.address} alt="test" />
                    <PadContentBottomLeftCon>
                      <p>Your {data.stakedToken.symbol} token staked</p>
                      <p>
                        {userStakeAmount.toFixed()} {data.stakedToken.symbol}
                      </p>
                      <span>
                        ≈ {stakeTokenUsd} USD{' '}
                        {computedPadStatus === PadStatus.Success
                          ? `| ${shareRate.lt(0.01) && shareRate.gt(0) ? `< 0.01` : shareRate.toFixed(2)}% of total`
                          : ''}
                      </span>
                    </PadContentBottomLeftCon>
                  </ListItem>
                  {canBuy && (
                    <ListItem>
                      <PadContentBottomLeftAvatar address={data.saleToken.address} alt="test" />
                      <PadContentBottomLeftCon>
                        <p>{data.saleToken.symbol} to receive</p>
                        <p>
                          ≈ {userAllocation.toFixed()} {data.saleToken.symbol}
                        </p>
                      </PadContentBottomLeftCon>
                    </ListItem>
                  )}
                </List>
              )}
              {(isCashing || (isEnded && padStatus === PadStatus.Success)) && progress !== -1 && (
                <List>
                  <ListItem className={classnames('progress', progress === 100 && 'success')}>
                    <span>Progress</span>
                    <span className="bar">
                      <span className="content" style={{ width: `${progress}%` }}></span>
                    </span>
                    <span className="text">{progress.toFixed(2)}%</span>
                  </ListItem>
                </List>
              )}
            </PadContentBottomLeft>
            <PadContentBottomRight>
              <List>
                <ListItem>
                  <span>Sales Amount</span>
                  <span>
                    <Value value={Number(data.salesAmount)} decimals={0} /> {data.saleToken.symbol}
                  </span>
                </ListItem>
                {data.poolType === PadType.unlimited && (
                  <ListItem>
                    <span>Additional fee</span>
                    <span>{additionFeeRate.toFixed(2)}%</span>
                  </ListItem>
                )}
                {data.poolType === PadType.basic ? (
                  <>
                    {/* Hard Cap */}
                    <ListItem>
                      <span>
                        <TooltipInfo
                          text={'The upper limit on the number of tokens that can be staked.'}
                          style={{ marginLeft: '-12px', width: '16px', height: '16px' }}
                        />
                        Hard Cap
                        <br />
                        (<Value value={Number(data.maxStakedCap)} decimals={0} /> {data.stakedToken.symbol})
                      </span>
                      <LabelRightText>
                        <Value value={formatStakedAmount} decimals={3} /> {data.stakedToken.symbol}
                        <br />({stakedPercentage.toFixed(0)}%)
                      </LabelRightText>
                    </ListItem>
                    <ListItem>
                      <span>
                        <TooltipInfo
                          text={'Maximum staking cap per user'}
                          style={{ marginLeft: '-12px', width: '16px', height: '16px' }}
                        />
                        Max. staking cap per user
                      </span>
                      <LabelRightText>
                        <Value value={Number(data.maxPerUser)} />
                        {data.stakedToken.symbol}
                      </LabelRightText>
                    </ListItem>
                  </>
                ) : (
                  <>
                    <ListItem>
                      <span>
                        <TooltipInfo
                          text={'The target amount for staking triggers the fee change.'}
                          style={{ marginLeft: '-12px', width: '16px', height: '16px' }}
                        />
                        Target staked amount
                        <br />
                        (<Value value={Number(data.maxStakedCap)} decimals={0} />
                        {data.stakedToken.symbol})
                      </span>
                      <LabelRightText>
                        <Value value={formatStakedAmount} decimals={3} /> {data.stakedToken.symbol}
                        <br />({stakedPercentage.toFixed(0)}%)
                      </LabelRightText>
                    </ListItem>
                    <ListItem>
                      <span>
                        <TooltipInfo
                          text={`If total number of staked users doesn't reach this number, this sale will fail`}
                          style={{ marginLeft: '-12px', width: '16px', height: '16px' }}
                        />
                        Min. staked users
                      </span>
                      <LabelRightText>
                        <Value value={Number(data.minStakedUserAmount)} decimals={0} />
                      </LabelRightText>
                    </ListItem>
                  </>
                )}
                <ListItem>
                  <span>
                    <TooltipInfo
                      text={`If total staked token amount doesn't reach this number, this sale will fail`}
                      style={{ marginLeft: '-12px', width: '16px', height: '16px' }}
                    />
                    Min. staking cap for total
                  </span>
                  <LabelRightText>
                    <Value value={data.minStakedCap} decimals={2} /> {data.stakedToken.symbol}
                  </LabelRightText>
                </ListItem>
                <ListItem>
                  <span>Price</span>
                  <LabelRightText>$ {data.price}</LabelRightText>
                </ListItem>
                <ListItem>
                  <span>Pay In</span>
                  <PaymentTokenInfo>
                    <TokenImage address={data.paymentToken.address} /> {data.paymentToken.symbol}
                  </PaymentTokenInfo>
                </ListItem>
              </List>
            </PadContentBottomRight>
          </PadContentBottom>
          {isStaking && (
            <PadContentBottomRightBtn>
              {isStaked ? (
                <>
                  <button
                    className={classnames(listStatus)}
                    onClick={() => {
                      setIsOpenStakeModal(true)
                    }}
                  >
                    Increase
                  </button>
                  <button
                    className="withdrawBtn"
                    onClick={() => {
                      setIsOpenWithdrawModal(true)
                    }}
                  >
                    Withdraw
                  </button>
                </>
              ) : account ? (
                <button
                  className={classnames(listStatus)}
                  onClick={() => {
                    setIsOpenStakeModal(true)
                  }}
                >
                  Stake
                </button>
              ) : (
                <button className={classnames(listStatus)} onClick={toggleWalletModal}>
                  Connect Wallet
                </button>
              )}
            </PadContentBottomRightBtn>
          )}
          {(isCashing || isEnded) && isStaked && (
            <PadContentBottomRightBtn>
              <Button
                className={canBuy ? 'buy' : ''}
                onClick={() => {
                  canBuy ? setIsOpenBuyModal(true) : setIsOpenWithdrawModal(true)
                }}
              >
                Withdraw{canBuy ? '&Buy' : ''}
              </Button>
            </PadContentBottomRightBtn>
          )}
        </PadContent>
        <PadFooter>
          <PadFooterExplain>
            Locked duration: {stakeDuration} Day{stakeDuration > 1 ? 's' : ''}
          </PadFooterExplain>
          {project && (
            <PadFooterLink onClick={() => setIsOpenIntro(true)}>Learn more about {data.saleToken.symbol}</PadFooterLink>
          )}
        </PadFooter>
      </PadWrapper>
      <StakeModal
        isOpen={isOpenStakeModal}
        onClose={() => {
          setIsOpenStakeModal(false)
        }}
        padInfo={data}
        baseModal={baseModal}
        unlimitModal={unlimitModal}
        handleStakeSuccess={handleTransSuccess}
        userStakeAmount={userStakeAmount}
        additionFeeRate={additionFeeRate}
        userInwhiteList={userInWhiteList}
        project={project}
        getRealTimeStakedAmount={getRealTimeStakedAmount}
      />
      <WithdrawModal
        isOpen={isOpenWithdraw}
        onClose={() => {
          setIsOpenWithdrawModal(false)
        }}
        padInfo={data}
        baseModal={baseModal}
        unlimitModal={unlimitModal}
        handleWithdrawSuccess={handleTransSuccess}
        userStakeAmount={userStakeAmount}
      />
      <BuyModal
        isOpen={isOpenBuyModal}
        onClose={() => {
          setIsOpenBuyModal(false)
        }}
        padInfo={data}
        baseModal={baseModal}
        unlimitModal={unlimitModal}
        handleWithdrawSuccess={handleTransSuccess}
        userStakeAmount={userStakeAmount}
        allocation={userAllocation}
        shareRate={shareRate}
        additionFeeRate={additionFeeRate}
      />
      <ProjectIntroModal
        isOpen={isOpenIntro}
        padInfo={data}
        project={project}
        onClose={() => {
          setIsOpenIntro(false)
        }}
      />
    </>
  )
}
