/* eslint-disable react/no-children-prop */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { isMobile } from 'react-device-detect'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import rehypeRaw from 'rehype-raw'

import { LinearProgress, Stepper, Step, StepLabel, StepConnector, StepIconProps, Tab, Tabs } from '@material-ui/core'
import { ExternalLink } from 'theme'
import { RowBetween } from 'components/Row'
import { AuthButton } from 'components/Button'
import moment from 'moment'
import Value from 'components/Value'
import DepositModal from './components/DepositModal'
import { useHistory, useParams } from 'react-router-dom'
import { ProjectIntro, useGetLaunchpadV2DetailById, useGetProjecInfo } from 'state/data/hooks/useGetLaunchpadV2Data'
import UnlimitedAbi from '../../constants/launchpadV2/unlimited.json'
import useLaunchpadV2PadContract, { Phase } from 'hooks/contract/useLaunchpadV2PadContract'
import { useGetWNett } from 'hooks/contract/useGetContract'
import BigNumber from 'bignumber.js'
import { useActiveWeb3React } from 'hooks'
import useSend from 'state/contract/hooks/useSend'
import { steps } from './config'
import { useAddTokenToMetakMask } from 'hooks/useAddTokenToMetamask'
import { getTokenImage } from 'utils'
import TokenImage from 'components/TokenImage'

import TelegramIcon from '@material-ui/icons/Telegram'
import TwitterIcon from '@material-ui/icons/Twitter'
import LanguageIcon from '@material-ui/icons/Language'
import MediumIcon from '../../components/Icon/medium'
import DiscordIcon from '../../components/Icon/discord'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import axios from 'axios'
import { ChainId } from '@netswap/sdk'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import { ReactComponent as StepSVG } from 'assets/images/launchpad/step-check.svg'

const OutterLink = styled(ExternalLink)`
  color: #c3c5cb;
  font-size: 1rem;
  font-weight: 400;
  display: inline-block;
  margin: 0 5px;
  width: 24px;
  height: 24px;
  text-align: center;
  text-decoration: none !important;
  padding: 5px;
  &:hover {
    color: #fff;
  }
`

const MediaWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

function SmallShareCon({ projectInfo }: { projectInfo?: ProjectIntro }) {
  const MENUS = [
    {
      label: 'Web',
      link: projectInfo?.projectInfo?.website,
      img: <LanguageIcon fontSize={'small'} />,
    },
    {
      label: 'Telegram',
      link: projectInfo?.projectInfo?.telegram,
      img: <TelegramIcon fontSize={'small'} />,
    },
    {
      label: 'Twitter',
      link: projectInfo?.projectInfo?.twitter,
      img: <TwitterIcon fontSize={'small'} />,
    },
    {
      label: 'Medium',
      link: projectInfo?.projectInfo?.medium,
      img: <MediumIcon fontSize={'small'} />,
    },
    {
      label: 'Discord',
      link: projectInfo?.projectInfo?.discord,
      img: <DiscordIcon fontSize={'small'} />,
    },
  ]
  return (
    <div>
      {MENUS.map((item, _) => {
        return (
          item.link && (
            <OutterLink href={item.link} target="_blank" key={item.label}>
              {item.img}
            </OutterLink>
          )
        )
      })}
    </div>
  )
}

const DetailWrapper = styled.div`
  width: 100%;
  max-width: 1180px;
  padding: 20px;
  h2,
  h3,
  p {
    margin: 0;
  }

  @media (max-width: 720px) {
    padding: 0;
    width: calc(100vw - 2rem);
  }
  /* detailInfo start */
  .detailInfo {
    background: ${({ theme }) => theme.v2.bg02};
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    .leftBorder,
    .rightBorder {
      z-index: -9;
      position: absolute;
      width: 782px;
      height: 782px;
      border: 1px solid rgba(255, 255, 255, 0.05);
      border-radius: 50%;
    }
    .leftBorder {
      left: 60px;
      top: -123px;
    }
    .rightBorder {
      left: 172px;
      top: -535px;
    }
    .detailLeftCon {
      background: ${({ theme }) => theme.v2.bg04};

      display: flex;
      max-width: 400px;
      width: 100%;
      flex-flow: column nowrap;
      padding: 20px;
      box-sizing: border-box;
      h2 {
        /* font-weight: 600; */
        font-size: 14px;
        line-height: 16px;
        color: ${({ theme }) => theme.v2.t02};
      }
      .detailSymbolInfo {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        width: 100%;

        h3 {
          margin-top: 20px;
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          color: ${({ theme }) => theme.v2.t01};
        }
        p {
          margin-top: 10px;
          max-width: 320px;
          text-align: left;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: ${({ theme }) => theme.v2.t02};
          &.whitelistTip {
            min-width: 135px;
            margin: 0;
            height: 44px;
            background: rgba(22, 103, 246, 0.05);
            border-radius: 56px;
            font-weight: 400;
            font-size: 14px;
            line-height: 44px;
            color: ${({ theme }) => theme.v2.t09};
          }
        }
        .symbolBtn {
          border: none;
          padding: 12px 20px;
          text-align: center;
          max-width: unset;
          height: 44px;
          border-radius: 10px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          width: fit-content;
        }

        .authButton {
          margin-top: 16px;
          p {
            color: #fff;
            margin: 0;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
    .detailRightCon {
      display: flex;
      width: 100%;
      flex: 1;
      flex-flow: column nowrap;
      padding: 20px;
      max-width: 780px;
      border-radius: 20px;
      .rightTopCon {
        display: flex;
        align-items: center;
        /* margin-bottom: 40px; */
        position: relative;
        .iconInfo {
          position: absolute;

          height: 32px;
          right: 0;
          top: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          @media (max-width: 720px) {
            width: 100%;
            justify-content: space-between;
          }

          .item {
            display: flex;
            box-sizing: border-box;
            padding: 0 8px;
            justify-content: center;
            align-items: center;
            background: ${({ theme }) => theme.v2.bg04};

            border-radius: 6px;
            font-weight: 300;
            font-size: 16px;
            color: ${({ theme }) => theme.v2.t01};
            height: 100%;

            img {
              width: 30px;
              margin-right: 8px;
            }

            p {
              font-weight: 700;
              font-size: 16px;
              color: ${({ theme }) => theme.v2.t01};
              margin: 0 0 0 5px;
            }

            &:first-child {
              margin-right: 8px;
            }
          }
        }
        img {
          width: 76px;
          height: 76px;
          margin-right: 16px;
        }
        .projectInfo {
          h2 {
            font-weight: 600;
            font-size: 28px;
            line-height: 34px;
            color: ${({ theme }) => theme.v2.t01};
          }
          p {
            font-weight: 300;
            font-size: 14px;
            line-height: 22px;
            color: ${({ theme }) => theme.v2.t02};
            margin: 4px 0 0 0;
          }
        }
      }
      .durationCon {
        justify-content: flex-end;
        margin-top: 40px;
        .durationInfo {
          /* width: calc(50% - 5px); */
          display: flex;
          justify-content: center;
          align-items: flex-end;
          h3 {
            padding: 0 8px;
            border-radius: 2px;
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            text-align: center;
            color: #75f47a;
            background: rgba(117, 244, 122, 0.1);
            margin-right: 8px;
            &.endTitle {
              color: #f64278;
              background: rgba(246, 66, 120, 0.1);
            }
          }
          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #ffffff;
          }
        }
        .prepare {
          border: none;
          padding: 0 8px;
          min-width: 100px;
          line-height: 24px;
          background: ${({ theme }) => theme.v2.bg04};

          border-radius: 13px;
          font-weight: 500;
          font-size: 14px;
          color: ${({ theme }) => theme.v2.t01};
        }

        .red {
          border: none;
          padding: 0 8px;
          min-width: 100px;
          line-height: 24px;
          background: red;

          border-radius: 13px;
          font-weight: 500;
          font-size: 14px;
          color: #fff;
        }
      }

      .rightBottomCon {
        margin-top: 40px;
        h2 {
          font-weight: 300;
          font-size: 12px;
          line-height: 14px;
          color: #767c93;
        }
        .saleInfo,
        .amountInfo {
          h3 {
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: ${({ theme }) => theme.v2.t01};
          }
          p {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: ${({ theme }) => theme.v2.t01};
            /* transform: scale(0.8); */
            span {
              color: ${({ theme }) => theme.v2.t02};
            }
          }
        }
        .saleInfo {
          margin: 4px 0 10px;
          align-items: flex-end;
        }
        .amountInfo {
          margin: 10px 0 0 0;
        }

        /* progress */
        .MuiLinearProgress-root {
          height: 20px;
          border-radius: 15px;
        }
        .MuiLinearProgress-colorPrimary {
          background: #4b2958;
        }
        .MuiLinearProgress-barColorPrimary {
          background: ${({ theme }) => theme.v2.t09};
          border-radius: 15px;
        }
      }
    }

    @media (max-width: 1024px) {
      .detailLeftCon {
        max-width: 266px;
        h2 {
          font-size: 16px;
          line-height: 19px;
        }
        .detailSymbolInfo {
          margin: 40px 0;
          h3 {
            font-size: 32px;
            line-height: 38px;
          }
          p {
            max-width: 220px;
          }
        }
      }
      .detailRightCon {
        padding: 20px;
        max-width: 498px;
        .rightTopCon {
          margin-bottom: 20px;
          img {
            max-width: 80px;
            max-height: 80px;
            margin-right: 20px;
          }
          .projectInfo {
            h2 {
              font-size: 24px;
              line-height: 29px;
            }
            p {
              font-size: 12px;
              line-height: 19px;
            }
          }
        }
        .durationCon {
          flex-wrap: wrap;
          .durationInfo {
            h3 {
              font-size: 12px;
              line-height: 21px;
            }
            p {
              font-size: 12px;
              line-height: 19px;
            }
          }
          .mediaDurationInfo {
            width: 100%;
            justify-content: flex-start;
            margin-bottom: 10px;
          }
          .prepare {
            font-size: 12px;
          }
        }

        .rightBottomCon {
          margin-top: 20px;
          h2 {
            font-size: 12px;
            line-height: 17px;
          }
          .saleInfo {
            margin: 4px 0 8px;
            align-items: flex-end;
          }
          .amountInfo {
            margin: 8px 0 0 0;
          }
        }
      }
    }

    @media (max-width: 720px) {
      justify-content: center;
      flex-flow: column nowrap;
      .detailLeftCon {
        max-width: 100%;
        .detailSymbolInfo {
          margin: 30px 0 20px;
        }
      }
      .detailRightCon {
        max-width: 100%;

        .rightTopCon {
          align-items: center;
          flex-flow: column nowrap;
          img {
            margin: 21px 0 10px;
          }
          .projectInfo {
            text-align: center;
          }
        }

        .rightBottomCon {
          h2 {
            line-height: 14px;
          }
          .saleInfo,
          .amountInfo {
            h3 {
              font-size: 16px;
              line-height: 19px;
            }
          }
          .saleInfo {
            margin: 4px 0;
            align-items: flex-end;
          }
          .amountInfo {
            margin: 4px 0 0 0;
            flex-direction: column;
            align-items: flex-start;
            p {
              font-size: 12px;
              line-height: 14px;
            }
          }
        }
      }
    }
  }
  /* detailInfo end */

  /* depositInfo start */
  .depositInfo {
    width: 100%;
    margin: 40px 0;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.v2.bg02};
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    .leftBorder,
    .rightBorder {
      z-index: -9;
      position: absolute;
      width: 782px;
      height: 782px;
      border: 1px solid rgba(255, 255, 255, 0.05);
      border-radius: 50%;
    }
    .leftBorder {
      left: 40px;
      top: -34px;
    }
    .rightBorder {
      width: 637px;
      height: 637px;
      left: 699px;
      top: -246px;
    }
    .depositLeftCon {
      display: flex;
      flex-flow: column nowrap;
      h2 {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: ${({ theme }) => theme.v2.t02};
      }
      p {
        margin-top: 20px;
        font-weight: 400;
        font-size: 30px;
        line-height: 36px;

        font-weight: bold;
        color: ${({ theme }) => theme.v2.t01};
      }
      h5 {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-top: 10px;
        color: ${({ theme }) => theme.v2.t01};
      }
    }
    .depositRightCon {
      width: 100%;
      max-width: 400px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      h2 {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: ${({ theme }) => theme.v2.t02};
      }
      p {
        margin: 8px 0;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 96px;
        color: ${({ theme }) => theme.v2.t01};
        span {
          font-size: 20px;
        }
      }

      .authButton {
        p {
          margin: 0;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }

    @media (max-width: 1024px) {
      margin: 20px 0 40px;
      padding: 40px 20px;
      .depositLeftCon {
        h2 {
          font-size: 40px;
          line-height: 48px;
        }
        p {
          font-size: 18px;
          line-height: 29px;
        }
      }
    }

    @media (max-width: 720px) {
      padding: 20px;
      flex-flow: column;
      .depositLeftCon {
        margin-bottom: 25px;
        align-items: center;
        h2 {
          font-size: 32px;
          line-height: 38px;
        }
        p {
          font-size: 16px;
          line-height: 26px;
        }
      }

      .depositRightCon {
        max-width: 100%;
        align-items: center;
      }
    }
  }
  /* depositInfo end */
  /* stepsInfo start */
  .stepsInfo {
    margin: 96px 0;
    .MuiStepConnector-alternativeLabel {
      ${({ theme }) => theme.mediaWidth.upToSmall`
            top: 22px;
            left: calc(-50% + 30px);
            right: calc(50% + 30px);
        `}
    }
    /* step css */
    .MuiStepper-root {
      padding: 0;
      background: transparent;
    }
    .MuiStepLabel-label {
      > span {
        display: inline-block;

        font-weight: 500;
        font-size: 14px;
        vertical-align: middle;
        color: #989dae;
        background: ${({ theme }) => theme.v2.t09};
        padding: 0 20px;
        padding-top: 5px;
        color: #000;

        ${({ theme }) => theme.mediaWidth.upToSmall`
            padding-left: 5px;
            padding-right: 5px;
        `}

        height: 26px;
        border-radius: 13px;
      }
    }
    .MuiStepLabel-label.MuiStepLabel-completed {
      color: #ffffff;
    }
    .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
      margin-top: 40px;
    }

    @media (max-width: 1024px) {
      margin: 0 0 40px 0;
      .MuiStepLabel-label {
        font-size: 14px;
        line-height: 17px;
      }
      .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
        margin-top: 20px;
      }
    }
  }
  /* stepsInfo end */

  /* infosLists start */
  .infosLists {
    width: 100%;
    background: transparent;
    .infosItem {
      width: 100%;
      padding: 10px;
      border-radius: 6px;
      color: ${({ theme }) => theme.v2.t01};
      &:nth-child(even) {
        background: ${({ theme }) => theme.v2.bg04};
      }

      &.video {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
    .markdown {
      width: 100%;
      img {
        width: 100%;
      }

      h2,
      h3,
      p {
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
      }
    }
    .infosCon {
      h3,
      a,
      p {
        color: ${({ theme }) => theme.v2.t01};
        font-size: 16px;
        line-height: 26px;
      }
      h3 {
        font-weight: 300;
        color: ${({ theme }) => theme.v2.t01};
      }
      a {
        font-weight: 500;
        color: ${({ theme }) => theme.v2.t05};
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
      p {
        font-weight: 500;
        color: ${({ theme }) => theme.v2.t01};
        display: flex;
        align-items: center;
        word-break: break-all;
        margin-left: 10px;
        img {
          margin-right: 8px;
        }
      }
      .infosAvatars {
        height: 24px;
        a {
          margin-right: 8px;
          &:last-child {
            margin: 0;
          }
        }
        img {
          max-width: 24px;
          max-height: 24px;
        }
      }
    }

    .MuiInput-root {
      padding: 10px;
      width: 100%;
      height: 44px;
      margin-bottom: 34px;
      color: #fff;
      font-weight: 600;

      font-size: 16px;

      background: #2c163a;
      border: 0.5px solid #1e4448;
      border-radius: 4px;
      &.MuiInput-underline:after {
        display: none;
      }
    }
    .MuiSelect-icon {
      color: #ffffff;
      font-size: 28px;
    }

    @media (max-width: 1024px) {
      padding: 20px;
    }
    @media (max-width: 720px) {
      .infosItem {
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 6px;
        background: rgba(255, 255, 255, 0.02);
        flex-flow: column wrap;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .infosCon {
        h3,
        a,
        p {
          font-size: 14px;
          line-height: 22px;
          width: 100%;
        }
        h3 {
          padding-bottom: 8px;
          margin-bottom: 8px;
          border-bottom: 0.5px solid #1e4448;
        }
        .infosAvatars {
          width: 100%;
        }
      }
    }
  }
  /* infosLists end */
`

const DepositBtn = styled(AuthButton)`
  width: 100%;
  height: 46px;
  background: #c42a61;
  border-radius: 56px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  line-height: 46px;
  border: none;
  max-width: 294px;

  @media (max-width: 1024px) {
  }
`
// const getSteps = ['Get wNETT', 'Required Whitelist', 'Deposit', 'Distribute', 'Sale Ends']

const LaunchpadConnector = withStyles({
  alternativeLabel: {
    top: 26,
    left: 'calc(-50% + 42px)',
    right: 'calc(50% + 42px)',
  },
  active: {
    '& $line': {
      background: 'linear-gradient(234deg, #00cfff 0%, #34C1FF 53%, #904BF9 100%)',
    },
  },
  completed: {
    '& $line': {
      background: 'linear-gradient(234deg, #00cfff 0%, #34C1FF 53%, #904BF9 100%)',
    },
  },
  line: {
    background: 'rgba(255, 255, 255, 0.19)',
    height: 10,
    borderRadius: 3,
  },
})(StepConnector)

// const LaunchpadConnector = styled(StepConnector)`
//     .MuiStepLabel-alternativeLabel {
//         top: 26px;
//         left: calc(-50% + 42px);
//         right: calc(50% + 42px);
//     }
//     .MuiStepConnector-line  {
//         .MuiStepConnector-completed {
//             border-color: linear-gradient(234deg, #00cfff 0%, #34C1FF 53%, #904BF9 100%);
//         }
//         border-color: rgba(255, 255, 255, 0.19);
//         border-top-width: 10px;
//         border-radius: 1px;
//     }
// `

const useLaunchpadStepIconStyles = makeStyles({
  root: {
    display: 'flex',
    height: 52,
    width: 52,
    alignItems: 'center',
    justifyContent: 'center',
    color: '#989dae',
    backgroundColor: 'transparent',
    border: '1px solid #989dae',
    fontSize: '24px',
    borderRadius: '50%',
  },
  active: {
    color: '#000',
    backgroundColor: '#00cfff',
    border: 'none',
  },
  completedRoot: {
    border: 'none',
  },
  completed: {
    maxWidth: '100%',
  },
})

const LaunchpadStepIcon = (props: StepIconProps) => {
  const classes = useLaunchpadStepIconStyles()
  const { active, completed, icon } = props

  return (
    <div
      className={cx(classes.root, {
        [classes.active]: active,
        [classes.completedRoot]: completed,
      })}
    >
      {/* {completed ? <CheckCircleOutlineRoundedIcon className={classes.completed} /> : icon} */}
      {completed ? <StepSVG /> : icon}
    </div>
  )
}

const NetswapLogo = styled.img`
  width: 98px;
  height: 26px;
  margin-top: 10px;
`
const StyledTabs = styled(Tabs)`
  .MuiTabs-root {
    margin-bottom: 40px;
  }
  .MuiTabs-flexContainer {
    justify-content: flex-start;
  }
  .MuiTab-root {
    padding: 0 20px;
    color: ${({ theme }) => theme.v2.t02};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        padding-left: 8px;
        padding-right: 8px;

        font-size: 14px;
    `}

    &.Mui-selected {
      color: ${({ theme }) => theme.v2.t09};
    }
  }
  .MuiTabs-indicator {
    display: flex;
    justify-content: flex-start;
    background-color: transparent;
    bottom: 0px !important;
    background-color: ${({ theme }) => theme.v2.t09};
    /* width: 60px !important; */
  }
`
interface StyledTabProps {
  label: string
}

const StyledTab = withStyles(() =>
  createStyles({
    root: {
      textTransform: 'none',
      paddingLeft: 0,
      color: '#7f6188',
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '24px',
      minWidth: 'auto',
      padding: '6px 28px 6px 0',
      '&:focus': {
        opacity: 1,
        color: '#ffffff',
      },
      '@media screen and (max-width: 720px)': {
        padding: '6px 20px 6px 0',
      },
    },
    selected: {
      fontWeight: 700,
      color: '#ffffff',
    },
    wrapper: {
      alignItems: 'flex-start',
    },
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />)

function a11yProps(index: any) {
  return {
    id: `launchpad-tab-${index}`,
    'aria-controls': `launchpad-tabpanel-${index}`,
  }
}

const Back = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  cursor: pointer;
  padding-bottom: 16px;
  color: ${({ theme }) => theme.v2.t01};
  font-weight: bold;

  span {
    margin-left: 8px;
    font-size: 20px;
  }
`

const Tips = styled.div`
  line-height: 1.4;
  color: ${({ theme }) => theme.v2.t02};
  font-size: 14px;
`

const PeopleIcon = styled(SupervisorAccountIcon)`
  fill: ${({ theme }) => theme.v2.t01};
  /* height: 20px !important; */
`

function getFeeRate(totalStakeAmount: number, totalRaised: number) {
  const multipler = totalStakeAmount / totalRaised
  let res = 1
  if (multipler <= 1) {
    res = 1
  } else if (multipler <= 1.5) {
    res = 0.5
  } else if (multipler <= 2) {
    res = 0.3
  } else if (multipler <= 2.5) {
    res = 0.25
  } else if (multipler <= 5) {
    res = 0.2
  } else if (multipler <= 10) {
    res = 0.1
  } else {
    res = 0.05
  }
  return res
}

export default function Detail() {
  const {
    id,
  }: {
    id: string
  } = useParams()
  const send = useSend()
  const { account } = useActiveWeb3React()
  const padInfo = useGetLaunchpadV2DetailById(id)
  const [canAdd, addToMetaMask] = useAddTokenToMetakMask()

  const {
    getPadCurrenctPhase,
    padContract,
    currentPhase,
    userRefunds,
    getUserRefunds,
    getUserInfo,
    userStakePaymentTokenAmount,
    userAllocation,
    userHasClaimedRefunds,
    getPadStoppedStatus,
    stopped,
  } = useLaunchpadV2PadContract(padInfo?.id, UnlimitedAbi)
  const wNettContract = useGetWNett()
  const [userwNettBalance, setUserWNettBalance] = useState(new BigNumber(0))
  const projectInfo = useGetProjecInfo(padInfo?.id)
  const [issuerCharged, setIssuerCharged] = useState(new BigNumber(0))

  async function getUserWNettBalance() {
    if (wNettContract && account) {
      const res = await wNettContract.balanceOf(account)

      setUserWNettBalance(res?.toString() ? new BigNumber(res.toString()).shiftedBy(-18) : new BigNumber(0))
    }
  }

  useEffect(() => {
    getUserWNettBalance()
  }, [wNettContract, account])

  const [projectInfoTab, setProjectInfoTab] = useState(0)
  const [isOpenDepositModal, setIsOpenDepositModal] = useState(false)
  const handleInfoChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setProjectInfoTab(newValue)
  }

  const percentage = useMemo(() => {
    return padInfo ? (padInfo.paidAmountBeforeLaunch / padInfo.targetRaised) * 100 : 0
  }, [padInfo])

  // const currentSold = useMemo(() => {
  //   if (!padInfo) return 0
  //   if (padInfo.paidAmountBeforeLaunch > padInfo.targetRaised) {
  //     return padInfo.issuedTokenAmount
  //   }

  //   return padInfo.paidAmountBeforeLaunch / padInfo.price
  // }, [padInfo])

  const depositStartTime = useMemo(() => {
    if (!padInfo) return '--'
    return moment.utc(padInfo.depositStart).format('YYYY-MM-DD HH:mm(UTC)')
  }, [padInfo])

  const depositEndTime = useMemo(() => {
    if (!padInfo) return '--'
    return moment.utc(padInfo.depositEnd).format('YYYY-MM-DD HH:mm(UTC)')
  }, [padInfo])

  const launchTime = useMemo(() => {
    if (!padInfo) return '--'
    return moment.utc(padInfo.launchTime).format('YYYY-MM-DD HH:mm(UTC)')
  }, [padInfo])

  useEffect(() => {
    let interval: any
    if (padContract) {
      getPadCurrenctPhase()
      getPadStoppedStatus()
      interval = setInterval(() => {
        getPadCurrenctPhase()
        getPadStoppedStatus()
      }, 30000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [padContract])

  function handleDepositSuccess() {
    getUserWNettBalance()
    getUserInfo(account || '', padInfo?.issuedToken.decimals)
  }

  useEffect(() => {
    getUserRefunds(account || '')
    getUserInfo(account || '', padInfo?.issuedToken.decimals)
  }, [padContract, account, padInfo])

  const hasRefunds = useMemo(() => {
    return userRefunds.isGreaterThan(0)
  }, [userRefunds])

  async function refund() {
    if (padContract) {
      await send({
        contract: padContract,
        method: 'refund',
        params: [],
        pendingText: `Refunding ${padInfo.paymentToken.symbol}`,
        summary: `Refunded  ${padInfo.paymentToken.symbol}`,
        onSuccess() {
          getUserRefunds()
        },
      })
    }
  }

  async function emergencyWithdraw() {
    if (padContract) {
      await send({
        contract: padContract,
        method: 'emergencyWithdraw',
        params: [],
        pendingText: `Withdrawing ${padInfo.paymentToken.symbol}`,
        summary: `Withdrew ${padInfo.paymentToken.symbol}`,
        onSuccess() {
          getUserInfo()
        },
      })
    }
  }

  function addIssuedToken() {
    if (!canAdd) return
    addToMetaMask({
      address: padInfo?.issuedToken.id,
      symbol: padInfo?.issuedToken.symbol,
      decimals: padInfo?.issuedToken.decimals,
      logoURI: getTokenImage(padInfo?.issuedToken.id) || '',
    })
  }

  const [markdown, setMarkDown] = useState<any>()

  const { chainId } = useActiveWeb3React()
  async function getMarkDown() {
    if (!padInfo) return
    try {
      const url = `https://raw.githubusercontent.com/Netswap/launchpad-resources/${
        chainId === ChainId.MAINNET ? 'master' : 'testnet'
      }/v2/${padInfo.id.toLowerCase()}/about.md`

      const { data } = await axios.get<ProjectIntro>(url)
      setMarkDown(data)
    } catch (e) {
      console.error('getMarkDown fetch failed')
      console.error(e)
    }
  }

  useEffect(() => {
    getMarkDown()
  }, [chainId])

  async function getFundsDistribution() {
    try {
      if (padContract) {
        const res = await padContract.getFundsDistribution()

        if (res.issuerCharged) {
          setIssuerCharged(new BigNumber((res.issuerCharged as any).toString()))
        }
      }
    } catch (e) {
      console.error('getFundsDistribution')
      console.error(e)
    }
  }

  useEffect(() => {
    getFundsDistribution()
    const interval = setInterval(() => {
      getFundsDistribution()
    }, 5000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [padContract])
  const history = useHistory()

  const tokenSaled = useMemo(() => {
    if (!padInfo?.price) return new BigNumber(0)
    return issuerCharged.dividedBy(padInfo.price).shiftedBy(-6)
  }, [issuerCharged, padInfo])

  const feeRate = useMemo(() => {
    if (!padInfo) return '-'
    return getFeeRate(padInfo.paidAmountBeforeLaunch, padInfo.targetRaised)
  }, [padInfo])

  useEffect(() => {
    const container = document.getElementById('pageContainer')
    if (container) {
      container.scrollTop = 0
    }
  }, [])
  const isCancel = stopped && currentPhase === Phase.Launch
  return (
    <DetailWrapper>
      <Back
        onClick={() => {
          history.push('/launchpad-v2')
        }}
      >
        <ArrowBackIosIcon />
        <span>Back</span>
      </Back>

      <div className="detailInfo">
        <div className="leftBorder"></div>
        <div className="rightBorder"></div>
        <div className="detailLeftCon">
          <h2>Unlimited Tier</h2>
          <div className="detailSymbolInfo">
            <h3>{projectInfo?.projectInfo.name || '--'}</h3>
            <p>{projectInfo?.projectInfo.description || '--'}</p>
            <MediaWrapper>
              <SmallShareCon projectInfo={projectInfo} />
              <AuthButton auth className="symbolBtn" onClick={addIssuedToken}>
                Add {padInfo?.issuedToken.symbol} to wallet
              </AuthButton>
            </MediaWrapper>

            {/* <p className="whitelistTip">Whitelist</p> */}
          </div>
        </div>
        <div className="detailRightCon">
          <div className="rightTopCon">
            <TokenImage address={padInfo?.issuedToken.id} />
            <div className="projectInfo">
              <h2>{padInfo?.issuedToken.symbol}</h2>
              <p>{padInfo?.issuedToken.name}</p>
            </div>
            <div className="iconInfo">
              <div className="item">
                <PeopleIcon />
                <p>{padInfo?.participatedUserCount}</p>
              </div>
              <div className="item">
                Price <p>${padInfo ? padInfo.price : '--'}</p>
              </div>
            </div>
            <RowBetween className="durationCon">
              {currentPhase < Phase.Deposit ? (
                <div className="durationInfo">
                  <h3>Start at</h3>
                  <p>{depositStartTime}</p>
                </div>
              ) : currentPhase < Phase.SaleEnded ? (
                <>
                  <div className={cx('durationInfo', 'mediaDurationInfo')}>
                    <h3>Ends at</h3>
                    <p>{depositEndTime}</p>
                  </div>
                  {/* <div className="durationInfo">
                    <h3 className="endTitle">End</h3>
                    <p>{depositEndTime}</p>
                  </div> */}
                </>
              ) : null}
              <button className={isCancel ? 'red' : 'prepare'}>{isCancel ? 'Cancelled' : steps[currentPhase]}</button>
            </RowBetween>
          </div>

          <div className="rightBottomCon">
            <h2>Total Raised</h2>
            <RowBetween className="saleInfo">
              <h3>
                $<Value value={padInfo ? padInfo.paidAmountBeforeLaunch : '--'} decimals={0} />
                &nbsp;/&nbsp;$
                <Value value={padInfo ? padInfo.targetRaised : '--'} decimals={0} />
              </h3>
              <p>Sale {percentage.toFixed(2)}%</p>
            </RowBetween>
            <LinearProgress variant="determinate" value={percentage >= 100 ? 100 : percentage} />
            <RowBetween
              className="amountInfo"
              style={{ justifyContent: currentPhase >= Phase.SaleEnded ? 'space-between' : 'flex-end' }}
            >
              {currentPhase >= Phase.SaleEnded && (
                <p>
                  Token Sold: <span>{tokenSaled.toNumber().toLocaleString('en-US', { maximumFractionDigits: 0 })}</span>
                </p>
              )}
              <p>
                Sales Amount:{' '}
                <span> {padInfo?.issuedTokenAmount.toLocaleString('en-US', { maximumFractionDigits: 0 })}</span>
              </p>
            </RowBetween>
          </div>
        </div>
      </div>
      <div className="depositInfo ">
        <div className="depositLeftCon">
          <h2>Your Deposit</h2>
          {currentPhase === Phase.Prepare || !account ? (
            <p>Deposit {padInfo?.paymentToken.symbol} to get allocation.</p>
          ) : (
            <>
              <p>
                {userStakePaymentTokenAmount.toFixed()} {padInfo?.paymentToken.symbol}
              </p>
              {currentPhase >= Phase.SaleEnded && (
                <>
                  {!stopped && (
                    <h5>
                      Your actual deposit: {userStakePaymentTokenAmount.minus(userRefunds).toFixed()} &nbsp;
                      {padInfo?.paymentToken.symbol}
                    </h5>
                  )}
                  <h5>
                    {userHasClaimedRefunds
                      ? `You have claimed ${userRefunds.toFixed()} ${padInfo?.paymentToken.symbol} refund`
                      : `Your refunds: ${stopped ? userStakePaymentTokenAmount.toFixed() : userRefunds.toFixed()} ${
                          padInfo?.paymentToken.symbol
                        }`}
                  </h5>
                  <p>
                    {(currentPhase === Phase.SaleEnded || currentPhase === Phase.Launch) &&
                      hasRefunds &&
                      !userHasClaimedRefunds && (
                        <DepositBtn auth onClick={refund} style={{ fontWeight: 600 }}>
                          Claim Refund
                        </DepositBtn>
                      )}
                    {stopped && userStakePaymentTokenAmount.isGreaterThan(0) && (
                      <DepositBtn auth onClick={emergencyWithdraw} style={{ fontWeight: 600 }}>
                        Claim Refund
                      </DepositBtn>
                    )}
                  </p>
                </>
              )}
            </>
          )}
          <NetswapLogo src={require('assets/images/launchpad/netswap.png')} />
        </div>
        <div className="depositRightCon">
          {currentPhase < Phase.SaleEnded && account ? (
            <>
              <h2>Your wNETT</h2>
              <p>
                ≈ {userwNettBalance.toFixed(3)}
                <span>wNETT</span>
              </p>
            </>
          ) : account && userAllocation.isGreaterThan(0) ? (
            <>
              <h2>Your allocation</h2>
              <p style={{ margin: '20px 0', lineHeight: '1.6' }}>
                ≈ {userAllocation.toFixed(3)}
                <span>&nbsp;{padInfo?.issuedToken.symbol}</span>
              </p>
              <Tips>
                Token launchs after <b>{launchTime}</b> according to vesting plan
              </Tips>
              {projectInfo?.saleInfo.vesting && (
                <Tips style={{ marginTop: '16px' }}>* {projectInfo?.saleInfo.vesting}</Tips>
              )}
              {id.toLowerCase() === '0x9520db998a3db6dfac2abffde5c619f2ba94ed92' && (
                <p style={{ margin: '20px 0', lineHeight: '1.6', textDecoration: 'underline', fontSize: '20px' }}>
                  <ExternalLink href="https://app.hummus.exchange/vesting">Claim here</ExternalLink>
                </p>
              )}
            </>
          ) : null}
          {currentPhase === Phase.Deposit && (
            <>
              <DepositBtn
                auth
                onClick={() => {
                  setIsOpenDepositModal(true)
                }}
              >
                Deposit
              </DepositBtn>

              <Tips style={{ marginTop: '8px' }}>
                * Burning 1 wNETT allows you to deposit {padInfo?.USDPerWNETT} {padInfo?.paymentToken.symbol}
              </Tips>
            </>
          )}
        </div>
      </div>
      <div className="stepsInfo">
        <Stepper activeStep={currentPhase} alternativeLabel connector={<LaunchpadConnector />}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={LaunchpadStepIcon}>
                <span>{stopped && index === Phase.Launch ? 'Cancelled' : label}</span>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <div className="infosLists">
        <StyledTabs value={projectInfoTab} onChange={handleInfoChange} aria-label="launchpad">
          <StyledTab label="Sale Info" {...a11yProps(0)} />
          <StyledTab label="Token Info" {...a11yProps(1)} />
          <StyledTab label="About the Project" {...a11yProps(2)} />
        </StyledTabs>

        {projectInfoTab === 0 && (
          <div className="infosCon">
            <RowBetween className="infosItem">
              <h3>Sales Amount</h3>
              <p>
                <Value value={padInfo?.issuedTokenAmount} decimals={0} />
              </p>
            </RowBetween>
            <RowBetween className="infosItem">
              <h3>Price</h3>
              <p>
                $ <Value value={padInfo?.price} />
              </p>
            </RowBetween>
            <RowBetween className="infosItem">
              <h3>Participation fee</h3>
              <p>{feeRate} %</p>
            </RowBetween>
            <RowBetween className="infosItem">
              <h3>Participants</h3>
              <p>{padInfo?.participatedUserCount}</p>
            </RowBetween>
            <RowBetween className="infosItem">
              <h3>Payment token</h3>
              <p>{padInfo?.paymentToken.symbol}</p>
            </RowBetween>
            <RowBetween className="infosItem">
              <h3>Deposit Start</h3>
              <p>{depositStartTime}</p>
            </RowBetween>
            <RowBetween className="infosItem">
              <h3>Deposit End</h3>
              <p>{depositEndTime}</p>
            </RowBetween>
            <RowBetween className="infosItem">
              <h3>Launch Time</h3>
              <p>{launchTime}</p>
            </RowBetween>
            {projectInfo?.saleInfo?.vesting && (
              <RowBetween className="infosItem">
                <h3>Vesting</h3>
                <p>{projectInfo?.saleInfo.vesting}</p>
              </RowBetween>
            )}
          </div>
        )}
        {projectInfoTab === 1 && (
          <div className="infosCon">
            <RowBetween className="infosItem">
              <h3>Name</h3>
              <p>{projectInfo?.tokenInfo.tokenName || '--'}</p>
            </RowBetween>
            <RowBetween className="infosItem">
              <h3>Symbol</h3>
              <p>{projectInfo?.tokenInfo.tokenSymbol || '--'}</p>
            </RowBetween>
            <RowBetween className="infosItem">
              <h3>Decimals</h3>
              <p>{projectInfo?.tokenInfo.tokenDecimals || '--'}</p>
            </RowBetween>
            <RowBetween className="infosItem">
              <h3>Total Supply</h3>
              <p>
                <Value value={projectInfo?.tokenInfo.totalSupply || 0} />
              </p>
            </RowBetween>
            {projectInfo?.tokenInfo?.marketCap && (
              <RowBetween className="infosItem">
                <h3>Market Cap</h3>
                <p>
                  <Value value={projectInfo?.tokenInfo.marketCap || 0} />
                </p>
              </RowBetween>
            )}
            {projectInfo?.tokenInfo?.maxSupply && (
              <RowBetween className="infosItem">
                <h3>Max Supply</h3>
                <p>
                  <Value value={projectInfo?.tokenInfo.maxSupply || 0} />
                </p>
              </RowBetween>
            )}
            {projectInfo?.tokenInfo?.fdv && (
              <RowBetween className="infosItem">
                <h3>FDV</h3>
                <p>
                  <Value value={projectInfo?.tokenInfo.fdv || 0} />
                </p>
              </RowBetween>
            )}
            {projectInfo?.tokenInfo?.tokenAddress && (
              <RowBetween className="infosItem">
                <h3>Token Address</h3>
                <ExternalLink
                  href={`https://andromeda-explorer.metis.io/token/${projectInfo.tokenInfo.tokenAddress}`}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <OpenInNewIcon style={{ marginRight: '8px' }} />
                  {projectInfo.tokenInfo.tokenAddress}
                </ExternalLink>
              </RowBetween>
            )}
          </div>
        )}
        {projectInfoTab === 2 && (
          <div className="infosCon">
            {projectInfo?.about?.summary && (
              <RowBetween className="infosItem">
                <h3>Summary</h3>
                <p>{projectInfo?.about?.summary || '--'}</p>
              </RowBetween>
            )}
            {projectInfo?.about?.whitepaper && (
              <RowBetween className="infosItem">
                <h3>Whitelist Event</h3>
                <ExternalLink href={projectInfo?.about?.whitepaper}>{projectInfo?.about?.whitepaper}</ExternalLink>
              </RowBetween>
            )}
            {projectInfo?.about?.tokenomics && (
              <RowBetween className="infosItem">
                <h3>Tokenomics</h3>
                <ExternalLink href={projectInfo?.about?.tokenomics}>{projectInfo?.about?.tokenomics}</ExternalLink>
              </RowBetween>
            )}
            {projectInfo?.about?.roadmap && (
              <RowBetween className="infosItem">
                <h3>Roadmap</h3>
                <ExternalLink href={projectInfo?.about?.roadmap}>{projectInfo?.about?.roadmap}</ExternalLink>
              </RowBetween>
            )}
            {projectInfo?.about?.team?.length && (
              <RowBetween className="infosItem">
                <h3>Team</h3>
                <div className="infosAvatars">
                  {projectInfo?.about?.team.map((item) => {
                    return (
                      item.avatar && (
                        <ExternalLink href={item.avatar} key={item.title}>
                          <img src={item.avatar} alt={item.name} />
                        </ExternalLink>
                      )
                    )
                  })}
                </div>
              </RowBetween>
            )}
            {projectInfo?.about?.investors?.length && (
              <RowBetween className="infosItem">
                <h3>Investors</h3>
                <div className="infosAvatars">
                  {projectInfo?.about?.investors.map((item) => {
                    return (
                      item.logo && (
                        <ExternalLink href={item.logo} key={item.name}>
                          <img src={item.logo} alt={item.name} />
                        </ExternalLink>
                      )
                    )
                  })}
                </div>
              </RowBetween>
            )}
            {projectInfo?.about?.video && (
              <RowBetween className="infosItem video">
                <h3>Video Introduction</h3>
                <iframe
                  width={!isMobile ? '560' : '100%'}
                  height={!isMobile ? '315' : '200'}
                  src={projectInfo?.about.video.link}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    marginTop: '16px',
                  }}
                ></iframe>
                {projectInfo?.about.video.introduction && (
                  <p style={{ marginTop: '16px' }}>{projectInfo?.about.video.introduction}</p>
                )}
              </RowBetween>
            )}
          </div>
        )}
        {projectInfoTab === 2 && markdown && (
          <div className="markdown">
            <ReactMarkdown children={markdown} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} />
          </div>
        )}
      </div>

      {!!padInfo && (
        <DepositModal
          isOpen={isOpenDepositModal}
          onClose={() => {
            setIsOpenDepositModal(false)
          }}
          padInfo={padInfo}
          wNettBalance={userwNettBalance}
          padContract={padContract}
          handleDepositSuccess={handleDepositSuccess}
          userStakePaymentTokenAmount={userStakePaymentTokenAmount}
          projectInfo={projectInfo}
        />
      )}
    </DetailWrapper>
  )
}
