/* eslint-disable react-hooks/exhaustive-deps */
import { ChainId } from '@netswap/sdk'
import { NETWORK_CHAIN_ID } from 'connectors'
import getUserFarmLPPools from 'gql/subgraph/user-farm-lp-pools'
import { useActiveWeb3React } from 'hooks'
import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import { setUserFarmPool } from '../index'

export default function useGetUserFarmLpPools() {
  const { chainId, account } = useActiveWeb3React()
  const dispatch = useAppDispatch()
  const { farm } = useAppSelector(state => state.netswapData)
  async function getPoolsData() {
    if (!account) return
    const res = await getUserFarmLPPools(account)
    const cid = chainId || NETWORK_CHAIN_ID
    if (res.length) {
      dispatch(
        setUserFarmPool({
          chainId: cid,
          account,
          data: res.map(item => item.farmPool.id),
          method: 'set'
        })
      )
    }
  }

  useEffect(() => {
    getPoolsData()
    // let interval = setInterval(() => {
    //   getPoolsData()
    // }, 60000)

    // return () => {
    //   if (interval) {
    //     clearInterval(interval)
    //   }
    // }
  }, [account])

  return useMemo(() => {
    if (account) {
      return farm.userLPPools[account] ? farm.userLPPools[account][(chainId || NETWORK_CHAIN_ID) as ChainId] : []
    }
    return []
  }, [farm.userLPPools, account, chainId])
}

export function useUpdateUserFarmLPPool() {
  const { chainId, account } = useActiveWeb3React()
  const dispatch = useAppDispatch()
  function update({ pids, method }: { pids: string[]; method: 'add' | 'remove' }) {
    if (!account) return
    const cid = chainId || NETWORK_CHAIN_ID
    dispatch(
      setUserFarmPool({
        chainId: cid,
        account: account,
        data: pids,
        method
      })
    )
  }

  return update
}
