import React from 'react'
import styled from 'styled-components'
import Pagination, { PaginationProps } from '@material-ui/lab/Pagination'
const StylePagination = styled(Pagination)<{
  props: PaginationProps
}>`
  &.MuiPagination-root {
    width: fit-content;
    .MuiPagination-ul {
      padding-inline-start: 0;
      li {
        button,
        .MuiPaginationItem-root {
          color: ${({ theme }) => theme.v2.t01};
          background: transparent;
        }

        .Mui-selected {
          color: ${({ theme }) => theme.v2.t05};
        }
      }
    }
  }
`

interface Props {
  pageSize?: number
}

export default function MaterialTablePagination(props: Props) {
  const { pageSize = 10 } = props

  function onChange(e: any, page: number) {
    ;(props as any).onChangePage(e, page - 1)
  }

  return (
    <StylePagination
      {...(props as any)}
      page={(props as any).page + 1}
      count={Math.floor((props as any).count > pageSize ? (props as any).count / pageSize + 1 : 1)}
      onChange={onChange}
      defaultPage={0}
    />
  )
}
