import { gql } from '@apollo/client'
import { TradeCompetition } from '../index'

export interface SwapVolRank {
  id: string
  userAddress: string
  swapVolume: number
  rank: number
}

export interface RoundInfo {
  id: string
  round: number
  startTime: number
  endTime: number
  lastUpdateTime: number
  nextUpdateTime: number
  updateTimeInterval: number
  status?: RoundStatus
}

export enum RoundStatus {
  COMMING_SOON = -1,
  PREPARE = 0,
  START = 1,
  END = 2,
  DISTRIBUTION = 3
}

export interface ReturnData {
  swapVolRanks: SwapVolRank[]
  roundInfo: RoundInfo
}

export interface UserInfo {
  id: string

  roundData: {
    round: number
    lastUpdateTime: number
    rank: number
    swapVolume: number
  }[]
  rewardRecords: {
    round: number
    rank: number
    tokens: {
      id: string
      symbol: string
      amount: string
    }[]
  }[]
}

function getRoundStatus(round: RoundInfo): RoundStatus {
  const timeNow = Math.floor(new Date().getTime() / 1000)

  if (timeNow < round.startTime) {
    return RoundStatus.PREPARE
  } else if (timeNow >= round.startTime && timeNow < round.endTime) {
    return RoundStatus.START
  } else if (timeNow >= round.endTime) {
    if (timeNow > round.endTime + 1 * 24 * 3600) {
      // TODO: server set distribution time
      return RoundStatus.DISTRIBUTION
    } else {
      return RoundStatus.END
    }
  }

  return RoundStatus.DISTRIBUTION
}

export async function getTradeCompetitionRoundRank(round: number): Promise<ReturnData | undefined> {
  // if (!name) return Promise.resolve([]);
  try {
    const queryData = await TradeCompetition().query<ReturnData>({
      query: gql`
        query swapVolRanks($round: Int!) {
          swapVolRanks(round: $round, orderDirection: asc) {
            id
            userAddress
            swapVolume
            rank
          }
          roundInfo(round: $round) {
            id
            round
            startTime
            endTime
            lastUpdateTime
            nextUpdateTime
            updateTimeInterval
          }
        }
      `,
      variables: {
        round
      },
      fetchPolicy: 'no-cache'
    })

    if (queryData.data) {
      const res = queryData.data
      if (res.roundInfo) {
        res.roundInfo.status = getRoundStatus(res.roundInfo)
      }
      return res
    } else {
      return undefined
    }
  } catch (err) {
    console.error(err)

    return undefined
  }
}

export async function getRounds(skip: number = 0, limit: number = 100): Promise<RoundInfo[]> {
  try {
    const queryData = await TradeCompetition().query<{ roundInfos: RoundInfo[] }>({
      query: gql`
        query rounds($skip: Int!, $limit: Int!) {
          roundInfos(skip: $skip, limit: $limit) {
            id
            round
            startTime
            endTime
            lastUpdateTime
            nextUpdateTime
            updateTimeInterval
          }
        }
      `,
      variables: {
        skip,
        limit
      }
    })

    return queryData.data.roundInfos.map(round => ({ ...round, status: getRoundStatus(round) }))
  } catch (err) {
    console.error(err)

    return []
  }
  // return [
  //   {
  //     id: 'info-1',
  //     lastUpdateTime: 1649257200,
  //     nextUpdateTime: 0,
  //     round: 1,
  //     startTime: 1646665200,
  //     endTime: 1649257200,
  //     updateTimeInterval: 0
  //   },
  //   {
  //     id: 'info-2',
  //     lastUpdateTime: 1649257200,
  //     nextUpdateTime: 0,
  //     round: 2,
  //     startTime: 1646665200,
  //     endTime: 1649257200,
  //     updateTimeInterval: 0
  //   },
  //   {
  //     id: 'info-3',
  //     lastUpdateTime: 1649257200,
  //     nextUpdateTime: 0,
  //     round: 3,
  //     startTime: 1646665200,
  //     endTime: 1849257200,
  //     updateTimeInterval: 0
  //   }
  // ].map(round => ({ ...round, status: getRoundStatus(round) }))
}

export async function getUserInfo(address: string): Promise<UserInfo | undefined> {
  // if (!name) return Promise.resolve([]);
  try {
    const queryRes = await TradeCompetition().query<{
      userInfo: UserInfo
    }>({
      query: gql`
        query userInfo($address: String!) {
          userInfo(id: $address) {
            id
            roundData {
              round
              lastUpdateTime
              rank
              swapVolume
            }
            rewardRecords {
              round
              rank
              tokens {
                id
                symbol
                amount
              }
            }
          }
        }
      `,
      variables: {
        address: address.toLowerCase()
      },
      fetchPolicy: 'no-cache'
    })

    const { data } = queryRes
    if (data.userInfo) {
      return data.userInfo
    }

    return undefined
  } catch (err) {
    console.error(err)

    return undefined
  }
}
