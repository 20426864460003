import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDarkModeManager } from 'state/user/hooks'

import Container from '@material-ui/core/Container'
import DarkBgSrc from 'assets/images/landing_page/trading_intro_bg.dark.png'
import LightBgSrc from 'assets/images/landing_page/trading_intro_bg.light.png'
import DarkBgMobileSrc from 'assets/images/landing_page/trading_intro_bg.mobile.dark.png'
import LightBgMobileSrc from 'assets/images/landing_page/trading_intro_bg.mobile.light.png'
import RunningNeboDarkSrc from 'assets/images/landing_page/running_nebo.dark.png'
import RunningNeboLightSrc from 'assets/images/landing_page/running_nebo.light.png'

import { ButtonV2 } from 'components/Button'
import { TYPE } from 'theme'

const Wrapper = styled.div`
  width: 100%;
  background-image: url(${({ theme }) => (theme.isDark ? DarkBgSrc : LightBgSrc)});
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 145px 20% 93px 13%;
  display: flex;
  flex-direction: column;
  position: relative;
  /* height: 718px; */

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 68px 0 40px;
    background-image: url(${({ theme }) => (theme.isDark ? DarkBgMobileSrc : LightBgMobileSrc)});
    margin-bottom: 100px;
  `}
`

const NeboImg = styled.img`
  position: absolute;
  bottom: 50px;
  right: 12%;
  height: 70%;
  width: auto;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: auto;
    width: 340px;
    right: -40px;
    bottom: -90px;
  `}
`

const TradeBtn = styled(ButtonV2)`
  width: 232px;
  height: 48px;
  box-shadow: 0px 4px 0px 0px #171717;
  border-radius: 16px;
  font-size: 16px;
  margin-top: 20px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
    height: 40px;
    width: 118px;
  `}
`

const Title = styled(TYPE.title)`
  font-size: 42px;
  line-height: 52px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 20px;
    line-height: 30px;
  `}
`

const Subtitle = styled(TYPE.title)`
  font-size: 32px;
  line-height: 42px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
    line-height: 22px;
  `}
`

const Description = styled(TYPE.main)`
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 20px !important;
  max-width: 540px;
  white-space: wrap;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
    line-height: 18px;
    marin-bottom: 16px;
  `}
`

const PartTitle = styled.div`
  display: flex;
  align-items: center;

  .iconfont {
    font-size: 22px;
    margin-right: 9px;
    color: ${({ theme }) => (theme.isDark ? '#ffffff' : '#5f7aff')};
  }

  .part-title {
    font-size: 18px;
    line-height: 30px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    .iconfont {
      font-size: 22px;
      margin-right: 6px;
    }

    .part-title {
      font-size: 16px;
      line-height: 20px;
    }
  `}
`

const PartContent = styled(TYPE.main)`
  padding-left: 32px;
  width: 296px;
  white-space: wrap;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 20px !important;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 18px !important;
  `}
`

export default function TradingIntroduction() {
  const { t } = useTranslation()
  const history = useHistory()
  const [darkMode] = useDarkModeManager()

  return (
    <Container maxWidth="lg">
      <Wrapper>
        <NeboImg src={darkMode ? RunningNeboDarkSrc : RunningNeboLightSrc} alt="" />

        <Title>{t('home.trading_intro.title')}</Title>

        <Subtitle>{t('home.trading_intro.subtitle')}</Subtitle>

        <Description>{t('home.trading_intro.description')}</Description>

        <PartTitle>
          <i className="iconfont icon-Swapfaster" />
          <TYPE.title>{t('home.trading_intro.part1_title')}</TYPE.title>
        </PartTitle>

        <PartContent className="part-title">{t('home.trading_intro.part1_content')}</PartContent>

        <PartTitle>
          <i className="iconfont icon-LessGas" />

          <TYPE.title className="part-title">{t('home.trading_intro.part2_title')}</TYPE.title>
        </PartTitle>

        <PartContent>{t('home.trading_intro.part2_content')}</PartContent>

        <TradeBtn onClick={() => history.push('/swap')}>{t('home.trade_now')}</TradeBtn>
      </Wrapper>
    </Container>
  )
}
