import React, { useCallback, useMemo, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Row from 'components/Row'
import { useHistory, useLocation } from 'react-router-dom'

import { SwapPair } from './Config'
import { useDarkModeManager } from 'state/user/hooks'
import { TYPE, theme } from 'theme'
import CrownImgSrc from 'assets/images/trade_competition/crown.png'
import GiftImgSrc from 'assets/images/trade_competition/award.png'
import ToolsLightImgSrc from 'assets/images/trade_competition/tools.light.png'
import ToolsDarkImgSrc from 'assets/images/trade_competition/tools.dark.png'
import TokenImage from 'components/TokenImage'
import { useMedia } from 'react-use'

const Wrapper = styled.div`
  width: 100%;
  max-width: 904px;
  margin: 30px 0 auto;
  background: linear-gradient(121deg, rgba(118, 170, 255, 0.17) 0%, rgba(82, 79, 148, 0.1) 100%);
  border-radius: 10px;
  padding: 94px 38px 60px 38px;
  position: relative;

  .right-arrow {
    background: linear-gradient(234deg, #00cfff 0%, #34c1ff 53%, #904bf9 100%);
    color: transparent;
    -webkit-background-clip: text;
    font-size: 28px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 24px 16px 100px;

    .right-arrow {
      transform: rotate(90deg);
      margin: 12px 0;
    }
  `}
`

const Column = styled(Row)`
  flex-direction: column;
`

const StepBox = styled(Column)`
  width: 370px;
  height: 82px;
  background: ${({ theme }) =>
    theme.isDark
      ? 'linear-gradient(180deg, #6b46f1 0%, #290f85 100%)'
      : 'linear-gradient(180deg, #8B6CF9 0%, #41279F 100%)'};
  border-radius: 9px;
  position: relative;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px;

  .icon-logo {
    width: 80px;
    height: 80px;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `}
`

const TradePairBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 55px;
  background: ${({ theme }) => (theme.isDark ? '#2d2d4e' : '#ffffff')};
  border-radius: 13px;
  margin-top: 12px;
  padding: 0 20px;

  .pair-logo-one,
  .pair-logo-two {
    height: 32px;
    width: 32px;

    & > img {
      height: 32px;
      width: 32px;
    }
  }

  .pair-logo-one {
    z-index: 2;
    margin-left: 6px;
  }

  .pair-logo-two {
    z-index: 1;
    transform: translateX(-6px);
    margin-right: 6px;
  }
`

const TradeBtn = styled.button`
  width: 120px;
  height: 36px;
  font-size: 14px;
  background: #00cfff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-family: 'Poppins-Medium';
  font-weight: 500;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

const ToolsImg = styled.img`
  position: absolute;
  bottom: 36px;
  left: -47px;
  width: 317px;
  height: auto;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 200px;
    bottom: -50px;
    left: -20px;
  `}
`

const StepContainer = styled(Row)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `}
`

export default function PairList() {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const ref = useRef<HTMLDivElement | null>(null)
  const isMobile = useMedia('(max-width: 768px)')
  const sizeRate = isMobile ? 0.8 : 1

  const [darkMode] = useDarkModeManager()
  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  const ToolsImgSrc = useMemo(() => {
    return themeObject.isDark ? ToolsDarkImgSrc : ToolsLightImgSrc
  }, [themeObject])

  const goSwapPair = useCallback(
    (token0Addr: string, token1Addr: string) => {
      history.push(`/swap?inputCurrency=${token0Addr}&outputCurrency=${token1Addr}`)
    },
    [history]
  )

  useEffect(() => {
    const scrollDom = document.getElementById('pageContainer')
    const hashs = location.hash.split('#')

    if (scrollDom && ref?.current && hashs[hashs.length - 1] === 'trade-join-helper') {
      const height = ref.current.offsetTop
      scrollDom.scrollTo({
        top: height - 80,
      })
    } else {
      scrollDom?.scrollTo({
        top: 0,
      })
    }

    return () => {}
  }, [ref, location])

  return (
    <Wrapper id="trade-join-helper" ref={ref}>
      <StepContainer style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <StepBox>
          <TYPE.title fontSize={16 * sizeRate} lineHeight="1.5" color="#ffffff">
            {t('trade_competition.trade_to_increase_rank')}
          </TYPE.title>
          <TYPE.mainLg fontSize={12 * sizeRate} lineHeight="20px" color="#ffffff">
            {t('trade_competition.see_rule_below')}
          </TYPE.mainLg>
          <img className="icon-logo" src={CrownImgSrc} alt="" />
        </StepBox>

        <i className="iconfont icon-point-arrow-right right-arrow" />

        <StepBox>
          <TYPE.title fontSize={16 * sizeRate} lineHeight="24px" color="#ffffff">
            {t('trade_competition.prize_claim')}
          </TYPE.title>
          <TYPE.mainLg fontSize={12 * sizeRate} lineHeight="20px" color="#ffffff">
            {t('trade_competition.connect_prizes_to_wallet')}
          </TYPE.mainLg>
          <img className="icon-logo" src={GiftImgSrc} alt="" />
        </StepBox>
      </StepContainer>

      <Row style={{ marginTop: 55 * sizeRate, alignItems: 'flex-start', flexDirection: isMobile ? 'column' : 'row' }}>
        <Column style={{ flex: 1, alignItems: 'flex-start' }}>
          <TYPE.title fontSize={26 * sizeRate} textAlign={isMobile ? 'center' : 'left'}>
            {t('trade_competition.competition_rules')}
          </TYPE.title>

          <TYPE.v2Main fontSize={18 * sizeRate} lineHeight="30px" marginTop={20 * sizeRate}>
            During the competition, all users who trade on the designated trading pair will be-ranked according to total
            effective trading volume completed by the user, and the top 100 users will have the opportunity to to share
            about 100,000 USD worth of Metis, NETT, Relay and MINES tokens.
          </TYPE.v2Main>
        </Column>

        <Column style={{ flex: 1, marginLeft: isMobile ? 0 : 46 }}>
          <TYPE.title textAlign="center" fontSize={24 * sizeRate} lineHeight="1.25" marginBottom={12}>
            {t('trade_competition.trade_pair_list')}
          </TYPE.title>

          {SwapPair.map((pair) => {
            return (
              <TradePairBox key={`${pair.token1.symbol}-${pair.token2.symbol}`}>
                <TokenImage className="pair-logo-one" address={pair.token1.address} alt={pair.token2.symbol} />
                <TokenImage className="pair-logo-two" address={pair.token2.address} alt={pair.token2.symbol} />

                <TYPE.v2Main
                  flex={1}
                  fontSize={16 * sizeRate}
                  lineHeight="1.25"
                >{`${pair.token1.symbol}/${pair.token2.symbol}`}</TYPE.v2Main>

                <TradeBtn onClick={() => goSwapPair(pair.token1.address, pair.token2.address)}>
                  {t('trade_competition.trade_now')}
                </TradeBtn>
              </TradePairBox>
            )
          })}
        </Column>
      </Row>

      <ToolsImg src={ToolsImgSrc} alt="" />
    </Wrapper>
  )
}
