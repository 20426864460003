import React, { useMemo } from 'react'
import styled from 'styled-components'
import Panel from '../Panel'
import { AutoColumn } from 'components/Column'
import { RowFixed } from 'components/Row'
import { TYPE } from 'theme'
import { usePairData } from 'hooks/infos/PairData'
import { formattedNum } from 'utils/infos'

const PriceCard = styled(Panel)`
  position: absolute;
  right: -220px;
  width: 220px;
  top: -20px;
  z-index: 9999;
  height: fit-content;
  background-color: ${({ theme }) => theme.bg1};
`

function formatPercent(rawPercent: number) {
  if (rawPercent < 0.01) {
    return '<1%'
  } else return (rawPercent * 100).toFixed(0) + '%'
}

export default function NetPrice() {
  const usdtPair = usePairData('0x3d60afecf67e6ba950b499137a72478b2ca7c5a1')
  const usdcPair = usePairData('0x5ae3ee7fbb3cb28c17e7adc3a6ae605ae2465091')

  const totalLiquidity = useMemo(() => {
    return usdtPair && usdcPair ? usdtPair.trackedReserveUSD + usdcPair.trackedReserveUSD : 0
  }, [usdtPair, usdcPair])

  const usdtPerMetis = usdtPair ? parseFloat(usdtPair.token0Price).toFixed(2) : '-'
  const usdcPerMetis = usdcPair ? parseFloat(usdcPair.token1Price).toFixed(2) : '-'

  return (
    <PriceCard>
      <AutoColumn gap="10px">
        <RowFixed>
          <TYPE.v2Main>USDT/METIS: {formattedNum(parseFloat(usdtPerMetis), true)}</TYPE.v2Main>
          <TYPE.v2Main style={{ marginLeft: '10px' }}>
            {usdtPair && totalLiquidity ? formatPercent(usdtPair.trackedReserveUSD / totalLiquidity) : '-'}
          </TYPE.v2Main>
        </RowFixed>
        <RowFixed>
          <TYPE.v2Main>USDC/METIS: {formattedNum(parseFloat(usdcPerMetis), true)}</TYPE.v2Main>
          <TYPE.v2Main style={{ marginLeft: '10px' }}>
            {usdcPair && totalLiquidity ? formatPercent(usdcPair.trackedReserveUSD / totalLiquidity) : '-'}
          </TYPE.v2Main>
        </RowFixed>
      </AutoColumn>
    </PriceCard>
  )
}
