import { Currency, Pair } from '@netswap/sdk'
import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween } from '../Row'
import { TYPE } from '../../theme'
import { Input as NumericalInput } from '../NumericalInput'
import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'

import { useActiveWeb3React, useGetSymbol } from '../../hooks'
import { useTranslation } from 'react-i18next'
import useTheme from '../../hooks/useTheme'
import { isMobile } from 'react-device-detect'

const InputRow = styled.div<{ selected: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  background: ${(props) => props.theme.v2.bg04};
  border-radius: 12px;
  margin-top: 8px;
  padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.75rem 0.75rem 0.75rem 1rem')};
`

const CurrencySelect = styled.button<{ selected: boolean }>`
  align-items: center;
  height: fit-content;
  font-size: 20px;
  font-weight: 500;
  background-color: transparent;
  color: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
  /* border-radius: 4px; */
  /* box-shadow: ${({ selected }) => (selected ? 'none' : '0px 6px 10px rgba(0, 0, 0, 0.075)')}; */
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  padding: 0.5rem;
  border-left: 1px solid rgba(0, 0, 0, 0.18);
  /* :focus,
  :hover {
    background-color: ${({ selected, theme }) => (selected ? theme.bg2 : darken(0.05, '#C42A61'))};
  } */
`

const LabelRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.text1};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0.5rem;
  height: 35%;

  path {
    stroke: ${({ theme }) => theme.v2.t01};
    strokewidth: 1.5px;
  }
`

const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '8px')};
  /* background-color: #170627; */
  z-index: 1;
`

const Container = styled.div<{ hideInput: boolean }>`
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '8px')};
  /* border: 1px solid #170627; */
  /* background-color: ${({ theme }) => theme.bg1}; */
`

const StyledTokenName = styled.span<{ active?: boolean }>`
  ${({ active }) => (active ? '  margin: 0 0.25rem 0 0.75rem;' : '  margin: 0 0.25rem 0 0.25rem;')}
  font-size:  ${({ active }) => (active ? '20px' : '16px')};
  color: ${({ theme }) => theme.v2.t01};

  ${({ theme, active }) => theme.mediaWidth.upToSmall`
    font-size:  ${active ? '12px' : '10px'};
    margin: 0 8px;
  `};
`

const StyledBalanceButton = styled.button`
  height: 32px;
  background-color: ${({ theme }) => theme.v2.bg05};
  border: none;
  /* border: 1px solid ${({ theme }) => theme.text6}; */
  border-radius: 12px;
  font-size: 14px;
  margin-right: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.v2.t01};
  :hover {
    color: ${({ theme }) => darken(0.1, theme.v2.t01)};
    background-color: ${({ theme }) => darken(0.4, theme.v2.bg05)};
  }
  :focus {
    color: ${({ theme }) => darken(0.1, theme.v2.t01)};
    background-color: ${({ theme }) => darken(0.4, theme.v2.bg05)};
    outline: none;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-right: 0.5rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
    margin: 0 4px;
  `};
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  customBalanceText?: string
  inputUSD?: string
  showOnHalf?: boolean
  onHalf?: () => void
}

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label = 'Input',
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases,
  customBalanceText,
  inputUSD,
  showOnHalf,
  onHalf,
}: CurrencyInputPanelProps) {
  const { t } = useTranslation()

  const [modalOpen, setModalOpen] = useState(false)
  const context = useActiveWeb3React()
  const { account, chainId } = context
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const theme = useTheme()

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])
  const { symbol } = useGetSymbol(chainId, currency)

  const { symbol: symbol0 } = useGetSymbol(chainId, pair?.token0)
  const { symbol: symbol1 } = useGetSymbol(chainId, pair?.token1)

  return (
    <InputPanel id={id}>
      <Container hideInput={hideInput}>
        {!hideInput && (
          <LabelRow>
            <RowBetween>
              <TYPE.body color={theme.text2} fontWeight={500} fontSize={14}>
                {label}
              </TYPE.body>
              {account && (
                <TYPE.body
                  onClick={onMax}
                  color={theme.v2.t02}
                  fontWeight={500}
                  fontSize={14}
                  style={{ display: 'inline', cursor: 'pointer' }}
                >
                  {!hideBalance && !!currency && selectedCurrencyBalance
                    ? (customBalanceText ?? 'Balance: ') + selectedCurrencyBalance?.toSignificant(6)
                    : ' -'}
                  {/* {account && currency && showMaxButton && label !== 'To' && (
                    <StyledBalanceButton onClick={onMax}>MAX</StyledBalanceButton>
                  )} */}
                </TYPE.body>
              )}
            </RowBetween>
          </LabelRow>
        )}
        <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}} selected={disableCurrencySelect}>
          {!hideInput && (
            <>
              <NumericalInput
                className="token-amount-input"
                value={value}
                onUserInput={(val) => {
                  onUserInput(val)
                }}
              />
              {account && currency && showOnHalf && label !== 'To' && (
                <StyledBalanceButton onClick={onHalf}>50%</StyledBalanceButton>
              )}
              {account && currency && showMaxButton && label !== 'To' && (
                <StyledBalanceButton onClick={onMax}>MAX</StyledBalanceButton>
              )}
            </>
          )}
          <CurrencySelect
            selected={!!currency}
            className="open-currency-select-button"
            onClick={() => {
              if (!disableCurrencySelect) {
                setModalOpen(true)
              }
            }}
          >
            <Aligner>
              {pair ? (
                <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={24} margin={true} />
              ) : currency ? (
                <CurrencyLogo currency={currency} size={isMobile ? '20px' : '24px'} />
              ) : null}
              {pair ? (
                <StyledTokenName className="pair-name-container">
                  {symbol0}:{symbol1}
                </StyledTokenName>
              ) : (
                <StyledTokenName className="token-symbol-container" active={Boolean(currency && currency.symbol)}>
                  {(symbol && symbol.length > 20
                    ? symbol.slice(0, 4) + '...' + symbol.slice(symbol.length - 5, symbol.length)
                    : symbol) || t('common.selectToken')}
                </StyledTokenName>
              )}
              {!disableCurrencySelect && <StyledDropDown selected={!!currency} />}
            </Aligner>
          </CurrencySelect>
        </InputRow>
        {inputUSD && (
          <RowBetween marginTop="4px">
            <TYPE.body
              color={theme.v2.t02}
              fontWeight={500}
              fontSize={14}
              style={{ display: 'inline', cursor: 'pointer', padding: '0 0 8px 16px' }}
            >
              {inputUSD}
            </TYPE.body>
          </RowBetween>
        )}
      </Container>
      {!disableCurrencySelect && onCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
        />
      )}
    </InputPanel>
  )
}
