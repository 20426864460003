import { useActiveWeb3React } from 'hooks'
import { useMemo } from 'react'
import { Config } from '../constants/tokens/nett'

export interface TokenParams {
  address: string
  symbol: string
  decimals: number
  logoURI: string
}

export function useAddTokenToMetakMask(): [boolean, (tokenInfo: TokenParams) => void] {
  const { account, library } = useActiveWeb3React()

  const canAdd = useMemo(() => {
    return !!(account && library?.provider.isMetaMask)
  }, [account, library])

  function addToMetaMask(tokenInfo: TokenParams) {
    if (!canAdd) {
      console.error('can not add token because library is not ready')
      return
    }

    if (!tokenInfo.address) {
      console.error('can not add token because token is not empty', tokenInfo)
      return
    }
    const params: any = {
      type: 'ERC20',
      options: {
        address: tokenInfo.address,
        symbol: tokenInfo.symbol,
        decimals: tokenInfo.decimals,
        image: tokenInfo.logoURI
      }
    }

    if (library && library.provider.isMetaMask && library.provider.request) {
      library.provider
        .request({
          method: 'wallet_watchAsset',
          params
        })
        .then(success => {
          if (success) {
            console.log(`Successfully added ${tokenInfo.symbol} to MetaMask`)
          } else {
            throw new Error('Something went wrong.')
          }
        })
        .catch(console.error)
    }
  }

  return [canAdd, addToMetaMask]
}

export function useAddNettToken() {
  const [canAdd, addToken] = useAddTokenToMetakMask()
  const { chainId } = useActiveWeb3React()
  const TokenConfig = useMemo(() => {
    if (!chainId) return
    return Config[chainId]
  }, [chainId])

  function add() {
    if (canAdd && TokenConfig)
      addToken({
        address: TokenConfig.address,
        decimals: 18,
        symbol: 'NETT',
        logoURI: `${window.location.origin}/logo.svg`
      })
  }
  return add
}
