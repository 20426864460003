// import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { TimelineConfig } from './Config'
import LightsSrc from 'assets/images/trade_competition/lights.png'

const ScollContainer = styled.div`
  width: 900px;
  box-sizing: border-box;
  padding: 60px 20px 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
      width: 100vw;
      overflow-x: scroll;
      padding: 20px 40px;
      height: fit-content;
  `}
  &::-webkit-scrollbar {
    width: 0;
  }
`

const Wrapper = styled.div`
  width: 100%;

  position: relative;
  height: 150px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
      width: 600px;
      transform: translateX(40px);
  `}
`

const Mainline = styled.div`
  position: absolute;
  height: 12px;
  background: rgba(255, 255, 255, 0.19);
  border-radius: 6px;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  overflow: hidden;
`

const Progress = styled.div`
  height: 100%;
  background: linear-gradient(234deg, #00cfff 0%, #34c1ff 53%, #904bf9 100%);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
`

const PointIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  z-index: 3;
`

const PointLights = styled.img`
  height: 258px;
  width: 258px;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  display: none;

  &.show {
    display: block;
  }
`

const PointInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const PointTitle = styled.div`
  bottom: -88px;
  left: 0;
  transform: translateX(-50%);
  width: 104px;
  height: 26px;
  border-radius: 13px;
  background: #3a4363;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  position: absolute;
  font-size: 14px;
  font-family: 'Poppins-Medium';
`

const Point = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  height: 10px;
  width: 10px;
  z-index: 1;

  &.active {
    ${PointTitle} {
      color: #020423;
      background: #00cfff;
    }
  }
`

// const PointTime = styled.div`
//   position: absolute;
//   bottom: -90px;
//   width: 100px;
//   height: 64px;
//   background: linear-gradient(180deg, #382246 0%, rgba(56, 34, 70, 0) 100%);
//   border-radius: 2px;
//   left: 50%;

//   transform: translateX(-50%);
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   flex-direction: column;
//   box-sizing: border-box;
//   padding: 10px;
// `

// const TradingLeftTime = styled.div`
//   position: absolute;
//   bottom: -30px;
//   font-size: 14px;
//   width: 130px;
//   text-align: center;
//   left: 50%;
//   transform: translateX(-50%);
// `

interface Props {
  nextPointIndex: number
  onChainTime: number
}

export default function Timeline({ nextPointIndex, onChainTime }: Props) {
  const { t } = useTranslation()
  const [activeIndex, setActiveIndex] = useState<number>(0)

  const progress = useMemo(() => {
    if (nextPointIndex > 1) {
      let preLen = TimelineConfig[nextPointIndex - 1].left
      let currentTotalLen = TimelineConfig[nextPointIndex].left - TimelineConfig[nextPointIndex - 1].left
      let periodTotalTime = TimelineConfig[nextPointIndex].time - TimelineConfig[nextPointIndex - 1].time
      let overLastPointTime = onChainTime - TimelineConfig[nextPointIndex - 1].time
      const res: number = preLen + (overLastPointTime / periodTotalTime) * currentTotalLen
      return Math.max(res, 30)
    }

    return 10
  }, [onChainTime, nextPointIndex])

  // const tradingLeftTime = useMemo(() => {
  //   if (nextPointIndex === 2) {
  //     return Math.max(moment(TimelineConfig[nextPointIndex].time).diff(onChainTime, 'days'), 1)
  //   }

  //   return 0
  // }, [onChainTime, nextPointIndex])

  useEffect(() => {
    if (nextPointIndex > 1 && isMobile) {
      const dom = document.getElementById('point-3-time')
      if (dom) {
        dom.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
      }
    }
  }, [nextPointIndex])

  useEffect(() => {
    let index = 0

    for (let itemIndex = 0; itemIndex < TimelineConfig.length; itemIndex++) {
      const item = TimelineConfig[itemIndex]
      if (item.time >= 0 && onChainTime > item.time) {
        index = itemIndex
      }
    }

    setActiveIndex(index)
  }, [onChainTime])

  return (
    <ScollContainer>
      <Wrapper>
        <Mainline>
          <Progress style={{ width: `${progress}%` }} />
        </Mainline>
        {TimelineConfig.map((item, index) => {
          const isActive = item.time >= 0 && onChainTime > item.time
          return (
            <Point
              key={index}
              style={{ left: `${item.left}%` }}
              className={isActive ? 'active' : ''}
              id={`point-${index}`}
            >
              <PointIcon
                style={{ width: item.iconSize[0], height: item.iconSize[1] }}
                src={isActive ? item.activeIconSrc : item.iconSrc}
                alt=""
              />
              <PointLights className={activeIndex === index ? 'show' : ''} src={LightsSrc} alt="" />
              <PointInner>
                <PointTitle>{t(item.title)}</PointTitle>
                {/* {!!item.time && item.time > 0 && (
                  <PointTime id={`point-${index}-time`}>
                    <TYPE.body fontSize={14} fontWeight={600}>
                      {moment.utc(item.time).format(' MMM.Do')}
                    </TYPE.body>
                    <TYPE.body fontSize={14}>{moment.utc(item.time).format('HH:mm(UTC)')}</TYPE.body>
                  </PointTime>
                )} */}
              </PointInner>
            </Point>
          )
        })}

        {/* {!!tradingLeftTime && (
          <Point style={{ left: `50%`, background: 'transparent' }}>
            <PointInner>
              <PointTitle>Trading</PointTitle>
              <TradingLeftTime>
                Remaining {tradingLeftTime} {`Day${tradingLeftTime > 1 ? 's' : ''}`}
              </TradingLeftTime>
            </PointInner>
          </Point>
        )} */}
      </Wrapper>
    </ScollContainer>
  )
}
