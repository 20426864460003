import React, { useState, useEffect, useRef, useMemo } from 'react'
import { createChart } from 'lightweight-charts'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { formattedNum } from 'utils/infos'
import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components'
import { usePrevious } from 'react-use'
import { Play } from 'react-feather'
import { IconWrapper } from '..'
import { NetswapDayData } from 'gql/subgraph/analytics'
import { TYPE, theme } from 'theme'
import Row from 'components/Row'

dayjs.extend(utc)

export const CHART_TYPES = {
  BAR: 'BAR',
  AREA: 'AREA'
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

// constant height for charts
const HEIGHT = 300

const PercentText = styled(TYPE.v2Main)<{ percent: number }>`
  color: ${({ percent, theme }) => (percent > 0 ? theme.v2.t08 : theme.v2.t07)};
`

const TradingViewChart = ({
  type = CHART_TYPES.BAR,
  data,
  base,
  baseChange,
  field,
  title,
  width,
  useWeekly = false
}: {
  type: string
  data: NetswapDayData[]
  base: number
  baseChange: number
  field: string
  title: string
  width: number
  useWeekly?: boolean
}) => {
  // const { t, i18n } = useTranslation()
  const { t } = useTranslation()
  const [darkMode] = useDarkModeManager()
  const themeObject = useMemo(() => theme(darkMode), [darkMode])
  // reference for DOM element to create with chart
  const ref = useRef<any>()

  // pointer to the chart object
  const [chartCreated, setChartCreated] = useState<any>(null)
  const dataPrev = usePrevious(data)
  const [price, setPrice] = useState<number>(0)
  const [dateStr, setDateStr] = useState<string>('')
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const previousTheme = usePrevious(darkMode)
  // const prevLng = usePrevious('en')

  useEffect(() => {
    if (data !== dataPrev && chartCreated && type === CHART_TYPES.BAR) {
      chartCreated.remove()
      setChartCreated(null)
    }
  }, [chartCreated, data, dataPrev, type])

  // parese the data and format for tardingview consumption
  const formattedData = data?.map(entry => {
    return {
      time: dayjs
        .unix(parseInt(entry.date))
        .utc()
        .format('YYYY-MM-DD'),
      value: parseFloat((entry as any)[field])
    }
  })

  // adjust the scale based on the type of chart
  const topScale = type === CHART_TYPES.AREA ? 0.32 : 0.2

  // const previousTheme = usePrevious(darkMode)
  const hrStr = t('analytics.24hr')

  // reset the chart if them switches
  useEffect(() => {
    if (chartCreated && previousTheme !== darkMode) {
      // remove the tooltip element
      chartCreated.resize(0, 0)
      setChartCreated(null)
    }
  }, [chartCreated, darkMode, previousTheme, type])

  // if no chart created yet, create one with options and add to DOM manually
  useEffect(() => {
    if (formattedData.length === 0 || chartCreated) {
      return
    }

    if (type === CHART_TYPES.BAR) {
      console.log({
        chartCreated,
        setChartCreated,
        formattedData,
        topScale,
        type,
        useWeekly,
        width,
        themeObject
      })
    }

    const chart = createChart(ref.current, {
      width: width,
      height: HEIGHT,
      layout: {
        backgroundColor: 'transparent',
        textColor: themeObject.v2.t04
      },
      rightPriceScale: {
        scaleMargins: {
          top: topScale,
          bottom: 0
        },
        borderVisible: false
      },
      timeScale: {
        borderVisible: false
      },
      grid: {
        horzLines: {
          color: 'rgba(197, 203, 206, 0.5)',
          visible: false
        },
        vertLines: {
          color: 'rgba(197, 203, 206, 0.5)',
          visible: false
        }
      },
      crosshair: {
        horzLine: {
          visible: false,
          labelVisible: false
        },
        vertLine: {
          visible: true,
          style: 0,
          width: 2,
          color: 'rgba(32, 38, 46, 0.1)',
          labelVisible: false
        }
      },
      localization: {
        priceFormatter: (val: number) => formattedNum(val, true),
        locale: 'en'
      }
    })

    const series =
      type === CHART_TYPES.BAR
        ? chart.addHistogramSeries({
            color: themeObject.v2.t10,
            priceFormat: {
              type: 'volume'
            },
            scaleMargins: {
              top: 0.32,
              bottom: 0
            }
          })
        : chart.addAreaSeries({
            topColor: themeObject.v2.t10,
            bottomColor: 'rgba(52, 255, 255, 0)',
            lineColor: themeObject.v2.t10,
            lineWidth: 1
          })

    series.setData(formattedData)

    // update the title when hovering on the chart
    chart.subscribeCrosshairMove(function(param: any) {
      if (
        param === undefined ||
        param.time === undefined ||
        !param?.point?.x ||
        !param?.point?.y ||
        param.point.x < 0 ||
        param.point.x > width ||
        param.point.y < 0 ||
        param.point.y > HEIGHT
      ) {
        setShowTooltip(false)
      } else {
        let dateStr = useWeekly
          ? dayjs(param.time.year + '-' + param.time.month + '-' + param.time.day)
              .startOf('week')
              .format('MMMM D, YYYY') +
            '-' +
            dayjs(param.time.year + '-' + param.time.month + '-' + param.time.day)
              .endOf('week')
              .format('MMMM D, YYYY')
          : dayjs(param.time.year + '-' + param.time.month + '-' + param.time.day).format('MMMM D, YYYY')
        const priceNow = param.seriesPrices.get(series)

        setPrice(priceNow)
        setDateStr(dateStr)
        setShowTooltip(true)
      }
    })

    chart.timeScale().fitContent()

    setChartCreated(chart)
  }, [chartCreated, formattedData, topScale, type, useWeekly, width, themeObject])

  // responsiveness
  useEffect(() => {
    if (width) {
      chartCreated && chartCreated.resize(width, HEIGHT)
      chartCreated && chartCreated.timeScale().scrollToPosition(0)
    }
  }, [chartCreated, width])

  return (
    <Wrapper>
      <TYPE.v2Main>
        {title} {showTooltip && type === CHART_TYPES.BAR && !useWeekly ? `(${hrStr})` : ''}
      </TYPE.v2Main>
      {showTooltip && (
        <Row>
          <TYPE.v2Main fontSize={16}>
            {formattedNum(price, true)}({dateStr})
          </TYPE.v2Main>
        </Row>
      )}
      {!showTooltip && (
        <Row>
          <TYPE.v2Main fontSize={16}>{formattedNum(base, true)}</TYPE.v2Main>
          <PercentText fontSize={16} marginLeft="8px" percent={baseChange}>
            {baseChange >= 0 ? `+${baseChange.toFixed(2)}%` : `${baseChange.toFixed(2)}%`}
          </PercentText>
        </Row>
      )}
      <div ref={ref} id={'view-chart' + type} style={{ flex: 1 }} />
      <IconWrapper>
        <Play
          onClick={() => {
            chartCreated && chartCreated.timeScale().fitContent()
          }}
        />
      </IconWrapper>
    </Wrapper>
  )
}

export default TradingViewChart
