import BigNumber from 'bignumber.js'
import { Candle } from 'gql/subgraph/dexcandles'

export function rebuildCandlesData(candles: Candle[]): Candle[] {
  if (candles.length === 0) {
    return candles
  }

  let lastCandleData = candles[0]

  for (let i = 1; i < candles.length; i++) {
    const nowCandle = candles[i]

    let nowHighLowRate = new BigNumber(nowCandle.high).div(nowCandle.low)
    let highChangeRate = new BigNumber(nowCandle.high).div(lastCandleData.high)

    nowHighLowRate = nowHighLowRate.gte(1) ? nowHighLowRate : new BigNumber(1).div(nowHighLowRate)
    highChangeRate = highChangeRate.gte(1) ? highChangeRate : new BigNumber(1).div(highChangeRate)

    if (nowHighLowRate.gte(10) || highChangeRate.gte(10)) {
      candles[i] = {
        ...lastCandleData,
        time: nowCandle.time
      }
    }

    lastCandleData = candles[i]
  }

  return candles
}
