import { gql } from '@apollo/client'
import { LaunchpadSubgraph } from '../index'

export interface SubgraphPad {
  pad: {
    id: string
  }
  cashedAmount: string
  paidAmount: string
}

export default function getUserPads(user: string): Promise<SubgraphPad[]> {
  // if (!name) return Promise.resolve([]);
  return new Promise((resolve, reject) => {
    LaunchpadSubgraph()
      .query<{
        userPadPositions: SubgraphPad[]
      }>({
        query: gql`
          query userPadPositions($user: String) {
            userPadPositions(where: { user: $user, stakeAmountBeforeStakingEnd_gt: 0 }, orderDirection: desc) {
              stakeAmountBeforeStakingEnd
              cashedAmount
              paidAmount
              pad {
                id
              }
            }
          }
        `,
        variables: {
          user: user.toLowerCase()
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        const { data } = res
        if (data.userPadPositions) {
          return resolve(data.userPadPositions)
        }
        resolve([])
      })
      .catch((e: any) => {
        resolve([])
      })
  })
}
