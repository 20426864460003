/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react'
import Modal from '../Modal'
import { useModalOpen, useBridgeToggle } from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/actions'
import styled from 'styled-components'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import {
  METIS_MAINNET_OB,
  METIS_MAINNET_MB,
  //   METIS_MAINNET_POLY,
  METIS_MAINNET_RB,
  METIS_MAINNET_SB,
  METIS_MAINNET_O3,
  METIS_MAINNET_MCB,
} from '../../constants/lists'
import { useAppSelector } from 'state'
import { useDarkModeManager } from 'state/user/hooks'

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  max-height: 90vh;
  overflow-y: scroll;
`

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const InfoCard = styled.button<{ active?: boolean }>`
  /* background-color: ${({ theme, active }) => (active ? theme.bg3 : theme.bg2)}; */
  background: ${({ theme }) => theme.v2.bg10};
  padding: 1rem;
  outline: none;
  border: 1px solid;
  border-radius: 12px;
  width: 100% !important;
  &:focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.primary1};
  }
  border-color: transparent;
  cursor: pointer;
`

const OptionCard = styled(InfoCard as any)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 2rem; */
  padding: 1rem;
`

const OptionCardLeft = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
  justify-content: center;
  height: 100%;
`

const OptionCardClickable = styled(OptionCard as any)<{ clickable?: boolean }>`
  width: 100%;
  margin-bottom: 16px;
  border-color: transparent;
  &:hover,
  &:focus {
    box-shadow: none;
    cursor: ${({ clickable }) => (clickable ? 'pointer' : '')};
    border-color: transparent;
  }
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`

// const GreenCircle = styled.div`
//   ${({ theme }) => theme.flexRowNoWrap}
//   justify-content: center;
//   align-items: center;

//   &:first-child {
//     height: 8px;
//     width: 8px;
//     margin-right: 8px;
//     background-color: ${({ theme }) => theme.green1};
//     border-radius: 50%;
//   }
// `

// const CircleWrapper = styled.div`
//   color: ${({ theme }) => theme.green1};
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `

const HeaderText = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  color: ${(props) => (props.color === 'blue' ? ({ theme }) => theme.primary1 : ({ theme }) => theme.text1)};
  font-size: 1rem;
  font-weight: 500;
`

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  color: #fff;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const CloseColor = styled(Close)`
  path {
    stroke: '${({ theme }) => theme.text4}';
  }
`

const SubHeader = styled.div`
  color: ${({ theme }) => theme.text1};
  margin-top: 10px;
  font-size: 12px;
  word-break: break-all;
  text-align: left;
`

const IconWrapper = styled.div<{ size?: number | null }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  & > img,
  span {
    height: fit-content;
    width: ${({ size }) => (size ? size + 'px' : '24px')};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};
  flex-shrink: 0;
`

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 500;
  color: ${(props) => (props.color === 'red' ? ({ theme }) => theme.text6 : 'inherit')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`

const ContentWrapper = styled.div`
  background-color: transparent;
  padding: 1rem 2rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 1rem`};
`

const bridgeUrls = [
  {
    jsonUrl: METIS_MAINNET_MB,
    url: 'https://bridge.metis.io',
    name: 'Metis Bridge',
    addBefore: 'Metis',
  },
  {
    jsonUrl: METIS_MAINNET_OB,
    url: 'https://oportal.boringdao.com/native_bridge?target_chain_id=1088',
    name: 'BoringDAO',
    addBefore: '',
  },

  //   {
  //     jsonUrl: METIS_MAINNET_POLY,
  //     url: 'https://bridge.poly.network',
  //     name: 'PolyNetwork',
  //     addBefore: 'Metis(BSC)'
  //   },
  {
    jsonUrl: METIS_MAINNET_RB,
    url: 'https://app.relaychain.com/#/',
    name: 'Relay Chain',
    addBefore: '',
  },
  {
    jsonUrl: METIS_MAINNET_SB,
    url: 'https://synapseprotocol.com/?inputCurrency=USDC&outputCurrency=USDC&outputChain=1088',
    name: 'Synapse Bridge',
    addBefore: '',
  },
  {
    jsonUrl: METIS_MAINNET_O3,
    url: 'https://v2.o3swap.com/bridge',
    name: 'O3 Swap',
    addBefore: 'METIS',
    darkLogo: require('assets/images/o3swap-dark.png'),
  },
  {
    jsonUrl: METIS_MAINNET_MCB,
    url: 'https://app.multichain.org/#/router',
    name: 'Mutichain',
    addBefore: '',
  },
]

export default function BridgeModal() {
  const bridgeOpen = useModalOpen(ApplicationModal.BRIDGE)
  const [isDark] = useDarkModeManager()
  const toggle = useBridgeToggle()
  const { byUrl } = useAppSelector((state) => state.lists)

  const bridgeConfig = useMemo(() => {
    const normalConfig = bridgeUrls.map((item) => {
      const tokenJson = byUrl[item.jsonUrl]
      let tokenNames = ''
      if (tokenJson?.current?.tokens) {
        tokenNames = tokenJson.current.tokens.map((t) => t.symbol).join('/')
        tokenNames = `${item.addBefore ? `${item.addBefore}/` : ''}${tokenNames}`
      }

      const bridgeLogo = tokenJson?.current?.logoURI || ''

      return {
        ...item,
        tokenNames,
        bridgeLogo,
      }
    })

    normalConfig.push({
      jsonUrl: '',
      url: 'https://cbridge.celer.network/',
      name: 'Celer Network',
      addBefore: 'Metis',
      bridgeLogo: 'https://raw.githubusercontent.com/Netswap/token-lists/master/images/celer-network.svg',
      tokenNames: 'Metis',
    })

    return normalConfig
  }, [byUrl])

  return (
    <Modal isOpen={bridgeOpen} onDismiss={toggle} minHeight={false} maxHeight={90}>
      <Wrapper>
        <UpperSection>
          <CloseIcon onClick={toggle}>
            <CloseColor />
          </CloseIcon>
          <HeaderRow
            style={{
              display: 'block',
              textAlign: 'left',
              fontSize: '16px',
              fontWeight: 'bold',
            }}
          >
            Bridge more tokens
          </HeaderRow>
          <ContentWrapper>
            {bridgeConfig.map((item) => {
              return (
                <OptionCardClickable
                  key={item.url}
                  onClick={() => {
                    window.open(item.url)
                  }}
                >
                  <OptionCardLeft>
                    <HeaderText>{item.name}</HeaderText>
                    <SubHeader>{item.tokenNames}</SubHeader>
                  </OptionCardLeft>
                  <IconWrapper size={36}>
                    <img src={isDark ? item.darkLogo || item.bridgeLogo : item.bridgeLogo} alt={'Icon'} />
                  </IconWrapper>
                </OptionCardClickable>
              )
            })}
          </ContentWrapper>
        </UpperSection>
      </Wrapper>
    </Modal>
  )
}
