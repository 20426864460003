import { RowBetween } from 'components/Row'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Currency, METIS } from '@netswap/sdk'
import styled from 'styled-components'
import { useTransition, animated } from 'react-spring'
import MaterialTable from 'material-table'
import { useActiveWeb3React } from 'hooks'
import { getSwaps, SwapReturn } from 'gql/subgraph/swaps'
import { useFormatTime } from 'hooks'
import { useDarkModeManager } from 'state/user/hooks'
import { TYPE, theme } from 'theme'
import useGetBasePriceToken from 'hooks/useGetBasePriceToken'
import { METIS_TOKEN } from '../../constants'
import Loading from 'components/Loading'
import MaterialTablePagination from 'components/MaterialTablePagination'
import { Pair as PairFromSubgraph, getPairByTwoTokens } from 'gql/subgraph/pairs'

const Wrapper = styled(animated.div)`
  width: 370px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.v2.bg02};
  min-height: 539px;
  box-sizing: border-box;
  padding: 8px;
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`

const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  justify-content: center;
  background-color: ${({ theme }) => theme.v2.bg06};
  width: 200px;

  height: 30px;
  border-radius: 8px;
`

const Tab = styled.div`
  width: 50%;
  border-radius: 8px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.v2.t01};
  font-size: 14px;
  height: 100%;
  cursor: pointer;

  font-weight: 500;
  &.active {
    background-color: #d2cfd5;
    color: #000;
  }
`

const TableWrapper = styled.div`
  width: 100%;
  position: relative;
  flex-grow: 1;
  /* max-height: 200px; */
  /* margin-top: 1rem; */
  .MuiPaper-root {
    background: transparent;
    box-shadow: none;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    width: 100%;

    > div {
      width: 100%;
    }
  }
  .MuiTableCell-head,
  .MuiTableCell-root {
    padding: 4px;
    text-align: center;
    border: none;
  }

  &.empty {
    .MuiTableBody-root {
      tr {
        height: 360px !important;
      }
    }
  }

  table {
    overflow: hidden;
  }

  .MuiTableCell-root {
    font-size: 12px !important;
    background-color: ${({ theme }) => theme.v2.bg04};

    &.MuiTableCell-head {
      font-size: 14px !important;
      color: #c0c0c0;
      background-color: transparent !important;
    }

    color: #747373 !important;

    .buy {
      color: #00cb83;
    }

    .sale {
      color: #ff6969;
    }
  }
  .MuiTableRow-root {
    height: 40px !important;
    border-top: 4px solid ${({ theme }) => theme.v2.df01};
    border-bottom: 4px solid ${({ theme }) => theme.v2.df01};
    .MuiTableCell-body {
      &:first-child {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
      }
      &:last-child {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }

  .MuiTablePagination-select,
  .MuiTablePagination-selectRoot {
    display: none !important;
  }
  .MuiTableFooter-root {
    overflow: hidden;
    .MuiTableCell-root {
      background-color: transparent;
    }
  }

  .MuiTablePagination-toolbar {
    display: block;
  }
`

interface Props {
  className?: string
  show?: boolean
  pair?: string
  fromToken?: Currency
  toToken?: Currency
}

const CellText = styled(TYPE.v2Main)`
  width: 100% !important;
  height: 100% !important;
  font-size: 12px !important;
  display: flex;
  align-items: center;
  justify-content: center;

  &.buy {
    color: ${({ theme }) => theme.v2.t08} !important;
  }

  &.sell {
    color: ${({ theme }) => theme.v2.t07} !important;
  }
`

const StyledLoading = styled(Loading)`
  margin-top: 160px;
`

export default function TranscationHistory({ className, show, fromToken, toToken }: Props) {
  const [all, setAll] = useState(true)
  const [pair, setPair] = useState<PairFromSubgraph | null>(null)
  const [darkMode] = useDarkModeManager()
  const themeObject = useMemo(() => theme(darkMode), [darkMode])
  const [history, setHistory] = useState<SwapReturn[]>([])
  const { account } = useActiveWeb3React()
  const transition = useTransition(show, null, {
    config: { duration: 200 },
    from: { opacity: 0, marginRight: '-20px' },
    enter: { opacity: 1, marginRight: '8px' },
    leave: { opacity: 0, marginRight: '-278px' },
  })

  const { formatTime } = useFormatTime()
  const getBasePriceToken = useGetBasePriceToken()
  const [baseToken, setBaseToken] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const updatePair = useCallback(
    async (token0: string, token1: string): Promise<void> => {
      if (!token0 || !token1) {
        setPair(null)
        return
      }
      const pair = await getPairByTwoTokens(token0, token1)

      setPair(pair)
    },
    [setPair]
  )

  useEffect(() => {
    if (!fromToken || !toToken) {
      return
    }
    let from = (fromToken as any).address
    let to = (toToken as any).address

    if (fromToken === METIS) {
      from = METIS_TOKEN.toLowerCase()
    }

    if (toToken === METIS) {
      to = METIS_TOKEN.toLowerCase()
    }

    updatePair(from, to)
  }, [updatePair, fromToken, toToken])

  const updateSwaps = useCallback(
    async (pair?: string, account?: string): Promise<void> => {
      if (!pair) {
        setHistory([])

        return
      }

      const endTime = Math.floor(new Date().getTime() / 1000)
      const swaps = await getSwaps({ pair, user: account, limit: 500, endTime })

      setHistory(swaps)
      setLoading(false)
    },
    [setHistory, setLoading]
  )

  useEffect(() => {
    if (!all && !account) {
      setHistory([])
    } else {
      setLoading(true)
      updateSwaps(pair?.id, all ? undefined : account || undefined)
    }
  }, [updateSwaps, pair, account, all])

  useEffect(() => {
    if (fromToken && toToken) {
      let fromAddr: string = (fromToken as any).address
      let toAddr: string = (toToken as any).address

      if (fromToken === METIS) {
        fromAddr = METIS_TOKEN
      }

      if (toToken === METIS) {
        toAddr = METIS_TOKEN
      }
      setBaseToken(getBasePriceToken({ fromToken: fromAddr.toLowerCase(), toToken: toAddr.toLowerCase() }))
    }
  }, [getBasePriceToken, fromToken, toToken])

  // const { swap } = transactionHistory

  // const mapData = useMemo(() => {
  //   let data = [...swap]
  //     .sort((a, b) => Number(b.time) - Number(a.time))
  //     .map(item => {
  //       return {
  //         ...item,
  //         time: moment.utc(item.time).format('YYYY-MM-DD')
  //       }
  //     })

  //   if (!all) {
  //     data = data.filter(item => item.account === account?.toLowerCase())
  //   }

  //   return data.filter(item => item.chainId === chainId)
  // }, [swap, all, account, chainId])
  return (
    <>
      {transition.map(
        ({ item, key, props }) =>
          item && (
            <Wrapper key={key} className={className} style={props}>
              <RowBetween style={{ flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '20px' }}>
                <TYPE.v2Main fontSize={20}>Trade History</TYPE.v2Main>
                <TabWrapper>
                  <Tab className={all ? 'active' : ''} onClick={() => setAll(true)}>
                    All
                  </Tab>
                  <Tab className={!all ? 'active' : ''} onClick={() => setAll(false)}>
                    My account
                  </Tab>
                </TabWrapper>
              </RowBetween>
              <TableWrapper className={history.length === 0 ? 'empty' : ''}>
                <StyledLoading show={loading} size={48} bgColor={themeObject.v2.bg02} />
                {!loading && (
                  <MaterialTable
                    title="Render Image Preview"
                    options={{ toolbar: false, sorting: false, pageSize: 8, draggable: false }}
                    localization={{
                      body: {
                        emptyDataSourceMessage:
                          !all && !account ? 'Please Connect to wallet' : 'No recent transactions',
                      },
                    }}
                    components={{
                      Pagination: (props) => <MaterialTablePagination pageSize={8} {...props} />,
                    }}
                    columns={[
                      {
                        title: 'USD',
                        field: 'amountUSD',
                        width: '25%',
                        render: (item) => (
                          <CellText className={baseToken === item.fromToken.id ? 'buy' : 'sell'}>
                            {' '}
                            ≈ $ {item.amountUSD.toFixed(2)}
                          </CellText>
                        ),
                      },
                      {
                        title: 'Amount',
                        field: 'amountIn',
                        width: '25%',
                        render: (item) => (
                          <CellText className={baseToken === item.fromToken.id ? 'buy' : 'sell'}>
                            {item.amountIn.toFixed(2)}
                          </CellText>
                        ),
                      },
                      {
                        title: '',
                        width: '25%',
                        render: (item) => (
                          <CellText>
                            {item.fromToken.symbol} <br /> {item.toToken.symbol}
                          </CellText>
                        ),
                      },
                      {
                        title: 'Time',
                        width: '25%',
                        render: (item) => <CellText>{formatTime(item.timestamp)}</CellText>,
                      },
                    ]}
                    data={history}
                  />
                )}
              </TableWrapper>
            </Wrapper>
          )
      )}
    </>
  )
}
