/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import styled from 'styled-components'
// import cx from 'classnames'
import { withStyles, createStyles } from '@material-ui/core/styles'
import { Box } from 'rebass/styled-components'
import { Tab, Tabs } from '@material-ui/core'

import LaunchpadLists from './components/Lists'

import StakeWNett from './components/StakeWNett'
import useGetV2UserPads, { useGetV2Pads } from 'state/data/hooks/useGetLaunchpadV2Data'
import { useMemo } from 'react'
import { Phase } from 'hooks/contract/useLaunchpadV2PadContract'
import { AutoColumn } from 'components/Column'
import { TYPE } from 'theme'

const LaunchpadBox = styled(Box)`
  width: 100%;
  max-width: 1080px;
  padding: 20px;
  display: flex;
  /* background: #170627; */
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100vw - 2rem);
    padding: 20px 0;
  `}

  .launchpadListWrapper {
    width: 100%;
    margin-top: 40px;
    .comingsoonCon {
      color: #ff5b8f;
      width: 100%;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 340px;
      flex-flow: column nowrap;
      img {
        margin-bottom: 24px;
      }
    }

    .MuiInput-root {
      padding: 10px;
      width: 100%;
      height: 44px;
      margin-bottom: 34px;
      color: #fff;
      font-weight: 600;

      font-size: 16px;

      background: #2c163a;
      border: 0.5px solid #1e4448;
      border-radius: 4px;
      &.MuiInput-underline:after {
        display: none;
      }
    }
    .MuiSelect-icon {
      color: #ffffff;
      font-size: 28px;
    }
  }
  #connect-wallet {
  }
`

// const StyledTabs = withStyles({
//   root: {
//     marginBottom: '40px'
//   },
//   flexContainer: {
//     justifyContent: 'flex-start'
//   },
//   indicator: {
//     display: 'flex',
//     justifyContent: 'flex-start',
//     backgroundColor: 'transparent',
//     bottom: '4px',
//     '& > span': {
//       width: 'calc(100% - 28px)',
//       backgroundColor: '#c42a61'
//     }
//   },
//   scroller: {
//     '@media screen and (max-width: 720px)': {
//       overflowX: 'scroll !important'
//     }
//   }
// })((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)

const StyledTabs = styled(Tabs)`
  .MuiTabs-root {
    margin-bottom: 40px;
  }
  .MuiTabs-flexContainer {
    justify-content: flex-start;
  }
  .MuiTab-root {
    padding: 0 20px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0 8px;
  `}
    color: ${({ theme }) => theme.v2.t02};

    &.Mui-selected {
      color: ${({ theme }) => theme.v2.t09};
    }
  }
  .MuiTabs-indicator {
    display: flex;
    justify-content: flex-start;
    background-color: transparent;
    bottom: 0px !important;
    background-color: ${({ theme }) => theme.v2.t09};
    /* width: 60px !important; */
  }
`

interface StyledTabProps {
  label: string
}

const StyledTab = withStyles(() =>
  createStyles({
    root: {
      textTransform: 'none',
      paddingLeft: 0,
      color: '#7f6188',
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '24px',
      minWidth: 'auto',
      padding: '6px 28px 6px 0',
      '&:focus': {
        opacity: 1,
        color: '#ffffff',
      },
      '@media screen and (max-width: 720px)': {
        padding: '6px 20px 6px 0',
      },
    },
    selected: {
      fontWeight: 700,
      color: '#ffffff',
    },
    wrapper: {
      alignItems: 'flex-start',
    },
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />)

function a11yProps(index: any) {
  return {
    id: `launchpad-tab-${index}`,
    'aria-controls': `launchpad-tabpanel-${index}`,
  }
}

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const TitleIcon = styled.img`
  width: 129px;
  height: 102px;
  margin-right: 10px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 70px;
    height: 60px;
  `}
`

export default function LaunchpadV2() {
  const isComingsoon = false
  const [tab, setTab] = React.useState(0)
  const [hasClickTab, setHasClickTab] = React.useState(false)
  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setTab(newValue)

    if (!hasClickTab) {
      setHasClickTab(true)
    }
  }

  const data = useGetV2Pads()
  const userPads = useGetV2UserPads()

  const computedData = useMemo(() => {
    let res = data
    if (tab === 0) {
      res = data.filter((item) => item.currentPhase === Phase.Deposit)
    } else if (tab === 1) {
      res = data.filter((item) => item.currentPhase === Phase.Prepare)
    } else if (tab === 2) {
      res = data.filter((item) => item.currentPhase >= Phase.SaleEnded)
    } else if (tab === 3) {
      res = data.filter((item) => userPads.indexOf(item.id.toLowerCase()) >= 0)
    }
    return res.sort((a, b) => {
      return b.depositEnd - a.depositEnd
    })
  }, [data, tab, userPads])

  useEffect(() => {
    if (!hasClickTab) {
      const hasLive = data.filter((item) => item.currentPhase === Phase.Deposit).length > 0
      if (!hasLive) {
        const hasPrepare = data.filter((item) => item.currentPhase === Phase.Prepare).length > 0

        if (hasPrepare) {
          setTab(1)
        } else {
          const hasEnded = data.filter((item) => item.currentPhase >= Phase.SaleEnded).length > 0

          if (hasEnded) {
            setTab(2)
          }
        }
      }
    }
  }, [data, hasClickTab])

  return (
    <LaunchpadBox>
      <TitleWrapper>
        <TitleIcon src={require('assets/images/launchpad/header.png')} />
        <AutoColumn justify="center" gap="md">
          <TYPE.v2Main fontWeight="bold" fontSize={32}>
            Launchpad
          </TYPE.v2Main>
          <TYPE.desc fontSize={14}>Staking NETT lets you gain wNETT which increases your buying power! </TYPE.desc>
          <TYPE.desc fontSize={14}>
            {`wNETT is the only "ticket" to join IDO events on our Launchpad V2!`}
            {/* Unlock your allocation by spending WNETT Tokens and{' '}
            <span style={{ color: '#5F7AFF' }}>depositing NETT.</span> */}
          </TYPE.desc>
        </AutoColumn>
      </TitleWrapper>
      <StakeWNett />
      <div className="launchpadListWrapper">
        <StyledTabs value={tab} onChange={handleChange} aria-label="launchpad">
          <StyledTab label="Live" {...a11yProps(0)} />
          <StyledTab label="Prepare" {...a11yProps(1)} />
          <StyledTab label="Closed" {...a11yProps(2)} />
          <StyledTab label="Participated" {...a11yProps(3)} />
        </StyledTabs>
        {/* {isMobile && (
          <Select value={tab} onChange={selectChange} IconComponent={KeyboardArrowDownIcon}>
            <MenuItem value={0}>Live</MenuItem>
            <MenuItem value={1}>Prepare</MenuItem>
            <MenuItem value={2}>Closed</MenuItem>
            <MenuItem value={3}>Participated</MenuItem>
          </Select>
        )} */}
        {isComingsoon ? (
          <div className="comingsoonCon">
            <img src={require('../../assets/images/img_coming soon.png')} alt="Coming Soon" />
            Coming Soon
          </div>
        ) : (
          <LaunchpadLists data={computedData} />
        )}
      </div>
    </LaunchpadBox>
  )
}
