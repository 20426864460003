import React from 'react'
import styled from 'styled-components'
import { darken, lighten } from 'polished'

import { RowBetween } from '../Row'
import { ChevronDown } from 'react-feather'
import { Button as RebassButton, ButtonProps } from 'rebass/styled-components'
import { useActiveWeb3React } from 'hooks'
import { useWalletModalToggle } from 'state/application/hooks'

const Base = styled(RebassButton)<{
  padding?: string
  width?: string
  borderRadius?: string
  altDisabledStyle?: boolean
}>`
  padding: ${({ padding }) => (padding ? padding : '18px')};
  width: ${({ width }) => (width ? width : '100%')};
  font-weight: 500;
  text-align: center;
  border-radius: 20px;
  border-radius: ${({ borderRadius }) => borderRadius && borderRadius};
  outline: none;
  border: 1px solid transparent;
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  font-family: Poppins-Medium;
  &:disabled {
    cursor: auto;
  }

  > * {
    user-select: none;
  }
`

export const ButtonPrimary = styled(Base)`
  background-color: ${({ theme }) => theme.primary1};
  color: white;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.primary1)};
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.primary1)};
    background-color: ${({ theme }) => darken(0.1, theme.primary1)};
  }
  &:disabled {
    background-color: ${({ theme, altDisabledStyle, disabled }) =>
      altDisabledStyle ? (disabled ? '#2A1034' : theme.primary1) : '#2A1034'};
    color: ${({ theme, altDisabledStyle, disabled }) =>
      altDisabledStyle ? (disabled ? theme.text3 : 'white') : theme.text3};
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
    opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? '0.5' : '1')};
  }
`

export const ButtonMetis = styled(Base)`
  background: #c42a61;
  color: white;
  border-radius: 8px;
  height: 48px;
  cursor: pointer;
  border: none !important;
  &:disabled {
    background: ${darken(0.1, '#C42A61')};
    box-shadow: none;
    outline: none;
    cursor: not-allowed;
  }
`

export const ConnectWalletButton = styled(Base)`
  width: 100%;
  background: #c42a61;
  border-radius: 56px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  line-height: 46px;
  border: none;
  max-width: 208px;
  cursor: pointer;
  height: 48px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 24px;
    margin-right: 12px;
  }
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
`

interface WrapperButtonProps extends ButtonProps {
  auth?: boolean
}

export function AuthButton(props: WrapperButtonProps) {
  const { auth, children, ...buttonProps } = props
  const { account } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()

  if (auth && !account) {
    return (
      <ButtonV2
        className="authButton"
        {...(buttonProps as any)}
        disabled={false}
        onClick={(e: any) => {
          toggleWalletModal()
          e.stopPropagation()
        }}
      >
        Connect Wallet
      </ButtonV2>
    )
  }
  return <ButtonV2 {...(buttonProps as any)}>{children}</ButtonV2>
}

const BorderWrapper = styled.div`
  display: inline-block;
  background: linear-gradient(to right, rgba(103, 212, 204, 1), rgba(166, 43, 180, 1));
  color: white;
  border-radius: 32px;
  height: 64px;
  box-sizing: border-box;
  padding: 2px;
  cursor: pointer;
`

export const ButtonMetisTransparentInner = styled(Base)`
  background: #0d1f32;
  color: white;
  border-radius: 30px;
  height: 100%;
  cursor: pointer;
  border: none !important;
  padding: 0;
  margin: 0;
  &:disabled {
    cursor: auto;
    box-shadow: none;
    /* border: 1px solid transparent; */
    outline: none;
    /* opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? '0.5' : '1')}; */
  }
`
interface ButtonMetisTransparentProps {
  buttonProps?: ButtonProps
  children: string | React.ReactNode
}

export function ButtonMetisTransparent(props: ButtonMetisTransparentProps) {
  const { buttonProps, children } = props
  return (
    <BorderWrapper>
      <ButtonMetisTransparentInner {...(buttonProps as any)}>{children}</ButtonMetisTransparentInner>
    </BorderWrapper>
  )
}

export const ButtonLight = styled(Base)`
  background-color: ${({ theme }) => theme.primary5};
  color: ${({ theme }) => theme.primaryText1};
  font-size: 16px;
  font-weight: 500;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.03, theme.primary5)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.03, theme.primary5)};
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.03, theme.primary5)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.05, theme.primary5)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.primary5)};
  }
  :disabled {
    opacity: 0.4;
    :hover {
      cursor: auto;
      background-color: ${({ theme }) => theme.primary5};
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  }
`

export const ButtonGray = styled(Base)`
  background-color: #2a1034;
  color: ${({ theme }) => theme.text2};
  font-size: 16px;
  font-weight: 500;
  &:focus {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg4)};
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg4)};
  }
  &:active {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.1, theme.bg4)};
  }
`

export const ButtonSecondary = styled(Base)`
  border: 1px solid ${({ theme }) => theme.primary4};
  color: ${({ theme }) => theme.primary1};
  background-color: transparent;
  font-size: 16px;
  border-radius: 12px;
  padding: ${({ padding }) => (padding ? padding : '10px')};

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.primary4};
    border: 1px solid ${({ theme }) => theme.primary3};
  }
  &:hover {
    border: 1px solid ${({ theme }) => theme.primary3};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.primary4};
    border: 1px solid ${({ theme }) => theme.primary3};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  a:hover {
    text-decoration: none;
  }
`

export const ButtonPink = styled(Base)`
  background-color: ${({ theme }) => theme.primary1};
  color: white;

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.primary1)};
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.primary1)};
    background-color: ${({ theme }) => darken(0.1, theme.primary1)};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.primary1};
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonUNIGradient = styled(ButtonPrimary)`
  color: white;
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  background-color: #2a1034;
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #ff007a 0%, #2172e5 100%), #edeef2;
  width: fit-content;
  position: relative;
  cursor: pointer;
  border: none;
  white-space: no-wrap;
  :hover {
    opacity: 0.8;
  }
  :active {
    opacity: 0.9;
  }
`

export const ButtonOutlined = styled(Base)`
  border: 1px solid ${({ theme }) => theme.bg2};
  background-color: transparent;
  color: ${({ theme }) => theme.text1};

  &:focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.bg4};
  }
  &:hover {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.bg4};
  }
  &:active {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.bg4};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonEmpty = styled(Base)`
  background-color: transparent;
  color: ${({ theme }) => theme.primary1};
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`
export const ButtonWhiteSide = styled(Base)`
  background-color: transparent;
  border: 2px solid #ffffff;
  font-size: 20px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonWhite = styled(Base)`
  border: 1px solid #edeef2;
  background-color: ${({ theme }) => theme.bg1};
  color: black;

  &:focus {
    box-shadow: 0 0 0 1pt ${darken(0.05, '#edeef2')};
  }
  &:hover {
    box-shadow: 0 0 0 1pt ${darken(0.1, '#edeef2')};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${darken(0.1, '#edeef2')};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

const ButtonConfirmedStyle = styled(Base)`
  background-color: ${({ theme }) => lighten(0.5, theme.green1)};
  color: ${({ theme }) => theme.green1};
  border: 1px solid ${({ theme }) => theme.green1};

  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

const ButtonErrorStyle = styled(Base)`
  background-color: ${({ theme }) => theme.red1};
  border: 1px solid ${({ theme }) => theme.red1};

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.red1)};
    background-color: ${({ theme }) => darken(0.05, theme.red1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.red1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.red1)};
    background-color: ${({ theme }) => darken(0.1, theme.red1)};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
    box-shadow: none;
    background-color: ${({ theme }) => theme.red1};
    border: 1px solid ${({ theme }) => theme.red1};
  }
`

export function ButtonConfirmed({
  confirmed,
  altDisabledStyle,
  ...rest
}: { confirmed?: boolean; altDisabledStyle?: boolean } & ButtonProps) {
  if (confirmed) {
    return <ButtonConfirmedStyle {...(rest as any)} />
  } else {
    return <ButtonV2 {...(rest as any)} altDisabledStyle={altDisabledStyle} />
  }
}

export function ButtonError({ error, ...rest }: { error?: boolean } & ButtonProps) {
  if (error) {
    return <ButtonErrorStyle {...(rest as any)} />
  } else {
    return <ButtonV2 {...(rest as any)} />
  }
}

export function ButtonDropdown({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonPrimary {...(rest as any)} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonPrimary>
  )
}

export function ButtonDropdownGrey({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonGray {...(rest as any)} disabled={disabled} style={{ borderRadius: '20px' }}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonGray>
  )
}

export function ButtonDropdownLight({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonOutlined {...(rest as any)} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonOutlined>
  )
}

export function ButtonRadio({ active, ...rest }: { active?: boolean } & ButtonProps) {
  if (!active) {
    return <ButtonWhite {...(rest as any)} />
  } else {
    return <ButtonPrimary {...(rest as any)} />
  }
}

/** V2 Component */

export const ButtonV2 = styled(Base)`
  background-color: #00cfff;
  color: #000;
  box-shadow: 0px 6px 0px -1px rgba(0, 0, 0, 0.75);
  &.authButton {
    width: 100%;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 600;
    line-height: 46px;
    border: none;
    max-width: 208px;
    cursor: pointer;
    height: 48px;
    padding: 0 20px;
  }
  &:focus {
    background-color: ${({ theme }) => darken(0.2, '#00cfff')};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.2, '#00cfff')};
  }
  &:active {
    background-color: ${({ theme }) => darken(0.2, '#00cfff')};
  }
  &:disabled {
    background-color: ${({ theme, altDisabledStyle, disabled }) =>
      altDisabledStyle ? (disabled ? '#16687b' : '#00cfff') : '#16687b'};
    color: ${({ theme, altDisabledStyle, disabled }) => (altDisabledStyle ? (disabled ? '#000' : '#000') : '#000')};
    cursor: auto;
    border: 1px solid transparent;
    outline: none;
    /* opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? '0.5' : '1')}; */
  }
`

export const ButtonV2Transparent = styled(Base)`
  border: 1px solid #00cfff;
  box-shadow: 0px 4px 0px 0px #171717;
  color: ${({ theme }) => (theme.isDark ? '#00cfff' : '#020423')};

  background-color: ${({ theme }) => (theme.isDark ? 'rgba(0, 0, 0, 0.28)' : '#ffffff')};
  font-size: 16px;
  border-radius: 12px;
  padding: ${({ padding }) => (padding ? padding : '10px')};

  &:hover,
  &:focus,
  &:active {
    background-color: ${({ theme }) => (theme.isDark ? 'rgba(0, 0, 0, 0.4)' : '#f1f1f3')};
  }

  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  a:hover {
    text-decoration: none;
  }
`

export const ConnectWalletButtonInHeader = styled(Base)`
  background-color: ${({ theme }) => theme.v2.bg09};
  color: ${({ theme }) => theme.v2.t03};
  font-size: 14px;
  border-radius: 20px;
  height: 40px;
  border: 1px solid #00cfff;
  line-height: 24px;
  font-weight: 500;
  width: auto;

  &.error {
    border-color: #ff6969;
    color: #ff6969;
  }
`

export function AuthButtonInHeader(props: WrapperButtonProps) {
  const { auth, children, ...buttonProps } = props
  const { account } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()

  if (auth && !account) {
    return <ConnectWalletButtonInHeader onClick={toggleWalletModal}>Connect to Wallet</ConnectWalletButtonInHeader>
  }
  return <ConnectWalletButtonInHeader {...(buttonProps as any)}>{children}</ConnectWalletButtonInHeader>
}
