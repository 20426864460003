import React, { useMemo } from 'react'
import styled from 'styled-components'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import useAirdrop from 'hooks/contract/nettAirdrop/airdrop'
import { useAppSelector } from 'state'
import Value from 'components/Value'
import { TYPE } from 'theme'
import TooltipInfo from 'components/TooltipInfo'
import moment from 'moment'
import { ButtonV2 } from 'components/Button'

const StyledGridLine = styled(Grid)`
  background: ${({ theme }) => theme.v2.bg02};
`

const StyledGridTitle = styled(Grid)`
  color: ${({ theme }) => theme.v2.t02};
`

const StyledGridValue = styled(Grid)`
  color: ${({ theme }) => theme.v2.t01};
`

const ClaimBtn = styled(ButtonV2)`
  width: 64px;
  height: 32px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 400;
  border: none;
  cursor: pointer;
  line-height: 32px;
  margin: 0 auto;
  &.claimOver {
    /* background: #2a1034; */
    cursor: default;
  }
`
const RewardNote = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  i {
    font-sizemargin-right: 8px;
  }
`

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //   flexGrow: 1,
      textAlign: 'center',
      marginTop: '14px'
    },
    head: {
      color: 'rgba(255,255,255,0.5)',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px'
    },
    con: {
      height: '80px',
      borderRadius: '4px',
      //   background: '#170627',
      color: '#ffffff',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      marginTop: '24px'
    }
  })
)

export default function OverviewInfo() {
  const classes = useStyles()
  const { claimAirdrop1, claimAirdrop2 } = useAirdrop()
  const {
    airdropData: { first, second }
  } = useAppSelector(state => state.netswapData)

  const isFirstAirdropEnd = useMemo(() => {
    if (first.endTime !== -1 && first.endTime && first.endTime > 0) {
      return new Date().getTime() > first.endTime
    }

    return false
  }, [first.endTime])

  const isSecondAirdropEnd = useMemo(() => {
    if (second.endTime !== -1 && second.endTime && second.endTime > 0) {
      return new Date().getTime() > second.endTime
    }

    return false
  }, [second.endTime])

  return (
    <div className={classes.root}>
      <Grid className={classes.head} container direction="row" justify="center" alignItems="center">
        <StyledGridTitle item xs={2}></StyledGridTitle>
        <StyledGridTitle item xs={2}>
          Swap Reward
        </StyledGridTitle>
        <StyledGridTitle item xs={2}>
          Liquidity Reward
        </StyledGridTitle>
        <StyledGridTitle item xs={2}>
          Promotion Reward
        </StyledGridTitle>
        <StyledGridTitle item xs={2}>
          Total Reward
        </StyledGridTitle>
        <StyledGridTitle item xs={2}></StyledGridTitle>
      </Grid>
      <StyledGridLine className={classes.con} container direction="row" justify="center" alignItems="center">
        <StyledGridValue item xs={2}>
          1st Airdrop
          <TooltipInfo
            text={
              <>
                <span style={{ display: 'block', lineHeight: '17px' }}>
                  Share of Swap Score:{' '}
                  {first.info?.swap ? `${first.info?.swap.share < 0.001 ? '< 0.001' : first.info?.swap.share}%` : '-'}
                </span>
                <span style={{ lineHeight: '17px' }}>
                  Share of Liquidity Score:{' '}
                  {first.info?.lp ? `${first.info?.lp.share < 0.001 ? '< 0.001' : first.info?.lp.share}%` : '-'}
                </span>
              </>
            }
            placement="top-start"
            style={{ marginLeft: '4px', width: '15px', height: '15px' }}
          />
        </StyledGridValue>
        <StyledGridValue item xs={2}>
          <Value value={first?.info?.swap.amount || '-'} />
        </StyledGridValue>
        <StyledGridValue item xs={2}>
          <Value value={first?.info?.lp.amount || '-'} />
        </StyledGridValue>
        <StyledGridValue item xs={2}>
          <Value value={first?.info?.promotion.amount || '-'} />
        </StyledGridValue>
        <StyledGridValue item xs={2}>
          <Value value={first?.info?.formatAmount || '-'} />
        </StyledGridValue>
        <StyledGridValue item xs={2}>
          {first?.info ? (
            !isFirstAirdropEnd && !first.claimed ? (
              <ClaimBtn onClick={claimAirdrop1}>Claim</ClaimBtn>
            ) : (
              <TYPE.v2Main color="t09">{first.claimed ? 'Claimed' : 'Activity over'}</TYPE.v2Main>
            )
          ) : (
            '-'
          )}
        </StyledGridValue>
      </StyledGridLine>
      <StyledGridLine className={classes.con} container direction="row" justify="center" alignItems="center">
        <StyledGridValue item xs={2}>
          2nd Airdrop
          <TooltipInfo
            text={
              <>
                <span style={{ display: 'block', lineHeight: '17px' }}>
                  Share of Swap Score:{' '}
                  {second.info?.swap
                    ? `${second.info?.swap.share < 0.001 ? '< 0.001' : second.info?.swap.share}%`
                    : '-'}
                </span>
                <span style={{ lineHeight: '17px' }}>
                  Share of Liquidity Score:{' '}
                  {second.info?.lp ? `${second.info?.lp.share < 0.001 ? '< 0.001' : second.info?.lp.share}%` : '-'}
                </span>
              </>
            }
            placement="top-start"
            style={{ marginLeft: '4px', width: '15px', height: '15px' }}
          />
        </StyledGridValue>
        <StyledGridValue item xs={2}>
          <Value value={second?.info?.swap.amount || '-'} />
        </StyledGridValue>
        <StyledGridValue item xs={2}>
          <Value value={second?.info?.lp.amount || '-'} />
        </StyledGridValue>
        <StyledGridValue item xs={2}>
          <Value value={second?.info?.promotion.amount || '-'} />
        </StyledGridValue>
        <StyledGridValue item xs={2}>
          <Value value={second?.info?.formatAmount || '-'} />
        </StyledGridValue>
        <StyledGridValue item xs={2}>
          {second?.info ? (
            !isSecondAirdropEnd && !second.claimed ? (
              <ClaimBtn onClick={claimAirdrop2}>Claim</ClaimBtn>
            ) : (
              <TYPE.v2Main color="t09">{second.claimed ? 'Claimed' : 'Activity over'}</TYPE.v2Main>
            )
          ) : (
            '-'
          )}
        </StyledGridValue>
      </StyledGridLine>
      <RewardNote>
        <i className="iconfont icon-helper"></i>
        You will be able to claim your reward until{' '}
        {first.endTime === -1 ? '-' : `${moment.utc(first.endTime).format('HH:mm:ss UTC, MMM Do YYYY')}`}.
      </RewardNote>
    </div>
  )
}
