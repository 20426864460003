/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import { InputBase } from '@material-ui/core'
import Modal from 'components/Modal'
import { ButtonMetis, ButtonOutlined } from 'components/Button'
import Value from 'components/Value'
import { AutoRow } from 'components/Row'
import { ReactComponent as Close } from 'assets/images/x.svg'
import { TYPE } from 'theme'
import { LaunchPad, PadType } from 'gql/microservices/pads'
import useTokenApprove from 'hooks/contract/useTokenApprove'
import { BigNumber } from 'bignumber.js'
import { Contract } from 'ethers'
import { ERC20_ABI } from 'constants/abis/erc20'
import { useContractByAbiAddr } from 'hooks/contract/useContract'
import { useActiveWeb3React } from 'hooks'
import TokenImage from 'components/TokenImage'
import useSend from 'state/contract/hooks/useSend'
import { LaunchPadIntroduction } from 'state/data'

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`

const LaunchpadSection = styled.div`
  padding: 32px 40px;
  position: relative;
  ${({ theme }) => theme.flexColumnNoWrap}
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 24px 12px;
  `}
`

const CloseIcon = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
  color: #c42a61;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
const CloseColor = styled(Close)`
  path {
    stroke: '${({ theme }) => theme.text4}';
  }
`
const LaunchpadTitle = styled(TYPE.white)`
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
`
const LaunchpadNoteOne = styled(TYPE.white)`
  font-size: 14px;
  line-height: 20px;
  max-width: 310px;
  word-break: break-word;
`

const LaunchpadCon = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  width: 100%;
  margin: 39px auto 32px;

  .launchpadConTitle {
    height: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    line-height: 20px;
    margin: 0;
  }
`

const LaunchpadSolidCon = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  justify-content: space-between;
  padding: 12px 16px;
  background: #221232;
  border-radius: 8px;
  align-item: center;
  margin: 16px 0;
  &.outlineTransParentBg {
    background: transparent;
    border: 1px solid #331b4b;
  }
  &.flexColumn {
    ${({ theme }) => theme.flexColumnNoWrap}
  }
  .launchpadConLeft {
    ${({ theme }) => theme.flexRowNoWrap}
    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }

  .launchpadConRight {
    ${({ theme }) => theme.flexRowNoWrap}
    &.unlimited {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
    }
    ${({ theme }) => theme.mediaWidth.upToSmall`
      justify-content: space-between;
    `}
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }
`
const LaunchpadInput = styled(InputBase)`
  width: fit-content;
  min-width: 100px;
  height: 32px;
  .MuiInputBase-input {
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
    line-height: 32px;
  }

  input {
    color: #fff;
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`

// const LaunchpadTextHalfWhite = styled.span`
//   font-size: 14px;
//   font-weight: 400;
//   color: rgba(255, 255, 255, 0.5);
//   line-height: 20px;
// `

const LaunchpadBorderCon = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  width: 100%;
  border-radius: 8px;
  border: 1px solid #331b4b;
  padding: 12px 16px;
`

const LaunchpadBorderSection = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  justify-content: space-between;
  margin-bottom: 16px;
`

const SmallBtnCon = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  justify-content: center;
`

const ConfirmButton = styled(ButtonMetis)`
  border-radius: 8px;
  width: 90%;
  max-width: 420px;
  margin: 0 auto;
  height: 64px;
  margin-bottom: 0;
  font-size: 20px;
`

// const SmallConfirmButton = styled(ButtonMetis)`
//   height: 48px;
//   font-size: 16px;
//   width: 160px;
//   border-radius: 8px;
//   font-weight: 600;
//   line-height: 48px;
// `
const CancelButton = styled(ButtonOutlined)`
  height: 48px;
  font-size: 16px;
  width: 160px;
  border-radius: 8px;
  border: 1px solid #ffffff;
  font-weight: 600;
  line-height: 48px;
`
const MaxBtn = styled(ButtonOutlined)`
  width: 42px;
  margin: 0 0 0 21px;
  padding: 0;
  height: 24px;
  border-radius: 4px;
  color: #c42a61;
  font-size: 14px;
  border: 1px solid #c42a61;
  &:focus,
  &:hover,
  &:active {
    box-shadow: none;
  }
`

const InfoDefault = styled.div`
  display: flex;
  justify-content: flex-start;
`

const InfoConTopBottom = styled(InfoDefault)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-flow: column nowrap;
  `}
  p,
  a {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    margin: 0;
    line-height: 1.5;
    color: ${({ theme }) => theme.text1};
    ${({ theme }) => theme.mediaWidth.upToSmall`
      font-size: 16px;
      width: 100%;
      word-break: break-all;
    `}
  }
  a {
    color: #c42a61;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`

interface Props {
  isOpen: boolean
  onClose(): void
  padInfo: LaunchPad
  baseModal: Contract | null
  unlimitModal: Contract | null
  handleStakeSuccess?(): void
  additionFeeRate: number
  userStakeAmount: BigNumber
  userInwhiteList: boolean
  project?: LaunchPadIntroduction
  getRealTimeStakedAmount(): Promise<BigNumber>
}

export default function StakeModal({
  isOpen,
  onClose,
  padInfo,
  baseModal,
  unlimitModal,
  handleStakeSuccess,
  additionFeeRate,
  userStakeAmount,
  userInwhiteList,
  project,
  getRealTimeStakedAmount,
}: Props) {
  const { account } = useActiveWeb3React()
  const [launchpadStep, setLaunchpadStep] = useState('init')
  const [confirmed] = useState(true)
  const [stakeInput, setStakeInput] = useState('')
  const stakeTokenContract = useContractByAbiAddr(padInfo.stakedToken.address, ERC20_ABI)
  const [userStakeTokenBalance, setUserStakeTokenBalance] = useState(new BigNumber(0))
  const [realTimeStakedAmount, setRealTimeStakeAmount] = useState(new BigNumber(0))

  const stakeInputAmount = useMemo(() => {
    return new BigNumber(stakeInput || 0)
  }, [stakeInput])

  function handleClose() {
    setLaunchpadStep('init')
    onClose()
  }

  const stakeContract = useMemo(() => {
    return padInfo.poolType === PadType.basic ? baseModal : unlimitModal
  }, [padInfo.poolType, baseModal, unlimitModal])
  const send = useSend()
  const { approved, approve } = useTokenApprove(
    {
      address: padInfo.stakedToken.address,
      decimals: padInfo.stakedToken.decimals,
      symbol: padInfo.stakedToken.symbol,
    },
    stakeContract?.address || '',
    stakeInputAmount,
    3000
  )
  async function getBalance(contract: any) {
    if (contract && account) {
      try {
        const res = await contract.balanceOf(account)
        return res
      } catch (e) {
        console.error(e)
      }
    }
  }

  async function getUserStakeTokenBalance() {
    if (stakeTokenContract) {
      const res = await getBalance(stakeTokenContract)

      setUserStakeTokenBalance(
        res?.toString() ? new BigNumber(res.toString()).shiftedBy(-padInfo.stakedToken.decimals) : new BigNumber(0)
      )
    }
  }

  useEffect(() => {
    let interval: any
    if (stakeTokenContract && account) {
      getUserStakeTokenBalance()

      interval = setInterval(() => {
        getUserStakeTokenBalance()
      }, 15000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [stakeTokenContract, account])

  async function getRealTimStakedAmountFunc() {
    const realTimeStakedAmount = await getRealTimeStakedAmount()
    setRealTimeStakeAmount(realTimeStakedAmount)
    return realTimeStakedAmount
  }

  const isStakeMetis = useMemo(() => {
    return padInfo.stakedToken.address.toLowerCase() === '0xdeaddeaddeaddeaddeaddeaddeaddeaddead0000'
  }, [padInfo])

  async function handleStakeMax() {
    let maxAmount = userStakeTokenBalance

    if (padInfo.poolType === PadType.basic) {
      const realTimeStakeAmount = await getRealTimStakedAmountFunc()
      const baseLeftAmount = new BigNumber(padInfo.maxStakedCap).minus(realTimeStakeAmount)
      const userLeftAmount = new BigNumber(padInfo.maxPerUser).minus(userStakeAmount)

      if (userLeftAmount.isGreaterThan(0)) {
        if (userStakeTokenBalance.isGreaterThanOrEqualTo(userLeftAmount)) {
          maxAmount = baseLeftAmount.isGreaterThanOrEqualTo(userLeftAmount) ? userLeftAmount : baseLeftAmount
        } else {
          maxAmount = baseLeftAmount.isGreaterThanOrEqualTo(userStakeTokenBalance)
            ? userStakeTokenBalance
            : baseLeftAmount
        }
      }
    }
    if (isStakeMetis) {
      if (maxAmount.isLessThanOrEqualTo(0.1)) {
        return
      } else {
        if (maxAmount.isEqualTo(userStakeTokenBalance)) {
          maxAmount = maxAmount.minus(0.1)
        }
      }
    }
    setStakeInput(maxAmount.toFixed())
  }

  useEffect(() => {
    getRealTimStakedAmountFunc()
    const interval = setInterval(() => {
      getRealTimStakedAmountFunc()
    }, 10000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [padInfo.id])

  const errorMessage = useMemo(() => {
    if (stakeInputAmount.isGreaterThan(userStakeTokenBalance.minus(isStakeMetis ? 0.1 : 0))) {
      return isStakeMetis
        ? `In order to make transaction success, you need to keep at lease 0.1 METIS `
        : `Insufficient balance`
    }

    if (padInfo.poolType === PadType.basic) {
      const baseLeftAmount = new BigNumber(padInfo.maxStakedCap).minus(realTimeStakedAmount)

      if (baseLeftAmount.isEqualTo(0)) {
        return `The total staking amount is full.`
      }
      const userLeftAmount = new BigNumber(padInfo.maxPerUser).minus(userStakeAmount)

      if (userLeftAmount.isEqualTo(0)) {
        return `You have reached the maximum staking cap per user.`
      }

      if (baseLeftAmount.isGreaterThanOrEqualTo(userLeftAmount)) {
        if (stakeInputAmount.isGreaterThan(userLeftAmount)) {
          return `You have exceed the maximum staking cap per user.`
        }
      } else {
        if (stakeInputAmount.isGreaterThan(baseLeftAmount)) {
          return `You have exceed the remaining staking amount of this project.`
        }
      }
    }
    return ''
  }, [realTimeStakedAmount, padInfo, stakeInputAmount, userStakeAmount, isStakeMetis, userStakeTokenBalance])

  async function stake() {
    if (stakeContract) {
      await send({
        contract: stakeContract,
        method: 'stake',
        params: [padInfo.pid, stakeInputAmount.shiftedBy(padInfo.stakedToken.decimals).toFixed(0)],
        pendingText: `Staking ${stakeInputAmount.toFixed()} ${padInfo.stakedToken.symbol}`,
        summary: `Staked ${stakeInputAmount.toFixed()} ${padInfo.stakedToken.symbol}`,
        onSuccess() {
          getUserStakeTokenBalance()
          if (handleStakeSuccess) {
            handleStakeSuccess()
          }
          setStakeInput('')
          handleClose()
        },
      })
    }
  }

  function handleConfirm() {
    if (padInfo.isWhiteList && !userInwhiteList) {
      setLaunchpadStep('whitelistfail')
      return
    }
    if (approved) {
      stake()
    } else {
      approve()
    }
  }

  const shareRate = useMemo(() => {
    const userAmount = stakeInputAmount.plus(userStakeAmount)
    if (userAmount.isEqualTo(0)) {
      return new BigNumber(0)
    } else if (userAmount.isGreaterThanOrEqualTo(padInfo.stakedAmount)) {
      return new BigNumber(100)
    } else {
      return userAmount.dividedBy(padInfo.stakedAmount).multipliedBy(100)
    }
  }, [userStakeAmount, stakeInputAmount, padInfo])

  return (
    <Modal isOpen={isOpen} onDismiss={handleClose} minHeight={false} maxWidth={560} maxHeight={200}>
      <Wrapper>
        <LaunchpadSection>
          <CloseIcon onClick={handleClose}>
            <CloseColor />
          </CloseIcon>
          {launchpadStep === 'init' && (
            <>
              <LaunchpadTitle>
                IDO-{padInfo.poolType === PadType.basic ? 'Primary Tier' : 'Unlimited Tier'}
              </LaunchpadTitle>
              <LaunchpadCon>
                <h3 className="launchpadConTitle">Project</h3>
                <LaunchpadSolidCon className="outlineTransParentBg">
                  <div className="launchpadConLeft">
                    <TokenImage address={padInfo.saleToken.address} />
                    <TYPE.white fontSize={14} lineHeight={'24px'} margin={'0 16px 0 12px'}>
                      {padInfo.saleToken.symbol}
                    </TYPE.white>
                  </div>
                  <TYPE.white fontSize={14} lineHeight={'24px'}>
                    Amount: <Value value={padInfo.salesAmount} />
                  </TYPE.white>
                </LaunchpadSolidCon>

                <h3 className="launchpadConTitle">Stake</h3>
                <LaunchpadSolidCon className="flexColumn">
                  <AutoRow justify="space-between">
                    {padInfo.poolType === PadType.basic && (
                      <TYPE.white fontSize={14} lineHeight={'24px'}>
                        Primary Tier Limit {padInfo.maxPerUser} {padInfo.stakedToken.symbol}
                      </TYPE.white>
                    )}
                    <div
                      className={classnames('launchpadConRight', padInfo.poolType === PadType.unlimited && 'unlimited')}
                    >
                      <TYPE.white fontSize={14} lineHeight={'24px'}>
                        Balance: {userStakeTokenBalance.toFixed()}
                      </TYPE.white>
                      <MaxBtn onClick={handleStakeMax}>Max</MaxBtn>
                    </div>
                  </AutoRow>
                  <AutoRow justify="space-between" style={{ margin: '12px 0', flexWrap: 'nowrap' }}>
                    <LaunchpadInput
                      value={stakeInput}
                      type="number"
                      placeholder="0.00"
                      onChange={(e) => {
                        if (Number(e.currentTarget.value) < 0 || e.currentTarget.value === '-') {
                          setStakeInput('')
                          return
                        }
                        setStakeInput(e.currentTarget.value || '')
                      }}
                    />
                    <div className="launchpadConRight">
                      <TokenImage address={padInfo.stakedToken.address} alt={padInfo.stakedToken.symbol} />
                      <TYPE.white fontSize={18} lineHeight={'32px'} marginLeft={12}>
                        {padInfo.stakedToken.symbol}
                      </TYPE.white>
                    </div>
                  </AutoRow>
                  <InfoConTopBottom>
                    {errorMessage && (
                      <p>
                        <TYPE.error fontSize={14} error={true}>
                          {errorMessage}
                        </TYPE.error>
                      </p>
                    )}
                  </InfoConTopBottom>
                </LaunchpadSolidCon>

                <div style={{ minHeight: '160px' }}>
                  <LaunchpadBorderCon>
                    <LaunchpadBorderSection>
                      <TYPE.white fontSize={14} lineHeight={'20px'}>
                        Price per {padInfo.saleToken.symbol}
                      </TYPE.white>
                      <TYPE.white fontSize={14} lineHeight={'20px'}>
                        $<Value value={padInfo.price} />
                      </TYPE.white>
                    </LaunchpadBorderSection>

                    <LaunchpadBorderSection>
                      <TYPE.white fontSize={14} lineHeight={'20px'}>
                        Estimate Share of total
                      </TYPE.white>
                      <TYPE.white fontSize={14} lineHeight={'20px'}>
                        {shareRate.lt(0.01) && shareRate.gt(0) ? `< 0.01` : shareRate.toFixed(2)}%
                      </TYPE.white>
                    </LaunchpadBorderSection>

                    {padInfo.poolType === PadType.unlimited && (
                      <LaunchpadBorderSection style={{ marginBottom: 0 }}>
                        <TYPE.white fontSize={14} lineHeight={'20px'}>
                          Additional fee
                        </TYPE.white>
                        <TYPE.white fontSize={14} lineHeight={'20px'}>
                          {additionFeeRate.toFixed(2)}%
                        </TYPE.white>
                      </LaunchpadBorderSection>
                    )}
                  </LaunchpadBorderCon>

                  {/* <AutoRow style={{ cursor: 'pointer', marginTop: '7px' }} onClick={() => setConfirmed(!confirmed)}>
                    <Checkbox
                      name="confirmed"
                      checkedIcon={<img src={require('assets/images/ic_tick_on.png')} alt="checkIcon" />}
                      icon={<img src={require('assets/images/ic_tick_off.png')} alt="checkOffIcon" />}
                      checked={confirmed}
                      onChange={() => setConfirmed(!confirmed)}
                    />
                    <LaunchpadTextHalfWhite>
                      I have understood and agreed to{' '}
                      <a href="/home" target="_blank" style={{ color: '#63d8cd' }}>
                        the service agreement
                      </a>
                    </LaunchpadTextHalfWhite>
                  </AutoRow> */}
                </div>
              </LaunchpadCon>

              <ConfirmButton disabled={!confirmed || !stakeInput || !!errorMessage} onClick={handleConfirm}>
                {approved ? 'Stake' : 'Approve'}
              </ConfirmButton>
            </>
          )}

          {launchpadStep === 'intro' && project && (
            <>
              <LaunchpadTitle>{project.name} Introduction</LaunchpadTitle>
              <LaunchpadCon>
                <h3 className="launchpadConTitle">Project</h3>
                <LaunchpadSolidCon className="outlineTransParentBg">
                  <div className="launchpadConLeft">
                    <TokenImage address={padInfo.saleToken.address} alt="logo" />
                    <TYPE.white fontSize={14} lineHeight={'24px'} margin={'0 16px 0 12px'}>
                      {padInfo.saleToken.symbol}
                    </TYPE.white>
                  </div>
                </LaunchpadSolidCon>

                <h3 className="launchpadConTitle">Project Description</h3>
                <LaunchpadBorderCon style={{ minHeight: '120px', margin: '16px 0' }}>
                  <TYPE.white fontSize={14} lineHeight={'24px'} style={{ wordBreak: 'break-all' }}>
                    {project.description}
                  </TYPE.white>
                </LaunchpadBorderCon>

                <div style={{ minHeight: '160px' }}>
                  <h3 className="launchpadConTitle">Project Description</h3>
                  <div style={{ marginTop: '7px' }}>
                    <TYPE.white fontSize={14} lineHeight={'20px'} marginTop={'5px'}>
                      Official website:{' '}
                      <a href={project.website} style={{ color: '#ffffff' }}>
                        {project.website}
                      </a>
                    </TYPE.white>
                    {project.links &&
                      Object.keys(project.links).map((item, index) => (
                        <TYPE.white key={index} fontSize={14} lineHeight={'20px'} marginTop={'5px'}>
                          {item}:{' '}
                          <a href={project.links[item]} style={{ color: '#ffffff' }}>
                            {project.links[item]}
                          </a>
                        </TYPE.white>
                      ))}
                  </div>
                </div>
              </LaunchpadCon>
              <ConfirmButton
                onClick={() => {
                  setLaunchpadStep('init')
                }}
              >
                Close
              </ConfirmButton>
            </>
          )}

          {launchpadStep === 'whitelistfail' && (
            <>
              <LaunchpadTitle>Check Whitelist</LaunchpadTitle>
              <img style={{ margin: '49px 0 32px' }} src={require('assets/images/ic_fail.png')} alt="successIcon" />
              <LaunchpadNoteOne marginBottom={'84px'}>
                Your address is not on the whitelist, please check the address or participate in the whitelist tasks.
              </LaunchpadNoteOne>
              <SmallBtnCon style={{ flexFlow: 'column' }}>
                {/* <SmallConfirmButton
                  style={{ marginBottom: '16px' }}
                  onClick={() => {
                    setLaunchpadStep('whitelist')
                  }}
                >
                  Participate
                </SmallConfirmButton> */}
                <CancelButton
                  onClick={() => {
                    handleClose()
                  }}
                >
                  Close
                </CancelButton>
              </SmallBtnCon>
            </>
          )}
          {/* whitelist modal end */}
        </LaunchpadSection>
      </Wrapper>
    </Modal>
  )
}
