import React from 'react'
import styled from 'styled-components'
import WarningIconSrc from 'assets/images/warnIcon.png'
import { TYPE } from 'theme'
import Container from '@material-ui/core/Container'
import { useURLWarningToggle, useURLWarningVisible } from '../../state/user/hooks'
import { useHeaderHeight } from '../../state/application/hooks'
import { isMobile } from 'react-device-detect'
import { Flex } from 'rebass'

const Wrapper = styled.div<{ isActive: boolean; headerHeight: number; offsetTop: number }>`
  width: 100%;
  padding: 21px 0;
  min-height: 88px;
  background: ${({ theme }) => (theme.isDark ? '#020423' : '#473EBC')};
  color: #ffffff;
  position: relative;
  /* top: ${({ headerHeight }) => headerHeight}px; */
  z-index: 99;
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  transform: translateY(${({ offsetTop }) => offsetTop}px);

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 8px 0;
    min-height: unset;
    position: relative;
    top: 0;
    z-index: 1;
  `}
`

const CloseIcon = styled.i`
  color: #ffffff;
  font-size: 24px;
  position: absolute;
  top: 16px;
  right: 10%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 20px;
    top: 8px;
    right: 8px;
  `}
`

export default function Warnning({ offsetTop = false }: { offsetTop?: boolean }) {
  const toggleURLWarning = useURLWarningToggle()
  const showURLWarning = useURLWarningVisible()
  const isNet = window.location.hostname === 'netswap.io'
  const headerHeight = useHeaderHeight()

  return isMobile || !isNet ? (
    <Wrapper isActive={showURLWarning} headerHeight={headerHeight} offsetTop={offsetTop ? -30 : 0}>
      <Container maxWidth="lg" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src={WarningIconSrc} style={{ height: isMobile ? 24 : 49, width: 'auto', marginRight: 18 }} alt="" />

        <Flex flexWrap="wrap">
          <TYPE.mainLg fontSize={isMobile ? 14 : 18} color="t09" marginRight={4}>
            PHISHING WARNING:
          </TYPE.mainLg>
          <TYPE.mainLg fontSize={isMobile ? 12 : 16}>
            Always make sure the URL is netswap.io - bookmark it to be safe.
          </TYPE.mainLg>
        </Flex>
      </Container>

      <CloseIcon className="iconfont icon-close" onClick={toggleURLWarning} />
    </Wrapper>
  ) : (
    <></>
  )
}
