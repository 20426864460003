/* eslint-disable react-hooks/exhaustive-deps */
import { ChainId } from '@netswap/sdk'
import { NETWORK_CHAIN_ID } from 'connectors'
import getLaunchPads from 'gql/microservices/pads'
import { useActiveWeb3React } from 'hooks'
import { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import { LaunchPadIntroduction, setLaunchpads, setLaunchpadsIntroduction } from '../index'
import axios from 'axios'

const IntroJson = {
  [ChainId.MAINNET]: 'https://raw.githubusercontent.com/Netswap/launchpad/master/project/mainnet-introduction.json',
  [ChainId.TESTNET]: 'https://raw.githubusercontent.com/Netswap/launchpad/master/project/testnet-introduction.json',
  [ChainId.TESTNET_SEPOLIA]:
    'https://raw.githubusercontent.com/Netswap/launchpad/master/project/testnet-introduction.json',
}

export default function useGetLaunchpads() {
  const { chainId } = useActiveWeb3React()
  const [loading, setLoading] = useState(true)
  const dispatch = useAppDispatch()
  const { launchpad } = useAppSelector((state) => state.netswapData)

  async function getLaunchPadsIntroduction() {
    try {
      const cid = chainId || NETWORK_CHAIN_ID

      const { data } = await axios.get<{
        [key: string]: LaunchPadIntroduction
      }>(IntroJson[cid as ChainId])
      if (data) {
        dispatch(
          setLaunchpadsIntroduction({
            chainId: cid,
            data,
          })
        )
      }
    } catch (e) {
      console.error('getLaunchPadsIntroduction fetch failed')
      console.error(e)
    }
  }

  async function getLaunchPadsFunc() {
    setLoading(true)
    try {
      await getLaunchPadsIntroduction()
      const res = await getLaunchPads()
      const cid = chainId || NETWORK_CHAIN_ID
      if (res) {
        dispatch(
          setLaunchpads({
            chainId: cid,
            data: res,
          })
        )
      }
    } catch (e) {
      console.error('useGetLaunchpads fetch failed')
      console.error(e)
    }
    setLoading(false)
  }

  useEffect(() => {
    getLaunchPadsFunc()
    let interval = setInterval(() => {
      getLaunchPadsFunc()
    }, 15000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  const pads = useMemo(() => {
    return launchpad.pads ? launchpad.pads[(chainId || NETWORK_CHAIN_ID) as ChainId] || [] : []
  }, [launchpad, chainId])

  const introduction = useMemo(() => {
    return launchpad.introduction ? launchpad.introduction[(chainId || NETWORK_CHAIN_ID) as ChainId] || {} : {}
  }, [launchpad, chainId])
  return {
    pads,
    loading,
    getPads: getLaunchPadsFunc,
    introduction,
  }
}
