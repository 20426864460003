import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import TopTokenList from 'components/Infos/TokenList'
import { TYPE } from 'theme'
import Panel from 'components/Infos/Panel'
import { useAllTokenData } from 'hooks/infos/TokenData'
import { PageWrapper, FullWrapper } from 'components/Infos/index'
import { RowBetween } from 'components/Row'
// import Search from '../components/Search'
import { useMedia } from 'react-use'

function AllTokensPage() {
  const { t } = useTranslation()
  const allTokens = useAllTokenData()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const below600 = useMedia('(max-width: 800px)')

  return (
    <PageWrapper>
      <FullWrapper>
        <RowBetween>
          <TYPE.title fontSize={26}>{t('analytics.all_tokens')}</TYPE.title>
          {/* {!below600 && <Search small={true} />} */}
        </RowBetween>
        <Panel style={{ marginTop: '6px', padding: below600 ? '1rem 0 0 0' : '' }}>
          <TopTokenList tokens={allTokens} itemMax={50} />
        </Panel>
      </FullWrapper>
    </PageWrapper>
  )
}

export default AllTokensPage
