import { Box as RebassBox } from 'rebass'
import styled, { css } from 'styled-components'

const panelPseudo = css`
  :after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 10px;
  }

  @media only screen and (min-width: 40em) {
    :after {
      content: unset;
    }
  }
`

const Panel = styled(RebassBox)<{
  hover?: boolean
  background?: string
  area?: string
  grouped?: boolean
  rounded?: boolean
  last?: boolean
  borderRadius?: string
}>`
  position: relative;
  background: ${({ theme }) => theme.v2.bg02};
  padding: 30px 24px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '16px')};
  border: 1px solid ${({ theme }) => theme.v2.bg02};
  :hover {
    cursor: ${({ hover }) => hover && 'pointer'};
  }

  ${props => props.background && `background-color: ${props.background};`}

  ${props => (props.area ? `grid-area: ${props.area};` : null)}

  ${props =>
    props.grouped &&
    css`
      @media only screen and (min-width: 40em) {
        &:first-of-type {
          border-radius: 20px 20px 0 0;
        }
        &:last-of-type {
          border-radius: 0 0 20px 20px;
        }
      }
    `}

  ${props =>
    props.rounded &&
    css`
      border-radius: 8px;
      @media only screen and (min-width: 40em) {
        border-radius: 10px;
      }
    `};

  ${props => !props.last && panelPseudo}
`

export default Panel

// const Panel = styled.div`
//   width: 100%;
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   border-radius: 12px;
//   background-color: ${({ theme }) => theme.advancedBG};
//   padding: 1.25rem;
//   box-sizing: border-box;
//   box-shadow: 0 1.1px 2.8px -9px rgba(0, 0, 0, 0.008), 0 2.7px 6.7px -9px rgba(0, 0, 0, 0.012),
//     0 5px 12.6px -9px rgba(0, 0, 0, 0.015), 0 8.9px 22.6px -9px rgba(0, 0, 0, 0.018),
//     0 16.7px 42.2px -9px rgba(0, 0, 0, 0.022), 0 40px 101px -9px rgba(0, 0, 0, 0.03);
// `
