/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import { ButtonMetis, ButtonOutlined } from 'components/Button'
import Value from 'components/Value'

import { ReactComponent as Close } from 'assets/images/x.svg'
import { TYPE } from 'theme'
import { LaunchPad, PadType } from 'gql/microservices/pads'
import { Contract } from 'ethers'
import BigNumber from 'bignumber.js'
import TokenImage from 'components/TokenImage'
import { ERC20_ABI } from 'constants/abis/erc20'
import { useContractByAbiAddr } from 'hooks/contract/useContract'
import { useActiveWeb3React } from 'hooks'
import useSend from 'state/contract/hooks/useSend'
import useTokenApprove from 'hooks/contract/useTokenApprove'
import { getTokenImage } from 'utils'
import { NETWORK_CHAIN_ID } from 'connectors'
import { WrappedTokenInfo } from 'state/lists/hooks'
import { ChainId } from '@netswap/sdk'

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`

const LaunchpadSection = styled.div`
  padding: 32px 40px;
  position: relative;
  ${({ theme }) => theme.flexColumnNoWrap}
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 24px 12px;
  `}
`

const CloseIcon = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
  color: #c42a61;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    right: 16px;
    top: 16px;
  `}
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
const CloseColor = styled(Close)`
  path {
    stroke: '${({ theme }) => theme.text4}';
  }
`
const LaunchpadTitle = styled(TYPE.white)`
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
`
const LaunchpadNoteOne = styled(TYPE.white)`
  font-size: 14px;
  line-height: 20px;
  max-width: 310px;
  word-break: break-word;
`

const LaunchpadCon = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  width: 100%;
  margin: 39px auto 32px;

  .launchpadConTitle {
    height: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    line-height: 20px;
    margin: 0;
  }
`

const LaunchpadSolidCon = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  justify-content: space-between;
  padding: 12px 16px;
  background: #221232;
  border-radius: 8px;
  align-items: center;
  margin: 16px 0;
  &.outlineTransParentBg {
    background: transparent;
    border: 1px solid #331b4b;
  }
  &.flexColumn {
    ${({ theme }) => theme.flexColumnNoWrap}
  }
  .launchpadConLeft {
    ${({ theme }) => theme.flexRowNoWrap}
    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }

  .launchpadConRight {
    ${({ theme }) => theme.flexRowNoWrap}
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }
`

const LaunchpadTextHalfWhite = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  line-height: 20px;
`

const LaunchpadBorderCon = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  width: 100%;
  border-radius: 8px;
  border: 1px solid #331b4b;
  align-item: center;
  padding: 12px 16px;
`

const LaunchpadBorderSection = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  justify-content: space-between;
  margin-bottom: 16px;
`

const SmallBtnCon = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-flow: column-reverse;
  `}
`

const ConfirmButton = styled(ButtonMetis)`
  border-radius: 8px;
  width: 90%;
  max-width: 420px;
  margin: 0 auto;
  height: 64px;
  margin-bottom: 0;
  font-size: 20px;
`

const SmallConfirmButton = styled(ButtonMetis)`
  height: 48px;
  font-size: 16px;
  width: 160px;
  border-radius: 8px;
  font-weight: 600;
  line-height: 48px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-left: 0 !important;
    margin-bottom: 16px;
  `}
`
const CancelButton = styled(ButtonOutlined)`
  height: 48px;
  font-size: 16px;
  width: 160px;
  border-radius: 8px;
  border: 1px solid #ffffff;
  font-weight: 600;
  line-height: 48px;
`

const InfoDefault = styled.div`
  display: flex;
  justify-content: flex-start;
`

const InfoConTopBottom = styled(InfoDefault)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-flow: column nowrap;
  `}
  p,
  a {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    margin: 0;
    line-height: 1.5;
    color: ${({ theme }) => theme.text1};
    ${({ theme }) => theme.mediaWidth.upToSmall`
      font-size: 16px;
      width: 100%;
      word-break: break-all;
    `}
  }
  a {
    color: #c42a61;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`

interface Props {
  isOpen: boolean
  onClose(): void
  padInfo: LaunchPad
  baseModal: Contract | null
  unlimitModal: Contract | null
  handleWithdrawSuccess?(): void
  userStakeAmount: BigNumber
  allocation: BigNumber
  shareRate: BigNumber
  additionFeeRate: number
}

export default function LaunchpadModal({
  isOpen,
  onClose,
  padInfo,
  baseModal,
  unlimitModal,
  userStakeAmount,
  handleWithdrawSuccess,
  allocation,
  shareRate,
  additionFeeRate
}: Props) {
  const [launchpadStep, setLaunchpadStep] = useState('init')
  const paymentTokenContact = useContractByAbiAddr(padInfo.paymentToken.address, ERC20_ABI)
  const { account, chainId } = useActiveWeb3React()
  const [paymentTokenBalance, setPaymentTokenBalance] = useState(new BigNumber(0))
  const send = useSend()
  const stakeContract = useMemo(() => {
    return padInfo.poolType === PadType.basic ? baseModal : unlimitModal
  }, [padInfo.poolType, baseModal, unlimitModal])
  async function getBalance(contract: any) {
    if (contract && account) {
      try {
        const res = await contract.balanceOf(account)
        return res
      } catch (e) {
        console.error(e)
      }
    }
  }

  async function getPaymentTokenBalance() {
    if (paymentTokenContact) {
      const res = await getBalance(paymentTokenContact)

      setPaymentTokenBalance(
        res?.toString() ? new BigNumber(res.toString()).shiftedBy(-padInfo.paymentToken.decimals) : new BigNumber(0)
      )
    }
  }

  useEffect(() => {
    getPaymentTokenBalance()
    let interval = setInterval(() => {
      getPaymentTokenBalance()
    }, 15000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [paymentTokenContact, account])

  const paymentUsdWithFee = useMemo(() => {
    return allocation
      .multipliedBy(padInfo.price)
      .multipliedBy(1 + additionFeeRate / 100)
      .toFixed()
  }, [allocation, padInfo.price, additionFeeRate])

  const isPaymentInsufficient = useMemo(() => {
    return paymentTokenBalance.isLessThan(paymentUsdWithFee)
  }, [paymentUsdWithFee, paymentTokenBalance])

  const { approved, approve } = useTokenApprove(
    {
      address: padInfo.paymentToken.address,
      decimals: padInfo.paymentToken.decimals,
      symbol: padInfo.paymentToken.symbol
    },
    stakeContract?.address || '',
    new BigNumber(
      allocation
        .multipliedBy(padInfo.price)
        .shiftedBy(padInfo.paymentToken.decimals)
        .toFixed(0)
    )
  )

  async function buy() {
    if (stakeContract) {
      await send({
        contract: stakeContract,
        method: 'cash',
        params: [padInfo.pid],
        pendingText: `Withdrawing ${userStakeAmount.toFixed()} ${
          padInfo.stakedToken.symbol
        } and claiming ${allocation} ${padInfo.saleToken.symbol}`,
        summary: `Withdraw ${userStakeAmount.toFixed()} ${padInfo.stakedToken.symbol} and claimed ${allocation} ${
          padInfo.saleToken.symbol
        }`,
        addTokenToMetamask: new WrappedTokenInfo(
          {
            chainId: chainId || (NETWORK_CHAIN_ID as ChainId),
            name: padInfo.saleToken.name,
            symbol: padInfo.saleToken.symbol,
            address: padInfo.saleToken.address,
            decimals: padInfo.saleToken.decimals,
            logoURI: getTokenImage(padInfo.saleToken.address)
          },
          []
        ),
        onSuccess() {
          if (handleWithdrawSuccess) {
            handleWithdrawSuccess()
          }
          onClose()
        }
      })
    }
  }

  return (
    <Modal isOpen={isOpen} onDismiss={onClose} minHeight={false} maxWidth={560} maxHeight={100}>
      <Wrapper>
        <LaunchpadSection>
          <CloseIcon onClick={onClose}>
            <CloseColor />
          </CloseIcon>
          {/* past modal start */}
          {launchpadStep === 'init' && (
            <>
              <LaunchpadTitle>Regarding your Launchpad allocation</LaunchpadTitle>
              <img
                style={{ margin: '57px 0 28px' }}
                src={require('assets/images/ic_launchshare.png')}
                alt="shareIcon"
              />
              <LaunchpadNoteOne marginBottom={'84px'}>
                You can purchase the amount of {padInfo.saleToken.symbol} according to your allocation.
                <br /> If you want to give up your allocation, please withdraw your stake after the event.
              </LaunchpadNoteOne>
              <SmallBtnCon>
                <CancelButton onClick={onClose}>Cancel</CancelButton>
                <SmallConfirmButton
                  style={{ marginLeft: '24px' }}
                  onClick={() => {
                    setLaunchpadStep('acknowledge')
                  }}
                >
                  Acknowledge
                </SmallConfirmButton>
              </SmallBtnCon>
            </>
          )}
          {launchpadStep === 'success' && (
            <>
              <LaunchpadTitle>Succeed</LaunchpadTitle>
              <img
                style={{ margin: '53px 0 37px' }}
                src={require('assets/images/ic_launchsucceed.png')}
                alt="successIcon"
              />
              <LaunchpadNoteOne marginBottom={'84px'}>
                You have successfully claim your {padInfo.saleToken.symbol} and withdraw your{' '}
                {padInfo.stakedToken.symbol} to your wallet
              </LaunchpadNoteOne>
              <SmallBtnCon>
                <CancelButton onClick={onClose}>Close</CancelButton>
              </SmallBtnCon>
            </>
          )}

          {launchpadStep === 'acknowledge' && (
            <>
              <LaunchpadTitle>
                Launchpad-{padInfo.poolType === PadType.basic ? 'Primary Tier' : 'Unlimited Tier'} share Purchasing
              </LaunchpadTitle>
              <LaunchpadCon>
                <h3 className="launchpadConTitle">Purchasing</h3>
                <LaunchpadSolidCon>
                  <div className="launchpadConLeft">
                    <TokenImage address={padInfo.saleToken.address} />
                    <TYPE.white fontSize={14} lineHeight={'24px'} margin={'0 16px 0 12px'}>
                      {padInfo.saleToken.symbol}
                    </TYPE.white>
                  </div>
                  <TYPE.white fontSize={14} lineHeight={'24px'} style={{ wordBreak: 'break-all' }}>
                    Amount: <Value value={allocation.toFixed()} />
                  </TYPE.white>
                </LaunchpadSolidCon>

                <h3 className="launchpadConTitle">Payment</h3>
                <LaunchpadSolidCon>
                  <div className="launchpadConLeft">
                    <TokenImage address={padInfo.paymentToken.address} />
                    <TYPE.white fontSize={14} lineHeight={'24px'} margin={'0 16px 0 12px'}>
                      {padInfo.paymentToken.symbol}
                    </TYPE.white>
                    <LaunchpadTextHalfWhite>Balance: {paymentTokenBalance.toFixed()}</LaunchpadTextHalfWhite>
                  </div>
                  <TYPE.white fontSize={14} lineHeight={'24px'}>
                    <Value value={allocation.multipliedBy(padInfo.price).toNumber()} />
                  </TYPE.white>
                </LaunchpadSolidCon>

                <h3 className="launchpadConTitle">Withdraw</h3>
                <LaunchpadSolidCon>
                  <div className="launchpadConLeft">
                    <TokenImage address={padInfo.stakedToken.address} />
                    <TYPE.white fontSize={14} lineHeight={'24px'} margin={'0 16px 0 12px'}>
                      {padInfo.stakedToken.symbol}
                    </TYPE.white>
                  </div>
                  <TYPE.white fontSize={14} lineHeight={'24px'}>
                    <Value value={userStakeAmount.toNumber()} />
                  </TYPE.white>
                </LaunchpadSolidCon>

                <div style={{ minHeight: '212px' }}>
                  <LaunchpadBorderCon>
                    <LaunchpadBorderSection>
                      <TYPE.white fontSize={14} lineHeight={'20px'}>
                        Price per {padInfo.saleToken.symbol}:
                      </TYPE.white>
                      <TYPE.white fontSize={14} lineHeight={'20px'}>
                        $<Value value={padInfo.price} />
                      </TYPE.white>
                    </LaunchpadBorderSection>

                    <LaunchpadBorderSection>
                      <TYPE.white fontSize={14} lineHeight={'20px'}>
                        Share of total
                      </TYPE.white>
                      <TYPE.white fontSize={14} lineHeight={'20px'}>
                        {shareRate.lt(0.01) && shareRate.gt(0) ? `< 0.01` : shareRate.toFixed(2)}%
                      </TYPE.white>
                    </LaunchpadBorderSection>

                    {padInfo.poolType === PadType.unlimited && (
                      <>
                        <LaunchpadBorderSection>
                          <TYPE.white fontSize={14} lineHeight={'20px'}>
                            Additional fee
                          </TYPE.white>
                          <TYPE.white fontSize={14} lineHeight={'20px'}>
                            {additionFeeRate}%
                          </TYPE.white>
                        </LaunchpadBorderSection>
                        <LaunchpadBorderSection style={{ justifyContent: 'flex-end' }}>
                          <LaunchpadTextHalfWhite>
                            {allocation
                              .multipliedBy(padInfo.price)
                              .multipliedBy(additionFeeRate)
                              .dividedBy(100)
                              .toFixed(3)}{' '}
                            {padInfo.paymentToken.symbol}
                          </LaunchpadTextHalfWhite>
                        </LaunchpadBorderSection>
                      </>
                    )}

                    <LaunchpadBorderSection
                      style={{ padding: '15px 0', borderTop: '1px solid #331b4b', marginBottom: '0px' }}
                    >
                      <TYPE.white fontSize={14} lineHeight={'20px'}>
                        Total to pay
                      </TYPE.white>
                      <TYPE.white fontSize={14} lineHeight={'20px'}>
                        <Value
                          value={allocation
                            .multipliedBy(padInfo.price)
                            .multipliedBy(
                              1 + (padInfo.poolType === PadType.unlimited ? Number(additionFeeRate) / 100 : 0)
                            )
                            .toFixed()}
                        />{' '}
                        {padInfo.paymentToken.symbol}
                      </TYPE.white>
                    </LaunchpadBorderSection>

                    <LaunchpadBorderSection
                      style={{ paddingTop: '15px', borderTop: '1px solid #331b4b', marginBottom: '0px' }}
                    >
                      <TYPE.white fontSize={14} lineHeight={'20px'}>
                        Total to receive
                      </TYPE.white>
                      <TYPE.white fontSize={14} lineHeight={'20px'}>
                        <Value value={allocation.toFixed()} /> {padInfo.saleToken.symbol}
                      </TYPE.white>
                    </LaunchpadBorderSection>
                  </LaunchpadBorderCon>
                </div>
                <InfoConTopBottom>
                  {isPaymentInsufficient && (
                    <p>
                      <TYPE.error fontSize={14} error={true}>
                        Insufficient {padInfo.paymentToken.symbol} balance
                      </TYPE.error>
                    </p>
                  )}
                </InfoConTopBottom>
              </LaunchpadCon>

              <ConfirmButton
                onClick={() => {
                  if (approved) {
                    buy()
                  } else {
                    approve()
                  }
                }}
                disabled={isPaymentInsufficient}
              >
                {approved ? 'Confirm' : 'Approve'}
              </ConfirmButton>
            </>
          )}
        </LaunchpadSection>
      </Wrapper>
    </Modal>
  )
}
