/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { isMobile } from 'react-device-detect'
import { InputBase } from '@material-ui/core'

import Modal from 'components/CustomModal/modal'
import { AssetsPool, Period } from 'gql/microservices/assets-pools'
import useTokenApprove from 'hooks/contract/useTokenApprove'
import { AuthButton } from 'components/Button'
import BigNumber from 'bignumber.js'
import TokenImage from 'components/TokenImage'
import AssetStakeABI from 'constants/assets-farm/assets-stake.json'
import { useContractByAbiAddr } from 'hooks/contract/useContract'
import { useActiveWeb3React } from 'hooks'
import useSend from 'state/contract/hooks/useSend'
import { TYPE } from 'theme'

import { RowBetween } from 'components/Row'
import TooltipInfo from 'components/TooltipInfo'
import Numeral from 'numeral'
import { useAppSelector } from 'state'
import { NETWORK_CHAIN_ID } from 'connectors'

const Wrapper = styled.div`
  width: 298px;
  margin-top: 20px;
  margin-right: 22px;
`

const Title = styled.div`
  height: 42px;

  border-radius: 13px 13px 0px 0px;
  width: 282px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.v2.bg04};
`

const Content = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 14px;
  border-radius: 13px;
  background: ${({ theme }) => theme.v2.bg02};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`

const TokenBanner = styled(TokenImage)`
  margin: 0 auto;
  transform: translateY(-10px);
`

const PoolStatus = styled.div`
  height: 26px;
  background: ${({ theme }) => theme.v2.bg04};
  border-radius: 13px;
  box-sizing: border-box;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
`

const RewardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.v2.bg04};
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  height: 40px;
  margin-top: 20px;
`

const FlexRowCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const GiftImg = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 10px;
`

const ActionButton = styled(AuthButton)`
  border-radius: 10px;
  margin-top: 20px;
  height: 40px;
`

const SwapLink = styled(NavLink)`
  margin-left: 0 !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.v2.t05};
  text-decoration: none;
`

const Timer = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  transform: translateY(-10px);
`

const Input = styled(InputBase)`
  input {
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`

const InputWrapper = styled.div`
  border-radius: 8px;
  margin-top: 12px;
  height: 46px;
  background: ${({ theme }) => theme.v2.bg04};
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    color: ${({ theme }) => theme.v2.t01};
    &::placeholder {
      ${({ theme }) => theme.v2.t02};
    }
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
     padding: 8px 16px;
  `};
`
const MaxBtn = styled.button`
  width: 50px;
  height: 32px;

  border-radius: 12px;

  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.v2.t01};
  border: none;
  background: ${({ theme }) => theme.v2.bg02};
  cursor: pointer;
`

const UnstakeBtn = styled.button`
  border: none;
  font-size: 16px;
  color: ${({ theme }) => theme.v2.t05};
  line-height: 22px;
  background: transparent;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    text-align: center;
    margin: 0 auto;
    display: block;
  `};
`

const StakeModalWrapper = styled.div`
  padding: 20px 12px;
  max-width: 560px;
  display: flex;
  flex-flow: column wrap;
  text-align: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 100%;
    > div {
      width: 100%;
    }
    h3 {
      font-size: 1.6rem !important;
      line-height: 2rem;
    }
  `};
  h2,
  h3 {
    margin: 0;
    font-weight: 600;
    color: ${({ theme }) => theme.v2.t01};
  }
  h2 {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 16px;
  }
  h3 {
    font-size: 40px;
    line-height: 56px;
  }
  span {
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.v2.t01};
    line-height: 20px;
  }
`
const StakeModalCon = styled.div`
  margin-top: 48px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 24px;
  `};

  h3 {
    word-break: break-word;
  }
`
const StakeModalNote = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.v2.t02};
  line-height: 20px;
  margin: 40px 0;
  text-align: left;
`
const StakeClaimBtn = styled(AuthButton)`
  width: 100%;
  height: 64px;

  border-radius: 8px;
  font-size: 20px;
  font-weight: 600;
  line-height: 64px;
  border: none;
  max-width: 420px;
  cursor: pointer;
  margin: 0 auto;
`

interface Props {
  data: AssetsPool
  addUserPool(id: string): void
  removeUserPool(id: string): void
}

export default function PoolItem({ data, addUserPool, removeUserPool }: Props) {
  const { account } = useActiveWeb3React()
  const [modalOpen, setModalOpen] = useState(false)
  const [inputAmount, setInputAmount] = useState(new BigNumber(0))
  const stakeContract = useContractByAbiAddr(data.stakeContractAddress, AssetStakeABI)
  const stakeTokenContract = useContractByAbiAddr(data.stakeToken.address, AssetStakeABI)
  const [userStakeTokenBalance, setUserStakeTokenBalance] = useState(new BigNumber(0))
  const [stakeBalance, setStakeBalance] = useState(new BigNumber(0))
  const [rewardBalance, setRewardBalance] = useState(new BigNumber(0))

  const { blockTime } = useAppSelector(state => state.application)
  const { chainId } = useActiveWeb3React()
  const send = useSend()
  const { approved, approve } = useTokenApprove(
    {
      address: data.stakeToken.address,
      decimals: data.stakeToken.decimals,
      symbol: data.stakeToken.symbol
    },
    data.stakeContractAddress,
    inputAmount
  )
  const handleModalClose = useCallback(() => {
    setModalOpen(false)
  }, [])

  const isInsufficienBalance = useMemo(() => {
    return inputAmount.isGreaterThan(userStakeTokenBalance)
  }, [inputAmount, userStakeTokenBalance])

  async function getBalance(contract: any) {
    if (contract && account) {
      try {
        const res = await contract.balanceOf(account)
        return res
      } catch (e) {
        console.error(e)
      }
    }
  }

  async function getStakeTokenBalance() {
    const res = await getBalance(stakeTokenContract)

    setUserStakeTokenBalance(
      res?.toString() ? new BigNumber(res.toString()).shiftedBy(-data.stakeToken.decimals) : new BigNumber(0)
    )
  }

  async function getStakeBalance() {
    if (stakeContract && account) {
      try {
        const res = await stakeContract.balanceOf(account)
        setStakeBalance(
          res?.toString() ? new BigNumber(res.toString()).shiftedBy(-data.stakeToken.decimals) : new BigNumber(0)
        )
      } catch (e) {
        console.error(e)
      }
    }
  }

  async function getRewardBalance() {
    if (stakeContract && account) {
      try {
        const res = await stakeContract.earned(account)

        const reward = res?.toString()
          ? new BigNumber(res.toString()).shiftedBy(-data.rewardToken.decimals)
          : new BigNumber(0)
        setRewardBalance(reward)
        return reward
      } catch (e) {
        console.error(e)
      }
    }
    return new BigNumber(0)
  }

  //   async function getPoolEndTime() {
  //     if (stakeContract) {
  //       try {
  //         const res = await stakeContract.periodFinish()
  //         if (res?.toNumber()) {
  //           setPoolEndTime(res.toNumber() * 1000)
  //         }
  //       } catch (e) {
  //         console.error(e)
  //       }
  //     }
  //   }

  useEffect(() => {
    let interval: any
    if (stakeTokenContract && account) {
      getStakeTokenBalance()
      getRewardBalance()

      interval = setInterval(() => {
        getStakeTokenBalance()
        getRewardBalance()
      }, 15000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [stakeTokenContract, account])

  useEffect(() => {
    getStakeBalance()
  }, [stakeContract, account])

  //   useEffect(() => {
  //     let interval: any
  //     if (stakeContract) {
  //       getPoolEndTime()
  //       interval = setInterval(() => {
  //         getPoolEndTime()
  //       }, 60000)
  //     }

  //     return () => {
  //       if (interval) {
  //         clearInterval(interval)
  //       }
  //     }
  //   }, [stakeContract])

  async function handleMax() {
    setInputAmount(userStakeTokenBalance)
  }

  async function stake() {
    await send({
      contract: stakeContract,
      method: 'stake',
      params: [inputAmount.shiftedBy(data.stakeToken.decimals).toFixed()],
      pendingText: `Staking ${inputAmount.toFixed()} ${data.stakeToken.symbol}`,
      summary: `Staked ${inputAmount.toFixed()} ${data.stakeToken.symbol} to earn ${data.rewardToken.symbol}`,
      onSuccess() {
        getStakeTokenBalance()
        getStakeBalance()
        getRewardBalance()
        setInputAmount(new BigNumber(0))
      }
    })
  }

  async function Unstake() {
    if (stakeBalance.toNumber()) {
      send({
        contract: stakeContract,
        method: 'exit',
        params: [],
        pendingText: `UnStaking ${stakeBalance.toFixed()} ${data.stakeToken.symbol}`,
        summary: `Unstake ${stakeBalance.toFixed()} ${data.stakeToken.symbol}`,
        onSuccess() {
          getStakeTokenBalance()
          getStakeBalance()
          getRewardBalance()
          handleModalClose()
        }
      })
    }
  }

  const isEnded = useMemo(() => {
    return data.period === Period.ended
  }, [data.period])

  const stakeButtonDisable = useMemo(() => {
    return !inputAmount.toNumber() || isInsufficienBalance || isEnded
  }, [inputAmount, isInsufficienBalance, isEnded])

  const actionButtonText = useMemo(() => {
    if (isInsufficienBalance) {
      return 'Insufficient Balance'
    }

    if (approved) {
      return 'Stake'
    } else {
      return 'Approve'
    }
  }, [approved, isInsufficienBalance])

  const actionButtonDisabled = useMemo(() => {
    return stakeButtonDisable || isInsufficienBalance
  }, [stakeButtonDisable, isInsufficienBalance])

  const handleActionButtonClick = () => {
    if (!approved) {
      approve()
    } else {
      stake()
    }
  }

  const countDown = useMemo(() => {
    if (data.period === Period.ongoing && blockTime[chainId || NETWORK_CHAIN_ID]) {
      const endTime = data.periodFinish
      const now = blockTime[chainId || NETWORK_CHAIN_ID]
      const leftTime = endTime - now
      const days = Math.floor(leftTime / 86400000)
      const hours = Math.floor((leftTime % 86400000) / 3600000)
      const minutes = Math.floor((leftTime % 3600000) / 60000)

      return `${days}d: ${hours}h: ${minutes}m`
    }

    return '--'
  }, [data.period, data.periodFinish, blockTime, chainId])

  useEffect(() => {
    if (stakeBalance.isGreaterThan(0)) {
      addUserPool(data.id)
    } else {
      removeUserPool(data.id)
    }
  }, [stakeBalance, data.id])

  return (
    <React.Fragment>
      <Wrapper className="poolItem">
        <Title>
          {data.period === Period.ended && <TYPE.desc fontSize={14}>Withdraw only</TYPE.desc>}
          {data.period === Period.prepare && (
            <TYPE.desc color="t05" fontSize={14}>
              Paticipate in Lauch event
            </TYPE.desc>
          )}
          {data.period === Period.ongoing && (
            <>
              <Timer src={require('assets/images/stake/timer.png')} />
              <TYPE.desc fontSize={14}>End &nbsp;&nbsp;</TYPE.desc>
              <TYPE.v2Main fontSize={14}>{countDown}</TYPE.v2Main>
            </>
          )}
        </Title>
        <Content>
          <TokenBanner size="46px" address={data.rewardToken.address} />
          <TYPE.v2Main fontSize={18}>Earn {data.rewardToken.symbol}</TYPE.v2Main>
          <PoolStatus>
            <TYPE.v2Main fontSize={12}>{data.period}</TYPE.v2Main>
          </PoolStatus>
          <RowBetween style={{ alignItems: 'flex-start' }}>
            <TYPE.desc fontSize={14} style={{ display: 'flex', alignItems: 'center' }}>
              APY <TooltipInfo text="Reward APY are calcuated in real time. " />
            </TYPE.desc>
            <TYPE.v2Main fontSize={16}>{Numeral(!isEnded ? data.apy : 0).format('0,0.[00]')}%</TYPE.v2Main>
          </RowBetween>
          <RowBetween style={{ marginTop: '8px' }}>
            <TYPE.desc fontSize={14} style={{ display: 'flex', alignItems: 'center' }}>
              TVL <TooltipInfo text="Total value of the funds in this pool" />
            </TYPE.desc>
            <TYPE.v2Main fontSize={16}>{Numeral(data.tvl).format('$0,0')}</TYPE.v2Main>
          </RowBetween>
          <RewardWrapper>
            <FlexRowCenter>
              <GiftImg src={require('assets/images/stake/gift.png')} />
              <TYPE.v2Main fontSize={14}>Reward</TYPE.v2Main>
            </FlexRowCenter>
            <FlexRowCenter>
              <TokenImage size="16px" style={{ marginRight: '6px' }} address={data.rewardToken.address} />
              <TYPE.v2Main fontSize={12}>{data.rewardToken.symbol}</TYPE.v2Main>
            </FlexRowCenter>
          </RewardWrapper>
          <RowBetween style={{ marginTop: '20px' }}>
            <TYPE.v2Main fontSize={14}>To Stake</TYPE.v2Main>
            <UnstakeBtn onClick={() => setModalOpen(!modalOpen)}>Unstake & Claim</UnstakeBtn>
          </RowBetween>
          <InputWrapper>
            <Input
              fullWidth
              autoFocus={!isMobile}
              placeholder="0.0"
              type="number"
              value={inputAmount.toNumber() ? inputAmount.toFixed() : ''}
              onChange={e => {
                setInputAmount(new BigNumber(e.currentTarget.value || 0))
              }}
            />
            <MaxBtn onClick={handleMax}>Max</MaxBtn>
          </InputWrapper>
          <ActionButton auth disabled={actionButtonDisabled} onClick={handleActionButtonClick}>
            {actionButtonText}
          </ActionButton>
          <RowBetween style={{ alignItems: 'flex-start', marginTop: '20px' }}>
            <TYPE.desc fontSize={12} style={{ display: 'flex', alignItems: 'center' }}>
              Available {data.stakeToken.symbol} Balance
            </TYPE.desc>
            <TYPE.v2Main fontSize={12}>{userStakeTokenBalance.toFixed(6, 0)}</TYPE.v2Main>
          </RowBetween>
          <RowBetween style={{ alignItems: 'flex-start' }}>
            <SwapLink to={`/swap?outputCurrency=${data.stakeToken.address}`}>Get {data.stakeToken.symbol}</SwapLink>
          </RowBetween>
          <RowBetween style={{ alignItems: 'flex-start', marginTop: '8px' }}>
            <TYPE.desc fontSize={12} style={{ display: 'flex', alignItems: 'center' }}>
              Your Staked
            </TYPE.desc>
            <TYPE.v2Main fontSize={12}>
              ≈ {stakeBalance.toFixed(6)} {data.stakeToken.symbol}
            </TYPE.v2Main>
          </RowBetween>
          <RowBetween style={{ alignItems: 'flex-start', marginTop: '8px' }}>
            <TYPE.desc fontSize={12} style={{ display: 'flex', alignItems: 'center' }}>
              Unclaimed reward
            </TYPE.desc>
            <TYPE.v2Main fontSize={12}>
              ≈ {rewardBalance.toFixed(6)} {data.rewardToken.symbol}
            </TYPE.v2Main>
          </RowBetween>
        </Content>
      </Wrapper>
      <Modal isOpen={modalOpen} onClose={handleModalClose}>
        <StakeModalWrapper>
          <h2>Unstake & Claim</h2>
          <StakeModalCon>
            <h3>{stakeBalance.toFixed()}</h3>
            <span>Staked {data.stakeToken.symbol}</span>
          </StakeModalCon>
          <StakeModalCon>
            <h3>{rewardBalance.toFixed()}</h3>
            <span>Unclaimed {data.rewardToken.symbol}</span>
          </StakeModalCon>
          <StakeModalNote>
            When you withdraw,your {data.rewardToken.symbol} is claimed and your {data.stakeToken.symbol} is returned to
            you.You will no longer earn {data.rewardToken.symbol} rewards on this {data.stakeToken.symbol}.
          </StakeModalNote>
          <StakeClaimBtn auth onClick={Unstake} disabled={!stakeBalance.toNumber()}>
            Unstake & Claim
          </StakeClaimBtn>
        </StakeModalWrapper>
      </Modal>
    </React.Fragment>
  )
}
