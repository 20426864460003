import React, { useState } from 'react'
import styled from 'styled-components'
import CustomModal from 'components/CustomModal'
import { AutoColumn } from 'components/Column'
import { ExternalLinkV2, TYPE } from 'theme'
import { ButtonV2, ButtonV2Transparent } from 'components/Button'
import { RowBetween } from 'components/Row'

const Wrapper = styled.div`
  width: 520px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: calc(100% - 2rem)
  `}
`

const LinkButton = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.v2.t09};
  text-decoration: underline;
  display: inline-block;
`

const GuideBanner = styled.img`
  width: 100%;
`

const ButtonRow = styled(RowBetween)`
  width: 80%;
  margin: 0 auto;

  > button {
    width: 45%;
    height: 48px;

    border-radius: 4px;
  }
`

interface Props {
  isOpen: boolean
  onClose(): void
}

export default function FarmMigrateGuideModal({ isOpen, onClose }: Props) {
  const [where, setWhere] = useState('init')

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={() => {
        onClose()
        setWhere('init')
      }}
    >
      <Wrapper>
        {where === 'init' && (
          <AutoColumn gap="lg" justify="center">
            <TYPE.v2Main fontSize={16}>Upgrade to Boost Farms!</TYPE.v2Main>
            <TYPE.desc>The new Boost reward is coming!</TYPE.desc>
            <TYPE.desc textAlign="center">
              If you have LP tokens staked in non-boost farms, please migrate them to boost farms(if they exist)!{' '}
              <LinkButton
                onClick={() => {
                  setWhere('first-page')
                }}
              >
                How to migrate?
              </LinkButton>
            </TYPE.desc>
            <GuideBanner src={require('assets/images/farm/guide-banner.png')} />
            <ButtonV2 style={{ width: '240px' }} onClick={onClose}>
              Acknowledge
            </ButtonV2>
            <ExternalLinkV2 href="https://docs.netswap.io/use-guide/farm" target="_blank">
              Learn more about Boost Farm
            </ExternalLinkV2>
          </AutoColumn>
        )}
        {where === 'first-page' && (
          <AutoColumn gap="sm">
            <TYPE.v2Main>1. Select the non-boost farm you staked</TYPE.v2Main>
            <GuideBanner src={require('assets/images/farm/guide-pool-info.png')} />
            <TYPE.v2Main>2. Click into the farm detail page and then unstake all of your LP tokens</TYPE.v2Main>
            <GuideBanner src={require('assets/images/farm/guide-pool-stake.png')} />
            <ButtonRow>
              <ButtonV2Transparent
                onClick={() => {
                  setWhere('init')
                }}
              >
                Previous step
              </ButtonV2Transparent>
              <ButtonV2
                onClick={() => {
                  setWhere('second-page')
                }}
              >
                Next step
              </ButtonV2>
            </ButtonRow>
          </AutoColumn>
        )}
        {where === 'second-page' && (
          <AutoColumn gap="sm">
            <TYPE.v2Main>3. Select the boost farm you want to join </TYPE.v2Main>
            <GuideBanner src={require('assets/images/farm/guide-boost-info.png')} />
            <TYPE.v2Main>
              4. Click into the farm detail page and then stake your LP tokens to get boost rewards with holding veNETT!
            </TYPE.v2Main>
            <GuideBanner src={require('assets/images/farm/guide-boost-stake.png')} />
            <ButtonRow>
              <ButtonV2Transparent
                onClick={() => {
                  setWhere('first-page')
                }}
              >
                Previous step
              </ButtonV2Transparent>
              <ButtonV2 onClick={onClose}>Acknowledge</ButtonV2>
            </ButtonRow>
          </AutoColumn>
        )}
      </Wrapper>
    </CustomModal>
  )
}
