/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { useHistory } from 'react-router-dom'
import { AutoColumn } from 'components/Column'
import { TYPE } from 'theme'
import TooltipInfo from 'components/TooltipInfo'
import Row, { RowBetween } from 'components/Row'
import TokenImage from 'components/TokenImage'
import { AuthButton } from 'components/Button'
import { useActiveWeb3React } from 'hooks'
import BigNumber from 'bignumber.js'
import { isMobile } from 'react-device-detect'
import { InputBase } from '@material-ui/core'
import RightBottomImage from 'assets/images/stake/v2/detail-right-bottom.png'
import ExploreFarmImage from 'assets/images/stake/v2/explore-farm.png'
import ExploreLaunchpadImage from 'assets/images/stake/v2/explore-launchpad.png'
import StakeNETTBGImage from 'assets/images/stake/v2/stake-nett-bg.png'
import Modal from 'components/CustomModal/modal'
import useTokenApprove from 'hooks/contract/useTokenApprove'
import { useGetNett } from 'hooks/contract/useGetContract'
import { useAppSelector } from 'state'
import { NETWORK_CHAIN_ID } from 'connectors'

const StakeWrapper = styled.div`
  width: 944px;
  background: ${({ theme }) => theme.v2.bg04};
  border-radius: 14px;
  box-sizing: border-box;
  padding: 24px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 24px;

  position: relative;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: calc(100vw - 2rem);
  padding: 1rem;
  flex-direction: column;
  `}
`

const StakeWrapperBG = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  width: 438px;
  height: 422px;
  background-image: url(${StakeNETTBGImage});
  background-position: bottom center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  transform: translateY(-269px);
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
  `}
`

const LeftBlocks = styled.div`
  width: 472px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  `}
`

const RightBlocks = styled.div`
  width: 400px;
  background: ${({ theme }) => theme.v2.bg02};
  box-sizing: border-box;
  padding: 18px 24px;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url(${RightBottomImage});
  background-position: bottom center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  margin-top: 1rem;
  `}
`

const Block = styled.div`
  border-radius: 14px;
  box-sizing: border-box;
  padding: 18px 24px;
  width: 100%;
  background: ${({ theme }) => theme.v2.bg02};
`

const StyledButtonV2 = styled(AuthButton)`
  width: 300px;
  margin: 0 auto;
  margin-top: 32px;
  height: 48px;
  &.authButton {
    max-width: unset !important;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  `}
`

const StakeTabWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 12px;
  justify-content: center;
  background-color: ${({ theme }) => theme.v2.bg06};
  width: 200px;

  /* height: 30px; */
  border-radius: 8px;
  box-sizing: border-box;
`

const StakeTab = styled.div`
  width: 50%;
  border-radius: 8px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.v2.t01};
  font-size: 14px;

  height: 30px;
  cursor: pointer;

  font-weight: 600;
  &.active {
    background-color: #d2cfd5;
    color: #000;
  }
`

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  padding: 12px 10px;
  width: 100%;
  height: 62px;
  background: ${({ theme }) => theme.v2.bg04};
  backdrop-filter: blur(13px);
  border-radius: 10px;
`

const ErrorMessage = styled.p`
  font-size: 12px;
  color: #fd4040;
  font-weight: 500;
  line-height: 14px;
  margin: 0 0 10px 0;
`

const MaxBtn = styled.button`
  width: 62px;
  height: 40px;
  background: ${({ theme }) => theme.v2.bg05};
  border-radius: 8px;
  border: none;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  line-height: 27px;
  cursor: pointer;
  flex-shrink: 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: fit-content;
    box-sizing: border-box;
    padding: 0 4px;
  `};
  margin-right: 16px;
`

const Input = styled(InputBase)`
  input {
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    color: ${({ theme }) => theme.v2.t01};
  }
`

const TipsInfo = styled.div`
  width: 100%;

  border-radius: 16px;
  box-sizing: border-box;
  padding: 8px 20px;

  font-size: 14px;
  background: ${({ theme }) => theme.v2.bg04};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-top: 1rem;
  `}
`

const ExploreWrapper = styled.div`
  width: 100%;
  cursor: pointer;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 18px 24px;
  background-position: center center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  &.farm {
    background-image: url(${ExploreFarmImage});
  }

  &.launchpad {
    background-image: url(${ExploreLaunchpadImage});
  }
  margin-top: 24px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding: 0.5rem;
  `}
`

const ExploreText = styled.div`
  height: 64px;
  border-radius: 32px;
  box-sizing: border-box;
  padding: 20px;

  font-size: 16px;
  color: #fff;
  background: rgb(27, 27, 48, 0.6);
`

const ExploreIcon = styled.div`
  width: 64px;

  background: rgb(27, 27, 48, 0.6);
  height: 64px;

  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: #fff;
  }
`

const StakeModalWrapper = styled.div`
  padding: 20px 12px;
  width: 420px;
  display: flex;
  flex-flow: column wrap;
  text-align: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 100%;
    > div {
      width: 100%;
    }
    h3 {
      font-size: 1.6rem !important;
      line-height: 2rem;
    }
  `};
  h2,
  h3 {
    margin: 0;
    font-weight: 600;
    color: ${({ theme }) => theme.v2.t01};
  }
  h2 {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 16px;
  }
  h3 {
    font-size: 40px;
    line-height: 56px;
  }
  span {
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.v2.t01};
    line-height: 20px;
  }
`
const StakeModalRow = styled.div`
  padding: 24px 0;

  &.border {
    border-top: 1px dashed ${({ theme }) => theme.v2.t01};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-top: 24px;
  `};

  h3 {
    word-break: break-word;
  }
`
const StakeModalNote = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.v2.t01};
  line-height: 20px;
  margin: 24px 0;
  text-align: left;

  border-radius: 20px;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.v2.bg06};
`

const Warning = styled.img`
  width: 84px;
  height: 84px;
`

const ProgressWrapper = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background: ${({ theme }) => theme.v2.bg04};
  position: relative;
  overflow: hidden;
  margin: 12px 0;
`

const Progress = styled.div<{ width: string }>`
  position: absolute;
  left: 0;
  width: ${({ width }) => (width ? width : 0)};
  height: 100%;
  background: linear-gradient(270deg, #ab83ff, #00cfff);
`

const ProgressTitle = styled(RowBetween)`
  font-size: 14px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: flex-end;
    `}
`

interface Props {
  type: 'stakingRewards' | 'wNETT' | 'veNETT'
  tvl?: string
  apy?: string
  stakeBalance: BigNumber
  stakeTokenUSD?: string
  unclaimedRewardBalace: BigNumber
  userRewardTokenBalance?: BigNumber
  rewardUSD?: string
  rewardToken: {
    symbol: string
    name: string
    address: string
    decimals: number
  }
  approveAddress: string
  userRewardPerDay?: number
  totalRewardTokenSupply?: string
  stake(props: { inputAmount: BigNumber; onSuccess?: () => void }): Promise<void>
  unStake(props: { inputAmount: BigNumber; onSuccess?: () => void }): Promise<void>
  claimPendingRewardToken?(props: { inputAmount: BigNumber; onSuccess?: () => void }): Promise<void>
  veNettSpeedConfig?: {
    endTime: number
    total: string
    progress: string
    current: string
  }
}

export default function StakeNett({
  type,
  tvl,
  apy,
  stakeBalance,
  stakeTokenUSD,
  rewardToken,
  unclaimedRewardBalace,
  rewardUSD,
  approveAddress,
  stake,
  unStake,
  userRewardPerDay,
  claimPendingRewardToken,
  userRewardTokenBalance,
  totalRewardTokenSupply,
  veNettSpeedConfig,
}: Props) {
  const { account, chainId } = useActiveWeb3React()
  const isWNett = type === 'wNETT'
  const isVeNeet = type === 'veNETT'
  const isRewardTokenPool = type === 'stakingRewards'
  const history = useHistory()
  const [isStake, setIsStake] = useState(true)

  const [inputValue, setInputValue] = useState('')
  const [userNettBalance, setUserNettBalance] = useState(new BigNumber(0))
  const [unStakeModalOpen, setUnStakeModalOpen] = useState(false)
  const [unStakeVeNettModalOpen, setUnStakeVeNettModalOpen] = useState(false)
  const nettTokenContract = useGetNett()

  const inputAmount = useMemo(() => {
    return new BigNumber(inputValue || 0)
  }, [inputValue])
  const { approved, approve } = useTokenApprove(
    {
      address: nettTokenContract?.address || '',
      decimals: 18,
      symbol: 'NETT',
    },
    approveAddress,
    inputAmount
  )

  async function getBalance(contract: any) {
    if (contract && account) {
      try {
        const res = await contract.balanceOf(account)
        return res
      } catch (e) {
        console.error(e)
      }
    }
  }
  async function getUserNettBalance() {
    const res = await getBalance(nettTokenContract)

    setUserNettBalance(res?.toString() ? new BigNumber(res.toString()).shiftedBy(-18) : new BigNumber(0))
  }

  useEffect(() => {
    let interval: any
    if (account) {
      getUserNettBalance()
      interval = setInterval(() => {
        getUserNettBalance()
      }, 15000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [chainId, account])

  const changeStakeUnstake = (name: string) => {
    if (!account) {
      return
    }
    if (name === 'stake') {
      if (!isStake) {
        setIsStake(true)
      }
    }
    if (name === 'unstake') {
      if (isStake) {
        setIsStake(false)
      }
    }
  }

  const errorMessage = useMemo(() => {
    if (
      (isStake && inputAmount.isGreaterThan(userNettBalance)) ||
      (!isStake && inputAmount.isGreaterThan(stakeBalance))
    ) {
      return 'Insufficient balance'
    }
    return ''
  }, [userNettBalance, stakeBalance, isStake, inputAmount])

  async function handleMax() {
    if (isStake) {
      setInputValue(userNettBalance.toFixed())
    } else {
      setInputValue(stakeBalance.toFixed())
    }
  }

  async function handleStake() {
    if (!approved) {
      await approve()
    } else {
      await stake({
        inputAmount,
        onSuccess() {
          getUserNettBalance()
          setInputValue('')
        },
      })
    }
  }
  async function handleUnStake() {
    await unStake({
      inputAmount,
      onSuccess() {
        getUserNettBalance()
        setInputValue('')
        if (isRewardTokenPool) {
          setUnStakeModalOpen(false)
        } else if (isVeNeet) {
          setUnStakeVeNettModalOpen(false)
        }
      },
    })
  }

  async function handleClaimPendingRewardToken() {
    if (claimPendingRewardToken) {
      await claimPendingRewardToken({
        inputAmount,
      })
    }
  }

  const { blockTime } = useAppSelector((state) => state.application)

  const endTimeCountDown = useMemo(() => {
    if (!veNettSpeedConfig?.endTime) return ''
    const endTime = veNettSpeedConfig.endTime * 1000
    const now = blockTime[chainId || NETWORK_CHAIN_ID]
    const leftTime = endTime - now
    if (leftTime <= 0) return ''
    const days = Math.floor(leftTime / 86400000)
    const hours = Math.floor((leftTime % 86400000) / 3600000)
    const minutes = Math.floor((leftTime % 3600000) / 60000)

    return `${days}d:${hours}h:${minutes}m`
  }, [veNettSpeedConfig?.endTime, blockTime, chainId])

  return (
    <>
      <StakeWrapper>
        {!isRewardTokenPool && <StakeWrapperBG />}
        <LeftBlocks>
          {tvl && (
            <Block>
              <AutoColumn gap="md">
                <TYPE.desc fontSize={16} style={{ display: 'flex' }}>
                  Total Staked{' '}
                  <TooltipInfo style={{ marginLeft: '8px' }} text="Total value of the funds in this pool" />
                </TYPE.desc>
                <TYPE.v2Main fontWeight="bold" fontSize={32}>
                  ≈ {tvl}
                </TYPE.v2Main>
              </AutoColumn>
            </Block>
          )}
          {apy && (
            <Block style={{ marginTop: '24px' }}>
              <AutoColumn gap="md">
                <TYPE.desc fontSize={16} style={{ display: 'flex' }}>
                  APR{' '}
                  <TooltipInfo
                    style={{ marginLeft: '8px' }}
                    text="The APR metric shows an annualized return that is forecasted"
                  />
                </TYPE.desc>
                <TYPE.v2Main fontWeight="bold" fontSize={32}>
                  {apy}%
                </TYPE.v2Main>
              </AutoColumn>
            </Block>
          )}
          {totalRewardTokenSupply && (
            <Block style={{ marginTop: '24px' }}>
              <AutoColumn gap="md">
                <TYPE.desc fontSize={16} style={{ display: 'flex' }}>
                  Total {rewardToken.symbol} Supply
                </TYPE.desc>
                <TYPE.v2Main fontWeight="bold" fontSize={32}>
                  {totalRewardTokenSupply} {rewardToken.symbol}
                </TYPE.v2Main>
              </AutoColumn>
            </Block>
          )}
          {!!veNettSpeedConfig && (
            <Block style={{ marginTop: '24px' }}>
              <AutoColumn gap="md">
                <RowBetween justify={!!veNettSpeedConfig.endTime ? 'between' : 'flex-start'}>
                  <TYPE.desc fontSize={16} style={{ display: 'flex' }}>
                    veNETT progress
                  </TYPE.desc>
                  {!!endTimeCountDown ? (
                    <TYPE.desc fontSize={12} color="t09" style={{ display: 'flex' }}>
                      {endTimeCountDown}
                    </TYPE.desc>
                  ) : stakeBalance.isGreaterThan(0) ? (
                    <TYPE.desc fontSize={12} color="t09" style={{ display: 'flex' }}>
                      2x Speed Stopped
                    </TYPE.desc>
                  ) : null}
                </RowBetween>
                <AutoColumn>
                  <ProgressTitle>
                    <Row style={{ width: 'fit-content' }}>
                      {veNettSpeedConfig.current} veNETT{' '}
                      <TooltipInfo
                        style={{ marginLeft: '4px', display: 'flex' }}
                        text="The amount of veNETT you already accrued"
                      />
                      {'   '}/ {veNettSpeedConfig.total} veNETT{' '}
                      <TooltipInfo
                        style={{ marginLeft: '4px', display: 'flex' }}
                        text="The maximum amount of veNETT you can accrue based on your staked NETT"
                      />
                    </Row>
                    <Row style={{ width: 'fit-content' }}>{veNettSpeedConfig.progress}% / 100%</Row>
                  </ProgressTitle>
                  <ProgressWrapper>
                    <Progress
                      width={
                        !!account && Number(veNettSpeedConfig.progress) < 5 && Number(veNettSpeedConfig.progress) > 0
                          ? '5%'
                          : `${veNettSpeedConfig.progress}%`
                      }
                    />
                  </ProgressWrapper>
                  {!!endTimeCountDown && (
                    <TYPE.desc fontSize={12} color="t09" style={{ display: 'flex' }}>
                      You are earning veNETT at 2x speed! Ends in {endTimeCountDown}
                    </TYPE.desc>
                  )}
                  {!endTimeCountDown && stakeBalance.isGreaterThan(0) && (
                    <TYPE.desc fontSize={12} style={{ display: 'flex' }}>
                      You need stake at lease {stakeBalance.multipliedBy(0.05).toFixed()} NETT again to activate 2x
                      speed accrual!
                    </TYPE.desc>
                  )}
                  {stakeBalance.isEqualTo(0) && (
                    <TYPE.desc fontSize={12} color="t09" style={{ display: 'flex' }}>
                      Earn 2X veNETT per day for your first deposit. Last for 15 days.
                    </TYPE.desc>
                  )}
                </AutoColumn>
              </AutoColumn>
            </Block>
          )}

          <Block style={{ marginTop: '24px' }}>
            <AutoColumn gap="md">
              <TYPE.desc fontSize={16} style={{ display: 'flex' }}>
                Your Staked
              </TYPE.desc>
              <Row>
                <TokenImage size="48px" style={{ marginRight: '16px' }} address={nettTokenContract?.address || ''} />
                <AutoColumn>
                  <TYPE.v2Main fontWeight="bold" fontSize={24}>
                    ≈ {stakeBalance.toFixed(6)} NETT
                  </TYPE.v2Main>
                  <TYPE.desc fontSize={14} style={{ display: 'flex' }}>
                    ≈ {stakeTokenUSD}
                  </TYPE.desc>
                </AutoColumn>
              </Row>
              <TYPE.desc fontSize={16} style={{ display: 'flex', marginTop: '18px' }}>
                Unclaimed Reward
              </TYPE.desc>
              <Row>
                <TokenImage size="48px" style={{ marginRight: '16px' }} address={rewardToken.address} />
                <AutoColumn>
                  <TYPE.v2Main fontWeight="bold" fontSize={24}>
                    ≈ {unclaimedRewardBalace?.toFixed(6)} {rewardToken.symbol}
                  </TYPE.v2Main>
                  {isRewardTokenPool && (
                    <TYPE.desc fontSize={14} style={{ display: 'flex' }}>
                      ≈ {rewardUSD}
                    </TYPE.desc>
                  )}
                  {(isWNett || isVeNeet) && (
                    <TYPE.desc fontSize={14} style={{ display: 'flex' }}>
                      ≈ {userRewardPerDay?.toFixed(3)} {rewardToken.symbol} per day
                    </TYPE.desc>
                  )}
                </AutoColumn>
              </Row>
            </AutoColumn>
            {isRewardTokenPool && (
              <StyledButtonV2 auth onClick={() => setUnStakeModalOpen(true)} disabled={!stakeBalance.toNumber()}>
                Unstake & Claim
              </StyledButtonV2>
            )}
            {(isWNett || isVeNeet) && claimPendingRewardToken && (
              <StyledButtonV2 auth onClick={handleClaimPendingRewardToken} disabled={unclaimedRewardBalace?.eq(0)}>
                Claim pending {rewardToken.symbol}
              </StyledButtonV2>
            )}
            {(isWNett || isVeNeet) && (
              <TYPE.desc
                fontSize={14}
                style={{ display: 'block', width: '100%', textAlign: 'center', marginTop: '12px' }}
              >
                Your {rewardToken.symbol} balance: ≈ {userRewardTokenBalance?.toFixed(3)}
              </TYPE.desc>
            )}
          </Block>
        </LeftBlocks>
        <RightBlocks>
          <div>
            {isRewardTokenPool ? (
              <TYPE.desc fontSize={16}>Stake</TYPE.desc>
            ) : (
              <StakeTabWrapper>
                <StakeTab className={isStake ? 'active' : ''} onClick={() => changeStakeUnstake('stake')}>
                  Stake
                </StakeTab>
                <StakeTab className={!isStake ? 'active' : ''} onClick={() => changeStakeUnstake('unstake')}>
                  UnStake
                </StakeTab>
              </StakeTabWrapper>
            )}
            <InputWrapper>
              <Input
                fullWidth
                autoFocus={!isMobile}
                placeholder="0.0"
                type="number"
                value={inputValue}
                onChange={(e) => {
                  if (Number(e.currentTarget.value) < 0 || e.currentTarget.value === '-') {
                    setInputValue('')
                    return
                  }
                  setInputValue(e.currentTarget.value || '')
                }}
              />
              <MaxBtn onClick={handleMax}>Max</MaxBtn>
              <TokenImage address={nettTokenContract?.address || ''} alt="NETTICON" />
              <TYPE.v2Main style={{ flexShrink: 0, marginLeft: '4px' }} fontSize={16}>
                NETT
              </TYPE.v2Main>
            </InputWrapper>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            {isStake && <TYPE.desc fontSize={14}>NETT Balance: {userNettBalance.toFixed()}</TYPE.desc>}
            {!isStake && <TYPE.desc fontSize={14}>You Staked: {stakeBalance.toFixed()} NETT</TYPE.desc>}
            {isStake && (
              <StyledButtonV2
                auth
                disabled={!inputValue || !!errorMessage}
                onClick={handleStake}
                style={{ height: '48px', marginTop: '16px' }}
              >
                {approved ? 'Stake' : 'Approve'}
              </StyledButtonV2>
            )}

            {!isStake && (
              <StyledButtonV2
                auth
                onClick={() => {
                  if (isRewardTokenPool) {
                    setUnStakeModalOpen(true)
                  } else if (isVeNeet) {
                    setUnStakeVeNettModalOpen(true)
                  } else {
                    handleUnStake()
                  }
                }}
                disabled={!inputValue || !!errorMessage}
                style={{ height: '48px', marginTop: '16px' }}
              >
                Unstake
              </StyledButtonV2>
            )}
          </div>
          <div>
            {isRewardTokenPool && (
              <TipsInfo>
                <h3>Stake Information</h3>
                <p>● Stake your NETT to earn token rewards that protocol received.</p>
                <p>● Every 30 days is an epoch and rewards are distributed per epoch.</p>
                <p>● You can exit the pool via ‘Unstake & Claim’ at any time.</p>
              </TipsInfo>
            )}
            {isWNett && (
              <TipsInfo>
                <h3>Stake Information</h3>
                <p>● wNETT is the only allocation credit used to participate in future Launchpad events.</p>
                <p>● The more wNETT you accrue, the higher allocation you can deposit for future Launchpad events.</p>
              </TipsInfo>
            )}
            {isVeNeet && (
              <TipsInfo>
                <h3>Stake Information</h3>
                <p>● veNETT will be the token used for future governance.</p>
                <p>{`● Stake NETT into veNETT to increase your NETT rewards in 'Boost' Farms.`}</p>
                <p>{`● When you claim veNETT you will automatically apply a 'Boost APR'`}.</p>
                <p>{`● 'Boost APR' will be an additional yield earned on top of 'NETT APR' and 'LP APR'.`}</p>
                <p>
                  ● The amount of veNETT you have will determine your share of the boosted rewards. If you unstake any
                  amount of NETT from veNETT you will lose all of your accrued veNETT.
                </p>
              </TipsInfo>
            )}
            {isWNett && (
              <ExploreWrapper
                className="launchpad"
                onClick={() => {
                  history.push('/launchpad-v2')
                }}
              >
                <ExploreText>Explore Launchpad V2</ExploreText>
                <ExploreIcon>
                  <ChevronRightIcon />
                </ExploreIcon>
              </ExploreWrapper>
            )}

            {isVeNeet && (
              <ExploreWrapper
                className="farm"
                onClick={() => {
                  history.push('/farm')
                }}
              >
                <ExploreText>Explore Farms</ExploreText>
                <ExploreIcon>
                  <ChevronRightIcon />
                </ExploreIcon>
              </ExploreWrapper>
            )}
          </div>
        </RightBlocks>
      </StakeWrapper>
      <Modal
        isOpen={unStakeModalOpen}
        onClose={() => {
          setUnStakeModalOpen(false)
        }}
      >
        <StakeModalWrapper>
          <h2>Unstake & Claim</h2>
          <StakeModalRow>
            <h3> ≈ {stakeBalance.toFixed(6)}</h3>
            <span>Staked NETT</span>
          </StakeModalRow>
          <StakeModalRow className="border">
            <h3>
              ≈ {unclaimedRewardBalace?.toFixed(6)} {rewardToken.symbol}{' '}
            </h3>
            <span>Reward</span>
          </StakeModalRow>
          <StakeModalNote>
            When you withdraw,your Reward is claimed and your NETT is returned to you. You will no longer earn staking
            rewards on this NETT.
          </StakeModalNote>
          <AuthButton
            auth
            onClick={handleUnStake}
            disabled={!!errorMessage}
            style={{ height: '48px', marginTop: '16px' }}
          >
            Unstake
          </AuthButton>
        </StakeModalWrapper>
      </Modal>
      <Modal
        isOpen={unStakeVeNettModalOpen}
        onClose={() => {
          setUnStakeVeNettModalOpen(false)
        }}
      >
        <StakeModalWrapper>
          <AutoColumn justify="center" gap="24px">
            <h2>{isWNett ? 'Unstake & Claim' : 'Unstake'}</h2>
            <Warning src={require('assets/images/stake/v2/warning.png')} />
            <TYPE.main>When you withdraw, your veNEET balance and pending veNETT will be burned.</TYPE.main>
            <AuthButton
              auth
              onClick={handleUnStake}
              disabled={!inputValue || !!errorMessage}
              style={{ height: '48px', marginTop: '16px' }}
            >
              Confirm
            </AuthButton>
          </AutoColumn>
        </StakeModalWrapper>
      </Modal>
    </>
  )
}
