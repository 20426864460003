/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import styled from 'styled-components'
import { AssetsPool } from 'gql/microservices/assets-pools'
import Pool from './PoolItem'
import { TYPE } from 'theme'
import TokenImage from 'components/TokenImage'
import { AuthButton } from 'components/Button'
import { useHistory } from 'react-router-dom'

interface PoolProps {
  pools: AssetsPool[]
  title?: string
  emptyHide?: boolean
}

const Wrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const ListWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: center;
  `}

  .poolItem {
    ${({ theme }) => theme.mediaWidth.upToSmall`
        margin-right: 0;
        // margin-bottom: 10px;
  `}
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
`

const Title = styled.div`
  background: linear-gradient(90deg, rgba(143, 112, 255, 0.27) 0%, rgba(143, 112, 255, 0) 100%);
  border-radius: 4px;
  color: ${({ theme }) => theme.v2.t01};
  padding: 0 10px;
  width: fit-content;
  display: flex;
  align-items: center;
  font-size: 18px;
  height: 32px;
`

interface PoolProps {
  pools: AssetsPool[]
  title?: string
  emptyHide?: boolean
  addUserPool(id: string): void
  removeUserPool(id: string): void
}

export default function Pools({ pools, title, emptyHide, addUserPool, removeUserPool }: PoolProps) {
  if (emptyHide && !pools.length) return null
  return (
    <Wrapper>
      {title && <Title>{title}</Title>}

      <ListWrapper>
        {pools.length ? (
          pools.map(item => (
            <Pool data={item} key={item.id} addUserPool={addUserPool} removeUserPool={removeUserPool} />
          ))
        ) : (
          <TYPE.desc> No records</TYPE.desc>
        )}
      </ListWrapper>
    </Wrapper>
  )
}

const PoolWrapper = styled.div`
  width: 298px;
  margin-top: 20px;
  margin-right: 22px;
`

const PoolContent = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 14px;
  border-radius: 13px;
  background: ${({ theme }) => theme.v2.bg02};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`

const TokenBanner = styled(TokenImage)`
  margin: 0 auto;
  /* transform: translateY(-10px); */
`

const ActionButton = styled(AuthButton)`
  border-radius: 10px;
  margin-top: 20px;
  height: 40px;
`

export function EarnWNettPool() {
  const history = useHistory()
  function goToLaunchpad() {
    history.push('/launchpad-v2')
  }
  return (
    <Wrapper>
      <Title>Stake NETT to earn wNETT</Title>

      <ListWrapper>
        <PoolWrapper>
          <PoolContent>
            <TokenBanner size="46px" address="0x90fe084f877c65e1b577c7b2ea64b8d8dd1ab278" />
            <TYPE.v2Main fontSize={18} marginTop="10px">
              Earn wNETT
            </TYPE.v2Main>
            <ActionButton onClick={goToLaunchpad}>Stake Now!</ActionButton>
          </PoolContent>
        </PoolWrapper>
      </ListWrapper>
    </Wrapper>
  )
}
