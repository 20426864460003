import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Container from '@material-ui/core/Container'
import { useDarkModeManager } from 'state/user/hooks'

import BeefyLightSrc from 'assets/images/landing_page/partners_light/beefy.png'
import DiaLightSrc from 'assets/images/landing_page/partners_light/dia.png'
import HeraLightSrc from 'assets/images/landing_page/partners_light/hera.png'
import HummusLightSrc from 'assets/images/landing_page/partners_light/hummus.png'
import MetisEcoLightSrc from 'assets/images/landing_page/partners_light/metis_eco.png'
import MetisLightSrc from 'assets/images/landing_page/partners_light/metis.png'
import RelayLightSrc from 'assets/images/landing_page/partners_light/relay.png'
import RevenantLightSrc from 'assets/images/landing_page/partners_light/revenant.png'
import SynapseLightSrc from 'assets/images/landing_page/partners_light/synapse.png'
import CryptocartLightSrc from 'assets/images/landing_page/partners_light/cryptocart.png'
import O3SwapLightSrc from 'assets/images/landing_page/partners_light/o3swap.svg'

import BeefyDarkSrc from 'assets/images/landing_page/partners_dark/beefy.png'
import DiaDarkSrc from 'assets/images/landing_page/partners_dark/dia.png'
import HeraDarkSrc from 'assets/images/landing_page/partners_dark/hera.png'
import HummusDarkSrc from 'assets/images/landing_page/partners_dark/hummus.png'
import MetisEcoDarkSrc from 'assets/images/landing_page/partners_dark/metis_eco.png'
import MetisDarkSrc from 'assets/images/landing_page/partners_dark/metis.png'
import RelayDarkSrc from 'assets/images/landing_page/partners_dark/relay.png'
import RevenantDarkSrc from 'assets/images/landing_page/partners_dark/revenant.png'
import SynapseDarkSrc from 'assets/images/landing_page/partners_dark/synapse.png'
import CryptocartDarkSrc from 'assets/images/landing_page/partners_dark/cryptocart.png'
import O3SwapDarkSrc from 'assets/images/landing_page/partners_dark/o3swap.svg'

import { TYPE } from 'theme'

const Wrapper = styled.div`
  width: 100%;
  padding: 120px 0 0;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-top: 60px;
  `}
`

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 16.66%);
  grid-template-rows: repeat(3, 90px);

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: repeat(3, 33%);
    grid-template-rows: repeat(5, 46px);
    a {
        max-height: 46px;
    }
  `}
`

const ListItem = styled.a<{ height?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  &.metis {
    img {
      width: auto;
      height: ${({ height }) => (height ? height : '50%')};
    }
  }

  > img {
    height: ${({ height }) => (height ? height : '80%')};
    width: auto;
  }
`

const Title = styled(TYPE.title)`
  display: flex;
  justify-content: center;
  font-size: 40px !important;
  margin-bottom: 40px !important;

  ${({ theme }) => theme.mediaWidth.upToSmall`
      font-size: 20px !important;
      margin-bottom: 28px !important;
  `}
`

export default function Partners() {
  const { t } = useTranslation()
  const [darkMode] = useDarkModeManager()

  const partners = useMemo(
    () => [
      {
        key: 'metis',
        src: darkMode ? MetisDarkSrc : MetisLightSrc,
        link: 'https://www.metis.io/',
      },
      {
        key: 'metis_eco',
        src: darkMode ? MetisEcoDarkSrc : MetisEcoLightSrc,
        link: 'https://linktr.ee/Master_Ecosystem',
      },
      //   {
      //     key: 'dexpools',
      //     src: darkMode ? DexpoolsDarkSrc : DexpoolsLightSrc,
      //     link: 'https://www.dexpools.com/',
      //   },
      {
        key: 'synapse',
        src: darkMode ? SynapseDarkSrc : SynapseLightSrc,
        link: 'https://synapseprotocol.com/landing',
      },
      //   {
      //     key: 'wowswap',
      //     src: darkMode ? WowswapDarkSrc : WowswapLightSrc,
      //     link: 'https://wowswap.io/',
      //   },
      {
        key: 'relay',
        src: darkMode ? RelayDarkSrc : RelayLightSrc,
        link: 'https://www.relaychain.com/',
      },
      {
        key: 'hummus',
        src: darkMode ? HummusDarkSrc : HummusLightSrc,
        link: 'https://www.hummus.exchange/',
      },
      //   {
      //     key: 'bencu',
      //     src: darkMode ? BencuDarkSrc : BencuLightSrc,
      //     link: 'https://bencu.fi/',
      //   },
      {
        key: 'beefy',
        src: darkMode ? BeefyDarkSrc : BeefyLightSrc,
        link: 'https://beefy.finance/',
      },
      //   {
      //     key: 'binarydao',
      //     src: darkMode ? BinaryDaoDarkSrc : BinaryDaoLightSrc,
      //     link: 'https://binarydao.finance/',
      //   },
      {
        key: 'dia',
        src: darkMode ? DiaDarkSrc : DiaLightSrc,
        link: 'https://www.diadata.org/',
      },
      //   {
      //     key: 'poly_network',
      //     src: darkMode ? PolyNetworkDarkSrc : PolyNetworkLightSrc,
      //     link: 'https://poly.network/',
      //   },
      {
        key: 'hera',
        src: darkMode ? HeraDarkSrc : HeraLightSrc,
        link: 'https://hera.finance/',
      },
      {
        key: 'revenant',
        src: darkMode ? RevenantDarkSrc : RevenantLightSrc,
        link: 'https://www.revenant.gg/',
      },
      {
        key: 'cryptocart',
        src: darkMode ? CryptocartDarkSrc : CryptocartLightSrc,
        link: 'https://giftcards.cryptocart.cc/',
      },
      {
        key: 'o3swap',
        src: darkMode ? O3SwapDarkSrc : O3SwapLightSrc,
        link: 'https://v2.o3swap.com/',
        height: '50%',
      },
      {
        key: 'blockchat',
        src: require('assets/images/landing_page/partners_dark/blockchat.png'),
        link: 'https://blockchatdao.com/',
        height: '50%',
      },
      //   {
      //     key: 'peak',
      //     src: require('assets/images/landing_page/partners_dark/peak.png'),
      //     link: 'https://peakfinance.io/',
      //   },
    ],
    [darkMode]
  )

  return (
    <Wrapper>
      <Container maxWidth="lg">
        <Title fontSize={42}>{t('home.partners.title')}</Title>

        <List>
          {partners.map((item) => (
            <ListItem className={item.key} key={item.key} href={item.link} height={item.height} target="__blank">
              <img src={item.src} alt={item.link} />
            </ListItem>
          ))}
        </List>
      </Container>
    </Wrapper>
  )
}
