import React from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { Box } from 'rebass/styled-components'
import { useAppSelector } from 'state'

import Guide from '../../components/Guide'
import { MyOverview } from '../../components/Reward/MyOverview'
import { AboutScore } from '../../components/Reward/AboutScore'
import ScoreRanking from '../../components/Reward/ScoreRanking'

const RewardBox = styled(Box)`
  width: 900px;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `}
`
const RewardWrapper = styled.div`
  padding: 32px 40px 0 40px;
  display: flex;
  flex-flow: column wrap;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.v2.bg02};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    padding: 22px 10px 0 10px;
  `}

  section {
    margin-bottom: 32px;
    width: 100%;
  }
`

const SectionTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.text1};
  line-height: 24px;
  margin: 0 0 16px 0;
`
const ScoreNRewardDetail = styled(Typography)`
  font-size: 14px !important;
  color: ${({ theme }) => theme.text1};
  line-height: 17px !important;
`
export default function Reward() {
  const { isChristmas } = useAppSelector(state => state.application)

  return (
    <RewardBox>
      {isChristmas && (
        <img
          style={{ width: '100%', maxHeight: '22px' }}
          src={require('../../assets/images/christmas/ic_snow_chang.png')}
          alt="christmasChang"
        />
      )}
      <RewardWrapper>
        <section>
          <SectionTitle>Score & Reward</SectionTitle>
          <ScoreNRewardDetail>
            Scoring Plan —The 900,000 NETT tokens will be distributed to the early traders and liquidity providers
            through our scoring system within the first 30 days after the launch of Scoring Plan.
          </ScoreNRewardDetail>
        </section>
        <section>
          <SectionTitle>My Overview</SectionTitle>
          <MyOverview />
        </section>
        <section>
          <SectionTitle>About Score</SectionTitle>
          <AboutScore />
        </section>
        <section>
          <ScoreRanking />
        </section>
      </RewardWrapper>
      <Guide
        title="How to get score?"
        text={
          <>
            <h3>Scores Formulas for Phase I</h3>
            <em>Swap Score = ∑(Swap Fees * Pair Rate)</em>
            <br />
            <em>Liquidity Score = ∑(USD value of LP Token when minted * Pool Rate * Duration)</em>
            <br />
            <br />
            <h3>Scores Formulas for Phase II </h3>
            <em>Swap Score = (Swap Score of Phase I * 10%) +∑(Swap Fees * Pair Rate)</em>
            <br />
            <em>
              Liquidity Provider Score = (Liquidity Provider Score of Phase I * 10%) +∑(USD value of LP Token when
              minted * Pool Rate * Duration)
            </em>
            <br />
            <br />
            <em>Notes: Impermanent loss is not considered</em>
          </>
        }
        width="900px"
      />

      <Guide
        title="Timeline"
        text={
          <>
            <h3>Dec 8, 2021</h3>
            <p>Netswap Scoring Plan release.</p>
            <h3>15 days after Netswap Scoring Plan release</h3>
            <p>First scoring plan airdrop campaign.(400K NETT token)</p>
            <h3>30 days after Netswap Scoring Plan release</h3>
            <p>Second scoring plan airdrop campaign.(500K NETT token)</p>
          </>
        }
        width="900px"
      />
    </RewardBox>
  )
}
