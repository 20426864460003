import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Numeral from 'numeral'

import Container from '@material-ui/core/Container'

import NeboCoinSrc from 'assets/images/landing_page/nebo_coin.png'
import { TYPE } from 'theme'
import { ButtonV2 } from 'components/Button'
import useGetOverviewData from 'state/data/hooks/useGetOverviewData'

const Wrapper = styled.div`
  width: 100%;
  background: linear-gradient(90deg, rgba(118, 170, 255, 0.17) 0%, rgba(82, 79, 148, 0) 100%);
  position: relative;
  z-index: 1;
  padding: 130px 0 85px 0;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 130px 0 85px 0;
  `}
`

const Triangle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-width: 50px 50vw 50px 50vw;
  border-style: solid;
  border-color: ${({ theme }) => theme.v2.bg01} transparent transparent ${({ theme }) => theme.v2.bg01};
  z-index: 2;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-width: 20px 50vw 20px 50vw;
  `}
`

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const NeboCoinImg = styled.img`
  height: 374px;
  width: auto;
  margin-right: 58px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    position: absolute;
    width: 94px;
    height: auto;
    right: -20px;
    top: -20px;
    z-index: 1;
  `}
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  max-width: 630px;
  z-index: 2;
`

const Title = styled(TYPE.title)`
  font-size: 32px;
  line-height: 42px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
    line-height: 20px;
  `}
`

const Subtitle = styled(TYPE.title)`
  font-size: 42px;
  line-height: 54px;
  margin: 10px 0 20px 0 !important;

  > strong {
    font-size: 52px;
    line-height: 54px;
    color: ${({ theme }) => (theme.isDark ? '#00cfff' : '#5347ff')};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 20px;
    line-height: 28px;

    > strong {
      font-size: 28px;
      line-height: 30px;
    }
  `}
`

const Description = styled(TYPE.main)`
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 60px !important;
  max-width: 580px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
    line-height: 18px;
  `}
`

const StakedBtn = styled(ButtonV2)`
  width: 208px;
  height: 48px;
  font-size: 16px;
`

export default function Launchpad() {
  const { t } = useTranslation()
  const history = useHistory()
  const { overview } = useGetOverviewData()

  return (
    <Wrapper>
      <Triangle />

      <Container maxWidth="lg">
        <InnerWrapper>
          <NeboCoinImg src={NeboCoinSrc} alt="" />

          <ContentContainer>
            <Title>{t('home.launchpad.title')}</Title>

            <Subtitle
              dangerouslySetInnerHTML={{
                __html: t('home.launchpad.staked_vol', {
                  amount: Numeral(overview?.wNettTvl || 0).format('$0,0'),
                }),
              }}
            />

            <Description>{t('home.launchpad.description')}</Description>

            <StakedBtn onClick={() => history.push('/nett-staking-detail/wNETT')}>
              {t('home.launchpad.stake_token', { token: 'NETT' })}
            </StakedBtn>
          </ContentContainer>
        </InnerWrapper>
      </Container>
    </Wrapper>
  )
}
