/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
// import cx from 'classnames'
import { isMobile } from 'react-device-detect'
import TokenImage from 'components/TokenImage'
import { RowBetween } from 'components/Row'
import { useActiveWeb3React } from 'hooks'
import { InputBase } from '@material-ui/core'
import { AuthButton } from 'components/Button'
import { useContract } from 'hooks/contract/useContract'
import { WNettStakeContract } from 'constants/wNettStaking'
import { useGetNett, useGetWNett } from 'hooks/contract/useGetContract'
import useSend from 'state/contract/hooks/useSend'
import useTokenApprove from 'hooks/contract/useTokenApprove'
import { BigNumber } from 'bignumber.js'
import Value from 'components/Value'
import { NETWORK_CHAIN_ID } from 'connectors'
import { ChainId } from '@netswap/sdk'
import { getTokenImage } from 'utils'
import { WrappedTokenInfo } from 'state/lists/hooks'
import { useFormatSymbolTokenPrice } from 'state/data/hooks/useTokenPrice'
import { TYPE } from 'theme'
import { AutoColumn } from 'components/Column'
import classNames from 'classnames'

const StakeWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-flow: column nowrap;
  margin: 0 auto;
  margin-top: 70px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 20px;
`}

  .firstHeaderCon {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 24px;
    align-items: center;
    h2 {
      margin: 0;
      font-size: 24px;
      font-weight: 600;
      color: #fff;
      text-align: center;
      min-width: 240px;
    }
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      list-style: none;
      font-size: 16px;
      line-height: 20px;
      color: #fff;
    }
  }

  .firstCon {
    display: flex;
    width: 100%;
    max-width: 100%;
    justify-content: center;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      flex-flow: column nowrap;
    `}
    .firstLeftCon,
    .firstRightCon {
      width: 100%;
      padding: 20px;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      backdrop-filter: blur(100px);
      border-radius: 20px;
      position: relative;
      overflow: hidden;
      ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 10px;
        width: 100% !important;
      `}

      h2,
      h3,
      p {
        margin: 0;
      }
      h3 {
        line-height: 22px;
        font-weight: 300;
        font-size: 12px;
        color: #989dae;
      }
    }
    .firstLeftCon {
      box-sizing: border-box;
      padding: 30px;
      width: 520px;
      background: ${({ theme }) => theme.v2.bg02};
      /* background: linear-gradient(259.03deg, rgba(255, 255, 255, 0.06) 9.6%, rgba(255, 255, 255, 0) 115.72%); */
      /* background-image: url(${require('assets/images/ic_ellipse_left_bg.svg')}); */
      ${({ theme }) => theme.mediaWidth.upToSmall`
        max-width: unset;
        width: 100%;
        padding: 1rem;
      `}
    }
    .firstLeftTop {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &.border {
        margin-bottom: 30px;
      }

      ${({ theme }) => theme.mediaWidth.upToSmall`
          flex-flow: column nowrap;
        `}

      &.globalInfo {
        border-bottom: 1px solid #8b7a93;
      }
      .firstLeftInfo {
        width: 40%;
        /* padding: 0 40px; */
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-end;
        ${({ theme }) => theme.mediaWidth.upToSmall`
            width: 100%;
            padding: 0 20px;
            margin-bottom: 20px;
            align-items: center;
          `}
      }
    }
    .firstLeftBottom {
      margin: 0 auto;
      margin-top: 40px;
      padding: 0 40px;
      ${({ theme }) => theme.mediaWidth.upToSmall`
          margin-top: 0;
          padding: 0 20px;
          width: 100%;
          display: flex;
          justify-content: center;
        `}

      button {
        max-width: 100%;
        border-radius: 14px;
      }
    }
    .firstRightCon {
      max-width: 480px;
      padding: 20px;
      margin-left: 20px;
      background: ${({ theme }) => theme.v2.bg02};
      ${({ theme }) => theme.mediaWidth.upToSmall`
        margin-left: 0px;
        margin-top: 20px;
        max-width: unset;
        padding: 20px;
      `}

      .firstRightTab {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        border-bottom: 1px solid #212139;
        padding-bottom: 10px;
        div {
          font-size: 14px;
          line-height: 17px;
          margin-right: 20px;
          cursor: pointer;
          &.unChoose {
            color: #989dae;
          }
        }
      }

      .firstRightTop,
      .firstRightBottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .firstRightTop {
        margin: 20px 0 10px;
        padding: 12px 10px;
        width: 100%;
        height: 62px;
        background: ${({ theme }) => theme.v2.bg04};

        border: 1px solid rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(13px);
        border-radius: 10px;
        .firstRightTopInfo {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 10px;
          margin: 0 10px;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: ${({ theme }) => theme.v2.t01};

          .MuiInputBase-input {
            font-weight: 600;
            font-size: 32px;
            line-height: 38px;
            color: ${({ theme }) => theme.v2.t01};
            padding: 0;
          }
        }
      }
      .errorInfo {
        font-size: 12px;
        color: #fd4040;
        font-weight: 500;
        line-height: 14px;
        margin: 0 0 10px 0;
      }
      .firstRightBottom {
        margin-bottom: 20px;
        ${({ theme }) => theme.mediaWidth.upToSmall`
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-start;
          p {
            margin-top: 4px;
          }
        `}
        span {
          font-size: 12px;
          font-weight: 300;
          line-height: 14px;
          color: #989dae;
        }
        p {
          margin: 0;
          font-size: 12px;
          color: ${({ theme }) => theme.v2.t02};

          font-weight: 500;
          line-height: 14px;
          ${({ theme }) => theme.mediaWidth.upToSmall`
            margin-top: 4px;
          `}
        }
      }
      .firstRightBtn {
        button {
          margin-top: 64px;
          max-width: 100%;
          border-radius: 14px;
        }

        &.error {
          button {
            margin-top: 40px;
          }
        }
        ${({ theme }) => theme.mediaWidth.upToSmall`
          flex-flow: column nowrap;
        `}
      }
    }
  }
`

const Input = styled(InputBase)`
  input {
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`
const MaxBtn = styled.button`
  width: 47px;
  height: 27px;
  background: ${({ theme }) => theme.v2.bg05};
  border-radius: 8px;
  border: none;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  line-height: 27px;
  cursor: pointer;
  flex-shrink: 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: fit-content;
    box-sizing: border-box;
    padding: 0 4px;
  `};
`
const StakeBtn = styled(AuthButton)`
  width: 100%;
  height: 46px;
  border-radius: 56px;
  font-size: 16px;
  font-weight: 600;
  line-height: 46px;
  border: none;
  max-width: 199px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 12px;
    width: 100%;
    margin-left: 0;
    max-width: unset;
  `};
`
// const UnstakeBtn = styled(AuthButton)`
//   font-size: 16px;
//   color: #cdcdcd;
//   width: 100%;
//   height: 46px;
//   border-radius: 56px;
//   font-size: 16px;
//   font-weight: 600;
//   max-width: 199px;
//   background: #4d2f58;
//   border: 0.5px solid #cdcdcd;
//   box-sizing: border-box;
//   backdrop-filter: blur(9px);
//   cursor: pointer;
//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     text-align: center;
//     margin: 12px auto 0;
//     display: block;
//     max-width: unset;
//   `};
// `
const ClaimBtn = styled(AuthButton)`
  width: 100%;
  height: 46px;
  border-radius: 56px;
  font-size: 16px;
  font-weight: 600;
  line-height: 46px;
  border: none;
  max-width: 208px;
  cursor: pointer;
  margin: 0 auto;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: unset;
  `}
  &.walletTmp {
    height: 48px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 24px;
      margin-right: 12px;
    }
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }
  }
`

export default function StakeWNett() {
  const { account, chainId } = useActiveWeb3React()

  const stakeContract = useContract(WNettStakeContract)
  const nettContract = useGetNett()
  const wNettContract = useGetWNett()
  const [inputValue, setInputValue] = useState('')
  const [isStake, setIsStake] = useState(true)
  const [userNettBalance, setUserNettBalance] = useState(new BigNumber(0))
  const [userwNettBalance, setUserWNettBalance] = useState(new BigNumber(0))
  const [pendingWNett, setPendingWNett] = useState(new BigNumber(0))
  const [userStakeAmount, setUserStakeAmount] = useState(new BigNumber(0))
  const [wNETTPerSec, setWNETTPerSec] = useState(new BigNumber(0))
  const [totalStaked, setTotalStake] = useState(new BigNumber(0))
  const [totalSupply, setTotalSupply] = useState(new BigNumber(0))
  const priceInfo = useFormatSymbolTokenPrice()

  const send = useSend()

  const inputAmount = useMemo(() => {
    return new BigNumber(inputValue || 0)
  }, [inputValue])

  const { approved, approve } = useTokenApprove(
    {
      address: nettContract?.address || '',
      decimals: 18,
      symbol: 'NETT',
    },
    stakeContract?.address || '',
    inputAmount
  )

  async function getBalance(contract: any) {
    if (contract && account) {
      try {
        const res = await contract.balanceOf(account)
        return res
      } catch (e) {
        console.error(e)
      }
    }
  }

  async function getUserNettBalance() {
    if (nettContract) {
      const res = await getBalance(nettContract)

      setUserNettBalance(res?.toString() ? new BigNumber(res.toString()).shiftedBy(-18) : new BigNumber(0))
    }
  }

  async function getUserWNettBalance() {
    if (wNettContract) {
      const res = await getBalance(wNettContract)

      setUserWNettBalance(res?.toString() ? new BigNumber(res.toString()).shiftedBy(-18) : new BigNumber(0))
    }
  }

  async function getWNETTPerSec() {
    if (stakeContract) {
      const res = await stakeContract.wNETTPerSec()
      if (res) {
        setWNETTPerSec(res?.toString() ? new BigNumber(res.toString()).shiftedBy(-18) : new BigNumber(0))
      }
    }
  }

  async function getTotalStakedNett() {
    if (stakeContract) {
      const res = await stakeContract.totalNETTStaked()
      if (res) {
        setTotalStake(res?.toString() ? new BigNumber(res.toString()).shiftedBy(-18) : new BigNumber(0))
      }
    }
  }

  async function getTotalSupply() {
    if (wNettContract) {
      const res = await wNettContract.totalSupply()
      if (res) {
        setTotalSupply(res?.toString() ? new BigNumber(res.toString()).shiftedBy(-18) : new BigNumber(0))
      }
    }
  }

  useEffect(() => {
    getWNETTPerSec()
  }, [stakeContract])

  useEffect(() => {
    let interval: any
    if (stakeContract) {
      getTotalStakedNett()

      interval = setInterval(() => {
        getTotalStakedNett()
      }, 30000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [stakeContract])

  useEffect(() => {
    let interval: any
    if (wNettContract) {
      getTotalSupply()

      interval = setInterval(() => {
        getTotalSupply()
      }, 30000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [wNettContract])

  const wNettPerDay = useMemo(() => {
    return wNETTPerSec.multipliedBy(86400).toNumber()
  }, [wNETTPerSec])

  useEffect(() => {
    let interval: any
    if (nettContract && account) {
      getUserNettBalance()

      interval = setInterval(() => {
        getUserNettBalance()
      }, 30000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [nettContract, account])

  useEffect(() => {
    let interval: any
    if (wNettContract && account) {
      getUserWNettBalance()

      interval = setInterval(() => {
        getUserWNettBalance()
      }, 30000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [wNettContract, account])

  async function getUserInfo() {
    if (!stakeContract || !account) return
    try {
      const res = await stakeContract.userInfo(account)
      setUserStakeAmount(new BigNumber(res?.amount.toString() || 0).shiftedBy(-18))
      // if (res?.allocation.toNumber()) {
      //   setUserAllocation(new BigNumber(res?.allocation.toString() || 0).shiftedBy(-data.saleToken.decimals))
      // }
    } catch (e) {
      console.error(`get  userInfo ${account} failed`)
      console.error(e)
    }
  }

  async function stake() {
    if (stakeContract) {
      await send({
        contract: stakeContract,
        method: 'deposit',
        params: [inputAmount.shiftedBy(18).toFixed(0)],
        pendingText: `Staking ${inputAmount.toFixed()} NETT`,
        summary: `Staked ${inputAmount.toFixed()} NETT`,
        onSuccess() {
          getUserInfo()
          getUserNettBalance()
          getUserWNettBalance()
          getPendingWNett()
          getTotalSupply()
          getTotalStakedNett()
          setInputValue('')
        },
      })
    }
  }

  async function Unstake() {
    if (userStakeAmount.isGreaterThan(0) && inputAmount.isGreaterThan(0)) {
      send({
        contract: stakeContract,
        method: 'withdraw',
        params: [inputAmount.shiftedBy(18).toFixed()],
        pendingText: `Unstaking ${inputAmount.toFixed()} NETT`,
        summary: `Unstake ${inputAmount.toFixed()} NETT`,
        onSuccess() {
          getUserInfo()
          getUserNettBalance()
          getUserWNettBalance()
          getPendingWNett()
          getTotalSupply()
          getTotalStakedNett()
          setInputValue('')
        },
      })
    }
  }

  async function claimPendingWNett() {
    if (pendingWNett.isGreaterThan(0)) {
      send({
        contract: stakeContract,
        method: 'withdraw',
        params: ['0'],
        pendingText: `Claim pending wNETT`,
        summary: `Claimed pending wNETT`,
        addTokenToMetamask: {
          chainId: chainId || (NETWORK_CHAIN_ID as ChainId),
          name: 'wNETT',
          symbol: 'wNETT',
          address: wNettContract?.address || '',
          decimals: 18,
          logoURI: getTokenImage(wNettContract?.address || ''),
        } as WrappedTokenInfo,
        onSuccess() {
          getUserWNettBalance()
          getPendingWNett()
          getTotalSupply()
        },
      })
    }
  }

  async function handleMax() {
    if (isStake) {
      setInputValue(userNettBalance.toFixed())
    } else {
      setInputValue(userStakeAmount.toFixed())
    }
  }

  function handleConfirm() {
    if (approved) {
      stake()
    } else {
      approve()
    }
  }

  async function getPendingWNett() {
    try {
      if (account && stakeContract) {
        const res = await stakeContract.pendingWNETT(account)

        if (res && res.toString()) {
          setPendingWNett(new BigNumber(res.toString()).shiftedBy(-18))
        } else {
          setPendingWNett(new BigNumber(0))
        }
      }
    } catch (e) {
      console.error(e)
      console.error('get pending wnett error')
    }
  }

  const canClaim = useMemo(() => {
    return pendingWNett.isGreaterThan(0)
  }, [pendingWNett])

  useEffect(() => {
    let interval: any
    if (stakeContract && account) {
      getPendingWNett()

      interval = setInterval(() => {
        getPendingWNett()
      }, 15000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [stakeContract, account])

  useEffect(() => {
    getUserInfo()
  }, [account, stakeContract])

  const errorMessage = useMemo(() => {
    if (
      (isStake && inputAmount.isGreaterThan(userNettBalance)) ||
      (!isStake && inputAmount.isGreaterThan(userStakeAmount))
    ) {
      return 'Insufficient balance'
    }
    return ''
  }, [userNettBalance, userStakeAmount, isStake, inputAmount])

  const changeStakeUnstake = (name: string) => {
    if (!account) {
      return
    }
    if (name === 'stake') {
      if (!isStake) {
        setIsStake(true)
      }
    }
    if (name === 'unstake') {
      if (isStake) {
        setIsStake(false)
      }
    }
  }

  const userRewardPerDay = useMemo(() => {
    if (totalStaked.isGreaterThan(0)) {
      return userStakeAmount.dividedBy(totalStaked).multipliedBy(wNettPerDay).toNumber()
    }
    return 0
  }, [userStakeAmount, totalStaked, wNettPerDay])

  const nettPrice = useMemo(() => {
    return priceInfo?.nett?.price || 0
  }, [priceInfo])

  const totalStakedUSD = useMemo(() => {
    if (nettPrice) {
      return totalStaked.multipliedBy(nettPrice).toNumber()
    }
    return '--'
  }, [totalStaked, nettPrice])

  const youStakedUSD = useMemo(() => {
    if (nettPrice) {
      return userStakeAmount.multipliedBy(nettPrice).toNumber()
    }
    return '--'
  }, [userStakeAmount, nettPrice])
  return (
    <StakeWrapper>
      {/* <div className="firstHeaderCon">
          <h2>Launchpad V2</h2>
          <ul>
            <li>
              1. Stake NETT to get wNETT as the ticket to enter Netswap Launchpad, 100wNETT unlocks $100 allocation.
            </li>
            <li>2. Use accrued wNETT to deposit equivalent stablecoins into the active event on launchpad.</li>
            <li>3. Wait for the launch time to receive new tokens and refunds(if any)</li>
          </ul>
        </div> */}
      <div className="firstCon">
        <div className="firstLeftCon">
          <div className="firstLeftTop border">
            <AutoColumn className="firstLeftInfo" gap="md">
              <TYPE.v2Main fontWeight="bold" fontSize={16}>
                Total Staked NETT(USD)
              </TYPE.v2Main>
              <TYPE.v2Main fontWeight="bold" fontSize={16}>
                ≈ $ <Value value={totalStakedUSD} />
              </TYPE.v2Main>
            </AutoColumn>
            <AutoColumn className="firstLeftInfo" gap="md">
              <TYPE.v2Main fontWeight="bold" fontSize={16}>
                Total wNETT Supply
              </TYPE.v2Main>
              <TYPE.v2Main fontWeight="bold" fontSize={16}>
                ≈ <Value value={totalSupply.toNumber()} />
              </TYPE.v2Main>
            </AutoColumn>
          </div>
          <div className="firstLeftTop">
            <AutoColumn className="firstLeftInfo" gap="md">
              <TYPE.v2Main fontWeight="bold" fontSize={16}>
                Your wNETT Balance
              </TYPE.v2Main>
              <TYPE.v2Main fontWeight="bold" fontSize={16}>
                ≈ <Value value={userwNettBalance.toNumber()} />
              </TYPE.v2Main>
              <RowBetween style={{ justifyContent: 'center', marginTop: '8px' }}>
                <TYPE.desc>≈ {pendingWNett.toFixed(3)} pending wNETT</TYPE.desc>
              </RowBetween>
            </AutoColumn>
            <AutoColumn className="firstLeftInfo" gap="md">
              <TYPE.v2Main fontWeight="bold" fontSize={16}>
                Your Staked NETT(USD)
              </TYPE.v2Main>
              <TYPE.v2Main fontWeight="bold" fontSize={16}>
                ≈ $ <Value value={youStakedUSD} />
              </TYPE.v2Main>
              <RowBetween style={{ justifyContent: 'center', marginTop: '8px' }}>
                <TYPE.desc>≈ {userRewardPerDay.toFixed(3)} wNETT per day</TYPE.desc>
              </RowBetween>
            </AutoColumn>
          </div>
          <div className="firstLeftBottom">
            <ClaimBtn auth onClick={claimPendingWNett} disabled={!canClaim}>
              Claim Pending wNETT
            </ClaimBtn>
          </div>
        </div>
        <div className="firstRightCon">
          <div className="firstRightTab">
            <TYPE.v2Main
              className={!isStake ? 'unChoose' : ''}
              onClick={() => {
                changeStakeUnstake('stake')
              }}
            >
              Stake
            </TYPE.v2Main>
            <TYPE.v2Main
              className={isStake ? 'unChoose' : ''}
              onClick={() => {
                changeStakeUnstake('unstake')
              }}
            >
              Unstake
            </TYPE.v2Main>
          </div>
          <h3>Stake NETT to get wNETT</h3>
          <div className="firstRightTop">
            <TokenImage address={'0x90fE084F877C65e1b577c7b2eA64B8D8dd1AB278'} alt="NETTICON" />
            <div className="firstRightTopInfo">
              <Input
                fullWidth
                autoFocus={!isMobile}
                placeholder="0.0"
                type="number"
                value={inputValue}
                onChange={(e) => {
                  if (Number(e.currentTarget.value) < 0 || e.currentTarget.value === '-') {
                    setInputValue('')
                    return
                  }
                  setInputValue(e.currentTarget.value || '')
                }}
              />
              NETT
            </div>
            <MaxBtn onClick={handleMax}>Max</MaxBtn>
          </div>
          {errorMessage && <p className="errorInfo">{errorMessage}</p>}
          <div className="firstRightBottom">
            {isStake && <p>NETT Balance: {userNettBalance.toFixed()}</p>}
            {!isStake && <p>You Staked: {userStakeAmount.toFixed()} NETT</p>}
          </div>
          <RowBetween className={classNames('firstRightBtn', errorMessage ? 'error' : '')}>
            {isStake && (
              <StakeBtn auth disabled={!inputValue || !!errorMessage} onClick={handleConfirm}>
                {approved ? 'Stake' : 'Approve'}
              </StakeBtn>
            )}
            {!isStake && (
              <StakeBtn auth onClick={Unstake} disabled={!inputValue || !!errorMessage}>
                Unstake
              </StakeBtn>
            )}
          </RowBetween>
        </div>
      </div>
    </StakeWrapper>
  )
}
