import { ChainId } from '@netswap/sdk'
import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import Modal from '../Modal'
import { ExternalLink, TYPE } from '../../theme'
import { CloseIcon, CustomLightSpinner } from '../../theme/components'
import { RowBetween } from '../Row'
import { AlertTriangle, ArrowUpCircle } from 'react-feather'
import { ButtonV2, ButtonPrimary } from '../Button'
import { AutoColumn, ColumnCenter } from '../Column'
import Circle from '../../assets/images/blue-loader.svg'

import { getEtherscanLink, isEnvChain, NETWORK_CONFIG } from '../../utils'
import { useActiveWeb3React } from '../../hooks'
import { useAddTokenToMetakMask, TokenParams } from 'hooks/useAddTokenToMetamask'
import { WrappedTokenInfo } from 'state/lists/hooks'
import { useAppDispatch, useAppSelector } from 'state'
import { setCloseConfirModalState } from 'state/contract'

const Wrapper = styled.div`
  width: 100%;
`
const Section = styled(AutoColumn)`
  padding: 24px;
`

const BottomSection = styled(Section)`
  background-color: ${({ theme }) => theme.bg2};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 30px 0;
`

const AddToken = styled.div`
  border-radius: 12px;
  padding: 14px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.v2.bg04};
  font-size: 14px;
  color: ${({ theme }) => theme.v2.t02};
  display: flex;
  align-items: center;
  margin-top: 1rem;
  cursor: pointer;
  .metamaskLogo {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
`

function ConfirmationPendingContent({ onDismiss, pendingText }: { onDismiss: () => void; pendingText: string }) {
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <div />
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <ConfirmedIcon>
          <CustomLightSpinner src={Circle} alt="loader" size={'90px'} />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify={'center'}>
          <TYPE.v2Main fontWeight={500} fontSize={20}>
            Waiting For Confirmation
          </TYPE.v2Main>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.v2Main fontWeight={600} fontSize={14} color="" textAlign="center">
              {pendingText}
            </TYPE.v2Main>
          </AutoColumn>
          <TYPE.v2Main fontSize={12} color="#565A69" textAlign="center">
            Confirm this transaction in your wallet
          </TYPE.v2Main>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  addTokenToMetamask,
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  addTokenToMetamask?: WrappedTokenInfo
}) {
  const theme: any = useContext(ThemeContext as any)
  const [canAdd, addToMetaMask] = useAddTokenToMetakMask()

  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <div />
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <ConfirmedIcon>
          <ArrowUpCircle strokeWidth={0.5} size={90} color={theme.v2.t09} />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify={'center'}>
          <TYPE.v2Main fontWeight={500} fontSize={20}>
            Transaction Submitted
          </TYPE.v2Main>
          {chainId && hash && (
            <ExternalLink href={getEtherscanLink(chainId, hash, 'transaction')}>
              <TYPE.v2Main fontWeight={500} fontSize={14} color="t05">
                View on {chainId && isEnvChain(chainId) ? NETWORK_CONFIG[chainId].scanLabel : 'Ethereum scan'}
              </TYPE.v2Main>
            </ExternalLink>
          )}
          {addTokenToMetamask && canAdd && (
            <AddToken
              onClick={() => {
                addToMetaMask(addTokenToMetamask as TokenParams)
              }}
            >
              <img className="metamaskLogo" src={require('assets/images/metamask.png')} alt="netswap" /> Add{' '}
              {addTokenToMetamask.symbol} to Metamask
            </AddToken>
          )}
          <ButtonV2 onClick={onDismiss} style={{ margin: '20px 0 0 0' }}>
            Close
          </ButtonV2>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export function ConfirmationModalContent({
  title,
  bottomContent,
  onDismiss,
  topContent,
}: {
  title: string
  onDismiss: () => void
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}) {
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <TYPE.v2Main fontWeight={500} fontSize={20}>
            {title}
          </TYPE.v2Main>
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        {topContent()}
      </Section>
      <BottomSection gap="12px">{bottomContent()}</BottomSection>
    </Wrapper>
  )
}

export function TransactionErrorContent({ message, onDismiss }: { message: string; onDismiss: () => void }) {
  const theme: any = useContext(ThemeContext as any)
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <TYPE.v2Main fontWeight={500} fontSize={20}>
            Error
          </TYPE.v2Main>
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <AutoColumn style={{ marginTop: 20, padding: '2rem 0' }} gap="24px" justify="center">
          <AlertTriangle color={theme.red1} style={{ strokeWidth: 1.5 }} size={64} />
          <TYPE.v2Main fontWeight={500} fontSize={16} color={theme.red1} style={{ textAlign: 'center', width: '85%' }}>
            {message}
          </TYPE.v2Main>
        </AutoColumn>
      </Section>
      <BottomSection gap="12px">
        <ButtonPrimary onClick={onDismiss}>Dismiss</ButtonPrimary>
      </BottomSection>
    </Wrapper>
  )
}

interface ConfirmationModalProps {
  isOpen: boolean
  onDismiss: () => void
  hash: string | undefined
  content: () => React.ReactNode
  attemptingTxn: boolean
  pendingText: string
  addTokenToMetamask?: WrappedTokenInfo
}

export default function TransactionConfirmationModal({
  isOpen,
  onDismiss,
  attemptingTxn,
  hash,
  pendingText,
  content,
  addTokenToMetamask,
}: ConfirmationModalProps) {
  const { chainId } = useActiveWeb3React()

  if (!chainId) return null

  // confirmation screen
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      {attemptingTxn ? (
        <ConfirmationPendingContent onDismiss={onDismiss} pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={onDismiss}
          addTokenToMetamask={addTokenToMetamask}
        />
      ) : (
        content()
      )}
    </Modal>
  )
}

export function TransactionConfirmationModalWithErrorMessage() {
  const { chainId } = useActiveWeb3React()
  const dispatch = useAppDispatch()
  const {
    confirmModal: { isOpen, attemptingTxn, hash, pendingText, errorMessage, addTokenToMetamask },
  } = useAppSelector((state) => state.contractState)

  function onDismiss() {
    dispatch(setCloseConfirModalState())
  }

  if (!chainId) return null

  // confirmation screen
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90} hideWithoutDuration>
      {attemptingTxn ? (
        <ConfirmationPendingContent onDismiss={onDismiss} pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={onDismiss}
          addTokenToMetamask={addTokenToMetamask}
        />
      ) : (
        <TransactionErrorContent
          onDismiss={onDismiss}
          message={errorMessage || 'Something wrong, please try again later'}
        />
      )}
    </Modal>
  )
}
