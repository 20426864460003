import { Modal } from '@material-ui/core'
import React, { useState } from 'react'
import { useAppSelector } from 'state'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;
  background-color: red;
  width: 640px;
  height: 403px;
  border-radius: 16px;
  padding: 40px;
  background: url(${require('./bg.png')}) no-repeat;
  background-size: 100%;
  background-position: center;
  outline: none !important;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 90vw;
    height: auto;
    `};
`
const TextWrapper = styled.div`
  width: 53%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    `};
`

const Header = styled.div`
  color: #fff;
  font-size: 32px;
  font-weight: bold;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
    `};
`
const Desc = styled.div`
  color: #fff;
  font-size: 22px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    `};
`
export default function Noti() {
  const { showNoti } = useAppSelector((state) => state.application)
  const [open, setOpen] = useState(showNoti)
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <Wrapper>
        <TextWrapper>
          <Header>
            Sequencer <br />
            Decentralizing
          </Header>
          <Desc>
            Metis is decentralizing its sequencer, making history as the first Ethereum Layer 2 to do so. Please be
            patient as the upgrade is currently taking place.
          </Desc>
        </TextWrapper>
      </Wrapper>
    </Modal>
  )
}
