/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { withStyles, createStyles, makeStyles } from '@material-ui/core/styles'
import { useMinimalSelectStyles } from '@mui-treasury/styles/select/minimal'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import { Select, MenuItem, MenuProps } from '@material-ui/core'

// import Guide from '../../components/Guide'
import Pad from './components/Pad'

import { useGetLaunchpadBaseModal, useGetLaunchpadUnlimitModal } from 'hooks/contract/useGetContract'
import useGetLaunchpads from 'state/data/hooks/useGetLaunchpads'
import { LaunchPad, PadPeriod, PadStatus, PadType } from 'gql/microservices/pads'
import { useGetTokensPrice } from 'state/data/hooks/useTokenPrice'
import { ExternalLink } from 'theme'
import useGetUserPads from 'state/data/hooks/useGerUserPads'

function a11yProps(index: any) {
  return {
    id: `launchpad-tab-${index}`,
    'aria-controls': `launchpad-tabpanel-${index}`,
  }
}

const LaunchpadBox = styled(Box)`
  width: 900px;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `}
`

const LaunchpadWraaper = styled.div`
  padding: 24px 40px;
  display: flex;
  flex-flow: column wrap;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.bg6};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 24px 16px;
  `}

  .launchpadSelect {
    width: 128px;
    height: 46px;
    padding-right: 12px;
    background: #1f0835;
    border-radius: 8px;
    display: flex;
    justify-content: center;
  }
`
interface StyledTabsProps {
  value: number
  onChange: (event: React.ChangeEvent<any>, newValue: number) => void
  children: any
}

const StyledTabs = withStyles({
  root: {
    marginBottom: '14px',
  },
  flexContainer: {
    justifyContent: 'space-evenly',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    bottom: '10px',
    '& > span': {
      maxWidth: 30,
      width: '100%',
      backgroundColor: '#c42a61',
    },
  },
})((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)

const Link = styled(ExternalLink)`
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 2rem;
`

interface StyledTabProps {
  label: string
}

const StyledTab = withStyles(() =>
  createStyles({
    root: {
      textTransform: 'none',
      color: '#fff',
      '&:focus': {
        opacity: 1,
        color: '#c42a61',
      },
    },
    selected: {
      color: '#c42a61',
    },
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />)

const LaunchpadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 900px;
  min-height: 440px;
  align-items: center;
  box-sizing: border-box;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    max-width: 900px;
  `}
`
const SortPeriod = {
  [PadPeriod.Cashing]: 1,
  [PadPeriod.Staking]: 2,
  [PadPeriod.Vesting]: 3,
  [PadPeriod.Ended]: 4,
  [PadPeriod.Prepare]: 5,
}

//select style start
const useStyles = makeStyles({
  root: {
    color: '#fff',
    display: 'flex',
    paddingLeft: '14px',
    alignItems: 'center',
  },
  select: {
    fontSize: '14px',
    color: '#fff',
  },
  menuPaper: {
    background: 'transparent',
  },
  menuRoot: {
    borderRadius: '4px',
    background: '#150623',
    width: '128px',
    boxShadow: '0px 2px 10px 0px rgba(196, 42, 97, 0.1)',
    color: '#fff',
  },
  menuItem: {
    background: 'transparent',
    height: '44px',
    color: '#fff',
    fontSize: '14px',
    justifyContent: 'center',
    '&.Mui-selected': {
      background: 'rgba(196, 42, 97, 0.1) !important',
    },
    '&:hover': {
      background: 'rgba(196, 42, 97, 0.1) !important',
    },
  },
})
//select style end

enum FilterType {
  all = 'all',
  base = 'base',
  unlimit = 'unlimit',
}

export default function Launchpad() {
  // const classes = useStyles()
  const [tab, setTab] = React.useState(0)
  const [filter, setFilter] = useState<FilterType>(FilterType.all)
  const { pads, loading, introduction } = useGetLaunchpads()
  const [computedPads, setComputedPads] = useState<LaunchPad[]>([])
  const userPads = useGetUserPads()
  const stakeTokenAddress = useMemo(() => {
    const res = pads.map((item) => item.stakedToken.address.toLowerCase() || '')
    return Array.from(new Set(res))
  }, [pads])

  // update fetchToken state to fetch reward token price
  useGetTokensPrice(stakeTokenAddress)

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    if ([0, 1, 2].indexOf(newValue) >= 0) {
      setTab(newValue)
    }
  }

  const baseModal = useGetLaunchpadBaseModal()
  const unlimitModal = useGetLaunchpadUnlimitModal()

  const userPadIds = useMemo(() => {
    return userPads.map((item) => item?.pad?.id)
  }, [userPads])

  function getComputedPads() {
    // beacuse pad status default is fail before stakingEndTime
    let res: LaunchPad[] = []
    if (tab === 1) {
      res = pads
        .filter(
          (item) =>
            item.period === PadPeriod.Ended ||
            (item.period === PadPeriod.Vesting && item.status === PadStatus.Fail) ||
            (item.period === PadPeriod.Cashing && item.status === PadStatus.Fail)
        )
        .sort((a, b) => {
          return Number(b.pid) - Number(a.pid)
        })
    } else if (tab === 0) {
      res = pads
        .filter(
          (item) =>
            item.period === PadPeriod.Prepare ||
            item.period === PadPeriod.Staking ||
            (item.period === PadPeriod.Vesting && item.status === PadStatus.Success) ||
            (item.period === PadPeriod.Cashing && item.status === PadStatus.Success)
        )
        .sort((a, b) => {
          return Number(b.pid) - Number(a.pid)
        })
    } else if (tab === 2 && userPadIds.length) {
      res = pads
        .filter((item) => userPadIds.indexOf(item.id) >= 0)
        .sort((a, b) => {
          return SortPeriod[a.period] - SortPeriod[b.period]
        })
    }

    setComputedPads(res)
  }

  const userPadsInfo = useMemo(() => {
    let res: {
      [key: string]: {
        cash: string
        paid: string
      }
    } = {}
    for (let i = 0, len = userPads.length; i < len; i++) {
      res[userPads[i].pad.id] = {
        cash: userPads[i].cashedAmount,
        paid: userPads[i].paidAmount,
      }
    }
    return res
  }, [userPads])

  const filterPads = useMemo(() => {
    if (filter === FilterType.base) return computedPads.filter((item) => item.poolType === PadType.basic)
    if (filter === FilterType.unlimit) return computedPads.filter((item) => item.poolType === PadType.unlimited)
    return computedPads
  }, [filter, computedPads])

  useEffect(() => {
    getComputedPads()
  }, [pads, tab, userPadIds])

  const filterPadNum = useMemo(() => {
    const all = computedPads.length
    const base = computedPads.filter((item) => item.poolType === PadType.basic).length
    const unlimit = computedPads.filter((item) => item.poolType === PadType.unlimited).length

    return {
      all,
      base,
      unlimit,
    }
  }, [computedPads])

  const selectStyles = useStyles()
  const minimalSelectClasses = useMinimalSelectStyles()

  // moves the menu below the select input
  const menuProps = {
    classes: {
      paper: minimalSelectClasses.paper,
      list: minimalSelectClasses.list,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  }
  menuProps.classes.list = `${menuProps.classes.list} ${selectStyles.menuRoot}`
  menuProps.classes.paper = selectStyles.menuPaper

  return (
    <LaunchpadBox>
      <LaunchpadWraaper>
        <StyledTabs value={tab} onChange={handleChange} aria-label="launchpad">
          <StyledTab label="Live" {...a11yProps(0)} />
          <StyledTab label="Closed" {...a11yProps(1)} />
          <StyledTab label="Participated" {...a11yProps(2)} />

          <Select
            disableUnderline
            className="launchpadSelect"
            classes={{ root: selectStyles.root, select: selectStyles.select }}
            MenuProps={menuProps as MenuProps}
            IconComponent={() => {
              return <img src={require('../../assets/images/ic_select.png')} alt="ic_select" />
            }}
            value={filter}
          >
            <MenuItem
              classes={{
                root: selectStyles.menuItem,
              }}
              value={FilterType.all}
              onClick={() => {
                setFilter(FilterType.all)
              }}
            >
              All({filterPadNum.all})
            </MenuItem>
            <MenuItem
              classes={{
                root: selectStyles.menuItem,
              }}
              value={FilterType.base}
              onClick={() => {
                setFilter(FilterType.base)
              }}
            >
              Primary({filterPadNum.base})
            </MenuItem>
            <MenuItem
              classes={{
                root: selectStyles.menuItem,
              }}
              value={FilterType.unlimit}
              onClick={() => {
                setFilter(FilterType.unlimit)
              }}
            >
              Unlimited({filterPadNum.unlimit})
            </MenuItem>
          </Select>
        </StyledTabs>
        {/* <Tabs value={value} onChange={handleChange} aria-label="launchpad">
          <Tab label="Next" {...a11yProps(0)} />
          <Tab label="Past" {...a11yProps(1)} />
        </Tabs> */}
        <LaunchpadWrapper>
          {filterPads.length ? (
            filterPads.map((item) => (
              <Pad
                key={item.id}
                data={item}
                baseModal={baseModal}
                unlimitModal={unlimitModal}
                userPadInfo={userPadsInfo[item.id]}
                project={introduction[item.saleToken.address.toLowerCase() as string]}
              />
            ))
          ) : (
            <div style={{ marginTop: '50px' }}>{!pads.length && loading ? 'Loading...' : 'No records'}</div>
          )}
        </LaunchpadWrapper>
      </LaunchpadWraaper>
      {/* {tab === 0 ? <Guide title="IDO Guide" text="xxxx" /> : <Guide title="Launchpad Guide" text="xxxx" />} */}
      <Link href="https://docs.netswap.io/use-guide/launchpad">Launchpad Guide</Link>
    </LaunchpadBox>
  )
}
