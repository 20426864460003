import { gql } from '@apollo/client'
import { MicroServicesClient } from '../index'

interface Token {
  symbol: string
  name: string
  address: string
  decimals: number
}

interface GraphAssetsPool {
  id: string
  stakeContractAddress: string
  rewardToken: Token
  stakeToken: Token
  tvl: number
  apy: number
  periodFinish: number
}

export interface AssetsPool {
  id: string
  stakeContractAddress: string
  rewardToken: Token
  stakeToken: Token
  tvl: number
  apy: number
  period: Period
  periodFinish: number
}

export enum Period {
  prepare = 'Prepare',
  ongoing = 'Ongoing',
  ended = 'Ended'
}

export default function getAssetsPool(): Promise<AssetsPool[] | undefined> {
  // if (!name) return Promise.resolve([]);
  return new Promise(resolve => {
    MicroServicesClient()
      .query<{
        pools: GraphAssetsPool[]
      }>({
        query: gql`
          query pools {
            pools(skip: 0, limit: 100) {
              id
              stakeContractAddress
              rewardToken {
                address
                decimals
                symbol
                name
              }
              stakeToken {
                address
                decimals
                symbol
                name
              }
              apy
              tvl
              periodFinish
            }
          }
        `,
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        let returnData = undefined
        let { data } = res
        const date = new Date().getTime()
        if (data?.pools) {
          returnData = data.pools
            .map(item => {
              let period = Period.prepare
              const periodFinish = item.periodFinish * 1000
              if (periodFinish > 0) {
                if (periodFinish < date) {
                  period = Period.ended
                } else {
                  period = Period.ongoing
                }
              }
              return {
                ...item,
                tvl: Number(item.tvl),
                period,
                periodFinish
              }
            })
            .sort((a, b) => {
              return Number(b.id) - Number(a.id)
            })
        }
        resolve(returnData)
      })
      .catch((e: any) => {
        console.error(e)
        resolve(undefined)
      })
  })
}
