import React, { useEffect, useState, useMemo } from 'react'
import MaterialTable from 'material-table'
import moment from 'moment'
import styled from 'styled-components'
import { getSwapScoreRanks, getLPScoreRank, getOverviewInfo } from 'gql/useGraphqlQuery'
import { shortenAddress } from 'utils'
import { ValueFormat } from 'components/Value'

const ScoreRankingWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-flow: column wrap;
  `}
`

const ScoreRankingCon = styled.div`
  max-width: 400px;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 350px;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    max-width: 100%;
    margin-top: 16px;
  `}
`
const ScoreRankingTitle = styled.h2`
  margin: 0;
  height: 24px;
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.v2.t01};
  line-height: 24px;
`
const ScoreRankingUpTime = styled.p`
  font-size: 12px;
  font-weight: 400;
  margin: 8px 0 24px 0;
  color: ${({ theme }) => theme.v2.t01};
  line-height: 17px;
`
const ScoreRankingTable = styled.div`
  .MuiPaper-elevation2 {
    box-shadow: none;
  }
  .MuiTable-root {
    background-color: ${({ theme }) => theme.v2.bg01};
    color: ${({ theme }) => theme.v2.t01};
    font-size: 14px !important;
  }
  .MuiTableHead-root {
    height: 30px;
  }
  .MuiTableCell-head {
    background-color: ${({ theme }) => theme.v2.bg01} !important;
    color: ${({ theme }) => theme.v2.t02} !important;
    line-height: 20px !important;
  }
  .MuiTableCell-root {
    border: none;
    font-size: 14px !important;
    height: 40px;
    line-height: 40px;
    padding: 0;
    text-align: center;
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      font-size: 0.75rem !important;
    `}
  }
  .MuiTypography-body2 {
    color: #fff;
  }
  .MuiTablePagination-toolbar {
    height: 40px;
    min-height: 40px;
    justify-content: center;
    display: flex;
  }
  .MuiTablePagination-input {
    display: none;
  }
  .MuiTypography-caption {
    color: ${({ theme }) => theme.v2.t01};
    line-height: 24px;
  }
  .MuiTableFooter-root {
    padding-top: 24px;
    background-color: transparent !important;
  }
  .MuiTablePagination-spacer {
    flex: initial !important;
  }
  .MuiIconButton-root {
    padding: 0;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    margin: 0 8px;
    background-color: ${({ theme }) => theme.v2.bg02} !important;
  }
  .MuiIcon-root {
    color: ${({ theme }) => theme.v2.t01};
    font-size: 14px;
  }
  .MuiTableBody-root {
    height: 210px;
  }
  .MuiTableCell-body {
    color: rgba(255, 255, 255, 0.5);
  }
`

export default function ScoreRanking() {
  const [userScoreRanks, setuserScoreRanks] = useState<any[]>([])
  const [lpScoreRanks, setLpScoreRanks] = useState<any[]>([])
  const [updateTime, setUpdateTime] = useState(0)
  const [timeText, setTimeText] = useState(true)

  const dateString = useMemo(() => {
    const showText = timeText ? 'Scoring Plan: ' : 'Latest update: '
    const showTime = updateTime === 0 ? '--' : moment.utc(updateTime * 1000).format('YYYY-MM-DD HH:mm:ss') + '(UTC)'
    return showText + showTime
  }, [updateTime, timeText])

  // 获取overviewinfo
  useEffect(() => {
    getOverviewInfo()
      .then(total_result => {
        const startTime = total_result.overviewInfo.airdropStartTime
        const tmpTime = total_result.overviewInfo.lastAirdropUpdateTime
        if (tmpTime <= 0) {
          setUpdateTime(startTime <= 0 ? 0 : startTime)
        } else {
          setTimeText(false)
          setUpdateTime(tmpTime <= 0 ? 0 : tmpTime)
        }
        getSwapScoreRanks()
          .then(result => {
            const formData = result.swapScoreRanks.map((item, _) => {
              return {
                ...item
              }
            })
            setuserScoreRanks(formData)
          })
          .catch((e: any) => {
            console.error(e)
          })

        getLPScoreRank()
          .then(result => {
            const formData = result.lpScoreRanks.map((item, _) => {
              return {
                ...item
              }
            })
            setLpScoreRanks(formData)
          })
          .catch((e: any) => {
            console.error(e)
          })
      })
      .catch((e: any) => {
        console.error(e)
      })
  }, [])
  return (
    <ScoreRankingWrapper>
      <ScoreRankingCon>
        <ScoreRankingTitle>Top 100 Swap Score</ScoreRankingTitle>
        <ScoreRankingUpTime>{dateString}</ScoreRankingUpTime>
        <ScoreRankingTable>
          <RankInfo ranklist={userScoreRanks} showTitle="Swap Score/Share" />
        </ScoreRankingTable>
      </ScoreRankingCon>
      <ScoreRankingCon>
        <ScoreRankingTitle>Top 100 Liquidity Score</ScoreRankingTitle>
        <ScoreRankingUpTime>{dateString}</ScoreRankingUpTime>
        <ScoreRankingTable>
          <RankInfo ranklist={lpScoreRanks} showTitle="LP Score/Share" />
        </ScoreRankingTable>
      </ScoreRankingCon>
    </ScoreRankingWrapper>
  )
}

interface Props {
  ranklist: any[]
  showTitle: string
}

export function RankInfo({ ranklist, showTitle }: Props) {
  return (
    <>
      <MaterialTable
        options={{
          search: false,
          showTitle: false,
          toolbar: false,
          sorting: false,
          draggable: false,
          pageSize: 5
        }}
        columns={[
          { title: 'Rank', field: 'id', headerStyle: { width: '60px' } },
          {
            title: 'Address',
            field: 'userAddress',
            headerStyle: { width: '160px' },
            render(item) {
              return `${shortenAddress((item as any).userAddress)}`
            }
          },
          {
            title: showTitle,
            field: 'score,id',
            render(item) {
              return (
                <>
                  {(item as any).score < 0.001 ? '< 0.001' : <ValueFormat value={(item as any).score} decimals={3} />}{' '}
                  <span>/ {(item as any).share < 0.01 ? '< 0.01' : (item as any).share.toFixed(2)}%</span>
                </>
              )
            }
          }
        ]}
        data={ranklist}
      />
    </>
  )
}
