import React from 'react'
import styled from 'styled-components'
import { TYPE } from '../../theme'

interface Props {
  title: string
  text: string | React.ReactNode
  width?: string
}
const GuideWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 900px;
  margin: 0 auto;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 900px;
    width: 100%;
  `}
`

const GuideTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.text1};
  line-height: 24px;
  margin: 32px 0 24px;
`
const GuideCon = styled.div`
  border-radius: 16px;
  background-color: ${({ theme }) => theme.v2.bg02};
  color: ${({ theme }) => theme.v2.t01};
  padding: 24px 32px;
  width: 900px;
  min-height: 140px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
      padding: 12px 16px;
  `}
  p,
  em,
  h3,
  div {
    margin: 0;
    font-size: 16px;
    line-height: 22px;
  }
  p {
    margin-bottom: 8px;
  }
`
export default function Guide({ title, text, width }: Props) {
  return (
    <GuideWrapper>
      <GuideTitle>{title}</GuideTitle>
      <GuideCon>
        <TYPE.v2Main fontSize="14px" lineHeight="20px" fontWeight="400">
          {text}
        </TYPE.v2Main>
      </GuideCon>
    </GuideWrapper>
  )
}
