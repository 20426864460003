import { createReducer, nanoid } from '@reduxjs/toolkit'
import { isMobile } from 'react-device-detect'
import {
  addPopup,
  PopupContent,
  removePopup,
  updateBlockNumber,
  updateBlockTime,
  ApplicationModal,
  setOpenModal,
  ApplicationToggleKey,
  updatToggleKey,
  updateHeaderHeight,
} from './actions'

type PopupList = Array<{ key: string; show: boolean; content: PopupContent; removeAfterMs: number | null }>

export interface ApplicationState {
  readonly blockNumber: { readonly [chainId: number]: number }
  readonly blockTime: { readonly [chainId: number]: number }
  readonly popupList: PopupList
  readonly openModal: ApplicationModal | null
  readonly isChristmas: boolean
  readonly headerHeight: number
  readonly toggleKey: {
    [key in ApplicationToggleKey]: boolean
  }
  readonly showNoti: boolean
}

const initialState: ApplicationState = {
  blockNumber: {},
  blockTime: {},
  popupList: [],
  openModal: null,
  isChristmas: false,
  headerHeight: 0,
  toggleKey: {
    [ApplicationToggleKey.SWAP_CHART]: !isMobile,
    [ApplicationToggleKey.SWAP_HISTORY]: false,
    [ApplicationToggleKey.SWAP_UPDATE_TRADE]: false,
  },
  showNoti: false,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateBlockNumber, (state, action) => {
      const { chainId, blockNumber } = action.payload
      if (typeof state.blockNumber[chainId] !== 'number') {
        state.blockNumber[chainId] = blockNumber
      } else {
        state.blockNumber[chainId] = Math.max(blockNumber, state.blockNumber[chainId])
      }
    })
    .addCase(updateBlockTime, (state, action) => {
      const { chainId, blockTime } = action.payload
      if (blockTime && chainId) {
        state.blockTime[chainId] = blockTime
      }
    })
    .addCase(setOpenModal, (state, action) => {
      state.openModal = action.payload
    })
    .addCase(addPopup, (state, { payload: { content, key, removeAfterMs = 15000 } }) => {
      state.popupList = (key ? state.popupList.filter((popup) => popup.key !== key) : state.popupList).concat([
        {
          key: key || nanoid(),
          show: true,
          content,
          removeAfterMs,
        },
      ])
    })
    .addCase(removePopup, (state, { payload: { key } }) => {
      state.popupList.forEach((p) => {
        if (p.key === key) {
          p.show = false
        }
      })
    })
    .addCase(updatToggleKey, (state, { payload: { key, value } }) => {
      state.toggleKey[key] = value
    })
    .addCase(updateHeaderHeight, (state, { payload }) => {
      state.headerHeight = payload.value
    })
)
