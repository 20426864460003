import { Config_1 as Airdrop1, Config_2 as Airdrop2 } from 'constants/nettAirdrop'
import { Config as NettConfig } from 'constants/tokens/nett'
import { useContract } from './useContract'
import { BaseModalConfig, UnlimitModalConfig } from 'constants/launchpad'
import { Config as wNettConfig } from 'constants/tokens/wNett'
import { Config as LaunchpadV2HelperConfig } from 'constants/launchpadV2/helper'
import { VeNettStakingConfig } from 'constants/veNettStaking'
import { VeNETTConfig } from 'constants/tokens/veNett'
import { StakeConfig } from 'constants/farm'
import { BoostNetFarmProxyConfig } from 'constants/BoostedNettFarm'
import { FarmLensConfig } from 'constants/FarmLens'

export function useGetAirdrop1() {
  return useContract(Airdrop1)
}

export function useGetAirdrop2() {
  return useContract(Airdrop2)
}

export function useGetNett() {
  return useContract(NettConfig)
}

export function useGetLaunchpadBaseModal() {
  return useContract(BaseModalConfig)
}

export function useGetLaunchpadUnlimitModal() {
  return useContract(UnlimitModalConfig)
}

export function useGetWNett() {
  return useContract(wNettConfig)
}

export function useGetLaunchpadV2Helper() {
  return useContract(LaunchpadV2HelperConfig)
}

export function useGetVeNETT() {
  return useContract(VeNETTConfig)
}

export function useGetVeNETTStaking() {
  return useContract(VeNettStakingConfig)
}

export function useGetFarm() {
  return useContract(StakeConfig)
}

export function useGetBoostNettFarmProxy() {
  return useContract(BoostNetFarmProxyConfig)
}

export function useGetFarmLens() {
  return useContract(FarmLensConfig)
}
