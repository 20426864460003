/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
// import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { Box } from 'rebass/styled-components'
// import { isMobile } from 'react-device-detect'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import Numeral from 'numeral'

import Row from 'components/Row'
import Web3Status from 'components/Web3Status'
import { useActiveWeb3React } from '../../hooks'
// import TooltipInfo from 'components/TooltipInfo'
import TokenImage from 'components/TokenImage'
import TimeLine from './TimeLine'
import Ranking from './Ranking'
import { TimelineConfig, FaqConLs } from './Config'
// import { NavLink } from 'react-router-dom'
import {
  getTradeCompetitionRoundRank,
  RoundInfo,
  SwapVolRank,
  getUserInfo,
  UserInfo,
} from 'gql/microservices/trade-competition'
import Value from 'components/Value'
// import { ButtonV2 } from 'components/Button'
import { useDarkModeManager } from 'state/user/hooks'
import { TYPE, theme } from 'theme'
import moment from 'moment'
import { useAppSelector } from 'state'
import { NETWORK_CHAIN_ID } from 'connectors'
// import { shortenAddress } from 'utils'
import { useGetTokensPrice } from 'state/data/hooks/useTokenPrice'
import Banner from './Banner'
import BigNumber from 'bignumber.js'
import CupSrc from 'assets/images/trade_competition/cup.png'
import UserRankDarkBg from 'assets/images/trade_competition/user_rank_bg.dark.png'
import UserRankLightBg from 'assets/images/trade_competition/user_rank_bg.light.png'
import NeboHelperLightImgSrc from 'assets/images/trade_competition/nebo_helper.light.png'
import NeboHelperDarkImgSrc from 'assets/images/trade_competition/nebo_helper.dark.png'
import TotalPrizes from './TotalPrizes'
import JoinHelper from './JoinHelper'
import { useMedia } from 'react-use'

const TradeBox = styled(Box)`
  width: 100%;
  max-width: 1072px;
  display: flex;
  padding: 0 0 300px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    padding-bottom: 40px;
  `}
`
const TitleBox = styled.div`
  height: 43px;
  border-radius: 8px;
  background: linear-gradient(234deg, #00cfff 0%, #34c1ff 53%, #904bf9 100%);
  color: #ffffff;
  width: 220px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins-Bold';
  font-size: 16px;
  line-height: 18px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
    line-height: 14px;
  `}
`

const TradeWrapper = styled.div`
  width: 100%;
  /* max-width: 900px; */
  margin: 80px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .tradeTitle {
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
      text-transform: uppercase;
      font-size: 24px;
      font-weight: 600;
      color: #ffffff;
      line-height: 33px;
      margin: 0;

      ${({ theme }) => theme.mediaWidth.upToSmall`
        font-size: 16px;
        line-height: 20px;
      `}
    }
  }
`

const Column = styled(Row)`
  flex-direction: column;
`

const UserRankBox = styled.div`
  width: 904px;
  height: 267px;
  background-image: url(${({ theme }) => (theme.isDark ? UserRankDarkBg : UserRankLightBg)});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 200px;
  padding-top: 88px;
  display: flex;
  justify-content: center;
  border-radius: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: auto;
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    padding-top: 60px;
    padding-bottom: 12px;
  `}
`

const CupImg = styled.img`
  height: 209px;
  width: auto;
  position: absolute;
  left: 50%;
  top: -126px;
  transform: translateX(-50%);
  z-index: 2;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 160px;
    top: -110px;
  `}
`

const TradingVolNum = styled.span`
  display: block;
  color: #ffffff;
  line-height: 40px;
  background: linear-gradient(228deg, #00cfff 0%, #34c1ff 53%, #904bf9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 30px;
  font-family: 'Poppins-Bold';
  font-weight: bold;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 24px;
    line-height: 32px;
  `}
`

const TradeWrapperCon = styled.div`
  max-width: 900px;
  border-radius: 8px;
  margin-top: 40px;
  position: relative;

  &.detailsCon {
    min-height: 200px;
    background: #2c0c41;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ theme }) => theme.mediaWidth.upToSmall`
      font-size: 12px;
      line-height: 14px;
    `}
  }
  &.faqCon {
    min-height: 200px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
      font-size: 12px;
      line-height: 14px;
    `}
  }
  .MuiAccordion-root {
    width: 100%;
    background: ${({ theme }) =>
      theme.isDark ? 'linear-gradient(121deg, rgba(118, 170, 255, 0.18) 0%, rgba(82, 79, 148, 0.1) 100%)' : '#8D98F9'};
    padding: 0 18px 0;
    box-shadow: none;
    border-radius: 8px !important;
    color: #ffffff;
    cursor: pointer;
    margin-bottom: 16px;
    font-size: 18px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
      font-size: 12px;
      line-height: 14px;
    `}
  }
  .MuiAccordionSummary-content,
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 39px 0;
  }
  .MuiAccordionDetails-root {
    padding: 20px 0 40px;
    margin: 0 16px;
  }
`

const NeboHelperImg = styled.img`
  width: 326px;
  height: auto;
  position: absolute;
  left: -260px;
  bottom: -80px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`

const BlockInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  color: ${({ theme }) => (theme.isDark ? '#c0c0c0' : '#020423')};

  > .iconfont {
    font-size: 28px;
    margin-right: 8px;
    color: #ff6969;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;

    > .iconfont {
      font-size: 18px;
    }
  `}
`

export default function TradeCompetition() {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()
  const [rankingList, setRankingList] = useState<SwapVolRank[]>([])
  const [rankinglistLoading, setRankingListLoading] = useState(false)
  const [expanded, setExpanded] = useState<string | false>(false)
  const [roundInfo, setRoundInfo] = useState<RoundInfo>()
  const [userInfo, setUserInfo] = useState<UserInfo>()
  const { blockTime } = useAppSelector((state) => state.application)
  const { chainId } = useActiveWeb3React()
  const onChainTime = useMemo(() => {
    return blockTime[chainId || NETWORK_CHAIN_ID] || new Date().getTime()
  }, [blockTime, chainId])

  const [darkMode] = useDarkModeManager()
  const themeObject = useMemo(() => theme(darkMode), [darkMode])
  const isMobile = useMedia('(max-width: 768px)')

  const currentPeriodIndex: number = useMemo(() => {
    let find = 0

    for (let i = 0, len = TimelineConfig.length; i < len; i++) {
      if (onChainTime < TimelineConfig[i].time) {
        find = i - 1
        break
      } else if (i === len - 1) {
        find = i
      }
    }
    return find
  }, [onChainTime])

  async function getUserInfoData() {
    if (!account) return
    try {
      const res = await getUserInfo(account)
      if (res) {
        setUserInfo(res)
      }
    } catch (e) {
      console.error(e)
      console.error(`get trade graph userinfo error : ${account}`)
    }
  }

  const userRewardInfo = useMemo(() => {
    return userInfo ? userInfo.rewardRecords.filter((item) => item.round === 1)[0] : undefined
  }, [userInfo])

  const rewardTokensAddress = useMemo(() => {
    if (userRewardInfo?.tokens) {
      return userRewardInfo.tokens.map((item) => item.id)
    }
    return []
  }, [userRewardInfo])

  const rewardTokensPriceObj = useGetTokensPrice(rewardTokensAddress)

  const rewardValueUSD = useMemo(() => {
    if (userRewardInfo?.tokens) {
      let value = new BigNumber(0)
      for (let i = 0, len = userRewardInfo.tokens.length; i < len; i++) {
        const t = userRewardInfo.tokens[i]
        const price = rewardTokensPriceObj[t.id.toLowerCase()] ? rewardTokensPriceObj[t.id.toLowerCase()].price : 0
        value = value.plus(new BigNumber(t.amount).multipliedBy(price))
      }
      return value.toNumber()
    }
    return 0
  }, [userRewardInfo, rewardTokensPriceObj])

  async function getRankList() {
    setRankingListLoading(true)

    try {
      const data = await getTradeCompetitionRoundRank(1)
      if (data?.swapVolRanks.length) {
        setRankingList(data.swapVolRanks)
      }

      if (data?.roundInfo) {
        setRoundInfo(data.roundInfo)
      }
    } catch (e) {
      console.error('getRankList error')
      console.error(e)
    }
    setRankingListLoading(false)
  }

  const userRoundData = useMemo(() => {
    if (userInfo) {
      return userInfo.roundData.filter((item) => item.round === 1)[0]
    }

    return undefined
  }, [userInfo])

  useEffect(() => {
    if (currentPeriodIndex === 0) return
    getRankList()
    let interval = setInterval(() => {
      getRankList()
    }, 180000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [currentPeriodIndex])

  useEffect(() => {
    getUserInfoData()
    let interval = setInterval(() => {
      getUserInfoData()
    }, 180000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [account])

  const handleChange = (panel: string) => (event: React.ChangeEvent<any>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <TradeBox>
      <Banner round={roundInfo} />
      <TradeWrapper style={{ marginTop: 60 }}>
        {/* <TradeTitle title={'timeline'} isTipShow /> */}
        <TitleBox>{t('trade_competition.timeline_title')}</TitleBox>
        <TimeLine
          onChainTime={onChainTime}
          nextPointIndex={
            currentPeriodIndex === TimelineConfig.length - 1 ? TimelineConfig.length - 1 : currentPeriodIndex + 1
          }
        />

        <UserRankBox>
          <CupImg src={CupSrc} alt="" />
          {account ? (
            <>
              <Column style={{ alignItems: 'center' }}>
                <TYPE.title fontSize={isMobile ? 16 : 22} color="#ffffff">
                  {t('trade_competition.your_ranking')}
                </TYPE.title>
                <TYPE.title fontSize={isMobile ? 24 : 30} color="#ffffff">
                  {userRoundData ? (userRoundData.rank === -1 ? 'Blocked' : `# ${userRoundData.rank}`) : '# --'}
                </TYPE.title>

                {userRoundData && (
                  <TYPE.mainLg fontSize={isMobile ? 12 : 14} color="#ffffff" style={{ marginTop: 4 }}>
                    {t('trade_competition.latest_updated', {
                      time: moment.utc(userRoundData.lastUpdateTime * 1000).format('YYYY-MM-DD HH:mm(UTC)'),
                    })}
                  </TYPE.mainLg>
                )}

                <Row style={{ alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
                  <TYPE.title style={{ marginRight: 4 }} fontSize={isMobile ? 16 : 20} color="#ffffff">
                    {t('trade_competition.trading_vol')}:
                  </TYPE.title>

                  <TradingVolNum>{Numeral(userRoundData?.swapVolume || 0).format('$0,0.00')}</TradingVolNum>
                </Row>
              </Column>

              {userRewardInfo && (
                <Column style={{ alignItems: 'center', justifyContent: 'center' }}>
                  <TYPE.title fontSize={isMobile ? 16 : 20} color="#ffffff">
                    {t('trade_competition.my_prizes')}
                  </TYPE.title>
                  <TYPE.mainLg fontSize={isMobile ? 12 : 14} color="#ffffff">
                    ≈ $ <Value value={rewardValueUSD} />
                  </TYPE.mainLg>
                  <ul className="myPrizesInfoLs" style={{ width: '100%', flexWrap: 'wrap' }}>
                    {userRewardInfo.tokens.map((item) => (
                      <li className="myPrizesInfo" key={item.id}>
                        <TokenImage address={item.id} />
                        <TYPE.mainLg fontSize={isMobile ? 12 : 14} color="#ffffff">
                          {Number(item.amount)} {item.symbol}
                        </TYPE.mainLg>
                      </li>
                    ))}
                  </ul>
                  <TYPE.mainLg className="utctime" fontSize={isMobile ? 12 : 14} color="#ffffff">
                    {currentPeriodIndex === 3 ? 'Prizes will be distributed to your address' : 'Rewards distributed!'}
                  </TYPE.mainLg>
                </Column>
              )}
            </>
          ) : (
            <Column>
              <TYPE.title fontSize={isMobile ? 16 : 22} color="#ffffff">
                {t('trade_competition.your_ranking')}
              </TYPE.title>
              <TYPE.v2Main fontSize={isMobile ? 12 : 14} color="#ffffff" style={{ marginBottom: isMobile ? 24 : 32 }}>
                {t('trade_competition.check_rank')}
              </TYPE.v2Main>

              <Web3Status />
            </Column>
          )}
        </UserRankBox>
      </TradeWrapper>

      <TradeWrapper>
        <TitleBox>{t('trade_competition.prizes_title')}</TitleBox>
        <TotalPrizes />
      </TradeWrapper>

      <TradeWrapper>
        {/* <TradeTitle title={'Trade to increase your rank'} /> */}
        <TitleBox>{t('trade_competition.participate_title')}</TitleBox>
        <JoinHelper />
      </TradeWrapper>
      <TradeWrapper>
        {/* <TradeTitle title={'Ranking'} /> */}
        <TitleBox>{t('trade_competition.rank_title')}</TitleBox>
        {currentPeriodIndex === 0 ? (
          <TradeWrapperCon className="detailsCon">Coming soon</TradeWrapperCon>
        ) : (
          <Ranking data={rankingList} loading={rankinglistLoading} roundInfo={roundInfo} />
        )}
        <BlockInfo>
          <i className="iconfont icon-alert" />
          All rights reserved by Netswap. If the address is found to be cheating, it will be removed from the ranking.
        </BlockInfo>
      </TradeWrapper>
      <TradeWrapper>
        {/* <TradeTitle title={'Q&A'} /> */}
        <TitleBox>{t('trade_competition.faq')}</TitleBox>
        <TradeWrapperCon className="faqCon">
          {FaqConLs.map((item, index) => (
            <Accordion
              key={`panel-${index}`}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
            >
              <AccordionSummary
                expandIcon={<i className="iconfont icon-faq-dropdown" style={{ color: '#ffffff', fontSize: 24 }} />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                {item.question}
              </AccordionSummary>
              <AccordionDetails>{item.answer}</AccordionDetails>
            </Accordion>
          ))}

          <NeboHelperImg src={themeObject.isDark ? NeboHelperDarkImgSrc : NeboHelperLightImgSrc} alt="" />
        </TradeWrapperCon>
      </TradeWrapper>
    </TradeBox>
  )
}

// export function TradeTitle({ title, isTipShow }: { title: string; isTipShow?: boolean }) {
//   const TradeTitle = styled.div`
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     h2 {
//       text-transform: uppercase;
//       font-size: 24px;
//       font-weight: 600;
//       color: #ffffff;
//       line-height: 33px;
//       margin: 0;
//       ${({ theme }) => theme.mediaWidth.upToExtraSmall`
//         font-size: 18px;
//       `};
//     }
//     .tradeIcon {
//       ${({ theme }) => theme.mediaWidth.upToExtraSmall`
//         width: 40px;
//       `};
//     }
//   `
//   return (
//     <TradeTitle>
//       <img className="tradeIcon" src={require('../../assets/images/ic_left.png')} alt="ic_left" />
//       <h2>
//         {title}
//         {isTipShow && (
//           <TooltipInfo text={'Andromeda Block Time'}>
//             <img
//               style={{ marginLeft: '5px', verticalAlign: 'middle' }}
//               src={require('../../assets/images/ic_bang.png')}
//               alt="ic_bang"
//             />
//           </TooltipInfo>
//         )}
//       </h2>
//       <img className="tradeIcon" src={require('../../assets/images/ic_right.png')} alt="ic_right" />
//     </TradeTitle>
//   )
// }
