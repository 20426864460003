/* eslint-disable react-hooks/exhaustive-deps */
import { useActiveWeb3React } from 'hooks'
import { useEffect, useMemo, useState } from 'react'
import useGetFarmAssetsPool from 'state/data/hooks/useGetFarmAssetsPools'
import { useGetTokensPrice } from 'state/data/hooks/useTokenPrice'
import { getContract } from 'utils'
import AssetStakeABI from 'constants/assets-farm/assets-stake.json'
import BigNumber from 'bignumber.js'
import { useGetNett } from 'hooks/contract/useGetContract'
import { AssetsPool } from 'gql/microservices/assets-pools'

interface PendingReward {
  rewardToken: AssetsPool['rewardToken']
  amount: BigNumber
}

export default function useGetStakingRewardsInfo() {
  const pools = useGetFarmAssetsPool()
  const { library, account } = useActiveWeb3React()
  const nettContract = useGetNett()
  const [totalStakeAmount, setTotalStakeAmount] = useState(new BigNumber(0))
  const [totalUserStakeBalance, setTotalUserStakeBalance] = useState(new BigNumber(0))
  const [pendingRewards, setPendingRewards] = useState<PendingReward[]>([])
  const [userPoolIds, setUserPoolIds] = useState<string[]>([])
  const highestAprPool = useMemo(() => {
    let res = [...pools]
    if (res.length) {
      res = res.sort((a, b) => {
        return b.apy - a.apy
      })
      return res[0]
    } else {
      return null
    }
  }, [pools])

  const tokensRequestPrice = useMemo(() => {
    const tokens = Array.prototype.concat.apply(
      [],
      pools.map((p) => {
        return [p.stakeToken.address, p.rewardToken.address]
      })
    )
    return Array.from(new Set(new Set(tokens)))
  }, [pools])

  const tokensPrice = useGetTokensPrice(tokensRequestPrice)

  async function getInfos() {
    if (library && nettContract) {
      const allPromise = []
      for (let i = 0, len = pools.length; i < len; i++) {
        const p: Promise<{
          poolStakeBalance: BigNumber
          stakeBalance: BigNumber
          pendingRewards: PendingReward
          userPool: {
            isUserPool: boolean
            poolId: string
          }
        }> = new Promise(async (resolve) => {
          const pool = pools[i]
          const stakeContract = getContract(pool.stakeContractAddress, AssetStakeABI, library, account || undefined)
          async function getRewardBalance() {
            if (stakeContract && account && pool) {
              try {
                const res = await stakeContract.earned(account)

                const reward = res?.toString()
                  ? new BigNumber(res.toString()).shiftedBy(-pool.rewardToken.decimals)
                  : new BigNumber(0)
                return reward
              } catch (e) {
                console.error(e)
                console.error(`getRewardBalance error`, pool)
              }
            }
            return new BigNumber(0)
          }

          async function getStakeBalance() {
            if (stakeContract && account) {
              try {
                const res = await stakeContract.balanceOf(account)
                return res?.toString()
                  ? new BigNumber(res.toString()).shiftedBy(pool ? -pool.stakeToken.decimals : -18)
                  : new BigNumber(0)
              } catch (e) {
                console.error(e)
                console.error(`getStakeBalance error`, pool)
              }
            }
            return new BigNumber(0)
          }

          async function getPoolNettBalance() {
            if (nettContract && account) {
              try {
                const res = await nettContract.balanceOf(pool.stakeContractAddress)
                return res?.toString()
                  ? new BigNumber(res.toString()).shiftedBy(pool ? -pool.stakeToken.decimals : -18)
                  : new BigNumber(0)
              } catch (e) {
                console.error(e)
                console.error(`getPoolNettBalance error`, pool)
              }
            }
            return new BigNumber(0)
          }
          const poolStakeBalance = await getPoolNettBalance()
          let stakeBalance = new BigNumber(0)
          let pendingRewards = new BigNumber(0)
          if (account) {
            stakeBalance = await getStakeBalance()
            pendingRewards = await getRewardBalance()
          }
          resolve({
            poolStakeBalance,
            stakeBalance,
            pendingRewards: {
              rewardToken: pool.rewardToken,
              amount: pendingRewards,
            },
            userPool: {
              isUserPool: stakeBalance.isGreaterThan(0),
              poolId: pool.id,
            },
          })
        })

        allPromise.push(p)
      }
      Promise.all(allPromise).then((values) => {
        let totalStake = new BigNumber(0)
        let userStake = new BigNumber(0)
        let userPending: PendingReward[] = []
        const usePoolsRes: string[] = []
        for (let i = 0, len = values.length; i < len; i++) {
          const res = values[i]
          totalStake = totalStake.plus(res.poolStakeBalance)
          userStake = userStake.plus(res.stakeBalance)
          userPending.push(res.pendingRewards)
          if (res.userPool.isUserPool) {
            usePoolsRes.push(res.userPool.poolId)
          }
        }
        setTotalStakeAmount(totalStake)
        setTotalUserStakeBalance(userStake)
        setPendingRewards(userPending)
        setUserPoolIds(usePoolsRes)
      })
    }
  }

  useEffect(() => {
    if (library && nettContract) {
      getInfos()
    }
  }, [library, account, nettContract])

  const totalStakeUSD = useMemo(() => {
    if (tokensPrice && nettContract?.address) {
      return totalStakeAmount.multipliedBy(tokensPrice[nettContract.address.toLowerCase()]?.price || 0).toNumber()
    }
    return 0
  }, [totalStakeAmount, tokensPrice, nettContract])

  const totalUserStakeUSD = useMemo(() => {
    if (tokensPrice && nettContract?.address) {
      return totalUserStakeBalance.multipliedBy(tokensPrice[nettContract.address.toLowerCase()]?.price || 0).toNumber()
    }
    return 0
  }, [totalUserStakeBalance, tokensPrice, nettContract])

  const pendingRewardsUSD = useMemo(() => {
    if (tokensPrice && pendingRewards.length) {
      let usd = new BigNumber(0)
      for (let i = 0, len = pendingRewards.length; i < len; i++) {
        let p = pendingRewards[i]
        usd = usd.plus(p.amount.multipliedBy(tokensPrice[p.rewardToken.address.toLowerCase()]?.price))
      }
      return usd.toNumber()
    }
    return 0
  }, [pendingRewards, tokensPrice])

  return {
    highestAprPool,
    totalStakeUSD,
    totalUserStakeUSD,
    pendingRewardsUSD,
    userPoolIds,
  }
}
