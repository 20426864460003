import { gql } from '@apollo/client'
import { BoostedFarmSubgraph } from '../index'

export default function getUserBoostedFarmPools(user: string): Promise<string[]> {
  return new Promise((resolve, reject) => {
    BoostedFarmSubgraph()
      .query<{
        users: {
          pool: {
            id: string
          }
        }[]
      }>({
        query: gql`
          query users($user: String) {
            users(where: { address: $user }) {
              pool {
                id
              }
            }
          }
        `,
        variables: {
          user: user.toLowerCase()
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        const { data } = res
        if (data.users.length) {
          return resolve(data.users.map(item => item.pool.id))
        }
        resolve([])
      })
      .catch((e: any) => {
        resolve([])
      })
  })
}
