import React from 'react'
import styled from 'styled-components'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { TYPE } from 'theme'
import { useHistory } from 'react-router-dom'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.v2.t01};
  width: fit-content;
  cursor: pointer;

  .title {
    font-size: 32px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
        font-size: 20px;
        font-weight: bold;
    `}
  }
`

const StyledArrow = styled(ArrowBackIosIcon)`
  height: 28px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
        height: 20px !important;
    `}
`

export default function BackPooList() {
  const history = useHistory()
  return (
    <Wrapper
      onClick={() => {
        history.push('/pool')
      }}
    >
      <StyledArrow />
      <TYPE.v2Main className="title">Pools</TYPE.v2Main>
    </Wrapper>
  )
}
