import React, { useEffect, useCallback, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import Row from 'components/Row'
import { RoundInfo, RoundStatus } from 'gql/microservices/trade-competition'
// import { throttle } from 'utils'

const Line = styled.span`
  display: block;
  position: absolute;
  height: calc(100% - 70px);
  left: 5px;
  top: 35px;
  background-color: ${({ theme }) => (theme.isDark ? 'rgba(255,255,255, 0.13)' : '#e2e4eb')};
  width: 2px;
  z-index: 1;
`

const ItemPoint = styled.span`
  display: block;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background-color: #3e4260;
  margin-right: 9px;
`

const ItemBox = styled.div`
  width: 210px;
  height: 70px;
  border-radius: 8px;
  background-color: ${({ theme }) => (theme.isDark ? '#2d2d4e' : '#8d98f9')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  padding: 14px 9px;

  ${Row} {
    .phase {
      font-size: 16px;
      flex: 1;
      display: block;
      margin-left: 8px;
      color: ${({ theme }) => (theme.isDark ? '#c0c0c0' : '#ffffff')};
    }

    .iconfont {
      font-size: 21px;
      color: rgba(255, 255, 255, 0.39);
    }
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 170px;
  `}
`

const StatusTag = styled.span`
  display: block;
  padding: 4px 6px;
  display: flex;
  min-width: 62px;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  font-size: 12px;
  transform: scale(0.8);
  background-color: ${({ theme }) => (theme.isDark ? '#162149' : '#ffffff')};
  color: ${({ theme }) => (!theme.isDark ? '#162149' : '#ffffff')};
`

const ProgressBar = styled.div`
  width: 100%;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;

  & > div {
    width: 0;
    height: 100%;
    background-color: #ffffff;
    border-radius: 4px;
  }
`

const RoundItem = styled.div`
  display: flex;
  z-index: 2;
  align-items: center;
  margin-bottom: 14px;
  font-family: 'Poppins-Medium';

  &.active {
    ${ItemPoint} {
      background-color: #00cfff;
    }

    ${ItemBox} {
      background-color: #00cfff;
      cursor: default;

      ${ProgressBar} {
        background-color: rgba(2, 4, 35, 0.59);

        & > div {
          background-image: linear-gradient(221deg, #746efb 0%, #34c1ff 53%, #904bf9 100%);
        }
      }

      ${StatusTag} {
        background-color: #162149;
        color: #ffffff;
      }
    }

    .iconfont {
      background: linear-gradient(232deg, #00cfff 0%, #34c1ff 53%, #904bf9 100%);
      color: transparent;
      -webkit-background-clip: text;
    }

    .phase {
      color: #020423;
    }
  }
`

const Wrapper = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;

  ${RoundItem}:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 190px;
  `}
`

export interface Props {
  rounds: RoundInfo[]
  selectedRound: RoundInfo | null
  onRoundSelect(round: RoundInfo): void
}

export default function RoundsLists({ rounds, selectedRound, onRoundSelect }: Props) {
  const { t } = useTranslation()
  const [offsetTop, setOffsetTop] = useState<number>(0)
  const [viewRounds, setViewRounds] = useState<RoundInfo[]>([])

  const handleScroll = useCallback(
    (event: any) => {
      setOffsetTop(event.target.scrollTop)
    },
    [setOffsetTop]
  )

  useEffect(() => {
    const scrollDom = document.getElementById('pageContainer')

    // const _handler = throttle(handleScroll, 100)
    const _handler = handleScroll

    if (scrollDom) {
      scrollDom.addEventListener('scroll', _handler)

      return () => scrollDom.removeEventListener('scroll', _handler)
    }

    return () => {}
  }, [handleScroll])

  useEffect(() => {
    const _rounds: RoundInfo[] = [...rounds].sort((a, b) => b.round - a.round)

    setViewRounds(_rounds)
  }, [rounds])

  return (
    <Wrapper style={{ top: offsetTop }}>
      <Line />

      {viewRounds.map((round) => {
        let statusIcon = 'icon-cup'

        if (round.status === RoundStatus.END || round.status === RoundStatus.DISTRIBUTION) {
          statusIcon = 'icon-running-man'
        }

        let process = 0
        const timeNow = Math.floor(new Date().getTime() / 1000)

        if (timeNow >= round.endTime) {
          process = 100
        } else if (timeNow >= round.startTime && timeNow < round.endTime) {
          process = Math.floor(((timeNow - round.startTime) / (round.endTime - round.startTime)) * 100)
        }

        return (
          <RoundItem key={round.id} className={selectedRound?.id === round.id ? 'active' : ''}>
            <ItemPoint />

            <ItemBox
              onClick={() => {
                if (selectedRound?.id !== round.id) {
                  onRoundSelect(round)
                }
              }}
            >
              <Row style={{ alignItems: 'center' }}>
                <i className={cx('iconfont', statusIcon)} />

                <span className="phase">Phase {round.round}</span>

                <StatusTag>{t(`round_status.${round.status}`)}</StatusTag>
              </Row>

              <ProgressBar>
                <div style={{ width: `${process}%` }} />
              </ProgressBar>
            </ItemBox>
          </RoundItem>
        )
      })}
    </Wrapper>
  )
}
