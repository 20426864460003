import { gql } from '@apollo/client'
import { BigNumber } from 'bignumber.js'
import { ExchangeSubgraph } from '../index'

export interface UserLiquidityPool {
  liquidityTokenBalance: string
  pair: {
    id: string

    token0: {
      symbol: string
      name: string
      id: string
    }
    token1: {
      symbol: string
      name: string
      id: string
    }
    reserve0: string
    reserve1: string
    liquidityTokenBalance: string
    totalSupply: string
  }
  token0Amount: number
  token1Amount: number
  share: number
}

export default function getUserLiquidityPositions(user: string): Promise<UserLiquidityPool[]> {
  // if (!name) return Promise.resolve([]);
  return new Promise((resolve, reject) => {
    ExchangeSubgraph()
      .query<{
        liquidityPositions: UserLiquidityPool[]
      }>({
        query: gql`
          query liquidityPositions($user: String) {
            liquidityPositions(where: { user: $user, liquidityTokenBalance_gt: 0 }) {
              pair {
                id

                totalSupply
                reserve0
                reserve1
                token0 {
                  symbol
                  name
                  id
                }
                token1 {
                  symbol
                  name
                  id
                }
              }
              liquidityTokenBalance
            }
          }
        `,
        variables: {
          user: user.toLowerCase()
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        const { data } = res
        if (data.liquidityPositions) {
          return resolve(
            data.liquidityPositions.map(item => {
              return {
                ...item,
                token0Amount: new BigNumber(item.pair.reserve0)
                  .multipliedBy(item.liquidityTokenBalance)
                  .div(item.pair.totalSupply)
                  .toNumber(),
                token1Amount: new BigNumber(item.pair.reserve1)
                  .multipliedBy(item.liquidityTokenBalance)
                  .div(item.pair.totalSupply)
                  .toNumber(),
                share: new BigNumber(item.liquidityTokenBalance)
                  .multipliedBy(100)
                  .div(item.pair.totalSupply)
                  .toNumber()
              }
            })
          )
        }
        resolve([])
      })
      .catch((e: any) => {
        resolve([])
      })
  })
}
