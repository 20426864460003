// import React from 'react'
// import styled from 'styled-components'
// import LoadingImgSrc from 'assets/images/loading.gif'

// const LoadingWrapper = styled.div`
//   position: absolute;
//   height: 100%;
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   z-index: 2;
//   top: 0;
//   left: 0;
//   border-radius: 16px;
// `

// export default function Loading({
//   show,
//   size = 48,
//   bgColor = 'transparent'
// }: {
//   show: boolean
//   size?: number
//   bgColor?: string
// }) {
//   return (
//     <LoadingWrapper style={{ display: show ? 'flex' : 'none', backgroundColor: bgColor }}>
//       <img src={LoadingImgSrc} alt="" style={{ width: size, height: size }} />
//     </LoadingWrapper>
//   )
// }
import React from 'react'
import styled, { keyframes } from 'styled-components'
import LogoSrc from 'assets/images/netswap-logo.png'

const pulse = keyframes`
  0% { transform: scale(1); }
  60% { transform: scale(1.1); }
  100% { transform: scale(1); }
`

const Wrapper = styled.div<{ show?: boolean; background?: string }>`
  pointer-events: none;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: ${({ background }) => background || 'transparent'};
`

const AnimatedImg = styled.div<{ size: number | string }>`
  animation: ${pulse} 800ms linear infinite;
  & > * {
    height: auto;
    width: ${({ size }) => (typeof size === 'string' ? size : `${size}px`)};
  }
`

const LocalLoader = ({
  show,
  size = 48,
  bgColor,
  className
}: {
  show: boolean
  size?: number | string
  bgColor?: string
  className?: string
}) => {
  return (
    <Wrapper className={className} show={show} background={bgColor}>
      <AnimatedImg size={size}>
        <img src={LogoSrc} alt="loading-icon" />
      </AnimatedImg>
    </Wrapper>
  )
}

export default LocalLoader
