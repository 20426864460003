import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { XAxis, YAxis, ResponsiveContainer, Tooltip, LineChart, Line, CartesianGrid } from 'recharts'
import { AutoRow, RowBetween } from 'components/Row'

import { toK, toNiceDate, toNiceDateYear, formattedNum, getTimeframe } from 'utils/infos'
import { OptionButton } from '../ButtonStyled'
import { useMedia } from 'react-use'
import { TimeframeOptions } from 'constants/index'
import DropdownSelect from '../DropdownSelect'
import { UserLP, useUserPositionChart } from 'hooks/infos/User'
import { useTimeframe } from 'hooks/infos/Application'
import LocalLoader from 'components/Loading'
import { useColor } from 'hooks/useColor'
import { useDarkModeManager } from 'state/user/hooks'

const ChartWrapper = styled.div`
  max-height: 420px;

  @media screen and (max-width: 600px) {
    min-height: 200px;
  }
`

const OptionsRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 40px;
`

const CHART_VIEW = {
  VALUE: 'Value',
  FEES: 'Fees'
}

const PairReturnsChart = ({ account, position }: { account: string; position: UserLP }) => {
  const { t } = useTranslation()
  let data = useUserPositionChart(position, account)

  const [timeWindow, setTimeWindow] = useTimeframe()

  const below600 = useMedia('(max-width: 600px)')

  const color = useColor()

  const [chartView, setChartView] = useState(CHART_VIEW.VALUE)

  // based on window, get starttime
  let utcStartTime = getTimeframe(timeWindow)
  data = data?.filter(entry => entry.date >= utcStartTime)

  const aspect = below600 ? 60 / 42 : 60 / 16

  const [darkMode] = useDarkModeManager()
  const textColor = darkMode ? 'white' : 'black'

  return (
    <ChartWrapper>
      {below600 ? (
        <RowBetween mb={40}>
          <div />
          <DropdownSelect options={TimeframeOptions} active={timeWindow} setActive={setTimeWindow} />
        </RowBetween>
      ) : (
        <OptionsRow>
          <AutoRow gap="6px" style={{ flexWrap: 'nowrap' }}>
            <OptionButton active={chartView === CHART_VIEW.VALUE} onClick={() => setChartView(CHART_VIEW.VALUE)}>
              {t('analytics.liquidity')}
            </OptionButton>
            <OptionButton active={chartView === CHART_VIEW.FEES} onClick={() => setChartView(CHART_VIEW.FEES)}>
              {t('analytics.fees')}
            </OptionButton>
          </AutoRow>
          <AutoRow justify="flex-end" gap="6px">
            <OptionButton
              active={timeWindow === TimeframeOptions.WEEK}
              onClick={() => setTimeWindow(TimeframeOptions.WEEK)}
            >
              {t('analytics.1w')}
            </OptionButton>
            <OptionButton
              active={timeWindow === TimeframeOptions.MONTH}
              onClick={() => setTimeWindow(TimeframeOptions.MONTH)}
            >
              {t('analytics.1m')}
            </OptionButton>
            <OptionButton
              active={timeWindow === TimeframeOptions.ALL_TIME}
              onClick={() => setTimeWindow(TimeframeOptions.ALL_TIME)}
            >
              {t('analytics.all')}
            </OptionButton>
          </AutoRow>
        </OptionsRow>
      )}
      <ResponsiveContainer aspect={aspect}>
        {data ? (
          <LineChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }} barCategoryGap={1} data={data}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={color} stopOpacity={0.35} />
                <stop offset="95%" stopColor={color} stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              tickLine={false}
              axisLine={false}
              interval="preserveEnd"
              tickMargin={14}
              tickFormatter={tick => toNiceDate(tick)}
              dataKey="date"
              tick={{ fill: textColor }}
              type={'number'}
              domain={['dataMin', 'dataMax']}
            />
            <YAxis
              type="number"
              orientation="right"
              tickFormatter={tick => '$' + toK(tick)}
              axisLine={false}
              tickLine={false}
              interval="preserveStartEnd"
              minTickGap={0}
              yAxisId={0}
              tick={{ fill: textColor }}
            />
            <Tooltip
              cursor={true}
              formatter={(val: number) => formattedNum(val, true)}
              labelFormatter={label => toNiceDateYear(label)}
              labelStyle={{ paddingTop: 4 }}
              contentStyle={{
                padding: '10px 14px',
                borderRadius: 10,
                borderColor: color,
                color: 'black'
              }}
              wrapperStyle={{ top: -70, left: -10 }}
            />

            <Line
              type="monotone"
              dataKey={chartView === CHART_VIEW.VALUE ? 'usdValue' : 'fees'}
              stroke={color}
              yAxisId={0}
              name={chartView === CHART_VIEW.VALUE ? 'Liquidity' : 'Fees Earned (Cumulative)'}
            />
          </LineChart>
        ) : (
          <LocalLoader show={true} />
        )}
      </ResponsiveContainer>
    </ChartWrapper>
  )
}

export default PairReturnsChart
