import { useEffect, useState } from 'react'
import initWeb3 from './init-web3'

export default function useContractByChainId(getContractFunc: any, provider: any, chainId?: number) {
  const [contract, setContract] = useState()

  useEffect(() => {
    if (getContractFunc && provider && chainId) {
      const web3 = initWeb3(provider)
      const contractInstance = getContractFunc(chainId, web3)
      setContract(contractInstance)
    }
  }, [getContractFunc, provider, chainId])

  return contract
}
