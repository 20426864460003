import { Web3Provider } from '@ethersproject/providers'
import { ChainId, Currency, Token } from '@netswap/sdk'
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { NETWORK_CONFIG, isEnvChain } from 'utils'
import { injected } from '../connectors'
import { NetworkContextName } from '../constants'

export function useActiveWeb3React(): Web3ReactContextInterface<Web3Provider> & { chainId?: ChainId } {
  const context = useWeb3ReactCore<Web3Provider>()
  const contextNetwork = useWeb3ReactCore<Web3Provider>(NetworkContextName)
  return context.active ? context : contextNetwork
}

export function useEagerConnect() {
  const { activate, active } = useWeb3ReactCore() // specifically using useWeb3ReactCore because of what this hook does
  const [tried, setTried] = useState(false)

  useEffect(() => {
    injected.isAuthorized().then(isAuthorized => {
      if (isAuthorized) {
        activate(injected, undefined, true).catch(() => {
          setTried(true)
        })
      } else {
        if (isMobile && window.ethereum) {
          activate(injected, undefined, true).catch(() => {
            setTried(true)
          })
        } else {
          setTried(true)
        }
      }
    })
  }, [activate]) // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (active) {
      setTried(true)
    }
  }, [active])

  return tried
}

/**
 * Use for network and injected - logs user in
 * and out after checking what network theyre on
 */
export function useInactiveListener(suppress = false) {
  const { active, error, activate } = useWeb3ReactCore() // specifically using useWeb3React because of what this hook does

  useEffect(() => {
    const { ethereum } = window

    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleChainChanged = () => {
        // eat errors
        activate(injected, undefined, true).catch(error => {
          console.error('Failed to activate after chain changed', error)
        })
      }

      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
          // eat errors
          activate(injected, undefined, true).catch(error => {
            console.error('Failed to activate after accounts changed', error)
          })
        }
      }

      ethereum.on('chainChanged', handleChainChanged)
      ethereum.on('accountsChanged', handleAccountsChanged)

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged)
          ethereum.removeListener('accountsChanged', handleAccountsChanged)
        }
      }
    }
    return undefined
  }, [active, error, suppress, activate])
}

export function useGetSymbol(chainId: ChainId | undefined, currency: Currency | undefined | null | Token) {
  const [symbol, setSymbol] = useState<string>()

  useEffect(() => {
    if (chainId && currency && currency.symbol) {
      if (isEnvChain(chainId) && currency.symbol === 'ETH' && currency.name === 'Ether') {
        setSymbol(NETWORK_CONFIG[chainId].tokenSymbol)
      } else {
        setSymbol(currency.symbol)
      }
    } else {
      setSymbol(undefined)
    }
  }, [chainId, currency])

  return { symbol }
}

export function useFormatTime() {
  const { t, i18n } = useTranslation()
  const [util, setUtil] = useState({ formatTime: (unix: number): string => '' })

  /* eslint-disable */
  useEffect(() => {
    setUtil({
      formatTime: (unix: number) => {
        const now = dayjs()
        const timestamp = dayjs.unix(unix)

        const inSeconds = now.diff(timestamp, 'second')
        const inMinutes = now.diff(timestamp, 'minute')
        const inHours = now.diff(timestamp, 'hour')
        const inDays = now.diff(timestamp, 'day')

        if (inHours >= 24) {
          return inDays === 1 ? t('day_ago', { number: inDays }) : t('days_ago', { number: inDays })
        } else if (inMinutes >= 60) {
          return inHours === 1 ? t('hour_ago', { number: inHours }) : t('hours_ago', { number: inHours })
        } else if (inSeconds >= 60) {
          return inMinutes === 1 ? t('minute_ago', { number: inMinutes }) : t('minutes_ago', { number: inMinutes })
        } else {
          return inSeconds === 1 ? t('second_ago', { number: inSeconds }) : t('seconds_ago', { number: inSeconds })
        }
      }
    })
  }, [i18n])
  /* eslint-enable */

  return util
}
