import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { TYPE } from 'theme'
import Panel from 'components/Infos/Panel'
import { useAllPairData } from 'hooks/infos/PairData'
import PairList from 'components/Infos/PairList'
import { PageWrapper, FullWrapper } from 'components/Infos/index'
import { RowBetween } from 'components/Row'
import { useMedia } from 'react-use'

function AllPairsPage() {
  const { t } = useTranslation()
  const allPairs = useAllPairData()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const below800 = useMedia('(max-width: 800px)')

  return (
    <PageWrapper>
      <FullWrapper>
        <RowBetween>
          <TYPE.title fontSize={26}>{t('analytics.all_pairs')}</TYPE.title>
        </RowBetween>
        <Panel style={{ padding: below800 ? '1rem 0.5rem 0' : '' }}>
          <PairList pairs={allPairs} maxItems={50} />
        </Panel>
      </FullWrapper>
    </PageWrapper>
  )
}

export default AllPairsPage
