import BigNumber from 'bignumber.js'
import { useState } from 'react'
import { useContractByAbiAddr } from './useContract'

export enum Phase {
  Prepare,
  Deposit,
  SaleEnded,
  Launch
}

const Convert = {
  0: Phase.Prepare,
  1: Phase.Deposit,
  2: Phase.SaleEnded,
  3: Phase.Launch
}

export default function useLaunchpadV2PadContract(id: string, abi: any) {
  const padContract = useContractByAbiAddr(id, abi)
  const [currentPhase, setCurrencPhase] = useState<Phase>(Phase.Prepare)
  const [minDeposit, setMinDeposit] = useState(new BigNumber(0))
  const [userRefunds, setUserRefunds] = useState(new BigNumber(0))
  const [userStakePaymentTokenAmount, setUserStakePaymentTokenAmount] = useState(new BigNumber(0))
  const [userAllocation, setUserAllocation] = useState(new BigNumber(0))
  const [userHasClaimedRefunds, setUserHasClaimedRefunds] = useState(false)
  const [stopped, setStopped] = useState(false)

  async function getUserInfo(account?: string, issuedTokenDecimals?: number) {
    try {
      if (!padContract || !account || !issuedTokenDecimals) return
      const res = await padContract.getUserInfo(account)

      if (res) {
        const balance = new BigNumber(res.balance.toString()).shiftedBy(-6)

        setUserStakePaymentTokenAmount(balance)

        const hasClaimedRefunds = res.hasClaimedRefunds

        setUserHasClaimedRefunds(hasClaimedRefunds)
      }

      const allocRes = await padContract.getUserAllocation(account)

      if (allocRes) {
        const allocation = new BigNumber(allocRes.toString()).shiftedBy(-issuedTokenDecimals)

        setUserAllocation(allocation)
      }
    } catch (e) {
      console.error(`getUserInfo error, acount ${account}`)
      console.error(e)
    }
  }

  async function getPadCurrenctPhase(): Promise<Phase | undefined> {
    try {
      if (!padContract) return undefined
      try {
        const res = await padContract.currentPhase()
        if (res) {
          const phase = Convert[res as keyof typeof Convert]
          setCurrencPhase(phase)
          return phase
        }
      } catch (e) {
        console.error(`getPadCurrenctPhase error, contract ${id}`)
        console.error(e)
      }
    } catch (e) {
      console.error()
      console.error(e)
    }

    return undefined
  }

  async function getPadStoppedStatus(): Promise<boolean> {
    try {
      if (!padContract) return false
      try {
        const res = await padContract.stopped()
        setStopped(!!res)
        return !!res
      } catch (e) {
        console.error(`getPadStoppedStatus error, contract ${id}`)
        console.error(e)
      }
    } catch (e) {
      console.error()
      console.error(e)
    }

    return false
  }

  async function getMinDeposit(): Promise<BigNumber> {
    try {
      try {
        if (padContract) {
          const res = await padContract.minDeposit()
          if (res) {
            const min = new BigNumber(res.toString()).shiftedBy(-6)
            setMinDeposit(min)
            return min
          }
        }
      } catch (e) {
        console.error(`getMinDeposit error, contract ${id}`)
        console.error(e)
      }
    } catch (e) {
      console.error()
      console.error(e)
    }

    return new BigNumber(0)
  }

  async function getUserRefunds(account?: string): Promise<BigNumber> {
    try {
      try {
        if (padContract && account) {
          const res = await padContract.getUserRefunds(account)
          if (res) {
            const refund = new BigNumber(res.toString()).shiftedBy(-6)
            setUserRefunds(refund)
            return refund
          }
        }
      } catch (e) {
        console.error(`getUserRefunds error, contract ${id}`)
        console.error(e)
      }
    } catch (e) {
      console.error()
      console.error(e)
    }

    return new BigNumber(0)
  }

  return {
    padContract,
    getPadCurrenctPhase,
    currentPhase,
    getMinDeposit,
    minDeposit,
    getUserInfo,
    userStakePaymentTokenAmount,
    userAllocation,
    getUserRefunds,
    userRefunds,
    userHasClaimedRefunds,
    getPadStoppedStatus,
    stopped
  }
}
