import React, { useState, useEffect } from 'react'
import { useMedia } from 'react-use'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import dayjs from 'dayjs'
import BigNumber from 'bignumber.js'
import { useTranslation } from 'react-i18next'
import LocalLoader from 'components/Loading'
import utc from 'dayjs/plugin/utc'
import { Box, Flex } from 'rebass'
import styled from 'styled-components'
import Link, { CustomLink } from '../Link'
import DoubleTokenLogo from '../DoubleLogo'
import { formattedNum, getPoolLink } from 'utils/infos'
import { AutoColumn } from 'components/Column'
import { useMetisPrice } from 'hooks/infos/GlobalData'
import { RowFixed } from 'components/Row'
import { ButtonLight } from 'components/Button'
import { TYPE } from 'theme'
import FormattedName from '../FormattedName'
import { UserLP } from 'hooks/infos/User'

dayjs.extend(utc)

const PageButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 0.5em;
`

const Arrow = styled.div<{ faded?: boolean }>`
  color: ${({ theme }) => theme.v2.t05};
  opacity: ${(props) => (props.faded ? 0.3 : 1)};
  padding: 0 20px;
  user-select: none;
  :hover {
    cursor: pointer;
  }
`

const List = styled(Box)`
  -webkit-overflow-scrolling: touch;
`

const DashGrid = styled.div<{ focus?: boolean; center?: boolean }>`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 5px 0.5fr 1fr 1fr;
  grid-template-areas: 'number name netswap return';
  align-items: flex-start;
  padding: 20px 0;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};

  > * {
    justify-content: center;
    width: 100%;

    :first-child {
      justify-content: flex-start;
      text-align: left;
      width: 20px;
    }

    :first-child {
      justify-content: flex-end;
      text-align: right;
    }
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: 35px 2.5fr 1fr 1fr;
    grid-template-areas: 'number name netswap return';
  }

  @media screen and (max-width: 740px) {
    grid-template-columns: 2.5fr 1fr 1fr;
    grid-template-areas: 'name netswap return';
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 2.5fr 1fr;
    grid-template-areas: 'name netswap';
  }
`

const ListWrapper = styled.div``

const ClickableText = styled(TYPE.v2Main)`
  text-align: center;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  user-select: none;
  color: ${({ theme }) => theme.v2.t02};
  font-size: 12px !important;
`

const DataText = styled(TYPE.mainLg)`
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.v2.t01};
  & > * {
    font-size: 1em;
  }

  @media screen and (max-width: 600px) {
    font-size: 13px;
  }
`

const SORT_FIELD = {
  VALUE: 'VALUE',
  NETSWAP_RETURN: 'NETSWAP_RETURN',
}

function PositionList({
  positions,
}: {
  positions: UserLP[]
} & RouteComponentProps<any>) {
  const { t } = useTranslation()
  const below500 = useMedia('(max-width: 500px)')
  const below740 = useMedia('(max-width: 740px)')

  // pagination
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const ITEMS_PER_PAGE = 10

  // sorting
  const [sortDirection, setSortDirection] = useState(true)
  const [sortedColumn, setSortedColumn] = useState(SORT_FIELD.VALUE)

  useEffect(() => {
    setMaxPage(1) // edit this to do modular
    setPage(1)
  }, [positions])

  useEffect(() => {
    if (positions) {
      let extraPages = 1
      if (positions.length % ITEMS_PER_PAGE === 0) {
        extraPages = 0
      }
      setMaxPage(Math.floor(positions.length / ITEMS_PER_PAGE) + extraPages || 1)
    }
  }, [positions])

  const [metisPrice] = useMetisPrice()

  const ListItem = ({ position, index }: { position: UserLP; index: number }) => {
    const poolOwnership = parseFloat(position.liquidityTokenBalance) / parseFloat(position.pair.totalSupply)
    const valueUSD = poolOwnership * parseFloat(position.pair.reserveUSD)

    return (
      <DashGrid style={{ opacity: poolOwnership > 0 ? 1 : 0.6 }} focus={true}>
        {!below740 && <DataText>{index}</DataText>}
        <DataText>
          <AutoColumn gap="8px" justify="flex-start">
            <DoubleTokenLogo size={16} a0={position.pair.token0.id} a1={position.pair.token1.id} margin={!below740} />
          </AutoColumn>
          <AutoColumn gap="8px" justify="flex-start" style={{ marginLeft: '20px' }}>
            <CustomLink to={'/analytics/pair/' + position.pair.id}>
              <TYPE.v2Main style={{ whiteSpace: 'nowrap' }}>
                <FormattedName
                  text={position.pair.token0.symbol + '-' + position.pair.token1.symbol}
                  maxCharacters={below740 ? 10 : 18}
                />
              </TYPE.v2Main>
            </CustomLink>

            <RowFixed gap="8px" justify="flex-start">
              <Link
                external
                href={getPoolLink(position.pair.token0.id, position.pair.token1.id)}
                style={{ marginRight: '.5rem' }}
              >
                <ButtonLight padding={'4px 6px'} borderRadius={'4px'}>
                  {'Add'}
                </ButtonLight>
              </Link>
              {poolOwnership > 0 && (
                <Link external href={getPoolLink(position.pair.token0.id, position.pair.token1.id, true)}>
                  <ButtonLight style={{ padding: '4px 6px', borderRadius: '4px' }}>Remove</ButtonLight>
                </Link>
              )}
            </RowFixed>
          </AutoColumn>
        </DataText>
        <DataText>
          <AutoColumn gap="12px" justify="flex-end">
            <TYPE.v2Main>{formattedNum(valueUSD, true, true)}</TYPE.v2Main>
            <AutoColumn gap="4px" justify="flex-end">
              <RowFixed>
                <TYPE.small fontWeight={400}>
                  {formattedNum(poolOwnership * parseFloat(position.pair.reserve0))}{' '}
                </TYPE.small>
                <FormattedName
                  text={position.pair.token0.symbol}
                  maxCharacters={below740 ? 10 : 18}
                  margin={true}
                  fontSize={'11px'}
                />
              </RowFixed>
              <RowFixed>
                <TYPE.small fontWeight={400}>
                  {formattedNum(poolOwnership * parseFloat(position.pair.reserve1))}{' '}
                </TYPE.small>
                <FormattedName
                  text={position.pair.token1.symbol}
                  maxCharacters={below740 ? 10 : 18}
                  margin={true}
                  fontSize={'11px'}
                />
              </RowFixed>
            </AutoColumn>
          </AutoColumn>
        </DataText>
        {!below500 && (
          <DataText>
            <AutoColumn gap="12px" justify="flex-end">
              <TYPE.v2Main color={'green'}>
                <RowFixed>{formattedNum(position?.fees.sum, true, true)}</RowFixed>
              </TYPE.v2Main>
              <AutoColumn gap="4px" justify="flex-end">
                <RowFixed>
                  <TYPE.small fontWeight={400}>
                    {parseFloat(position.pair.token0.derivedMETIS)
                      ? formattedNum(
                          position?.fees.sum / (parseFloat(position.pair.token0.derivedMETIS) * metisPrice) / 2,
                          false,
                          true
                        )
                      : 0}{' '}
                  </TYPE.small>
                  <FormattedName
                    text={position.pair.token0.symbol}
                    maxCharacters={below740 ? 10 : 18}
                    margin={true}
                    fontSize={'11px'}
                  />
                </RowFixed>
                <RowFixed>
                  <TYPE.small fontWeight={400}>
                    {parseFloat(position.pair.token1.derivedMETIS)
                      ? formattedNum(
                          position?.fees.sum / (parseFloat(position.pair.token1.derivedMETIS) * metisPrice) / 2,
                          false,
                          true
                        )
                      : 0}{' '}
                  </TYPE.small>
                  <FormattedName
                    text={position.pair.token1.symbol}
                    maxCharacters={below740 ? 10 : 18}
                    margin={true}
                    fontSize={'11px'}
                  />
                </RowFixed>
              </AutoColumn>
            </AutoColumn>
          </DataText>
        )}
      </DashGrid>
    )
  }

  const positionsSorted =
    positions &&
    positions

      .sort((p0, p1) => {
        // if (sortedColumn === SORT_FIELD.PRINCIPAL) {
        //   return p0?.principal?.usd > p1?.principal?.usd ? (sortDirection ? -1 : 1) : sortDirection ? 1 : -1
        // }
        // if (sortedColumn === SORT_FIELD.HODL) {
        //   return p0?.hodl?.sum > p1?.hodl?.sum ? (sortDirection ? -1 : 1) : sortDirection ? 1 : -1
        // }
        if (sortedColumn === SORT_FIELD.NETSWAP_RETURN) {
          return p0?.netswap?.return > p1?.netswap?.return ? (sortDirection ? -1 : 1) : sortDirection ? 1 : -1
        }
        if (sortedColumn === SORT_FIELD.VALUE) {
          const bal0 = new BigNumber(p0.liquidityTokenBalance).div(p0.pair.totalSupply).multipliedBy(p0.pair.reserveUSD)
          const bal1 = new BigNumber(p1.liquidityTokenBalance).div(p1.pair.totalSupply).multipliedBy(p1.pair.reserveUSD)
          return bal0.gt(bal1) ? (sortDirection ? -1 : 1) : sortDirection ? 1 : -1
        }
        return 1
      })
      .slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE)
      .map((position, index) => {
        return (
          <div key={index}>
            <ListItem key={index} index={(page - 1) * 10 + index + 1} position={position} />
          </div>
        )
      })

  return (
    <ListWrapper>
      <DashGrid center={true} style={{ height: '32px', padding: 0 }}>
        {!below740 && (
          <Flex alignItems="flex-start">
            <TYPE.v2Main color="t02" fontSize={12}>
              #
            </TYPE.v2Main>
          </Flex>
        )}
        <Flex alignItems="flex-start">
          <TYPE.v2Main color="t02" fontSize={12}>
            {t('analytics.name')}
          </TYPE.v2Main>
        </Flex>
        <Flex alignItems="center">
          <ClickableText
            onClick={(e) => {
              setSortedColumn(SORT_FIELD.VALUE)
              setSortDirection(sortedColumn !== SORT_FIELD.VALUE ? true : !sortDirection)
            }}
          >
            {below740 ? t('analytics.value') : t('analytics.liquidity')}{' '}
            {sortedColumn === SORT_FIELD.VALUE ? (!sortDirection ? '↑' : '↓') : ''}
          </ClickableText>
        </Flex>
        {!below500 && (
          <Flex alignItems="center">
            <ClickableText
              onClick={() => {
                setSortedColumn(SORT_FIELD.NETSWAP_RETURN)
                setSortDirection(sortedColumn !== SORT_FIELD.NETSWAP_RETURN ? true : !sortDirection)
              }}
            >
              {below740 ? t('analytics.fees') : t('analytics.total_fees_earned')}{' '}
              {sortedColumn === SORT_FIELD.NETSWAP_RETURN ? (!sortDirection ? '↑' : '↓') : ''}
            </ClickableText>
          </Flex>
        )}
      </DashGrid>
      <List p={0}>{!positionsSorted ? <LocalLoader show={true} /> : positionsSorted}</List>
      <PageButtons>
        <div onClick={() => setPage(page === 1 ? page : page - 1)}>
          <Arrow faded={page === 1 ? true : false}>
            <i className="iconfont icon-page-prev" />
          </Arrow>
        </div>
        <TYPE.body>{t('analytics.page_of', { current: page, total: maxPage })}</TYPE.body>
        <div onClick={() => setPage(page === maxPage ? page : page + 1)}>
          <Arrow faded={page === maxPage ? true : false}>
            <i className="iconfont icon-page-next" />
          </Arrow>
        </div>
      </PageButtons>
    </ListWrapper>
  )
}

export default withRouter(PositionList)
