/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import { setBaseTokensInfos } from '../index'

const METIS_NETT_COINGECKO_URL =
  'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=metis-token%2Cnetswap&order=market_cap_desc&per_page=100&page=1&sparkline=false'

// nett token and metis token info
export function useGetBaseTokensOverviewInfo() {
  const dispatch = useAppDispatch()

  const { baseTokensInfos } = useAppSelector((state) => state.netswapData)

  const getTokensInfo = useCallback(async function (): Promise<void> {
    try {
      const res = await fetch(METIS_NETT_COINGECKO_URL)

      const resJson = await res.json()
      const infos = [
        {
          symbol: 'METIS',
          price: resJson[0].current_price,
          maxSupply: resJson[0].max_supply,
          circulatingSupply: resJson[0].circulating_supply,
          fdv: resJson[0].fully_diluted_valuation,
        },
        {
          symbol: 'NETT',
          price: resJson[1].current_price,
          maxSupply: resJson[1].max_supply,
          circulatingSupply: resJson[1].circulating_supply,
          fdv: resJson[1].fully_diluted_valuation,
        },
      ]

      dispatch(setBaseTokensInfos({ data: infos }))
    } catch (err) {
      console.error(err)
    }
  }, [])

  useEffect(() => {
    getTokensInfo()

    let interval = setInterval(() => {
      getTokensInfo()
    }, 60000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  return baseTokensInfos
}
