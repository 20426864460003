/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'
import { BigNumber } from 'bignumber.js'

const useAllowance = (contract: any, spender: any, address: string, refresh?: boolean) => {
  const [allowance, setAllowance] = useState(new BigNumber(0))

  const fetchAllowance = useCallback(async () => {
    try {
      const allowance: string = await contract.methods.allowance(address, spender).call()
      setAllowance(new BigNumber(allowance))
    } catch (e: any) {
      return e.message
    }
  }, [address, spender, contract])

  useEffect(() => {
    if (address && spender && contract) {
      fetchAllowance()
    }
    const refreshInterval = setInterval(fetchAllowance, 10000)
    return () => clearInterval(refreshInterval)
  }, [address, spender, contract, refresh])

  return {
    allowance,
  }
}

export default useAllowance
