import { gql } from '@apollo/client'
import { DexCandlesSubgraph } from '../index'
import { ChainId } from '@netswap/sdk'
import { USD_PRICE_TOKEN, BASE_PRICE_TOKEN } from '../../constants'

export interface Pair {
  id: string
  token0: Token
  token1: Token
}

export interface Token {
  id: string
  name: string
  symbol: string
  decimals: string
}

export interface Candle {
  // time + period + srcToken + dstToken
  id: string
  time: number
  period: number
  lastBlock: number
  token0: Token
  token1: Token

  token0TotalAmount: string
  token1TotalAmount: string
  open: string
  close: string
  low: string
  high: string
}

export interface Period {
  key: string
  value: number
}

export const PERIODS: Period[] = [
  {
    key: '5m',
    value: 5 * 60
  },
  {
    key: '15m',
    value: 15 * 60
  },
  {
    key: '1h',
    value: 3600
  },
  {
    key: '4h',
    value: 4 * 3600
  },
  {
    key: '1d',
    value: 24 * 3600
  },
  {
    key: '1w',
    value: 7 * 24 * 3600
  }
]

const GET_USD_PAIR_BY_TOKENS = gql`
  query pair($token0: Bytes!, $token1Arr: [Bytes]!) {
    pairs0: pairs(where: { token0: $token0, token1_in: $token1Arr }) {
      id
      token0 {
        id
        name
        symbol
      }

      token1 {
        id
        name
        symbol
      }
    }

    pairs1: pairs(where: { token0_in: $token1Arr, token1: $token0 }) {
      id
      token0 {
        id
        name
        symbol
      }

      token1 {
        id
        name
        symbol
      }
    }
  }
`

const GET_PAIR_BY_TOKENS = gql`
  query pair($token0: Bytes!, $token1: Bytes!) {
    pairs0: pairs(where: { token0: $token0, token1: $token1 }) {
      id
      token0 {
        id
        name
        symbol
      }

      token1 {
        id
        name
        symbol
      }
    }

    pairs1: pairs(where: { token0: $token1, token1: $token0 }) {
      id
      token0 {
        id
        name
        symbol
      }

      token1 {
        id
        name
        symbol
      }
    }
  }
`

const GET_CANDLES_BY_TOKENS = gql`
  query candles($token0: Bytes!, $token1: Bytes!, $limit: Int!, $endTime: Int!, $period: Int!) {
    candles(
      where: { token0: $token0, token1: $token1, period: $period, time_lte: $endTime }
      first: $limit
      orderBy: time
      orderDirection: desc
    ) {
      id
      time
      period
      lastBlock
      token0 {
        id
        name
        symbol
        decimals
      }
      token1 {
        id
        name
        symbol
        decimals
      }

      token0TotalAmount
      token1TotalAmount
      open
      close
      low
      high
    }
  }
`

export interface GetCandlesParams {
  token0: string
  token1: string
  endTime: number
  limit: number
  period: number
}

export async function getUsdCandlesByTokens({
  token0,
  token1,
  endTime,
  limit,
  period
}: GetCandlesParams): Promise<{ pair?: Pair; candles: Candle[] }> {
  try {
    const filterNotBaseTokensRes = [token0.toLowerCase(), token1.toLowerCase()].filter(
      address => BASE_PRICE_TOKEN[ChainId.MAINNET].map(token => token.address.toLowerCase()).indexOf(address) === -1
    )

    if (filterNotBaseTokensRes.length === 2 || filterNotBaseTokensRes.length === 0) {
      return { candles: [] }
    }

    const tokenAddr = filterNotBaseTokensRes[0]

    const searchPairs = await DexCandlesSubgraph().query<{ pairs0: Pair[]; pairs1: Pair[] }>({
      query: GET_USD_PAIR_BY_TOKENS,
      variables: {
        token0: tokenAddr,
        token1Arr: USD_PRICE_TOKEN[ChainId.MAINNET].map(token => token.address.toLowerCase())
      }
    })

    if (searchPairs.data.pairs0.length === 0 && searchPairs.data.pairs1.length === 0) {
      return {
        candles: []
      }
    }

    if (searchPairs.data.pairs0.length === 0 && searchPairs.data.pairs1.length === 0) {
      return {
        candles: []
      }
    }

    let pair: Pair

    if (searchPairs.data.pairs0.length > 0) {
      pair = searchPairs.data.pairs0[0]
    } else {
      pair = searchPairs.data.pairs1[0]
    }

    const queryRes = await DexCandlesSubgraph().query<{ candles: Candle[] }>({
      query: GET_CANDLES_BY_TOKENS,
      variables: {
        token0: pair.token0.id,
        token1: pair.token1.id,
        endTime,
        limit,
        period
      }
    })

    return {
      pair: pair,
      candles: [...queryRes.data.candles].reverse()
    }
  } catch (err) {
    console.error(err)
    return { candles: [] }
  }
}

export async function getCandlesByTokens({
  token0,
  token1,
  endTime,
  limit,
  period
}: GetCandlesParams): Promise<{ pair?: Pair; candles: Candle[] }> {
  try {
    const searchPairs = await DexCandlesSubgraph().query<{ pairs0: Pair[]; pairs1: Pair[] }>({
      query: GET_PAIR_BY_TOKENS,
      variables: {
        token0: token0.toLowerCase(),
        token1: token1.toLowerCase()
      }
    })

    if (searchPairs.data.pairs0.length === 0 && searchPairs.data.pairs1.length === 0) {
      return {
        candles: []
      }
    }

    let pair: Pair

    if (searchPairs.data.pairs0.length > 0) {
      pair = searchPairs.data.pairs0[0]
    } else {
      pair = searchPairs.data.pairs1[0]
    }

    const queryRes = await DexCandlesSubgraph().query<{ candles: Candle[] }>({
      query: GET_CANDLES_BY_TOKENS,
      variables: {
        token0: pair.token0.id,
        token1: pair.token1.id,
        endTime,
        limit,
        period
      }
    })

    return {
      pair: pair,
      candles: [...queryRes.data.candles].reverse()
    }
  } catch (err) {
    console.error(err)
    return {
      candles: []
    }
  }
}

export async function getAllCandlesByTokens(
  params: GetCandlesParams
): Promise<{ pair?: Pair; candles: Candle[]; usdCandles: Candle[] }> {
  const { pair, candles } = await getCandlesByTokens(params)

  const filterNotUSDTokensRes = [params.token0.toLowerCase(), params.token1.toLowerCase()].filter(
    address => USD_PRICE_TOKEN[ChainId.MAINNET].map(token => token.address.toLowerCase()).indexOf(address) === -1
  )

  let usdCandles: Candle[] = []

  if (filterNotUSDTokensRes.length === 2) {
    usdCandles = (await getUsdCandlesByTokens(params)).candles
  }

  return {
    pair,
    candles: filterNotUSDTokensRes.length === 1 ? [] : candles,
    usdCandles: filterNotUSDTokensRes.length === 1 ? candles : usdCandles
  }
}
