/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'
import { useActiveWeb3React } from '../../hooks'
import useDebounce from '../../hooks/useDebounce'
import useIsWindowVisible from '../../hooks/useIsWindowVisible'
import { updateBlockNumber, updateBlockTime } from './actions'
import { useDispatch } from 'react-redux'
import { ChainId } from '@netswap/sdk'
// import { SWITCH_NETWORK_CONFIG } from '../../constants'
// import axios from 'axios'

export default function Updater(): null {
  const { library, chainId } = useActiveWeb3React()
  const dispatch = useDispatch()

  const windowVisible = useIsWindowVisible()

  const [state, setState] = useState<{ chainId: number | undefined; blockNumber: number | null }>({
    chainId,
    blockNumber: null,
  })

  const [blockTimeState, setBlockTimeState] = useState<number>(0)

  const blockNumberCallback = useCallback(
    (blockNumber: number) => {
      if (library && blockNumber) {
        library.getBlock(blockNumber).then((res) => {
          if (res?.timestamp) {
            setBlockTimeState(Number(res.timestamp) * 1000)
          }
        })
      }

      if (blockNumber) {
        setState((state) => {
          if (chainId === state.chainId) {
            if (typeof state.blockNumber !== 'number') return { chainId, blockNumber }
            return { chainId, blockNumber: Math.max(blockNumber, state.blockNumber) }
          }
          return state
        })
      }
    },
    [chainId, setState, setBlockTimeState]
  )

  function getBlockNumber() {
    if (library) {
      library
        .getBlockNumber()
        .then(blockNumberCallback)
        .catch((error) => console.error(`Failed to get block number for chainId: ${chainId}`, error))
    }
    // getBlockNumberByApi()
  }

  // async function getBlockNumberByApi() {
  //   if (chainId && chainId !== ChainId.MAINNET) {
  //     const explore = SWITCH_NETWORK_CONFIG[chainId].blockExplorerUrls[0]
  //     const res = await axios.get(`${explore}/api?module=block&action=eth_block_number`)
  //     if (res?.data?.result) {
  //       blockNumberCallback(Number(res.data.result))
  //     }
  //   }
  // }

  // attach/detach listeners
  useEffect(() => {
    if (!library || !chainId || !windowVisible) return undefined
    const intervalSecond = chainId === ChainId.MAINNET ? 8000 : 2000

    setState({ chainId, blockNumber: null })

    getBlockNumber()
    const interval = setInterval(() => {
      getBlockNumber()
    }, intervalSecond)

    library.on('block', blockNumberCallback)
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [dispatch, chainId, library, blockNumberCallback, windowVisible])

  const debouncedState = useDebounce(state, 100)

  useEffect(() => {
    if (!debouncedState.chainId || !debouncedState.blockNumber || !windowVisible) return
    dispatch(updateBlockNumber({ chainId: debouncedState.chainId, blockNumber: debouncedState.blockNumber }))
    dispatch(updateBlockTime({ chainId: debouncedState.chainId, blockTime: blockTimeState }))
  }, [windowVisible, dispatch, debouncedState.blockNumber, debouncedState.chainId, blockTimeState])

  return null
}
