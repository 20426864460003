import React, { useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useUserTransactions, useUserPositions, UserLP } from 'hooks/infos/User'
import TxnList from 'components/Infos/TxnList'
import Panel from 'components/Infos/Panel'
import { formattedNum } from 'utils/infos'
import Row, { AutoRow, RowFixed, RowBetween } from 'components/Row'
import { AutoColumn } from 'components/Column'
import UserChart from 'components/Infos/UserChart'
import PairReturnsChart from 'components/Infos/PairReturnsChart'
import PositionList from 'components/Infos/PositionList'
import { TYPE } from 'theme'
import { ButtonDropdown } from 'components/Infos/ButtonStyled'
import { PageWrapper, ContentWrapper, StyledIcon } from 'components/Infos/index'
import DoubleTokenLogo from 'components/Infos/DoubleLogo'
import { Bookmark, Activity } from 'react-feather'
import Link from 'components/Infos/Link'
import { FEE_WARNING_TOKENS, EXPLORE_URL } from 'constants/index'
import { BasicLink } from 'components/Infos/Link'
import { RouteComponentProps, withRouter } from 'react-router-dom'

const AccountWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.2);
  padding: 6px 16px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Header = styled.div``

const DashboardWrapper = styled.div`
  width: 100%;
`

const DropdownWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
  border: 1px solid #edeef2;
  border-radius: 12px;
`

const Flyout = styled.div`
  position: absolute;
  top: 38px;
  left: -1px;
  width: 100%;
  background-color: ${({ theme }) => (theme.isDark ? theme.v2.bg01 : theme.v2.bg02)};
  z-index: 999;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-top: 4px;
  border: 1px solid #edeef2;
  border-top: none;
`

const MenuRow = styled(Row)`
  width: 100%;
  padding: 12px 0;
  padding-left: 12px;

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.bg2};
  }
`

const PanelWrapper = styled.div`
  grid-template-columns: 1fr;
  grid-template-rows: max-content;
  gap: 6px;
  display: inline-grid;
  width: 100%;
  align-items: start;
`

const Warning = styled.div`
  background-color: ${({ theme }) => theme.v2.bg02};
  color: ${({ theme }) => theme.text1};
  padding: 1rem;
  font-weight: 600;
  border-radius: 10px;
  margin-bottom: 1rem;
  width: calc(100% - 2rem);
`

function AccountPage({ match }: RouteComponentProps<{ account: string }>) {
  const { t } = useTranslation()
  const account = match.params.account.toLowerCase()
  // get data for this account
  const transactions = useUserTransactions(account)
  const positions = useUserPositions(account)

  // get data for user stats
  const transactionCount = transactions?.swaps?.length + transactions?.burns?.length + transactions?.mints?.length

  // get derived totals
  let totalSwappedUSD = useMemo(() => {
    return transactions?.swaps
      ? transactions?.swaps.reduce((total, swap) => {
          return total + parseFloat(swap.amountUSD)
        }, 0)
      : 0
  }, [transactions])

  // if any position has token from fee warning list, show warning
  const [showWarning, setShowWarning] = useState(false)
  useEffect(() => {
    if (positions) {
      for (let i = 0; i < positions.length; i++) {
        if (
          FEE_WARNING_TOKENS.includes(positions[i].pair.token0.id) ||
          FEE_WARNING_TOKENS.includes(positions[i].pair.token1.id)
        ) {
          setShowWarning(true)
        }
      }
    }
  }, [positions])

  // settings for list view and dropdowns
  const hideLPContent = positions && positions.length === 0
  const [showDropdown, setShowDropdown] = useState(false)
  const [activePosition, setActivePosition] = useState<UserLP | null>()

  const dynamicPositions = activePosition ? [activePosition] : positions

  const aggregateFees = dynamicPositions?.reduce(function(total, position) {
    return total + position.fees.sum
  }, 0)

  const positionValue = useMemo(() => {
    return dynamicPositions
      ? dynamicPositions.reduce((total, position) => {
          return (
            total +
            (parseFloat(position?.liquidityTokenBalance) / parseFloat(position?.pair?.totalSupply)) *
              parseFloat(position?.pair?.reserveUSD || '0')
          )
        }, 0)
      : null
  }, [dynamicPositions])

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })
  }, [])

  return (
    <PageWrapper>
      <ContentWrapper>
        <RowBetween>
          <TYPE.v2Main>
            <BasicLink to="/accounts">{t('analytics.accounts') + ' '}</BasicLink>→{' '}
            <Link href={`${EXPLORE_URL}/address/${account}`} target="_blank">
              {' '}
              {account?.slice(0, 42)}{' '}
            </Link>
          </TYPE.v2Main>
        </RowBetween>
        <Header>
          <RowBetween>
            <span>
              <TYPE.title fontSize={24}>{account?.slice(0, 6) + '...' + account?.slice(38, 42)}</TYPE.title>
              <Link href={`${EXPLORE_URL}/address/${account}`} target="_blank">
                <TYPE.v2Main fontSize={14}>{t('analytics.view_on_metis_scan')}</TYPE.v2Main>
              </Link>
            </span>
            <AccountWrapper>
              <StyledIcon>
                <Bookmark style={{ opacity: 0.4 }} />
              </StyledIcon>
            </AccountWrapper>
          </RowBetween>
        </Header>
        <DashboardWrapper>
          {showWarning && <Warning>{t('analytics.account_page.warning')}</Warning>}
          {!hideLPContent && (
            <DropdownWrapper>
              <ButtonDropdown width="100%" onClick={() => setShowDropdown(!showDropdown)} open={showDropdown}>
                {!activePosition && (
                  <RowFixed>
                    <StyledIcon>
                      <Activity size={16} />
                    </StyledIcon>
                    <TYPE.v2Main ml={'10px'}>{t('analytics.account_page.all_positions')}</TYPE.v2Main>
                  </RowFixed>
                )}
                {activePosition && (
                  <RowFixed>
                    <DoubleTokenLogo a0={activePosition.pair.token0.id} a1={activePosition.pair.token1.id} size={16} />
                    <TYPE.v2Main ml={'16px'}>
                      {activePosition.pair.token0.symbol}-{activePosition.pair.token1.symbol}{' '}
                      {t('analytics.account_page.position')}
                    </TYPE.v2Main>
                  </RowFixed>
                )}
              </ButtonDropdown>
              {showDropdown && (
                <Flyout>
                  <AutoColumn gap="0px">
                    {positions?.map((p, i) => {
                      if (p.pair.token1.symbol === 'WMETIS') {
                        p.pair.token1.symbol = 'METIS'
                      }
                      if (p.pair.token0.symbol === 'WMETIS') {
                        p.pair.token0.symbol = 'METIS'
                      }
                      return (
                        p.pair.id !== activePosition?.pair.id && (
                          <MenuRow
                            onClick={() => {
                              setActivePosition(p)
                              setShowDropdown(false)
                            }}
                            key={i}
                          >
                            <DoubleTokenLogo a0={p.pair.token0.id} a1={p.pair.token1.id} size={16} />
                            <TYPE.v2Main ml={'16px'}>
                              {p.pair.token0.symbol}-{p.pair.token1.symbol} {t('analytics.account_page.position')}
                            </TYPE.v2Main>
                          </MenuRow>
                        )
                      )
                    })}
                    {activePosition && (
                      <MenuRow
                        onClick={() => {
                          setActivePosition(null)
                          setShowDropdown(false)
                        }}
                      >
                        <RowFixed>
                          <StyledIcon>
                            <Activity size={16} />
                          </StyledIcon>
                          <TYPE.v2Main ml={'10px'}>{t('analytics.account_page.all_positions')}</TYPE.v2Main>
                        </RowFixed>
                      </MenuRow>
                    )}
                  </AutoColumn>
                </Flyout>
              )}
            </DropdownWrapper>
          )}
          {!hideLPContent && (
            <Panel style={{ height: '100%', marginBottom: '1rem' }}>
              <AutoRow gap="20px">
                <AutoColumn gap="10px">
                  <RowBetween>
                    <TYPE.v2Main>{t('analytics.liquidity_including_fees')}</TYPE.v2Main>
                    <div />
                  </RowBetween>
                  <RowFixed align="flex-end">
                    <TYPE.title fontSize={'24px'} lineHeight={1}>
                      {positionValue
                        ? formattedNum(positionValue, true)
                        : positionValue === 0
                        ? formattedNum(0, true)
                        : '-'}
                    </TYPE.title>
                  </RowFixed>
                </AutoColumn>
                <AutoColumn gap="10px">
                  <RowBetween>
                    <TYPE.v2Main>{t('analytics.fees_earned_cumu')}</TYPE.v2Main>
                    <div />
                  </RowBetween>
                  <RowFixed align="flex-end">
                    <TYPE.title fontSize={'24px'} lineHeight={1} color={aggregateFees && 'green'}>
                      {aggregateFees ? formattedNum(aggregateFees, true, true) : '-'}
                    </TYPE.title>
                  </RowFixed>
                </AutoColumn>
              </AutoRow>
            </Panel>
          )}
          {!hideLPContent && (
            <PanelWrapper>
              <Panel style={{ gridColumn: '1' }}>
                {activePosition ? (
                  <PairReturnsChart account={account} position={activePosition} />
                ) : (
                  <UserChart account={account} />
                )}
              </Panel>
            </PanelWrapper>
          )}
          <TYPE.v2Main fontSize={'1.125rem'} style={{ marginTop: '3rem' }}>
            {t('analytics.account_page.positions')}
          </TYPE.v2Main>{' '}
          <Panel
            style={{
              marginTop: '1.5rem'
            }}
          >
            <PositionList positions={positions} />
          </Panel>
          <TYPE.v2Main fontSize={'1.125rem'} style={{ marginTop: '3rem' }}>
            {t('analytics.account_page.transactions')}
          </TYPE.v2Main>{' '}
          <Panel
            style={{
              marginTop: '1.5rem'
            }}
          >
            <TxnList transactions={transactions} />
          </Panel>
          <TYPE.v2Main fontSize={'1.125rem'} style={{ marginTop: '3rem' }}>
            {t('analytics.account_page.wallet_stats')}
          </TYPE.v2Main>{' '}
          <Panel
            style={{
              marginTop: '1.5rem'
            }}
          >
            <AutoRow gap="20px">
              <AutoColumn gap="8px">
                <TYPE.title fontSize={24}>{totalSwappedUSD ? formattedNum(totalSwappedUSD, true) : '-'}</TYPE.title>
                <TYPE.v2Main>{t('analytics.account_page.total_value_swapped')}</TYPE.v2Main>
              </AutoColumn>
              <AutoColumn gap="8px">
                <TYPE.title fontSize={24}>
                  {totalSwappedUSD ? formattedNum(totalSwappedUSD * 0.003, true) : '-'}
                </TYPE.title>
                <TYPE.v2Main>{t('analytics.account_page.total_fees_paid')}</TYPE.v2Main>
              </AutoColumn>
              <AutoColumn gap="8px">
                <TYPE.title fontSize={24}>{transactionCount ? transactionCount : '-'}</TYPE.title>
                <TYPE.v2Main>{t('analytics.account_page.total_trans')}</TYPE.v2Main>
              </AutoColumn>
            </AutoRow>
          </Panel>
        </DashboardWrapper>
      </ContentWrapper>
    </PageWrapper>
  )
}

export default withRouter(AccountPage)
