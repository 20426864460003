/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import { ButtonMetis } from 'components/Button'
import { ReactComponent as Close } from 'assets/images/x.svg'
import { TYPE } from 'theme'
import { LaunchPad } from 'gql/microservices/pads'
import TokenImage from 'components/TokenImage'
import { LaunchPadIntroduction } from 'state/data'

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`

const LaunchpadSection = styled.div`
  padding: 32px 40px;
  position: relative;
  ${({ theme }) => theme.flexColumnNoWrap}
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 24px 12px;
  `}
`

const CloseIcon = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
  color: #c42a61;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
const CloseColor = styled(Close)`
  path {
    stroke: '${({ theme }) => theme.text4}';
  }
`
const LaunchpadTitle = styled(TYPE.white)`
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
`

const LaunchpadCon = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  width: 100%;
  margin: 39px auto 32px;

  .launchpadConTitle {
    height: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    line-height: 20px;
    margin: 0;
  }
`

const LaunchpadSolidCon = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  justify-content: space-between;
  padding: 12px 16px;
  background: #221232;
  border-radius: 8px;
  align-item: center;
  margin: 16px 0;
  &.outlineTransParentBg {
    background: transparent;
    border: 1px solid #331b4b;
  }
  &.flexColumn {
    ${({ theme }) => theme.flexColumnNoWrap}
  }
  .launchpadConLeft {
    ${({ theme }) => theme.flexRowNoWrap}
    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }

  .launchpadConRight {
    ${({ theme }) => theme.flexRowNoWrap}
    ${({ theme }) => theme.mediaWidth.upToSmall`
      justify-content: space-between;
    `}
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }
`

// const LaunchpadTextHalfWhite = styled.span`
//   font-size: 14px;
//   font-weight: 400;
//   color: rgba(255, 255, 255, 0.5);
//   line-height: 20px;
// `

const LaunchpadBorderCon = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  width: 100%;
  border-radius: 8px;
  border: 1px solid #331b4b;
  padding: 12px 16px;
`

const ConfirmButton = styled(ButtonMetis)`
  border-radius: 8px;
  width: 90%;
  max-width: 420px;
  margin: 0 auto;
  height: 64px;
  margin-bottom: 0;
  font-size: 20px;
`

interface Props {
  isOpen: boolean
  onClose(): void
  padInfo: LaunchPad
  project?: LaunchPadIntroduction
}

export default function ProjectIntro({ isOpen, onClose, padInfo, project }: Props) {
  if (!project) return null
  return (
    <Modal isOpen={isOpen} onDismiss={onClose} minHeight={false} maxWidth={560} maxHeight={200}>
      <Wrapper>
        <LaunchpadSection>
          <CloseIcon onClick={onClose}>
            <CloseColor />
          </CloseIcon>
          <LaunchpadTitle>{project.name} Introduction</LaunchpadTitle>
          <LaunchpadCon>
            <h3 className="launchpadConTitle">Project</h3>
            <LaunchpadSolidCon className="outlineTransParentBg">
              <div className="launchpadConLeft">
                <TokenImage address={padInfo.saleToken.address} alt="logo" />
                <TYPE.white fontSize={14} lineHeight={'24px'} margin={'0 16px 0 12px'}>
                  {padInfo.saleToken.symbol}
                </TYPE.white>
              </div>
            </LaunchpadSolidCon>

            <h3 className="launchpadConTitle">Project Description</h3>
            <LaunchpadBorderCon style={{ minHeight: '120px', margin: '16px 0' }}>
              <TYPE.white fontSize={14} lineHeight={'24px'} style={{ wordBreak: 'break-all' }}>
                {project.description}
              </TYPE.white>
            </LaunchpadBorderCon>

            <div style={{ minHeight: '160px' }}>
              <h3 className="launchpadConTitle">Useful Links</h3>
              <div style={{ marginTop: '7px' }}>
                <TYPE.white fontSize={14} lineHeight={'20px'} marginTop={'5px'}>
                  Official website:{' '}
                  <a href={project.website} style={{ color: '#ffffff' }}>
                    {project.website}
                  </a>
                </TYPE.white>
                {project.links &&
                  Object.keys(project.links).map(
                    (item, index) =>
                      project.links[item] && (
                        <TYPE.white fontSize={14} lineHeight={'20px'} marginTop={'5px'} key={index}>
                          {item}:{' '}
                          <a
                            href={project.links[item]}
                            style={{ color: '#ffffff' }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {project.links[item]}
                          </a>
                        </TYPE.white>
                      )
                  )}
              </div>
            </div>
          </LaunchpadCon>
          <ConfirmButton onClick={onClose}>Close</ConfirmButton>
        </LaunchpadSection>
      </Wrapper>
    </Modal>
  )
}
