import { AutoColumn } from 'components/Column'
import React from 'react'
import styled from 'styled-components'
import { TYPE } from 'theme'

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 20px 0;

  .title {
    ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 28px;
    `}
  }
`

const TitleIcon = styled.img`
  /* width: 110px; */
  width: fit-content;
  height: 102px;
  margin-right: 48px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 90px;
        height: auto;
        margin-right: 12px;
    `};
`

const Desc = styled(TYPE.desc)`
  max-width: 400px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 100%;
    `}
`

interface Props {
  title: string
  desc: string
  logo: string
}

export default function Title({ title, desc, logo }: Props) {
  return (
    <TitleWrapper>
      <TitleIcon src={logo} />
      <AutoColumn gap="sm" justify="flex-start">
        <TYPE.v2Main fontWeight="bold" className="title" fontSize={32}>
          {title}
          {/* {isRewardTokenPool && 'StakingRewards'}
            {isWNett && 'Earn wNETT'}
            {isVeNeet && 'Earn wNETT'} */}
        </TYPE.v2Main>
        <Desc fontSize={14}>
          {desc}
          {/* {isRewardTokenPool && 'Stake NETT to earn incentives'}
            {isWNett && 'Stake NETT to eran wNETT used to enter Launchpad'}
            {isVeNeet && 'Stake NETT to boost yield farming.'} */}
        </Desc>
      </AutoColumn>
    </TitleWrapper>
  )
}
