/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { NavLink, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import ListItem from '@material-ui/core/ListItem'

interface Props {
  menuName: string
  icon?: string

  links: {
    path: string
    name: string
  }[]
  moreActive?(pathname: string): boolean
}

const useStyles = makeStyles({
  menuStyleList: {
    '& .MuiPopover-paper': {
      background: '#170627',
      boxShadow: '0px 2px 10px 0px rgba(196, 42, 97, 0.1)',
      borderRadius: '4px',
      top: '80px !important',
      marginLeft: '-18px',
    },
  },
  flexWrap: {
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
})

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  outline: none;
  cursor: pointer;
  color: ${({ theme }) => theme.text1};
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
  line-height: 22px;
  width: 100% !important;
  box-sizing: border-box;
  padding-left: 12px;

  :hover,
  :focus {
    color: ${({ theme }) => theme.v2.t09};
  }

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.v2.t09};
  }
`

const StyledSecondBtn = styled.button`
  align-items: left;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;
  background: transparent;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
`

const StyledUl = styled.ul`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: 100%;
  margin: 0 12px;
  font-weight: 500;
  display: none;
  z-index: 999;
  list-style: none;
  padding-inline-start: 10px;

  &.${activeClassName} {
    display: inline-block;
  }
`

const SecondLi = styled.li`
  margin-top: 12px;
  width: 100%;
`

// const Icon = styled.img`
//   width: 24px;
//   height: 24px;
//   margin-right: 8px;
// `

export default function SecondMenuNav({ menuName, icon, links, moreActive }: Props) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  const location = useLocation()

  const active = useMemo(() => {
    let res = false
    for (let i = 0, len = links.length; i < len; i++) {
      if (location.pathname.startsWith(links[i].path) || (!!moreActive && moreActive(location.pathname))) {
        res = true
        break
      }
    }

    return res
  }, [location, links, moreActive])

  return (
    <ListItem
      classes={{
        root: classes.flexWrap,
      }}
      button
    >
      <StyledSecondBtn onClick={handleClick}>
        {/* {icon && <Icon src={require(`../../assets/images/nav/${icon}.svg`)} alt="icon" />} */}
        {menuName}
      </StyledSecondBtn>
      <StyledUl className={classNames((active || open) && 'ACTIVE')}>
        {links.map((item, index) => {
          return (
            <SecondLi key={index}>
              <StyledNavLink key={item.path} to={item.path}>
                {item.name}
              </StyledNavLink>
            </SecondLi>
          )
        })}
      </StyledUl>
    </ListItem>
  )
}
