import { gql } from '@apollo/client'
import { ExchangeSubgraph } from '../index'
import axios from 'axios'

export interface Token {
  id: string
  symbol: string
  decimals: number
  derivedMETIS: number
}

export interface GraphReturnData {
  tokens: Token[]
  bundle: {
    metisPrice: number
  }
}

export interface TokenData {
  [key: string]: {
    price: number
  }
}

export default function getTokens(ids: string[]): Promise<TokenData | undefined> {
  // if (!name) return Promise.resolve([]);
  return new Promise((resolve, reject) => {
    ExchangeSubgraph()
      .query<GraphReturnData>({
        query: gql`
          query tokensPrices($tokenIds: [ID!]) {
            tokens(where: { id_in: $tokenIds }) {
              id
              symbol
              decimals
              derivedMETIS
            }

            bundle(id: "1") {
              metisPrice
            }
          }
        `,
        variables: {
          tokenIds: ids,
        },
        fetchPolicy: 'no-cache',
      })
      .then((res) => {
        const { data } = res
        if (data) {
          const { tokens, bundle } = data
          if (bundle?.metisPrice) {
            let returnData: TokenData = {}

            for (let i = 0, len = tokens.length; i < len; i++) {
              const token = tokens[i]
              returnData[token.id] = {
                price: Number(token.derivedMETIS * bundle.metisPrice),
              }
            }

            returnData['metis'] = {
              price: Number(bundle.metisPrice),
            }

            return resolve(returnData)
          }

          resolve(undefined)
        }
      })
      .catch(async (e: any) => {
        console.error('subgraph error', e)

        try {
          const prices = await Promise.all(
            ids.map((item) => axios.get(`https://api.diadata.org/v1/assetInfo/Metis/${item}`))
          )
          if (prices) {
            let returnData: TokenData = {}
            for (let i = 0, len = prices.length; i < len; i++) {
              const p = prices[i]
              if (p.status === 200) {
                let id = ids[i]
                if (p.data?.Symbol === 'Metis') {
                  id = 'metis'
                }
                returnData[id] = {
                  price: p.data?.Price,
                }
              }
            }

            resolve(returnData)
          } else {
            resolve(undefined)
          }
        } catch (e) {
          console.error(e)
          resolve(undefined)
        }
      })
  })
}
