import React, { createContext, useContext, useReducer, useMemo, useCallback, useEffect } from 'react'

import { ExchangeSubgraph as client } from 'gql/index'
import {
  TOKEN_DATA,
  FILTERED_TRANSACTIONS,
  TOKEN_CHART,
  TOKENS_CURRENT,
  TOKENS_DYNAMIC,
  PRICES_BY_BLOCK,
  // PAIR_DATA,
  TokenDayData,
  PairTransactions,
  TokenInfo,
} from 'gql/subgraph/analytics'

import { useMetisPrice } from './GlobalData'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import {
  get2DayPercentChange,
  getPercentChange,
  getBlockFromTimestamp,
  isAddress,
  getBlocksFromTimestamps,
  splitQuery,
} from 'utils/infos'
import { TimeframeOptions } from 'constants/index'
import { useLatestBlocks } from './Application'

const UPDATE = 'UPDATE'
const UPDATE_TOKEN_TXNS = 'UPDATE_TOKEN_TXNS'
const UPDATE_CHART_DATA = 'UPDATE_CHART_DATA'
const UPDATE_PRICE_DATA = 'UPDATE_PRICE_DATA'
const UPDATE_TOP_TOKENS = ' UPDATE_TOP_TOKENS'
const UPDATE_ALL_PAIRS = 'UPDATE_ALL_PAIRS'

const TOKEN_PAIRS_KEY = 'TOKEN_PAIRS_KEY'

dayjs.extend(utc)

export type TokenPriceData = {
  timestamp: number
  open: number
  close: number
}

export type TokenData = TokenInfo & {
  priceUSD?: number
  totalLiquidityUSD?: number
  oneDayVolumeUSD?: number
  oneDayVolumeMETIS?: number
  volumeChangeUSD?: number
  priceChangeUSD?: number
  oneDayVolumeUT?: number
  volumeChangeUT?: number
  liquidityChangeUSD?: number
  oneDayTxns?: number
  txnChange?: number
  chartData?: TokenDayData[]
  txns?: PairTransactions

  [TOKEN_PAIRS_KEY]?: {
    id: string
  }[]

  // [key: string]?: {
  //   [key: string]: TokenPriceData
  // }
} & {
  [k in TimeframeOptions]?: {
    [key: string]: TokenPriceData[]
  }
}

export type TokenDataState = {
  [key: string]: TokenData
}

const TokenDataContext = createContext<[TokenDataState, any]>([{}, {}])

function useTokenDataContext() {
  return useContext(TokenDataContext)
}

function reducer(state: TokenDataState, { type, payload }: { type: string; payload: any }) {
  switch (type) {
    case UPDATE: {
      const { tokenAddress, data } = payload
      return {
        ...state,
        [tokenAddress]: {
          ...state?.[tokenAddress],
          ...data,
        },
      }
    }
    case UPDATE_TOP_TOKENS: {
      const { topTokens } = payload
      let added: TokenDataState = {}
      topTokens &&
        topTokens.map((token: TokenData) => {
          return (added[token.id] = token)
        })
      return {
        ...state,
        ...added,
      }
    }

    case UPDATE_TOKEN_TXNS: {
      const { address, transactions } = payload
      return {
        ...state,
        [address]: {
          ...state?.[address],
          txns: transactions,
        },
      }
    }
    case UPDATE_CHART_DATA: {
      const { address, chartData } = payload
      return {
        ...state,
        [address]: {
          ...state?.[address],
          chartData,
        },
      }
    }

    case UPDATE_PRICE_DATA: {
      const { address, data, timeWindow, interval } = payload
      return {
        ...state,
        [address]: {
          ...state?.[address],
          [timeWindow]: {
            ...(state?.[address] as any)?.[timeWindow],
            [interval]: data,
          },
        },
      }
    }

    case UPDATE_ALL_PAIRS: {
      const { address, allPairs } = payload
      return {
        ...state,
        [address]: {
          ...state?.[address],
          [TOKEN_PAIRS_KEY]: allPairs,
        },
      }
    }
    default: {
      throw Error(`Unexpected action type in DataContext reducer: '${type}'.`)
    }
  }
}

export default function Provider({ children }: { children: React.ReactChildren | React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, {})
  const update = useCallback((tokenAddress: any, data: any) => {
    dispatch({
      type: UPDATE,
      payload: {
        tokenAddress,
        data,
      },
    })
  }, [])

  const updateTopTokens = useCallback((topTokens: any) => {
    dispatch({
      type: UPDATE_TOP_TOKENS,
      payload: {
        topTokens,
      },
    })
  }, [])

  const updateTokenTxns = useCallback((address: any, transactions: any) => {
    dispatch({
      type: UPDATE_TOKEN_TXNS,
      payload: { address, transactions },
    })
  }, [])

  const updateChartData = useCallback((address: any, chartData: any) => {
    dispatch({
      type: UPDATE_CHART_DATA,
      payload: { address, chartData },
    })
  }, [])

  const updateAllPairs = useCallback((address: any, allPairs: any) => {
    dispatch({
      type: UPDATE_ALL_PAIRS,
      payload: { address, allPairs },
    })
  }, [])

  const updatePriceData = useCallback((address: any, data: any, timeWindow: any, interval: any) => {
    dispatch({
      type: UPDATE_PRICE_DATA,
      payload: { address, data, timeWindow, interval },
    })
  }, [])

  return (
    <TokenDataContext.Provider
      value={useMemo(
        () => [
          state,
          {
            update,
            updateTokenTxns,
            updateChartData,
            updateTopTokens,
            updateAllPairs,
            updatePriceData,
          },
        ],
        [state, update, updateTokenTxns, updateChartData, updateTopTokens, updateAllPairs, updatePriceData]
      )}
    >
      {children as any}
    </TokenDataContext.Provider>
  )
}

const getTopTokens = async (metisPrice: number, metisPriceOld: number) => {
  const utcCurrentTime = dayjs()
  const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix()
  const utcTwoDaysBack = utcCurrentTime.subtract(2, 'day').unix()
  let oneDayBlock = await getBlockFromTimestamp(utcOneDayBack)
  let twoDayBlock = await getBlockFromTimestamp(utcTwoDaysBack)

  try {
    let current = await client().query<{ tokens: TokenInfo[] }>({
      query: TOKENS_CURRENT,
      fetchPolicy: 'cache-first',
    })

    let oneDayResult = await client().query<{ tokens: TokenInfo[] }>({
      query: TOKENS_DYNAMIC(oneDayBlock),
      fetchPolicy: 'cache-first',
    })

    let twoDayResult = await client().query<{ tokens: TokenInfo[] }>({
      query: TOKENS_DYNAMIC(twoDayBlock),
      fetchPolicy: 'cache-first',
    })

    let oneDayData: {
      [key: string]: TokenInfo
    } = oneDayResult?.data?.tokens.reduce((obj, cur, i) => {
      return { ...obj, [cur.id]: cur }
    }, {})

    let twoDayData: {
      [key: string]: TokenInfo
    } = twoDayResult?.data?.tokens.reduce((obj, cur, i) => {
      return { ...obj, [cur.id]: cur }
    }, {})

    let bulkResults = await Promise.all(
      current &&
        oneDayData &&
        twoDayData &&
        current?.data?.tokens.map(async (token) => {
          let data: TokenData = {
            ...token,
          }

          // let liquidityDataThisToken = liquidityData?.[token.id]
          let oneDayHistory = oneDayData?.[token.id]
          let twoDayHistory = twoDayData?.[token.id]

          // catch the case where token wasnt in top list in previous days
          if (!oneDayHistory) {
            let oneDayResult = await client().query({
              query: TOKEN_DATA(token.id, oneDayBlock),
              fetchPolicy: 'cache-first',
            })
            oneDayHistory = oneDayResult.data.tokens[0]
          }
          if (!twoDayHistory) {
            let twoDayResult = await client().query({
              query: TOKEN_DATA(token.id, twoDayBlock),
              fetchPolicy: 'cache-first',
            })
            twoDayHistory = twoDayResult.data.tokens[0]
          }

          // calculate percentage changes and daily changes
          const [oneDayVolumeUSD, volumeChangeUSD] = get2DayPercentChange(
            data.tradeVolumeUSD,
            oneDayHistory?.tradeVolumeUSD ?? 0,
            twoDayHistory?.tradeVolumeUSD ?? 0
          )
          const [oneDayTxns, txnChange] = get2DayPercentChange(
            data.txCount,
            oneDayHistory?.txCount ?? 0,
            twoDayHistory?.txCount ?? 0
          )

          const currentLiquidityUSD = parseFloat(data?.totalLiquidity) * metisPrice * parseFloat(data?.derivedMETIS)
          const oldLiquidityUSD =
            parseFloat(oneDayHistory?.totalLiquidity) * metisPriceOld * parseFloat(oneDayHistory?.derivedMETIS)

          // percent changes
          const priceChangeUSD = getPercentChange(
            parseFloat(data?.derivedMETIS) * metisPrice,
            parseFloat(oneDayHistory?.derivedMETIS) ? parseFloat(oneDayHistory?.derivedMETIS) * metisPriceOld : 0
          )

          // set data
          data.priceUSD = parseFloat(data?.derivedMETIS) * metisPrice
          data.totalLiquidityUSD = currentLiquidityUSD
          data.oneDayVolumeUSD = oneDayVolumeUSD
          data.volumeChangeUSD = volumeChangeUSD
          data.priceChangeUSD = priceChangeUSD
          data.liquidityChangeUSD = getPercentChange(currentLiquidityUSD ?? 0, oldLiquidityUSD ?? 0)
          data.oneDayTxns = oneDayTxns
          data.txnChange = txnChange

          // new tokens
          if (!oneDayHistory && data) {
            data.oneDayVolumeUSD = parseFloat(data.tradeVolumeUSD)
            data.oneDayVolumeMETIS = parseFloat(data.tradeVolume) * parseFloat(data.derivedMETIS)
            data.oneDayTxns = parseFloat(data.txCount)
          }

          // if (data.id === '0xdeaddeaddeaddeaddeaddeaddeaddeaddead0000') {
          //   data.name = 'METIS (Wrapped)'
          //   data.symbol = 'METIS'
          // }

          // HOTFIX for Aave
          // if (data.id === '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9') {
          //   const aaveData = await client().query({
          //     query: PAIR_DATA('0xdfc14d2af169b0d36c4eff567ada9b2e0cae044f'),
          //     fetchPolicy: 'cache-first',
          //   })
          //   const result = aaveData.data.pairs[0]
          //   data.totalLiquidityUSD = parseFloat(result.reserveUSD) / 2
          //   data.liquidityChangeUSD = 0
          //   data.priceChangeUSD = 0
          // }

          return data
        })
    )

    return bulkResults

    // calculate percentage changes and daily changes
  } catch (e) {
    console.log(e)
    return []
  }
}

const getTokenData = async (address: string, metisPrice: number, metisPriceOld: number): Promise<TokenData | null> => {
  const utcCurrentTime = dayjs()
  const utcOneDayBack = utcCurrentTime.subtract(1, 'day').startOf('minute').unix()
  const utcTwoDaysBack = utcCurrentTime.subtract(2, 'day').startOf('minute').unix()
  let oneDayBlock = await getBlockFromTimestamp(utcOneDayBack)
  let twoDayBlock = await getBlockFromTimestamp(utcTwoDaysBack)

  // initialize data arrays
  let data: TokenData
  let oneDayData: TokenInfo
  let twoDayData: TokenInfo

  try {
    // fetch all current and historical data
    let result = await client().query<{ tokens: TokenInfo[] }>({
      query: TOKEN_DATA(address),
      fetchPolicy: 'cache-first',
    })

    data = result.data.tokens[0]

    // get results from 24 hours in past
    let oneDayResult = await client().query<{ tokens: TokenInfo[] }>({
      query: TOKEN_DATA(address, oneDayBlock),
      fetchPolicy: 'cache-first',
    })
    oneDayData = oneDayResult.data.tokens[0]

    // get results from 48 hours in past
    let twoDayResult = await client().query<{ tokens: TokenInfo[] }>({
      query: TOKEN_DATA(address, twoDayBlock),
      fetchPolicy: 'cache-first',
    })
    twoDayData = twoDayResult.data.tokens[0]

    // catch the case where token wasnt in top list in previous days
    if (!oneDayData) {
      let oneDayResult = await client().query<{ tokens: TokenInfo[] }>({
        query: TOKEN_DATA(address, oneDayBlock),
        fetchPolicy: 'cache-first',
      })
      oneDayData = oneDayResult.data.tokens[0]
    }
    if (!twoDayData) {
      let twoDayResult = await client().query<{ tokens: TokenInfo[] }>({
        query: TOKEN_DATA(address, twoDayBlock),
        fetchPolicy: 'cache-first',
      })
      twoDayData = twoDayResult.data.tokens[0]
    }

    // calculate percentage changes and daily changes
    const [oneDayVolumeUSD, volumeChangeUSD] = get2DayPercentChange(
      data.tradeVolumeUSD,
      oneDayData?.tradeVolumeUSD ?? 0,
      twoDayData?.tradeVolumeUSD ?? 0
    )

    // calculate percentage changes and daily changes
    const [oneDayVolumeUT, volumeChangeUT] = get2DayPercentChange(
      data.untrackedVolumeUSD,
      oneDayData?.untrackedVolumeUSD ?? 0,
      twoDayData?.untrackedVolumeUSD ?? 0
    )

    // calculate percentage changes and daily changes
    const [oneDayTxns, txnChange] = get2DayPercentChange(
      data.txCount,
      oneDayData?.txCount ?? 0,
      twoDayData?.txCount ?? 0
    )

    const priceChangeUSD = getPercentChange(
      Number(data?.derivedMETIS) * metisPrice,
      parseFloat(oneDayData?.derivedMETIS ?? 0) * metisPriceOld
    )

    const currentLiquidityUSD = Number(data?.totalLiquidity) * metisPrice * Number(data?.derivedMETIS)
    const oldLiquidityUSD = Number(oneDayData?.totalLiquidity) * metisPriceOld * Number(oneDayData?.derivedMETIS)

    // set data
    data.priceUSD = Number(data?.derivedMETIS) * metisPrice
    data.totalLiquidityUSD = currentLiquidityUSD
    data.oneDayVolumeUSD = oneDayVolumeUSD
    data.volumeChangeUSD = volumeChangeUSD
    data.priceChangeUSD = priceChangeUSD
    data.oneDayVolumeUT = oneDayVolumeUT
    data.volumeChangeUT = volumeChangeUT
    const liquidityChangeUSD = getPercentChange(currentLiquidityUSD ?? 0, oldLiquidityUSD ?? 0)
    data.liquidityChangeUSD = liquidityChangeUSD
    data.oneDayTxns = oneDayTxns
    data.txnChange = txnChange

    // new tokens
    if (!oneDayData && data) {
      data.oneDayVolumeUSD = Number(data.tradeVolumeUSD)
      data.oneDayVolumeMETIS = Number(data.tradeVolume) * Number(data.derivedMETIS)
      data.oneDayTxns = Number(data.txCount)
    }

    // fix for WMETIS
    // if (data.id === '0xdeaddeaddeaddeaddeaddeaddeaddeaddead0000') {
    //   data.name = 'METIS (Wrapped)'
    //   data.symbol = 'METIS'
    // }

    // HOTFIX for Aave
    // if (data.id === '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9') {
    //   const aaveData = await client().query({
    //     query: PAIR_DATA('0xdfc14d2af169b0d36c4eff567ada9b2e0cae044f'),
    //     fetchPolicy: 'cache-first',
    //   })
    //   const result = aaveData.data.pairs[0]
    //   data.totalLiquidityUSD = parseFloat(result.reserveUSD) / 2
    //   data.liquidityChangeUSD = 0
    //   data.priceChangeUSD = 0
    // }

    return data
  } catch (e) {
    console.log(e)

    return null
  }
}

const getTokenTransactions = async (allPairsFormatted: string[]): Promise<PairTransactions> => {
  const transactions: PairTransactions = {
    mints: [],
    burns: [],
    swaps: [],
  }
  try {
    let result = await client().query<PairTransactions>({
      query: FILTERED_TRANSACTIONS,
      variables: {
        allPairs: allPairsFormatted,
      },
      fetchPolicy: 'cache-first',
    })
    transactions.mints = result.data.mints
    transactions.burns = result.data.burns
    transactions.swaps = result.data.swaps
  } catch (e) {
    console.log(e)
  }
  return transactions
}

const getTokenPairs = async (tokenAddress: string): Promise<{ id: string }[]> => {
  try {
    // fetch all current and historical data
    let result = await client().query<{
      tokens: TokenInfo[]
      pairs0: {
        id: string
      }[]
      pairs1: {
        id: string
      }[]
    }>({
      query: TOKEN_DATA(tokenAddress),
      fetchPolicy: 'cache-first',
    })
    return result.data?.pairs0.concat(result.data?.pairs1)
  } catch (e) {
    console.log(e)
    return []
  }
}

const getIntervalTokenData = async (
  tokenAddress: string,
  startTime: number,
  interval = 3600,
  latestBlock: number
): Promise<TokenPriceData[]> => {
  const utcEndTime = dayjs.utc()
  let time = startTime

  // create an array of hour start times until we reach current hour
  // buffer by half hour to catch case where graph isnt synced to latest block
  const timestamps = []
  while (time < utcEndTime.unix()) {
    timestamps.push(time)
    time += interval
  }

  // backout if invalid timestamp format
  if (timestamps.length === 0) {
    return []
  }

  // once you have all the timestamps, get the blocks for each timestamp in a bulk query
  let blocks
  try {
    blocks = await getBlocksFromTimestamps(timestamps, 100)

    // catch failing case
    if (!blocks || blocks.length === 0) {
      return []
    }

    if (latestBlock) {
      blocks = blocks.filter((b) => {
        return parseFloat(b.number) <= latestBlock
      })
    }

    let result: {
      [key: string]: {
        derivedMETIS: string
        metisPrice: string
      }
    } = await splitQuery(PRICES_BY_BLOCK, client(), [tokenAddress], blocks, 50)

    // format token METIS price results
    let values: {
      timestamp: number
      derivedMETIS: number
      priceUSD: number
    }[] = []
    for (let row in result) {
      let timestamp = row.split('t')[1]
      let derivedMETIS = parseFloat(result[row]?.derivedMETIS)
      if (timestamp) {
        values.push({
          timestamp: Number(timestamp),
          derivedMETIS,
          priceUSD: 0,
        })
      }
    }

    // go through ht usd prices and assign to original values array
    let index = 0
    for (let brow in result) {
      let timestamp = brow.split('b')[1]
      if (timestamp && result[brow]) {
        values[index].priceUSD = parseFloat(result[brow].metisPrice) * values[index].derivedMETIS
        index += 1
      }
    }

    let formattedHistory = []

    // for each hour, construct the open and close price
    for (let i = 0; i < values.length - 1; i++) {
      formattedHistory.push({
        timestamp: values[i].timestamp,
        open: values[i].priceUSD,
        close: values[i + 1].priceUSD,
      })
    }

    return formattedHistory
  } catch (e) {
    console.log(e)
    console.log('error fetching blocks')
    return []
  }
}

const getTokenChartData = async (tokenAddress: string) => {
  let data: TokenDayData[] = []
  const utcEndTime = dayjs.utc()
  let utcStartTime = utcEndTime.subtract(1, 'year')
  let startTime = utcStartTime.startOf('minute').unix() - 1

  try {
    let allFound = false
    let skip = 0
    while (!allFound) {
      let result = await client().query<{ tokenDayDatas: TokenDayData[] }>({
        query: TOKEN_CHART,
        variables: {
          tokenAddr: tokenAddress,
          skip,
        },
        fetchPolicy: 'cache-first',
      })
      if (result.data.tokenDayDatas.length < 1000) {
        allFound = true
      }
      skip += 1000
      data = data.concat(result.data.tokenDayDatas)
    }

    let dayIndexSet = new Set()
    const oneDay = 24 * 60 * 60
    const formatedData = []
    data.forEach((dayData, i) => {
      // add the day index to the set of days
      dayIndexSet.add((Number(data[i].date) / oneDay).toFixed(0))
      formatedData.push({
        ...dayData,
        dailyVolumeUSD: parseFloat(dayData.dailyVolumeUSD),
        date: '',
      })
    })

    let dayIndexArray = [...data]

    // fill in empty days
    let timestamp = data[0] && data[0].date ? Number(data[0].date) : startTime
    let latestLiquidityUSD = data[0] && data[0].totalLiquidityUSD
    let latestPriceUSD = data[0] && data[0].priceUSD
    // TODO: There is no mostLiquidPairs in graph
    // let latestPairDatas = data[0] && data[0].mostLiquidPairs
    let index = 1
    while (timestamp < utcEndTime.startOf('minute').unix() - oneDay) {
      const nextDay = timestamp + oneDay
      let currentDayIndex = (nextDay / oneDay).toFixed(0)
      if (!dayIndexSet.has(currentDayIndex)) {
        formatedData.push({
          date: nextDay,
          dayString: nextDay,
          dailyVolumeUSD: 0,
          priceUSD: latestPriceUSD,
          totalLiquidityUSD: latestLiquidityUSD,
        })
      } else {
        latestLiquidityUSD = dayIndexArray[index].totalLiquidityUSD
        latestPriceUSD = dayIndexArray[index].priceUSD
        index = index + 1
      }
      timestamp = nextDay
    }
    data = data.sort((a, b) => (parseInt(a.date) > parseInt(b.date) ? 1 : -1))
  } catch (e) {
    console.log(e)
  }
  return data
}

export function Updater() {
  const [, { updateTopTokens }] = useTokenDataContext()
  const [metisPrice, metisPriceOld] = useMetisPrice()
  useEffect(() => {
    async function getData() {
      // get top pairs for overview list
      let topTokens = await getTopTokens(metisPrice, metisPriceOld)
      topTokens && updateTopTokens(topTokens)
    }
    metisPrice && metisPriceOld && getData()
  }, [metisPrice, metisPriceOld, updateTopTokens])
  return null
}

export function useTokenData(tokenAddress: string) {
  const [state, { update }] = useTokenDataContext()
  const [metisPrice, metisPriceOld] = useMetisPrice()
  const tokenData = state?.[tokenAddress]

  useEffect(() => {
    if (!tokenData && metisPrice && metisPriceOld && isAddress(tokenAddress)) {
      getTokenData(tokenAddress, metisPrice, metisPriceOld).then((data) => {
        if (!data) {
          update(tokenAddress, data)
        }
      })
    }
  }, [metisPrice, metisPriceOld, tokenAddress, tokenData, update])

  return tokenData || {}
}

export function useTokenTransactions(tokenAddress: string) {
  const [state, { updateTokenTxns }] = useTokenDataContext()
  const tokenTxns = state?.[tokenAddress]?.txns

  const allPairsFormatted =
    state[tokenAddress] &&
    state[tokenAddress][TOKEN_PAIRS_KEY] &&
    (state[tokenAddress][TOKEN_PAIRS_KEY] as { id: string }[]).map((pair) => {
      return pair.id
    })

  useEffect(() => {
    async function checkForTxns() {
      if (!tokenTxns && allPairsFormatted) {
        let transactions = await getTokenTransactions(allPairsFormatted)
        updateTokenTxns(tokenAddress, transactions)
      }
    }
    checkForTxns()
  }, [tokenTxns, tokenAddress, updateTokenTxns, allPairsFormatted])

  return tokenTxns as PairTransactions
}

export function useTokenPairs(tokenAddress: string) {
  const [state, { updateAllPairs }] = useTokenDataContext()
  const tokenPairs = state?.[tokenAddress]?.[TOKEN_PAIRS_KEY]

  useEffect(() => {
    async function fetchData() {
      let allPairs = await getTokenPairs(tokenAddress)
      updateAllPairs(tokenAddress, allPairs)
    }
    if (!tokenPairs && isAddress(tokenAddress)) {
      fetchData()
    }
  }, [tokenAddress, tokenPairs, updateAllPairs])

  return tokenPairs || []
}

export function useTokenChartData(tokenAddress: string) {
  const [state, { updateChartData }] = useTokenDataContext()
  const chartData = state?.[tokenAddress]?.chartData
  useEffect(() => {
    async function checkForChartData() {
      if (!chartData) {
        let data = await getTokenChartData(tokenAddress)
        updateChartData(tokenAddress, data)
      }
    }
    checkForChartData()
  }, [chartData, tokenAddress, updateChartData])
  return chartData
}

/**
 * get candlestick data for a token - saves in context based on the window and the
 * interval size
 * @param {*} tokenAddress
 * @param {*} timeWindow // a preset time window from constant - how far back to look
 * @param {*} interval  // the chunk size in seconds - default is 1 hour of 3600s
 */
export function useTokenPriceData(tokenAddress: string, timeWindow: TimeframeOptions, interval = 3600) {
  const [state, { updatePriceData }] = useTokenDataContext()
  const chartData = state?.[tokenAddress]?.[timeWindow]?.[interval]
  const [latestBlock] = useLatestBlocks()

  useEffect(() => {
    const currentTime = dayjs.utc()
    const windowSize = timeWindow === TimeframeOptions.MONTH ? 'month' : 'week'
    const startTime =
      timeWindow === TimeframeOptions.ALL_TIME ? 1589760000 : currentTime.subtract(1, windowSize).startOf('hour').unix()

    async function fetch() {
      if (latestBlock) {
        let data = await getIntervalTokenData(tokenAddress, startTime, interval, latestBlock)
        updatePriceData(tokenAddress, data, timeWindow, interval)
      }
    }
    if (!chartData) {
      fetch()
    }
  }, [chartData, interval, timeWindow, tokenAddress, updatePriceData, latestBlock])

  return chartData
}

export function useAllTokenData() {
  const [state] = useTokenDataContext()
  return state
}
