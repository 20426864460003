/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import styled from 'styled-components'
import { Period } from 'gql/microservices/assets-pools'
import { useEffect, useMemo, useRef } from 'react'
import useGetFarmAssetsPool from 'state/data/hooks/useGetFarmAssetsPools'
import Title from './components/Title'
import Slider from 'react-slick'
import TokenImage from 'components/TokenImage'
import Tabs from '@material-ui/core/Tabs'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { Tab } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { AutoColumn } from 'components/Column'
import { TYPE } from 'theme'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import StakeNett from './components/StakeNett'
import Numeral from 'numeral'
import { useContractByAbiAddr } from 'hooks/contract/useContract'
import AssetStakeABI from 'constants/assets-farm/assets-stake.json'
import BigNumber from 'bignumber.js'
import { useActiveWeb3React } from 'hooks'
import { useGetTokensPrice } from 'state/data/hooks/useTokenPrice'
import useSend from 'state/contract/hooks/useSend'
import { NETWORK_CHAIN_ID } from 'connectors'
import { useAppSelector } from 'state'
import { isMobile } from 'react-device-detect'
import useGetStakingRewardsInfo from './hooks/useGetStakingRewardInfo'
import { AuthButton } from 'components/Button'

const StyledTabs = styled(Tabs)`
  margin-top: 28px;
  width: 100%;

  .MuiTabs-root {
    margin-bottom: 40px;
  }
  .MuiTabs-flexContainer {
    justify-content: flex-start;
  }
  .MuiTab-root {
    padding: 0 20px;
    color: ${({ theme }) => theme.v2.t02};
    text-transform: none;

    &.Mui-selected {
      color: ${({ theme }) => theme.v2.t09};
    }
  }
  .MuiTabs-indicator {
    display: flex;
    justify-content: flex-start;
    background-color: transparent;
    bottom: 0px !important;
    background-color: ${({ theme }) => theme.v2.t09};
    /* width: 60px !important; */
  }
`

const PoolCard = styled.div`
  width: 100%;
  height: 100px;
  background: ${({ theme }) => theme.v2.bg02};
  border-radius: 14px;
  cursor: pointer;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  height: auto;
  .poolTitle {
    text-align: center;
  }
  `}

  &.active {
    background: ${({ theme }) => theme.v2.bg11};

    .poolTitle {
      color: #fff;
    }

    .poolTag {
      background: ${({ theme }) => theme.v2.bg07};
      color: ${({ theme }) => theme.v2.t09};
    }
  }
`

const CardHeaderInfo = styled.div`
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 20px 0;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  flex-direction: column;
  padding: 1rem;
  `}
`

const CardImg = styled(TokenImage)`
  margin-right: 16px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-right: 0;
  margin-bottom: 1rem;
  `}
`

const CardTag = styled.div`
  border-radius: 4px;
  color: ${({ theme }) => theme.v2.t03};

  font-size: 10px;
  padding: 4px 12px;
  background: ${({ theme }) => theme.v2.bg04};
  width: fit-content;
  display: flex;
  align-items: center;
`

const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 32px 0;
  width: 100%;
  border-bottom: 1px dashed #a2a2a8;
`

const StyledSlider = styled(Slider)`
  width: 840px;
  .slick-slide {
    width: 240px;
    height: 100px;
    margin: 0 12px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    height: auto;
    width: 220px
    `}
  }
`

const ArrowWrapper = styled.div`
  position: relative;
  width: 48px;
  height: 100px;
  cursor: pointer;

  background: ${({ theme }) => theme.v2.bg04};
  border-radius: 8px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
  `}

  svg {
    width: 30px;
    height: 30px;
  }
`
const BackgroundMode = styled.div`
  position: absolute;
  right: 100%;
  width: 80px;
  height: 146px;
  background: ${({ theme }) => `linear-gradient(270deg, ${theme.v2.bg01} 0%, transparent 100%)`};
`

const StyledAccessTimeIcon = styled(AccessTimeIcon)`
  fill: ${({ theme }) => theme.v2.t03};
  width: 14px !important;
  height: 14px !important;
  margin-right: 8px;
`

export default function StakeingReward() {
  const { account, chainId } = useActiveWeb3React()
  let { tab, id } = useParams<{
    tab: string
    id: string
  }>()

  const tabIndex = Number(tab)
  const history = useHistory()
  const { userPoolIds } = useGetStakingRewardsInfo()
  const handleTabChange = (event: React.ChangeEvent<any>, newValue: number) => {
    history.push(`/nett-staking-detail/${newValue}/${id}`)
  }
  const [stakeBalance, setStakeBalance] = useState(new BigNumber(0))
  const pools = useGetFarmAssetsPool()
  const [unclaimedRewardBalace, setUnClaimedReward] = useState(new BigNumber(0))
  const slider = useRef<any>()
  const send = useSend()
  const computedPools = useMemo(() => {
    let res = [...pools]
    if (res.length) {
      res = res.sort((a, b) => {
        return b.apy - a.apy
      })
    }
    if (tabIndex === 0) {
      return res
    } else if (tabIndex === 1) {
      return res.filter((item) => item.period === Period.ongoing)
    } else if (tabIndex === 2) {
      return res.filter((item) => item.period === Period.ended)
    } else if (tabIndex === 3) {
      return res.filter((item) => userPoolIds.indexOf(item.id) >= 0)
    }

    return []
  }, [pools, tabIndex])

  const tokensRequestPrice = useMemo(() => {
    const tokens = Array.prototype.concat.apply(
      [],
      pools.map((p) => {
        return [p.stakeToken.address, p.rewardToken.address]
      })
    )
    return Array.from(new Set(new Set(tokens)))
  }, [pools, userPoolIds])

  const tokensPrice = useGetTokensPrice(tokensRequestPrice)

  const pool = useMemo(() => {
    return pools.find((item) => item.id === id)
  }, [pools, id])
  const stakeContract = useContractByAbiAddr(pool?.stakeContractAddress, AssetStakeABI)
  const stakeTokenContract = useContractByAbiAddr(pool?.stakeToken.address, AssetStakeABI)

  const SlickSetting = useMemo(() => {
    const initialSlide = computedPools.findIndex((p) => p.id === id)
    let slidesToShow = 3
    if (isMobile) {
      slidesToShow = computedPools.length >= 2 ? 2 : computedPools.length
    } else {
      slidesToShow = computedPools.length >= 3 ? 3 : computedPools.length
    }
    return {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: slidesToShow,
      slidesToScroll: 1,
      variableWidth: true,
      arrows: false,
      focusOnSelect: true,
      initialSlide: initialSlide >= 0 ? initialSlide : 0,
    }
  }, [computedPools, isMobile])

  //   useEffect(() => {
  //     const poolIndex = computedPools.findIndex(p => p.id === id)

  //     if (slider.current) {
  //       slider.current.slickGoTo(poolIndex)
  //     }
  //   }, [computedPools, id])

  const isEnded = useMemo(() => {
    return pool?.period === Period.ended
  }, [pool])

  async function getStakeBalance() {
    if (stakeContract && account) {
      try {
        const res = await stakeContract.balanceOf(account)
        setStakeBalance(
          res?.toString()
            ? new BigNumber(res.toString()).shiftedBy(pool ? -pool.stakeToken.decimals : -18)
            : new BigNumber(0)
        )
      } catch (e) {
        console.error(e)
      }
    }
  }

  async function getRewardBalance() {
    if (stakeContract && account && pool) {
      try {
        const res = await stakeContract.earned(account)

        const reward = res?.toString()
          ? new BigNumber(res.toString()).shiftedBy(-pool.rewardToken.decimals)
          : new BigNumber(0)
        setUnClaimedReward(reward)
        return reward
      } catch (e) {
        console.error(e)
      }
    }
    return new BigNumber(0)
  }

  useEffect(() => {
    getStakeBalance()
  }, [stakeContract, account])

  useEffect(() => {
    let interval: any
    if (stakeTokenContract && account) {
      getRewardBalance()

      interval = setInterval(() => {
        getRewardBalance()
      }, 15000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [stakeTokenContract, account])

  const stakeTokenUSD = useMemo(() => {
    return pool?.stakeToken.address
      ? Numeral(stakeBalance.multipliedBy(tokensPrice[pool?.stakeToken.address]?.price || 0).toNumber()).format(
          '$ 0,0.[00]'
        )
      : '0'
  }, [stakeBalance, pool, tokensPrice])

  const rewardUSD = useMemo(() => {
    return pool?.rewardToken.address
      ? Numeral(
          unclaimedRewardBalace.multipliedBy(tokensPrice[pool?.rewardToken.address]?.price || 0).toNumber()
        ).format('$ 0,0.[00]')
      : '0'
  }, [unclaimedRewardBalace, pool, tokensPrice])

  async function stake({ inputAmount, onSuccess }: { inputAmount: BigNumber; onSuccess?: () => void }) {
    await send({
      contract: stakeContract,
      method: 'stake',
      params: [inputAmount.shiftedBy(pool?.stakeToken.decimals || 18).toFixed()],
      pendingText: `Staking ${inputAmount.toFixed()} ${pool?.stakeToken.symbol}`,
      summary: `Staked ${inputAmount.toFixed()} ${pool?.stakeToken.symbol} to earn ${pool?.rewardToken.symbol}`,
      onSuccess() {
        // getStakeTokenBalance()
        getStakeBalance()
        getRewardBalance()
        if (onSuccess) {
          onSuccess()
        }
        // setInputAmount(new BigNumber(0))
      },
    })
  }

  async function unStake({ inputAmount, onSuccess }: { inputAmount: BigNumber; onSuccess?: () => void }) {
    if (stakeBalance.toNumber()) {
      send({
        contract: stakeContract,
        method: 'exit',
        params: [],
        pendingText: `UnStaking ${stakeBalance.toFixed()} ${pool?.stakeToken.symbol}`,
        summary: `Unstake ${stakeBalance.toFixed()} ${pool?.stakeToken.symbol}`,
        onSuccess() {
          if (onSuccess) {
            onSuccess()
          }
          //   getStakeTokenBalance()
          getStakeBalance()
          getRewardBalance()
          //   handleModalClose()
        },
      })
    }
  }
  const { blockTime } = useAppSelector((state) => state.application)
  const poolCountDown = useMemo(() => {
    return computedPools.map((p) => {
      if (p.period === Period.ongoing && blockTime[chainId || NETWORK_CHAIN_ID]) {
        const endTime = p.periodFinish
        const now = blockTime[chainId || NETWORK_CHAIN_ID]
        const leftTime = endTime - now
        const days = Math.floor(leftTime / 86400000)
        const hours = Math.floor((leftTime % 86400000) / 3600000)
        const minutes = Math.floor((leftTime % 3600000) / 60000)

        return `End: ${days}d: ${hours}h: ${minutes}m`
      } else if (p.period === Period.prepare) {
        return 'Prepare'
      }

      return 'Reward has ended'
    })
  }, [computedPools, blockTime, chainId])

  return (
    <>
      <Title
        title="StakingRewards"
        desc="Stake NETT to earn various tokens"
        logo={require('assets/images/stake/v2/stakingrewards-logo.png')}
      />
      <StyledTabs value={tabIndex} onChange={handleTabChange} aria-label="launchpad">
        <Tab disableRipple label="All" />
        <Tab disableRipple label="Active" />
        <Tab disableRipple label="Ended" />
        <Tab disableRipple label="My" />
      </StyledTabs>
      {computedPools.length > 0 ? (
        <SliderWrapper>
          <ArrowWrapper
            style={{
              marginRight: '24px',
            }}
            onClick={() => {
              slider.current.slickPrev()
            }}
          >
            <KeyboardArrowLeftIcon />
          </ArrowWrapper>
          <StyledSlider ref={slider} {...SlickSetting}>
            {computedPools.map((p, index) => (
              <PoolCard
                key={index}
                className={p.id === id ? 'active' : ''}
                onClick={() => {
                  history.push(`/nett-staking-detail/${tabIndex}/${p.id}`)
                }}
              >
                <CardHeaderInfo>
                  <CardImg size="48px" address={p.rewardToken.address} />
                  <AutoColumn gap="md">
                    <TYPE.v2Main fontWeight="bold" fontSize={16} className="poolTitle">
                      Earn {p.rewardToken.symbol}
                    </TYPE.v2Main>
                    <CardTag className="poolTag">
                      <StyledAccessTimeIcon />
                      {poolCountDown[index]}
                    </CardTag>
                  </AutoColumn>
                </CardHeaderInfo>
              </PoolCard>
            ))}
          </StyledSlider>
          <ArrowWrapper
            onClick={() => {
              slider.current.slickNext()
            }}
          >
            <BackgroundMode />
            <KeyboardArrowRightIcon />
          </ArrowWrapper>
        </SliderWrapper>
      ) : account ? (
        <TYPE.desc margin="20px 0" textAlign="center" width="100%">
          No pool
        </TYPE.desc>
      ) : (
        <AuthButton auth style={{ margin: '20px auto 0 auto ' }} />
      )}

      {!!pool && (
        <StakeNett
          type="stakingRewards"
          tvl={Numeral(pool?.tvl || 0).format('$ 0,0')}
          apy={Numeral(!isEnded ? pool?.apy : 0).format('0,0.[00]')}
          stakeBalance={stakeBalance}
          stakeTokenUSD={stakeTokenUSD}
          rewardToken={pool.rewardToken}
          unclaimedRewardBalace={unclaimedRewardBalace}
          rewardUSD={rewardUSD}
          approveAddress={pool?.stakeContractAddress}
          stake={stake}
          unStake={unStake}
        />
      )}
    </>
  )
}
