/* eslint-disable react-hooks/exhaustive-deps */
import { ChainId } from '@netswap/sdk'
import BigNumber from 'bignumber.js'
import { NETWORK_CHAIN_ID } from 'connectors'
import { useActiveWeb3React } from 'hooks'
import { useGetNett } from 'hooks/contract/useGetContract'
import { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import { setCommunityTresuryNettBalance, setNettTokenInfoTotalSupply, setBlackHoldAddress } from '../index'

const CommunityTreasury = {
  [ChainId.MAINNET]: '0x2Dc348972dF99DFf6716007d19368EaAa6e75ED7',
  [ChainId.TESTNET]: '0x545C82659128Bdf914e75e1268D76925aa45e5BD',
  // need config
  [ChainId.TESTNET_SEPOLIA]: '0x545C82659128Bdf914e75e1268D76925aa45e5BD',
}

const BlackHold = {
  [ChainId.MAINNET]: '0x1B3Da69C93CB57E620a1cb6fD94D8421Af14DF3b',
  [ChainId.TESTNET]: '',
  [ChainId.TESTNET_SEPOLIA]: '',
}

export default function useGetNettTokenInfo() {
  const nettContract = useGetNett()
  const dispatch = useAppDispatch()
  const { chainId } = useActiveWeb3React()
  const commuinityTresuryAddress = useMemo(() => {
    return CommunityTreasury[chainId || (NETWORK_CHAIN_ID as ChainId)]
  }, [chainId])

  const blackHoldAddress = useMemo(() => {
    return BlackHold[chainId || (NETWORK_CHAIN_ID as ChainId)]
  }, [chainId])

  async function getContractData() {
    if (nettContract) {
      try {
        const totalSupply = await nettContract.totalSupply()
        if (totalSupply?.toString()) {
          dispatch(setNettTokenInfoTotalSupply(new BigNumber(totalSupply.toString()).shiftedBy(-18).toNumber()))
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  async function getCommunityTresuryNettBalance() {
    if (nettContract && commuinityTresuryAddress) {
      try {
        const res = await nettContract.balanceOf(commuinityTresuryAddress)
        if (res?.toString()) {
          dispatch(setCommunityTresuryNettBalance(new BigNumber(res.toString()).shiftedBy(-18).toNumber()))
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  async function getBlackHoldAddress() {
    if (nettContract && blackHoldAddress) {
      try {
        const res = await nettContract.balanceOf(blackHoldAddress)
        if (res?.toString()) {
          dispatch(setBlackHoldAddress(new BigNumber(res.toString()).shiftedBy(-18).toNumber()))
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  useEffect(() => {
    getContractData()
    let interval = setInterval(() => {
      getContractData()
    }, 30000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [nettContract])

  useEffect(() => {
    let interval: any
    if (nettContract && commuinityTresuryAddress) {
      getCommunityTresuryNettBalance()
      interval = setInterval(() => {
        getCommunityTresuryNettBalance()
      }, 30000)
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [commuinityTresuryAddress, nettContract])

  useEffect(() => {
    let interval: any
    if (nettContract && blackHoldAddress) {
      getBlackHoldAddress()
      interval = setInterval(() => {
        getBlackHoldAddress()
      }, 30000)
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [blackHoldAddress, nettContract])
}

export function useGetNettTokenBalance() {
  const nettContract = useGetNett()
  const [balance, setBalance] = useState(new BigNumber(0))
  const { account } = useActiveWeb3React()

  async function updateBalance() {
    if (nettContract && account) {
      const res = await nettContract.balanceOf(account)
      if (res) {
        setBalance(new BigNumber(res.toString()).shiftedBy(-18))
      }
    }
  }

  useEffect(() => {
    updateBalance()
    let interval = setInterval(() => {
      updateBalance()
    }, 30000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [nettContract, account])

  return { balance, updateBalance }
}

export function useGetNETTCirculationAndMAxSupply() {
  const {
    // airdropData: { first, second },
    nettTokenInfo: { totalSupply },
    communityTresury: { nettBalance },
    blackHold: { nettBalance: blackHoldBalance },
  } = useAppSelector((state) => state.netswapData)

  const nettInCirculation = useMemo(() => {
    if (totalSupply >= 0 && nettBalance >= 0 && blackHoldBalance >= 0) {
      return totalSupply - nettBalance - blackHoldBalance
    }

    return -1
  }, [totalSupply, nettBalance, blackHoldBalance])

  return {
    nettInCirculation,
    maxSupply: 16666667,
  }
}
