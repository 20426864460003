/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react'
import Modal from '../Modal'
import { useModalOpen, useNettToggle } from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/actions'
import styled from 'styled-components'
import { AuthButton } from '../Button'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import { TYPE } from '../../theme'
// import { useHistory } from 'react-router-dom'
import { useAddNettToken } from 'hooks/useAddTokenToMetamask'
// import { useActiveWeb3React } from 'hooks'
import { useAppSelector } from 'state'
import Value from 'components/Value'
import { useFormatSymbolTokenPrice } from 'state/data/hooks/useTokenPrice'
import { useGetNettTokenBalance } from 'state/data/hooks/useGetNettTokenInfo'
import { getTokenImage } from 'utils'

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`

const SwapLogo = styled.img`
  margin: 30px 0 0 0;
`

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  color: #fff;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const CloseColor = styled(Close)`
  path {
    stroke: '${({ theme }) => theme.v2.t01}';
  }
`

const ParentSection = styled.div`
  position: relative;
`

const NettInfoSection = styled.div`
  background-color: transparent;
  padding: 2rem;
  text-align: center;
  /* min-height: 500px; */

  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 1rem`};
`

const NettDataSection = styled.div`
  display: flex;
  justify-content: space-between;
  height: 25px;
  line-height: 25px;
`

// const NettRewardSection = styled(NettDataSection)`
//   background: ${({ theme }) => theme.v2.bg01};
//   color: ${({ theme }) => theme.v2.t01};
//   border-radius: 5px;
//   margin: 15px 0;
//   height: 40px;
//   line-height: 40px;
//   padding: 0 20px;
//   position: relative;
// `

const NettButton = styled(AuthButton)`
  padding: 4px;
  border-radius: 8px;
  width: 90%;
  margin: 15px auto;
  height: 45px;
  padding: 0 25px;
  margin-bottom: 0;
`

// const ClaimButton = styled(ButtonV2)`
//   height: 25px;
//   font-size: 12px;
//   padding: 1px;
//   width: 60px;
//   position: absolute;
//   right: 10px;
//   top: 8px;
//   border-radius: 5px;
// `

// const NettTips = styled.div`
//   transform: scale(0.6, 0.6);
//   position: absolute;
//   top: -8px;
//   width: 90px;
//   right: -18px;
//   background: ${({ theme }) => theme.v2.btn01};
//   color: #000;
//   border-radius: 0 10px;
//   text-align: center;
// `

// const NettUnclaimedTips = styled(NettTips)`
//   background: ${({ theme }) => theme.v2.bg01};
//   color: ${({ theme }) => theme.v2.t01};
// `

// const AddToken = styled.div`
//   margin: 10px 0 30px 0px;
//   font-size: 13px;
//   color: ${({ theme }) => theme.v2.t01};
//   font-weight: 600;
//   border-radius: 5px;
//   padding: 5px;
//   height: 27px;
//   &:hover {
//     display: inline-block;
//     background: ${({ theme }) => theme.v2.bg01};
//   }
//   cursor: pointer;
// `

const NettUpperSection = styled.div`
  /* border: 1px solid ${({ theme }) => theme.v2.t01}; */
  background: ${({ theme }) => theme.v2.bg04};
  border-radius: 5px;
  padding: 10px 20px;
  margin: 24px 0;
`

// const NettText = styled(TYPE.v2Main)`
//   width: 30%;
//   text-align: right;
//   font-size: 12px;
// `

// const RewardNote = styled.div`
//   font-size: 12px;
//   font-weight: 400;
//   color: ${({ theme }) => theme.text1};
//   line-height: 18px;
//   display: flex;
//   align-items: flex-start;
//   justify-content: center;
//   margin-top: 12px;
//   padding: 0 5px;
//   img {
//     width: 15px;
//     height: 15px;
//     margin-right: 4px;
//     margin-top: 3px;
//   }
// `

const NettTotalPrice = styled.div`
  width: fit-content;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
`
const NettElement = styled.div`
  width: fit-content;
  /* position: absolute; */
  font-size: 12px;
  top: 0;
  /* left: 100%; */
  /* line-height: 30px; */
  margin-left: 10px;
`

export default function NettModal() {
  const NettOpen = useModalOpen(ApplicationModal.NETT)
  const toggleNett = useNettToggle()
  //   const history = useHistory()
  //   const { account } = useActiveWeb3React()
  const addToken = useAddNettToken()
  const {
    // airdropData: { first, second },
    nettTokenInfo: { totalSupply },
    communityTresury: { nettBalance },
    blackHold: { nettBalance: blackHoldBalance }
  } = useAppSelector(state => state.netswapData)
  const tokens = useFormatSymbolTokenPrice()
  const { balance, updateBalance } = useGetNettTokenBalance()

  useEffect(() => {
    if (NettOpen) {
      updateBalance()
    }
  }, [NettOpen])

  const nettInCirculation = useMemo(() => {
    if (totalSupply >= 0 && nettBalance >= 0 && blackHoldBalance >= 0) {
      return totalSupply - nettBalance - blackHoldBalance
    }

    return -1
  }, [totalSupply, nettBalance, blackHoldBalance])

  //   function getRewardStatus(status: string | null) {
  //     switch (status) {
  //       case 'Claim':
  //         return <ClaimButton>{status}</ClaimButton>
  //       case 'Unclaimed':
  //         return <NettUnclaimedTips>{status}</NettUnclaimedTips>
  //       case 'Claimed':
  //         return <NettTips>{status}</NettTips>
  //       default:
  //         return <NettTips>{status}</NettTips>
  //     }
  //   }

  return (
    <Modal isOpen={NettOpen} onDismiss={toggleNett} minHeight={false} maxHeight={90}>
      <Wrapper>
        <ParentSection>
          <CloseIcon onClick={toggleNett}>
            <CloseColor />
          </CloseIcon>
          <NettInfoSection>
            <SwapLogo src={getTokenImage('0x90fE084F877C65e1b577c7b2eA64B8D8dd1AB278')} width="60px" />
            <TYPE.v2Main fontSize={24} fontWeight={600}>
              <NettTotalPrice>
                {balance.toNumber()}
                <NettElement>NETT</NettElement>
              </NettTotalPrice>
            </TYPE.v2Main>

            <NettUpperSection>
              <NettDataSection>
                <TYPE.v2Main fontSize={12}>NETT price:</TYPE.v2Main>
                <TYPE.v2Main fontSize={12}>
                  $ <Value value={tokens?.nett?.price || '-'} />
                </TYPE.v2Main>
              </NettDataSection>
              <NettDataSection>
                <TYPE.v2Main fontSize={12}>NETT in circulation:</TYPE.v2Main>
                <TYPE.v2Main fontSize={12}>
                  <Value value={nettInCirculation === -1 ? '-' : nettInCirculation} />
                </TYPE.v2Main>
              </NettDataSection>
              <NettDataSection>
                <TYPE.v2Main fontSize={12}>Max Supply:</TYPE.v2Main>
                <TYPE.v2Main fontSize={12}>16,666,667</TYPE.v2Main>
              </NettDataSection>
            </NettUpperSection>
            {/* {account && <AddToken onClick={addToken}>Add to Wallet</AddToken>} */}
            {/* <NettRewardSection>
              <TYPE.v2Main fontSize={12} width="50%" textAlign="left">
                1st Airdrop Reward:
              </TYPE.v2Main>
              <NettText>
                <Value value={first?.info?.formatAmount || 0} />
              </NettText>
              {first?.info && (
                <>
                  <TYPE.v2Main width="20%" height="40px"></TYPE.v2Main>
                  {getRewardStatus(first?.claimed ? 'Claimed' : 'Unclaimed')}
                </>
              )}
            </NettRewardSection>
            <NettRewardSection>
              <TYPE.v2Main fontSize={12} width="50%" textAlign="left">
                2nd Airdrop Reward:
              </TYPE.v2Main>
              <NettText>
                <Value value={second?.info?.formatAmount || 0} />
              </NettText>
              {second?.info && (
                <>
                  <TYPE.v2Main width="20%" height="40px"></TYPE.v2Main>
                  {getRewardStatus(second?.claimed ? 'Claimed' : 'Unclaimed')}
                </>
              )}
            </NettRewardSection> */}
            {/* <RewardNote>
              <i className="iconfont icon-helper"></i>
              You will be able to claim your reward until 15:00:00 UTC, Jun 30th 2022.
            </RewardNote> */}
            {/* <NettButton
              onClick={() => {
                toggleNett()
                history.push('/reward')
              }}
            >
              View Score & Reward
            </NettButton> */}

            <NettButton auth onClick={addToken}>
              Add to wallet
            </NettButton>
          </NettInfoSection>
        </ParentSection>
      </Wrapper>
    </Modal>
  )
}
