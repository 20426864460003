import { RoundStatus } from 'gql/microservices/trade-competition'
import BoxCloseSrc from 'assets/images/trade_competition/box1.inactive.png'
import BoxCloseActiveSrc from 'assets/images/trade_competition/box1.active.png'
import BoxOpenSrc from 'assets/images/trade_competition/box2.inactive.png'
import BoxOpenActiveSrc from 'assets/images/trade_competition/box2.active.png'

export enum Period {
  Preparing = 'Preparing',
  Start = 'Start',
  Finish = 'Ended',
  Distribution = 'Distribution'
}

export const TimelineConfig = [
  {
    title: `trade_competition.round_status.${RoundStatus.PREPARE}`,
    time: 0,
    left: 0,
    status: RoundStatus.PREPARE,
    iconSrc: BoxCloseSrc,
    activeIconSrc: BoxCloseActiveSrc,
    iconSize: [85, 89]
  },
  {
    title: `trade_competition.round_status.${RoundStatus.START}`,
    time: 1646665200000,
    left: 33.33,
    status: RoundStatus.START,
    iconSrc: BoxOpenSrc,
    activeIconSrc: BoxOpenActiveSrc,
    iconSize: [134, 88]
  },
  {
    title: `trade_competition.round_status.${RoundStatus.END}`,
    time: 1649257200000,
    left: 66.66,
    status: RoundStatus.END,
    iconSrc: BoxCloseSrc,
    activeIconSrc: BoxCloseActiveSrc,
    iconSize: [85, 89]
  },
  {
    title: `trade_competition.round_status.${RoundStatus.DISTRIBUTION}`,
    time: 1649466856732,
    left: 100,
    status: RoundStatus.DISTRIBUTION,
    iconSrc: BoxOpenSrc,
    activeIconSrc: BoxOpenActiveSrc,
    iconSize: [134, 88]
  }
]

export const SwapPair = [
  {
    token1: {
      symbol: 'WBTC',
      address: '0xa5B55ab1dAF0F8e1EFc0eB1931a957fd89B918f4'
    },
    token2: {
      symbol: 'Metis',
      address: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000'
    }
  },
  {
    token1: {
      symbol: 'WETH',
      address: '0x420000000000000000000000000000000000000a'
    },
    token2: {
      symbol: 'Metis',
      address: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000'
    }
  },
  {
    token1: {
      symbol: 'NETT',
      address: '0x90fE084F877C65e1b577c7b2eA64B8D8dd1AB278'
    },
    token2: {
      symbol: 'Metis',
      address: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000'
    }
  },
  {
    token1: {
      symbol: 'Metis',
      address: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000'
    },
    token2: {
      symbol: 'm.USDT',
      address: '0xbb06dca3ae6887fabf931640f67cab3e3a16f4dc'
    }
  },
  {
    token1: {
      symbol: 'Metis',
      address: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000'
    },
    token2: {
      symbol: 'm.USDC',
      address: '0xea32a96608495e54156ae48931a7c20f0dcc1a21'
    }
  },
  {
    token1: {
      symbol: 'Metis',
      address: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000'
    },
    token2: {
      symbol: 'Relay',
      address: '0xfe282Af5f9eB59C30A3f78789EEfFA704188bdD4'
    }
  },
  {
    token1: {
      symbol: 'Metis',
      address: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000'
    },
    token2: {
      symbol: 'MINES',
      address: '0xE9Aa15a067b010a4078909baDE54F0C6aBcBB852'
    }
  }
]

export const FaqConLs = [
  {
    question: 'Which trading pairs are eligible?',
    answer:
      'Only trades on WBTC/Metis, WETH/Metis, NETT/Metis, Metis/m.USDT, Metis/m.USDC, Metis/Relay, Metis/MINES pairs will be included in volume calculations.'
  },
  {
    question: 'How are prizes distributed?',
    answer: `Top five winners' prizes, including Metis, NETT, Relay and MINES, will be distributed in the form of equivalent USD. The amount of tokens will be announced before distribution. 6 -20, 21-50, 51-100 users in the three groups will be rewarded with Metis+NETT+Relay+MINES tokens corresponding to the equivalent of US dollars according to the proportion of personal transaction volume to the total transaction volume of users in each group during the event.`
  },
  {
    question: 'Do I have to register?',
    answer: 'NO. As long as you swap trading pairs, you are entitled to join the competition.'
  },
  {
    question: 'Can I join the competition after it starts?',
    answer:
      'YES. Provided that your trading pairs transactions happen between 15:00 (UTC) March 7th, 2022 and 15:00 (UTC) April 6th, 2022 (Andromeda Block time).'
  },
  {
    question: 'How can I see my current rank?',
    answer: 'Check your current rank and volume on the event page. You’ll need to connect your wallet, of course.'
  },
  {
    question: 'How do I claim my prizes?',
    answer: 'No need to claim. We will distribute tokens to your wallet address after counting.'
  }
]
