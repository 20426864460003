import React, { useState, useEffect } from 'react'
import { useMedia } from 'react-use'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import LocalLoading from 'components/Loading'
import utc from 'dayjs/plugin/utc'
import { Box, Flex } from 'rebass'
import styled from 'styled-components'
import { TopLP } from 'hooks/infos/GlobalData'

import { CustomLink } from '../Link'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { formattedNum } from 'utils/infos'
import { TYPE } from 'theme'
import DoubleTokenLogo from '../DoubleLogo'
import { RowFixed } from 'components/Row'

dayjs.extend(utc)

const PageButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 0.5em;
`

const Arrow = styled.div<{ faded?: boolean }>`
  color: ${({ theme }) => theme.v2.t01};
  opacity: ${(props) => (props.faded ? 0.3 : 1)};
  padding: 0 20px;
  user-select: none;
  :hover {
    cursor: pointer;
  }
`

const List = styled(Box)`
  -webkit-overflow-scrolling: touch;
`

const DashGrid = styled.div<{ disableLinks?: string[]; focus?: boolean; center?: boolean }>`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 10px 1.5fr 1fr 1fr;
  grid-template-areas: 'number name pair value';
  padding: 0 4px;
  /* justify-content: ${({ center }) => (center ? 'center' : 'flex-start')} > * {
    justify-content: ${({ center }) => (center ? 'center' : 'flex-end')};
  } */

  > * {
    justify-content: center;
    text-align: center;

    &:first-child {
      justify-content: flex-start;
      text-align: left;
    }

    &:last-child {
      justify-content: flex-end;
      text-align: right;
    }
  }

  @media screen and (max-width: 1080px) {
    grid-template-columns: 10px 1.5fr 1fr 1fr;
    grid-template-areas: 'number name pair value';
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 'name pair value';
  }
`

const ListWrapper = styled.div``

const DataText = styled(TYPE.mainLg)<{ area?: string; fontWeight?: number }>`
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.v2.t01};
  & > * {
    font-size: 14px;
  }

  @media screen and (max-width: 600px) {
    font-size: 13px;
  }
`

function LPList({
  lps,
  disableLinks = [],
  maxItems = 10,
}: {
  lps: TopLP[]
  disableLinks?: string[]
  maxItems?: number
} & RouteComponentProps<any>) {
  const { t } = useTranslation()
  const below600 = useMedia('(max-width: 600px)')
  const below800 = useMedia('(max-width: 800px)')

  // pagination
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const ITEMS_PER_PAGE = maxItems

  useEffect(() => {
    setMaxPage(1) // edit this to do modular
    setPage(1)
  }, [lps])

  useEffect(() => {
    if (lps) {
      let extraPages = 1
      if (Object.keys(lps).length % ITEMS_PER_PAGE === 0) {
        extraPages = 0
      }
      setMaxPage(Math.floor(Object.keys(lps).length / ITEMS_PER_PAGE) + extraPages)
    }
  }, [ITEMS_PER_PAGE, lps])

  const ListItem = ({ lp, index }: { lp: TopLP; index: number }) => {
    return (
      <DashGrid style={{ height: '48px' }} disableLinks={disableLinks} focus={true}>
        {!below600 && <DataText area="number">{index}</DataText>}
        <DataText area="name">
          <CustomLink
            style={{ marginLeft: below600 ? 0 : '1rem', whiteSpace: 'nowrap' }}
            to={'/analytics/accounts/' + lp.user.id}
          >
            {below800 ? lp.user.id.slice(0, 4) + '...' + lp.user.id.slice(38, 42) : lp.user.id}
          </CustomLink>
        </DataText>

        {/* {!below1080 && (
          <DataText area="type" justifyContent="flex-end">
            {lp.type}
          </DataText>
        )} */}

        <DataText>
          <CustomLink to={'/pair/' + lp.pairAddress}>
            <RowFixed>
              {!below600 && <DoubleTokenLogo a0={lp.token0} a1={lp.token1} size={16} margin={true} />}
              {lp.pairName}
            </RowFixed>
          </CustomLink>
        </DataText>
        <DataText area="value">{formattedNum(lp.usd, true)}</DataText>
      </DashGrid>
    )
  }

  const lpList =
    lps &&
    lps.slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE).map((lp, index) => {
      return (
        <div key={index}>
          <ListItem key={index} index={(page - 1) * maxItems + index + 1} lp={lp} />
        </div>
      )
    })

  return (
    <ListWrapper>
      <DashGrid center={true} disableLinks={disableLinks} style={{ height: 'fit-content', padding: ' 0 0 1rem 0' }}>
        {!below600 && (
          <Flex alignItems="center">
            <TYPE.v2Main color="t02">#</TYPE.v2Main>
          </Flex>
        )}
        <Flex alignItems="center">
          <TYPE.v2Main color="t02">{t('analytics.account')}</TYPE.v2Main>
        </Flex>
        {/* {!below1080 && (
          <Flex alignItems="center" justifyContent="flexEnd">
            <TYPE.v2Main area="type">Type</TYPE.v2Main>
          </Flex>
        )} */}
        <Flex alignItems="center">
          <TYPE.v2Main color="t02">{t('analytics.pair')}</TYPE.v2Main>
        </Flex>
        <Flex alignItems="center">
          <TYPE.v2Main color="t02">{t('analytics.value')}</TYPE.v2Main>
        </Flex>
      </DashGrid>
      <List p={0}>{!lpList ? <LocalLoading show={true} size={48} /> : lpList}</List>
      <PageButtons>
        <div onClick={() => setPage(page === 1 ? page : page - 1)}>
          <Arrow faded={page === 1 ? true : false}>
            <i className="iconfont icon-page-prev" />
          </Arrow>
        </div>
        <TYPE.body>{t('analytics.page_of', { current: page, total: maxPage })}</TYPE.body>
        <div onClick={() => setPage(page === maxPage ? page : page + 1)}>
          <Arrow faded={page === maxPage ? true : false}>
            <i className="iconfont icon-page-next" />
          </Arrow>
        </div>
      </PageButtons>
    </ListWrapper>
  )
}

export default withRouter(LPList)
