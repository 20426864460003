/* eslint-disable @typescript-eslint/ban-types */
import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import Numeral from 'numeral'
import NetswapRouterABI from '../constants/abis/NetswapRouter_ABI.json'
import { ROUTER_ADDRESS, SupportChainId, SWITCH_NETWORK_CONFIG } from '../constants'
import { ChainId, JSBI, Percent, Token, CurrencyAmount, Currency, METIS } from '@netswap/sdk'
import { TokenAddressMap } from '../state/lists/hooks'
import BigNumberJs from 'bignumber.js'
// import EthereumLogo from '../assets/images/network/ethereum.png'
// import HecoLogo from '../assets/images/network/heco.png'
// import BscLogo from '../assets/images/network/bsc.svg'
import MetisLogo from '../assets/images/network/metis.svg'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

const METISSCAN_PREFIXES: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '',
  [ChainId.TESTNET]: '',
  [ChainId.TESTNET_SEPOLIA]: '',
}
type NetworkConfig = {
  tokenSymbol: string
  tokenWrapperSymbol: string
  label: string
  scanLabel: string
  logo: string
  tokenName: string
}

export const NETWORK_CONFIG: { [chainId in ChainId]: NetworkConfig } = {
  [ChainId.MAINNET]: {
    tokenSymbol: 'METIS',
    tokenName: 'Metis Token',
    tokenWrapperSymbol: 'METIS',
    label: 'Metis',
    scanLabel: 'Andromeda Explorer ',
    logo: MetisLogo,
  },
  [ChainId.TESTNET]: {
    tokenSymbol: 'METIS',
    tokenName: 'Metis Token',
    tokenWrapperSymbol: 'METIS',
    label: 'Metis',
    scanLabel: 'Metis Goerli',
    logo: MetisLogo,
  },
  [ChainId.TESTNET_SEPOLIA]: {
    tokenSymbol: 'METIS',
    tokenName: 'Metis Token',
    tokenWrapperSymbol: 'METIS',
    label: 'Metis',
    scanLabel: 'Metis Sepolia',
    logo: MetisLogo,
  },
}

export function getEtherscanLink(
  chainId: ChainId,
  data: string,
  type: 'transaction' | 'token' | 'address' | 'block'
): string {
  let prefix
  const isMetis = chainId === ChainId.TESTNET
  if (chainId === ChainId.TESTNET) {
    prefix = 'https://goerli.explorer.metisdevops.link'
  } else if (chainId === ChainId.TESTNET_SEPOLIA) {
    prefix = SWITCH_NETWORK_CONFIG[chainId].blockExplorerUrls[0]
  } else if (chainId === ChainId.MAINNET) {
    prefix = 'https://andromeda-explorer.metis.io'
  } else {
    prefix = `https://${METISSCAN_PREFIXES[chainId] || METISSCAN_PREFIXES[ChainId.MAINNET]}etherscan.io`
  }

  switch (type) {
    case 'transaction': {
      let tempPrefix = 'tx'
      if (isMetis) {
        tempPrefix = 'tx'
      }
      return `${prefix}/${tempPrefix}/${data}`
    }
    case 'token': {
      let tempPrefix = 'token'
      if (isMetis) {
        tempPrefix = 'tokens'
      }
      return `${prefix}/${tempPrefix}/${data}`
    }
    case 'block': {
      let tempPrefix = 'blocks'
      if (isMetis) {
        tempPrefix = 'blocks'
      }
      return `${prefix}/${tempPrefix}/${data}`
    }
    case 'address':
    default: {
      let tempPrefix = 'address'
      return `${prefix}/${tempPrefix}/${data}`
    }
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000)),
  ]
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

// account is optional
export function getRouterContract(_: number, library: Web3Provider, account?: string): Contract {
  const chainId = library?.network?.chainId || ChainId.MAINNET
  return getContract(ROUTER_ADDRESS[chainId as ChainId], NetswapRouterABI, library, account)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
  if (currency === METIS) return true
  return Boolean(currency instanceof Token && defaultTokens[currency.chainId]?.[currency.address])
}

export function isEnvChain(chainId: ChainId | undefined): boolean {
  return true
}

export function isMetisNetwork(chainId: number) {
  return SupportChainId.indexOf(chainId) >= 0
}

export function getTokenImage(address: string) {
  if (!isAddress) {
    console.error('token address invaild')
    return
  }
  return `https://raw.githubusercontent.com/Netswap/tokens/master/assets/${address.toLowerCase()}/logo.png`
}

export function throttle(fn: Function, wait: number) {
  let time = new Date().getTime()

  return function (...args: any[]) {
    const timeNow = new Date().getTime()
    if (time + wait - timeNow < 0) {
      fn(...args)
      time = new Date().getTime()
    }
  }
}

const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
})

export const formattedPrice = (number: any, usd: boolean) => {
  let num = parseFloat(number)
  let pre = usd ? '$' : ''

  if (num > 1e9) {
    return Numeral(num).format(`${pre}0,0.00`)
  }

  if (num === 0) {
    return pre + '0'
  }

  if (num < 0.0000000001 && num > 0) {
    return `< ${pre}0.0000000001`
  }

  if (num > 1000) {
    return pre + Number(num.toFixed(0)).toLocaleString()
  }

  if (num < 0.0001) {
    return pre + num.toFixed(10)
  } else if (num < 0.1) {
    return pre + Number(num.toFixed(4))
  } else {
    let usdString = priceFormatter.format(num)
    return pre + usdString.slice(1, usdString.length)
  }
}

export function wrapperNumeral(val: string | number | undefined | BigNumberJs) {
  if (!val) return Numeral(0)
  const res = new BigNumberJs(val)
  if (res.isLessThan(0.000001) && res.isGreaterThan(0)) {
    return Numeral(new BigNumberJs(val).toFixed(6))
  } else {
    return Numeral(res)
  }
}
